import { EvaluationItem } from "../../../../admin/evaluation/interfaces"
import { ExercisesType } from "../../../../admin/exercises/interfaces"
import { UseBookStudentModule } from "../store/main"

// const {
//   lessonData,
// } = UseBookStudentModule()

export const SkipInteractions = (current:EvaluationItem | null, lessonData: EvaluationItem[])=> {
  
  if(current === null) return
  if (lessonData.length === 0 || lessonData===null || lessonData === undefined)return
  
  lessonData.sort((a, b) => a.order - b.order)

  let numberOrder = current.order-1 //quitar el indice para manejar bien el while
  let itemsArray:EvaluationItem[] = []

  console.log(lessonData[numberOrder].exercise.type)
  
  while(lessonData[numberOrder].exercise.type === ExercisesType.AUDIO_INTERACTION){
    console.log("numberOrder", numberOrder);
    
      itemsArray.push(current)
      numberOrder += 1
  }
  console.log(lessonData[numberOrder])
  
  return({
      itemsArray,
      newCurrent: lessonData[numberOrder],
      newStep: numberOrder
    }
  )
}