import React, { useEffect } from 'react';

import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { QuestionAndAnswer } from '../interfaces';
import { useNavigate } from 'react-router-dom';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { ResetStepByExercises } from '../../utils';
import { AddSimpleSelectionQuestionsResponse, OneTaskByFindOneTask } from '../../basic-vocabulary/services';
import useIsImportantEvaluation from '../../../hooks/useIsImportantEvaluation';
import { validationPercentageObtained } from '../../../utils/validatePercentage';


type Props = {
    total:number
    correct:number
    incorrect:number
    correctAnswer: QuestionAndAnswer[],
    falseAnswer: QuestionAndAnswer[],
    setStatus:(string)=>void
};

const BasicVocabularyExerciseResume: React.FC<Props> = ({correctAnswer, falseAnswer, total,correct,incorrect,setStatus }: Props) => {
    const { mode } = useThemeMode();
    const navigate = useNavigate();
    const {evaluationTest, stepEvaluationTest, setStepEvaluationTest} = UseEvaluationTestModule();
    const {
        evaluationLesson, 
        stepEvaluation, 
        questionsEvaluation, 
        lessonCourse,
        currentTaskId, 
        lessonsId,
        setStepEvaluation, 
        setShowEndEvaluation,
    } = UseEvaluationCourseModule();
    const {settings} = UseMyRoadmapModule.getState();

    const {modeCourse, modeTest} = UseModeCourseModule();
    const [url, setUrl] = React.useState<string>(`/media/svg/michigan-icons/face-resume/blue/100.svg`);
    const [message, setMessage] = React.useState<string>('');
    const [percentageExercise, setPercentageExercise] = React.useState<number>(0);
    const isImportantQuiz = useIsImportantEvaluation();
    
    const calculatePercentageAndShowLevel = () =>{
        if (total <= 0) {
            throw new Error("The total number of questions must be greater than 0.");
        }
        const percentage = (correct / total) * 100;
        const roundedPercentage = parseFloat(percentage.toFixed(2));
        
        let level: number;
        if (roundedPercentage >= 100) {
            level = 5;
            setMessage( `You did perfect!`);
            setUrl(`/media/svg/michigan-icons/face-resume/blue/100.svg`)
        } else if (roundedPercentage >= 80) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/80.svg`)
            setMessage( `You did great!`);
            level = 4;
        } else if (roundedPercentage >= 50) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/50.svg`)
            setMessage( `You can do better!`);
            level = 3;
        } else if (roundedPercentage >= 40) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/40.svg`)
            setMessage( `You did okay,c´mon!`);
            level = 2;
        } else if (roundedPercentage >= 20) {
            setMessage( `You did okay!`);
            setUrl(`/media/svg/michigan-icons/face-resume/blue/20.svg`)
            level = 1;
        } else {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/20.svg`)
            setMessage( `you can do better`);
            level = 0;
        }
        if(!isImportantQuiz){
            validationPercentageObtained(questionsEvaluation?.minPercentage??50, roundedPercentage);
        }
        return roundedPercentage;
    }

    useEffect(() => {
        setPercentageExercise(calculatePercentageAndShowLevel());
    }, []);

    const handleSubmit = async () => {
        ResetStepByExercises();
        try {
            await AddSimpleSelectionQuestionsResponse({
                exercises: questionsEvaluation?.exercise?.id??'xx',
                task: currentTaskId??'xx',
                description: '',
                isActive: true,
                percentage: percentageExercise,
                true_answer: correctAnswer.map(x => ({...x.answer, root: settings?.id??'xxx'})),
                false_answer: falseAnswer.map(x => ({...x.answer, root: settings?.id??'xxx'}))
            }) 
        } catch (e) {
            showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
        }
    }

    return (
        <>
            <div className=" card-basic-vocabulary"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                        border: `1px solid`,
                        borderColor: '#2d30e1',
                        position: 'relative'
                    }
                }>
                { isImportantQuiz?
                    <>
                        <img className='image-basic-vocabulary-resume' src={toAbsoluteUrl(`/media/svg/michigan-icons/face-resume/blue/100.svg`)} alt='' />
                        <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                            style={{
                                color: evaluationLesson?.colorPrimary??'#3e94ff',
                            }}>
                            Good job, keep going
                        </h2>
                    </>
                :
                    <>
                        <img className='image-basic-vocabulary-resume' src={toAbsoluteUrl(url)} alt='' />
                        <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                            style={{
                                color: evaluationLesson?.colorPrimary??'#2d30e1',
                            }}>
                            {message}
                        </h2>
                        <h2 className='champ-light title-basic-vocabulary-resume-number text'
                            style={{
                                color: evaluationLesson?.colorPrimary??'#2d30e1',
                            }}>
                            {correct}/{total}
                        </h2>
                    </>
                }
                
                <div
                    className='  arrow-left-basic-vocabulary cursor-pointer'
                    style={{ position: 'absolute' }}
                    onClick={()=>setStatus('ANSWER')}
                >
                    <img className='' height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/try_again.svg`)} alt='' />
                </div>
                <div
                    className='arrow-right-next-sessions cursor-pointer'
                    style={{ position: 'absolute' }}
                    onClick={async(e) => {
                        e.preventDefault()
                        if (modeTest && evaluationTest && evaluationTest.evaluation_item) {
                            ResetStepByExercises();
                            if (evaluationTest?.evaluation_item?.length > stepEvaluationTest + 1) {
                                setStepEvaluationTest(stepEvaluationTest + 1)
                            } else {
                                alert('acabo')
                            }
                        }
                        if (modeCourse && evaluationLesson
                            && evaluationLesson.evaluation_item) {
                                handleSubmit();
                            if (evaluationLesson?.evaluation_item?.length > stepEvaluation + 1) {
                                setStepEvaluation(stepEvaluation + 1)
                            } else if (lessonCourse) {
                                await OneTaskByFindOneTask(currentTaskId??'xx');
                                setShowEndEvaluation(true);
                                const nextLessonId = lessonsId?.find(x => lessonCourse.order < x.order)?.id;
                                if (nextLessonId) {
                                    navigate(`/courses/container/${nextLessonId}`);
                                } else {
                                    navigate('/dashboard');
                                }    
                            } else {
                                setShowEndEvaluation(true);
                            }
                        }
                    }}
                >
                    <img className='me-5' height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_session.svg`)} alt='' />
                </div>
            </div>
        </>
    );
};

export { BasicVocabularyExerciseResume };