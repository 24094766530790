import { create } from "zustand"
import { WordsSelectedData } from "../ExerciseSeven";
import { text } from "stream/consumers";

export interface CategoriesHighlightWord {
    title: string;
    order: number;
    color?: string;
}

export interface ResponseHighlightWord {
    category1?: string[],
    category2?: string[],
    category3?: string[],
    category4?: string[],
    category5?: string[],
    wordsMatched: number,
    totalWords: number
}

export interface StudentAnswer {
    id: string
    text: string, 
    start: number,
    end: number,
    category: 
        { 
            number: number, 
            color: string 
        },
}

interface InterfaceModuleExerciseHighlightWord {
    instruction: string;
    questionText?: string;
    categories: CategoriesHighlightWord[];
    studentAnswers: StudentAnswer[];
    responseQuestion: ResponseHighlightWord;
    exerciseId?: string;
    setResponseData:(value: any, order: number)=>void;
    removeResponseData:(value: any, order: number)=>void;
    resetResponseData: ()=>void;
    setWordsMatched:(matches: number, words:number)=>void;
    setStudentAnswers: (value: StudentAnswer[]) => void;
    setQuestions:(
        exercisesId: string,
        questionText: string, 
        instruction: string,
        categories: CategoriesHighlightWord[])=>void;
}
export const UseModuleExerciseHighlightWord = create<InterfaceModuleExerciseHighlightWord>((set) => ({                   
    instruction: '',
    responseQuestion: {
        category1: [],
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        wordsMatched: 0,
        totalWords: 0
    },
    studentAnswers: [],
    categories: [],
    setResponseData:(value, number)=>{
        set(state => {
            return {
                responseQuestion: {
                    ...state.responseQuestion, [`category${number}`]: [
                        ...state.responseQuestion[`category${number}`], value
                    ]
                }
            }
        })
    },
    removeResponseData: (value, number) => {
        set(state => {
            const updatedCategory = state.responseQuestion[`category${number}`].filter(item => item !== value);
            return {
                responseQuestion: {
                    ...state.responseQuestion,
                    [`category${number}`]: updatedCategory
                }
            };
        })
    },
    resetResponseData:()=>{
        set({responseQuestion: {
            category1: [],
            category2: [],
            category3: [],
            category4: [],
            category5: [],
            wordsMatched: 0,
            totalWords: 0
        }})
    },
    setWordsMatched:(matches, words)=>{
        set(state => {
            return {
                responseQuestion: {
                    ...state.responseQuestion,
                    wordsMatched: matches,
                    totalWords: words
                }
            }
        })
    },
    setQuestions:(exerciseId, instruction, questionText, categories)=>{
        set({questionText, categories, exerciseId, instruction});
    },
    setStudentAnswers: (value) => {
        set({ studentAnswers: value });
    }
}))