/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {LeadSourceForm} from '../forms'
import {UseLeadSourceModule} from '../../store/main'
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const {modal, mode,setModal,resetData} = UseLeadSourceModule();
  const submit = () => {
    window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        {mode === 'ADD' && <h2>Crear </h2>}
        {mode === 'EDIT' && <h2>Editar </h2>}
        {mode === 'VIEW' && <h2>Ver </h2>}
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModal(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <LeadSourceForm mode={mode} />
    </Modal>,
    modalsRoot
  )
}

export {CreateModal}
