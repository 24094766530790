import React, {FormEvent, useState} from 'react'
import * as Yup from 'yup'
import { TeacherProfileBlockHoursAdd, TeacherProfileByDay } from '../../services'
import { useQueryClient } from '@tanstack/react-query'
import { UseBlockHourModule } from '../../store/main'
import { TeacherBlockHoursData } from '../../interfaces'
import { TemplateTableTimeDay } from '../../../template-table-time/interfaces'

type Props ={
  mode: 'MONDAY'|'TUESDAY'|'WEDNESDAY'|'THURSDAY'|'FRIDAY'|'SATURDAY'|null
}
const BlockHoursTeacherForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {teacherId, 
    dataBlockHour,
    mode,
    monday, 
    tuesday, 
    thursday,
    wednesday, 
    friday, 
    saturday,
    setMonday,
    setTuesday,
    setWednesday,
    setThursday,
    setFriday,
    setSaturday,
    setTotalHours,
    setModal} = UseBlockHourModule();

  const [loading, setLoading] = React.useState<boolean>(false);

    const [isChecked, setIsChecked] = useState<string[]>([]);

    React.useEffect(() => {
      let initialCheckedItems: string[] = [];
    
      switch (mode) {
        case 'MONDAY':
          initialCheckedItems = monday?.map(x=>x.id) || [];
          break;
        case 'TUESDAY':
          initialCheckedItems = tuesday?.map(x=>x.id) || [];
          break;
        case 'WEDNESDAY':
          initialCheckedItems = wednesday?.map(x=>x.id) || [];
          break;
        case 'THURSDAY':
          initialCheckedItems = thursday?.map(x=>x.id) || [];
          break;
        case 'FRIDAY':
          initialCheckedItems = friday?.map(x=>x.id) || [];
          break;
        case 'SATURDAY':
          initialCheckedItems = saturday?.map(x=>x.id) || [];
          break;
        default:
          initialCheckedItems = [];
          break;
      }
    
      setIsChecked(initialCheckedItems);
    }, [mode, monday, tuesday, wednesday, thursday, friday, saturday]);

    const handleCheckAll = () => {
      const allBlockHours = dataBlockHour?.map((item) => item.id).filter(Boolean) || [];
      const allSelected = allBlockHours?.every((item) => isChecked.includes(item ?? ''));
      if (allSelected) {
        setIsChecked([]);
      } else {
        setIsChecked(allBlockHours as string[]);
      }
    };

    const fetchDataByMode = async (mode: string) => {
      if (teacherId) {
        setLoading(true);
        
        switch (mode) {
          case 'MONDAY':
            const mondayResponse = await TeacherProfileByDay(teacherId, TemplateTableTimeDay.MONDAY);
            setMonday(mondayResponse.monday);
            break;
          case 'TUESDAY':
            const tuesdayResponse = await TeacherProfileByDay(teacherId, TemplateTableTimeDay.TUESDAY);
            setTuesday(tuesdayResponse.tuesday);
            break;
          case 'WEDNESDAY':
            const wednesdayResponse = await TeacherProfileByDay(teacherId, TemplateTableTimeDay.WEDNESDAY);
            setWednesday(wednesdayResponse.wednesday);
            break;
          case 'THURSDAY':
            const thursdayResponse = await TeacherProfileByDay(teacherId, TemplateTableTimeDay.THURSDAY);
            setThursday(thursdayResponse.thursday);
            break;
          case 'FRIDAY':
            const fridayResponse = await TeacherProfileByDay(teacherId, TemplateTableTimeDay.FRIDAY);
            setFriday(fridayResponse.friday);
            break;
          case 'SATURDAY':
            const saturdayResponse = await TeacherProfileByDay(teacherId, TemplateTableTimeDay.SATURDAY);
            setSaturday(saturdayResponse.saturday);
            break;
          default:
            break;
        }
    
        setLoading(false);
      }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      
      const daysData = {
        'MONDAY': { monday: isChecked },
        'TUESDAY': { tuesday: isChecked },
        'WEDNESDAY': { wednesday: isChecked },
        'THURSDAY': { thursday: isChecked },
        'FRIDAY': { friday: isChecked },
        'SATURDAY': { saturday: isChecked },
      };
    
      const selectedDayData = daysData[mode];
      
      const teacherBlockHoursData: TeacherBlockHoursData = {
        user: teacherId ?? '',
        monday: monday?.map(x => x.id) || [],
        tuesday: tuesday?.map(x => x.id) || [],
        wednesday: wednesday?.map(x => x.id) || [],
        thursday: thursday?.map(x => x.id) || [],
        friday: friday?.map(x => x.id) || [],
        saturday: saturday?.map(x => x.id) || [],
        ...selectedDayData,
      };

      setLoading(true);
      await TeacherProfileBlockHoursAdd(teacherBlockHoursData);
    
      await fetchDataByMode(mode);
      setTotalHours(teacherId??'');
      
      setModal(false, 'MONDAY');
      setLoading(false);
    };

    const handleDivClick = (id: string) => {
      let updatedSelectedItems: string[] = [...isChecked];
      if (isChecked.includes(id)) {
        updatedSelectedItems = isChecked.filter((item) => item !== id);
      } else {
        updatedSelectedItems.push(id);
      }
      setIsChecked(updatedSelectedItems);
    };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>
          <div className='col-6'>
            <h3>Mañana</h3>
            {dataBlockHour &&
              dataBlockHour
                .filter(item => item.start < '12:00')
                .map((item, index) => (
                  <a
                    href='#'
                    key={index}
                    className={`col-6 mb-1 ${isChecked.includes(item.id??'') ? 'bg-primary text-white' : 'bg-secondary text-dark'}`}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '5px', borderRadius: '5px' }}
                    onClick={() => handleDivClick(item.id??'')}
                  >
                    {isChecked.includes(item.id??'') ? (
                      <i className="fa-solid fa-check text-white me-2"></i>
                    ) : (
                      <i className="bi bi-dash-square-fill me-2"></i>
                    )}
                    {item.start} - {item.end}
                  </a>
                ))}
          </div>
          <div className='col-6'>
            <h3>Tarde</h3>
            {dataBlockHour &&
              dataBlockHour
                .filter(item => item.start >= '12:00')
                .map((item, index) => (
                  <a
                    href='#'
                    key={index}
                    className={`col-6 mb-1 ${isChecked.includes(item.id??'') ? 'bg-primary text-white' : 'bg-secondary text-dark'}`}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '5px', borderRadius: '5px' }}
                    onClick={() => handleDivClick(item.id??'')}
                  >
                    {isChecked.includes(item.id??'') ? (
                      <i className="fa-solid fa-check text-white me-2"></i>
                    ) : (
                      <i className="bi bi-dash-square-fill me-2"></i>
                    )}
                    {item.start} - {item.end}
                  </a>
                ))}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            type='button'
            onClick={handleCheckAll}
            className='btn btn-primary me-3'
            disabled={loading}
          >
            Check All
          </button>

          <button type='submit' className='btn btn-primary' disabled={loading /*||mode==='VIEW'*/}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {BlockHoursTeacherForm}
