import React, {useEffect} from 'react'
import '../index.css'
import HeaderCard from '../ui/HeaderCard'
import {UseModuleExerciseHighlightWord} from './store/main'
import {ResetStepByExercises} from '../../../../../modules/courses/exercises/utils'
import {UseBookContainerModule} from '../../../../../modules/book/store/main'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ModeTypeExercise} from '../../../../../modules/book/components/RenderExerciseBook'
import {AddHighlightWordResponse} from './store/service'
import {showSimpleAlert} from '../../../../../commonHooks/alert'
import {UseBookStudentModule} from '../../../../../modules/book/modules/screen/store/main'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ButtonTryAgain} from '../../../../../modules/courses/exercises/common/button-try-again/buttonTryAgain'
import {ButtonSaveExercise} from '../../../../../modules/courses/exercises/common/button-save-exercise/buttonSaveExercise'

export interface WordsSelectedData {
  category1: string[]
  category2: string[]
  category3: string[]
  category4: string[]
  category5: string[]
}

type AnswerData = {
  percentage: number
  wordsMatched: number
  totalWords: number
}

type Selection = {
  id: string
  text: string
  start: number
  end: number
  category: {
    number: number
    color: string
  }
}

type Props = {
  userMode?: ModeTypeExercise
}

const HighlightWord: React.FC<Props> = ({userMode}) => {
  const {setOffDraggable} = UseBookContainerModule()
  const {
    instruction,
    questionText,
    categories,
    responseQuestion,
    studentAnswers,
    exerciseId,
    setResponseData,
    setWordsMatched,
    setStudentAnswers,
    resetResponseData,
    removeResponseData,
  } = UseModuleExerciseHighlightWord()

  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    currentItemBook,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
  } = UseBookStudentModule()

  const [categoriesData, setCategoriesData] = React.useState<{
    number: number
    color: string
    title: string
  }>()
  const [answerData, setAnswerData] = React.useState<AnswerData>()
  const [selections, setSelections] = React.useState<Selection[]>([])
  const [isUnderlining, setIsUnderlining] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)
  const [renderText, setRenderText] = React.useState<JSX.Element[]>()
  const [check, setCheck] = React.useState<boolean>(false)

  const handleUnderline = () => {
    setIsDeleting(false)
    setIsUnderlining(true)
  }

  const handleDelete = () => {
    setIsDeleting(!isDeleting)
    setIsUnderlining(false)
  }

  const divStyle = {
    backgroundColor: '#3f8bff',
    width: '525px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px',
  }

  const clearText = (text: string) => {
    const regex = /\{([^}]+)\}|\[([^\]]+)\]|\&([^&]+)\&|\%([^%]+)\%|\#([^#]+?)\#/g
    return text.replace(regex, '$1$2$3$4$5')
  }

  const handleNext = () => {
    let percentagePerCategory:{wordsText: number, wordsMatched: number}[] = []
    for (let i = 1; i <= categories.length; i++) {
      const categoryKey = `category${i}`
      const words = responseQuestion[categoryKey]
      const isValid = validationExercise(words, i)
      percentagePerCategory.push(isValid)
    }
    const { wordsMatched, totalWords } = percentagePerCategory.reduce((accumulator, currentObject) => {
      accumulator.wordsMatched += currentObject.wordsMatched;
      accumulator.totalWords += currentObject.wordsText;
      return accumulator;
    }, { wordsMatched: 0, totalWords: 0 });
    const totalPercentage = wordsMatched/totalWords * 100
    setWordsMatched(wordsMatched, totalWords)
    setAnswerData({
      percentage: totalPercentage,
      wordsMatched: wordsMatched,
      totalWords: totalWords,
    })
    setCheck(true)
  }

  const resetExercise = () => {
    setCheck(false)
    setSelections([])
    setAnswerData(undefined)
    resetResponseData()
    setWordsMatched(0, 0)
  }

  const validationExercise = (wordsStudent: string[], numberCategory: number) => {
    const regex = [/\{([^}]+)\}/g, /\[([^\]]+)\]/g, /\&([^&]+)\&/g, /\%([^%]+)\%/g, /\#([^#]+?)\#/g]
    const wordsTextMatch = questionText && questionText.match(regex[numberCategory - 1])  
    const wordsText = wordsTextMatch ? wordsTextMatch.map((match) => match.slice(1, -1)) : []
    const wordsMatched = wordsStudent.filter((x) => wordsText.includes(x)).length
    return {wordsText: wordsText.length, wordsMatched}
  }

  const handleSelectionText = () => {
    setOffDraggable(false)
    const selected = window.getSelection()

    if (selected && categoriesData) {
      const textSelected = selected.toString().trim()
      if (textSelected !== '') {
        const range = selected.getRangeAt(0)
        const startOffset = range.startOffset
        const endOffset = range.endOffset

        // Verificar si hay intersección con las selecciones existentes
        const isIntersecting = selections.some(
          (selection) => startOffset < selection.end && endOffset > selection.start
        )

        if (!isIntersecting) {
          const span = document.createElement('span')
          span.textContent = textSelected

          range.deleteContents()
          range.insertNode(span)

          const newSelection = {
            id: (startOffset + textSelected.replace(/\s+/g, '') + endOffset).trim(),
            text: textSelected,
            category: categoriesData,
            start: startOffset,
            end: endOffset,
          }
          setSelections([...selections, newSelection])
          setResponseData(textSelected, categoriesData?.number)
        } else {
          showSimpleAlert({message: "You can't underline a text that is underlined", icon: 'error'})
        }
      } else {
        showSimpleAlert({message: 'Empty text selected', icon: 'error'})
      }
    } else if (!categoriesData) {
      showSimpleAlert({message: 'Select a category', icon: 'error'})
    }
    setIsUnderlining(false)
  }

  const renderHighlightedText = React.useCallback(() => {
    if (!questionText) return null
    const text = clearText(questionText)

    const elements: JSX.Element[] = []
    let currentPosition = 0

    const sortedSelections = [...selections].sort((a, b) => a.start - b.start)

    sortedSelections.forEach((selection, index) => {
      const startIndex = selection.start
      const endIndex = selection.end

      if (startIndex !== -1 && endIndex !== -1 && currentPosition <= startIndex) {
        if (currentPosition < startIndex) {
          const beforeText = text.substring(currentPosition, startIndex)
          elements.push(<span key={`before-${selection.id}`}>{beforeText}</span>)
          currentPosition = startIndex
        }

        const selectedText = text.substring(startIndex, endIndex)
        elements.push(
          <span
            key={`highlight-${selection.id}`}
            style={{
              backgroundColor: selection.category.color,
              cursor: `${isDeleting ? 'no-drop' : 'default'}`,
            }}
            onClick={() => {
              if (isDeleting) {
                const filteredSelections = selections.filter((s) => s.id !== selection.id)
                setSelections(filteredSelections)
                removeResponseData(selectedText.trim(), selection.category.number);
              }
            }}
          >
            {selectedText}
          </span>
        )

        currentPosition = endIndex
      }
    })

    if (currentPosition < text.length) {
      const remainingText = text.substring(currentPosition)
      elements.push(<span key='after'>{remainingText}</span>)
    }

    setRenderText(elements)
  }, [selections, isDeleting])
  const handleSubmit = async () => {
    if (lessonData && lessonData.evaluation && lessonData.evaluation.evaluation_item) {
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId ?? '')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
      addCompletedExerciseId(exerciseId ?? '')
    }
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        const response = await AddHighlightWordResponse({
          description: '',
          answer: selections,
          isActive: true,
          percentage: answerData?.percentage ?? 0,
          exercises: exerciseId ?? '',
          task: currentTaskId,
        });
        onChangeTaskByAnswer(response, 'highlight_word');
      } catch (e) {
        showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
      }
    }
  }

  React.useEffect(() => {
    setStudentAnswers(selections)
    renderHighlightedText()
  }, [selections, isDeleting])

  React.useEffect(() => {
    if (studentAnswers.length > 0) {
      setSelections(studentAnswers)
    }
  }, [])

  return (
    <div className='container-fluid d-flex '>
      <div style={divStyle}>
        <HeaderCard />
        <div className="scroll-y overflow-y-auto" style={{maxHeight:'70px'}}>
          <span className='text-white cuestion mb-0 poppins-light' style={{marginLeft: '0px'}}>{instruction}</span>
        </div>
        {isUnderlining ? (
          <label
            className='text-white scroll-y p-1 poppins-light'
            style={{
              maxHeight: '100px',
              fontSize: '75%',
              marginLeft: '15px',
              marginTop: '10px',
              cursor: 'text',
            }}
            onMouseDown={handleUnderline}
            onMouseUp={handleSelectionText}
            onMouseMove={() => setOffDraggable(true)}
            onMouseOut={() => setOffDraggable(false)}
          >
            {clearText(questionText ?? '')}
          </label>
        ) : (
          <label
            className='text-white scroll-y overflow-y-auto p-1 poppins-light'
            style={{
              maxHeight: '100px',
              fontSize: 11,
          
              marginTop: '10px',
              cursor: 'default',
            }}
          >
            {renderText}
          </label>
        )}
        <div className='d-flex justify-content-between my-5'>
          <div className='d-flex align-items-center '>
            <span className='cursor-pointer text-secondary font-weight-bold me-5'>
              Selecciona la categoría
            </span>

            <div className='d-flex'>
              {categories.map((x) => (
                <OverlayTrigger overlay={<Tooltip>{x.title}</Tooltip>} placement='top'>
                  <div
                    className='rounded rounded-circle me-1 cursor-pointer'
                    key={x.order}
                    onClick={() => {
                      setCategoriesData({number: x.order, color: x.color ?? '#ccc', title: x.title})
                    }}
                    style={{width: '30px', height: '30px', backgroundColor: x.color}}
                  />
                </OverlayTrigger>
              ))}
            </div>
          </div>
          <div>
            <OverlayTrigger overlay={<Tooltip>Underline</Tooltip>} placement='top'>
              <button
                type='button'
                className={`btn  btn-${isUnderlining ? 'warning' : 'light'} px-2 py-1 me-2`}
                onClick={handleUnderline}
              >
                <i className='bi bi-pen p-0'></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Erase</Tooltip>} placement='top'>
              <button
                type='button'
                className={`btn  btn-${isDeleting ? 'danger' : 'light'} px-2 py-1 me-2`}
                onClick={handleDelete}
              >
                <i className='bi bi-eraser p-0'></i>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex align-items-center'>
            {categoriesData && (
              <>
                <span className='text-secondary fw-bold'>
                  {`Categoría seleccionada: ${categoriesData.title}`}{' '}
                </span>
                <div
                  className='rounded rounded-circle mx-4 cursor-pointer'
                  key={'selected-category'}
                  style={{width: '30px', height: '30px', backgroundColor: categoriesData.color}}
                />
              </>
            )}
          </div>
        </div>
          <div className={`d-flex justify-content-${check? "between" : "end"} mt-10`}>
            {check && (
              <>
                <div>
                  <ButtonTryAgain onAction={resetExercise} />
                </div>
                <div>
                  <h3 className='text-center' style={{fontSize: 10}}>
                    {' '}
                    <span className=' text-white  mb-0 poppins-light'>
                      Porcentaje correcto: {answerData && answerData?.percentage.toFixed(2)}%
                    </span>
                  </h3>
                </div>
              </>
            )}
            <div>
              <ButtonSaveExercise
                isChecked={check}
                onNext={handleNext}
                onSubmit={handleSubmit}
                exerciseId={exerciseId}
              />
            </div>
          </div>
      </div>
    </div>
  )
}

export {HighlightWord}
