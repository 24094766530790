import React from 'react';
import { MultipleSelectionExItem } from './components/item';
import { UseMultipleSelectionModule } from './store';
import { MultipleSelectionExItemTwoOptions } from './components/item-two-options';
import { MultipleSelectionType } from './interfaces';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';


type Props = {
    variant?: MultipleSelectionType;
    userMode?: ModeTypeExercise
};

const MultipleSelectionEx: React.FC<Props> = ({ variant, userMode }) => {
    let {
        questions,
        answer,
    } = UseMultipleSelectionModule();

    return (<>

        {variant === MultipleSelectionType.GROUP_OPTIONS ?
            <MultipleSelectionExItem
                userMode={userMode}
                responses={answer}
                question={questions}
            />
        : variant === MultipleSelectionType.TWO_OPTIONS ? 
            <MultipleSelectionExItemTwoOptions
                userMode={userMode}
                responses={answer}
                question={questions}
            />
        : <MultipleSelectionExItemTwoOptions
            userMode={userMode}
            responses={answer}
            question={questions}
            />
        }



    </>);
};
export { MultipleSelectionEx };