import {Route, Routes} from 'react-router-dom'
import { TasksDetails } from '../components/details'
import { TasksTableList } from '../components/list'


const TasksPage = () => (
  <Routes>
      <Route path='/' element={<TasksTableList />} />
  </Routes>
)

export {TasksPage}
