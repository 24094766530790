/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { t } from 'i18next'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import moment from 'moment'
import { UseAppointmentDashboardCount } from '../../services'
import { TrailClassChart } from '../trail-class-chart'

export interface LevelCount {
    level: TraditionalLevelEnum
    count: string
}
type ResumeResponse = {
    total: string
    totalPercentage
}
type LevelChartsProps = {
    list: LevelCount[]
    totalStudent: number
    after: Date
    before: Date
}

const AppointmentChart: React.FC<LevelChartsProps> = ({ list, totalStudent, after, before }) => {
    const [pendingCount, setPendingCount] = React.useState<number>(0);
    const [cancelledCount, setCanceledCount] = React.useState<number>(0);
    const [inProcessCount, setInProcessCount] = React.useState<number>(0);
    const [finishedCount, setFinishedCount] = React.useState<number>(0);
    const [totalCount, setTotalCount] = React.useState<number>(0);

    let getPercentageForLevel = (total: number) => {
        const percentage = (total / totalCount) * 100;
        return `${percentage.toFixed(2)}%`;
    }
    let getTotalForLevel = (requestedLevel: string) => {
        const levelInfo = list.find(level => level.level === requestedLevel);
        if (!levelInfo) {
            return `0`;
        }
        return levelInfo?.count
    }
    let findAppointmentDashboardCount = async () => {
        let _before = moment(before).format('YYYY-MM-DD')
        let _after = moment(after).format('YYYY-MM-DD')
        let response = await UseAppointmentDashboardCount(_before, _after)
        console.log(response)
        setCanceledCount(response.CANCELLED)
        setFinishedCount(response.FINISHED)
        setPendingCount(response.PENDING)
        setInProcessCount(response.IN_PROCESS)
        setTotalCount(response.TOTAL)
    }
    useEffect(() => {
        findAppointmentDashboardCount()
    }, [after, before])
    return (
        <div className='row'>

            <div className='col-6'>
                <TrailClassChart after={after} before={before} />
            </div>
            <div className='card shadow p-8 col-6'>
                <h1 className='champ-light text-center'>Horas</h1>
                <hr></hr>
                <h5 className='champ-light'>Pendientes {pendingCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(pendingCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(pendingCount)}</div>
                </div>
                <h5 className='champ-light'>Canceladas {cancelledCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(cancelledCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(cancelledCount)}</div>
                </div>
                <h5 className='champ-light'>En Proceso {inProcessCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(inProcessCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(inProcessCount)}</div>
                </div>
                <h5 className='champ-light'>Finalizadas {finishedCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(finishedCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(finishedCount)}</div>
                </div>
            </div>
        </div>
    )
}

export { AppointmentChart }
