import React from 'react';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { FrontPageCourses } from '../../../../components/front-page';
import { UseLearningPathModule } from '../../../../../abroad/learning-path/store/main';
import { UseLearningPathAllPaginate } from '../../../../../abroad/learning-path/services';
import { showSimpleAlert } from '../../../../../../commonHooks/alert';
import { LearningPathType } from '../../../../../abroad/learning-path/interfaces';

type Props = {};

const FrontPageEnglishExplorers: React.FC<Props> = () => {
    const { param, setParam } = UseLearningPathModule();
    const [dataCourse, setDataCourse] = React.useState<any[]>();
    React.useEffect(() => {
        setParam('ENGLISH_EXPLORER');
    }, []);

    React.useEffect(() => {
        if (param) {
            const findCourse = async () => {
                try {
                let response = await UseLearningPathAllPaginate();
                setDataCourse(response.result);
                } catch (e) {
                    showSimpleAlert({ message: 'Error al encontrar Caoitulos!', icon: 'error' })
                    throw 'Error'
                }
            }
            findCourse();
        }
    }, [param]);

    return (<>
    {dataCourse && 
        <FrontPageCourses 
            description={dataCourse[0]?.description} 
            logoCourse={dataCourse[0]?.logo.location} 
            bannerPrincipal={dataCourse[0]?.bannerPrincipal.location} 
            colorCourse={dataCourse[0]?.colorBase}
            typeCourse={dataCourse[0]?.type} 
            mode='STUDENT'
            courseId={dataCourse[0]?.id??''}
        />
    }
    </>)
};

export {FrontPageEnglishExplorers}