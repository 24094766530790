import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {ApprovalTypeTopic, Topics} from '../../interfaces'

import { TopicsAdd, TopicsUpdate } from '../../services'
import { UseTopicsModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { ResourceType } from '../../../chapter/interfaces'
import { SequenceType } from '../../../../../road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ToTypeContentCourse, TypeContentCourse } from '../../../../../../admin/file-folder/interfaces'
import FileContentComponent from '../../../../../../../components/common/SingleFile/FileContent'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/helpers'
import FileUploadComponent from '../../../../../../../components/common/SingleFile'
import { favIconAppSelector } from '../../../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  approval: ApprovalTypeTopic;
  sequence: SequenceType;
  resourceType?: ResourceType;
  order: number;
  resource?: string;
  fileContent?: any;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TopicsForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseTopicsModule();
  const [data, setData] = useState<Topics>()
  const [loading, setLoading] = React.useState<boolean>(false);
  const [contentFile, setContentFile] = React.useState<any>();
  const [applyCoverInput, setApplyCoverInput] = React.useState<boolean>(false);
  const [coverFile, setCoverFile] = React.useState<any>();
  const [typeFileInput, setTypeFileInput] = React.useState<TypeContentCourse>(TypeContentCourse.IMAGE);

  const {id} = useParams();

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        approval: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.approval ?? ApprovalTypeTopic.EXAM : ApprovalTypeTopic.EXAM,
        sequence: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sequence ?? SequenceType.RANDOM : SequenceType.RANDOM,
        order: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.order ?? 0 : 0,
        fileContent: (mode === 'EDIT' || mode ==='VIEW') ? itemSelected?.fileContent?.id ?? undefined:undefined
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await TopicsAdd({...formData, lesson: id??''})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['topics'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  TopicsUpdate({...formData, lesson: id??''})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['topics'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

    const changeFileContent = (obj: any) => {
      setContentFile(obj)
    };
  
    const changeFileCover = (obj: any) => {
      setCoverFile(obj)
    };
  
    const changeContent = (id: string) => {
      setFieldValue('fileContent', id)
    };
  
    React.useEffect(() => {
      setTypeFileInput(itemSelected?.fileContent?.type ?? TypeContentCourse.IMAGE);
      setApplyCoverInput(itemSelected?.fileContent?.applyCover ?? itemSelected?.fileContent?.cover ? true : false);
      setContentFile(itemSelected?.fileContent?.content);
      setCoverFile(itemSelected?.fileContent?.cover);
    }, [itemSelected]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Approval</label>
            <select
              disabled={mode === 'VIEW'}
              name='approval'
              value={values.approval}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ApprovalTypeTopic).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.approval && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.approval}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Sequence</label>
            <select
              disabled={mode === 'VIEW'}
              name='sequence'
              value={values.sequence}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SequenceType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.sequence && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.sequence}</span>
                </div>
              </div>
            )}
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>resourceType</label>
            <select
              disabled={mode === 'VIEW'}
              name='resourceType'
              value={values.resourceType}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ResourceType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.resourceType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resourceType}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>resourceType</label>
            <select
              disabled={mode === 'VIEW'}
              value={typeFileInput}
              onChange={(e)=> setTypeFileInput(e.target.value as TypeContentCourse)}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TypeContentCourse).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Order</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Apply cover?</label>
            <div className="form-check form-switch">
              <input
                checked={applyCoverInput}
                onChange={(e)=>setApplyCoverInput(e.target.checked)}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          {applyCoverInput && <div className='mb-10 col-6 d-flex flex-column'>
              <label className='form-label mb-3'>Cover</label>
              {(coverFile && (coverFile.url || coverFile.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(coverFile.url || coverFile.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
                <FileUploadComponent 
                  onChangeFile={(e) => changeFileCover(e)} 
                  onChangeLoading={(e) => setLoading(e)}
                  pushCover
                  idInput='coverFile' />
          </div>}

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Content</label>
            {(contentFile && (contentFile.url || contentFile.location)) ? 
              typeFileInput !== TypeContentCourse.VIDEO &&
              <img className='img-fluid' style={{width:'50%'}} 
                src={(contentFile.url || contentFile.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : typeFileInput !== TypeContentCourse.VIDEO &&
              <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileContentComponent
              onChangeFile={(e) => changeFileContent(e)} 
              onChangeLoading={(e) => setLoading(e)}
              onChangeContent={(e) => changeContent(e)}
              idInput='contentChapter'
              to={ToTypeContentCourse.TOPIC}
              root={itemSelected?.id??'x'}
              typeContent={typeFileInput}
              applyCover={applyCoverInput}
              cover={coverFile?coverFile.id:undefined}
            />
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Resource</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'resource'}
              value={values.resource}
              onChange={handleChange}
            />
            {touched.resource && errors.resource && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resource}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {TopicsForm}
