import React, { useEffect } from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseBasicVocabularyModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';

type Props = {
    titleContent: string;
    descriptionContent: string;
};

const BasicVocabularyExercise: React.FC<Props> = ({ titleContent, descriptionContent}) => {
    const { mode } = useThemeMode();
    const [question, setQuestion] = React.useState<number>(0);
    let { 
        questions,
        setStep, 
        step, 
        setAnswer, 
        status, 
        falseAnswer, 
        trueAnswer, 
        setStatus,
        startQuiz,
        title,
        instructions
    } = UseBasicVocabularyModule();
    
    return (
        <>
            {status != 'RESUME' && status != 'TITLE' &&
                <BasicVocabularyExerciseItem
                    falseAnswer={falseAnswer[step]}
                    question={questions[step]}
                    handleNext={(e) => setAnswer(e)}
                    handleSendAnswer={(e) => setAnswer(e)}
                    modeQuiz={status}
                    totalQuestions={questions.length}
                    setStatus={(e) => setStatus(e)}
                    setStep={(e) => setStep(step + e)}
                    description={descriptionContent}
                />}
            {status === 'RESUME' &&
                <BasicVocabularyExerciseResume
                    total={questions.length}
                    correct={trueAnswer.length}
                    incorrect={falseAnswer.length}
                    setStatus={(e) => setStatus(e)}
                    correctAnswer={trueAnswer}
                    falseAnswer={falseAnswer}
                />}
            {status === 'TITLE' && 
                <IntroBasicVocabulary 
                    title={titleContent}
                    instructions={descriptionContent}
                    startQuiz={()=>startQuiz()}
                />
            }
        </>
    );
};

export { BasicVocabularyExercise };