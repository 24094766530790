import React from 'react';
import MyX from '../../../../components/abroad/e-book/beyong-the-reading/img/EQUIS_btn1.svg'
import { UseBookContainerModule } from '../../store/main';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';

type Props = {};

const TimerToolComponent: React.FC<Props> = () => {
  const {setToolsTimer} = UseBookContainerModule();

  const [timerMin, setTimerMin] = React.useState<number>(0);
  const [timerSec, setTimerSec] = React.useState<number>(0);
  const [isRunning, setIsRunning] = React.useState<boolean>(false);
  const [timerSmall, setTimerSmall] = React.useState<boolean>(false);

  React.useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTimerSec((prevSec) => {
          if (prevSec === 0) {
            if (timerMin === 0) {
              clearInterval(timerInterval);
              setIsRunning(false);
              return 0;
            }
            setTimerMin((prevMin) => prevMin - 1);
            return 59;
          }
          return prevSec - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, timerMin]);

  const handleStartTimer = () => {
    setIsRunning(true);
    setTimerSmall(true);
  };

  const handlePauseTimer = () => {
    setIsRunning(false);
  };

  const handleResetTimer = () => {
    setIsRunning(false);
    setTimerSmall(false);
    setTimerMin(0);
    setTimerSec(0);
  };

  const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

    return(<>
        <div className='container-fluid d-flex'>
          {timerSmall ? <div
            className='d-flex justify-content-center align-items-center'
            style={{
              borderRadius: '30px',
              border: 'solid rgb(255, 255, 255)',
              width: '200px',
              height: '60px',
              backgroundColor: '#3f8bff',
          }}>
            <div className='d-flex justify-content-center align-items-center'>
              <img alt="reset"
                className='cursor-pointer'
                src={toAbsoluteUrl('/media/svg/michigan-icons/tools/white/reset_timer_white.svg')} 
                onClick={handleResetTimer}
                style={{width: '35px'}} />
                {isRunning ? <img alt="pause"
                className='cursor-pointer'
                onClick={handlePauseTimer}
                src={toAbsoluteUrl('/media/svg/michigan-icons/tools/blue/pause_timer_blue.svg')} 
                style={{width: '35px'}} /> : 
                <img alt="play"
                  className='cursor-pointer'
                  onClick={handleStartTimer}
                  src={toAbsoluteUrl('/media/svg/michigan-icons/tools/blue/play_timer_blue.svg')} 
                  style={{width: '35px'}} />}


              <span className='champ-light text-white mt-2 ms-1' style={{
                fontSize: 25,
              }}>{formatTime(timerMin)}:{formatTime(timerSec)}</span>
              
            </div>
          </div> : <div
            className='d-flex justify-content-center align-items-center'
            style={{
              borderRadius: '30px',
              border: 'solid rgb(255, 255, 255)',
              width: '250px',
              height: '250px',
              backgroundColor: '#3f8bff',
            }}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <span className='champ-light text-white' 
                style={{
                  fontSize: '35px'
                }}>Timer</span>
              <span 
                className='poppins-light text-white fs-6 text-center mb-3'
                style={{
                  marginTop: '-10px'
                }}
              >Set the time and start the countdown</span>
              <div className='row'>
                <div className='col-6 d-flex flex-column align-items-end mb-3'>
                  <div className="custom-input-wrapper">
                    <input 
                      type="number" 
                      name=""
                      className='custom-input-timer champ-light ps-2'
                      value={timerMin??0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setTimerMin(isNaN(value) ? 0 : Math.min(Math.max(0, value), 99));
                      }}
                      min={0}
                      style={{
                        width: '80px',
                        height: '50px',
                        fontSize: 30,
                        paddingTop: '6px',
                        borderRadius: '8px',
                        backgroundColor: 'transparent',
                        outline: 'none',
                        color: 'white',
                        border: '1px solid #8BA9C9',
                      }}
                      id="" 
                    />
                    <span className="custom-input-icon-up cursor-pointer"
                      onClick={() => setTimerMin(Math.min(Math.max(0, timerMin + 1), 99))}
                      style={{
                        top: 0,
                        left: 60
                      }}>
                      <i className="fa-solid fa-chevron-up text-white"></i>
                    </span>
                    <span className="custom-input-icon-down cursor-pointer"
                      onClick={() => setTimerMin(Math.min(Math.max(0, timerMin - 1), 99))}
                      style={{
                        bottom: 0,
                        left: 60
                      }}>
                      <i className="fa-solid fa-chevron-down text-white"></i>
                    </span>
                  </div>

                  <label className='poppins-light me-9 text-white'>min</label>
                </div>
                <div className='col-6 d-flex flex-column mb-3'>
                  <div className="custom-input-wrapper">
                    <input 
                      type="number" 
                      name=""
                      className='custom-input-timer champ-light ps-2'
                      min={0}
                      value={timerSec??0}
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setTimerSec(isNaN(value) ? 0 : Math.min(Math.max(0, value), 60));
                      }}
                      style={{
                        width: '80px',
                        height: '50px',
                        fontSize: 30,
                        paddingTop: '6px',
                        outline: 'none',
                        borderRadius: '8px',
                        backgroundColor: 'transparent',
                        color: 'white',
                        border: '1px solid #8BA9C9',
                      }}
                      id="" 
                    />
                    <span className="custom-input-icon-up cursor-pointer"
                      onClick={() => setTimerSec(Math.min(Math.max(0, timerSec + 1), 60))}
                      style={{
                        top: 0,
                        left: 60
                      }}>
                      <i className="fa-solid fa-chevron-up text-white"></i>
                    </span>
                    <span className="custom-input-icon-down cursor-pointer"
                      onClick={() => setTimerSec(Math.min(Math.max(0, timerSec - 1), 60))}
                      style={{
                        bottom: 0,
                        left: 60
                      }}>
                      <i className="fa-solid fa-chevron-down text-white"></i>
                    </span>
                  </div>

                  <label className='poppins-light ms-9 text-white'>sec</label>
                </div>

                <div className='col-6 text-end'>
                    <button 
                      type='button' 
                      className='btn champ-bold bg-white px-3 py-1'
                      style={{
                        color: '#3f8bff',
                        fontSize: '15px',
                        borderRadius: '30px',
                        width: '80px'
                      }}
                      onClick={handleResetTimer}
                    >
                      restart
                    </button>
                </div>
                <div className='col-6'>
                    <button 
                      type='button' 
                      className='btn champ-light bg-white px-4 py-1'
                      style={{
                        color: '#3f8bff',
                        fontSize: '15px',
                        borderRadius: '15px',
                        width: '80px'
                      }}
                      onClick={handleStartTimer}
                    >
                      start
                    </button>
                </div>
              </div>
            </div>
          </div>}
          <img  src={MyX} style={{ width: '40px', height: '40px' }} onClick={() => setToolsTimer(false)}></img>
        </div>
        <style>
          {`
          .custom-input-wrapper {
            position: relative;
          }
          
          .custom-input-timer {
            -moz-appearance: textfield;
          }
          
          .custom-input-timer::-webkit-inner-spin-button,
          .custom-input-timer::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          
          .custom-input-icon-up,
          .custom-input-icon-down {
            position: absolute;
          }  
          `}
        </style>
    </>)
};

export {TimerToolComponent};
// import React from 'react';

// type Props = {
//   initialMinutes: number; // Añade una prop para el número inicial de minutos
// };

// const TimerToolComponent: React.FC<Props> = ({ initialMinutes }) => {

//   // setTimerChecked
//     const [timerMin, setTimerMin] = React.useState<number>(initialMinutes);
//     const [timerSec, setTimerSec] = React.useState<number>(0);
//     const [isTimeUp, setIsTimeUp] = React.useState<boolean>(false); 
//     React.useEffect(() => {
//       let timerInterval: NodeJS.Timeout;
  
//       if (!isTimeUp) {
//         timerInterval = setInterval(() => {
//           setTimerSec((prevSec) => {
//             if (prevSec === 0) {
//               if (timerMin === 0) {
//                 clearInterval(timerInterval);
//                 setIsTimeUp(true); // Actualiza el estado cuando el tiempo llega a cero
//                 return 0;
//               }
//               setTimerMin((prevMin) => prevMin - 1);
//               return 59;
//             }
//             return prevSec - 1;
//           });
//         }, 1000);
//       }
  
//       return () => clearInterval(timerInterval);
//     }, [timerMin, isTimeUp]);
  
//     // Función para formatear el tiempo en el temporizador
//     const formatTime = (value: number) => {
//       return value < 10 ? `0${value}` : value.toString();
//     };
  
//     return (
//       <>
//         <div className='champ-bold'>
//           <span>{formatTime(timerMin)}</span> : <span>{formatTime(timerSec)}</span>
//         </div>
//         <div className='champ-bold '>
//           {/* Mostrar estado del temporizador */}
//           {isTimeUp ? <p className='text-success'>Time's up!</p> : <p className='text-danger'>Counting down...</p>}
//         </div>
//       </>
//     );
//   };
  
//   export { TimerToolComponent };
