
import React, { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Advertisements } from '../../../admin/advertisements/interfaces';
import { UseAdvertisementsPublic } from '../../../admin/advertisements/services';
import { useThemeMode } from '../../../../../_metronic/partials';

type Props = {

}
const AdvertisementsCard: React.FC<Props> = ({ }: Props) => {
    const { mode } = useThemeMode()
    const [item, setItem] = React.useState<Advertisements[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const findAllItems = async () => {
        setLoading(true)
        let response = await UseAdvertisementsPublic();
        setItem(response)
        setLoading(false)
    }

    useEffect(() => {
        findAllItems()
    }, [])
    return (<>

        {loading ?
            <div className="d-flex justify-content-center align-items-center"
                style={
                    mode != 'dark' ? {
                        height: 350,
                        borderRadius: '50px',
                        background: 'white',
                        padding: 3
                    } : {
                        height: '100%',
                        borderRadius: '50px',
                        background: '#1e1e2d',
                        padding: 3,
                        border: `1px solid`,
                        borderColor: '#2d30e1'
                    }
                }>
                <div className="spinner-grow text-red" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            :
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" style={{ height: "100%" }} >
                <div className="carousel-indicators">
                    {item.map((x, index) => (
                        index < 1
                            ?

                            <button key={index} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className="active" aria-current="true" aria-label="Slide 1"></button>
                            :
                            <button key={index} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} aria-label="Slide 1"></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {item.map((x, index) => (
                        x.image && x.image.location &&

                        (index < 1 ?
                            <div className="carousel-item active" key={index}>
                                {x.url ? (
                                    <a href={x.url} target='_blank' rel='noopener noreferrer'>
                                        <img src={x.image.location} className="d-block w-100" height='375' style={{ borderRadius: 30 }} alt="..." />
                                    </a>
                                ) : (
                                    <img src={x.image.location} className="d-block w-100" height='375' style={{ borderRadius: 30 }} alt="..." />
                                )}
                            </div> :
                            <div className="carousel-item " key={index}>
                                {x.url ? (
                                    <a href={x.url} target='_blank' rel='noopener noreferrer'>
                                        <img src={x.image.location} className="d-block w-100" height='375' style={{ borderRadius: 30 }} alt="..." />
                                    </a>
                                ) : (
                                    <img src={x.image.location} className="d-block w-100" height='375' style={{ borderRadius: 30 }} alt="..." />
                                )}
                            </div>
                        )
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>}
    </>);
}
export { AdvertisementsCard }