import { useQuery, useQueryClient } from '@tanstack/react-query'
import { RoadMapStepByRoadMap, useRoadMapStepAllPaginate } from '../services'

export function useRoadMapStepState(){
  return  useQuery({ queryKey: ['road-map-step'], queryFn: useRoadMapStepAllPaginate })
}

export function useRoadMapStepById(id: string){
  return useQuery({ queryKey: ['road-map-step', id], queryFn: () => RoadMapStepByRoadMap(id) })
}
