
import useAuth from '../../../../api/main'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { catchServerError } from '../../../../hooks/useCatchError'
import { api } from '../../../auth/services/auth.services'
import { TemplateTableTimeDay } from '../../template-table-time/interfaces'
import { TemplateTimeItemValidateTeacher } from '../../template-table-time/modules/template-table-time-item/services'
import { TeacherBlockHoursData } from '../interfaces'

const { token, logout, authChecked } = useAuth.getState()

export const UseBlockHourAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/block-hour`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const TeacherProfileBlockHoursAll = async (term: string): Promise<any> => {
  try {
    const response = await api.get(`/auth/teacher-profile/${term}`, 
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    return response.data;
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const TeacherProfileBlockHoursAdd = async (data: TeacherBlockHoursData): Promise<any> => {
  try {
    const response = await api.patch(`/auth/teacher-profile`,
    {
      ...data
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data;
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const TeacherProfileTotalHours = async (data: any): Promise<any> => {
  try {
    const response = await api.patch(`/auth/teacher-profile-total-hours`,
    {
      ...data
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
   // showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data;
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UseTemplateAllByClassroomTeacher = async ({room, teacher, template, day}:TemplateTimeItemValidateTeacher): Promise<any> => {
  try {
    const response: any = await api.get(
      `/block-hour/template-all-by-classroom-teacher?teacher=${teacher}&room=${room}&template=${template}&day=${day}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const TeacherProfileByDay = async (term: string, day: TemplateTableTimeDay): Promise<any> => {
  try {
    const response = await api.get(`/auth/teacher-profile-by-day?root=${term}&day=${day}`, 
    {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    return response.data;
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}