
import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';
import './ExerciseFourteen.css'


type ExerciseFiveProps = {
  

}

const ExerciseFourteen: React.FC<ExerciseFiveProps> = ({}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const divStyle = {
    backgroundColor: '#3f8bff',
    width: '525px',
    height: 'auto',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 45px 20px'
  };
 

  const handleSelect = (option?) => {
    setSelectedOption(option);
  };
    //champ-bold    champ-light  poppins-light poppins-bold 
    return (
    <>  
     <div className="container-fluid" style={{display: 'flex'}}>
           <div style={divStyle}>
           <HeaderCard/>
                 <p className="text-white p-2 fs-8 mb-0 poppins-light" style={{marginLeft:'10px'}}>2. Chose the correct answer according to the previous text.
                 </p>
                 <div className="container text-white">
                    
                        <div className='col-12 '>

                            <div className='row p-2 '>
                                <div className="col-7">
                                    <li className="fs-8 poppins-light mb-0 "><strong>Thopson's role was...</strong></li>
                                </div>

                                <div className="col-2 " style={{ }}>
                                <div 
                                    className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                    onClick={() => handleSelect('a')}><span className='span-select'>challen</span>
                                    </div>
                                </div>

                                <div className="col-2"  style={{}}>
                                    <div 
                                    className={`fs-8 poppins-light mb-0  ${selectedOption === 'b' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                    onClick={() => handleSelect('b')}><span className='span-select'>raton</span>
                                    </div>
                                </div>
                                <div className="col-1">

                                </div>
                            </div>

                        </div>
                        <div className='col-12 '>

                        <div className='row p-2'>
                            <div className="col-7">
                                <li className="fs-8 poppins-light mb-0 "><strong>The movie location makes the </strong></li>
                            </div>

                            <div className="col-2 " style={{ }}>
                            <div 
                                className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                onClick={() => handleSelect('a')}><span className='span-select'>routirg</span>
                                </div>
                            </div>

                            <div className="col-2"  style={{}}>
                                <div 
                                className={`fs-8 poppins-light mb-0  ${selectedOption === 'b' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                onClick={() => handleSelect('b')}><span className='span-select'>routirg</span>
                                </div>
                            </div>
                            <div className="col-1">

                            </div>
                        </div>

                        </div>
                        <div className='col-12 '>

                        <div className='row p-2'>
                            <div className="col-7">
                                <li className="fs-8 poppins-light mb-0 "><strong>The seundtrack is...</strong></li>
                            </div>

                            <div className="col-2 " style={{ }}>
                            <div 
                                className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                onClick={() => handleSelect('a')}><span className='span-select'>routirg</span>
                                </div>
                            </div>

                            <div className="col-2"  style={{}}>
                                <div 
                                className={`fs-8 poppins-light mb-0  ${selectedOption === 'b' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                onClick={() => handleSelect('b')}><span className='span-select'>routirg</span>
                                </div>
                            </div>
                            <div className="col-1">

                            </div>
                        </div>

                        </div>
                        <div className='col-12 '>

                        <div className='row p-2'>
                            <div className="col-7">
                                <li className="fs-8 poppins-light mb-0 "><strong>The movie atmosphere is..</strong></li>
                            </div>

                            <div className="col-2 " style={{ }}>
                            <div 
                                className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                onClick={() => handleSelect('a')}><span className='span-select'>routirg</span>
                                </div>
                            </div>

                            <div className="col-2"  style={{}}>
                                <div 
                                className={`fs-8 poppins-light mb-0  ${selectedOption === 'b' ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                onClick={() => handleSelect('b')}><span className='span-select'>routirg</span>
                                </div>
                            </div>
                            <div className="col-1">

                            </div>
                        </div>

                        </div>
                 </div>  
           </div>
            <div className="position-relative" /*onClick={handleCheck}*/>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <div className='contenedor-imagen'>

            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
            </div>
       </div>
    </>
    );
};
export { ExerciseFourteen };
