import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { Evaluation, EvaluationItem } from '../../../../admin/evaluation/interfaces';
import { Lessons } from '../../../../abroad/learning-path/modules/lessons/interfaces';
import { setQuestionsEbook } from '../../../utils/setQuestionsEBook';
import { ExercisesType } from '../../../../admin/exercises/interfaces';
import { SkipInteractions } from '../services';
import { UseModeCourseModule } from '../../../../courses/components/front-page/store/main';
import { CurrentPage } from '../../test/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { AddTaskByStudentSettingsManual } from '../../../../abroad/trail/services';
import { TaskType } from '../../../../admin/tasks/interfaces';
import { OneTaskByFindOneTask } from '../../../../courses/exercises/basic-vocabulary/services';
import { setAnswersEbook } from '../../../utils/setAnswersEBook';

interface BookStudentModules {
    currentTaskId?: string;
    currentItemBook: EvaluationItem | null;
    stepBookItem:number;
    currentInteractiveItems: EvaluationItem[];
    evaluationBook?: Evaluation;
    lessonData?: Lessons;
    task?: any;
    statePage: 1 | 2;
    currentPage: 1 | 2;
    allAnswered: boolean;
    clickedInteractionsIds: string[];
    completedExerciseIds: string[];
    setStatePage:(value: 1 | 2)=>void;
    setCurrentsItemsTest:(value: EvaluationItem, skipExercise: boolean)=>void;
    setEvaluationBook:(value: Evaluation)=>void;
    setLessonData:(values: Lessons)=>void;
    setCurrentPage:(value: CurrentPage)=>void;
    setDeleteCurrentItem:(value: string)=>void;
    setCloseExercise:()=>void;
    setCurrentTask:(value: string) => void;
    setStepBookItem:(value: string)=>void;
    setAllAnswered:(value: boolean)=>void;
    addCompletedExerciseId: (id: string) => void;
    addClickedInteractionsId: (id: string) => void;
    setTask:(value: any)=>void;
    onChangeTaskByAnswer:(value: any, exerciseType: string) => void;
    refreshExercisesId:()=>void;
    resetCurrentItems:()=>void;
}

export const UseBookStudentModule = create(persist<BookStudentModules>( (set, get) => ({
    currentInteractiveItems: [],
    stepBookItem: 0,
    currentItemBook: null,
    currentPage: 1,
    statePage: 2,
    answeredItems: 0,
    clickedInteractions: 0,
    allAnswered: true,
    completedExerciseIds: [],
    clickedInteractionsIds : [],
    setTask:(value)=>{
        set({task: value});
    },
    onChangeTaskByAnswer:(value, exerciseType)=>{
        const currentTask = get().task;
        if (!(exerciseType in currentTask)) {
            return;
        }

        currentTask[exerciseType] = currentTask[exerciseType]?.map((x: any) => {
            if (x.id === value.id) {
                return value
            }
            return x
        });

        set({task: currentTask});
    },
    resetCurrentItems:()=>{
        set({currentItemBook: null, currentInteractiveItems: []});
    },
    setCloseExercise:()=>{
        set({currentItemBook: null})
    },
    setCurrentsItemsTest:(value, skipExercise)=>{
        const findItem = get().currentInteractiveItems.find(x => x.id === value.id);
        const findTypeExercise = get().currentInteractiveItems.find(x => x.exercise?.type === value.exercise?.type);
        let currentItems: EvaluationItem[]; 

        if (findItem) {
            currentItems = [...get().currentInteractiveItems];
        } else if (findTypeExercise) {
            currentItems =[...get().currentInteractiveItems.filter(x => x.id !== findTypeExercise.id), value]
        } else {
            currentItems = skipExercise ? [value] : [...get().currentInteractiveItems, value];
        }
        let totalInteractionIds: string[] = [];
        currentItems?.forEach((x) => {
            if (x.exercise.type === ExercisesType.AUDIO_INTERACTION) {
                totalInteractionIds.push(x.exercise.id)
            }
            setQuestionsEbook(x);
            const findExercise = get().completedExerciseIds.find((exercise) => exercise === x.exercise.id);
            if (findExercise) {
                setAnswersEbook(get().task, x.exercise.type, findExercise);
            } 
        });
        set({currentInteractiveItems: currentItems, clickedInteractionsIds: totalInteractionIds });
    },
    setEvaluationBook:(value)=>{
        set({evaluationBook: value});
    },
    setLessonData:(value)=>{
        set({lessonData: value});
    },
    setStatePage:(value)=>{
        set({statePage: value});
    },
    setCurrentPage:async(value: CurrentPage)=> {
        /* 
            @param Value 0 es volver a la leccion anterior
            Value 1 es poner la primera pagina
            Value 2 es poner la segunda pagina
            Value 3 es pasar a la siguiente leccion
        */  
        const lessonId = get().lessonData
        const settings = UseMyRoadmapModule.getState().settings 
        if (lessonId && lessonId.book_pages && lessonId.book_pages.length > 0) {
            const lessons = UseModeCourseModule.getState().lessonsId;
            let currentChapter: undefined | any = undefined;
            if (lessons && lessonId) {
                currentChapter = lessons.find(x => x.id === lessonId.id)
            }
            if (value === 3) { 
                if (currentChapter && lessons) {
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
                    let nextLesson = chapterLessons.find(x => x.order > currentChapter.order);
                    if (nextLesson) {
                        if (nextLesson.statusTask === 'NOT_TASK') {
                            try{
                                //añadir la siguiente tarea
                                await AddTaskByStudentSettingsManual({
                                    code: 'xx',
                                    description: '',
                                    isActive: true,
                                    lesson: nextLesson.id,
                                    student_setting: settings?.id ?? 'xx',
                                    isArtificial: false,
                                    type: TaskType.NORMAL
                                });
                                showSimpleAlert({icon: 'success', message: 'lección completada'})
                            } catch (e){
                                console.error(e)
                                showSimpleAlert({icon: 'error', message: 'Error al crear la siguiente tarea'})
                            }
                        }
                        if(nextLesson.typeScreen === 'BOOK'){
                            window.location.replace(`container?lesson=${nextLesson.id}&mode=STUDENT`);
                        } else {
                            window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                        }
                    } else {
                        // Buscar que exista el próximo capítulo
                        const nextChapter = lessons.filter(x => x.chapter_order > currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        
                        if (nextChapter) {
                            // Filtrar y ordenar las lecciones del próximo capítulo
                            const nextChapterLessons = lessons.filter(x => x.chapter_order === nextChapter.chapter_order).sort((a, b) => a.order - b.order);
                            nextLesson = nextChapterLessons.find(x => x.order >= 0);
                            if (nextLesson) {
                                if(nextLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`container?lesson=${nextLesson.id}&mode=STUDENT`);
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones posteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({ message: 'No hay lecciones posteriores', icon: 'info' });
                        }
                    }
                }
            } 
            else if ( value === 0){
                if( currentChapter && lessons){
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => b.order - a.order);
                    let prevLesson = chapterLessons.find(x=> x.order < currentChapter.order)
                    if (prevLesson){
                        if(prevLesson.typeScreen === 'BOOK'){
                            window.location.replace	(`container?lesson=${prevLesson.id}&mode=STUDENT`)
                        } else {
                            window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                        }
                    } else {
                        const prevChapter = lessons.filter(x => x.chapter_order < currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        // const prevChapter = lessons.find(x=> x.order === 1 && x.chapter_order === currentChapter.order -1)
                        if (prevChapter) {
                            const prevChapterLessons = lessons.filter(x => x.chapter_order === prevChapter.chapter_order).sort((a, b) => b.order - a.order);
                            prevLesson = prevChapterLessons[0];  // Tomar la última lección del capítulo anterior
                            if (prevLesson) {
                                if(prevLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`container?lesson=${prevLesson.id}&mode=STUDENT`);
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones anteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({message: 'No hay lecciones anteriores', icon: 'info'})	
                        }
                    }
                } 
            } else {
                set({currentPage: value})
                return
            }
        }
        set({currentPage: 1});
    },
    setDeleteCurrentItem:(value)=> {
        const filteredItems = get().currentInteractiveItems.filter(x=>x.id !== value)
        set({currentInteractiveItems: filteredItems})
    },
    setCurrentTask:(value)=>{
        set({currentTaskId: value});
    },
    setStepBookItem:(value)=>{
        set((state) => {
            let nextExercise: EvaluationItem | undefined = undefined;
            if (state.lessonData && state.lessonData.evaluation && state.lessonData.evaluation.evaluation_item) {
                const current = state.lessonData.evaluation.evaluation_item.find(x => x.exercise?.id === value);
                if (current) {
                    nextExercise  = state.lessonData.evaluation.evaluation_item.sort((a, b) => a.order - b.order).find(x => x.order > current.order);
                }
            }   
            
            if (nextExercise) {
                if(nextExercise?.exercise.type===ExercisesType.AUDIO_INTERACTION && state.lessonData && state.lessonData.evaluation && state.lessonData.evaluation.evaluation_item){
                    const skipInteractions = SkipInteractions(nextExercise, state.lessonData.evaluation.evaluation_item)
                    // set({currentInteractiveItems: skipInteractions?.itemsArray ??[]});
                    if (skipInteractions?.itemsArray) {
                        skipInteractions.itemsArray.map(x => {
                            get().setCurrentsItemsTest(x, false);
                        })
                    }
                    setQuestionsEbook(skipInteractions?.newCurrent??nextExercise)
                    nextExercise = skipInteractions?.newCurrent??nextExercise
                } else {
                    setQuestionsEbook(nextExercise);
                }
                get().setCurrentsItemsTest(nextExercise, true);
            }
            return {}
        });
    },
    setAllAnswered:(value)=>{
        set({allAnswered: value})
    },
    addCompletedExerciseId: async(value) => {
        
        //Crear una tarea en el momento que se cumple el ultimo ejercicio
        const lessonData = get().lessonData
        const isIncluded = get().completedExerciseIds.some(x => x === value);
        const answeredExercises = get().completedExerciseIds.length
        const exercisesLength = lessonData?.evaluation?.evaluation_item?.filter( (x) => x.exercise.type !== ExercisesType.AUDIO_INTERACTION).length ?? 0
        if(!isIncluded && exercisesLength === answeredExercises + 1){
            const lessonId = get().lessonData
            if (lessonId && lessonId.book_pages && lessonId.book_pages.length > 0) {
                const lessons = UseModeCourseModule.getState().lessonsId;
                let currentChapter: undefined | any = undefined;
                if (lessons && lessonId) {
                    currentChapter = lessons.find(x => x.id === lessonId.id)
                }
                
                if (currentChapter && lessons) {
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
                    let nextLesson = chapterLessons.find(x => x.order > currentChapter.order);
                    
                    if (nextLesson && nextLesson.statusTask === 'NOT_TASK') {
                        // try{
                        //     //añadir la siguiente tarea
                        //     await AddTaskByStudentSettingsManual({
                        //         code: 'xx',
                        //         description: '',
                        //         isActive: true,
                        //         lesson: nextLesson.id,
                        //         student_setting: settings?.id ?? 'xx',
                        //         isArtificial: false,
                        //         type: TaskType.NORMAL
                        //     });
                        //     showSimpleAlert({icon: 'success', message: 'lección completada'})
                        // } catch (e){
                        //     console.error(e)
                        //     showSimpleAlert({icon: 'error', message: 'Error al crear la siguiente tarea'})
                        // }
                    }
                }
            }
            const taskId = get().currentTaskId
            if (taskId) {
                await OneTaskByFindOneTask(taskId);
            }
        }
        set((state) => {
            const updatedIds = state.completedExerciseIds.includes(value)
            ? state.completedExerciseIds
            : [...state.completedExerciseIds, value];
            updatedIds.filter(x=>x !== null)
            return {
                completedExerciseIds: updatedIds,
                answeredItems: updatedIds.length, 
            }
        });
    },
    addClickedInteractionsId: (value) => {
        if(value === '') return
        set((state)=> {
            const updatedIds = state.clickedInteractionsIds.includes(value)
            ? state.clickedInteractionsIds
            : [...state.clickedInteractionsIds, value];
            updatedIds.filter(x=>x !== null)
            return {
                clickedInteractionsIds: updatedIds,
                answeredItems: updatedIds.length, 
            }
        })
    },
    refreshExercisesId:() => {
        set({completedExerciseIds: [], clickedInteractionsIds:[]})
    }
    }),
    {
        name: 'content-book'
    }
));

