import React from 'react';
import { TrueWordsItem } from './components/item';
import { UseTrueWordsModule} from './store';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';


type Props = {
    userMode?: ModeTypeExercise
};

const TrueWords: React.FC<Props> = ({ userMode }) => {
    let {
        questions,
        answer,
    } = UseTrueWordsModule();

    return (
        <>
            <TrueWordsItem
                userMode={userMode ?? 'ADMIN'}
                responses={answer}
                question={questions}
            />
        </>
    );
};

export { TrueWords };