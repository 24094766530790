export interface DataPaginateDragDropQuestionsResponse {
    status:  string;
    message: string;
    data:    DataDragDropQuestions;
}

export interface DataAllDragDropQuestionsResponse {
    status:  string;
    message: string;
    data:    DragDropQuestions[];
}

export interface DataDragDropQuestions {
    id?: string;
    description?:string;
    type: TypeDragDrop;
    name:string;
    isActive: boolean;
    sentences: string[]
    words: string[];
    exercises: any;
    createdAt?:string;
    updatedAt?:string;
}

export interface DragDropQuestions  {
    id?: string;
    description?:string;
    type: TypeDragDrop;
    name:string;
    isActive: boolean;
    sentences: string[]
    words: WordDragDropIE[];
    exercises: any;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: DragDropQuestions[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface WordDragDropIE {
    id :string
    text:string
}

export enum TypeDragDrop {
    WORD_BOX_V1 = 'WORD_BOX_V1',
    WORD_BOX_V2 = 'WORD_BOX_V2',
    WORD_BOX_PARAGRAPH = 'WORD_BOX_PARAGRAPH'
}