import {Route, Routes} from 'react-router-dom'
import { NotificationListGlobal } from '../components/list-global'

const NotificationGlobalPage = () => (
  <Routes>
      <Route path='/' element={<NotificationListGlobal />} />
  </Routes>
)

export {NotificationGlobalPage}
