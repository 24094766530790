import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { TrailSuspenseAdd, TrailSuspenseUpdate } from '../../services'
import { UseTrailSuspenseModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import MultipleFileInput from '../../../../files/components/multiple-files'
import { TrailSuspenseReason } from '../../../../abroad/trail/trail-suspense-reason/interfaces'
import { UseTrailSuspenseReasonAll } from '../../../../abroad/trail/trail-suspense-reason/services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'

export type FormFields = {
  description: string;
  name: string;
  class?: string;
  reason?: string;
  files?: any[];
  isActive: boolean;
  isApprove: boolean;
}

const entitySchema = Yup.object().shape({
  reason: Yup.string().required('First name is required')

})

type Props = {
  class?: string;
  mode: 'ADD' | 'EDIT' | 'VIEW';
  onSubmit:() => void;
  
}
const TrailSuspenseForm: React.FC<Props> = (props: Props) => {

  const [reasons, setReasons] = useState<TrailSuspenseReason[]>([])
  const queryClient = useQueryClient()
  const { itemSelected, mode,trail_class, resetData } = UseTrailSuspenseModule();
  const [files, setFiles] = useState<any[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        reason: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.reason ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        isApprove: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isApprove ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD' && trail_class) { 
          try {
              Swal.fire({
                title: '¿Estas seguro?',
                html: "<p class='poppins-light'>Estos cambios son irreversibles</p>",
                icon: 'warning',
                iconColor: '#2d30e1',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
                cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
                customClass: {
                  title: 'champ-light',
                  confirmButton: 'confirm-button-sweet-alert',
                  cancelButton: 'cancel-button-sweet-alert',
                }
              }).then(async(result) => {
                if (result.isConfirmed) {
                  try{
                    setLoading(true);
                    await TrailSuspenseAdd({ 
                        ...formData,
                        class:trail_class,
                        files: files.map(x => x.id)
                    })
                    resetData();
                    props.onSubmit()
                    setLoading(false)
                    queryClient.invalidateQueries({ queryKey: ['trail-suspense'] })
                    queryClient.invalidateQueries({ queryKey: ['me-booking-active'] })
                  }catch(e){
                      
                  }
                }
              })


          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await TrailSuspenseUpdate({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['trail-suspense'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })
  const fetchReasons = async () => {
    try {
      let x = await UseTrailSuspenseReasonAll()
      setReasons(x)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Nivles!', icon: 'error' })
      throw 'Error'
    }
  }
  useEffect(() => {
    fetchReasons();
  }, []);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row poppins-light'>
          {props.mode != 'ADD' && <>
            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Activo ?</label>
              <div className="form-check form-switch">
                <input
                  name={'isActive'}
                  checked={values.isActive}
                  value={values.isActive ? 1 : 0}
                  onChange={handleChange}
                  className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              </div>
            </div>
            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Aprobado ?</label>
              <div className="form-check form-switch">
                <input
                  name={'isApprove'}
                  checked={values.isApprove}
                  value={values.isApprove ? 1 : 0}
                  onChange={handleChange}
                  className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              </div>
            </div>
            <div className='mb-10 col-12'>
              <label className='form-label mb-3'>Name</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'name'}
                value={values.name}
                onChange={handleChange}
              />
              {touched.name && errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.name}</span>
                  </div>
                </div>
              )}
            </div>
          </>}

          <div className='mb-1 col-12'>
            <label className='form-label mb-3'>Motivo de cancelamiento</label>
            <select
              disabled={mode === 'VIEW'}
              name={'reason'}
              value={values.reason}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option value={undefined}></option>
              {reasons.map((x) =>
                (<option key={x.id} value={x.id}>{`${x.name}`}</option>)
              )}
            </select>
            {errors.reason && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.reason}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Descripción</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Archivos de soporte</label>
            <MultipleFileInput
              files_={files}
              onChangeFile={(e) => { setFiles(files.concat(e)) }}
              onChangeLoading={(e) => console.log(e)}
              onDeleteFile={(e) => {
                alert(e)
                setFiles(prevFiles => {
                  const updatedFiles = prevFiles.filter((_, index) => index !== e);
                  return updatedFiles;
                });
              }}
            />
          </div>

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>´
        </div>
      </form>
    </div>
  )
}

export { TrailSuspenseForm }
