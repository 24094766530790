 import {create} from 'zustand';
import { QuestionOrderSentences,  QuestionTrueOptionsIE,  quiz  } from '../interfaces';

interface Answer {
  title: string;
  sentences: string;
  order: number
  letter: string;
}

interface TrueOptionsInterface {
  questions:QuestionTrueOptionsIE
  title:string;
  responseStudent?:Answer[];
  instructions:string;
  trueAnswer:QuestionOrderSentences[]
  falseAnswer:QuestionOrderSentences[]
  percentage:number
  exerciseId?:string;
  resetData:()=>void;
  setQuestions:(
    exerciseId: string, 
    instruction: string,
    value: QuestionTrueOptionsIE)=>void;
  setResponseStudent:(value: Answer[])=>void;
}

export const UseTrueOptionsModule = create<TrueOptionsInterface>( (set) => ({
    status:'ANSWER',
    title:"Este es un titulo de quiz",
    instructions:"",
    questions:{
      options: [],
      sentences: []
    },
    trueAnswer:[],
    falseAnswer:[],
    percentage:0,
    setTitle:(value)=>{},
    resetData:()=>{},
    setQuestions:(exerciseId, instructions, value)=>{
      set({exerciseId, questions: value, instructions});
    },
    setResponseStudent:(value)=>{
      set({responseStudent: value});
    }
}));