 import {create} from 'zustand';
import { Answer, QuestionBasicVocabulary, quiz,QuestionAndAnswer, questions } from '../interfaces';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';
interface TitleAndInstruction {
  title: string;
  instruction: string;
}
interface BasicVocabularyInterface {
  status:StatusQuiz
  questions:QuestionBasicVocabulary[]
  title:string;
  instructions:string;
  step:number;
  trueAnswer:QuestionAndAnswer[]
  falseAnswer:QuestionAndAnswer[]
  list:Answer[]
  total:number
  mode:'IMAGE'|'WORDS'
  setAnswer:(Answer)=>void;
  setStatus:(StatusQuiz)=>void
  setStep:(number)=>void;
  startQuiz:()=>void;
  setQuestionsBasic:(value:QuestionBasicVocabulary[])=>void;
  setTitle:(value: TitleAndInstruction)=>void;
  resetData:()=>void;
}

export const UseBasicVocabularyModule = create<BasicVocabularyInterface>( (set) => ({
    status:'TITLE',
    title:"Este es un titulo de quiz",
    instructions:"esta es una insturccion",
    questions:[],
    step:0,
    trueAnswer:[],
    falseAnswer:[],
    list:[],
    total:0,
    mode:'WORDS',
    setAnswer:(value:Answer)=>{
      let step:number=UseBasicVocabularyModule.getState().step;
      let currentQuestion:QuestionBasicVocabulary =UseBasicVocabularyModule.getState().questions[step];
      let questionsLength:number=UseBasicVocabularyModule.getState().questions.length;
      let trueAnswer:QuestionAndAnswer[]=UseBasicVocabularyModule.getState().trueAnswer;
      let falseAnswer:QuestionAndAnswer[]=UseBasicVocabularyModule.getState().falseAnswer;
      let list:Answer[]=UseBasicVocabularyModule.getState().list;
      let status:StatusQuiz ='ANSWER';
      if(questionsLength===(step+1)){
        status = 'RESUME'
      }
      if(value.isTrue){
        trueAnswer.push({...currentQuestion,answer:value})
        list.push(value)
        set({
          trueAnswer:trueAnswer,
          step:step+1,
          status:status
        })
      }
      if(!value.isTrue){
        falseAnswer.push({...currentQuestion,answer:value})
        list.push(value)
        set({
          falseAnswer:falseAnswer,
          step:step+1,
          status:status
        })
      }
    },
    setStep:(value:number)=>{
      let step:number=UseBasicVocabularyModule.getState().step;
      let questionsLength:number=UseBasicVocabularyModule.getState().falseAnswer.length;
      let status:StatusQuiz ='THROW_ERROR';
      if(questionsLength===value){
        status = 'RESUME'
      }
      set({
        step:value,
        status:status
      })
    },
    startQuiz(){
      set({
        status:'ANSWER'
      })
    },
    setStatus:(value:StatusQuiz)=>{
      if(value==='ANSWER'){
        set({
          trueAnswer:[],
          falseAnswer:[],
          step:0,
          status:'ANSWER'
        })
      }
      if(value==='THROW_ERROR'){
        set({
          step:0,
          status:'THROW_ERROR'
        })
      }
      if(value==='RESUME'){
        set({
          step:0,
          status:'RESUME'
        })
      }
    },
    setQuestionsBasic:(value)=>{
      set({questions: value})
    },
    setTitle:(value)=>{
      set({title: value.title, instructions: value.instruction})
    },
    resetData:()=>{
      set({
        status:'TITLE',
        title:"Este es un titulo de quiz",
        instructions:"esta es una insturccion",
        questions:questions,
        step:0,
        trueAnswer:[],
        falseAnswer:[],
        list:[],
        total:0,
      })
    }
}));