interface Quiz {
    theme:string
    title:string
    questions:QuestionBasicVocabularyCompletion[]
    instructions:string
}
export interface QuestionBasicVocabularyCompletion {
    description: string
    name :string
    order:number
    answers:Answer[]
    timer:number
}

export interface QuestionAndAnswer {
    name :string
    order:number
    answers:Answer[],
    timer:number
    answer:Answer
}

export interface Answer {
    title :string[]
    order:number
    isTrue:boolean
    feedback: string
}

export interface Resume {
    step:number
    trueAnswer:Answer[]
    falseAnswer:Answer[]
    list:Answer[], 
    total:number
}


export const questions: QuestionBasicVocabularyCompletion[] = [
    {
        name: "París es la capital de Francia ?",
        order: 1,
        timer: 30,
        description: 'revisa',
        answers: [
            { title: ["París","Francia"], order: 1, isTrue: true , feedback: ""},
            { title: ["Madrid"], order: 2, isTrue: false, feedback: ""},
            { title: ["Londres"], order: 3, isTrue: false, feedback: ""},
            { title: ["Berlín"], order: 4, isTrue: false, feedback: ""},
        ],
    },
    {
        name: "¿La tierra es el tercer planeta del sistema solar?",
        order: 2,
        timer: 30,
        description: 'revisa',
        answers: [
            { title: ["Jupiter"], order: 1, isTrue: false , feedback: ""},
            { title: ["Tierra"], order: 2, isTrue: true , feedback: ""},
            { title: ["Saturno"], order: 3, isTrue: false , feedback: ""},
            { title: ["Neptuno"], order: 4, isTrue: false , feedback: ""},
        ],
    },
    {
        name: "¿El río Amazonas es el rio más largo del mundo?",
        order: 3,
        timer: 30,
        description: 'revisa',
        answers: [
            { title: ["Nilo"], order: 1, isTrue: false , feedback: ""},
            { title: ["Amazonas"], order: 2, isTrue: true , feedback: ""},
            { title: ["Orinoco"], order: 3, isTrue: false , feedback: ""},
            { title: ["Meta"], order: 4, isTrue: false , feedback: ""},
        ],
    },
 
];

export const quiz :Quiz={
    theme:"Vocabulary",
    title:"Titulo del quiz que va a empezar",
    instructions:"Esta es una instrucion para hacer testing en el quiz que necesito entregar hoy.",
    questions:questions
}