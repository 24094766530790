import {create} from 'zustand';
import { SupportTicket, Pagination, SupportTicketStatus, SupportTicketPriority } from '../interfaces';


interface SupportTicketModules {
    ticketStatus?: SupportTicketStatus,
    ticketPriority?: SupportTicketPriority,
    itemSelected?: SupportTicket,
    root?: string,
    mode:'ADD'|'EDIT'|'VIEW'
    modal:boolean,
    modalInteractive:boolean,
    isActive:boolean,
    paginator:Pagination
    where:string
    currentPage:number
    limit:number
    total:number
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setSelectedToEdit:(item:SupportTicket)=>void;
    setSelectedToView:(item:SupportTicket)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setLimit:(value:number)=>void,
    setWhere:(value:string)=>void,
    setToAdd:()=>void,
    setTicketStatus:(value?: SupportTicketStatus)=>void,
    setTicketPriority:(value?: SupportTicketPriority)=>void,
    setModalInteractive:(value:boolean)=>void
    setInteractive:(value: SupportTicket)=>void,
    setRoot:(value:string)=>void,
}

export const UseSupportTicketModule = create<SupportTicketModules>( (set) => ({
    mode:'ADD',
    modal:false,
    modalInteractive:false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    total:0,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT',modal:true})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false,modalInteractive:false})
    },
    setPaginator:(pagination)=>{
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setTicketStatus(value) {
        set({ticketStatus: value})
    },
    setTicketPriority(value) {
        set({ticketPriority: value})
    },
    setModalInteractive(value) {
        set({modalInteractive:value})
    },
    setInteractive(value) {
        set({itemSelected:value})
    },
    setRoot(value) {
        set({root: value})
    },
}));