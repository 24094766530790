import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { Booster } from '../../interfaces'
import { BoosterAdd, BoosterUpdate } from '../../services'
import { UseBoosterModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  name: string
  isActive: boolean
  description?: string;
  sentences: string[];
  level: TraditionalLevelEnum;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const BoosterForm: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseBoosterModule();
  const [dataSentence, setDataSentence] = useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        sentences: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sentences ?? [] : [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await BoosterAdd({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['booster'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await BoosterUpdate({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['booster'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });

  const addSentence = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (dataSentence === '') return; 
    setFieldValue('sentences', [...values.sentences, dataSentence]);
    setDataSentence('');
  };

  const deleteSentence = (sentence: string) => {
    const newSentences = values.sentences.filter(x => x !== sentence);
    setFieldValue('sentences', newSentences);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              name='level'
              className='form-select'
              aria-label='Default select example'
              value={values.level}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
              <option value=''></option>
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Answers</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'sentence'}
              value={dataSentence}
              onChange={(e) => setDataSentence(e.target.value)}
            />
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addSentence} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Sentence</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='scroll-y' style={{maxHeight: '10px'}}>
                {values.sentences.length > 0 && values.sentences.map((x, i) => (
                  <tr key={i}>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x}
                            </a>
                          </div>
                        </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteSentence(x)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { BoosterForm }
