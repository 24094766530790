/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {UseBlockHourModule } from '../../store/main'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { BlockHoursTeacherForm } from '../forms'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const {modal, mode,setModal,resetData} = UseBlockHourModule();
  const submit = () => {
    window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        {mode === 'MONDAY' && <h2>Monday</h2>}
        {mode === 'TUESDAY' && <h2>Tuesday</h2>}
        {mode === 'WEDNESDAY' && <h2>Wednesday</h2>}
        {mode === 'THURSDAY' && <h2>Thursday</h2>}
        {mode === 'FRIDAY' && <h2>Friday</h2>}
        {mode === 'SATURDAY' && <h2>Saturday</h2>}

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModal(false, 'MONDAY')}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <BlockHoursTeacherForm mode={mode} />
    </Modal>,
    modalsRoot
  )
}

export {CreateModal}