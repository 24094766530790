import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {TrueOption, TrueOptionItem, TrueOptionSentence, TrueOptionType} from '../../interfaces'
import { TrueOptionAdd, TrueOptionUpdate } from '../../services'
import { UseTrueOptionModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'
import { KTIcon } from '../../../../../../../../../_metronic/helpers'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  level: TraditionalLevelEnum;
  type: TrueOptionType;
  sentences: TrueOptionSentence[];
  options: TrueOptionItem[];
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TrueOptionForm: React.FC<Props>  = (props:  Props) => {
  const {id} = useParams();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {itemSelected, mode,resetData} = UseTrueOptionModule();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [viewItems, setViewItems] = React.useState({
    sentences: false,
    options: false
  });
  const [dataOptions, setDataOptions] = React.useState<TrueOptionItem>({
    title: '',
    order: 1,
    letter: 'A'
  });
  const [dataSentences, setDataSentences] = React.useState<TrueOptionSentence>({
    title: '',
    letter: 'A'
  });

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? TrueOptionType.COMPLETE_SENTENCE : TrueOptionType.COMPLETE_SENTENCE,
        sentences: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sentences?.map(x => JSON.parse(x)) ?? [] : [],
        options: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.options?.map(x => JSON.parse(x)) ?? [] : []
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await TrueOptionAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['true-option'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  TrueOptionUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['true-option'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });
  
  const handleAddOption = () => {
    const findOrderOrLetter = values.options.find(x => x.order === dataOptions.order || x.letter === dataOptions.letter);
    if (findOrderOrLetter && findOrderOrLetter.order === dataOptions.order) 
      return showSimpleAlert({message: `La opcion "${findOrderOrLetter.title} ya tiene el orden: ${dataOptions.order}"`, icon: 'error'});
    if (findOrderOrLetter && findOrderOrLetter.letter === dataOptions.letter) 
      return showSimpleAlert({message: `La opcion "${findOrderOrLetter.title} ya tiene la letra: ${dataOptions.letter}"`, icon: 'error'})
    setFieldValue('options', [...values.options, dataOptions]);
    setDataOptions({
      title: '',
      order: 1,
      letter: 'A'
    });
  };

  const handleAddSentence = () => {
    const findLetter = values.sentences.find(x => x.letter === dataSentences.letter);
    if (findLetter) return showSimpleAlert({message: `La sentencia "${findLetter.title}" ya tiene la letra: ${dataSentences.letter}`, icon: 'error'})
    setFieldValue('sentences', [...values.sentences, dataSentences]);
    setDataSentences({
      title: '',
      letter: 'A'
    });
  };

  const deleteSentence = (letter: string) => {
    const deleteItem = values.sentences.filter(x => x.letter !== letter);
    const deleteOption = values.options.filter(x => x.letter !== letter);
    //Preguntar si hay opciones o sentencias trampa
    setFieldValue('sentences', deleteItem);
    setFieldValue('options', deleteOption);
  };

  const handleDeleteOption = (order: string) => {

  };

  const truncateText = (text: string) => {
    if (text.length > 150) {
      return text.substring(0, 150) + '...';
    }
    return text;
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Tipo</label>
            <select
              disabled={mode === 'VIEW'}
              name='type'
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TrueOptionType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.type}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn'
              onClick={() => setViewItems({sentences: false, options: false})}
            >
              Lista
            </button>
            <div className='d-flex'>
              <button type='button' className='btn'
                onClick={() => setViewItems({sentences: false, options: true})}
              >
                Columna a
                <KTIcon iconName='plus' className='fs-3' />
              </button>
              <button type='button' className='btn'
                onClick={() => setViewItems({sentences: true, options: false})}
              >
                Columna b
                <KTIcon iconName='plus' className='fs-3' />
              </button>
            </div>
          </div>
          {/* ------ */}
          {!viewItems.options && !viewItems.sentences && <>
            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Columna a</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.options.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {truncateText(x.title)}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                Respuesta: {x.letter}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteSentence(x.letter)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Columna b</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.sentences.map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {truncateText(x.title)}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                Valor: {x.letter}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteSentence(x.letter)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>     
          </>}

          {viewItems.sentences && <>
            <div className='mb-10 col-6'>
              <label className='form-label mb-3 fw-bold'>Columna b</label>
              <div className='mb-2 col-12'>
                <label className='form-label mb-2'>Sentencia</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={dataSentences.title}
                  onChange={(e) => setDataSentences(prev => ({...prev, title: e.target.value}))}
                />
              </div>
              <div className='mb-2 col-12'>
                <label className='mb-2 form-label'>Letra</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={dataSentences.letter}
                  onChange={(e)=> setDataSentences(prev=>({...prev, letter: e.target.value.toUpperCase()}))}
                />
              </div>
              
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' onClick={handleAddSentence} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Pregunta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.sentences.map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {truncateText(x.title)}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {x.letter}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteSentence(x.letter)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}
          {viewItems.options && <>
            <div className='mb-10 col-6'>
              <label className='form-label mb-3 fw-bold'>Preguntas</label>
              <div className='mb-2 col-12'>
                <label className='form-label mb-2'>Pregunta</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={dataOptions.title}
                  onChange={(e) => setDataOptions(prev => ({...prev, title: e.target.value}))}
                />
              </div>
              <div className='mb-2 col-12'>
                <label className='mb-2 form-label'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={dataOptions.order}
                  onChange={(e)=> setDataOptions(prev=>({...prev, order: parseInt(e.target.value)}))}
                />
              </div>
              <div className='mb-2 col-12'>
                <label className='mb-2 form-label'>Sentencia asignada</label>
                <select
                  disabled={mode === 'VIEW'}
                  name=''
                  value={dataOptions.letter}
                  onChange={(e) => setDataOptions(prev => ({...prev, letter: e.target.value}))}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {values.sentences.map((x) => (
                    <option key={x.letter} value={x.letter}>
                      {`${x.letter} - ${x.title}`}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className='mb-2 col-12'>
                <label className='mb-2 form-label'>Color</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={dataAnswer.color}
                  onChange={(e)=> setDataOptions(prev=>({...prev, color: e.target.value}))}
                />
              </div>  */}
              
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' onClick={handleAddOption} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Pregunta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.options.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {truncateText(x.title)}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {x.letter}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteSentence(x.letter)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}

          

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {TrueOptionForm}
