export const SidebarTranslateEn = {
    PAYMENTS:"Payments",
    TESTS: "Tests",
    CONSTANCY:"Constancy",
    FREEZING:"Freezing",
    SCHEDULE_HISTORY:"Schedule History",
    CHAT:"Chat",
    SUPPORT:"Support",
    ATTENDANCE: "Attendance",
    LEARNING_PATH: "Learning path"
  }