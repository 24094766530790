import {Route, Routes} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { BookingLayout } from '../../booking/layout'
import { LobbyContainer } from '../container'
 
 
 

const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Wizards',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const LobbyClassPage = () => (
  <Routes>
    <Route element={<BookingLayout />}>
      <Route path='/:id' element={<>
      <PageTitle breadcrumbs={wizardsBreadCrumbs}>Looby</PageTitle>
            <LobbyContainer className=''/>
        </>
        } />
      </Route>
  </Routes>
)

export {LobbyClassPage}
