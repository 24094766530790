import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { MovieWritingData } from '../store';


type Props = {
    movie: MovieWritingData;
    handleChangeMovie:(value: MovieWritingData)=>void;
    name?: string;
    description?:string;
};

const HistoryWritingItem: React.FC<Props> = ({handleChangeMovie, movie,name, description}: Props) => {

    return (<div className='p-5 d-flex justify-content-center align-items-start flex-column w-100 h-100'>
        <div className='row' style={{paddingLeft:10,marginBottom:30}}>
            <div className="row align-items-center p-0" style={{margin:0}}>
                <div className="col-3 p-0 ">
                    <label className="col-form-label poppins-light text-white p-0">Title of the movie:</label>
                </div>
                <div className="col-5 p-0 m-0">
                    <input
                        type="text"
                        className="form-control poppins-light  text-white"
                        aria-describedby="passwordHelpInline"
                        value={movie.title}
                        onChange={(e) => 
                            handleChangeMovie({
                                ...movie,
                                title: e.target.value
                            })
                        }
                        style={{
                            marginTop:0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height:10,
                            boxShadow: 'none' // Asegura que no haya sombra en el input
                        }}
                        />
                </div>
            </div>
            <div className="row align-items-center p-0" style={{margin:0}}>
                <div className="col-3 p-0 ">
                    <label className="col-form-label poppins-light text-white p-0">Genre:</label>
                </div>
                <div className="col-5 p-0 m-0">
                    <input
                        type="text"
                        className="form-control poppins-light  text-white"
                        aria-describedby="passwordHelpInline"
                        value={movie.genre}
                        onChange={(e) => 
                            handleChangeMovie({
                                ...movie,
                                genre: e.target.value
                            })
                        }
                        style={{
                            marginTop:0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height:10,
                            boxShadow: 'none' // Asegura que no haya sombra en el input
                        }}
                        />
                </div>
            </div>
            <div className="row align-items-center p-0" style={{margin:0}}>
                <div className="col-3 p-0 ">
                    <label className="col-form-label poppins-light text-white p-0"> Duration:</label>
                </div>
                <div className="col-5 p-0 m-0">
                    <input
                        type="text"
                        className="form-control poppins-light  text-white"
                        aria-describedby="passwordHelpInline"
                        value={movie.duration}
                        onChange={(e) => 
                            handleChangeMovie({
                                ...movie,
                                duration: e.target.value
                            })
                        }
                        style={{
                            marginTop:0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height:10,
                            boxShadow: 'none' // Asegura que no haya sombra en el input
                        }}
                    />
                </div>
            </div>
            <div className="row align-items-center p-0" style={{margin:0}}>
                <div className="col-3 p-0 ">
                    <label className="col-form-label poppins-light text-white p-0">Main characters:</label>
                </div>
                <div className="col-5 p-0 m-0">
                    <input
                        type="text"
                        className="form-control poppins-light  text-white"
                        aria-describedby="passwordHelpInline"
                        value={movie.mainCharter}
                        onChange={(e) => 
                            handleChangeMovie({
                                ...movie,
                                mainCharter: e.target.value
                            })
                        }
                        style={{
                            marginTop:0,
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height:10,
                            boxShadow: 'none' // Asegura que no haya sombra en el input
                        }}
                        />
                </div>
            </div>
            <div className="row align-items-center p-0" style={{margin:0}}>
                <div className="col-3 p-0 ">
                    <label className="col-form-label poppins-light text-white p-0">Rating:</label>
                </div>
                <div className="col-5 p-0 m-0">
                    <input
                        type="number"
                        className="form-control poppins-light  text-white"
                        aria-describedby="passwordHelpInline"
                        value={movie.rating}
                        min={0}
                        max={5}
                        onChange={(e) =>{ 
                            let value:number = parseInt(e.target.value)
                            if(value < 0){
                                value = 0
                            } else if (value > 5 ){
                                value = 5
                            }
                            handleChangeMovie({
                                ...movie,
                                rating: value
                            })}
                        }
                        style={{
                            marginTop:0,
                            width:"100%",
                            background: 'transparent',
                            borderBottom: '1px solid white',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            color: '#2d30e1',
                            height:10,
                            boxShadow: 'none' // Asegura que no haya sombra en el input
                        }}
                        />
                </div>
            </div>
        </div>
        <h3 className='poppins-bold text-white' style={{fontSize:18,textAlign:'justify'}}>{name??'Learn how to use Bootstrap, the world’s most popular framework for responsive.'}</h3>
        <p className='poppins-light text-white' style={{fontSize:15,textAlign:'justify'}}>
            {description??'Lorem ipsum es el texto que se usa habitualmente en diseño gráfico en demostraciones de tipografías o de borradores de diseño para probar el diseño visual antes de insertar el texto final.'} 
        </p>
    </div>
    );
};

export { HistoryWritingItem };