/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useThemeMode } from '../../../../../_metronic/partials';
import './style.css'
import { useTranslation } from 'react-i18next';

type Props = {
  className: string;
  studentsTrail: any[];
}

const AssistantLobbyClass: React.FC<Props> = ({ className, studentsTrail }) => {
  const {mode} = useThemeMode();
  const {t} = useTranslation();
  return (
    <div className={` ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0 pr-5'>
        <h4 className=' mb-5 champ-bold student-related-title' 
          style={
            mode==='dark'
                      ?
                      {color:'white'}
                      :
                      {color:'#2d30e1'}
          }>
          Students Related This Class
        </h4>
        
        <div className='mb-7   mh-200px scroll-y me-n7 pe-7' style={{minHeight:200}}>
          {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl(x.studentPhoto !== "" ? x.studentPhoto :'/media/svg/avatars/001-boy.svg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {t(x?.level)}
                </span>
              </div>
            </div>

          ))}
            {/*{studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
                  {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
                  {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
                  {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
                  {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
                  {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
                  {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
 
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
          {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
              
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))}
          {studentsTrail.map((x) => (
            <div className='d-flex mb-1 student-related-card'>
              <div className='symbol symbol-45px me-5'>
                <img className='rounded-circle img-fluid' src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
              </div>
              <div className='d-flex flex-column flex-row-fluid'>
                <div className='d-flex align-items-center flex-wrap mb-1'>
                  <p style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}className='champ-light text-hover-primary fw-bold me-2 pb-0 mb-0'>
                    {x?.name}
                  </p>
              
                </div>
                <span  style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}} className='champ-light fs-7 fw-normal pt-1'>
                  {x?.level}
                </span>
              </div>
            </div>

          ))} */}
          
        </div>
      </div>
    </div>
  )
}

export { AssistantLobbyClass }
