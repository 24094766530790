import useAuth from '../../../../api/main'
import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { UseMyRoadmapModule } from './store'

const { token, logout, authChecked } = useAuth.getState()

export const UseGetMyRoadMap = async (): Promise<any> => {
  try {
    await authChecked()
    const response = await api.get(`/student-settings/me`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    if (e.response.request.status === 401) {
      logout();
    }
  }
}

