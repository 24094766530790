
import React, { useEffect } from 'react';
import { SingleChart } from '../../../../components/common/SingleChart';
import { UseLeadResumeModule } from '../../../resume/store/main';
import useAuth from '../../../../api/main';
import { DateRange } from '../../../resume/components/list';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { UseAppointmentExecutiveState, UseAppointmentTeacherState } from '../../../resume/hooks/useAppointmentRepo';
import { DayPickerRangeSelector } from '../../../../components/common/DayRangePicker/indext';
 
import { UseContractDashboardCount, UseStudentDashboardCount, UseTeacherDashboardCount } from '../../services';
import { DashboardWidgetCustom } from '../dashboard-widget';
import { LevelCount, LevelWidgetCustom } from '../level-charts/indext';
import { AppointmentChart } from '../appointment-chart/indext';
import { AppointmentByActivityChart } from '../appointment-chart-by-activity/indext';

type Props = {

}
const StatsDashboardTeacher: React.FC<Props> = ({ }: Props) => {
    const queryClient = useQueryClient()
    const { typeUser } = useAuth();
    const { data, isLoading } = UseAppointmentTeacherState();
    const [studentCount, setStudentCount] = React.useState<number>(0);
    const [teacherCount, setTeacherCount] = React.useState<number>(0);
    const [contractCount, setContractCount] = React.useState<number>(0);
    const [mode, setMode] = React.useState<string>('STUDENT');
    
    const [levelCount, setLevelCount] = React.useState<LevelCount[]>([]);
    
    const {
        after,
        before,
        paginator,
        setDate
    } = UseLeadResumeModule()

    const [dateRange, setDateRange] = React.useState<DateRange>({
        after: after,
        before: before
    });
    const findListStudentActive = async () => {
        let response = await UseStudentDashboardCount()
        finderActiveStudent(response)
        setLevelCount(response.levels)
    }
    const findTeacherActive = async () => {
        let response = await UseTeacherDashboardCount()
        finderActiveTeacher(response)
    }
    const findContractActive = async () => {
        let response = await UseContractDashboardCount()
        setContractCount(response.contract.count)
    }
    

    const finderActiveStudent = (data: any) => {
        let value = data.status.find((x) => x.status === 'ACTIVE');
        setStudentCount(value.count)
    }
    const finderActiveTeacher = (data: any) => {
        let value = data.users.find((x) => x.type === 'TEACHER');
        setTeacherCount(value.count)
    }
    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['resume-teacher'] })
        findListStudentActive()
        findTeacherActive()
        findContractActive()
    }, [after, before])
    return (<>
        {isLoading}
        <div className='row'>
            <div className='col-3'>
                <DayPickerRangeSelector
                    after={after}
                    before={before}
                    changeDate={(e) => setDate({
                        after: e.after,
                        before: e.before
                    })}
                />
            </div>

            <div className='col-9'>
                <div className='row row justify-content-evenly mb-4'>
                    <h4 className='my-5 mb-5 text-center'>ESTADÍSTICAS</h4>
                    <div className='col-xl-3'>
                        <DashboardWidgetCustom
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='timer'
                            color='dark'
                            iconColor='primary'
                            title={studentCount.toString()}
                            titleColor='white'
                            description='Horas Programadas'
                            descriptionColor='white'
                            mode={'HOURS'}
                            setMode={(e)=>setMode(e)}
                        />
                    </div>

                    <div className='col-xl-3'>
                        <DashboardWidgetCustom
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='people'
                            color='dark'
                            iconColor='white'
                            title={contractCount.toString()}
                            titleColor='white'
                            description='Matrículas'
                            descriptionColor='white'
                            mode={'CONTRACT'}
                            setMode={(e)=>setMode(e)}
                        />
                    </div>

                    <div className='col-xl-3'>
                        <DashboardWidgetCustom
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='teacher'
                            color='dark'
                            iconColor='white'
                            title={teacherCount.toString()}
                            titleColor='white'
                            description='Profesores'
                            descriptionColor='white'
                            mode={'TEACHER'}
                            setMode={(e)=>setMode(e)}
                        />
                    </div>

                    <div className='col-xl-3'>
                        <DashboardWidgetCustom
                            className='card-xl-stretch mb-xl-8'
                            svgIcon='user-tick'
                            color='dark'
                            iconColor='white'
                            title={studentCount.toString()}
                            titleColor='white'
                            description='Estudiantes'
                            descriptionColor='white'
                            mode={'STUDENT'}
                            setMode={(e)=>setMode(e)}
                        />
                    </div>
                    {
                        mode==='STUDENT' &&<LevelWidgetCustom list={levelCount} totalStudent={studentCount}/>

                    }
                    {
                        mode==='HOURS' &&<AppointmentChart list={levelCount} totalStudent={studentCount} after={after} before={before}/>

                    }
                    {
                        mode==='TEACHER' &&<AppointmentByActivityChart  after={after} before={before}/>

                    }
                        
                    {/* <SingleChart
                        className='card-xl-stretch mb-xl-8'
                        chartColor='primary'
                        chartHeight='200px'
                        stats={
                            {
                                percentage: 10,
                                total: 10
                            }
                        }
                        title='Campaña'
                    /> */}




                </div>
            </div>
        </div >


    </>);
}
export { StatsDashboardTeacher }