  import {Route, Routes} from 'react-router-dom'
  import { ExerciseTwentyTwo } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwentyTwo/ExerciseTwentyTwo'
//import { ExerciseTwentyThree } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwentyThree/ExerciseTwentyThree'
  import { ExerciseTwentyFor } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwentyFor/ExerciseTwentyFor'
  import { ExerciseTwenty } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwenty/ExerciseTwenty'
  import { ExerciseTwentyOne } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwentyOne/ExerciseTwentyOne'
  import { ExerciseNineteen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseNineteen/ExerciseNineteen'
//import { ExerciseSeventeen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSeventeen/ExerciseSeventeen'
  import { ExerciseEight } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseEight/ExerciseEight'
//import { ExerciseSixteen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSixteen/ExerciseSixteen'
  import { Exercisefifteen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseFifteen/ExerciseFifteen'
  import { ExerciseFourteen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseFourteen/ExerciseFourteen'
  import { ExerciseThirteen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseThirteen/ExerciseThirteen'
  import { ExerciseTwelve } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwelve/ExerciseTwelve'
 
  import { ExerciseTen } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTen/ExerciseTen'
  import { ExerciseNine } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseNine/ExerciseNine'
//import { ExerciseTwo } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwo/ExerciseTwo'  
//import { ExerciseSeven } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSeven/ExerciseSeven'
  import { ExerciseSix } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSix/ExerciseSix'
  import { ExerciseFive } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseFive/ExerciseFive'
//import { ExerciseTwo } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwo/ExerciseTwo'
  import { ExerciseThree } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseThree/ExerciseThree'
//import { ExerciseTwo } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseTwo/ExerciseTwo'
  import { ExerciseOne } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseOne/ExerciseOne'
import { ExerciseEleven } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseEleven/ExerciseEleven'



const FeatureInterfacePage = () => (
  <Routes>
      <Route path='/24' element={<ExerciseTwentyFor instruction={'2. taking into account the definitions from the text, slassify the images below.'} />} />
  {/* <Route path='/23' element={<ExerciseTwentyThree/>} /> */ }
      <Route path='/22' element={<ExerciseTwentyTwo instruction={'1. Complete the Following:'} questions={'b: What does the author refer to the asan attemp?:'}/>} />
      <Route path='/21' element={<ExerciseTwentyOne instruction={'1. Order the following events in the stoyry chronologically (1-6)'} questions={''}/>} />
      <Route path='/20' element={<ExerciseTwenty instruction={'1. Complete the following:'} questions={'b: What does the author refer to the asan attempt?:'}/>} />
      <Route path='/19' element={<ExerciseNineteen/>}/>
  {/* <Route path='/18' element={<ExerciseSeventeen/>} /> */ }
  {/* <Route path='/17' element={<ExerciseEight/>} /> */ }
  {/* <Route path='/16' element={<ExerciseSixteen/>} /> */ }
      <Route path='/15' element={<Exercisefifteen instruction={'2. read the ogain and match the descriptions of column A to the definitions of columna B. The images con give you somo clues.'}/>} />
      <Route path='/14' element={<ExerciseFourteen />} /> 
      <Route path='/13' element={<ExerciseThirteen question={'2. correct the following sentences according to the reading. Change ONLY ONE word to make them right.'}/>} />//Ajustar
      <Route path='/12' element={<ExerciseTwelve instruction={'Highlight or underline the past form of the verbr that you find in the reading. the,number them and use the chart below to classfy them.'}/>} />// No completado
      <Route path='/11' element={<ExerciseEleven instruction={'1. Read the text again and choose the right combination of words as the example shows.'}/>} />  
      <Route path='/10' element={<ExerciseTen instruction={'1. loook at the images imn the box then, whrire the name of the dish and categorize it in the chart below'}/>} />/ No completado
      <Route path='/9' element={<ExerciseNine />} />
      <Route path='/8' element={<ExerciseEight question={''} textarea={''} buttonNext={''} instruction={''}/>} /> 
      <Route path='/5' element={<ExerciseFive question={'te the Following:'} textarea={'rte'} buttonNext={''} instruction={'2. correct the following sentences according to the reading. Change ONLY ONE word to make them right.'}/>} />
  {/* <Route path='/4' element={<ExerciseFor/>} /> */ }
      <Route path='/3' element={<ExerciseThree/>} />//Add store
  {/* <Route path='/2' element={<ExerciseTwo/>} /> */ }
      <Route path='/1' element={<ExerciseOne instruction={''}/>} />
  </Routes>
)
export {FeatureInterfacePage}
