import {Route, Routes} from 'react-router-dom'
import { SupportTicketDetails } from '../components/details'
import { SupportTicketTableList } from '../components/list'


const SupportTicketPage = () => (
  <Routes>
      <Route path='/details' element={<SupportTicketDetails />} />
      <Route path='/' element={<SupportTicketTableList />} />
  </Routes>
)

export {SupportTicketPage}
