import { useEffect, useMemo, useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { FileFolderAdd } from '../../../modules/admin/file-folder/services';
import { FileContentChildrenAdd } from '../../../modules/admin/file-folder/services/file-content-course';
import { showSimpleAlert } from "../../../commonHooks/alert";
// import { MediaContentAudio } from "../../../modules/courses/components/media/audio";


type Props = {
  onChangeFile: (file: any) => void;
  onChangeLoading: (isLoading: boolean) => void;
  idInput?: string;
  maxTime?: number;
  pushCover?: boolean;
}
const VoiceRecorderInput: React.FC<Props> = ({ maxTime, onChangeFile, onChangeLoading, idInput, pushCover }: Props) => {
  // Initialize the recorder controls using the hook
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const recorderControls = useVoiceVisualizer();
  const {
    // ... (Extracted controls and states, if necessary)
    recordedBlob,
    error,
    audioRef,
    recordingTime,
    stopRecording,
  } = recorderControls;

  const recordingTimeInSeconds = useMemo(() => {
    return recordingTime / 1000;
  }, [recordingTime]);

  const changeFile = (file: any) => {
    onChangeFile(file);
  };

  const handleFileUpload = async () => {
    try {
      if (recordedBlob) {
        setIsLoading(true);
        onChangeLoading(true);
        const formData = new FormData();
        const file = new File([recordedBlob], "audio.mp3", {
          type: 'audio/mpeg',
        });
        formData.append('file', file);
        const response = typeof pushCover !== 'undefined' && pushCover ? await FileContentChildrenAdd(formData) : await FileFolderAdd(formData);
        await changeFile(response);
        onChangeLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      onChangeLoading(false);
      setIsLoading(false);
    }
  };

  function downloadMp3(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download.mp3";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  useEffect(() => {
    console.log(maxTime)
    if (maxTime && recordingTimeInSeconds >= maxTime) {
      stopRecording();
      showSimpleAlert({ message: 'Excediste el tiempo limite del audio', icon: 'info' });
    }
  }, [recordingTimeInSeconds, maxTime]);

  return (<>
    <div className="d-flex justify-content-center align-items-center">
    <VoiceVisualizer ref={audioRef} controls={recorderControls} canvasContainerClassName="d-none" />

      {(!isLoading && recordedBlob) ? <div><button
        onClick={() => handleFileUpload()}
        className='btn btn-lg btn-success btn-outline-success ms-3 mt-3'
      >
        Guardar <i style={{ color: 'white', fontSize: 17 }} className="fas fa-save"></i>
      </button></div> : <></>}
      {isLoading && <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}

    </div>
    {/* <button className="btn btn-active-color-primary" type="button" onClick={()=>handleFileUpload()}>
          <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-floppy-o"></i>asd
          
        </button> */}
  </>
  );
};
export { VoiceRecorderInput }
