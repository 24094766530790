import {Route, Routes} from 'react-router-dom'
import { CampusDetails } from '../components/details'
import { CampusTableList } from '../components/list'


const CampusPage = () => (
  <Routes>
      <Route path='/details' element={<CampusDetails />} />
      <Route path='/' element={<CampusTableList />} />
  </Routes>
)

export {CampusPage}
