import React from 'react'
import './index.css';
type Props = {
    title: string
    description?: string
}

const TitleContentCourse: React.FC<Props> = ({ title, description }: Props) => {

    return (<>
        <div className=''>
            <h3 className='champ-bold text-white title-content-course ms-5'>{title}</h3>
            <p className='text-white poppins-light description-conte-course ms-5 overflow-y-auto scroll-y' 
                style={{maxHeight: '70px'}}>{description}</p>
        </div>
    </>)
}
export { TitleContentCourse }
