import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import './ExerciseTwelve.css';
import HeaderCard from '../ui/HeaderCard';
import { UseModuleExerciseTwelven } from './main/main'
import Swal from 'sweetalert2';


   type Props = {
       instruction: string
   }
 
const ExerciseTwelve: React.FC<Props> = () => {
    const [inputValueOne, setInputValueOne] = useState<{[key: string]: string}>({});
    const [inputValueTwo, setInputValueTwo] = useState<{[key: string]: string}>({});
    const [clicked, setClicked] = useState(false);
    const [Percentage, setPercentage] = React.useState<number | null>(null); 
    const [correctTotal, setCorrectTotal] = useState<number>(0); // Declarar correctTotal
    const [total, setTotal] = useState<number>(0); // Declarar total
    const { Arrayquestion,instruction, checked, setChecked, setInputValuesMo } = UseModuleExerciseTwelven();
    

    const clearInputValues = () => {
        const clearedInputValueOne: {[key: string]: string} = {};
        const clearedInputValueTwo: {[key: string]: string} = {};

        Arrayquestion.itemOne.values.forEach((_, index) => {
            clearedInputValueOne[index] = '';
        });
        Arrayquestion.itemTwo.values.forEach((_, index) => {
            clearedInputValueTwo[index] = '';
        });

        setInputValueOne(clearedInputValueOne);
        setInputValueTwo(clearedInputValueTwo);
    };

    const handleInputChangeOne = (index: number, value: string) => {
        const newInputValuesOne = { ...inputValueOne };
        newInputValuesOne[index] = value;
        setInputValueOne(newInputValuesOne);
    };
    
    const handleInputChangeTwo = (index: number, value: string) => {
        const newInputValuesTwo = { ...inputValueTwo };
        newInputValuesTwo[index] = value;
        setInputValueTwo(newInputValuesTwo);
    };

    const handleEdit = () => {
        setClicked(false);
        clearInputValues();
    };
    const handleCheck = () => {
        let correctAnswers = 0;
        let totalAnswers = 0;
        let emptyFields = false;

        Arrayquestion.itemOne.values.forEach((value, index) => {
            if (!inputValueOne[index]) {
                emptyFields = true;
            } else {
                totalAnswers++;
                if (inputValueOne[index] === value) {
                    correctAnswers++;
                }
            }
        });

        Arrayquestion.itemTwo.values.forEach((value, index) => {
            if (!inputValueTwo[index]) {
                emptyFields = true;
            } else {
                totalAnswers++;
                if (inputValueTwo[index] === value) {
                    correctAnswers++;
                }
            }
        });


        if (emptyFields) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '¡Debe ingresar todos los campos!',
            });
        } else {
            const accuracyPercentage = (correctAnswers / totalAnswers) * 100;
            Swal.fire({
                title: `Porcentaje de aciertos: ${accuracyPercentage.toFixed(1)}%`,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3f8bff' ,
                customClass: {
                    title: 'poppins-light'
                  },
                showClass: {
                  popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                  `
                },
                hideClass: {
                  popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                  `
                }
              });
    const incorrectFieldsOne = Arrayquestion.itemOne.values.filter((value, index) => inputValueOne[index] !== value);
            const incorrectFieldsTwo = Arrayquestion.itemTwo.values.filter((value, index) => inputValueTwo[index] !== value);
    
         
            if (incorrectFieldsOne.length > 0) {
                console.log('Campos incorrectos del primer conjunto:', incorrectFieldsOne);
            }
            if (incorrectFieldsTwo.length > 0) {
                console.log('Campos incorrectos del segundo conjunto:', incorrectFieldsTwo);
            }
            
            const Percentage = (correctAnswers / totalAnswers) * 100;
            setPercentage(Percentage);
            setCorrectTotal(correctAnswers);
            setTotal(totalAnswers);
            setClicked(true);
        }
    };
    
    React.useEffect(() => {
        console.log('ItemOne', inputValueOne)
    }, [inputValueOne])

    React.useEffect(() => {
        console.log('ItemTwo', inputValueTwo)
    }, [inputValueTwo])


    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };

return(<>
        <div className="container-fluid" style={{display: 'flex'}}>
           <div style={divStyle}>
           <HeaderCard/>
           <p className=" text-white  poppins-light fs-8 " style={{marginLeft:'8px'}}>{instruction}</p>
                    <table className="table table-responsive table-rounded-table  table-sm  ">
                        <thead>
                            <tr className="poppins-bold">
                                <th className=' text-center bg-light  table-rounded-table-th-one fs-8' style={{ width: '200px' ,color:'#3F8BFF'}}>{Arrayquestion.itemOne.title}</th>
                                <th className=' text-center bg-light  table-rounded-table-th-two fs-8' style={{ width: '200px' ,color:'#3F8BFF'}}>{Arrayquestion.itemTwo.title}</th>
                            </tr>
                        </thead>
                        <tbody className='text-center text-white poppins-light fs-9 '>
                            <tr className=''>
                                <td className='border border-top-0' style={{borderRadius:'0px 0px 0px 13px',border:'2px solid #ffffff',paddingTop:'6px'}} >   
                                    <div className="" >
                                        {Arrayquestion.itemOne.values.map((value:string,index:number)=>(  
                                            <input  
                                                key={index} 
                                                type="text" 
                                                style={{
                                                    color:'rgb(0, 0, 168)',
                                                    width: '68px',
                                                    borderRadius: '50px',
                                                    border: '0px',
                                                    outline: 'none',
                                                    background: clicked && inputValueOne[index] !== value ? '#d65353' : '#D0FF00', // Cambiar el color a rojo si se ha hecho clic y el valor es incorrecto
                                                    marginRight: '3px'
                                                }}
                                                className='mb-1  text-center fs-9  poppins-bold'
                                                value={inputValueOne[index]}
                                                onChange={(e) => handleInputChangeOne(index, e.target.value)}
                                                disabled={clicked}
                                                
                                            />
                                        ))} 
                                    </div>
                                </td>
                                <td className='border border-top-0' style={{borderRadius:'0px 0px 13px 0px',border:'2px solid #ffffff',paddingTop:'6px'}} >    
                                    <div>
                                    {Arrayquestion.itemTwo.values.map((value:string,index:number)=>(  
                                            <input  
                                                key={index} 
                                                type="text" 
                                                style={{
                                                    color:'rgb(0, 0, 168)',
                                                    width: '61px',
                                                    borderRadius: '50px',
                                                    border: '0px',
                                                    outline: 'none',
                                                    background: clicked && inputValueTwo[index] !== value ? '#d65353' : '#D0FF00', // Cambiar el color a rojo si se ha hecho clic y el valor es incorrecto
                                                    marginRight: '3px'
                                                }}
                                                className='mb-1 text-center fs-9  poppins-bold'
                                                value={setInputValueTwo[index]}
                                                onChange={(e) => handleInputChangeTwo(index, e.target.value)}
                                                disabled={clicked}
                                            />
                                        ))} 
                                    </div>
                                </td>
                            </tr>
                        </tbody> 
                    </table>
                    <div className='mb-4'>
                        <span className={`animate__animated text-white fs-8 poppins-light  ${Percentage ? 'animate__bounce' : 'd-none'}`}>Porcentaje de aciertos: holaaaaaaa {Percentage !== null ? `${Percentage.toFixed(1)}%` : 'Calculando...'}</span><br/>
                        <span  style={{marginBottom:'40px'}} className={`animate__animated text-white fs-8 poppins-light  ${Percentage ? 'animate__bounce' : 'd-none'}`}>{`Acertaste ${correctTotal}/${total}`}</span>
                    </div>
            </div>
            <div className="position-relative" onClick={(e) => {
                                e.preventDefault();
                                handleCheck()
                            }}>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <div className="position-relative" onClick={(e) => {
                                e.preventDefault();
                                handleEdit();
                                
                            }}> 
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '90px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            
            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
       </div>
  </>)
};
export {ExerciseTwelve}