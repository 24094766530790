import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ReContract} from '../../interfaces'

import { ReContractAdd, ReContractUpdate } from '../../services'
import { UseReContractModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { SettingPlacementTestStatus, SettingVerificationStatus, SettingWelcomeDayStatus, TraditionalLevelEnum } from '../../../../abroad/road-map-step/interfaces'
import { SettingModalityType, SettingStudentStatus } from '../../../user/interfaces'
import { useTranslation } from 'react-i18next'
import { usePlacementTestState } from '../../../placement-test/hooks/usePlacementTestRepo'
import { UsePlacementTestModule } from '../../../placement-test/store/main'
import { Campus } from '../../../../abroad/campus/interfaces'
import { RoadMap } from '../../../../abroad/road-map/interfaces'
import { RoadMapStep } from '../../../student-actions/promotion-history/components/forms'
import { Country } from '../../../country/interfaces'
import { setTypeToSpanish } from '../../../user/utils/setTypeToSpanish'
import { UseUserModule } from '../../../user/store/main'

export type ReContractFormFields = {
  code: string;
  codeContract: string;
  roadMap: string;
  currentStep: string;
  description?: string;
  isActive: boolean;
  start: string;
  finish: string;
  hours: number;
  hoursActivity: number;
  hoursUnits: number;
  user: string;
  level: TraditionalLevelEnum;
  placementTest?:SettingPlacementTestStatus
  welcomeDay?:SettingWelcomeDayStatus
  verification?:SettingVerificationStatus
  daysFreezingAvailable: number;
  classAvailable: number;
  modality: SettingModalityType;
  status: SettingStudentStatus;
  campus: string;
}
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const entitySchema = Yup.object().shape({
  email: Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Email is required'),
  isActive: Yup.boolean().required(' name is required'),
  fullName: Yup.string().required(' name is required'),
  phoneNumber: Yup.string()
  .matches(phoneRegExp, 'Formato de número de teléfono incorrecto')
  .required('El número de teléfono es obligatorio'),
  password: Yup.string()
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols')
  .required('Password is required'),
  country: Yup.string().required('País es requerido'),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const ReContractForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const {itemSelected,   mode,  resetData} = UseReContractModule();
  const { data, isLoading } = usePlacementTestState();
  const {where, type: typeWelcome, setType, setWhere} = UsePlacementTestModule();
  const [campus, setCampus] = useState<Campus[]>([])
  const [roadMap, setRoadMap] = React.useState<RoadMap[]>([]);
  const [roadMapStep, setRoadMapStep] = React.useState<RoadMapStep[]>([])
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [selectedWelcome, setSelectedWelcome] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tabLabel, setTabLabel] = React.useState<string>('');

  const memorizedValues = React.useMemo(() => ({where, typeWelcome}), [where, typeWelcome])

  const {values:valuesStudent, 
    errors:errorsStudent, 
    touched:touchedStudent, 
    setFieldValue:setFieldValueStudent, 
    handleChange:handleChangeStudent, 
    handleSubmit:handleSubmitStudent} =useFormik<ReContractFormFields>({
      initialValues: {
         code: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.code ?? '' : '',
         codeContract: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.codeContract ?? '' : '',
         roadMap: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelected?.roadMap && itemSelected?.roadMap.id) ?? '' : '',
         currentStep: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelected?.currentStep && itemSelected?.currentStep.id) ?? '' : '',
         isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
         start: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.start ?? '' : '',
         finish: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.finish ?? '' : '',
         hours: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hours ?? 0 : 0,
         user: itemSelected?.id ?? '',
         level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
         placementTest: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.placementTest ?? SettingPlacementTestStatus.PENDING : SettingPlacementTestStatus.PENDING,
         welcomeDay: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.welcomeDay ?? SettingWelcomeDayStatus.PENDING : SettingWelcomeDayStatus.PENDING,
         verification: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.verification ?? SettingVerificationStatus.APPROVED : SettingVerificationStatus.APPROVED,
         classAvailable: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.classAvailable ?? 0 : 0,
         status: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.status ?? SettingStudentStatus.ACTIVE : SettingStudentStatus.ACTIVE,
         campus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.campus?.id ?? '' : '',
         daysFreezingAvailable: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.daysFreezingAvailable ?? 30 : 30,
         hoursActivity: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hoursActivity ?? 0 : 0,
         hoursUnits: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hoursUnits ?? 0 : 0,
         modality: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.modality ?? SettingModalityType.VIRTUAL : SettingModalityType.VIRTUAL
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await ReContractAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['re-contract'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  ReContractUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['re-contract'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
    <form onSubmit={(e) => {
      e.preventDefault()
 
 
        handleSubmitStudent();
 
    }} noValidate className='form'>
      <div className='card-body border-top p-9 row'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item mb-10 col-6 d-flex justify-content-center'>
              <a
                className={`nav-link text-active-primary me-6 ` + 
                (tabLabel === '' && 'active')}
                onClick={() => setTabLabel('')}>
                {/* <i className={`bi ${route.icon}`} style={{marginRight: '5px'}}/> */}
                <span style={{paddingLeft: '5px'}}>Student</span>
                <KTIcon iconName={'user'} className='icon' />
              </a>
            </li>
            {mode !== 'ADD' && itemSelected?.isActive === true && 
              <li className='nav-item mb-10 col-6 d-flex justify-content-center'>
                <a
                  className={`nav-link text-active-primary me-6 ` + 
                  (tabLabel === 'SETTINGS' && 'active')}
                  onClick={() => setTabLabel('SETTINGS')}>
                  {/* <i className={`bi ${route.icon}`} style={{marginRight: '5px'}}/> */}
                  <span style={{paddingLeft: '5px'}}>Settings</span>
                  <KTIcon iconName={'setting'} className='icon' />
                </a>
              </li>
            }
            {mode !== 'ADD' && itemSelected?.isActive === false && itemSelected &&
              <li className='nav-item mb-10 col-6 d-flex justify-content-center'>
                <a
                  className={`nav-link text-active-primary me-6 ` + 
                  (tabLabel === 'RE_CONTRACT' && 'active')}
                  onClick={() => setTabLabel('RE_CONTRACT')}>
                  {/* <i className={`bi ${route.icon}`} style={{marginRight: '5px'}}/> */}
                  <span style={{paddingLeft: '5px'}}>Recontrato</span>
                  <KTIcon iconName={'setting'} className='icon' />
                </a>
              </li>
            }
        </ul>
        
 

        {tabLabel === 'SETTINGS' && mode!=='ADD' && <>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={valuesStudent.isActive}
                value={valuesStudent.isActive?1:0}
                onChange={handleChangeStudent}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Nivel</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={valuesStudent.level}
              onChange={handleChangeStudent}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errorsStudent.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.level}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Campus</label>
            <select
              disabled={mode === 'VIEW'}
              name={'campus'}
              value={valuesStudent.campus}
              onChange={handleChangeStudent}
              className="form-select" aria-label="Default select example">
              <option value={undefined}></option>
              {campus.map((x) => (<option value={x.id}>{x.name}</option>))}
            </select>
            {errorsStudent.campus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.campus}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Modalidad</label>
            <select
              disabled={mode === 'VIEW'}
              name='modality'
              value={valuesStudent.modality}
              onChange={handleChangeStudent}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SettingModalityType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errorsStudent.modality && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.modality}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Verificación</label>
            <select
              disabled={mode === 'VIEW'}
              name='verification'
              value={valuesStudent.verification}
              onChange={handleChangeStudent}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SettingVerificationStatus).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errorsStudent.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Welcome Day</label>
            <select
              disabled={mode === 'VIEW'}
              name='welcomeDay'
              value={valuesStudent.welcomeDay}
              onChange={handleChangeStudent}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SettingWelcomeDayStatus).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errorsStudent.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.level}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Placement Test Status</label>
            <select
              disabled={mode === 'VIEW'}
              name='placementTest'
              value={valuesStudent.placementTest}
              onChange={handleChangeStudent}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SettingPlacementTestStatus).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errorsStudent.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Status</label>
            <select
              disabled={mode === 'VIEW'}
              name='status'
              value={valuesStudent.status}
              onChange={handleChangeStudent}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SettingStudentStatus).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errorsStudent.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={valuesStudent.code}
              onChange={handleChangeStudent}
            />
            {touchedStudent.code && errorsStudent.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.code}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code Contract</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'codeContract'}
              value={valuesStudent.codeContract}
              onChange={handleChangeStudent}
            />
            {touchedStudent.codeContract && errorsStudent.codeContract && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.codeContract}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Dias de Congelamiento</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'daysFreezingAvailable'}
              value={valuesStudent.daysFreezingAvailable}
              onChange={handleChangeStudent}
            />
            {touchedStudent.daysFreezingAvailable && errorsStudent.daysFreezingAvailable && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.daysFreezingAvailable}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Horas de experiencia</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled
              name={'hours'}
              value={valuesStudent.hours}
              onChange={handleChangeStudent}
            />
            {touchedStudent.hours && errorsStudent.hours && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.hours}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Horas permitidas por unidades</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'hoursUnits'}
              value={valuesStudent.hoursUnits}
              onChange={handleChangeStudent}
            />
            {touchedStudent.hoursUnits && errorsStudent.hoursUnits && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.hoursUnits}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Horas permitidas por actividades</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'hoursActivity'}
              value={valuesStudent.hoursActivity}
              onChange={handleChangeStudent}
            />
            {touchedStudent.hoursActivity && errorsStudent.hoursActivity && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.hoursActivity}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Total de clases por semana</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled
              name={'classAvailable'}
              value={valuesStudent.classAvailable}
              onChange={handleChangeStudent}
            />
            {touchedStudent.classAvailable && errorsStudent.classAvailable && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.classAvailable}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Buscar Welcome Day</label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                name={'selectedWelcome'}
                value={where}
                onChange={(e) => setWhere(e.target.value)}
              />
              <span className='input-group-text border-0' id='search-icon'>
                <i className='fas fa-search'></i>
              </span>
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Welcome Day</label>
            <select
              disabled={mode === 'VIEW'}
              name='welcomeDay'
              value={selectedWelcome}
              onChange={(e) => setSelectedWelcome(e.target.value)}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {data && data.result && data.result.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
            {errorsStudent.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorsStudent.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Road Map</label>
            <select
              name='roadMap'
              className='form-select'
              aria-label='Default select example'
              onChange={handleChangeStudent}
              value={valuesStudent.roadMap}
              disabled={mode==='VIEW'}
            >
              <option value='' selected>
                
              </option>
              {roadMap?.map((x, i) => (
                <option value={x.id} key={i}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Road Map Step</label>
            <select
              name='currentStep'
              className='form-select'
              aria-label='Default select example' 
              onChange={handleChangeStudent}
              value={valuesStudent.currentStep}
              disabled={mode==='VIEW'}
            >
              <option value='' selected>
                
              </option>
              {roadMapStep?.map((x, i) => (
                <option value={x.id} key={i}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>

          <div className='mb-1 col-6'>
              <label className='form-label mb-0'>Hora De Inicio</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'start'}
                value={valuesStudent.start}
                onChange={handleChangeStudent}
              //   onChange={(e)=>{
              //    if(handleHourChange(e)){
              //      handleChange(e)
              //    }
              //   }
              // }
              />
              {touchedStudent.start && errorsStudent.start && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errorsStudent.start}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-1  col-6'>
              <label className='form-label mb-0'>Hora De Cierre</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'finish'}
                value={valuesStudent.finish}
                onChange={handleChangeStudent}
              //   onChange={(e)=>{
              //     if(handleHourEndChange(e)){
              //       handleChange(e)
              //     }
              //    }
              //  }
              />
              {touchedStudent.finish && errorsStudent.finish && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errorsStudent.finish}</span>
                  </div>
                </div>
              )}
            </div>
        </>}
      </div>

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
          {!loading && 'Save'}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  </div>
  )
}

export {ReContractForm}
