
interface AppUrlSelectorObj {
    facebook:string
    www:string
    in:string
    youtube:string
    tiktok:string
    instagram:string
    name:string
    info:string
}
export const logoAppSelector = (theme:'LIGHT'|'DARK'):string => {
    let mode = process.env.REACT_APP_THEME_DEMO
    if(mode ==='ICE'){
        if(theme==='LIGHT'){
           return '/media/logos/abroad-white.png'
        }
        if(theme==='DARK'){
            return '/media/logos/abroad-color.png'
        }
    }
    if(mode ==='MICHI'){
        if(theme==='LIGHT'){
           return '/media/logos/MICHIGAN_logo1.svg'
        }
        if(theme==='DARK'){
            return '/media/logos/MICHIGAN_logo1.svg'
         }
    }
     return '/media/logos/MICHIGAN_logo1.svg'
};

export const favIconAppSelector = ():string => {
    let mode = process.env.REACT_APP_THEME_DEMO
    if(mode ==='ICE'){
        return '/media/logos/abroad-color.png'
    }
    if(mode ==='MICHI'){
           return '/media/logos/favicon.jpg'
    }
     return '/media/logos/favicon.jpg'
};

export const logoAppSelectorUrl = ():AppUrlSelectorObj => {

    let mode = process.env.REACT_APP_THEME_DEMO
    if(mode ==='ICE'){
        return {
            facebook:"#",
            tiktok:"#",
            instagram:"#",
            www:"https://iceschool.com.pe/",
            in:"#",
            youtube:"https://iceschool.com.pe/",
            name:"Ice School",
            info:"#"
        }
    }
    if(mode ==='MICHI'){
        return {
            facebook:'https://www.facebook.com/michiganmaster',
            tiktok:"https://tiktok.com/@michiganmasterco",
            instagram:"https://www.instagram.com/michiganmastersas",
            www:"www.michiganmaster.edu.co",
            in:"#",
            youtube:"https://www.youtube.com/c/MichiganMasterColombia",
            name:"Michigan Masters",
            info:"https://www.michiganmaster.edu.co/chico.php"
        }
    }
    return {
        facebook:'https://www.facebook.com/michiganmaster',
        tiktok:"https://tiktok.com/@michiganmasterco",
        instagram:"https://www.instagram.com/michiganmastersas",
        www:"www.michiganmaster.edu.co",
        in:"#",
        youtube:"https://www.youtube.com/c/MichiganMasterColombia",
        name:"Michigan Masters",
        info:"https://www.michiganmaster.edu.co/chico.php"
    }
};