export const NotificationsTranslateEs = {
  STANDARD: 'Aviso',
  COMMENT_POST : 'Comentó tu post',
  COMMENT_PROJECT : 'Comento tu proyecto',
  FORGOT_PASSWORD : 'Contraseña',
  INTERNAL : 'Interna',
  TICKET_CREATED : 'Ticket Creado',
  TICKET_RESPONSE : 'Ticket respondido',
  CALENDAR_EVENT_INVITED : 'Has sido invitado a un evento',
  CALENDAR_EVENT_UPDATED : 'Evento actualizado',
  CALENDAR_EVENT_DELETED : 'Evento eliminado',
  NEW_APPOINTMENT_RESERVED : 'Nueva clase reservada',
  APPOINTMENT_CANCELED : 'Clase cancelada',
  APPOINTMENT_STARTER : 'Tu clase inició',
  STRIKE_ADDED : 'Strike agregado',
  SCHEDULING_REMINDER : 'Recordatorio de tu clase',
  YOU_ARE_SANCTIONED: 'Estas sancionado',
  NOTIFICATIONS: 'Notificaciones',
  TOPIC_SELECTED: 'Topico seleccionado'
}