export const LearningPathTranslateEn = {
    LEARNING_PATH:"Courses",
    MANAGEMENT_LEARNING_PATH:"Course Management",
    TYPE:"Type",
    NAME:"Name",
    DESCRIPTION:"Description",
    ACTIVE:"Active",
    ACTIONS:"Actions",
    STANDARD:"Standard",
    RANDOM:"Random",
    VIEW: "View",
  }