import React, { useEffect } from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseMovieWritingModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';
import { TitleContentCourse } from '../../../../components/common/TitleContentCourse';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import RenderingContentFullScreen from '../RenderingContentFullScreen';
import { HistoryWritingItem } from './components/history-item';
import { UseEvaluationTestModule } from '../../../admin/evaluation/modules/test/store/main';
import { UseModeCourseModule } from '../../components/front-page/store/main';

type Props = {
    titleContent: string;
    descriptionContent: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
};

const MovieWritingFullExercise: React.FC<Props> = ({ renderType, titleContent, descriptionContent, colorSecondary }) => {
    const { mode } = useThemeMode();
    const {modeTest, modeCourse} = UseModeCourseModule();
    const [question, setQuestion] = React.useState<number>(0);
    const {questionsEvaluation} = UseEvaluationCourseModule();
    const {questionsEvaluationTest} = UseEvaluationTestModule();
    let {
        questions,
        setStep,
        step,
        status,
        falseAnswer,
        trueAnswer,
        setStatus,
        startQuiz,
        title,
        instructions,
        movie,
        setAnswerMovie,
        setDescriptionMovie,
    } = UseMovieWritingModule();

    return (
        <>
        {renderType && renderType === 'left' ?
            <HistoryWritingItem 
                movie={movie} 
                handleChangeMovie={(e) => setAnswerMovie(e)} 
                name={questionsEvaluation?.exercise?.movie_writing[0]?.name}
                description={questionsEvaluation?.exercise?.movie_writing[0]?.description}
            />
        : <>
            {
            status !== 'RESUME' && status !== 'TITLE'?
                <BasicVocabularyExerciseItem
                    movie={movie}
                    falseAnswer={falseAnswer[step]}
                    question={questions[step]}
                    handleNext={(e) => setAnswerMovie(e)}
                    handleSendAnswer={(e) => setAnswerMovie(e)}
                    modeQuiz={status}
                    totalQuestions={1}
                    setStatus={(e) => setStatus(e)}
                    setStep={(e) => setStep(step + e)}
                    handleChangeDescription={(e) => setDescriptionMovie(e)}
                />
            : <></>}
            {status === 'RESUME' &&
                <BasicVocabularyExerciseResume
                    total={1}
                    correct={trueAnswer.length}
                    incorrect={falseAnswer.length}
                    setStatus={(e) => setStatus(e)}
                    correctAnswer={trueAnswer}
                    falseAnswer={falseAnswer}
                />}
            {status === 'TITLE' &&
                <IntroBasicVocabulary
                    title={titleContent}
                    instructions={descriptionContent}
                    startQuiz={() => startQuiz()}
                />
            }
        </>}
        

            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>
        </>
    );
};

export default React.memo(MovieWritingFullExercise);