import { RefObject, useRef, useState } from 'react';

export interface ScrollAndZoomHandlers {
  handleScroll: React.WheelEventHandler<HTMLDivElement>;
  handleMouseDown: React.MouseEventHandler<HTMLDivElement>;
  handleMouseMove: React.MouseEventHandler<HTMLDivElement>;
  handleMouseUp: React.MouseEventHandler<HTMLDivElement>;
  zoom: number;
  isDragging: boolean;
}

const useScrollAndZoom = (containerRef: RefObject<HTMLDivElement>): ScrollAndZoomHandlers => {
  const [zoom, setZoom] = useState<number>(100);
  const minZoom: number = 100;
  const maxZoom: number = 200;
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);

  const handleScroll: React.WheelEventHandler<HTMLDivElement> = (event) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const offsetX = event.clientX - containerRect.left;
      const offsetY = event.clientY - containerRect.top;

      const newZoom = event.deltaY < 0 ? Math.min(zoom + 10, maxZoom) : Math.max(zoom - 10, minZoom);
      setZoom(newZoom);

      const scaleFactor = newZoom / zoom;
      containerRef.current.scrollLeft = (offsetX * scaleFactor) - (offsetX - containerRef.current.scrollLeft);
      containerRef.current.scrollTop = (offsetY * scaleFactor) - (offsetY - containerRef.current.scrollTop);

      event.preventDefault(); // Evitar el scroll de la página
    }
  };

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setIsDragging(true);
    setStartX(event.clientX);
    setStartY(event.clientY);
    setStartScrollLeft(containerRef.current?.scrollLeft || 0);
    setStartScrollTop(containerRef.current?.scrollTop || 0);
    // Evitar que el navegador inicie acciones de arrastre por defecto
    event.preventDefault();
  };

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isDragging || !containerRef.current) return;

    const deltaX = event.clientX - startX;
    const deltaY = event.clientY - startY;

    containerRef.current.scrollLeft = startScrollLeft - deltaX;
    containerRef.current.scrollTop = startScrollTop - deltaY;
  };

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsDragging(false);
  };

  return { handleScroll, handleMouseDown, handleMouseMove, handleMouseUp, zoom, isDragging };
};

export default useScrollAndZoom;