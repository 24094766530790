/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { t } from 'i18next'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import moment from 'moment'
import { UseAppointmentDashboardCount, UseTrailClassDashboardCount } from '../../services'

export interface LevelCount {
    level: TraditionalLevelEnum
    count: string
}
 
type LevelChartsProps = {
    after: Date
    before: Date
}
export interface ResumeResponse {
    totalTrials: string
    studentsAttended: string
    studentsMissed: string
    teachersAttended: string
    teachersMissed: string
  }
const TrailClassChart: React.FC<LevelChartsProps> = ({  after, before }) => {
    const [resume, setResume] = React.useState<ResumeResponse>()
 
    let findAppointmentDashboardCount = async () => {
        let _before = moment(before).format('YYYY-MM-DD')
        let _after = moment(after).format('YYYY-MM-DD')
        let response = await UseTrailClassDashboardCount(_before, _after)
        setResume(response)
    }
    useEffect(() => {
        findAppointmentDashboardCount()
    }, [after, before])
    return (
        <div className='row'>

 
            <div className='card shadow p-8 col-12'>
                <h1 className='champ-light text-center'>Reservas</h1>
                <h5 className='poppins-light'>Desde:{moment(before).format('DD-MM-YYYY')}</h5>
                <h5 className='poppins-light'>Hasta:{moment(after).format('DD-MM-YYYY')}</h5>
                <hr></hr>
                <h5 className='champ-light'>Asistencias {resume?.studentsAttended} </h5>
                <h5 className='champ-light'>Inasistencias {resume?.studentsMissed} </h5>
                <h5 className='champ-light'>Reservas totales {resume?.totalTrials} </h5>
 
            </div>
        </div>
    )
}

export { TrailClassChart }
