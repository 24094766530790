import React, { useEffect } from 'react';
import { BasicVocabularyExercise } from '../../../exercises/basic-vocabulary';
import './index.css';
import { MediaContentPicture } from '../../../components/media/pricture';
// import { MediaContentAudio } from '../../../components/media/audio';
import { TitleContentCourse } from '../../../../../components/common/TitleContentCourse';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import MediaContentVideo from '../../../components/media/video';
import { MediaContentHtml } from '../../../components/media/html';
import { BasicVocabularyCompletionExercise } from '../../../exercises/basic-vocabulary-completion';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import { ExercisesType } from '../../../../admin/exercises/interfaces';
import { TypeContentCourse } from '../../../../admin/file-folder/interfaces';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { TrueFalseExercise } from '../../../exercises/true-false';
type Props = {
    colorSecondary: string;
    titleContent: string;
    descriptionContent: string;
};

const MainCardContent: React.FC<Props> = ({ colorSecondary, titleContent, descriptionContent }) => {

    const {stepEvaluationTest, questionsEvaluationTest, evaluationTest, lessonCourse: lessonTest} = UseEvaluationTestModule();
    const {stepEvaluation, questionsEvaluation, evaluationLesson, lessonCourse} = UseEvaluationCourseModule();
    const {modeCourse, modeTest} = UseModeCourseModule();
    
    return (
        <>
            <div className="row d-none d-md-block">
                <div className='d-flex justify-content-end row' style={{marginTop: '-2%'}}>
                    <div className='col-10 d-flex justify-content-end align-items-end'>
                        <img 
                            src={evaluationLesson?.cover?.location} 
                            alt="michigan"
                            className='position-absolute'
                            style={{width: '400px', top: '0px'}}
                        />
                    </div>
                    <div className='col-2 d-flex justify-content-end align-items-end flex-column'>
                        <button type='button' 
                            className='btn d-flex justify-content-end align-items-end p-0 mb-2'
                            id='kt_glossary_course_toggle'
                            style={{zIndex: 2}}
                        >
                            <span className='fs-4 poppins-light text-white me-2'>Glosario</span>
                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/glossary_white.svg')} alt="metronic" style={{width: '30px'}} />
                        </button>
                        <button type='button' 
                            className='btn d-flex justify-content-end align-items-end p-0 mb-2'
                            id='kt_tips_course_toggle'
                            style={{zIndex: 2}}
                        >
                            <span className='fs-4 poppins-light text-white me-2'>Tips</span>
                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/tips_white.svg')} alt="metronic" style={{width: '30px'}} />
                        </button>
                        <button type='button' 
                            className='btn d-flex justify-content-end align-items-end p-0'
                            // id='kt_glossary_course_toggle'
                            style={{zIndex: 2}}
                        >
                            <span className='fs-4 poppins-light me-2' style={{color: 'rgba(255, 255, 255, 0.5)'}}>Quiz</span>
                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/quiz_white.svg')} alt="metronic" style={{width: '30px'}} />
                        </button>
                    </div>
                </div>
                <div className='col-12' style={{marginTop: '-5%'}}>
                    <div className='row '>
                        <div
                            style={{ zIndex: 2, padding: 0, backgroundColor: colorSecondary }}
                            className='col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12  card-with-extended-background content-course-card-left'>
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                width: '100%',
                            }}>
                                <TitleContentCourse 
                                    title={titleContent}
                                    description={descriptionContent}
                                />
                                {modeTest && lessonTest?.fileContent?.type === TypeContentCourse.HTML ? 
                                    <MediaContentHtml
                                        urlHtml={lessonTest?.fileContent?.content?.location}
                                        cover={lessonTest.fileContent.applyCover?
                                            lessonTest?.fileContent?.cover?.location :''}
                                    />
                                    : lessonTest?.fileContent?.type === TypeContentCourse.VIDEO ?
                                        <MediaContentVideo 
                                            heightVideo='370'
                                            coverFile={
                                                lessonTest?.fileContent?.cover?.location??
                                                'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'
                                            }
                                            urlVideo={lessonTest?.fileContent?.iFrame??
                                                'https://player.vdocipher.com/v2/?otp=20160313versUSE323rM9exuhRUfuytV4Uo1mmWEDN3YKba8oNFIkNGAGcU87RPo&playbackInfo=eyJ2aWRlb0lkIjoiZDRjYTljNWE4YmE3NDc0OGI1Y2YwOGUyMTVhZWRjM2UifQ=='
                                            }
                                        />
                                    :  lessonTest?.fileContent?.type === TypeContentCourse.IMAGE &&
                                        <MediaContentPicture 
                                            imageUrl={lessonTest?.fileContent?.content?.location??
                                            'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}
                                        />
                                }
                                {/* Lesson content rendering */}
                                {modeCourse && lessonCourse?.fileContent?.type === TypeContentCourse.HTML ? 
                                    <MediaContentHtml
                                        urlHtml={lessonCourse?.fileContent?.content?.location}
                                        cover={lessonCourse.fileContent.applyCover?
                                            lessonCourse?.fileContent?.cover?.location :
                                            'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}
                                    />
                                    : lessonCourse?.fileContent?.type === TypeContentCourse.VIDEO ?
                                        <MediaContentVideo 
                                            heightVideo='370'
                                            coverFile={
                                                lessonCourse?.fileContent?.cover?.location??
                                                'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'
                                            }
                                            urlVideo={lessonCourse?.fileContent?.iFrame??
                                                'https://player.vdocipher.com/v2/?otp=20160313versUSE323rM9exuhRUfuytV4Uo1mmWEDN3YKba8oNFIkNGAGcU87RPo&playbackInfo=eyJ2aWRlb0lkIjoiZDRjYTljNWE4YmE3NDc0OGI1Y2YwOGUyMTVhZWRjM2UifQ=='
                                            }
                                        />
                                    : lessonCourse?.fileContent?.type === TypeContentCourse.IMAGE &&
                                        <MediaContentPicture 
                                            imageUrl={lessonCourse?.fileContent?.content?.location??
                                            'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}
                                        />
                                }
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 content-course-card-right" style={{ zIndex: 1, position: 'relative' }}>
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                width: '100%'
                            }}>
                                {/* Rendering of the exercises according to the current step */}
                                {modeTest ? stepEvaluationTest + 1 === questionsEvaluationTest?.order && 
                                    questionsEvaluationTest?.exercise?.type === ExercisesType.SIMPLE_SELECTION ?
                                        <BasicVocabularyExercise titleContent={questionsEvaluation?.exercise.name} descriptionContent={questionsEvaluation?.exercise.description} />
                                    : questionsEvaluationTest?.exercise?.type === ExercisesType.SIMPLE_SELECTION_COMPLETION ?
                                        <BasicVocabularyCompletionExercise 
                                            titleContent={questionsEvaluation?.exercise.name} 
                                            descriptionContent={questionsEvaluation?.exercise.description} 
                                        />
                                    : questionsEvaluationTest?.exercise?.type === ExercisesType.TRUE_FALSE &&
                                        <TrueFalseExercise />
                                    // : questionsEvaluation?.exercise?.type === ExercisesType.DRAG_DROP &&
                                    // questionsEvaluation?.exercise?.drag_drop_questions[0]?.type === TypeDragDrop.WORD_BOX_V1 ?
                                    // <ParagraphDragDrop
                                    //     colorSecondary={colorSecondary}
                                    //     titleContent={questionsEvaluation?.name}
                                    //     descriptionContent={questionsEvaluation?.description}
                                    // />
                                    // : questionsEvaluation?.exercise?.drag_drop_questions?.length > 0 && 
                                    //     questionsEvaluation?.exercise?.drag_drop_questions[0]?.type === TypeDragDrop.WORD_BOX_V2 &&
                                    //     <WordBoxContent
                                    //         colorSecondary={colorSecondary}
                                    //         titleContent={questionsEvaluation?.name}
                                    //         descriptionContent={questionsEvaluation?.description}
                                    //     />

                                : modeCourse && stepEvaluation + 1 === questionsEvaluation?.order && 
                                    questionsEvaluation?.exercise?.type === ExercisesType.SIMPLE_SELECTION ?
                                        <BasicVocabularyExercise  titleContent={questionsEvaluation?.exercise.name} descriptionContent={questionsEvaluation?.exercise.description} />
                                    : questionsEvaluation?.exercise?.type === ExercisesType.SIMPLE_SELECTION_COMPLETION ?
                                        <BasicVocabularyCompletionExercise 
                                            titleContent={questionsEvaluation?.exercise.name} 
                                            descriptionContent={questionsEvaluation?.exercise.description} 
                                        />
                                    : questionsEvaluation?.exercise?.type === ExercisesType.TRUE_FALSE &&
                                        <TrueFalseExercise />
                                    // : questionsEvaluation?.exercise?.type === ExercisesType.DRAG_DROP &&
                                    //     questionsEvaluation?.exercise?.drag_drop_questions[0]?.type === TypeDragDrop.WORD_BOX_V1 ?
                                    //     <ParagraphDragDrop
                                    //         colorSecondary={colorSecondary}
                                    //         titleContent={questionsEvaluation?.name}
                                    //         descriptionContent={questionsEvaluation?.description}
                                    //     />
                                    //     : questionsEvaluation?.exercise?.drag_drop_questions?.length > 0 && 
                                    //         questionsEvaluation?.exercise?.drag_drop_questions[0]?.type === TypeDragDrop.WORD_BOX_V2 &&
                                    //         <WordBoxContent
                                    //             colorSecondary={colorSecondary}
                                    //             titleContent={questionsEvaluation?.name}
                                    //             descriptionContent={questionsEvaluation?.description}
                                    //         />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-block d-md-none">
                <div className='col-12 '>
                    <div className="row ">
                        <div
                            style={{ zIndex: 2,paddingLeft:12,paddingRight:12,paddingTop:40,borderRadius:'30px 30px 0px 0px', background:'#ff336b'}}
                            className='col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12  '>
                                <TitleContentCourse 
                                    title={titleContent}
                                    description={descriptionContent}
                                />
                                {modeTest && lessonTest?.fileContent?.type === TypeContentCourse.HTML ? 
                                    <MediaContentHtml
                                        urlHtml={lessonTest?.fileContent?.content?.location}
                                        cover={lessonTest.fileContent.applyCover?
                                            lessonTest?.fileContent?.cover?.location :''}
                                    />
                                    : lessonTest?.fileContent?.type === TypeContentCourse.VIDEO ?
                                        <MediaContentVideo 
                                            heightVideo='200'
                                            coverFile={
                                                lessonTest?.fileContent?.cover?.location??
                                                'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'
                                            }
                                            urlVideo={lessonTest?.fileContent?.iFrame??
                                                'https://player.vdocipher.com/v2/?otp=20160313versUSE323rM9exuhRUfuytV4Uo1mmWEDN3YKba8oNFIkNGAGcU87RPo&playbackInfo=eyJ2aWRlb0lkIjoiZDRjYTljNWE4YmE3NDc0OGI1Y2YwOGUyMTVhZWRjM2UifQ=='
                                            }
                                        />
                                    :  lessonTest?.fileContent?.type === TypeContentCourse.IMAGE &&
                                        <MediaContentPicture 
                                            imageUrl={lessonTest?.fileContent?.content?.location??
                                            'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}
                                        />
                                }
                                {/* Lesson content rendering */}
                                {modeCourse && lessonCourse?.fileContent?.type === TypeContentCourse.HTML ? 
                                    <MediaContentHtml
                                        urlHtml={lessonCourse?.fileContent?.content?.location}
                                        cover={lessonCourse.fileContent.applyCover?
                                            lessonCourse?.fileContent?.cover?.location :
                                            'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}
                                    />
                                    : lessonCourse?.fileContent?.type === TypeContentCourse.VIDEO ?
                                        <MediaContentVideo 
                                            heightVideo='200'
                                            coverFile={
                                                lessonCourse?.fileContent?.cover?.location??
                                                'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'
                                            }
                                            urlVideo={
                                                lessonCourse?.fileContent?.iFrame??
                                                'https://player.vdocipher.com/v2/?otp=20160313versUSE323rM9exuhRUfuytV4Uo1mmWEDN3YKba8oNFIkNGAGcU87RPo&playbackInfo=eyJ2aWRlb0lkIjoiZDRjYTljNWE4YmE3NDc0OGI1Y2YwOGUyMTVhZWRjM2UifQ=='
                                            }
                                        />
                                    : lessonCourse?.fileContent?.type === TypeContentCourse.IMAGE &&
                                        <MediaContentPicture 
                                            imageUrl={lessonCourse?.fileContent?.content?.location??
                                            'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}
                                        />
                                }
                                {/* <MediaContentAudio /> */}
                       
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12"  style={{paddingLeft:12,paddingRight:12}}>
                            {/* Rendering of the exercises according to the current step */}
                            {modeTest ? stepEvaluationTest + 1 === questionsEvaluationTest?.order && 
                                questionsEvaluationTest?.exercise?.type === ExercisesType.SIMPLE_SELECTION ?
                                    <BasicVocabularyExercise titleContent={questionsEvaluation?.exercise.name} descriptionContent={questionsEvaluation?.exercise.description} />
                                : questionsEvaluationTest?.exercise?.type === ExercisesType.SIMPLE_SELECTION_COMPLETION ?
                                    <BasicVocabularyCompletionExercise 
                                        titleContent={questionsEvaluation?.exercise.name} 
                                        descriptionContent={questionsEvaluation?.exercise.description} 
                                    />
                                : questionsEvaluationTest?.exercise?.type === ExercisesType.TRUE_FALSE &&
                                    <TrueFalseExercise />
                            : modeCourse && stepEvaluation + 1 === questionsEvaluation?.order && 
                                questionsEvaluation?.exercise?.type === ExercisesType.SIMPLE_SELECTION ?
                                    <BasicVocabularyExercise titleContent={questionsEvaluation?.exercise.name} descriptionContent={questionsEvaluation?.exercise.description} />
                                : questionsEvaluation?.exercise?.type === ExercisesType.SIMPLE_SELECTION_COMPLETION ?
                                    <BasicVocabularyCompletionExercise 
                                        titleContent={questionsEvaluation?.exercise.name} 
                                        descriptionContent={questionsEvaluation?.exercise.description} 
                                    />
                                : questionsEvaluation?.exercise?.type === ExercisesType.TRUE_FALSE &&
                                    <TrueFalseExercise />
                            }
                        </div>

                        <div className='col-12 row mx-1'>
                            <button type='button' 
                                className='btn col-4 d-flex justify-content-center align-items-center'
                                id='kt_glossary_course_toggle'
                            >
                                <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/glossary_white.svg')} alt="metronic" style={{width: '30px'}} />
                                <span className='ms-2 fs-4 poppins-light text-white'>Glosario</span>
                            </button>
                            <button type='button' 
                                className='btn col-4 d-flex justify-content-center align-items-center'
                                id='kt_tips_course_toggle'
                            >
                                <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/tips_white.svg')} alt="metronic" style={{width: '30px'}} />
                                <span className='ms-2 fs-4 poppins-light text-white'>Tips</span>
                            </button>
                            <button type='button' className='btn col-4 d-flex justify-content-center align-items-center'>
                                <img src={toAbsoluteUrl('/media/svg/michigan-icons/elements/quiz_white.svg')} alt="metronic" style={{width: '30px'}} />
                                <span className='ms-2 fs-4 poppins-light text-white'>Quiz</span>    
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>
        </>
    );
};

export { MainCardContent };