import {Route, Routes} from 'react-router-dom'
import { AppointmentDetails } from '../components/details'
import { AppointmentTableList } from '../components/list'


const AppointmentPage = () => (
  <Routes>
      <Route path='/details' element={<AppointmentDetails />} />
      <Route path='/' element={<AppointmentTableList />} />
  </Routes>
)

export {AppointmentPage}
