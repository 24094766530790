import React, { useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';
import './ExerciseEight.css'


type ExerciseFiveProps = {
  
  question: string;
  textarea: string;
  buttonNext: string;
  instruction:string
}

const ExerciseEight: React.FC<ExerciseFiveProps> = ({}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const divStyle = {
    backgroundColor: '#3f8bff',
    width: '525px',
    height: 'auto',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px'
  };
 

  const handleSelect = (option) => {
    setSelectedOption(option);
  };
    //champ-bold    champ-light  poppins-light poppins-bold 
    return (
    <>  
     <div className="container-fluid" style={{display: 'flex'}}>
           <div style={divStyle}>
           <HeaderCard/>
                 <div className="text-white p-2 fs-8 mb-0 poppins-light" style={{marginLeft:'10px'}}>2. Chose the correct answer according to the previous text.
                 </div>
                 <div className="container">
                    <div className="row py-2">

                        <div className='col-6 '>
                        <div>
                          <li className="fs-8 poppins-light mb-0"><strong>What's the reading about?</strong></li>
                            <span
                              className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book' : ''}`}
                              onClick={() => handleSelect('a')}
                            >
                              a. Habits to be successful
                            </span><br />
                            <span
                              className={`fs-8 poppins-light mb-0 ${selectedOption === 'b' ? 'select-simple-book' : ''}`}
                              onClick={() => handleSelect('b')}
                            >
                              b. A description of routine
                            </span><br />
                            <span
                              className={`fs-8 poppins-light mb-0 ${selectedOption === 'c' ? 'select-simple-book' : ''}`}
                              onClick={() => handleSelect('c')}
                            >
                              c. A research on habits and success
                            </span>
                         </div>
                        </div>
                    
                        <div className='col-6 '>
                        <div>
                          <li className="text-white fs-8 poppins-light mb-0"><strong>What's the reading about?</strong></li>
                            <span
                              className={`fs-8 poppins-light mb-0 ${selectedOption === 'd' ? 'select-simple-book' : 'text-white'}`}
                              onClick={() => handleSelect('d')}
                            >
                              a. Habits to be successful
                            </span><br />
                            <span
                              className={`fs-8 poppins-light mb-0 ${selectedOption === 'e' ? 'select-simple-book' : 'text-white'}`}
                              onClick={() => handleSelect('e')}
                            >
                              b. A description of routine
                            </span><br />
                            <span
                              className={`fs-8 poppins-light mb-0 ${selectedOption === 'c' ? 'select-simple-book' : 'text-white'}`}
                              onClick={() => handleSelect('c')}
                            >
                              c. A research on habits and success
                            </span>
                         </div>
                        </div>
                     </div>
                     <div className="row">

                    <div className='col-6 '>
                    <div>
                      <li className="text-white fs-8 poppins-light mb-0"><strong>What's the reading about?</strong></li>
                        <span
                          className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book' : 'text-white'}`}
                          onClick={() => handleSelect('a')}
                        >
                          a. Habits to be successful
                        </span><br />
                        <span
                          className={`fs-8 poppins-light mb-0 ${selectedOption === 'b' ? 'select-simple-book' : 'text-white'}`}
                          onClick={() => handleSelect('b')}
                        >
                          b. A description of routine
                        </span><br />
                        <span
                          className={`fs-8 poppins-light mb-0 ${selectedOption === 'c' ? 'select-simple-book' : 'text-white'}`}
                          onClick={() => handleSelect('c')}
                        >
                          c. A research on habits and success
                        </span>
                    </div>
                    </div>

                    <div className='col-6'>
                    <div>
                      <li className="text-white fs-8 poppins-light mb-0"><strong>What's the reading about?</strong></li>
                        <span
                          className={`fs-8 poppins-light mb-0 ${selectedOption === 'a' ? 'select-simple-book' : 'text-white'}`}
                          onClick={() => handleSelect('a')}
                        >
                          a. Habits to be successful
                        </span><br />
                        <span
                          className={`fs-8 poppins-light mb-0 ${selectedOption === 'b' ? 'select-simple-book' : 'text-white'}`}
                          onClick={() => handleSelect('b')}
                        >
                          b. A description of routine
                        </span><br />
                        <span
                          className={`fs-8 poppins-light mb-0 ${selectedOption === 'c' ? 'select-simple-book' : 'text-white'}`}
                          onClick={() => handleSelect('c')}
                        >
                          c. A research on habits and success
                        </span>
                    </div>
                    </div>
                    </div>
                 </div>
               
           </div>
            <div className="position-relative" /*onClick={handleCheck}*/>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
       </div>
    </>
    );
};
export { ExerciseEight };
