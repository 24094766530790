import React from 'react';
import { UseTrueFalseModule } from './store';
import { TrueOrFalseBookItem } from './components/book';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';

type Props = {
    userMode?: ModeTypeExercise
};

const TrueOrFalseBookContainer: React.FC<Props> = ({ userMode }) => {
    let { 
        questions,
        setAnswer, 
    } = UseTrueFalseModule();
    
    return (
    <>
        <TrueOrFalseBookItem
            userMode={userMode}
            questions={questions}
            handleSendAnswer={(e) => setAnswer(e)}
        />
    </>
    );
};

export { TrueOrFalseBookContainer };