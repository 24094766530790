import React from "react";
import { useParams } from "react-router";
import { TopicsTableList } from "../list";
import { TopicsTopWidget } from "./top-widget";
import { Lessons } from "../../../lessons/interfaces";
import { UseLessonOne } from "../../../lessons/services";
import { KTIcon } from "../../../../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const TopicsDashboard: React.FC = () => {
    const [lesson, setLesson] = React.useState<Lessons>();
    const {id} = useParams();
    const navigate = useNavigate();
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UseLessonOne(id??'')
                setLesson(response)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    return (
        <div className="row">
            <div>
                <a href={`#`} className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => navigate(-1)}>
                    <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
                </a>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
                <TopicsTableList />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
                {lesson && <TopicsTopWidget item={lesson}/> }
            </div>
        </div>
    )
};

export {TopicsDashboard}