/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'

import moment from 'moment'
import { UseBookingModule } from '../../me-booking/store';
import { Booking } from '../../admin/appointment/interfaces';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import CustomButton from '../../../components/common/custom-button';
import { UseMyRoadmapModule } from '../../profile/components/my-road-step/store';
import { useTranslation } from 'react-i18next';
import { useThemeMode } from '../../../../_metronic/partials';
import { UseBookingWindows } from '../store';


type Props = {
  className: string;
  items: any[]
  day: Date | undefined
  setBooking: (level: Booking) => void;
}

const BookingWindowsList: React.FC<Props> = ({ items, setBooking, className, day }: Props) => {
  const { t } = useTranslation();
  const { mode } = useThemeMode();
  let {
    myAgenda,
    classByWeek
  } = UseBookingModule();
  let { classType } = UseBookingWindows()
  const { settings } = UseMyRoadmapModule.getState()

  const finderMyAgenda = React.useMemo(() => {
    return (id: string, time: string) => {
      if (myAgenda && myAgenda.length > 0) {
        if (myAgenda.some((item) => item.appointment === id) || myAgenda.some((item) => item.start === time)) {
          return false
        } else {
          return true
        }
      } else {
        return true;
      }
    };
  }, [myAgenda]);
  return (
    <div className={clsx('card', className)} style={{ width: '95%', borderRadius: 10, maxHeight: '280px', height: '280px' }}>
      <div className='card-body pt-3 scroll-y me-n7 pe-7 ms-n7 ps-10' style={{ maxHeight: '235px' }}>
        {settings && classByWeek && (
           (classByWeek.result?.length >= settings.classAvailable) ||
           (classType === 'CLASS' && classByWeek.numberClassLessons?.length >= settings.hoursUnits) ||
           (classType === 'WORKSHOP' && classByWeek.numberClassWorkshops?.length >= settings.hoursActivity)
        ) ? <>
          <h2 className='champ-light text-center' 
            style={{ 
              color: mode === 'light' ? '#2d30e1' : '#FFFFFF', 
              paddingTop: '90px' 
            }}>{t('MESSAGE_FULL_BOOKING')}</h2>
        </>
        : <>
          {items.map((x) => (
            finderMyAgenda(x.id, x.time.start) &&
            <div className='d-flex mb-7'>
              <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                  <a href='#' className='fs-5 champ-light text-gray-800 text-hover-primary fw-bolder'>
                    {x.title}
                  </a>
                  <span className='text-gray-400 fw-bold fs-7'>
                    {finderMyAgenda(x.id, x.time.start) ?
                      <CustomButton
                        buttonDisabled={x.disabledButton ?? undefined}
                        title='Reserve'
                        type='sm'
                        color='PRIMARY'
                        handleClick={() => setBooking(x)} />
                      :
                      <p className='champ-light'>
                        Class has already booked it
                      </p>
                    }
                  </span>
                </div>
                <div className='text-end py-lg-0 py-2'>
                  <span className='text-gray-600 fw-boldest fs-3 champ-light'>{moment(x.time.start).format('hh:mm a')}</span>
                  <span className='text-gray-700 fs-7 fw-bold d-block'>{moment(x.time.start).format('DD/MM/YYYY')}</span>
                </div>
              </div>
            </div>
          ))}
          {items.length < 1 && <h2 className='champ-light text center' style={{ color: '#2d30e1' }}>There are no classes available...</h2>}
        </>}
      </div>
    </div>
  )
}

export { BookingWindowsList }
