/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { StatisticsWidget1} from '../../../../../../_metronic/partials/widgets'
import { TeacherWidgets } from './teacher-widgets'
import { UseBookingDashboard } from '../../../store';
import moment from 'moment';


const 
DashboardWidget: React.FC = () => {
    const { itemSelected } = UseBookingDashboard();
    return (
        <div className='row g-5 g-xl-8'>
            <div className='col-xl-4'>
                {itemSelected && itemSelected.classroom && <TeacherWidgets
                    className='card-xl-stretch mb-xl-8'
                    avatar='/media/svg/avatars/029-boy-11.svg'
                    title={itemSelected?.teacher.fullName.toUpperCase()}
                    description={itemSelected?.classroom.name.toUpperCase()}
                />}
            </div>

            <div className='col-xl-4'>
            {itemSelected &&<StatisticsWidget1
                    className='card-xl-stretch mb-xl-8'
                    image='abstract-2.svg'
                    title={itemSelected.activity?.name??''}
                    time={itemSelected.status_appointment??''}
                    description={''}
                />}
            </div>

            <div className='col-xl-4'>
            {itemSelected &&<StatisticsWidget1
                    className='card-xl-stretch mb-5 mb-xl-8'
                    image='abstract-1.svg'
                    title={`Agendados : ${itemSelected.trail_class.length}`}
                    time={`${moment(itemSelected.start).format('DD/MM/YYYY')} ${itemSelected.blockHour[0].start}`}
                    description=''
                />}
            </div>
        </div>
    )
}

export { 
    DashboardWidget
 }


