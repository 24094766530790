import {create} from 'zustand';
import { Appointment, AppointmentStatus, Pagination } from '../interfaces';
import moment from 'moment'

type DateRange ={
    before:Date
    after:Date
}

interface AppointmentModules {
    itemSelected?: Appointment 
    blockHour?: number,
    classroom?: string,
    typeClassroom?: string,
    transferClass:boolean,
    mode:'ADD'|'EDIT'|'VIEW',
    modal:boolean,
    isActive:boolean,
    campus?:string,
    before:Date,
    after:Date
    paginator:Pagination
    where:string
    teacher?:string
    activity?:string
    step:number 
    level?:string
    currentPage:number
    limit:number
    total:number
    statusAppointmentType?:AppointmentStatus
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setTeacher:(mode:string)=>void;
    setActivity:(mode:string)=>void;
    setSelectedToEdit:(item:Appointment)=>void;
    setSelectedToView:(item:Appointment)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setLimit:(value:number)=>void,
    setWhere:(value:string)=>void,
    setLevel:(value:string)=>void,
    setToAdd:()=>void,
    setStep:(mode:number)=>void;
    resetLevel:()=>void;
    setDate:(value:DateRange)=>void;
    setCampus:(value:string)=>void;
    setBlockHour:(value: number|undefined)=>void,
    setClassroom:(value:string)=>void;
    setTypeClassroom:(value:string)=>void;
    setTransferClass:(value:boolean)=>void;
    setStatusAppointmentType:(value: AppointmentStatus | undefined) =>void;
}

export const UseAppointmentModule = create<AppointmentModules>( (set) => ({
    mode:'ADD',
    modal:false,
    currentPage:1,
    transferClass:false,
    where:'',
    limit:10,
    isActive:true,
    after:moment().toDate(),
    before:moment().toDate(),
    total:0,
    level:"",
    step:1,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setStep:(value:number)=>{
        set({step:value})
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    resetLevel:()=>{
        set({
            step:1
        })
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT',modal:true})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false})
    },
    setPaginator:(pagination)=>{
        console.log(pagination)
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setTeacher:(value)=>{
        set({teacher:value})
    },
    setActivity:(value)=>{
        set({activity:value})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setLevel:(value:string)=>{
        set({level:value})
    },
    setDate:(value:DateRange)=>{
        set({
            after:value.after,
            before:value.before
        })
    },
    setCampus:(value)=>{
        set({ campus: value })
    },
    setBlockHour(value) {
        set({ blockHour: value })
    },
    setClassroom:(value)=>{
        set({ classroom: value })
    },
    setTypeClassroom:(value)=>{
        set({ typeClassroom: value })
    },
    setTransferClass(value) {
        set({ transferClass: value })
    },
    
    setStatusAppointmentType(value) {
        set({ statusAppointmentType: value })
    },
}));