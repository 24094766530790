import React from 'react'
import { UseUserModule } from '../../store/main'
import { UseUserChatLink } from '../../services'
import * as Swal from 'sweetalert2'
import { showSimpleAlert } from '../../../../../commonHooks/alert'

type Props = {}
const ChatLinkedComponent: React.FC<Props> = ({ }: Props) => {
    const { usersToActions,resetUsers, setActionsModal } = UseUserModule()
    const loadChat=()=>{
        let users:string[] = usersToActions.map(x => x.id)
        if(users.length>0 ){
            try{
                UseUserChatLink(users)
                showSimpleAlert({ message: 'Ok!', icon: 'success' })
            }catch{
                showSimpleAlert({ message: 'Ok!', icon: 'error' })
            }
        }
    }
    const loadUsers = async () => {
        //@ts-ignore
        Swal.fire({
          title: 'Estas seguro?',
          text: "estas seguro de vincular a esos usuarios al chat?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si , vincular!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              loadChat()
              resetUsers();
              setActionsModal(false)
            } catch (e) {
    
            }
          }
        })
      }
    return (<div className='card p-5'>
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Email</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Rol</th>
                </tr>
            </thead>
            <tbody>
                {usersToActions.map((x, index) => (<tr>
                    <th scope="row">{index + 1}</th>
                    <td>{x.email}</td>
                    <td>{x.fullName}</td>
                    <td>@{x.type}</td>
                </tr>))}
                <tr>
                  
                    <td colSpan={3}> </td>
                    <td>
                        <button
                        onClick={()=>loadUsers()}
                        type="button" className="btn btn-success">Cargar</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>)
}
export { ChatLinkedComponent }
