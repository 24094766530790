import { useQueryClient } from 'react-query';
import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { Evaluation, EvaluationItem } from '../../../../admin/evaluation/interfaces';
import { Lessons } from '../../../../abroad/learning-path/modules/lessons/interfaces';
import { setQuestionsEbook } from '../../../utils/setQuestionsEBook';
import { Exercises } from '../../../../admin/exercises/interfaces';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseModeCourseModule } from '../../../../courses/components/front-page/store/main';

export type CurrentPage =0| 1| 2| 3
interface BookTestModules {
    currentsItemsTest: EvaluationItem[];
    evaluationBookTest?: Evaluation;
    lessonData?: Lessons;
    statePage: 1 | 2;
    currentPage: 1 | 2;
    setStatePage:(value: 1 | 2)=>void;
    setCurrentsItemsTest:(value: EvaluationItem)=>void;
    setEvaluationBookTest:(value: Evaluation)=>void;
    setLessonData:(values: Lessons)=>void;
    setCurrentPage:(value: CurrentPage)=>void;
    setDeleteCurrentItem:(value: string)=>void;
    resetCurrentItems:()=>void;
}

export const UseBookTestModule = create(persist<BookTestModules>( (set, get) => ({
    currentsItemsTest: [],
    currentPage: 1,
    statePage: 2,
    resetCurrentItems:()=>{
        set({currentsItemsTest:[]});
    },
    setCurrentsItemsTest:(value)=>{
        const findItem = get().currentsItemsTest.find(x => x.id === value.id);
        const findTypeExercise = get().currentsItemsTest.find(x => x.exercise?.type === value.exercise?.type);
        let currentItems: EvaluationItem[]; 

        if (findItem) {
            currentItems = [...get().currentsItemsTest];
        } else if (findTypeExercise) {
            currentItems =[...get().currentsItemsTest.filter(x => x.id !== findTypeExercise.id), value]
        } else {
            currentItems = [...get().currentsItemsTest, value];
        }

        currentItems?.forEach((x) => {
            setQuestionsEbook(x);
        });
        set({currentsItemsTest: currentItems});
    },
    setEvaluationBookTest:(value)=>{
        set({evaluationBookTest: value});
    },
    setLessonData:(value)=>{
        set({lessonData: value});
    },
    setStatePage:(value)=>{
        set({statePage: value});
    },
    setCurrentPage:(value: CurrentPage)=> {
        /* 
            @param Value 0 es volver a la leccion anterior
            Value 1 es poner la primera pagina
            Value 2 es poner la segunda pagina
            Value 3 es pasar a la siguiente leccion
        */  
        const lessonId = get().lessonData        
        if (lessonId && lessonId.book_pages && lessonId.book_pages.length > 0) {
            const lessons = UseModeCourseModule.getState().lessonsId;
            let currentChapter: undefined | any = undefined;
            if (lessons && lessonId) {
                currentChapter = lessons.find(x => x.id === lessonId.id)
            }            
            if (value === 3) { 
                if (currentChapter && lessons) {
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
                    let nextLesson = chapterLessons.find(x => x.order > currentChapter.order);
                    if (nextLesson) {
                        if(nextLesson.typeScreen === 'BOOK'){
                            window.location.replace(`test?lesson=${nextLesson.id}&mode=ADMIN`);
                        } else {
                            window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                        }
                    } else {
                        const nextChapter = lessons.filter(x => x.chapter_order > currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        if (nextChapter) {
                            // Filtrar y ordenar las lecciones del próximo capítulo
                            const nextChapterLessons = lessons.filter(x => x.chapter_order === nextChapter.chapter_order).sort((a, b) => a.order - b.order);
                            nextLesson = nextChapterLessons.find(x => x.order >= 0);
                            if (nextLesson) {
                                if(nextLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`test?lesson=${nextLesson.id}&mode=ADMIN`);
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones posteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({ message: 'No hay lecciones posteriores', icon: 'info' });
                        }
                    }
                }
            } 
            else if ( value === 0){
                if( currentChapter && lessons){
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => b.order - a.order);
                    let prevLesson = chapterLessons.find(x=> x.order < currentChapter.order)
                    if (prevLesson){
                        if(prevLesson.typeScreen === 'BOOK'){
                            window.location.replace	(`test?lesson=${prevLesson.id}&mode=ADMIN`)
                        } else if(prevLesson.typeScreen) {
                            window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                        }
                    } else {
                        const prevChapter = lessons.filter(x => x.chapter_order < currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        if (prevChapter) {
                            const prevChapterLessons = lessons.filter(x => x.chapter_order === prevChapter.chapter_order).sort((a, b) => b.order - a.order);
                            prevLesson = prevChapterLessons[0];  // Tomar la última lección del capítulo anterior
                            if (prevLesson) {
                                if(prevLesson.typeScreen === 'BOOK'){
                                    window.location.replace	(`test?lesson=${prevLesson.id}&mode=ADMIN`)
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones anteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({message: 'No hay lecciones anteriores', icon: 'info'})	
                        }
                    }
                } 
            } else {
                set({currentPage: value})
                return
            }
        }
        set({currentPage: 1});
    },
    setDeleteCurrentItem:(value)=> {
     
        const filteredItems = get().currentsItemsTest.filter(x=>x.id !== value)
        set({currentsItemsTest: filteredItems})
    }
    }),

    {
        name: 'content-book-test'
    }
));

export const transformStringToJson = (
    exercise: any/*Exercises*/, 
    campExercise: string, 
    campString:string,
    type: 'all' | 'camp',
    findExercise?: string,
) => {

    if (type === 'all') {
        return {
            ...exercise,
            [campExercise]: exercise[campExercise]?.map((x) => ({
                ...x,
                [campString]: x[campString]?.map((q) => JSON.parse(q))
            }))
        }
    };

    if (type === 'camp') {
        if(!findExercise) {
            if (exercise[campExercise].length > 0 && exercise[campExercise][0][campString].length > 0) {
                return [
                    ...exercise[campExercise][0][campString]?.map((q) => JSON.parse(q))
                ]
            } else {
                return showSimpleAlert({
                    message: "No existen ejercicios de este tipo",
                    icon: "error"
                })
            }
        } else {
            if (exercise[campExercise].length > 0) {
                const foundExercise = exercise[campExercise]?.find(item => item?.exercises?.id === findExercise)
                if (foundExercise) {
                    const dataExercise = foundExercise[campString]
                    if (Array.isArray(dataExercise)) {
                        return [
                            ...foundExercise[campString]?.map((q) => JSON.parse(q))
                        ]
                    } else {
                        return JSON.parse(dataExercise)
                    }
                } else {
                    return showSimpleAlert({
                        message: "No se encontró el ejercicio.",
                        icon: "error"
                    })
                }
            } else {
                // return showSimpleAlert({
                //     message: "No existen ejercicios de este tipo",
                //     icon: "error"
                // })
            }
        }
    };
}; 