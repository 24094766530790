import React, { useEffect } from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseTrueFalseModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';

type Props = {
    
};

const TrueFalseExercise: React.FC<Props> = ({ }) => {
    const { mode } = useThemeMode();
    const [question, setQuestion] = React.useState<number>(0);
    let { 
        questions,
        step, 
        setAnswer, 
        status, 
        answer,
        setStatus,
        startQuiz,
        title,
        instructions
    } = UseTrueFalseModule();
    
    return (
        <>
            {status != 'RESUME' && status != 'TITLE' &&
                <BasicVocabularyExerciseItem
                    questions={questions}
                    handleNext={(e) => {}}
                    handleSendAnswer={(e) => setAnswer(e)}
                    modeQuiz={status}
                    totalQuestions={questions.length}
                    setStatus={(e) => setStatus(e)}
                    setStep={(e) => console.log(step + e)}
                />}
            {status === 'RESUME' &&
                <BasicVocabularyExerciseResume
                    total={questions.length}
                    correct={answer.length}
                    incorrect={answer.length}
                    setStatus={(e) => setStatus(e)}
                    answer={answer}
                />}
            {status === 'TITLE' && 
                <IntroBasicVocabulary 
                    title={title}
                    instructions={instructions}
                    startQuiz={()=>startQuiz()}
                />
            }
        </>
    );
};

export { TrueFalseExercise };