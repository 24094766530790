/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers' 
import { Booking } from '../../../../admin/appointment/interfaces'
import moment from 'moment'

type Props = {
  item:Booking
  setBooking: (level: Booking) => void;
}

const BookingItem: React.FC<Props> = ({setBooking, item }:Props) => {
  return (
    <button className='btn btn-lg btn-success m-2' onClick={()=>setBooking(item)}> {moment(item.time.start).format('hh:mm')} </button>
  )
}

export {BookingItem}
