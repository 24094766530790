import React from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { UseSpeakOutModule } from './store';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';
import { ExerciseTimeAndWriting } from './components/writingAndTime';
 

type Props = {
    userMode?: ModeTypeExercise
};

const SpeakOutExercise: React.FC<Props> = ({ userMode }) => {
 
    const { 
        studentAnswer,
        mode,
        setStudentAnswer
    } = UseSpeakOutModule();
    
    return (
    <>
        {mode &&
            <BasicVocabularyExerciseItem
                userMode={userMode}
                question={''}
                type={mode}
                handleSendAnswer={(e) => console.log(e)}
                studentAnswer= {studentAnswer}
                setStudentAnswer={setStudentAnswer}
            /> 
        }
    </>
    );
};

export { SpeakOutExercise };