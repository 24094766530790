export const SidebarTranslateEs = {
  PAYMENTS:"Pagos",
  TESTS: "Evaluaciones",
  CONSTANCY:"Constancia",
  FREEZING:"Congelamiento",
  SCHEDULE_HISTORY:"Historial De Reservas",
  CHAT:"Chat",
  SUPPORT:"Soporte",
  ATTENDANCE: "Asistencia",
  LEARNING_PATH: "Learning path"
  }