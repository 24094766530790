import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'

import { useQueryClient } from '@tanstack/react-query'
import { UseBookingDashboard } from '../../store'
import { Lead } from '../../interfaces'
import { useParams } from 'react-router-dom'
import { UseGetOneAppointment } from '../../../admin/appointment/services'
import useAuth from '../../../../api/main'
import { AssistantTeacherTrailClass } from '../../../abroad/trail/services'

export type FormFields = {
  isActive: boolean
  lateArrival:boolean
  assistantTeacher: boolean
  teacherObservation: string
  attended?:string
  arrival?:string
}
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const entitySchema = Yup.object().shape({
  teacherObservation: Yup.string(),
  assistantTeacher: Yup.string(),
  isActive: Yup.boolean(),
  lateArrival: Yup.boolean(),
  attended:Yup.string(),
})

type Props = {

}
const LeadsForm: React.FC<Props> = (props: Props) => {
 
  let { code } = useParams();
  let urlString = window.location.href;
  let url = new URL(urlString);
  let params = new URLSearchParams(url.search);
  const { trail_class, setModal,setItem } = UseBookingDashboard();
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        lateArrival: trail_class ? trail_class.lateArrival : false,
        isActive: trail_class ? trail_class.isActive : false,
        assistantTeacher: trail_class ? trail_class.assistantTeacher : false,
        teacherObservation: trail_class ? trail_class.teacherObservation : '',
        attended: trail_class && trail_class.assistantTeacher ? 'YES' : 'NOT',
        arrival: trail_class && trail_class.lateArrival ? 'YES' : 'NOT'
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (trail_class) {
          try {
            setLoading(true);
            await AssistantTeacherTrailClass( trail_class.id,{
              ...formData,
              assistantTeacher:formData.attended==='YES'?true:false,
              attended:undefined,
              arrival:undefined,
              lateArrival:formData.arrival==='YES'?true:false,

            } )
            setLoading(false)
            findBooking()
            setModal(false)
          } catch (e) {
            setLoading(false)

          }
        }

      },
    });

    let findBooking=async ()=>{
      if(code){
          try{
              let data = await UseGetOneAppointment(code);
              setItem(data)
          }catch(e){
              
          }
      }
  }

  useEffect(() => {
    setFieldValue('teacherObservation',trail_class?.teacherObservation??'')
    setFieldValue('attended',trail_class?.assistantTeacher?'YES':'NOT')
    setFieldValue('assistantTeacher',trail_class?.assistantTeacher )
  }, [trail_class])

  return (
    <div className='row'>

      <div id='kt_account_profile_details' className='collapse show col-md-12 p-16'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-4 row'>

            <div className='mb-10 col-12'>
              <label className='form-label mb-3 champ-light'>Asistió</label>
              <select
                name={'attended'}
                value={values.attended}
                onChange={handleChange}
                className="form-select" aria-label="Default select example">
                <option value={'YES'}>SI</option>
                <option value={'NOT'}>NO</option>
              </select>
              {errors.attended && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.attended}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-12'>
              <label className='form-label mb-3 champ-light'>LLegó tarde</label>
              <select
                name={'arrival'}
                value={values.arrival}
                onChange={handleChange}
                className="form-select" aria-label="Default select example">
                <option value={'YES'}>SI</option>
                <option value={'NOT'}>NO</option>
              </select>
              {errors.arrival && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.arrival}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-12'>
              <label className='form-label mb-3 champ-light'>Description</label>
              <textarea
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                name={'teacherObservation'}
                value={values.teacherObservation}
                onChange={handleChange}
              />
              {errors.teacherObservation &&<label style={{
                color:'red'
              }}>Campo inválido</label>}
            </div>




            {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Código</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
              {errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Contrato</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              name={'contract'}
              value={values.contract}
              onChange={handleChange}
            />
              {errors.contract && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.contract}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Referencia</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              name={'ref'}
              value={values.ref}
              onChange={handleChange}
            />
              {errors.ref && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.ref}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Origen</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              name={'source'}
              value={values.source}
              onChange={handleChange}
            />
              {errors.source && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.source}</span>
                </div>
              </div>
            )}
          </div> */}
            {/* 
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {errors.code &&<label style={{
              color:'red'
            }}>Campo inválido</label>}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {errors.description &&<label style={{
              color:'red'
            }}>Campo inválido</label>}
          </div>*/}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>


            <button type='submit' className='btn btn-primary' 
            disabled={loading}
            >
              {!loading && 'Editar'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { LeadsForm }
