import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Book} from '../../interfaces'

import { BookAdd, BookUpdate } from '../../services'
import { UseBookModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../abroad/road-map-step/interfaces'
import { t } from 'i18next'
import FileUploadComponent from '../../../../../components/common/SingleFile'
import { favIconAppSelector } from '../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  description: string
  author: string
  level: string
  name: string
  isActive: boolean
  cover:string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const BookForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseBookModule();
  const [data, setData] = useState<Book>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [cover, setCover] = React.useState<any>()
  const  changeAvatar =async (obj:any)=> {  
    setCover(obj)
    setFieldValue('cover',obj.id)
  }

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        cover: (mode==='EDIT' || mode==='VIEW')? itemSelected?.cover.id??'': '',
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_2 : TraditionalLevelEnum.PRE_A1_2,
        author: (mode==='EDIT' || mode==='VIEW')? itemSelected?.author??'': '',
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
      },
      validationSchema: entitySchema,

      
      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await BookAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['book'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  BookUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['book'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
    useEffect(() => {
      setCover(itemSelected?.cover)
    }, [itemSelected])
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-12 mx-auto d-flex'>
            { (cover && (cover.url || cover.location)) ? 
                <img  className="img-fluid" width={'50%'} src={(cover.url||cover.location)??toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
                :
                <img  className="img-fluid" width={'50%'} src={toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
            }
                <FileUploadComponent 
                  onChangeFile={(e)=>changeAvatar(e)} 
                  onChangeLoading={(e)=>setLoading(e)}  />
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Level</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={values.level}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Author</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'author'}
              value={values.author}
              onChange={handleChange}
            />
            {touched.author && errors.author && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.author}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {BookForm}
