/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { StatisticsWidget1} from '../../../../../../_metronic/partials/widgets'
 
import moment from 'moment';
import { SingleWidget } from '../../../../../components/common/widgets/single-widget';
import { RoadMapStepFindOne } from './interface';

 type Props = {
    item:RoadMapStepFindOne
 }
  const ActivityWidget: React.FC<Props> = ({item}) => {
    return (
        <div className='row g-5'>
            <div className='col-xl-12'>
                <SingleWidget
                    className='card-xl-stretch '
                    avatar='/media/svg/extra/book.svg'
                    title={`Programa:  ${item.chapter.path.name??''}` }
                    description={`Unidad:  ${item.chapter.name??''}` }
                /> 
            </div>

            <div className='col-xl-12'>
                <SingleWidget
                    className='card-xl-stretch '
                    avatar='/media/svg/extra/course.svg'
                    title={`Modalidad:  ${item.sequence??''}` }
                    description={`Nivel:  ${item.level??''}` }
                /> 
            </div>

            <div className='col-xl-12'>
                <SingleWidget
                    className='card-xl-stretch '
                    avatar='/media/svg/extra/lesson.svg'
                    title={`Horas:  ${item.hours??''}` }
                    description={`Lecciones:  ${item.lessons??''}` }
                /> 
            </div>

            
            <div className='col-xl-12'>
                <SingleWidget
                    className='card-xl-stretch '
                    avatar='/media/svg/extra/minimun.svg'
                    title={`Nivel Mínimo:  ${item.levelToSee??''}` }
                    description={`Horas Mínimo:  ${item.hoursToSee??''}` }
                /> 
            </div>
        </div>
    )
}

export { 
    ActivityWidget
 }


