import {create} from 'zustand';
import { MasterClassLevel } from '../../admin/master-class-level/interfaces';
import { Booking } from '../../admin/appointment/interfaces';
 


interface BookingInterface {
    level_id?:string 
    booking_id?:string
    step:number 
    booking?:Booking
    level?:MasterClassLevel
    ref?:string
    setRef:(mode:string)=>void;
    setLevel:(mode:MasterClassLevel)=>void;
    setBooking:(mode:Booking)=>void;
    resetBooking:()=>void;
    setStep:(mode:number)=>void;
    resetLevel:()=>void;
}

export const UseBookingModule = create<BookingInterface>( (set) => ({
    step:1,
    setStep:(value:number)=>{
        set({step:value})
    },
    setLevel:(value:MasterClassLevel)=>{
        set({level:value,level_id:value.id})
    },
    setBooking:(value:Booking)=>{
        set({booking:value,booking_id:value.id, step:3})
    },
    resetBooking:()=>{
        set({
                booking:undefined,
                booking_id:undefined, 
                step:2
            })
    },
    resetLevel:()=>{
        set({
            booking:undefined,
            booking_id:undefined, 
            level:undefined,
            level_id:undefined,
            step:1
        })
    },
    setRef:(ref:string)=>{
        set({ref:ref})
    }
}));