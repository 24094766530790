import {Route, Routes} from 'react-router-dom'
import { RoadMapTableList } from '../components/list'
import { RoadMapDetails } from '../modules/components'


const RoadMapPage = () => (
  <Routes>
      <Route path='/details/:id' element={<RoadMapDetails />} />
      <Route path='/' element={<RoadMapTableList />} />
  </Routes>
)

export {RoadMapPage}
