 import {create} from 'zustand';
import { Answer, QuestionHistoryWriting, quiz,QuestionAndAnswer, questions, HistoryWritingResponseExercise } from '../interfaces';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import { HistoryWriting } from '../../../../admin/exercises/templates-exercise/history-writing/interfaces';
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';
interface TitleAndInstruction {
  title: string;
  instruction: string;
}
interface HistoryWritingInterface {
  status:StatusQuiz
  questions:QuestionHistoryWriting[];
  questionFull: HistoryWriting[];
  title:string;
  instructions:string;
  step:number;
  limit:number;
  answerStudent:HistoryWritingResponseExercise[]
  list:Answer[]
  total:number
  mode:'IMAGE'|'WORDS';
  exerciseId?: string;
  setAnswer:(value: HistoryWritingResponseExercise)=>void;
  setStatus:(StatusQuiz)=>void
  setStep:(number)=>void;
  startQuiz:()=>void;
  setQuestionsHistory:(
    exerciseId: string, 
    instruction: string,
    value:QuestionHistoryWriting[])=>void;
  setQuestionsHistoryFull:(
    exerciseId: string, 
    instruction: string,
    value:HistoryWriting[])=>void;
  setTitle:(value: TitleAndInstruction)=>void;
  resetData:()=>void;
}

export const UseHistoryWritingModule = create<HistoryWritingInterface>( (set, get) => ({
    status:'TITLE',
    title:"",
    instructions:"",
    questions:[],
    questionFull: [],
    step:0,
    answerStudent:[],
    list:[],
    limit:200,
    total:0,
    mode:'WORDS',
    setAnswer:(value: HistoryWritingResponseExercise)=>{
      set({answerStudent: [...get().answerStudent, value]});
    },
    setStep:(value:number)=>{
      set({
        step:value,
      })
    },
    startQuiz(){
      set({
        status:'ANSWER'
      })
    },
    setStatus:(value:StatusQuiz)=>{
  
    },
    setQuestionsHistory:(exerciseId, instructions, value)=>{
      set({questions: value, exerciseId, instructions})
    },
    setQuestionsHistoryFull:(exerciseId, instructions, value)=>{
      set({questionFull: value, exerciseId, instructions})
    },
    setTitle:(value)=>{
      set({title: value.title, instructions: value.instruction})
    },
    resetData:()=>{
      set({
        status:'TITLE',
        title:"Este es un titulo de quiz",
        instructions:"esta es una insturccion",
        questions:[],
        step:0,
        answerStudent:[],
        list:[],
        total:0,
      })
    }
}));