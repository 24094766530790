import { Route, Routes } from 'react-router-dom'
import { ParagraphDragDrop } from '../../courses/exercises/paragraph-drag-drop'
import { BasicVocabularyExercise } from '../../courses/exercises/basic-vocabulary'
import BasicVocabularyFullExercise from '../../courses/exercises/basic-vocabulary/full-screen'
import BasicVocabularyCompletionFullScreenExercise from '../../courses/exercises/basic-vocabulary-completion/full-screen'
import { WordBoxContent } from '../../courses/exercises/paragraph-drag-drop/word-box-content'
import TrueFalseFullExercise from '../../courses/exercises/true-false/full-screen'
import HistoryWritingFullExercise from '../../courses/exercises/history-wrinting/full-screen'
import MovieWritingFullExercise from '../../courses/exercises/movie-writing/full-screen'
import { TrueOrFalseBookContainer } from '../../courses/exercises/true-false/book-container'
import { OrderSentences } from '../../courses/exercises/order-sentence'
import { BeyondReadingWritingExerciseOne } from '../../courses/exercises/history-wrinting/beyond-reading-one'
import { DraggableModal } from '../../book/components/modal'
import { WritingCheckList } from '../../courses/exercises/WrintingCheckList'
import { CorrectSentence } from '../../courses/exercises/CorrectSentence'
import { MultipleSelectionEx } from '../../courses/exercises/multiple-selection'
import { TrueOptions } from '../../courses/exercises/true-options'
import { TrueWords } from '../../courses/exercises/true-words'
import { VoiceRecorderInput } from '../../../components/common/voice-recorder'
import { DragDropBeyondReading } from '../../courses/exercises/paragraph-drag-drop/drag-drop-beyond-reading'
import { Coordinates } from '../../courses/exercises/coordinates/ExerciseEleven'
import { GroupWords } from '../../courses/exercises/group-words/ExerciseTwelve'
import { SpeakOutExercise } from '../../courses/exercises/speak-out'
import { CheckWritingEx } from '../../courses/exercises/check-writing'
import { BoosterEx } from '../../courses/exercises/booster'
import  AudioTestFullScreenExercise  from '../../courses/exercises/audio-test/full-screen'
import  VerificationTestFullScreenExercise  from '../../courses/exercises/verficaton-test/full-screen'

// import { AppointmentDetails } from '../components/details'
// import { AppointmentTableList } from '../components/list'


const TestZonePage = () => (
        <Routes>
                <Route path='/basic-vocabulary-completion-full' element={<BasicVocabularyCompletionFullScreenExercise
                        colorSecondary='blue'
                        titleContent='Lionel Pessi'
                        descriptionContent='El genio mundial '
                />} />

                <Route path='/verification' element={<VerificationTestFullScreenExercise
                        colorSecondary='blue'
                        titleContent='Lionel Pessi'
                        descriptionContent='El genio mundial '
                        isLastStep={(e) => console.log(e)}
                        setResponse={(e) => console.log(e)}
                />} />


                <Route path='/audio-test' element={<AudioTestFullScreenExercise
                        colorSecondary='blue'
                        titleContent='Lionel Pessi'
                        descriptionContent='El genio mundial '
                />} />
                <Route path='/basic-vocabulary-full' element={<BasicVocabularyFullExercise
                        colorSecondary='blue'
                        titleContent='Lionel Pessi'
                        descriptionContent='El genio mundial '
                />} />
                <Route path='/true-false' element={<TrueOrFalseBookContainer
                />} />
                <Route path='/movie' element={<MovieWritingFullExercise
                        colorSecondary='blue'
                        titleContent='Movie writing'
                        descriptionContent='Lorem ipsum '
                />} />
                <Route path='/writing-check' element={<DraggableModal>
                        <WritingCheckList userMode={'ADMIN'}/>
                </DraggableModal>} />
                <Route path='/writing' element={<BeyondReadingWritingExerciseOne />} />

                <Route path='/order' element={<DraggableModal>
                        <OrderSentences/>
                </DraggableModal>} />

                <Route path='/correct-sentence' element={<DraggableModal>
                        <CorrectSentence userMode={'ADMIN'}/>
                </DraggableModal>} />

                <Route path='/multiple-selection' element={<DraggableModal>
                        <MultipleSelectionEx/>
                </DraggableModal>} />

                <Route path='/true-options' element={<DraggableModal>
                        <TrueOptions/>
                </DraggableModal>} />

                <Route path='/true-words' element={<DraggableModal>
                        <TrueWords/>
                </DraggableModal>} />

                <Route path='/coordinates' element={<DraggableModal >
                        <Coordinates/> //
                </DraggableModal>} />

                <Route path='/group-words' element={<DraggableModal>
                        <GroupWords/> //
                </DraggableModal>} />

                <Route path='/voice' element={<DraggableModal>
                        <VoiceRecorderInput 
                                    onChangeFile={(e) => console.log(e)} 
                                    onChangeLoading={(e) => console.log(e)}
                                    idInput='bannerVertical'
                        />
                </DraggableModal>} />

                <Route path='/speak-out' element={<DraggableModal>
                        <SpeakOutExercise  />
                </DraggableModal>} /> //

                <Route path='/booster' element={<DraggableModal>
                        <BoosterEx  />
                </DraggableModal>} /> //

                <Route path='/check-writing' element={<DraggableModal>
                        <CheckWritingEx  />
                </DraggableModal>} /> //

                
                

                <Route path='/word-box-reading' element={<DraggableModal>
                        <DragDropBeyondReading />
                </DraggableModal>} />

 

                <Route path='/word-box-reading' element={<DragDropBeyondReading />} />
                <Route path='/word-box' element={<WordBoxContent />} />
                
                <Route path='/' element={<ParagraphDragDrop />} />
        </Routes>
)

export { TestZonePage }
