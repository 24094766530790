import React, { useEffect } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { UseMyPaymentsModule } from '../../store/main'
import * as Swal from 'sweetalert2'
import { MyPaymentsDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import { useNavigate } from 'react-router-dom'
import { useThemeMode } from '../../../../../../_metronic/partials'
import './index.css';
import { useTranslation } from 'react-i18next'
import { logoAppSelectorUrl } from '../../../../../hooks/useAppSelectorAssetsMode'

type PaginatorSetter ={
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const MyPaymentsTableList: React.FC<Props> = ({ }) => {
  const {t} = useTranslation();
  let url = logoAppSelectorUrl();
  const {mode} = useThemeMode();
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const { 
      paginator, 
      modal,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit, 
      setIsActive, 
      setSelectedToEdit, 
      setSelectedToView,
      setModal,
      setWhere,
      setToAdd
  } = UseMyPaymentsModule()
  // const { data, isLoading } = useMyPaymentsState();

  const [buttonStyles, setButtonStyles] = React.useState({
    backgroundColor: '#FFFFFF',
    color: mode==='light'?'#4497ff':'#FFFFFF'
  })

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage:1,
    items:0,
    total:0,
    totalPage:0
});

  const deleteItem = async (id:string)=>{
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          await MyPaymentsDelete(id)
          showSimpleAlert({message:'Ok!',icon:'success'})
         }catch(e){
            
         }
      }
    })
  }
  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['my-payments'] })
  }, [isActive,where,currentPage,limit])
  return (<>

  <div className='card cards-general-radius-payments' style={{ background: mode === 'light' ? '#4497ff' : '', border: mode === 'dark' ? '1px solid #4497ff' : '' }}>
    <div className='card-body pt-0'>
      <div className='d-none d-md-flex d-flex row mt-8 mb-0'>
        <div className='col-md-4 d-flex flex-column'>
          <h3 className='champ-light michigan-title-payments-top text-white'>{url.name}</h3>
          <div className='mb-0'>
            <div className='icon-left-payments'>
              <img
                src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/back_white.svg')}
                alt=''
                onClick={() => navigate('/dashboard')}
                style={{
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                }}
                onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              />
            </div>
          </div>
          <span className='champ-light title-card-payments' style={{color:mode==='light' ? 'white':'#4497ff'}}>{t('PAYMENTS')}</span>
        </div>

        <div className='col-md-6 d-flex justify-content-center p-0'>
          <div className='row text-white'>
            <div className='col-6'>
              <span className='poppins-light fs-5'>Total Contrato:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>$0.000.000,00</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>Valor Pagado:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>$0.000.000,00</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>Pagado por Matricula:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>$0.000.000,00</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>Pagado en Cuotas:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>$0.000.000,00</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>Total en Ajustes:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>$0.000.000,00</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>Total Debe:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fs-5'>$0.000.000,00</span>
            </div>
          </div>
        </div>

        <div className='col-md-2 d-flex justify-content-end'>
            <div className='icon-right-payments'>
              <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/payment_white.svg')} alt='' />
            </div>
        </div>
      </div>
      {/* Window Movil */}
      <div className='d-md-none mt-10 mb-0'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex flex-column'>
            <h3 className='champ-light michigan-title-payments-top'>{url.name}</h3>
            <div className='mb-0'>
              <div className='icon-left-payments'>
                <img
                  src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/back_white.svg')}
                  alt=''
                  onClick={() => navigate('/dashboard')}
                  style={{
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                />
              </div>
            </div>
            <span className='champ-light title-card-payments' style={{color:mode==='light' ? 'white':'#4497ff'}}>{t('PAYMENTS')}</span>
          </div>
          <div className='d-flex justify-content-end'>
              <div className='icon-right-payments'>
                <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/payment_white.svg')} alt='' />
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  {/* Content Movil */}
  <div className='d-md-none mt-8'>
    <div className='card' style={{borderRadius:'30px'}}>
      <div className='row py-3' style={{ color: mode === 'light' ? '#2d30e1' : '#FFFFFF' }}>
        <div className='col-6 ps-16'>
          <span className='poppins-light fs-3'>Total Contrato:</span>
        </div>
        <div className='col-6 d-flex align-items-center'>
          <span className='poppins-light fs-3'>$0.000.000,00</span>
        </div>
        <div className='col-6 ps-16'>
          <span className='poppins-light fs-3'>Valor Pagado:</span>
        </div>
        <div className='col-6 d-flex align-items-center'>
          <span className='poppins-light fs-3'>$0.000.000,00</span>
        </div>
        <div className='col-6 ps-16'>
          <span className='poppins-light fs-3'>Pagado por Matricula:</span>
        </div>
        <div className='col-6 d-flex align-items-center'>
          <span className='poppins-light fs-3'>$0.000.000,00</span>
        </div>
        <div className='col-6 ps-16'>
          <span className='poppins-light fs-3'>Pagado en Cuotas:</span>
        </div>
        <div className='col-6 d-flex align-items-center'>
          <span className='poppins-light fs-3'>$0.000.000,00</span>
        </div>
        <div className='col-6 ps-16'>
          <span className='poppins-light fs-3'>Total en Ajustes:</span>
        </div>
        <div className='col-6 d-flex align-items-center'>
          <span className='poppins-light fs-3'>$0.000.000,00</span>
        </div>
        <div className='col-6 ps-16'>
          <span className='poppins-light fs-3'>Total Debe:</span>
        </div>
        <div className='col-6 d-flex align-items-center'>
          <span className='poppins-light fs-3'>$0.000.000,00</span>
        </div>
      </div>
    </div>
  </div>

    <div className='row'>
      <div className='col-lg-7 col-12 mt-8'>
        <div className='card p-6 px-13 cards-general-radius-payments' style={{ backgroundColor:mode==='light'?'#E6E6FA':'', height: '100%',border:mode==='dark'?'1px solid #E6E6FA':'' }}>
          <div className="row" style={{color:mode==='light'?'#2d30e1':'#E6E6FA'}}>
            <div className='col-6'>
              <span className='poppins-bold fs-4'>Ultimo Pago:</span>
            </div>
            <div className='col-6'></div>

            <div className='col-6 pt-0'>
              <span className='poppins-light fs-5'>Nº de recibo:</span>
            </div>
            <div className='col-6 pt-0'>
              <span className='poppins-light fs-5'>000000000000</span>
            </div>
          
            <div className='col-6 pt-0'>
              <span className='poppins-light fs-5'>Fecha de pago:</span>
            </div>
            <div className='col-6 pt-0'>
              <span className='poppins-light fs-5'>00/00/0000</span>
            </div>
        
            <div className='col-6 pt-0'>
              <span className='poppins-light fs-5'>Valor pagado:</span>
            </div>
            <div className='col-6 pt-0'>
              <span className='poppins-light fs-5'>000000</span>
            </div>
          </div>
        </div>
      </div>

      <div className='col-lg-5 col-12 mt-8'>
        <div className='card d-flex justify-content-center cards-general-radius-payments' 
          style={{ backgroundColor:mode==='light'?'#4497ff':'', height: '100%', border:mode==='dark'?'1px solid #4497ff':'' }}
        >
          <div className='row' style={{color:mode==='light'?'#FFFFFF':'#4497ff'}}>
            <div className='col-6 d-flex flex-column py-7 ps-13'>
              <span className='poppins-bold fs-4'>Proximo pago:</span>
              <span className='poppins-light fs-3'>Fecha:</span>
              <span className='poppins-bold fs-3'>00/00/0000</span>
            </div>
            <div className='col-6 py-7 pe-13 d-flex justify-content-center align-items-center'>
              <button className='btn' type='button' 
                style={{ borderRadius: '25px', width: '100%', ...buttonStyles }}
                onMouseOver={() => {
                  setButtonStyles({
                      backgroundColor: '#2d30e1',
                      color: '#FFFFFF',
                  });
                }}
                onMouseOut={() => {
                    setButtonStyles({
                      backgroundColor: '#FFFFFF',
                      color: '#4497ff'
                    });
                }}
              >
                <span className='poppins-light fs-1'>Pagar</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div className='card mt-8 cards-general-radius-payments' style={{ backgroundColor:mode==='light'?'#E6E6FA':'', border:mode==='dark'?'1px solid #E6E6FA':'' }}>
      <div className='card-body row'>
        <div className='col-9'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw poppins-bold fs-5' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA', borderBottom:mode==='light'?'1px solid #2d30e1':'1px solid #E6E6FA' }}>
                  <th style={{ paddingBottom: '5px' }}>Nº</th>
                  <th style={{ paddingBottom: '5px' }}>Valor Cuota</th>
                  <th style={{ paddingBottom: '5px' }}>Valor Pagado</th>
                  <th style={{ paddingBottom: '5px' }}>Recibo</th>
                  <th style={{ paddingBottom: '5px' }}>Vence</th>
                  <th style={{ paddingBottom: '5px' }}>Saldo</th>
                </tr>
              </thead>
              <tbody className='mt-2'>
                <tr style={{color:mode==='light'?'#2d30e1':'#E6E6FA'}}>
                  <td className='pt-0 pb-0'>
                    <span className='d-block fs-6 poppins-light'>
                      0
                    </span>
                  </td>
                  <td className='pt-0 pb-0'>
                    <span className='d-block fs-6 poppins-light'>
                      000.000.00
                    </span>
                  </td>
                  <td className='pt-0 pb-0'>
                    <span className='d-block fs-6 poppins-light'>
                      000.000.00
                    </span>
                  </td>
                  <td className='pt-0 pb-0'>
                    <span className='d-block fs-6 poppins-light'>
                      MA12145sds
                    </span>
                  </td>
                  <td className='pt-0 pb-0'>
                    <span className='d-block fs-6 poppins-light'>
                      00/00/0000
                    </span>
                  </td>
                  <td className='pt-0 pb-0'>
                    <span className='d-block fs-6 poppins-light'>
                      0.00
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <GenericPaginator 
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
          </div>
        </div>

        <div className='col-3 d-flex flex-column justify-content-center align-items-start' style={{color:mode==='light'?'#2d30e1':'#E6E6FA'}}>
          <span className='poppins-bold fs-3'>
            Asesor de Cobranza:
          </span>
          <span className='champ-bold fs-2'>
            William Hinostroza
          </span>
          <div className='d-flex'>
            <i className="fa-brands fa-whatsapp fs-2 pe-2" style={{ color:mode==='light'?'#2d30e1':'#E6E6FA' }}></i>
            <span className='fs-5'>
              315 6881809
            </span>
          </div>
        </div>
      </div>
    </div>

    {/* <div className={`card `}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>my-payments</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de my-payments </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >

          <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div>
          <button
            onClick={() => setToAdd()}
            className='btn btn-sm btn-light-primary mr-8'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
          </button>
          <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
             <BooleanCell value={isActive} />
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        {!isLoading && data && <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>País</th>
                <th className='min-w-120px'>Descripción</th>
                <th className='min-w-120px'>Active</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
             {isLoading &&<tr className='text-center'>
                  <img  
                  className="rounded mx-auto d-block" 
                  width={'70%'}
                  src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
              </tr>}
              {data.result.map((x: MyPayments) => (
                <tr key={x.id} className='p-0'>
                  <td className='p-0'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {x.name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {x.code}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='p-0'>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {x.description}
                    </span>
                  </td>
                  <td className='text-start'>
                    <BooleanCell value={x.isActive} />
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        onClick={()=>setSelectedToView(x)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <i style={{ color: 'grey', fontSize: 17 }} className="fas fa-eye"></i>
                      </a>
                      <a
                        href='#'
                        onClick={()=>setSelectedToEdit(x)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-pen"></i>
                      </a>
                      <a
                        href='#'
                        onClick={() => deleteItem(x.id??'xxx')}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <i style={{ color: 'red', fontSize: 17 }} className="fas fa-trash-alt"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              <GenericPaginator 
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
        </div>}
      </div>
    </div> */}
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { MyPaymentsTableList }
