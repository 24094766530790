import {Route, Routes} from 'react-router-dom'
import { TransferClassDetails } from '../components/details'
import { TransferClassTableList } from '../components/list'


const TransferClassPage = () => (
  <Routes>
      <Route path='/details' element={<TransferClassDetails />} />
      <Route path='/' element={<TransferClassTableList />} />
  </Routes>
)

export {TransferClassPage}
