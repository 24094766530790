import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ActivityByRoadMapStep, UseActivityAllPaginate } from '../services'
import { UseRoadMapStepOne } from '../../road-map-step/services';

export function useActivityState(toWorkshop?: 1 | 2){
  return  useQuery({ queryKey: ['activity'], queryFn:()=> UseActivityAllPaginate(toWorkshop) })
}

export function useActivityStateByStep(id: string){
  return  useQuery({ queryKey: ['activity', id], queryFn: () => ActivityByRoadMapStep(id) })
}

export function useStepById(id:string) {
  return useQuery({
    queryKey: ['activity-step', id], // Agregar el parámetro a la queryKey
    queryFn: () => UseRoadMapStepOne(id), // Llamar useActivityAllPaginate con el parámetro
  });
}