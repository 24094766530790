import React from 'react';

import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';

    type Props = {
        instruction:string;
    }
const ExerciseTen: React.FC<Props> = ({instruction}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        height: 'auto',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
    return(<>
    
        <div className="d-flex container-fluid">
            <div style={divStyle}>
                <HeaderCard/>
                <p className=" text-white  mb-0 poppins-light fs-9 p-1" style={{marginLeft:'8px'}}>{instruction}</p>
                <div className="container">
                    <div className="row p-2 text-white fs-9 " >
                        <div className="col-2 rounded-circle border border-1 border-white " style={{ width: '40px', height: '40px' }} ><img src="" alt="img"/></div>
                        <div className="col-2 rounded-circle border border-1 border-white " style={{ width: '40px', height: '40px' }} ><img src="" alt="img"/> </div>
                        <div className="col-2 rounded-circle border border-1 border-white " style={{ width: '40px', height: '40px' }} ><img src="" alt="img"/> </div>
                        <div className="col-2 rounded-circle border border-1 border-white " style={{ width: '40px', height: '40px' }} ><img src="" alt="img"/></div>
                        <div className="col-2 rounded-circle border border-1 border-white " style={{ width: '40px', height: '40px' }} ><img src="" alt="img"/></div>
                        <div className="col-2 rounded-circle border border-1 border-white " style={{ width: '40px', height: '40px' }} ><img src="" alt="img"/></div>
                    </div>
                    <div className="table-responsive rounded-4 border border-2 border-white mb-9 mt-2" >
                        <table className="table table-bordered table-sm border-white" >
                            <thead>
                                <tr className="poppins-bold">
                                    <th className='text-primary text-center bg-light p-2' style={{ width: '200px' }}>#vn</th>
                                    <th className='text-primary text-center bg-light p-2' style={{ width: '200px' }}>Last</th>
                                    <th className='text-primary text-center bg-light p-2' style={{ width: '200px' }}>First</th>
                                    <th className='text-primary text-center bg-light p-2' style={{ width: '200px' }}>Handle</th>
                                 
                                </tr>
                            </thead>
                            <tbody className='text-center text-white poppins-light fs-9'>
                                <tr>
                                    <td>Mxcvark</td>
                                    <td>Mxcvark</td>
                                    <td>Otxcvto</td>
                                    <td>@mdo</td>                             
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="position-relative" /*onClick={handleCheck}*/>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
        </div></>)
};
export {ExerciseTen}