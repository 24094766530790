// FillTheGaps.tsx
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FillTheGapsProps } from './types';
import Gap from './Gap';
import './index.css';
import { RightCardContent } from '../../../../components/common/right-card-content/indext';
// import { LeftCardContent } from '../../../../components/common/left-card-content/indext';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { UseParagraphDragDropModule } from './store';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import WordWordBox from './word-word-box';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import { UseModeCourseModule } from '../../components/front-page/store/main';
import { AddDragDropResponse } from './services';
import { ResetStepByExercises } from '../utils';
import { useNavigate } from 'react-router-dom';
import { OneTaskByFindOneTask } from '../basic-vocabulary/services';
import { UseEvaluationTestModule } from '../../../admin/evaluation/modules/test/store/main';
import useAuth from '../../../../api/main';
import { UseExerciseTimerModule } from '../common/store/main';
import { FinishWelcome } from '../../../admin/placement-test/services';
import { EvaluationType } from '../../../admin/evaluation/interfaces';
import { validationPercentageObtained } from '../../utils/validatePercentage';
import useIsImportantEvaluation from '../../hooks/useIsImportantEvaluation';
import { ButtonNextFullScreen } from '../../utils/buttonNextFullScreen';

const WordBoxDragAndDrop: React.FC<FillTheGapsProps> = ({ renderType, sentence, words, titleContent, descriptionContent, colorSecondary }) => {

  const { typeUser } = useAuth();
  let {
    setSentencesConstructed, allWords ,setWords, percentage
  } = UseParagraphDragDropModule();
  const { questionsEvaluation,
    currentTaskId,
    evaluationLesson,
    lessonCourse,
    lessonsId,
    stepEvaluation,
    setShowEndEvaluation,
    setEvaluationResponses,
    setStepEvaluation } = UseEvaluationCourseModule();

  const { stepEvaluationTest,
    questionsEvaluationTest,
    evaluationTest,
    setStepEvaluationTest
  } = UseEvaluationTestModule();
  const { modeCourse, modeTest, isPercentageMin,setIsPercentageMin } = UseModeCourseModule();
  const { exerciseBeforeTime } = UseExerciseTimerModule();

  const navigate = useNavigate();
  const [number, setNumber] = useState(0);
  const [usedWords, setUsedWords] = useState<{ [key: string]: boolean }>({});
  const [filledGaps, setFilledGaps] = useState<{ [key: string]: string }>({});
  const [resetFlag, setResetFlag] = useState(0);
  const [gapsCorrectness, setGapsCorrectness] = useState<{ [key: string]: boolean }>({});
  const [hasChecked, setHasChecked] = useState(false);
  const isImportantQuiz = useIsImportantEvaluation();

  // const !isImportantQuiz = evaluationLesson?.type !== EvaluationType.PLACEMENT_TEST && evaluationLesson?.type !== EvaluationType.FINAL_PRESENTATION && evaluationLesson?.type !== EvaluationType.QUIZ  && evaluationLesson?.type !== EvaluationType.WELCOME_DAY
  // const isImportantQuiz = isPercentageMin || evaluationLesson?.type === EvaluationType.FINAL_PRESENTATION || evaluationLesson?.type === EvaluationType.PLACEMENT_TEST || evaluationLesson?.type === EvaluationType.QUIZ || evaluationLesson?.type === EvaluationType.WELCOME_DAY
  const handleFillGap = (gapId: string, wordId: string) => {
    setFilledGaps((prevFilledGaps) => ({
      ...prevFilledGaps,
      [gapId]: wordId,
    }));
    setUsedWords((prevUsedWords) => ({
      ...prevUsedWords,
      [wordId]: true,
    }));
    setSentencesConstructed(getUserConstructedSentence())
  };

  function calculateAccuracyPercentage(totalQuestions: number, correctAnswers: number): number {
    if (totalQuestions > 0) {
      const percentage: number = (correctAnswers / totalQuestions) * 100;
      return percentage;
    } else {
      return 0;
    }
  }

  const checkSentence = () => {

    const totalGaps = sentence.reduce((acc, sent) => acc + (sent.match(/\{.*?\}/g) || []).length, 0);

    // if (totalGaps > Object.keys(filledGaps).length) {
    //   showSimpleAlert({ message: 'Error! Please fill all the gaps before checking.', icon: 'error' });
    //   return;
    // }
    setHasChecked(true);
    let _trueWords: string[] = [];
    let _falseWords: string[] = [];
    let _allWords: string[] = [];
    const newGapsCorrectness = Object.keys(filledGaps).reduce((acc, gapId) => {
      _allWords.push(gapId)
      // alert(gapId)
      const wordId = filledGaps[gapId];
      const wordItem = words.find(word => word.id === wordId);
      const isCorrect = wordItem ? wordItem.text === gapId : false;
      if (isCorrect) {
        _trueWords.push(gapId)
      }
      if (isCorrect === false) {
        _falseWords.push(gapId)
      }
      acc[gapId] = isCorrect;
      return acc;
    }, {} as { [key: string]: boolean });

    setGapsCorrectness(newGapsCorrectness);
    const isOverallCorrect = Object.values(newGapsCorrectness).every(value => value);
    // alert(isOverallCorrect ? 'Correct!' : 'Incorrect, try again.');
    let totalPercentage = calculateAccuracyPercentage(totalGaps, _trueWords.length)
    if(!isImportantQuiz){
      validationPercentageObtained(questionsEvaluation?.minPercentage ?? 50, totalPercentage);
    }
    setWords({
      percentage: totalPercentage,
      allWords: {
        filledGaps,
        usedWords
      },
      hasChecked: true
    })
  };

  const resetExercise = () => {
    setFilledGaps({});
    setUsedWords({});
    setResetFlag(flag => flag + 1);
    setHasChecked(false);
    setNumber(0)
    setIsPercentageMin(false)
  };

  const handleRemoveWord = (gapId: string, wordId: string) => {
    setFilledGaps((prev) => {
      const newState = { ...prev };
      delete newState[gapId];
      return newState;
    });
    setUsedWords((prev) => {
      const newState = { ...prev };
      delete newState[wordId];
      return newState;
    });
  };
  // Función para incrementar el número
  const increment = () => {
    setNumber(prevNumber => prevNumber + 1);
  };

  // Función para disminuir el número
  const decrement = () => {
    setNumber(prevNumber => prevNumber - 1);
  };
  const getUserConstructedSentence = () => {
    return sentence.map((sent) => {
      const parts = sent.split(/(\{.*?\})/).filter(part => part);
      const completedParts = parts.map((part) => {
        if (part.startsWith('{') && part.endsWith('}')) {
          const gapId = part.replace(/[{}]/g, '');
          const wordId = filledGaps[gapId];
          const wordItem = words.find(word => word.id === wordId);
          return wordItem ? wordItem.text : part;
        }
        return part;
      });
      return completedParts.join('');
    }).join(' ');
  };

  const handleSubmit = async () => {
    ResetStepByExercises();
    setEvaluationResponses({
      percentage: percentage,
      exercise_title:  questionsEvaluation?.exercise.name ?? "Titulo ejercicio",
      responses:[],
      isDragDropExercise: {
        sentences: sentence,
        responses: [''],
        percentage: percentage,
        isPercentageMin: isPercentageMin
      }
    })
    if (typeUser === 'STUDENT') {
      try {
        await AddDragDropResponse({
          percentage: percentage,
          description: '',
          isActive: true,
          true_answer: allWords,
          exercises: questionsEvaluation?.exercise?.id ?? 'xx',
          task: currentTaskId ?? 'xx'
        });
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta.', icon: 'error' });
        console.error(e);
      }
    }
  }

  return (<>
    <DndProvider backend={HTML5Backend}>
      {renderType && renderType === 'left' ? <></> :
        <>
          <RightCardContent>
            <h4 className='champ-light word-box-title'
              style={{ color: '#3e94ff', marginBottom: 0, paddingBottom: 0 }}
            > {titleContent}</h4>
            <p className='instruction-word-box' style={{ color: '#3e94ff' }}>
              <strong>Instruction:</strong>{descriptionContent}
            </p>

            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center', // Centra los elementos horizontalmente
              gap: '10px', // Espaciado entre elementos
              maxHeight: '50px',
              overflowY: 'auto'
            }}>
              {words.map((word) => (
                <WordWordBox key={word.id} word={word} isUsed={!!usedWords[word.id]} />
              ))}
            </div>

            <hr style={{ color: '#3e94ff', height: 0, marginBottom: 4 }} />
            <p className='champ-light text-end' style={{
              marginTop: 0,
              marginBottom: 0,
              color: '#3e94ff',
              fontSize: 14 , 
            }}>{`${number + 1}${'/'}${sentence.length}`}</p>
            {sentence.map((sentence, sIndex) => (
              <div className='poppins-light' key={sIndex}
                style={{
                  color: '#3e94ff',
                  fontSize: 16,
                  display: 'flex',
                  flexWrap: 'wrap',
                  maxHeight: '180px',
                  overflowY: 'auto'
                }}>
                {sentence.split(/(\{.*?\})/).filter(part => part).map((part, pIndex) => {
                  // Comprobación para partes que deben ir en negrita
                  const boldPattern = /.+:\s+/; // Busca cualquier texto seguido de dos puntos y un espacio
                  const matchResult = part.match(boldPattern); // Almacena el resultado de match
                  
                  if (matchResult) { // Verifica si matchResult no es null
                    const boldPart = matchResult[0]; // Asume que hay al menos una coincidencia
                    const restOfPart = part.substring(boldPart.length);
                    
                    return (
                      <span key={`${sIndex}-${pIndex}`} style={{ display: sIndex === number ? '' : 'none' }}>
                        <strong>{boldPart}</strong>{restOfPart}
                      </span>
                    );
                  } else if (part.startsWith('{') && part.endsWith('}')) {
                    // Manejo de los huecos
                    const gapId = part.replace(/[{}]/g, '');
                    return <Gap key={`${sIndex}-${pIndex}`}
                      size={15}
                      gap={{ id: gapId, correctWordId: gapId }}
                      resetFlag={resetFlag}
                      onFill={handleFillGap}
                      reviewMode={false}
                      colorString='#3e94ff'
                      onRemove={handleRemoveWord}
                      isCorrect={gapsCorrectness[gapId]}
                      hasChecked={hasChecked}
                      display={sIndex === number ? 'inline-block' : 'none'}
                    />;
                  } else {
                    // Partes de la oración que no requieren tratamiento especial
                    return <span key={`${sIndex}-${pIndex}`} style={{ display: sIndex === number ? '' : 'none' }}>{part}</span>;
                  }
                })}
              </div>
            ))}
            {hasChecked && !isImportantQuiz &&
              <h2
                className='arrow-left-basic-vocabulary cursor-pointer  poppins-light'
                onClick={() => resetExercise()}
                style={{
                  position: 'absolute',
                  color: '#3e94ff', // Texto blanco
                  fontSize: 16,
                  marginBottom:8,
                  marginLeft: 64,
                  whiteSpace:'nowrap'
                }}
              >
                Try again 
              </h2> 
              }
              { isImportantQuiz  ?
                <>
                  {number > 0 && !hasChecked &&
                    <div
                      className='  arrow-left-basic-vocabulary cursor-pointer'
                      style={{ position: 'absolute' }}
                      onClick={() =>{ 
                        if((isImportantQuiz) && !hasChecked){
                          decrement()
                        } else if(!isImportantQuiz){
                          decrement()
                        }
                      }}
                    >
                      <img className='' height={28} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/back_active.svg`)} alt='' />
                    </div>
                  }
                </>
                :
                <>
                  {number > 0 &&
                    <div
                      className='arrow-left-basic-vocabulary cursor-pointer'
                      style={{ position: 'absolute' }}
                      onClick={() =>{ 
                        if((isImportantQuiz) && !hasChecked){
                          decrement()
                        } else if(!isImportantQuiz){
                          decrement()
                        }
                      }}
                    >
                      <img className='' height={28} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/back_active.svg`)} alt='' />
                    </div>
                  }
                </>
              }
            {hasChecked && (isPercentageMin || isImportantQuiz) ?
            <ButtonNextFullScreen onAction={handleSubmit}/>
              : (number + 1) === sentence.length ? 
              //ultimo next del ejercicio
              <div
                className='arrow-right-basic-vocabulary cursor-pointer'
                style={{ position: 'absolute' }}
                onClick={() => checkSentence()}
              >
                <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
              </div>
                :
                <div
                  className='arrow-right-basic-vocabulary cursor-pointer'
                  style={{ position: 'absolute' }}
                >
                  <button
                    type='button'
                    title={exerciseBeforeTime ? "Vea todo el contenido" : "Next"}
                    style={{ backgroundColor: 'transparent', border: 'none', cursor: `${exerciseBeforeTime ? 'not-allowed' : 'pointer'}`, }}
                    onClick={() => increment()}
                    disabled={exerciseBeforeTime}
                  >
                    <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                  </button>
                </div>}
            {/* <div>
                <h2>Contador: {number}</h2>
                <button onClick={increment}>+1</button>
                <button onClick={decrement}>-1</button>
              </div> */}
            {/* 
              <DndProvider backend={HTML5Backend}>
                <button onClick={checkSentence}>Check</button>
                <button onClick={resetExercise}>Reset</button>
                <button onClick={() => alert(getUserConstructedSentence())}>Show Constructed Sentence</button>
              </DndProvider> */}
          </RightCardContent>
        </>}
      {/* <p>verdad</p> :{trueWords.map((x) => (<p>{x}</p>))}
      <p>false</p> :{falseWords.map((x) => (<p>{x}</p>))}
      {percentage} */}
      {/* <div className='row '>
        <div
          style={{ zIndex: 2, padding: 0, backgroundColor: colorSecondary }}
          className='col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12   card-with-extended-background content-course-card-left'>
          <div style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%'
          }}>
            <TitleContentCourse
              title={titleContent}
              description={descriptionContent}
            /> */}
      {/* <RenderingContentFullScreen>
              </RenderingContentFullScreen> */}
      {/* </div>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 content-course-card-right" style={{ zIndex: 1, position: 'relative' }}>
          <div style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
          }}> */}

      {/* </div>

        </div>
      </div> */}

      <style>
        {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
      </style>

    </DndProvider>
  </>);
};

export default React.memo(WordBoxDragAndDrop);
