/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { ErrorMessage, Field } from 'formik'
import { StepBeneficiaryForm } from './form';
import { BeneficiaryType } from '../../../interface';
import { UseAutoVerification } from '../../../store/main';

type Props = {
  onSubmit: () => void;
  prevStep: () => void;
}

const StepBeneficiary: FC<Props> = ({ onSubmit, prevStep }) => {
  const { beneficiaries } = UseAutoVerification();
  const [selectBeneficiary, setSelectBeneficiary] = React.useState<BeneficiaryType>();

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='champ-light' style={{ color: '#2d30e1' }}>
          Beneficiarios
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Haciendo click en los nombres podras editar la información de tus beneficiarios

          .
        </div>
      </div>

 


      {!selectBeneficiary && <>
 
        <ol className="list-group list-group-numbered">
          {beneficiaries.map((x) => (

            <li onClick={() => setSelectBeneficiary(x)} className="list-group-item d-flex justify-content-between align-items-start">
              <div className="ms-2 me-auto">
                <div className="fw-bold"> {x.firstName}</div>
              </div>
            </li>
          ))}
        </ol>


        <div className='d-flex flex-stack pt-10'>
          <div className='mr-2'>
            <button
              onClick={prevStep}
              type='button'
              className='btn btn-lg btn-light-primary me-3'
              data-kt-stepper-action='previous'
            >
              <KTIcon iconName='arrow-left' className='fs-4 me-1' />
              Back
            </button>
          </div>

          <div>
            <button type='button' onClick={onSubmit} className='btn btn-lg btn-primary me-3'>
              <span className='indicator-label'>
                {'Continue'}
                <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
              </span>
            </button>
          </div>
        </div>
      </>}
      {selectBeneficiary &&
        <StepBeneficiaryForm
          selectUser={selectBeneficiary}
          onSubmit={() => setSelectBeneficiary(undefined)}
        />}

    </div>
  )
}

export { StepBeneficiary }