import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { CustomDashboard } from '../modules/dashboard/pages'
import { FaqPage } from '../modules/abroad/faq/pages'
import { SupportTicketPage } from '../modules/abroad/support-ticket/pages'
import { MeReportPage } from '../modules/me-report/pages'
import { LobbyClassPage } from '../modules/lobby/pages'
import { TrailFreezingPage } from '../modules/admin/trail-freezing/pages'
import { MeBookingActivePage } from '../modules/me-booking-active/pages'
import { MyPaymentsPage } from '../modules/student/my-payments/pages'
import { CertificatePage } from '../modules/student/certificate/pages'
import { MyCoursesPage } from '../modules/student/my-courses/pages'
import { StrikesPage } from '../modules/me-report/modules/strikes/pages'
import { NotificationGlobalPage } from '../modules/notifications/pages'
import { EnglishExplorersPage } from '../modules/courses/english-explorers/page'
import { PrismaticPage } from '../modules/courses/prismatic/page'
import { PrismaPage } from '../modules/courses/prismatics/page'
import { CoursesPage } from '../modules/courses/page'
import { DragZone } from '../modules/courses/exercises/simple-drag-drop'
import FillTheGaps from '../modules/courses/exercises/text-drag-drop/FillTheGaps'
import { TextDragDrop } from '../modules/courses/exercises/text-drag-drop'
import { ParagraphDragDrop } from '../modules/courses/exercises/paragraph-drag-drop'
import { TestZonePage } from '../modules/test-zone/pages'
import { EvaluationPage } from '../modules/admin/evaluation/pages'
import { BookContainerPage } from '../modules/book/pages'
import { TasksPage } from '../modules/admin/tasks/pages'
import { ChatAbroadPage } from '../modules/chat/pages'
import { MyTestsPage } from '../modules/student/my-tests/pages'


const StudentRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='dashboard' element={<CustomDashboard />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        <Route
          path='chat/*'
          element={
            <SuspensedView>
              <ChatAbroadPage />
            </SuspensedView>
          }
        />

        <Route
          path='me-booking/*'
          element={
            <SuspensedView>
              <MeBookingActivePage />
            </SuspensedView>
          }
        />

        <Route
          path='lobby-class/*'
          element={
            <SuspensedView>
              <LobbyClassPage />
            </SuspensedView>
          }
        />

        <Route
          path='faq/*'
          element={
            <SuspensedView>
              <FaqPage />
            </SuspensedView>
          }
        />
        <Route
          path='test-zone/*'
          element={
            <SuspensedView>
              <TestZonePage />
            </SuspensedView>
          }
        />

        <Route
          path='book/*'
          element={
            <SuspensedView>
              <BookContainerPage />
            </SuspensedView>
          }
        />

        <Route
          path='english-explorers/*'
          element={
            <SuspensedView>
              <EnglishExplorersPage />
            </SuspensedView>
          }
        />

        <Route
          path='tasks/*'
          element={
            <SuspensedView>
              <TasksPage />
            </SuspensedView>
          }
        />

        <Route
          path='courses/*'
          element={
            <SuspensedView>
              <CoursesPage />
            </SuspensedView>
          }
        />

        <Route
          path='prismatic/*'
          element={
            <SuspensedView>
              <PrismaPage />
            </SuspensedView>
          }
        />

        <Route
          path='prismatics/*'
          element={
            <SuspensedView>
              <PrismaPage />
            </SuspensedView>
          }
        />

        <Route
          path='support-ticket/*'
          element={
            <SuspensedView>
              <SupportTicketPage />
            </SuspensedView>
          }
        />

        <Route
          path='strikes/*'
          element={
            <SuspensedView>
              <StrikesPage />
            </SuspensedView>
          }
        />

        <Route
          path='notifications/*'
          element={
            <SuspensedView>
              <NotificationGlobalPage />
            </SuspensedView>
          }
        />

        <Route
          path='evaluation/*'
          element={
            <SuspensedView>
              <EvaluationPage />
            </SuspensedView>
          }
        />
        <Route
          path='trail-freezing/*'
          element={
            <SuspensedView>
              <TrailFreezingPage />
            </SuspensedView>
          }
        />

        <Route
          path='my-courses/*'
          element={
            <SuspensedView>
              <MyCoursesPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='me-booking/*'
          element={
            <SuspensedView>
              <MeBookingPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='me-resume/*'
          element={
            <SuspensedView>
              <MeReportPage />
            </SuspensedView>
          }
        />

        <Route
          path='payments/*'
          element={
            <SuspensedView>
              <MyPaymentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='certificate/*'
          element={
            <SuspensedView>
              <CertificatePage />
            </SuspensedView>
          }
        />

        <Route
          path='tests/*'
          element={
            <SuspensedView>
              <MyTestsPage />
            </SuspensedView>
          }
        />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { StudentRoutes }
