import { TraditionalLevelEnum } from "../../../../../abroad/road-map-step/interfaces";
import { Exercises } from "../../../interfaces";

export interface DataPaginateTrueOrFalseResponse {
    status:  string;
    message: string;
    data:    DataTrueOrFalse;
}

export interface DataAllTrueOrFalseResponse {
    status:  string;
    message: string;
    data:    TrueOrFalse[];
}

export interface DataTrueOrFalse {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    level: TraditionalLevelEnum;
    answer?: TrueOrFalseOption[];
    exercises: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface TrueOrFalse  {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    level: TraditionalLevelEnum;
    answer: TrueOrFalseOption[] | string[];
    exercises?: Exercises;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: TrueOrFalse[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface TrueOrFalseOption {
    title :string
    order:number
    answer:TrueOrFalseEnum
}
export enum TrueOrFalseEnum {
    TRUE = 'TRUE',
    FALSE = 'FALSE',
    NOT_GIVEN = 'NOT_GIVEN'
}