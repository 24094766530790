import axios from 'axios';
import { LoginResponse, RecoveryRequestResponse } from '../interfaces';
import { showSimpleAlert } from '../../../commonHooks/alert';
import useAuth from '../../../api/main';

//const API_URL = process.env.APPI_URL ?? 'https://abroadapi.komarcasoft.com/api/auth/login'
// const API_URL = process.env.APPI_URL ?? 'http://66.94.125.144:4000/api'
//const API_URL = process.env.APPI_URL ?? 'http://localhost:3000/api'
 
const API_URL =  process.env.REACT_APP_API_URL ?? 'http://localhost:3000/api'
 


export const api = axios.create({
  baseURL: API_URL,
});


export const loginAuth = async (data: any):Promise<any> => {
  try{
 
    const response = await api.post('/auth/login', data);
    if(response.status==201){
          showSimpleAlert({  message:"ok!",  icon:'success'})
          return response.data;
    }
  }catch(e:any){
  
    if(e.response.data.statusCode==401){
      showSimpleAlert({  message:"Credenciales inválidas!",  icon:'error'});
      return;
    }
    if(e.response.data.statusCode==400){
      showSimpleAlert({  message:"Consulta iválida!",  icon:'error'});
      return;
    }

  }

};
 

export const forgotPasswordService = async (data: any):Promise<any> => {
 
  try{
    const response = await api.post('/auth/forgot-password', data);
    console.log(response)
    if(response && response.status==201){
          showSimpleAlert({  message:"Revisa tu email para continuar!!!!",  icon:'success'})
          return response.data;
    }
    if(response.data.status==404){
      showSimpleAlert({  message:"Verifica tu email!",  icon:'error'});
      return;
    }
    if(response.data.status==400){
      showSimpleAlert({  message:"Consulta iválida!",  icon:'error'});
      return;
    }
  }catch(e:any){
  
    if(e.response.data.status==401){
      showSimpleAlert({  message:"Verifica tu email!",  icon:'error'});
      return;
    }
    if(e.response.data.status==400){
      showSimpleAlert({  message:"Consulta iválida!",  icon:'error'});
      return;
    }

  }

};

export const recoveryPasswordService = async (data: any):Promise<any> => {
 
  try{
    const response = await api.post('/auth/new-password', data);
    console.log(response.data)
    if(response.data && response.data.status==201){
          showSimpleAlert({  message:"ok!",  icon:'success'})
          return response.data;
    }
    if(response.data.status==404){
      showSimpleAlert({  message:"Verifica tu email!",  icon:'error'});
      return;
    }
    if(response.data.status==400){
      showSimpleAlert({  message:"Consulta iválida!",  icon:'error'});
      return;
    }
  }catch(e:any){
  
    if(e.response.data.status==401){
      showSimpleAlert({  message:"Verifica tu email!",  icon:'error'});
      return;
    }
    if(e.response.data.status==400){
      showSimpleAlert({  message:"Consulta iválida!",  icon:'error'});
      return;
    }

  }

};

export const authChecked = async ():Promise<any> => {
    const { token } = await useAuth.getState()
    const response = await api.get('/auth/check-status',
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
};

export const updateMe = async (data:any):Promise<any> => {
  const { token } = await useAuth.getState()
  const response = await api.patch('/auth/me',
  data,
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getItems = async () => {
  const response = await api.get('/items');
  return response.data;
};

export const updateItem = async (id: number, data: any) => {
  const response = await api.put(`/items/${id}`, data);
  return response.data;
};

export const deleteItem = async (id: number) => {
  const response = await api.delete(`/items/${id}`);
  return response.data;
};

export const recoveryRequest = async (data: any):Promise<RecoveryRequestResponse> => {
  const response = await api.post('/recovery/new', data);
  return response.data;
};


export const recoveryRequestCode = async (data: any):Promise<RecoveryRequestResponse> => {
  const response = await api.post('/recovery', data);
  return response.data;
};

export const recoveryRequestPass = async (data: any):Promise<RecoveryRequestResponse> => {
  const response = await api.post('/recovery/update', data);
  return response.data;
};