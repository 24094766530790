import {Route, Routes} from 'react-router-dom'
import { AdvertisementsDetails } from '../components/details'
import { AdvertisementsTableList } from '../components/list'


const AdvertisementsPage = () => (
  <Routes>
      <Route path='/details' element={<AdvertisementsDetails />} />
      <Route path='/' element={<AdvertisementsTableList />} />
  </Routes>
)

export {AdvertisementsPage}
