import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {SpeakOut, SpeakOutType} from '../../interfaces'

import { SpeakOutAdd, SpeakOutUpdate } from '../../services'
import { UseSpeakOutModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../../abroad/road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type SpeakOutFormField = {
  name: string
  description?: string
  isActive: boolean
  level: TraditionalLevelEnum
  type: SpeakOutType
  tips?: string[];
  minWords: number;
  maxWords: number;
}


const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const SpeakOutForm: React.FC<Props>  = (props:  Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const {id} = useParams()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseSpeakOutModule();
  const [data, setData] = useState<SpeakOut>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [tips, setTips] = React.useState<string>('')
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<SpeakOutFormField>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        level:(mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.A1 ,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? SpeakOutType.SPEAK_OUT : SpeakOutType.SPEAK_OUT ,
        tips: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.tips ?? undefined : undefined,
        maxWords: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.maxWords ?? 50 : 50,
        minWords: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.minWords ?? 1 : 1
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await SpeakOutAdd({
              ...formData,
              exercises: id??''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['speak-out'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  SpeakOutUpdate({
                ...formData,
                exercises: id??""
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['speak-out'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  
  const addTip = () => {
    const updatedTips = values.tips ? [...values.tips, tips] : [tips];
    setFieldValue('tips', updatedTips);
    setTips('');
  };

  const deleteTip = (text: string) => {
    const newValues = values.tips?.filter((x) => x !== text);

    setFieldValue('tips', newValues);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Minimo de palabras</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'minWords'}
              value={values.minWords}
              onChange={handleChange}
            />
            {touched.minWords && errors.minWords && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.minWords}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Maximo de palabras</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'maxWords'}
              value={values.maxWords}
              onChange={handleChange}
            />
            {touched.maxWords && errors.maxWords && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.maxWords}</span>
                </div>
              </div>
            )}
          </div>

        {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              name='level'
              className='form-select'
              aria-label='Default select example'
              value={values.level}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
              <option value=''></option>
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Type</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
              <option value=''></option>
              {Object.keys(SpeakOutType).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>

            <div className="d-flex">
              <hr className="my-auto flex-grow-1"/>
              <div className="px-4">Questions</div>
              <hr className="my-auto flex-grow-1"/>
            </div>
            <div className='col-6'>
              <label className='form-label mb-3 fw-bold'>Title</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'title'}
                  value={tips}
                  onChange={(e) => setTips(e.target.value)}
                />

                <div className='col-6 py-6 px-9'>
                  <button type='button' onClick={addTip} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                    {!loading && 'Save'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
            </div>

              <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.tips && values.tips.map((x, i) => (
                    <tr key={i}>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {x}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {/* {x.color} */}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteTip(x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

        </div>


        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {SpeakOutForm}
