import React from 'react';

import MyLogo from '../img/BEYONDTHEREADING_btn1.svg';
import MyX from '../img/EQUIS_btn1.svg';
import NexBtnThow from '../ui/NextBtnOne';
import HeaderCard from '../ui/HeaderCard';

type Props = {
    instruction: string;
    questions: string;   
}
const ExerciseTwenty: React.FC<Props> = ({instruction, questions}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        height: '290px',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
    return(<>
        <div className="container-fluid d-flex">
            <div style={divStyle}>
            <HeaderCard/>
                <span className=" text-white cuestion mb-0 poppins-light">{instruction}</span><br/>
                <span className=" text-white cuestion mb-1 p-2 ">{questions}</span>
                <textarea className="form-control rounded-4 text-white poppins-light fs-8" placeholder='titulo' style={{ background: "#3f8bff"}}  id="exampleFormControlTextarea1" ></textarea>

            </div>
            <NexBtnThow/>
            <img className="" src={MyX} style={{ width: '40px', height: '40px' }}></img>
        </div>
   </>)
};

export {ExerciseTwenty}
