import { Appointment, AppointmentStatus, BlochHourByClassroomTeacher, DataAllAppointmentResponse, DataPaginateAppointmentResponse, LevelObserver, TransferTeacherAppointment } from '../interfaces/index'
import useAuth from '../../../../api/main'

import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseAppointmentModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import moment from 'moment'
import { SettingModalityType } from '../../user/interfaces'
import { ClassroomType } from '../../classroom/interfaces'
import { ClassTypeBooking } from '../../../booking-window/store'


const { token,id, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}

export interface BookingFind {
  after:string
  before:string
  blockHour?: number;
  campus?:string;
  classroomType?: ClassroomType;
  classType?: ClassTypeBooking;
}

export interface BookingFindValidate {
  date:string
  tutor?:string
  room?:String
}
export const UseGetOneAppointment = async (id:string): Promise<any> => {
  try {
    const response = await api.get(`/appointment/${id}`,
    {
      headers: {
        Authorization:`Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const useAppointmentAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/appointment/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}


export const UseBookingPaginate = async ({
  before,
  after, 
  blockHour, 
  campus, 
  classType,
  classroomType }:BookingFind): Promise<any> => {
  try {
    let url = `/appointment/booking?after=${after}&before=${before}`;
    if (blockHour) {
      url += `&blockHour=${blockHour}`
    }
    if (campus) {
      url += `&campus=${campus}`;
    }
    if (classroomType) {
      url += `&typeClassroom=${classroomType}`;
    }
    if (classType) {
      url += `&typeClass=${classType}`
    }
    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.result
  } catch (e:any) {
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseBookingValidate = async ({date ,tutor ,room }:BookingFindValidate): Promise<any> => {
  try {
    const response: any = await api.get(
      `/appointment/booking?after=${date}&before=${date}&room=${room}&teacher=${tutor}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.result
  } catch (e:any) {
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseAppointmentAllPaginate = async (): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where,activity, isActive, after, before, 
      classroom, teacher, typeClassroom, blockHour } = await UseAppointmentModule.getState()
    
    let url = `/appointment/paginated?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&after=${after}&before=${before}`;

    if (classroom) {
      url += `&room=${classroom}`
    };
    if (typeClassroom) {
      url += `&typeClassroom=${typeClassroom}`
    };
    if (blockHour) {
      url += `&blockHour=${blockHour}`
    };
    if (teacher) {
      url += `&teacher=${teacher}`
    };
    if (activity) {
      url += `&activity=${activity}`
    };
    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const UseAppointmentAllByTeacherPaginate = async (teacherId?: string): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where, isActive,teacher, after, before, statusAppointmentType } = await UseAppointmentModule.getState()
    let linkVariant = ""
    // if(!teacherId && !statusAppointmentType){
    //   linkVariant ="&appointmentStatus="+AppointmentStatus.PENDING
    // }

    const response: any = await api.get(
      `/appointment/booking-teacher?after=${moment(after).format('YYYY-MM-DD')}&before=${moment(before).format('YYYY-MM-DD')}&isActive=1&teacher=${teacherId ? teacherId : id}${statusAppointmentType?"&appointmentStatus="+statusAppointmentType :linkVariant}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const UseAppointmentAllByResponsiblePaginate = async (): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where, isActive,teacher, after, before } = await UseAppointmentModule.getState()

    const response: any = await api.get(
      `/appointment/paginated?after=${moment(after).format('YYYY-MM-DD')}&before=${moment(before).format('YYYY-MM-DD')}&isActive=1&responsible=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const AppointmentAdd = async (data: Appointment): Promise<any> => {
  try {
    const response: any = await api.post(
      '/appointment',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const AppointmentUpdate = async (data: Appointment): Promise<any> => {
  try {
    const { itemSelected } = UseAppointmentModule.getState()
    const response: any = await api.patch(
      `/appointment/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const AppointmentUpdateStatus = async (id:string,status: string): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/appointment/status/${id}`,
      {
        status_appointment:status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const AppointmentDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/appointment/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const BlockHourAllByClassroomTeacher = async ({start,teacher,classroom}: BlochHourByClassroomTeacher): Promise<any> => {
  try {
    const response: any = await api.get(`/block-hour/all-by-classroom-teacher?start=${start}&teacher=${teacher}&room=${classroom}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    //showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseAppointmentAllByTeacher = async (teacher:string,after?:Date,before?:Date): Promise<any> => {
  try {
    let url = `/appointment/paginated?isActive=${1}&limit=${10000}&after=${after}&before=${before}&teacher=${teacher}`;
    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data.result
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const TransferTeacher = async (data: TransferTeacherAppointment): Promise<any> => {
  try {
    const response: any = await api.patch(
      '/appointment/transfer-teacher',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const GetProcessorLevelObserver = async (data: LevelObserver): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/appointment/level-observer`, 
      {
        ...data
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}