/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { UseBookingModule } from '../../store'
import { SelectorLevelMasterClass } from './steps/SelectorLevel'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'

type Props = {
  className: string
}

const AlternativeWizard: React.FC<Props> = ({ className }) => {


 
  let {
    step,
    level,
    setStep,
    setLevel,
    setBooking,
    setRef,
    resetBooking,
    resetLevel
  } = UseBookingModule();


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Master Class</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Reserva una clase de cortesía.</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <button
                onClick={() => resetLevel()}
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary  fw-bold px-4 me-1 ${step === 1 ? 'active' : ''}`}
                data-bs-toggle='tab'
              >
                Level
              </button>
            </li>
            <li className='nav-item'>
              <button
                disabled={step === 1}
                onClick={() => resetBooking()}
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary  fw-bold px-4 me-1 ${step === 2 ? 'active' : ''}`}
                data-bs-toggle='tab'
              >
                Fecha
              </button>
            </li>
            <li className='nav-item'>
              <button
                disabled
                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary  fw-bold px-4 me-1 ${step === 3 ? 'active' : ''}`}
                data-bs-toggle='tab'
              >
                Reservar
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {step === 1 && <SelectorLevelMasterClass setLevelSelected={(e) => {
            setLevel(e);
            setStep(2)
          }} className='xxx' />}

          {step === 2 && <Step1 />}
          {step === 3 && <Step3 />}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { AlternativeWizard }
