import {Route, Routes} from 'react-router-dom'
import { TrailSuspenseReasonDetails } from '../components/details'
import { TrailSuspenseReasonTableList } from '../components/list'


const TrailSuspenseReasonPage = () => (
  <Routes>
      <Route path='/details' element={<TrailSuspenseReasonDetails />} />
      <Route path='/' element={<TrailSuspenseReasonTableList />} />
  </Routes>
)

export {TrailSuspenseReasonPage}
