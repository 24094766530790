import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {CompleteSentences, QuestionsCompleteSentenceIE} from '../../interfaces'

import { CompleteSentencesAdd, CompleteSentencesUpdate } from '../../services'
import { UseCompleteSentencesModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'
import Swal from 'sweetalert2'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  questions: QuestionsCompleteSentenceIE[];
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const CompleteSentencesForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {id} = useParams();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseCompleteSentencesModule();
  const [column, setColumn] = useState<string>('');
  const [itemColumn, setItemColumn] = useState<string>('');

  const [modeQuestion, setModeQuestion] = React.useState<{column: boolean, item: boolean}>({
    column: true,
    item: false
  });

  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        questions: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.questions.map(q => JSON.parse(q)) ?? [] : []
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await CompleteSentencesAdd({
              ...formData,
              exercises: id??''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['complete-sentences'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  CompleteSentencesUpdate({
                ...formData,
                exercises: id??''
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['complete-sentences'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const addColumn = () => {
    const findColumn = values.questions.find(x => x.title === column);
    if (findColumn) 
      return showSimpleAlert({message: 'Ya hay una columna con el mismo nombre', icon: 'error'});

    setFieldValue('questions', [...values.questions, {title: column, rowValue: []}]);
    setColumn('');
  }; 

  const addItemColumn = () => {
    const questionToUpdate = values.questions.find(x => x.title === column);
    if (!questionToUpdate) 
      return showSimpleAlert({ message: 'No se encontró la columna correspondiente', icon: 'error' }); 
    const updatedValues = [...questionToUpdate.rowValue, itemColumn];
    const updatedItems = {...questionToUpdate, rowValue: updatedValues};
    const updatedColumns = values.questions.map(x => {
      if (x.title === column) {
        return updatedItems;
      } else {
        return x;
      }
    });

    setFieldValue('questions', updatedColumns);
    setItemColumn('');
  };

  const deleteColumn = (value: string) => {
    const findColumn = values.questions.find(x => x.title === value);
    if (!findColumn) 
      return showSimpleAlert({message: 'No se encontro ninguna columna', icon:'error'});
    if (findColumn.rowValue.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Esta columna tiene items, ¿Desea borrarla?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          const newValues = values.questions.filter(x => x.title !== value);
          setFieldValue('questions', newValues);
        }
      })
    }
    setColumn('');
    return;
  };

  const deleteItem = (value: string) => {
    const questionToUpdate = values.questions.find(x => x.title === column);
    if (!questionToUpdate) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' });

    const newItems = questionToUpdate.rowValue.filter(x => x !== value);
    const updatedItems = { ...questionToUpdate, rowValue: newItems };
    const updatedColumns = values.questions.map((x) => {
      if (x.title === column) {
        return updatedItems;
      } else {
        return x;
      }
    });

    setFieldValue('questions', updatedColumns);
    setColumn('');
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className="d-flex">
            <hr className="my-auto flex-grow-1"/>
            <div className="px-4">Sentences</div>
            <hr className="my-auto flex-grow-1"/>
          </div>

          {modeQuestion.item &&
            <div className='card-footer d-flex justify-content-start py-6 px-9'>
              <button type='button' 
                onClick={() =>{ 
                  setColumn('');
                  setModeQuestion({
                    column: true,
                    item: false
                  })
                }} 
                className='btn btn-primary' 
                disabled={loading ||mode==='VIEW'}>
                Questions
              </button>
            </div>}

          {modeQuestion.column ? <>
            <div className='mb-10 col-6 row'>
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title'}
                value={column}
                onChange={(e) => setColumn(e.target.value)}
              />

              <div className='col-6 py-6 px-9'>
                <button type='button' onClick={addColumn} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {x.title}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {/* {x.color} */}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            // disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> {
                              setModeQuestion({item: true, column: false});
                              setColumn(x.title);
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fa-solid fa-plus" style={{ color: 'blue', fontSize: 15 }}></i>
                          </button>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteColumn(x.title)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </> : modeQuestion.item && <>
            <div className='mb-10 col-6 row'>
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title_option'}
                value={itemColumn}
                onChange={(e) => setItemColumn(e.target.value)}
              />

              <div className='col-6'>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='button' onClick={addItemColumn} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                    {!loading && 'Save'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.find(x => x.title === column)
                    ?.rowValue?.map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteItem(x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {CompleteSentencesForm}
