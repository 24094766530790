import {Route, Routes} from 'react-router-dom'
import { SettingsDetails } from '../components/details'
import { SettingsTableList } from '../components/list'


const SettingsPage = () => (
  <Routes>
      <Route path='/details' element={<SettingsDetails />} />
      <Route path='/' element={<SettingsTableList />} />
  </Routes>
)

export {SettingsPage}
