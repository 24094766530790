import {Outlet, Route, Routes} from 'react-router-dom'
import { LearningPathDetails } from '../components/details'
import { LearningPathTableList } from '../components/list'
import { LearningPathHeader } from '../LearningPathHeader'
import { ChapterTableList } from '../modules/chapter/components/list'
import { LessonsTableList } from '../modules/lessons/components/list'
import { TopicsTableList } from '../modules/topics/components/list'
import { ChapterDashboard } from '../modules/chapter/components/dashboard'
import { LessonsDashboard } from '../modules/lessons/components/dashboard'
import { TopicsDashboard } from '../modules/topics/components/dashboard'


const LearningPathPage = () => (
  <Routes>
      <Route path='/topic/:id' element={<TopicsDashboard />} />
      <Route path='/lesson/:id' element={<LessonsDashboard />} />
      <Route path='/chapter/:id' element={<ChapterDashboard />} />
      <Route path='/details' element={<LearningPathDetails />} />
      <Route path='/' element={<LearningPathTableList />} />
  </Routes>
)

export {LearningPathPage}
