import React from 'react';

import MyLogo from '../img/BEYONDTHEREADING_btn1.svg';
import MyX from '../img/EQUIS_btn1.svg';
import NexBtnThow from '../ui/NextBtnOne';
import HeaderCard from '../ui/HeaderCard';

type Props = {
    instruction: string;
     questions: string;   
}
const ExerciseTwentyOne: React.FC<Props> = ({instruction, questions}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',width: '525px',
        height: '290px',borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
      const inputStyle: React.CSSProperties = {
        display: 'flex',width: '25px',
        height: '16px',marginRight: '10px',borderRadius: '40px',
        borderWidth: '0px',borderStyle: 'solid',borderColor: '#ffffff',
        textAlign: 'center',backgroundColor: '#d0ff00',outline: 'none',marginBottom: '0',
        marginTop: '1px'
      };
      const arrayquestion = [
        "Everyone arrived in cape town.",
        "An explorer arrived on the island.",
        "The Olssons left Sweden.",
        "There was a plane occident.",
        "The Olssons left Sweden.",
        "The Olssons left Sweden."
    ];
    return(<>
        <div className="d-flex container-fluid">
            <div style={divStyle}>
                <HeaderCard/>
                <span className=" text-white  mb-0 poppins-light">{instruction}</span><br/>
                <span className=" text-white  mb-1 p-2 ">{questions}</span>
                {arrayquestion.map((x) => (
                    <>
                      <div className=" row d-flex px-5 fs-7 text-white poppins-light container-fluid ">
                          <div className="col-1">
                             <input style={inputStyle} type="text"/>
                          </div>                        
                            
                          <div className="col-11 d-flex ">
                             <span className="mb-0">{x}h</span>
                          </div>
                      </div>
                        </>
                    ))}
            </div>
            <NexBtnThow/>
            <img className="" src={MyX} style={{ width: '40px', height: '40px' }}></img>
        </div>
   </>)
};
export {ExerciseTwentyOne}