import React from "react";
import { SingleWidget } from "../../../../../../../components/common/widgets/single-widget";
import { Lessons } from "../../../lessons/interfaces";
import { useTranslation } from "react-i18next";

type Props = {
    item: Lessons;
};

const TopicsTopWidget: React.FC<Props> = ({item}) => {
    const {t} = useTranslation();
    return (
        <div className='row g-5'>
            <div className="col-xl-12">
                <SingleWidget
                    className="card-xl-stretch"
                    avatar="/media/svg/extra/book.svg"
                    title={`${t('LESSON')}: ${item.name??''}`}
                    description={`${t('DESCRIPTION')}: ${item.description??''}`}
                />
            </div>
        </div>
    )
};

export {TopicsTopWidget};