import {Route, Routes} from 'react-router-dom'
import { TemplatePusherDetails } from '../components/details'
import { TemplatePusherTableList } from '../components/list'


const TemplatePusherPage = () => (
  <Routes>
      <Route path='/details' element={<TemplatePusherDetails />} />
      <Route path='/' element={<TemplatePusherTableList />} />
  </Routes>
)

export {TemplatePusherPage}
