import { create } from 'zustand';
import { TrueWordsItemQuestionsAnswer, TrueWordsInterface, quiz } from '../interfaces';

interface TrueWordsModuleInterface {
  questions: TrueWordsInterface[]
  title: string;
  instructions?: string;
  percentage: number
  answer?: TrueWordsItemQuestionsAnswer[];
  exerciseId?: string;
  resetData: () => void;
  setQuestions: (
    exerciseId: string, 
    instruction: string,
    value: TrueWordsInterface[]) => void;
  setAnswer: (value: TrueWordsItemQuestionsAnswer[]) => void;
}

export const UseTrueWordsModule = create<TrueWordsModuleInterface>((set) => ({
  status: 'ANSWER',
  title: "Este es un titulo de quiz",
  instructions: "",
  questions: quiz.questions,
  percentage: 0,
  setTitle: (value) => { },
  resetData: () => { },
  setQuestions: (exerciseId, instructions, value) => {
    set({ questions: value, exerciseId, instructions });
  },
  setAnswer: (value) => {
    set({ answer: value });
  }
}));