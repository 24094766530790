import React from 'react'
import { HeaderBook } from '../header'
import { ToolbarBook } from '../toolbar'
import { RenderBook } from '../render-book'
import { UseBookStudentModule } from '../../modules/screen/store/main'
import { ExercisesType } from '../../../admin/exercises/interfaces'
import '../toolbar/ColorPicker.css'
import { RenderItemExercise } from '../interactions/RenderItemExercise'
import { updateTaskStatusById } from '../../../courses/exercises/basic-vocabulary/services'
import { TaskStatus } from '../../../admin/tasks/interfaces'

type Props = {}

const ContainerBook: React.FC<Props> = () => {

  const {
    lessonData,
    currentInteractiveItems,
    currentItemBook,
    statePage,
    currentPage,
    clickedInteractionsIds,
    completedExerciseIds,
    currentTaskId,
    setStatePage,
    setCurrentsItemsTest,
    setCurrentPage,
    setDeleteCurrentItem,
    setAllAnswered,
    addClickedInteractionsId,
    resetCurrentItems,
  } = UseBookStudentModule()

  const completedExercises = completedExerciseIds.length
  const clickedInteractions = clickedInteractionsIds.length
  const exercisesLength = lessonData?.evaluation?.evaluation_item?.filter((x) => x.exercise.type !== ExercisesType.AUDIO_INTERACTION).length ?? 0
  const interactionsLength = lessonData?.evaluation?.evaluation_item?.filter((x) => x.exercise.type === ExercisesType.AUDIO_INTERACTION).length ?? 0

  const approveTask = async()=>{
    if(currentTaskId)
      await updateTaskStatusById(currentTaskId, TaskStatus.APPROVED)
  }

  React.useEffect(() => {
    if (
      lessonData &&
      lessonData.evaluation &&
      lessonData.evaluation.evaluation_item &&
      lessonData.evaluation.evaluation_item.length > 0
    ) {
      if (exercisesLength === 0) {
        if (clickedInteractions === interactionsLength) {
          setAllAnswered(true)
          approveTask() 
        } else {
          setAllAnswered(false)
        }
      } else {
        if (completedExercises === exercisesLength) {
          setAllAnswered(true)
          // findAnswer()
        } else {
          setAllAnswered(false)
        }
      }
    }
  }, [currentItemBook, completedExerciseIds, clickedInteractionsIds])

  React.useEffect(() => {
    setCurrentPage(1);
  }, []);

  React.useEffect(() => {

    window.addEventListener('beforeunload', resetCurrentItems);
    window.addEventListener('popstate', resetCurrentItems);
    window.addEventListener('hashchange', resetCurrentItems);

    return () => {
      window.removeEventListener('beforeunload', resetCurrentItems);
      window.removeEventListener('popstate', resetCurrentItems);
      window.removeEventListener('hashchange', resetCurrentItems);
    };

  }, [resetCurrentItems]);

  return (
    <>
      <div>
        <HeaderBook
          logoCourse={lessonData ? lessonData.chapter.path.logo.location : ''}
          colorCourse={lessonData ? lessonData.chapter.path.colorBase : ''}
          lessonName={lessonData ? lessonData.name : 'Michigan Master'}
        />
        <div className='d-none d-lg-flex'>
          <RenderBook
            numberPage={currentPage}
            numberRenderPage={statePage}
            pagesBook={lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []}
          />
        </div>
        <div className='d-flex d-lg-none'>
          <RenderBook
            numberPage={currentPage}
            numberRenderPage={1}
            pagesBook={lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []}
          />
        </div>
        <ToolbarBook
          onChangeStatePage={(e) => setStatePage(e ? 2 : 1)}
          statePage={statePage === 1 ? false : true}
          currentPage={currentPage}
          setCurrentPage={(e) => setCurrentPage(e)}
          mode='STUDENT'
        />

        <RenderItemExercise
          typeUser={'STUDENT'}
          exercisesLesson={lessonData?.evaluation?.evaluation_item ?? []}
          currentExercises={currentInteractiveItems}
          onDeleteExercise={(e) => setDeleteCurrentItem(e)}
          setExercise={(e) => setCurrentsItemsTest(e, false)}
          addClickedInteractionsId={(e) => addClickedInteractionsId(e)}
        />

      </div>
    </>
  )
}

export { ContainerBook }
