import React, { useEffect, useState } from 'react';
import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { CheckWritingQuestionIE, CheckWritingQuestionAnswerIE, CheckWritingTheme, CheckWritingAnswerAndThemesIE } from '../interfaces';
import CheckWritingHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/CheckWritingHeaderCard';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddCheckWrittingResponse } from '../services';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { UseCheckWritingModule } from '../store';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
  userMode?: ModeTypeExercise
  question: CheckWritingQuestionIE[]
  theme: CheckWritingTheme[]
  response?: CheckWritingAnswerAndThemesIE;
};
const divStyle = {
  backgroundColor: '#3f8bff',
  minWidth: '530px',
  minHeight: '420px',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)',
  borderStyle: 'solid',
  padding: '27px 10px 40px 20px'
};

export interface ThemeAnswer {
  title: string
  order: number
  text?: string
}

const CheckWritingExItem: React.FC<Props> = ({
  userMode,
  question,
  theme,
  response
}: Props) => {
  const [allAnsweredThemes, setAllAnsweredThemes] = useState<boolean>(false);
  const [error, setError] = React.useState<null | string>(null);
  // const [allAnswered, setAllAnswered] = useState<boolean>(false);
  const [answers, setAnswers] = useState<CheckWritingQuestionAnswerIE[]>([]);
  const [answersThemes, setAnswersThemes] = useState<ThemeAnswer[]>([]);
  const [score, setScore] = useState<number | null>(null);

  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    currentItemBook,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer
  } = UseBookStudentModule();

  const { exerciseId, instructions } = UseCheckWritingModule();

  useEffect(() => {
    if (response) {
      if (response.answer && response.answer.length > 0) {
        setAnswers(response.answer.map(q => ({
          title: q.title,
          text: q.text,
          order: q.order,
          isTrue: false
        })));
      }
      if (response.answersThemes && response.answersThemes.length > 0) {
        setAnswersThemes(response.answersThemes.map((x) => ({
          title: x.title,
          text: x.text,
          order: x.order
        })));
        setSelected(response.answersThemes[0] ? 0 : null);
      }
    } else {
      setAnswers(question.map(q => ({
        title: q.title,
        text: '',
        order: q.order,
        isTrue: false
      })));
      setAnswersThemes(theme.map((x) => ({
        title: x.title,
        text: '',
        order: x.order
      })));
      setSelected(theme.length > 0 ? 0 : null);
    }
  }, [question, theme]);

  useEffect(() => {
    // Verifica si todas las preguntas han sido respondidas
    const allAnsweredCheck = answersThemes.every(answer => answer.text !== '');
    setAllAnsweredThemes(allAnsweredCheck);

    if (allAnsweredCheck) {
      calculateScore();
    }
  }, [answersThemes]);

  const handleInputChange = (title: string, value: string, isTrue: boolean) => {
    const existingAnswer = answers.find(answer => answer.title === title && answer.text === value);

    if (existingAnswer) {
      setAnswers(answers.filter(answer => !(answer.title === title && answer.text === value)));
    } else {
      setAnswers([...answers, { title, text: value, isTrue, order: 0 }]);
    }
  };

  const resetExercise = () => {
    setAllAnsweredThemes(false);
    // setAllAnswered(false);
    setScore(null);
    setAnswers(question.map(q => ({
      title: q.title,
      text: '',
      order: q.order,
      isTrue: false
    })));

    setAnswersThemes(theme.map((x) => ({
      title: x.title,
      text: '',
      order: x.order
    })));
    setSelected(theme.length > 0 ? 0 : null);
  };

  const calculateScore = () => {
    const correctAnswers = answers.filter(answer => answer.isTrue).length;
    const percentage = (correctAnswers / question.length) * 100;
    setScore(percentage);
    // Aquí puedes pasar el porcentaje a otro estado o función si lo necesitas
  };
  ////////////////////
  const [selected, setSelected] = useState<number | null>(null);
  const finderText = (index: number): boolean => {
    return answersThemes.some((x) => x.order === index && (x.text && x.text?.length > 0))
  }

  const selectQuestion = (index: number) => {
    setSelected(index);
  };

  const handleChangeText = (text: string) => {
    if (selected !== null) {
      let newAnswers = [...answersThemes];
      newAnswers[selected] = { ...newAnswers[selected], text };
      setAnswersThemes(newAnswers);
    }
    let _allAnsweredTheme = answersThemes.some((x) => x.text === undefined || x.text.length < 1);
    setAllAnsweredThemes(!_allAnsweredTheme)
  };

  const handleSubmit = async () => {
    // ResetStepByExercises();
    if (lessonData && lessonData.evaluation &&
      lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(exerciseId ?? "")
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId ?? '')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        const response = await AddCheckWrittingResponse({
          isActive: true,
          isReviewed: false,
          isPending: false,
          answer: answers,
          task: currentTaskId ?? '',
          exercises: exerciseId,
          percentage: score ?? 0,
          answerThemes: answersThemes
        });
        onChangeTaskByAnswer(response, 'check_writing');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
      }
    }
  };

  const { setOffDraggable } = UseBookContainerModule()

  return (
    <>
      <div className="container-fluid " style={{ display: 'flex' }}>
        <div style={divStyle}>
          <CheckWritingHeaderCard />
          <div className='scroll-y overflow-y-auto mb-2' style={{ maxHeight: '70px', maxWidth: '435px' }}>
            <span className=" text-white  mb-0 poppins-light">{instructions}</span><br />
          </div>
          <div className="container">
            <div className="row py-2">
              <div className='col-6 '>
                <div className='row scrollable-div'   >
                  {question.map((q, questionIndex) => (
                    <div className='col-12' key={q.title}>
                      <div>
                        <p className="champ-bold mb-0 text-white" style={{ fontSize: 17 }}>{q.title}</p>
                        {q.options.map((option, optionIndex) => (
                          <div className='d-flex align-items-center' key={option.title}>
                            <input
                              checked={!!answers.find(answer => answer.title === q.title && answer.text === option.title)}
                              type='checkbox'
                              className='round-checkbox'
                              onChange={() => handleInputChange(q.title, option.title, option.isTrue)}
                            />
                            <span
                              className="poppins-light text-white ms-2"
                              style={{ fontSize: 15 }}
                            >
                              {option.title}
                            </span><br />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='col-6'>
                <div className='row'>
                  <div className="col-12">
                    {theme.map((x, index) => (
                      <p key={index} className="text-white mb-0 p-0 poppins-light" style={{ padding: 0, fontSize: 17, cursor: 'pointer' }} onClick={() => selectQuestion(index)}>
                        {finderText(x.order)
                          ?
                          <i style={{ color: 'white', fontSize: 12, paddingRight: 5 }} className="fa fa-check"></i>
                          :
                          <i style={{ color: 'white', fontSize: 12, paddingRight: 5 }} className="fa fa-circle"></i>
                        }
                        {x.title}
                      </p>
                    ))}
                  </div>
                  <div className="col-12">
                    <textarea
                      value={selected !== null ? answersThemes[selected].text : ''}
                      onChange={(e) => handleChangeText(e.target.value)}
                      onMouseEnter={() => setOffDraggable(true)}
                      onMouseLeave={() => setOffDraggable(false)}
                      className="form-control rounded-4 text-white" placeholder="Type here.."
                      style={{ background: "#3f8bff", height: "150px" }} id="exampleFormControlTextarea1"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {allAnsweredThemes && <div className='d-flex justify-content-between align-items-center'>
            <div>
              <ButtonTryAgain onAction={resetExercise} />
            </div>
            <ButtonSaveExercise
              isChecked={allAnsweredThemes}
              onNext={() => { }}
              onSubmit={handleSubmit}
              exerciseId={exerciseId}
            />
            {/* <div
              onClick={handleSubmit}
              className="cursor-pointer">
              <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '42px', height: '42px' }} alt="Next" />
            </div> */}
          </div>}
        </div>
      </div>
    </>
  );
};

export { CheckWritingExItem };