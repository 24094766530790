import React, { useEffect } from 'react';
import '../audio/index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import TikTokEmbed from '../../../../../components/common/TiktokEmbed';

type Props = {
  urlVideo?: string;
  coverFile?: string;
  heightVideo?: string;
};

const MediaContentVideo: React.FC<Props> = ({ urlVideo, coverFile, heightVideo }: Props) => {
  const { mode } = useThemeMode();

  const isYouTubeUrl = (url: string | undefined): boolean => {
    if (!url) return false;
    const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/;
    return youtubeRegex.test(url);
  };

  const getIdVideoYoutube = (url: string) => {
    const regex = /[?&]v=([^&]+)/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }

  return (
    <>
      {urlVideo && isYouTubeUrl(urlVideo) ? (
        <div className="card-media-picture"
        style={
          mode !== 'dark' ? {

            background: `black center center / cover no-repeat`,
            position: 'relative',
            overflow: 'hidden',
          } : {

            background: `black center center / cover no-repeat`,
            position: 'relative',
            overflow: 'hidden',
          }
        }>
          <TikTokEmbed 
            height={heightVideo}
            video={getIdVideoYoutube(urlVideo)} />
        </div>
      ) : (
        <div className="card-media-picture"
          style={
            mode !== 'dark' ? {

              background: `url(${coverFile ?? 'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}) center center / cover no-repeat`,
              position: 'relative',
              overflow: 'hidden',
            } : {

              background: `url(${coverFile ?? 'https://th.bing.com/th/id/R.34be4bd35c651e24a47b5fc31079aea6?rik=cXiKHyLM1EtJ0A&pid=ImgRaw&r=0'}) center center / cover no-repeat`,
              position: 'relative',
              overflow: 'hidden',
            }
          }>
          <iframe
            src={urlVideo}
            style={{ width: '100%', height: '100%' }}
            allowFullScreen={true}
            allow="encrypted-media">
          </iframe>
        </div>
      )}

    </>
  );
};

export default React.memo(MediaContentVideo);