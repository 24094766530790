import React, {useEffect} from 'react'
import {useQueryClient} from '@tanstack/react-query'
import * as Swal from 'sweetalert2'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import GenericPaginator from '../../../../components/common/Paginator'
import moment from 'moment'
import {CardHeaderModule} from '../../../../components/common/widgets/statistics/cardHeaderModule'
import {CreateModalTrialSuspense} from '../../../admin/trail-suspense/components/modal'
import {UseTrailSuspenseMe} from '../../../admin/trail-suspense/services'
import {UseBookingModule} from '../../../me-booking/store'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import {FindOneSettingByType} from '../../../abroad/settings/services'
import {SettingEnumType} from '../../../abroad/settings/interfaces'
import {UseTrailSuspenseModule} from '../../../admin/trail-suspense/store/main'
import {useThemeMode} from '../../../../../_metronic/partials'
import '../../../me-booking-active/components/list/index.css'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {UseMeBookingActiveModule} from '../../../me-booking-active/store/main'
import {useMeBookingActiveState} from '../../../me-booking-active/hooks/useMeReportRepo'
import {MeReportDelete, UseMeReportAllPaginate} from '../../../me-booking-active/services'
import {StudentSetting} from '../../../profile/components/my-road-step/interfaces'
import {StudentSettingsOneByUser} from '../../user/services/student/StudentSettingsService'
import {RoadMap} from '../../../abroad/road-map/interfaces'
import {Country} from '../../country/interfaces'
import useAuth from '../../../../api/main'

type PaginatorSetter = {
  currentPage: number
  items: number
  total: number
  totalPage: number
}

type Props = {}
const StudentActionBookingActiveTableList: React.FC<Props> = ({}) => {
  const {t} = useTranslation()
  const {id} = useParams()
  const {typeUser} = useAuth()
  const {mode} = useThemeMode()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {after, before, paginator, limit, currentPage, isActive, where, setCurrentPage, setLimit} =
    UseMeBookingActiveModule()

  const {modal, setModal, trail_class, setTrailClass} = UseTrailSuspenseModule()

  const {level, setMeSuspense, setMyAgenda, myAgenda, setStrikes, strikes} = UseBookingModule()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [bookingAvailable, setBookingAvailable] = React.useState<any[]>([])
  const [dataSettings, setDataSettings] = React.useState<any>()
  const [studentSettings, setStudentSettings] = React.useState<StudentSetting>()

  //se maneja asi el hook de la data porque
  //primero: no se debe poder permitir que entre a  UseMeReportAllPaginate cuando no este definido studentSettings
  //y segundo: react no permite usar el hook useMeBookingActiveState dentro de un useEffect
  const [data, setData] = React.useState<any>(null)
  useEffect(() => {
    if (studentSettings) {
      const fetchData = async () => {
        setLoading(true)
        try {
          const response = await UseMeReportAllPaginate({
            id: studentSettings?.id,
            before: moment().startOf('week').format('YYYY-MM-DD').toString(),
            after: moment().endOf('week').format('YYYY-MM-DD').toString(),
          })
          setData(response)
        } catch (error) {
          showSimpleAlert({message: `${error}`, icon: 'error'})
        } finally {
          setLoading(false)
        }
      }
      fetchData()
    }
  }, [studentSettings])

  const theadName = [
    'FECHA',
    'BLOQUE HORARIO',
    'ASISTENCIA',
    'ACTIVIDAD',
    'SALON',
    'PROFESOR',
    'ASISTENCIA PROFESOR',
    'ESTRELLAS',
    '',
    'OBSERVACION ESTUDIANTE',
    'OBSERVACION PROFESOR',
  ]
  // nota que le da al estudiante el profe

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0,
  })

  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await MeReportDelete(id)
        } catch (e) {}
      }
    })
  }
  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['me-booking-active']})
  }, [isActive, where, currentPage, limit])

  const findTrailSuspense = async () => {
    try {
      let response = await UseTrailSuspenseMe()
      setMeSuspense(response)
    } catch (e) {
      console.error(e)
    }
  }

  // const fetchSettings = async () => {
  //   try {
  //     const responseSettings = await FindOneSettingByType(SettingEnumType.NUMBER_HOURS_BEFORE_CANCELING_RESERVATION);
  //     setDataSettings(responseSettings);
  //   } catch (e) {
  //     showSimpleAlert({ message: 'Error al encontrar Settings!', icon: 'error' });
  //     throw 'Error'
  //   };
  // };

  const responseStudentSettings = async () => {
    const response = await StudentSettingsOneByUser(id ?? '')
    setStudentSettings(response)
  }

  React.useEffect(() => {
    // fetchSettings();
    responseStudentSettings()
  }, [])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (studentSettings) {
        const updatedBookings = data?.result?.map((x) => {
          return {
            ...x,
          }
        })

        setBookingAvailable(updatedBookings)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [studentSettings, data])

  return (
    <>
      <CardHeaderModule
        title={t('BOOKING_ACTIVE')}
        leftIcon={`/media/svg/michigan-icons/header/${
          mode === 'light' ? 'blue/back_blue' : 'white/back_white'
        }.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${
          mode === 'light' ? 'blue/my_booking_eye' : 'white/my_booking_eye'
        }.svg`}
        background={'#E6E6FA'}
        colorTitle={'#2d30e1'}
        routeBack='/user/student-setting'
      />

      <div
        className={`card mt-3 content-booking`}
        style={{border: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}`}}
      >
        {/* begin::Body */}
        <div className='card-body pt-3'>
          {studentSettings && (
            <div className='row d-flex align-middle' style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}>
              <div className='col mt-2 d-flex'>
                <div className=' pt-5'>
                  <span className='poppins-bold fs-3'>Estudiante:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.fullName}
                  </span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Email:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.email}
                  </span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Telefono:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div
            className='card mt-8 content-student'
            style={{border: mode === 'light' ? '2px solid #2d30e1' : '2px solid #FFFFFF'}}
          ></div>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr
                  className='fw-bold poppins-bold fs-5'
                  style={{
                    color: mode === 'light' ? '#2d30e1' : 'white',
                    borderBottom: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}`,
                  }}
                >
                  <th className='min-w-120px ps-0' style={{paddingBottom: '5px'}}> {' '}  Fecha{' '} </th>
                  <th className='min-w-120px ps-0' style={{paddingBottom: '5px'}}> {' '}  Inicio{' '} </th>
                  <th className='min-w-120px ps-0' style={{paddingBottom: '5px'}}> {' '}  Final{' '} </th>
                  <th className='min-w-120px ps-0' style={{paddingBottom: '5px'}}> {' '}  Tipo{' '} </th>
                  <th className='min-w-120px ps-0' style={{paddingBottom: '5px'}}> {' '}  Sesion{' '} </th>
                  <th className='min-w-120px ps-0' style={{paddingBottom: '5px'}}> {' '}  Salon{' '} </th>
                  {/* <th className='min-w-120px ps-0 text-end' style={{paddingBottom: '5px'}}> {' '}  Ingresar{' '} </th> */}
                  <th className='min-w-120px ps-0 text-center' style={{paddingBottom: '5px'}}> {' '}  Cancelar{' '} </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className='mt-2'>
                {loading && (
                  <tr className='text-center'>
                    <img
                      className='rounded mx-auto d-block'
                      width={'70%'}
                      src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                      alt=''
                    />
                  </tr>
                )}
                {data &&
                  bookingAvailable?.map((x) => (
                    <tr
                      key={x.id}
                      className=''
                      style={{color: mode === 'light' ? '#2d30e1' : 'white'}}
                    >
                      <td className='p-0'>
                        <span className='d-block fs-7 poppins-light'>
                          {moment(x.start.split(' ')[0]).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className='p-0'>
                        <span className='d-block fs-7 poppins-bold'>
                          {x.time.start.split(' ')[1]}
                        </span>
                      </td>
                      <td className='p-0'>
                        <span className='d-block fs-7 poppins-light'>
                          {x.time.end.split(' ')[1]}
                        </span>
                      </td>
                      <td className='p-0'>
                        <span className='d-block fs-7 poppins-light'>{x.title}</span>
                      </td>
                      <td className='p-0'>
                        <span className='d-block fs-7 poppins-light'>{x.title}</span>
                      </td>
                      <td className='p-0'>
                        <span className='d-block fs-7 poppins-light'>{x.room}</span>
                      </td>
                      {/* <td className='p-0 text-end'>
                        <button
                          className='btn pe-8'
                          onClick={() => navigate(`/lobby-class/${x.id}`)}
                        >
                          <img
                            src={toAbsoluteUrl(
                              '/media/svg/michigan-icons/elements/next_active_blue.svg'
                            )}
                            style={{width: '20px'}}
                            alt=''
                          />
                        </button>
                      </td> */}
                      <td className='p-0 text-center'>
                        <button
                          className='btn'
                          onClick={() => setTrailClass(x.id)}
                          disabled={typeUser !== 'ADMIN'}
                        >
                          <i className='fa-solid fa-ban fs-2 text-danger cursor-pointer'></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            <GenericPaginator
              limit={limit}
              currentPage={paginator.current_page}
              items={paginator.result.length}
              total={paginator.total}
              totalPage={paginator.total_page}
              changePage={changePage}
              changeLimit={changeLimit}
            />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CreateModalTrialSuspense
        show={modal}
        handleClose={() => setModal(false)}
        onSubmit={() => {
          findTrailSuspense()
        }}
      />
    </>
  )
}

export {StudentActionBookingActiveTableList}
