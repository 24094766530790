import React from "react";
import { SingleWidget } from "../../../../../../../components/common/widgets/single-widget";
import { LearningPath } from "../../../../interfaces";
import { useTranslation } from "react-i18next";

type Props = {
    item: LearningPath;
};

const ChapterTopWidget: React.FC<Props> = ({item}) => {
    const {t} = useTranslation();
    return (
        <div className='row g-5'>
            <div className="col-xl-12">
                <SingleWidget
                    className="card-xl-stretch"
                    avatar="/media/svg/extra/book.svg"
                    title={`${t('LEARNING_PATH')}: ${item.name??''}`}
                    description={`${t('DESCRIPTION')}: ${item.description??''}`}
                />
            </div>
        </div>
    )
};

export {ChapterTopWidget};