import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {UseEvaluationCourseModule} from '../../../../app/modules/courses/screen/store/main'
import {Offcanvas} from 'react-bootstrap'

type Props = {}

const TipsDrawer: React.FC<Props> = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const {lessonCourse} = UseEvaluationCourseModule()
  const tipsLesson: string[] = lessonCourse?.tips as string[]

  return (
    <>
      <button
        type='button'
        className='btn d-flex justify-content-end align-items-end p-0 mb-2'
        id='kt_tips_course_toggle'
        style={{zIndex: 2}}
        onClick={handleShow}
      >
        <span className='fs-4 poppins-light text-white me-2'>Tips</span>
        <img
          src={toAbsoluteUrl('/media/svg/michigan-icons/elements/tips_white.svg')}
          alt='metronic'
          style={{width: '30px'}}
        />
      </button>

      <Offcanvas show={show} onHide={handleClose} placement='end' style={{background: '#3e94ff'}}>  
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className='mt-10'>
              <button
                type='button'
                className='btn cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center'
                id='kt_topics_course_close'
                style={{borderRadius: '30px', color: 'white'}}
                onClick={handleClose}
              >
                <img
                  style={{width: `70px`}}
                  src={toAbsoluteUrl('/media/svg/michigan-icons/elements/tips_white.svg')}
                  alt=''
                />
                <span className='pt-1 mx-4'>Tips</span>
              </button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            className='card-body position-relative'
            id='kt_tips_course_body'
            style={{marginTop: '5%'}}
          >
            <div

              id='kt_tips_course_scroll'
              className='position-relative me-n5  pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_tips_course_body'
              data-kt-scroll-dependencies='#kt_tips_course_header, #kt_tips_course_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='d-flex flex-column mx-3' style={{maxHeight: '500px'}}>
                {tipsLesson &&
                  tipsLesson.map((x, i) => (
                    <div className='mb-4 d-flex' key={i}>
                      <div
                        className={`rounded-circle me-2`}
                        style={{
                          width: '6px',
                          height: '6px',
                          backgroundColor: '#d0ff00',
                          marginTop: '7px',
                        }}
                      ></div>
                      <span className='poppins-light text-white fs-6'>{x}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <div
        id='tips_course'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='tips_course'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'lg': '400px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_tips_course_toggle'
        data-kt-drawer-close='#kt_tips_course_close'
      >
        <div className='card shadow-none rounded-0 w-100' style={{backgroundColor: '#3e94ff'}}>
          <div className='card-header border-0 mx-3' id='kt_tips_course_header'>
            <div className='mt-10 d-flex flex-column cursor-pointer' id='kt_tips_course_close'>
              <img
                style={{width: `70px`}}
                src={toAbsoluteUrl('/media/svg/michigan-icons/elements/tips_white.svg')}
                alt=''
              />
              <span className='ms-1 mt-2 fs-2 poppins-bold text-white'>Tips</span> */}
      {/* <button type='button'
                    className='btn bg-white cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center'
                    id='kt_tips_course_close'
                    style={{borderRadius: '30px', color: '#2d30e1'}}
                >
                    <img className='me-1 ms-4' style={{width:'10px'}} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)} alt='' />
                    <span className='pt-1 me-4'>Topics</span>
                </button> */}
      {/* </div>
          </div>
          <div
            className='card-body position-relative'
            id='kt_tips_course_body'
            style={{marginTop: '5%'}}
          >
            <div
              id='kt_tips_course_scroll'
              className='position-relative me-n5 pe-5'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_tips_course_body'
              data-kt-scroll-dependencies='#kt_tips_course_header, #kt_tips_course_footer'
              data-kt-scroll-offset='5px'
            >
              <div className='d-flex flex-column scroll-y mx-3' style={{maxHeight: '500px'}}>
                {tipsLesson &&
                  tipsLesson.map((x, i) => (
                    <div className='mb-4 d-flex' key={i}>
                      <div
                        className={`rounded-circle me-2`}
                        style={{
                          width: '6px',
                          height: '6px',
                          backgroundColor: '#d0ff00',
                          marginTop: '7px',
                        }}
                      ></div>
                      <span className='poppins-light text-white fs-6'>{x}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export {TipsDrawer}
