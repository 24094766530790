import React from 'react';
import { UseBookStudentModule } from './store/main';
import { useLocation } from 'react-router-dom';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { FindTaskMeByLesson } from '../../../courses/screen/services';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';
import { ContainerBook } from '../../components/container';

type Props = {};

const ContainerScreenBook: React.FC<Props> = () => {
  const {
    lessonData, 
    addCompletedExerciseId, 
    refreshExercisesId,
    resetCurrentItems,
    setLessonData, 
    setCurrentTask, 
    setTask,
  } = UseBookStudentModule();
  const location = useLocation();
  const {settings} = UseMyRoadmapModule.getState();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const findTask = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await FindTaskMeByLesson(settings?.id??'xx',id);
      if (response.task && response.task.lesson) {
        setLessonData(response?.task?.lesson);
        delete response?.task?.lesson
        setTask(response?.task);
        setCurrentTask(response.task.id);
        if (response?.resume) {
          response?.resume.map((x) => {
            if (x.isCompleted) {
              addCompletedExerciseId(x.exercises);
            }
          })
        }
      }
    } catch(e) {
      console.error(e)
      showSimpleAlert({message: 'Error al encontrar Leccion', icon: 'error'});
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    refreshExercisesId();
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    if (params) {
      findTask(params.lesson);
    }
  }, [location, settings]);

  React.useEffect(() => {

    window.addEventListener('beforeunload', resetCurrentItems);
    window.addEventListener('hashchange', resetCurrentItems);
    window.addEventListener('popstate', resetCurrentItems);
    return () => {
      window.removeEventListener('beforeunload', resetCurrentItems);
      window.removeEventListener('hashchange', resetCurrentItems);
      window.removeEventListener('popstate', resetCurrentItems);
    };
  }, [resetCurrentItems]);

  return(<>
    {isLoading ?
    <div className='d-flex justify-content-center align-items-center'>
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border spinner-border-lg align-middle'></span>
      </span>
    </div>
    : lessonData &&
      <ContainerBook />
    }
  </>)
};

export {ContainerScreenBook};