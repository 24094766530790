import React from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { TypeDragDrop, WordDragDropIE} from '../../interfaces'

import { DragDropQuestionsAdd, DragDropQuestionsUpdate } from '../../services'
import { UseDragDropQuestionsModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string;
  words: WordDragDropIE[];
  sentences: string[];
  type: TypeDragDrop;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const DragDropQuestionsForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const {id} = useParams();
  const queryClient = useQueryClient()
  const {itemSelected, mode,resetData} = UseDragDropQuestionsModule();
  const [sentenceData, setSentenceData] = React.useState<string>();
  const [wordsData, setWordsData] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        words: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.words.map(x => JSON.parse(x)) ?? [] : [],
        sentences: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sentences ?? [] : [],
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? TypeDragDrop.WORD_BOX_V1 : TypeDragDrop.WORD_BOX_V1

      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await DragDropQuestionsAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['drag-drop-questions'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  DragDropQuestionsUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['drag-drop-questions'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const deleteSentence = (sentence: string) => {
    setFieldValue('sentences', values.sentences.filter(x => x !== sentence));
  };

  const deleteWord = (word: string) => {
    setFieldValue('words', values.words.filter(x => x.id !== word));
    setSentenceData(undefined);
  };

  const addSentence = () => {
    setFieldValue('sentences', [...values.sentences, sentenceData]);
    setSentenceData('');
  };

  const addWord = () => {
    setFieldValue('words', [...values.words, {id: `word_${values.words.length+1}`,text:wordsData}]);
    setWordsData('');
  }

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              <option value=''></option>
              {Object.keys(TypeDragDrop).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>

          <div className='mb-5 col-12 row'>
            <div className='col-6 d-flex flex-column'>
              <label className='form-label mb-3'>Sentencias</label>
              <input 
                type="text"
                className='form-control form-control-lg form-control-solid'
                disabled={mode==='VIEW'}
                value={sentenceData}
                onChange={(e) => setSentenceData(e.target.value)}
              />
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' className='btn btn-primary' onClick={addSentence} disabled={loading ||mode==='VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='col-6 d-flex flex-column scroll-y'>
              {values.sentences.map((x, i) => (<>
                <span key={i} className='cursor-pointer'>{x}</span>
              </>))}
            </div>
          </div>

          <div className='mb-5 col-12 row'>
            <div className='col-6 d-flex flex-column'>
              <label className='form-label mb-3'>Palabras</label>
              <input 
                type="text"
                className='form-control form-control-lg form-control-solid'
                disabled={mode==='VIEW'}
                value={wordsData}
                onChange={(e) => setWordsData(e.target.value)}
              />
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' className='btn btn-primary' onClick={addWord} disabled={loading ||mode==='VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='col-6 scroll-y d-flex flex-column'>
              {values.words.map(x => (<>
                <span key={x.id} className='cursor-pointer'>{x.text}</span>
              </>))}
            </div>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {DragDropQuestionsForm}
