/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'

import { useParams } from 'react-router-dom'
import * as Swal from 'sweetalert2'
import { DashboardResume } from '../../../../booking-dashboard/components/dashsboard/resume'
import moment from 'moment'
import { ActivityTableList } from '../list'
import { ActivityWidget } from './top-widge'
import { UseRoadMapStepOne } from '../../../road-map-step/services'
import { RoadMapStepFindOne } from './interface'
import { useStepById } from '../../hooks/useActivityRepo'
import { UseActivityModule } from '../../store/main'


const ActivityDashboard: React.FC = () => {
    let { id } = useParams();
    const { data, isLoading } = useStepById(id??'');
   // const [data, setData] = useState<RoadMapStepFindOne>()
    // let findBooking=async ()=>{
    //     if(id){
    //         try{
    //             let data = await UseRoadMapStepOne(id);
    //             setData(data)
    //         }catch(e){
                       
    //         }
    //     }
    // }

    // useEffect(() => {
    //     findBooking();
    // }, [id])

    const {setLevelStep} = UseActivityModule();
    React.useEffect(() => {
      if (data && data.level) {
        setLevelStep(data.level)
      }
    }, [data])

  return (
    <div className='row'>
        <div className='col-sm-12 col-md-8 col-lg-8'>
            <ActivityTableList></ActivityTableList>
        </div>
        <div className='col-sm-12 col-md-4 col-lg-4'>
            {data && isLoading==false && <ActivityWidget item={data}/>}
        </div>
    </div>
  )
}

export {ActivityDashboard}
