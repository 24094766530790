import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {PlacementTest, PlacementType} from '../../interfaces'

import { PlacementTestAdd, PlacementTestUpdate } from '../../services'
import { UsePlacementTestModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseEvaluationModule } from '../../../evaluation/store/main'
import { Evaluation, EvaluationType } from '../../../evaluation/interfaces'
import { UseEvaluationAllPaginate } from '../../../evaluation/services'
import { useTranslation } from 'react-i18next'

export type PlacementTestFormFields = {
  name: string
  type: PlacementType;
  isActive: boolean
  evaluation: string;
  description:string;
  pre_a_one:number;
  a_one: number;
  a_two: number;
  b_one: number;
  b_two: number;
  c_one:number;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const PlacementTestForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const {evaluationType, setEvaluationType} = UseEvaluationModule();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UsePlacementTestModule();
  const [evaluation, setEvaluation] = useState<Evaluation[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<PlacementTestFormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? PlacementType.PLACEMENT_TEST : PlacementType.PLACEMENT_TEST,
        evaluation: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.evaluation?.id ?? '' : '',
        pre_a_one: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.pre_a_one ?? 0 : 0,
        a_one: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.a_one ?? 0 : 0,
        a_two: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.a_two ?? 0 : 0,
        b_one: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.b_one ?? 0 : 0,
        b_two: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.b_two ?? 0 : 0,
        c_one: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.c_one ?? 0 : 0
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await PlacementTestAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['placement-test'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  PlacementTestUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['placement-test'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

  const findEvaluation = React.useCallback(async() => {
    try {
      const response = await UseEvaluationAllPaginate();
      if (response && response.result) {
        setEvaluation(response.result);
      }
    } catch (e) {
      showSimpleAlert({message: 'Error al encontrar evaluaciones', icon: 'error'});
    }
  }, []);

  React.useEffect(() => {
    const type = values.type === PlacementType.PLACEMENT_TEST ? EvaluationType.PLACEMENT_TEST : 
      EvaluationType.WELCOME_DAY
    setEvaluationType(type);
  }, [values.type]);

  React.useEffect(() => {
    if (evaluationType === EvaluationType.PLACEMENT_TEST || 
      evaluationType === EvaluationType.WELCOME_DAY 
      // || evaluationType === EvaluationType.QUIZ
    ) {
      findEvaluation();
    }
  }, [evaluationType]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Type</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              {Object.values(PlacementType).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Evaluation</label>
            <select
              name='evaluation'
              className='form-select'
              aria-label='Default select example'
              value={values.evaluation}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              <option value={undefined}></option>
              {evaluation && evaluation.map((x) => (
                <option key={x.id} value={x.id}>{x.name}</option>
              ))}
            </select>
          </div>


          {values.type === PlacementType.PLACEMENT_TEST && <>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Pre A1</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'pre_a_one'}
              value={values.pre_a_one}
              onChange={handleChange}
            />
            {touched.pre_a_one && errors.pre_a_one && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.pre_a_one}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>A1</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'a_one'}
              value={values.a_one}
              onChange={handleChange}
            />
            {touched.a_one && errors.a_one && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.a_one}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>A2</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'a_two'}
              value={values.a_two}
              onChange={handleChange}
            />
            {touched.a_two && errors.a_two && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.a_two}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>B1</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'b_one'}
              value={values.b_one}
              onChange={handleChange}
            />
            {touched.b_one && errors.b_one && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.b_one}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>B2</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'b_two'}
              value={values.b_two}
              onChange={handleChange}
            />
            {touched.b_two && errors.b_two && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.b_two}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>C1</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'c_one'}
              value={values.c_one}
              onChange={handleChange}
            />
            {touched.c_one && errors.c_one && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.c_one}</span>
                </div>
              </div>
            )}
          </div>
          </>}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {PlacementTestForm}
