import useAuth from "../../../../../api/main"
import { showSimpleAlert } from "../../../../../commonHooks/alert"
import { api } from "../../../../auth/services/auth.services"

const { token } = useAuth.getState()

export const UseGetOneUserByID = async (id:string) => {
  try {
    const response = await api.get(`/auth/profile/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    return response.data
  } catch (e) {
    console.error(e)
    showSimpleAlert({message: `${e}`, icon: "error"})
  }
}