import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionVerificationTest, QuestionAndAnswer } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';

type Props = {
    question: QuestionVerificationTest
    handleNext: (x:number) => void
    handleSendAnswer: (x:Answer) => void
    modeQuiz:'RESUME'|'ANSWER'|'THROW_ERROR'|'TITLE'
 
    setStatus:(string)=>void
    setStep:(number)=>void
    totalQuestions:number
};

const BasicVocabularyExerciseCompletionItem: React.FC<Props> = ({
        setStep,
        setStatus,
   
        question,
        handleNext,
        modeQuiz,
        handleSendAnswer,
        totalQuestions
}: Props) => {
    const { mode } = useThemeMode();
    const {evaluationLesson} = UseEvaluationCourseModule();

    const [selected, setSelected] = React.useState<Answer>();
    const [hoverString, setHoverString] = React.useState<any|null>(null);
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    // const handleSend = () => {
    //     if(selected?.isTrue){
    //         handleSendAnswer(selected)
    //         setSelected(undefined)
    //     }
    //     if(selected?.isTrue ===false){
     
    //         handleSendAnswer(selected)
    //         setSelected(undefined)
    //     }
    // };
    // const filterTrueAnswers = (answersArray:Answer[]):string[] =>{
    //     let resume = answersArray.find((x)=>x.isTrue === true);
    //     return resume?.title??[]
    // }
    //const filterStringCut =  (paragraph:string):string=>{
    //     let wordsToRemove: string[] = filterTrueAnswers(question.answers);
    //     const normalizeText = (text: string): string => {
    //         return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    //     };
    //     const cleanedParagraph = normalizeText(paragraph).replace(/[.,'"\?]/g, "");
    //     const paragraphElements = cleanedParagraph.split(/(\s+|\b)/);
    //     let normalizedWordsToRemove = wordsToRemove.map(word => normalizeText(word));
    //     let total = paragraphElements.map(element => normalizedWordsToRemove.includes(normalizeText(element)) ? "_".repeat(element.length) : element).join('');
    //     return total;
    // }
    

    useEffect(() => {
        setSelected(undefined)
    }, [question]);

    return (
        <>
            <div className=" card-basic-verification-test"
                style={
                    mode !== 'dark' ? {
                        height: 420,

                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,

                        background: '#1e1e2d',
                        border: `1px solid`,
                        borderColor: '#3e94ff',
                        position: 'relative'
                    }
                }
                >

                <h3 className='poppins-light subtitle-basic-vocabulary-completion text'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',
                    }}
                >
                    <strong style={{ fontWeight: 'bold' }}>Instrucciones</strong>Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.  
                </h3>
                <h2 className='champ-light title-basic-vocabulary-completion text'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',
                    }}>
                    #{question.order}/{totalQuestions}
                </h2>
 
                <h2 className='champ-light title-basic-vocabulary-completion text mb-5D'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',
                    }}>
                     {question.name}
                </h2>
                {/* { modeQuiz === 'ANSWER' && !selected && question.answers.map((x, i) => (<h2 
                    key={i}
                    onClick={()=>{
                                handleClick();
                                setSelected(x)
                            }}
                    className='poppins-light-italic option-basic-vocabulary-completion'
                    >
                        <i className="bi bi-check icon-option-basic-vocabulary-completion" style={{ color: 'transparent'}}></i>
                       <span className='cursor-pointer custom-hover badge rounded-pill px-1 my-1'
                            style={{
                                color: evaluationLesson?.colorPrimary??'#3e94ff',
                                fontSize: 20
                            }}
                       > {i+1}. {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))} </span>
                </h2>))} */}
{/* 
                {selected && question.answers.map((x,i) => (<h2
                    className='poppins-light-italic option-basic-vocabulary-completion d-flex'
                    style={{ color: evaluationLesson?.colorPrimary??'#3e94ff',top:-15}}>
                         {x.isTrue ?
                        <i className="bi bi-check icon-option-basic-vocabulary-completion mb-1" style={{ color: 'green'}}></i>:
                        <i className="bi bi-x icon-option-basic-vocabulary-completion" style={{ color: 'red'}}></i>}
                            
                            {selected.order === x.order ?
                                <span className="badge rounded-pill my-1 px-1" style={{background:'#bdbeff', color:evaluationLesson?.colorPrimary??'#3e94ff',fontSize:20}}>
                                    {i+1}. {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))}
                                </span>
                                :  (<span className="badge rounded-pill my-1 px-1"
                                        style={{fontSize:20, color:evaluationLesson?.colorPrimary??'#3e94ff'}}
                                    >
                                    {i+1}. {x.title.map((y,index)=>(
                                        <>{y}{x.title.length===index+1?'':'/'}</>
                                    ))}
                                    </span>)
                            }
                </h2>))}

                {modeQuiz === 'THROW_ERROR' && <>
                    {question.order > 1 && <div
                        className='  arrow-left-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => setStep(-1)}
                    >
                        <a href='#' className='poppins-light text-cursor-basic-vocabulary-completion-left' style={{ position: 'absolute' }}> back</a>

                        <img className='' src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                    </div>}
                    <div
                        className='arrow-right-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => setStep(1)}
                    >
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                    </div>
                </>}       
                {modeQuiz === 'ANSWER' && selected &&<>
                    <div
                        className='arrow-right-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => handleSend()}
                    >
                        <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                    </div>
                </>}     */}
            </div>
        </>
    );
};

export { BasicVocabularyExerciseCompletionItem };