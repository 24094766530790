import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { ModeSpeakOut } from '../../../../../modules/courses/exercises/speak-out/store';

type Props = {
    typeExercise: ModeSpeakOut;
};

const WritingDownHeaderCard: React.FC<Props> = ({typeExercise}) => {
        return (
        <>
            <div className="p-1 d-flex ">
                <img className="" src={
                    toAbsoluteUrl(`/media/svg/michigan-icons/header/white/${typeExercise === 'WRITE_DOWN' ? 'write_it_down.svg' : 'speak_out.svg'}`)
                } style={{ width: '40px', height: '40px' }}/>
                <h5 className="mb-0 p-2 text-white champ-light" style={{ fontSize: "20px" }}>
                    {typeExercise === 'WRITE_DOWN' ? 'Write it down!' : 'Speak out!'}
                </h5>
            </div>
        </>     
        );
       };
                
export default WritingDownHeaderCard;