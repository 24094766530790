import React from "react";
import '../audio/index.css'
type Props = {
  urlHtml: string;
  cover?: string;
};

export const SanitizeHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.innerHTML;
};

const MediaContentHtml: React.FC<Props> = ({urlHtml, cover}) => {
    const [htmlContent, setHtmlContent] = React.useState<string | null>(null);

    React.useEffect(() => {
        const fetchHtmlContent = async () => {
          try {
            const response = await fetch(urlHtml);
            const htmlText = await response.text();
            const sanitizedHtml = SanitizeHtml(htmlText);
            setHtmlContent(sanitizedHtml);
          } catch (error) {
            console.error("Error al cargar el HTML:", error);
          }
        };
        fetchHtmlContent();
      }, [urlHtml]);
    return(<> 
        <div
            dangerouslySetInnerHTML={{ __html: htmlContent || '' }}
            style={{
              position: 'relative',
              background: `url(${cover}) center center / cover no-repeat`,
            }}
            className="card-media-picture scroll-y mb-20"
        />
    </>)
};

export {MediaContentHtml};