import {Route, Routes} from 'react-router-dom'
import { WorkshopDetails } from '../components/details'
import { WorkshopTableList } from '../components/list'


const WorkshopPage = () => (
  <Routes>
      <Route path='/details' element={<WorkshopDetails />} />
      <Route path='/' element={<WorkshopTableList />} />
  </Routes>
)

export {WorkshopPage}
