import {Route, Routes} from 'react-router-dom'
import { LeadSourceDetails } from '../components/details'
import { LeadSourceTableList } from '../components/list'


const LeadSourcePage = () => (
  <Routes>
      <Route path='/details' element={<LeadSourceDetails />} />
      <Route path='/' element={<LeadSourceTableList />} />
  </Routes>
)

export {LeadSourcePage}
