import React, { useEffect, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useAppointmentState } from '../../hooks/useAppointmentRepo'
import { Appointment, LeadResume, Teacher } from '../../interfaces'
import * as Swal from 'sweetalert2'
import moment from 'moment'
import Draggable from 'react-draggable'; // The default


import { UseLeadResumeModule } from '../../store/main'
import { UseCountryAll } from '../../../admin/country/services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { ButtonSelector } from '../../../../components/common/ButtonSelector'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { BooleanCell } from '../../../../components/common/BooleanCell'
import { DayPickerRangeSelector } from '../../../../components/common/DayRangePicker/indext'
import { CreateModal } from '../../../admin/country/components/modal'
import { deleteItem } from '../../../auth/services/auth.services'
import { ILayout, getLayout } from '../../../../../_metronic/layout/core'
import { ProgressBar } from '../../../../components/common/PrgressBar'
import { Country } from '../../../admin/country/interfaces'
import { MasterClassLevel } from '../../../admin/master-class-level/interfaces'
import { User } from '../../../apps/user-management/users-list/core/_models'
import { LeadSource } from '../../../admin/lead-source/interfaces'
import { UseLeadSourceAll } from '../../../admin/lead-source/services'
import { UseUserTypePaginate } from '../../../admin/user/services'
import { UseMasterClassLevelAll } from '../../../admin/master-class-level/services'
import AllExcel from '../../../../components/common/AllExcel'
type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
export interface DateRange {
  before: Date
  after: Date
}
type Props = {

}
const ResumeTableList: React.FC<Props> = ({ }) => {
  const [countries, setCountries] = React.useState<Country[]>([])
  const [masterClassLevel, setMasterClassLevel] = React.useState<MasterClassLevel[]>([])
  const [teachers, setTeachers] = React.useState<any[]>([])
  const [levels, setLevels] = React.useState<MasterClassLevel[]>([])
  const [leadSources, setLeadSources] = React.useState<LeadSource[]>([])
  const [config, setConfig] = useState<ILayout>(getLayout());
  

  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    level,
    teacher,
    after,
    source,
    before,
    country,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setCountry,
    setWhere,
    setToAdd,
    setTeacher,
    setDate,
    setSource,
    setLevel
  } = UseLeadResumeModule()
  const { data, isLoading } = useAppointmentState();
  const [dateRange, setDateRange] = React.useState<DateRange>({
    after: after,
    before: before
  });
  const handleSearch =() =>{
    queryClient.invalidateQueries({ queryKey: ['resume'] })
  }
  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });
  const theadName = [
    "ID",
    "NOMBRE",
    "EMAIL",
    "TELEFONO",
    "DESCRIPCION",
    "CODIGO",
    "CONTRATO",
    "REFERIDO",
    "ACTIVO",
    "ASISTIO",
    "ORIGEN",
    "NIVEL",
    "MATRICULADO",
    "INTERES",
    "CREADO",
    " ",
    "RED SOCIAL",
    " ",
    "NIVEL",
    "PAIS",
    "EDITADO"
  ]

  const changeDate = (date: DateRange) => {
    setDate(date)
  };
  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }
  const fetchCountries = async () => {
    try {
      let countries = await UseCountryAll()
      setCountries(countries)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontar paises!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchSourceLead = async () => {
    try {
      let source = await UseLeadSourceAll()
      setLeadSources(source)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontar redes!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchTeacher = async () => {
    try {
      let teacher = await UseUserTypePaginate('TEACHER')
      setTeachers(teacher)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' })
      throw 'Error'
    }
  }
  const fetchLevels = async () => {
    try {
      let countries = await UseMasterClassLevelAll()
      setLevels(countries)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Nivles!', icon: 'error' })
      throw 'Error'
    }
  }
  useEffect(() => {
    fetchCountries();
    fetchSourceLead();
    fetchTeacher();
    fetchLevels();
  }, [])

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['resume'] })
  }, [isActive, where, currentPage, limit, after, before])
  return (<>
    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-1'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Resusmen</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Reportes </span>
        </h3>
 
      </div>
      {/* end::Header */}


      {/* begin::Body */}
      <div className='py-0 row'>
        {/* begin::Table container */}

        <div className='mb-10 col-3 p-8 mh-700px scroll-y'>

          <DayPickerRangeSelector
            after={after}
            before={before}
            changeDate={(e) => setDate({
              after: e.after,
              before: e.before
            })}
          />


            <div className='row'>
              <div className=' col-12'>
                <label className='form-label mb-3'>País</label>
                <select
                  name={'country'}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {countries.map((x) => (<option value={x.id}>{x.name}</option>))}
                </select>
              </div>
              <div className=' col-12'>
                <label className='form-label mb-3'>Master Class</label>
                <select
                  name={'level'}
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {levels.map((x) => (<option value={x.id}>{x.name} {x.country.name}</option>))}
                </select>
              </div>
              <div className=' col-12'>
                <label className='form-label mb-3'>Profesor</label>
                <select
                  name={'teacher'}
                  value={teacher}
                  onChange={(e) => setTeacher(e.target.value)}
                  className="form-select" aria-label="Default select example">
                    <option selected></option>
                   {teachers.map((x) => 
                      (<option value={x.id}>{x.firstName.toUpperCase()??''} {x.lastName.toUpperCase()??''}</option>)
                    )}
                </select>
              </div>
              <div className=' col-12'>
                <label className='form-label mb-3'>Network</label>
                <select
                  name={'source'}
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {leadSources.map((x) => (<option value={x.id}>{x.name}</option>))}
                </select>
              </div>
            </div>
            <div className="d-grid gap-2 col-12 my-1 mx-auto">
              <button className="btn btn-primary" type="button" 
                disabled={isLoading}
                onClick={()=>handleSearch()}
              >{isLoading?'Carganando...':'Buscar'}</button>
            </div>
 
            {paginator && paginator.result?.length>0 && <AllExcel
         dataExcel={paginator.result} 
         filename={`Master Class Resume`} 
         sheetName='Leads' 
         theadName={theadName}
        />}
          {paginator && paginator.stats && <>
            <ProgressBar
              color='primary'
              title={'Asistencias'}
              stats={paginator.stats?.attended}
            />
            <ProgressBar
              color='primary'
              title={'Matrícula'}
              stats={paginator.stats?.enrolled}
            />


            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Niveles
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse " data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    {paginator.stats && paginator.stats.level && <>
                      <ProgressBar
                        color='success'
                        title={'PRE A1'}
                        stats={paginator.stats?.level.pre_a_1}
                      />
                      <ProgressBar
                        color='success'
                        title={'A1'}
                        stats={paginator.stats?.level.a1}
                      />
                      <ProgressBar
                        color='success'
                        title={'A2'}
                        stats={paginator.stats?.level.a2}
                      />
                      <ProgressBar
                        color='success'
                        title={'B1'}
                        stats={paginator.stats?.level.b1}
                      />
                      <ProgressBar
                        color='success'
                        title={'B2'}
                        stats={paginator.stats?.level.b2}
                      />
                      <ProgressBar
                        color='success'
                        title={'C1'}
                        stats={paginator.stats?.level.c1}
                      />
                      <ProgressBar
                        color='success'
                        title={'N/A'}
                        stats={paginator.stats?.level.na}
                      />
                    </>}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Interés
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    {paginator.stats && paginator.stats.interest && <>
                      <ProgressBar
                        color='success'
                        title={'Muy Alto'}
                        stats={paginator.stats?.interest.veryHigh}
                      />
                      <ProgressBar
                        color='success'
                        title={'Alto'}
                        stats={paginator.stats?.interest.high}
                      />
                      <ProgressBar
                        color='success'
                        title={'Moderado'}
                        stats={paginator.stats?.interest.moderate}
                      />
                      <ProgressBar
                        color='success'
                        title={'Bajo'}
                        stats={paginator.stats?.interest.low}
                      />
                      <ProgressBar
                        color='success'
                        title={'Muy Bajo'}
                        stats={paginator.stats?.interest.veryLow}
                      />
                      <ProgressBar
                        color='success'
                        title={'N/A'}
                        stats={paginator.stats?.interest.na}
                      />
                    </>}
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Origen
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    {paginator.stats && paginator.stats.source && <>
                      <ProgressBar
                        color='success'
                        title={'Campaña'}
                        stats={paginator.stats?.source.campaign}
                      />
                      <ProgressBar
                        color='success'
                        title={'Ejecutivos'}
                        stats={paginator.stats?.source.executive}
                      />
                      <ProgressBar
                        color='success'
                        title={'Orgánico'}
                        stats={paginator.stats?.source.organic}
                      />

                    </>}
                  </div>
                </div>
              </div>
            </div>





          </>}

        </div>
        <div className='mb-10 col-9 mh-700px scroll-y'>
          {!isLoading && data && <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted  text-start'>
                  <th className='min-w-120px text-start'>Nombre</th>
                  <th className='min-w-120px text-start'>Email</th>
                  <th className='min-w-120px text-start'>Teléfono</th>
                  <th className='min-w-120px text-start'>Asistió</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {isLoading && <tr className='text-center'>
                  <img
                    className="rounded mx-auto d-block"
                    width={'70%'}
                    src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
                </tr>}
                {data.result.map((x: LeadResume) => (
                  <tr key={x.id} className='p-0'>
                    <td className='p-0'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {x.level && x.name}
                          </a>

                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        <strong>{x.email}</strong>
                      </span>
                    </td>
                    <td className='p-0'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        <strong>{x.phoneNumber}</strong>
                      </span>
                    </td>
                    <td className='p-0'>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        <strong>{x.attended==='YES'?'Si':'No'}</strong>
                      </span>
                    </td>
 
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* <GenericPaginator 
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              /> */}
            {/* end::Table */}
          </div>}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { ResumeTableList }
