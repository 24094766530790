import {Route, Routes} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Horizontal } from '../components/wizard'
import { AlternativeWizard } from '../components/wizard/alternative'
import { ErrorsLayout } from '../../errors/ErrorsLayout'
import { BookingLayout } from '../layout'

const wizardsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Wizards',
      path: '/crafted/pages/wizards/horizontal',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const BookingPage = () => (
  <Routes>
    <Route element={<BookingLayout />}>
      <Route path='/:code' element={<>
      <PageTitle breadcrumbs={wizardsBreadCrumbs}>Horizontal</PageTitle>
        <AlternativeWizard className='xxx' />
        </>
        } />
      </Route>
  </Routes>
)

export {BookingPage}
