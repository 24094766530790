import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';

const CheckWritingHeaderCard = () => {
        return (
        <>
            <div className="p-1 d-flex ">
                <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/beyond_the_reading_btn1.svg')} style={{ width: '40px', height: '40px' }}/>
                <h5 className="mb-0 p-2 text-white champ-light" style={{ fontSize: "20px" }}>Check your writing! </h5>
            </div>
        </>     
        );
       };
                
export default CheckWritingHeaderCard;