import { create  } from "zustand";

enum ExerciseNineEnum {
    A = 'A',
    P = 'P',
    R = 'R'
}

interface InterfaceModuleExercise{
    checked: boolean;
    Arrayquestion: { idWord: string, title: string, value: ExerciseNineEnum }[];
    instruction: string;
    setChecked:(value: boolean)=>void
}
export const UseModuleExerciseNine = create<InterfaceModuleExercise>((set)=> ({
    checked:false,
    instruction: 'look at the sentences from the text and clasify them into',
    Arrayquestion : [
            {idWord: 'word_1',title:"Everyone arrived izzzzzzzz`ioioipcv.",value: ExerciseNineEnum.P},
            {idWord: 'word_2',title:"An explorer arriveczzzzcvd on the island.",value: ExerciseNineEnum.P},
            {idWord: 'word_3',title:"The Olssons left Szzzzzzzzzzzzvcbxcvbweden..",value: ExerciseNineEnum.P},
            {idWord: 'word_4',title:"There was a planzxxxxxe cvbxvcoccident.",value: ExerciseNineEnum.P},
            {idWord: 'word_5',title:"There was a planzxxxxxe cvbxvcoccident.",value: ExerciseNineEnum.P},
        
        ],
        setChecked:(value) => {
            set({checked: value})
        },
    }))