import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { api } from "../../../../auth/services/auth.services";
import { AnswerCoordinates } from "../components/coordiantes-item";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseCoordinates {
  task: string
  exercises: string;
  percentage: number;
  isActive: boolean;
  answers: AnswerCoordinates[];
}

export const AddCoordinatesResponse = async (data: ResponseCoordinates): Promise<any> => {
    try {
      const response: any = await api.post(
        '/coordinates-response',
        {
          ...data,
        },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }