import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { forgotPasswordService } from '../services/auth.services'
import { logoAppSelector, logoAppSelectorUrl } from '../../../hooks/useAppSelectorAssetsMode'
import { showSimpleAlert } from '../../../commonHooks/alert'

const initialValues = {
  email: ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols') 
    .required('Email is required'),
})

export function ForgotPasswordPlatform() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const [buttonStyles, setButtonStyles] = useState({
    backgroundColor: '#1bebd4',
    color: '#2d30e1',
  });
  let url = logoAppSelectorUrl();

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        forgotPasswordService({
          email: values.email
        }).then(({ data: { result } }) => {alert()
          showSimpleAlert({ message: 'Email de recuperación enviado', icon: 'success' })
          setHasErrors(false)
          setLoading(false)
        })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <div className='w-100'
      style={{
        overflowY: 'hidden',
        minHeight: '100vh',
        height: '100%',
      }}>
      <img
        src={toAbsoluteUrl('/media/svg/michigan-icons/image_login_min.png')}
        alt="Background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      <div className='row w-100'>

        <div className='col-lg-4 col-md-6'>
          <div className='mt-20 ms-20 col-12'>
            <img
              src={toAbsoluteUrl(logoAppSelector('LIGHT'))}
              alt=""
              className='img-fluid title'
              style={{ width: '22%' }}
            />
          </div>
          <div className='mt-15 ms-17 col-12'>
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/TITULO_blanco.svg')}
              alt=""
              className='img-fluid'
              style={{ width: '83%' }}
            />
          </div>
          <div className='mt-6 col-12'>
            <a href={url.www} className='poppins-light text-decoration-none text-white ms-20'>
              {url.www}
            </a>
          </div>
        </div>

        <div className='col-lg-4 col-md-6'>
          <div className='d-flex align-items-end h-100 justify-content-center' style={{ marginTop: '-16%' }}>
            <button onClick={() => window.location.replace('/auth')} className='btn' style={{ backgroundColor: '#FFFFFF', borderRadius: 30 }}>
              <span className='p-2 fs-1 poppins-light'>
                Entrar
                <i className='fa-regular fa-circle-right ms-5 fs-1' style={{ color: '#2d30e1' }}></i>
              </span>
            </button>
          </div>
        </div>

        <div className='col-lg-4 col-md-12 d-flex flex-column justify-content-center align-items-center'>
          <div className='col-12 d-flex justify-content-center align-items-center'>
            <form
              className='form w-75 row d-flex justify-content-center align-items-center'
              onSubmit={formik.handleSubmit}
              style={{
                backgroundColor: '#1bebd4',
                borderRadius: 30,
                background: 'linear-gradient(to bottom, #1bebd4 70%, rgba(27, 235, 212, 0.6))',
                margin: '20px 0',
              }}
              noValidate
              id='kt_login_signin_form'
            >

              <div className='col-12 p-10'>
                <label className='form-label poppins-bold d-flex justify-content-center mb-3' style={{ color: '#2d30e1' }}>Recuperación de contraseña</label>
                <div className='col-12 mb-3'>
                  <label className='form-label poppins-bold' style={{ color: '#2d30e1', marginBottom: '-2px' }}>Email</label>
                  <input
                    type='email'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    style={{
                      borderBottom: '1px solid #2d30e1',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className='col-12'>
                                <label className='form-label poppins-bold' style={{ color: '#2d30e1' }}>Password</label>
                                <input
                                    type='password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                    )}
                                    style={{
                                        borderBottom: '1px solid #2d30e1',
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderRight: 'none',
                                    }}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                    </div>
                                )}
                            </div> */}
              </div>
              <div className='col-12 mb-10 d-flex justify-content-center align-items-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn w-100'
                  style={{
                    borderRadius: 30,
                    border: '1px solid #2d30e1',
                    transition: 'background-color 0.3s, color 0.3s',
                    ...buttonStyles,
                  }}
                  onMouseOver={() => {
                    setButtonStyles({
                      backgroundColor: '#2d30e1',
                      color: '#ffffff',
                    });
                  }}
                  onMouseOut={() => {
                    setButtonStyles({
                      backgroundColor: '#1bebd4',
                      color: '#2d30e1',
                    });
                  }}
                >
                  {!loading && <span className='indicator-label poppins-bold'>Recuperar contraseña</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>

          <div className='col-12 mt-3 d-flex justify-content-center align-items-center'>
            <a href={url.facebook} className='text-decoration-none'>
              <i className="fa-brands fa-facebook-f text-white" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.instagram} className='text-decoration-none'>
              <i className="fa-brands fa-instagram text-white ms-9 cursor-pointer" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.youtube} className='text-decoration-none'>
              <i className="fa-brands fa-youtube text-white ms-9" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.in} className='text-decoration-none'>
              <i className="fa-brands fa-linkedin-in text-white ms-9" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.tiktok} className='text-decoration-none'>
              <i className="fa-brands fa-tiktok text-white ms-9" style={{ fontSize: 30 }}></i>
            </a>
          </div>
        </div>
      </div>


    </div>
  )
}
