import { useEffect } from "react";
import { showSimpleAlert } from "../../../commonHooks/alert";
import { UseModuleCompleteSentence } from "../../../components/abroad/e-book/beyong-the-reading/ExerciseOne/store/main";
import { CategoriesHighlightWord, UseModuleExerciseHighlightWord } from "../../../components/abroad/e-book/beyong-the-reading/ExerciseSeven/store/main";
import { EvaluationItem } from "../../admin/evaluation/interfaces";
import { ExercisesType } from "../../admin/exercises/interfaces";
import { QuestionsCompleteSentenceIE } from "../../admin/exercises/templates-exercise/book/complete-sentences/interfaces";
import { CorrectSentenceInterface } from "../../courses/exercises/CorrectSentence/interfaces";
import { UseCorrectSentenceItemModule } from "../../courses/exercises/CorrectSentence/store";
import { ClueCrossword, UseModuleExerciseCrossword } from "../../courses/exercises/Crossword/store/main";
import { WritingCheckQuestions } from "../../courses/exercises/WrintingCheckList/interfaces";
import { UseWritingCheckListModule } from "../../courses/exercises/WrintingCheckList/store";
import { UseBoosterExercise } from "../../courses/exercises/booster/main/main";
import { CheckWritingQuestionIE, CheckWritingTheme, CheckWritingType } from "../../courses/exercises/check-writing/interfaces";
import { UseCheckWritingModule } from "../../courses/exercises/check-writing/store";
import { UseExerciseTimerModule } from "../../courses/exercises/common/store/main";
import { ColumnItemCoordinates, RowItemCoordinates, UseCoordinatesEleven } from "../../courses/exercises/coordinates/main/main";
import { UseGroupWordsModules } from "../../courses/exercises/group-words/main/main";
import { QuestionHistoryWriting } from "../../courses/exercises/history-wrinting/interfaces";
import { UseHistoryWritingModule } from "../../courses/exercises/history-wrinting/store";
import { MultipleSelectionQuestionIE } from "../../courses/exercises/multiple-selection/interfaces";
import { UseMultipleSelectionModule } from "../../courses/exercises/multiple-selection/store";
import { QuestionOrderSentences } from "../../courses/exercises/order-sentence/interfaces";
import { UseOrderSentencesModule } from "../../courses/exercises/order-sentence/store";
import { UseParagraphDragDropModule, WordItem } from "../../courses/exercises/paragraph-drag-drop/store";
import { UseSpeakOutModule } from "../../courses/exercises/speak-out/store";
import { QuestionTrueFalse } from "../../courses/exercises/true-false/interfaces";
import { UseTrueFalseModule } from "../../courses/exercises/true-false/store";
import { TrueOptionItem, TrueOptionSentence } from "../../courses/exercises/true-options/interfaces";
import { UseTrueOptionsModule } from "../../courses/exercises/true-options/store";
import { TrueWordsInterface } from "../../courses/exercises/true-words/interfaces";
import { UseTrueWordsModule } from "../../courses/exercises/true-words/store";
import { IsExerciseTimeUp } from "../../courses/exercises/utils";
import { UseAudioInteractionModule } from "../components/interactions/store/main";
import { transformStringToJson } from "../modules/test/store/main";

export const setQuestionsEbook = (currentQuestion: EvaluationItem) => {
    
    const {setQuestionsCross} = UseModuleExerciseCrossword.getState();
    const {setQuestionsOrderSentences} = UseOrderSentencesModule.getState();
    const {setQuestions: setWritingCheckList} = UseWritingCheckListModule.getState();
    const {setQuestions: setHighlightWord} = UseModuleExerciseHighlightWord.getState();
    const {setQuestionsParagraphDrag} = UseParagraphDragDropModule.getState();
    const {setQuestions: setCorrectSentence} = UseCorrectSentenceItemModule.getState();
    const {setQuestions: setTrueWords} = UseTrueWordsModule.getState();
    const {setQuestionsTrueFalse} = UseTrueFalseModule.getState();
    const {setQuestions: setTrueOptions} = UseTrueOptionsModule.getState();
    const {setQuestions: setMultipleSelection} = UseMultipleSelectionModule.getState();
    const { setQuestionsHistory } = UseHistoryWritingModule.getState();
    const {setContent} = UseAudioInteractionModule.getState();
    const {setQuestions: setGroupWords} = UseGroupWordsModules.getState();
    const {setQuestions: setCoordinates} = UseCoordinatesEleven.getState();
    const {setValues: setBooster} = UseBoosterExercise.getState();
    const {setQuestions: setCheck} = UseCheckWritingModule.getState();
    const {setTip} = UseSpeakOutModule.getState();
    const {setQuestions: setCompleteSentence, setAnswer} = UseModuleCompleteSentence.getState();
    const { setExerciseBeforeTime, setTimeLeft} = UseExerciseTimerModule.getState()

    const exercise = currentQuestion.exercise;
    const type = currentQuestion?.exercise.type as ExercisesType;

    //poner a correr el timer desde que entra al ejercicio, depende de cada componente hacer que     
    IsExerciseTimeUp(
        exercise.id,
        currentQuestion.timer, 
        (e) => {
            setExerciseBeforeTime(e)
        }, 
        (e) => {
            setTimeLeft(e)
        }
    )

    switch (type) {
        case ExercisesType.CROSSWORD:
            const sentencesCross = transformStringToJson(
                exercise,
                'crossword',
                'sentences',
                'camp'
            );
            setQuestionsCross(
                exercise.id,
                exercise.description,
                sentencesCross as ClueCrossword[],
                exercise.crossword[0].rows,
                exercise.crossword[0].cols
            );
            break;
        case ExercisesType.ORDER_SENTENCES:
            const answer = transformStringToJson(
                exercise, 'order_sentences', 'answer', 'camp'
            );
            setQuestionsOrderSentences(
                exercise.id,
                exercise.description,
                answer as QuestionOrderSentences[]
            );
        break;
        case ExercisesType.WRITING_CHECK_LIST:
            const questionsWriting = transformStringToJson(
                exercise,
                'writing_check',
                'questions',
                'camp'
            );
            setWritingCheckList(
                exercise.id,
                exercise.description,
                questionsWriting as WritingCheckQuestions[],
                exercise?.writing_check[0]?.type
            );
        break;
        case ExercisesType.HIGHLIGHT_WORD:
            const categories = transformStringToJson(
                exercise,
                'highlight_word',
                'categories',
                'camp'
            );
            setHighlightWord(
                exercise.id,
                exercise.description,
                exercise.highlight_word[0].questionText,
                categories as CategoriesHighlightWord[],
            );
        break;
        case ExercisesType.TRUE_OPTIONS:
            const optionsTrueOptions = transformStringToJson(
                exercise, 'true_option', 'options', 'camp'
            );
            const sentencesTrueOptions = transformStringToJson(
                exercise, 'true_option', 'sentences', 'camp'
            );
            setTrueOptions(
                exercise.id, 
                exercise.description, {
                options: optionsTrueOptions as TrueOptionItem[],
                sentences: sentencesTrueOptions as TrueOptionSentence[]
            });
        break;
        case ExercisesType.MULTIPLE_SELECTION:
            const questions = transformStringToJson(
                exercise, 'multiple_selection', 'questions', 'camp'
            );
            setMultipleSelection(
                exercise.id,
                exercise.description,
                questions as MultipleSelectionQuestionIE[]
            );
        break;
        case ExercisesType.DRAG_DROP:
            const words = transformStringToJson(
                exercise, 'drag_drop_questions', 'words', 'camp'
            );
            // const sentences = transformStringToJson(
            //     exercise, 'drag_drop_questions', 'sentences', 'camp'
            // );
            if (exercise.drag_drop_questions.length > 0) {
                if (exercise.drag_drop_questions[0].sentences.length > 0) {
                    setQuestionsParagraphDrag(
                        exercise.id,
                        words as WordItem[],
                        exercise.drag_drop_questions[0].sentences as string[],
                        exercise.name,
                        exercise.description
                    );
                }
            }
        break;
        case ExercisesType.CORRECT_SENTENCES:
            const question = transformStringToJson(
                exercise, 'correct_sentence', 'question', 'camp'
            );
            setCorrectSentence(
                exercise.id,
                exercise.description,
                question as CorrectSentenceInterface[]
            );
        break;
        case ExercisesType.TRUE_WORDS:
            const sentencesTrueWords = transformStringToJson(
                exercise, 'true_words', 'sentences', 'camp'
            );
            setTrueWords(
                exercise.id,
                exercise.description,
                sentencesTrueWords as TrueWordsInterface[]
            );
        break;
        case ExercisesType.HISTORY_WRITING:
            const questionHistory = transformStringToJson(
                exercise, 'history_writing', 'questions', 'camp'
            );
            setQuestionsHistory(
                exercise.id,
                exercise.description,
                questionHistory as  QuestionHistoryWriting[]
            );
        break;
        case ExercisesType.TRUE_FALSE:
            const answerTrueFalse = transformStringToJson(
                exercise, 'true_or_false', 'answer', 'camp'
            );
            setQuestionsTrueFalse(
                exercise.id,
                exercise.description,
                answerTrueFalse as QuestionTrueFalse[]
            );
        break;
        case ExercisesType.AUDIO_INTERACTION:
            setContent(
                exercise.audio_interaction[0]
            );
        break;
        case ExercisesType.GROUP_WORDS:
            if (exercise.group_word && exercise.group_word.length > 0) {
                const groups = transformStringToJson(
                    exercise, 'group_word', 'groups', 'camp'
                );
                setGroupWords(exercise.id, 
                    exercise.description, 
                    groups,
                );
            }
        break;
        case ExercisesType.COORDINATES:
            const rowCoordinates = transformStringToJson(
                exercise, 'coordinates', 'row', 'camp'
            );
            const columnCoordinates = transformStringToJson(
                exercise, 'coordinates', 'column', 'camp'
            );
            setCoordinates(exercise.id, 
                exercise.description, {
                row: rowCoordinates as RowItemCoordinates[],
                column: columnCoordinates as ColumnItemCoordinates[]
            })
        break;
        case ExercisesType.SPEAK_OUT:
            setTip(
                exercise.id,
                exercise?.speak_out[0]?.tips ?? [],
                exercise?.speak_out[0]?.type ?? 'TIME_SPEAK',
                exercise?.description,
                exercise?.speak_out[0]?.maxWords??50,
                exercise?.speak_out[0]?.minWords??1
            );
            break;
        case ExercisesType.BOOSTER:
            setBooster(
                exercise.id,
                exercise.description,
                exercise.booster[0].sentences
            );
        break;
        case ExercisesType.CHECK_WRITING:
            const questionsCheck = transformStringToJson(
                exercise, 'check_writing', 'questions', 'camp'
            );
            let themesCheck = [];
            if (exercise?.check_writing?.length > 0 &&
                exercise?.check_writing[0]?.type === CheckWritingType.CHECK_WRITING_DESCRIPTION
            ) {
                themesCheck = transformStringToJson(
                   exercise, 'check_writing', 'themes', 'camp'
               );
            }
            setCheck(
                exercise.id,
                exercise.description,
                questionsCheck as CheckWritingQuestionIE[],
                themesCheck as CheckWritingTheme[]
            )
        break;

        case ExercisesType.COMPLETE_SENTENCES:
            const questionCom = transformStringToJson(
                exercise, 'complete_sentence', 'questions', 'camp'
            );
            setCompleteSentence(
                exercise.id,
                exercise.description,
                questionCom as QuestionsCompleteSentenceIE[]
            );
            break;

        default: 
            return showSimpleAlert({message: 'Tipo de ejercicio no permitido', icon:'error'});
    }
};