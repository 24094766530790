export const ChapterTranslateEn = {
  CHAPTERS:"Books",
  CHAPTER:"Book",
  MANAGEMENT_CHAPTER:"Books Management",
  TYPE:"Type",
  NAME:"Name",
  DESCRIPTION:"Description",
  ACTIVE:"Active",
  ACTIONS:"Actions",
  SEQUENCE:"Sequence",
  LEVEL:"Level",
  PRE_A1_1: 'Pre A1-1',
  PRE_A1_2: 'Pre A1-2',
  A1: 'A1',
  PRE_A2: 'Pre A2',
  A2: 'A2',
  PRE_B1: 'Pre B1', 
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  BY_HOUR: 'By Hours',
  BY_TOPICS: 'By Topics',
  EXAM: 'By Exam'
}