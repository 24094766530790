 import React, { useEffect } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { t } from 'i18next'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import moment from 'moment'
import { UseAppointmentDashboardCount, UseByActivityCount, UseTrailClassDashboardCount } from '../../services'
import { UseActivityAll } from '../../../abroad/activity/services'
import { Activity } from '../../../abroad/activity/interfaces'
import { showSimpleAlert } from '../../../../commonHooks/alert'

export interface LevelCount {
    level: TraditionalLevelEnum
    count: string
}
 
type LevelChartsProps = {
    after: Date
    before: Date
}
export interface ResumeResponse {
    totalTrials: string
    studentsAttended: string
    studentsMissed: string
    teachersAttended: string
    teachersMissed: string
  }
const AppointmentByActivityChart: React.FC<LevelChartsProps> = ({  after, before }) => {
    const [resume, setResume] = React.useState<ResumeResponse>()
    const [pendingCount, setPendingCount] = React.useState<number>(0);
    const [cancelledCount, setCanceledCount] = React.useState<number>(0);
    const [inProcessCount, setInProcessCount] = React.useState<number>(0);
    const [finishedCount, setFinishedCount] = React.useState<number>(0);
    const [totalCount, setTotalCount] = React.useState<number>(0);

    const [activities, setActivities] = React.useState<Activity[]>([]);
    const [activity, setActivity] = React.useState<string>();
    let findAppointmentDashboardCount = async () => {
        let _before = moment(before).format('YYYY-MM-DD')
        let _after = moment(after).format('YYYY-MM-DD')
        if(!activity) return;
        let response = await UseByActivityCount(_before, _after,activity)
        setCanceledCount(response.CANCELLED)
        setFinishedCount(response.FINISHED)
        setPendingCount(response.PENDING)
        setInProcessCount(response.IN_PROCESS)
        setTotalCount(response.TOTAL)
    }

    let getPercentageForLevel = (total: number) => {
        const percentage = (total / totalCount) * 100;
        return `${percentage.toFixed(2)}%`;
    }
    const fetchActivity = async () => {
        try {
          let activity = await UseActivityAll()
          setActivities(activity);
        } catch (e) {
          showSimpleAlert({ message: 'Error al encontrar salones!', icon: 'error' })
          throw 'Error'
        }
      };
    
    useEffect(() => {
        findAppointmentDashboardCount()
        fetchActivity();
    }, [after, before,activity])
    return (
        <div className='row card shadow p-8'>
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Actividad</label>
                <select
                  name={'activity'}
                  value={activity}
                  onChange={(e)=>setActivity(e.target.value)}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {activities.map((x) =>
                    (<option value={x.id}>{`${x.name}`}</option>)
                  )}
                </select>
              </div>
 
              <div className='card shadow p-8 col-12'>
                <h1 className='champ-light text-center'>Horas Por Actividad</h1>
                <hr></hr>
                <h5 className='champ-light'>Pendientes {pendingCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(pendingCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(pendingCount)}</div>
                </div>
                <h5 className='champ-light'>Canceladas {cancelledCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(cancelledCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(cancelledCount)}</div>
                </div>
                <h5 className='champ-light'>En Proceso {inProcessCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(inProcessCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(inProcessCount)}</div>
                </div>
                <h5 className='champ-light'>Finalizadas {finishedCount} </h5>
                <div className="progress my-3">
                    <div className="progress-bar bg-success " role="progressbar" style={{ width: getPercentageForLevel(finishedCount) }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(finishedCount)}</div>
                </div>
            </div>
        </div>
    )
}

export { AppointmentByActivityChart }