import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import * as Swal from 'sweetalert2'
import { useQueryClient } from '@tanstack/react-query'
import { TemplateTableTime, TemplateTableTimeDay, TemplateTableTimeItemData } from '../../../../interfaces'
import { TemplateTableTimeItemAdd, TemplateTableTimeItemDelete, TemplateTableTimeItemUpdate, UseTemplateTableTimeItemByTeacherAll,  } from '../../services'
import { UseTemplateTableTimeItemModule } from '../../store/main'
import { KTIcon, toAbsoluteUrl } from '../../../../../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import { UseClassroomAll } from '../../../../../../admin/classroom/services'
import { UseActivityAll } from '../../../../../activity/services'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'
import { Classroom } from '../../../../../../booking-dashboard/interfaces'
import { Activity } from '../../../../../activity/interfaces'
import { TeacherProfileBlockHoursAll, TeacherProfileByDay, UseTemplateAllByClassroomTeacher } from '../../../../../block-hours/services'
import { BlockHour, DataTeacherProfileByDay, TeacherBlockHoursType } from '../../../../../block-hours/interfaces'
import Multiselect from 'multiselect-react-dropdown'
import { extractOrder } from '../../../../../../../components/common/ExcelExport/utils'
import GenericPaginator from '../../../../../../../components/common/Paginator'
import { useTemplateTableTimeItemStateTeacher } from '../../hooks/useTemplateTableTimeItemRepo'
import NexBtnOne from '../../../../../../../components/abroad/e-book/beyong-the-reading/ui/NextBtnOne'

export type FormFields = {
  isActive: boolean;
  activity: string;
  classroom: string;
  blockHour: number[] // BlockHour[];
}

const entitySchema = Yup.object().shape({
  //name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TemplateTableTimeItemForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {
    activity,
    dayAvailable,
    paginatorForm,
    limitForm,
    currentPageForm,
    itemSelected,
    mode,
    day,
    setLimitForm,
    setCurrentPageForm,
    teacherId,
    setDay,
    setActivityLabel,
  } = UseTemplateTableTimeItemModule();

  const { data, isLoading } = useTemplateTableTimeItemStateTeacher();
  const [loading, setLoading] = React.useState<boolean>(false);

  const {id} = useParams();

  const [classroom, setClassroom] = React.useState<Classroom[]>([]);
  const [activities, setActivities] = React.useState<Activity[]>([]);
  const [allItemsByTeacher, setAllItemsByTeacher] = React.useState<TemplateTableTimeItemData[]>([]);

  const [selectedBlockHours, setSelectedBlockHours] = React.useState<DataTeacherProfileByDay>();
  const [blockFreeClass, setBlockFreeClass] = React.useState<BlockHour[]>([]);
  const [blockAdyacente, setBlockAdyacente] = React.useState<BlockHour[]>([]);

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit, resetForm} =
    useFormik<FormFields>({
      initialValues: {
        isActive:  (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        activity:  (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.activity?.id ?? '' : '',
        classroom: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.classroom?.id ?? '' : '',
        blockHour: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.blockHour.map(x => x.order) ?? [] : []
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await TemplateTableTimeItemAdd({
              ...formData,
              teacher: teacherId,
              template: id??'',
              day: day,
              //@ts-ignore
              blockHour: [parseInt(values.blockHour)] ?? [] // extractOrder(values.blockHour) : [],
            })
            resetForm();
            setActivityLabel('all')
            queryClient.invalidateQueries({ queryKey: ['template-table-time-item-teacher'] })
            setLoading(false)
            fetchAllItemsByTeacher();
            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  TemplateTableTimeItemUpdate({
                ...formData,
                teacher: teacherId,
                template: id??'',
                day: day,
                //@ts-ignore
                blockHour: [parseInt(values.blockHour)] ?? [] // ? extractOrder(values.blockHour) : [],
              })
              resetForm();
              setActivityLabel('all')
              queryClient.invalidateQueries({ queryKey: ['template-table-time-item-teacher'] })
              setLoading(false)
              fetchAllItemsByTeacher();
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const deleteItem = async (id:string)=>{
      //@ts-ignore
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
            await TemplateTableTimeItemDelete(id)
            fetchAllItemsByTeacher();
            queryClient.invalidateQueries({ queryKey: ['template-table-time-item-teacher'] })
            showSimpleAlert({message:'Ok!',icon:'success'})
           }catch(e){
            queryClient.invalidateQueries({ queryKey: ['template-table-time-item-teacher'] })
           }
        }
      })
    };

    const changePage = (number: number) => {
      setCurrentPageForm(number)
    };
    const changeLimit =(number:number)=>{
        setLimitForm(number)
    }

    const fetchClassroom = async () => {
      try {
        let classroom = await UseClassroomAll()
        setClassroom(classroom)
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar salones!', icon: 'error' })
        throw 'Error'
      }
    }
    
    const fetchActivity = async () => {
      try {
        let activity = await UseActivityAll()
        setActivities(activity);
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
        throw 'Error'
      }
    };

    const fetchTemplateByClassTeacher = React.useCallback(async () => {
      try {
        if (teacherId && values.classroom && id && day) {
          const templateResponse = await UseTemplateAllByClassroomTeacher({
            room: values.classroom,
            teacher: teacherId,
            template: id??'',
            day: day
          });

          setBlockFreeClass(templateResponse);
        }
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar template item disponibles!', icon: 'error' })
        throw 'Error'
      };
    }, [values.classroom, teacherId, day]);

    const fetchBlockHour = React.useCallback(async () => {
      try {
        if (teacherId && day) {
          const blockHourResponse = await TeacherProfileByDay(teacherId, day);
          setSelectedBlockHours(blockHourResponse);
        }
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
        throw 'Error'
      };
    }, [day, teacherId])

    const fetchAllItemsByTeacher = React.useCallback(async () => {
      try {
        if (teacherId) {
          const itemsResponse = await UseTemplateTableTimeItemByTeacherAll(teacherId, day, id??'');
          setAllItemsByTeacher(itemsResponse);
        }
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar Items del profesor!', icon: 'error' })
        throw 'Error'
      };
    }, [teacherId, day])

    React.useEffect(()=> {
      fetchActivity();
      fetchClassroom();
    }, []);
    
    React.useEffect(() => {
      fetchBlockHour();
      fetchAllItemsByTeacher();
    }, [teacherId, day, id]);

    React.useEffect(() => {
      fetchTemplateByClassTeacher();
    }, [teacherId, values.classroom, id, day]);

    React.useEffect(() => {
      queryClient.invalidateQueries({ queryKey: ['template-table-time-item-teacher'] });
    }, [day, limitForm, currentPageForm, activity, values.activity]);

    React.useEffect(() => {
      if (values.activity) {
        setActivityLabel(values.activity);
      }
    }, [values.activity]);

    const handleClick = (day: TemplateTableTimeDay) => {
      setDay(day);
      setActivityLabel('all');
      resetForm();
    };
    
    // React.useEffect(() => {
    //   const blockHoursBefore: BlockHour[] = [];
    //   const blockHoursAfter: BlockHour[] = [];

    //   values.blockHour.map((value) => {
    //     const nextBlock = blockFreeClass.find(x => x.order === value.order + 1);
    //     const previousBlock = blockFreeClass.find(x => x.order === value.order - 1);
    
    //     if (nextBlock) blockHoursBefore.push(nextBlock);
    //     if (previousBlock) blockHoursAfter.push(previousBlock);
    //   });
    //   setBlockAdyacente([...blockHoursAfter, ...values.blockHour, ...blockHoursBefore]);

    // }, [values.blockHour]);
    
    return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-5 row'>
          <div className='col-12'>
            <div className='overflow-auto'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                    <button
                      disabled={dayAvailable?.monday ? false : true}
                      type='button'
                      className={`btn nav-link text-active-primary me-6 ${day === TemplateTableTimeDay.MONDAY && 'bg-primary text-white'}`}
                      onClick={() => handleClick(TemplateTableTimeDay.MONDAY)}
                    >
                      <span style={{paddingLeft: '5px'}}>Monday</span>
                    </button>
                  </li>
                  <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                    <button
                      disabled={dayAvailable?.tuesday ? false : true}
                      type='button'
                      className={`btn nav-link text-active-primary me-6 ${day === TemplateTableTimeDay.TUESDAY && 'bg-primary text-white'}`}
                      onClick={() => handleClick(TemplateTableTimeDay.TUESDAY)}
                      >
                      <span style={{paddingLeft: '5px'}}>Tuesday</span>
                    </button>
                  </li>
                  <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                    <button
                      disabled={dayAvailable?.wednesday ? false : true}
                      type='button'
                      className={`btn nav-link text-active-primary me-6 ${day === TemplateTableTimeDay.WEDNESDAY && 'bg-primary text-white'}`}
                      onClick={() => handleClick(TemplateTableTimeDay.WEDNESDAY)}
                      >
                      <span style={{paddingLeft: '5px'}}>Wednesday</span>
                    </button>
                  </li>
                  <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                    <button
                      disabled={dayAvailable?.thursday ? false : true}
                      type='button'
                      className={`btn nav-link text-active-primary me-6 ${day === TemplateTableTimeDay.THURSDAY && 'bg-primary text-white'}`}
                      onClick={() => handleClick(TemplateTableTimeDay.THURSDAY)}
                      >
                      <span style={{paddingLeft: '5px'}}>Thursday</span>
                    </button>
                  </li>
                  <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                    <button
                      disabled={dayAvailable?.friday ? false : true}
                      type='button'
                      className={`btn nav-link text-active-primary me-6 ${day === TemplateTableTimeDay.FRIDAY && 'bg-primary text-white'}`}
                      onClick={() => handleClick(TemplateTableTimeDay.FRIDAY)}
                      >
                      <span style={{paddingLeft: '5px'}}>Friday</span>
                    </button>
                  </li>
                  <li className='nav-item mb-4 col-sm d-flex justify-content-center'>
                    <button
                      disabled={dayAvailable?.saturday ? false : true}
                      type='button'
                      className={`btn nav-link text-active-primary me-6 ${day === TemplateTableTimeDay.SATURDAY && 'bg-primary text-white'}`}
                      onClick={() => handleClick(TemplateTableTimeDay.SATURDAY)}
                      >
                      <span style={{paddingLeft: '5px'}}>Saturday</span>
                    </button>
                  </li>
              </ul>
            </div>
            
          </div>

          <div className="col-sm-4">
            <div className="mb-2 col-12">
              <label className='form-label mb-3'>Activo ?</label>
              <div className="form-check form-switch">
                <input
                  name={'isActive'}
                  checked={values?.isActive}
                  value={values?.isActive?1:0}
                  onChange={handleChange}
                  className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              </div>
            </div>
            <div className='mb-1 col-12'>
                <label className='form-label mb-0'>Actividad</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'activity'}
                  value={values?.activity}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option value={'all'}></option>
                  {activities.map((x) =>
                    (<option key={x.id} value={x.id}>{`${x.name}`}</option>)
                  )}
                </select>
                {errors.activity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.activity}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-1 col-12'>
                <label className='form-label mb-0'>Salón </label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'classroom'}
                  value={values?.classroom}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {classroom.sort((a, b) => a.name.localeCompare(b.name)).map((x) =>
                    (<option key={x.id} value={x.id}>{x.name.toUpperCase() ?? ''}</option>)
                  )}
                </select>
                {errors.classroom && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.classroom}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-1 col-12'>
                <label className='form-label mb-0'>Block Hour</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'blockHour'}
                  // value={values?.blockHour && values.blockHour.length > 0 ? values.blockHour : 0}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {blockFreeClass && blockFreeClass.map((x) =>
                    (<option key={x.order} value={x.order}>{x.start} - {x.end}</option>)
                  )}
                </select>
                {/* <Multiselect
                 options={blockAdyacente.length > 0 ? blockAdyacente : blockFreeClass}
                 selectedValues={values.blockHour}
                 displayValue={`start`}
                 onSelect={(x) => {setFieldValue('blockHour', x);}}
                 onRemove={(x) => {setFieldValue('blockHour', x);}}
                 disable={mode === 'VIEW'}
                 className='custom-multiselect'
                /> */}
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
          </div>

          <div className="col-sm-6">
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-120px'>Activity</th>
                      <th className='min-w-120px'>Classroom</th>
                      <th className='min-w-120px'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                  {isLoading &&<tr className='text-center'>
                    <img  
                    className="rounded mx-auto d-block" 
                    width={'40%'}
                    src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
                  </tr>}
                  {data && data?.result?.map((x, i) => {
                  const blockHours = x.blockHour.map((hour) => `${hour.start} - ${hour.end}`);
                  return (
                    <tr>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x?.activity?.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {blockHours.join(', ')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <span className='text-dark fw-semibold d-block fs-7'>
                          {x?.classroom?.name}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              href='#'
                              onClick={()=> deleteItem(x?.id??'xx')}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                            </a>
                        </div>
                      </td>
                    </tr>
                  )
                  })}
                </tbody>
            </table>
            <GenericPaginator 
                  limit={limitForm}
                  currentPage={paginatorForm.current_page}
                  items={paginatorForm.result.length}
                  total={paginatorForm.total}
                  totalPage={paginatorForm.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
          </div>
          
          <div className="col-sm-2">
            <div className='d-flex flex-column'>
              <div className='text-center mb-3'>
                <h5>Block Hour Teacher</h5>
              </div>
              {selectedBlockHours && (
                <div className='d-flex flex-column'>
                  {selectedBlockHours[day?.toLowerCase()??TemplateTableTimeDay.MONDAY.toLowerCase()]?.map((x, i) => (
                    <div
                      key={i}
                      className={`text-white ${
                        allItemsByTeacher?.some(d =>
                          d?.blockHour?.some(m => m.start === x.start && m.end === x.end)
                        )
                          ? 'bg-success'
                          : 'bg-danger'
                      } d-flex align-items-center justify-content-center rounded mb-2`}
                    >
                      {x.start} - {x.end}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            
          </div>
        </div>

      </form>
    </div>
  )
}

export {TemplateTableTimeItemForm}
