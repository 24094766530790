import useAuth from "../../../api/main"
import { showSimpleAlert } from "../../../commonHooks/alert"
import { api, authChecked } from "../../auth/services/auth.services"
import { UseAutoVerification } from "../store/main"

const { token, logout } = useAuth.getState()

export const FileVerificationAdd = async (data: any): Promise<any> => {
    try {
      const response: any = await api.post(
        '/file-verification/file',data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      )
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 400) {
        console.log(e.response)
         e.response.data.message.map((x)=>{
           //@ts-ignore
           showSimpleAlert({ message: x, icon: 'error' })
         })
         throw 'Error'
       }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }

export const VerificationRequestUpdate = async (data: any): Promise<any> => {
  try {
    const {verificationRequest} = UseAutoVerification.getState();
    const response: any = await api.patch(
      `/verification-request/${verificationRequest.id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' });
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const ContentVerificationAdd = async (data: any): Promise<any> => {
    try {
        const response: any = await api.post(
        '/file-verification',
        {
            ...data
        }
        ,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        )
        showSimpleAlert({ message: 'Ok!', icon: 'success' })
        return response.data
    } catch (e:any) {
        //@ts-ignore
        if (e.response.status === 401) {
        logout()
        }
        if (e.response.status === 400) {
        console.log(e.response)
            e.response.data.message.map((x)=>{
            //@ts-ignore
            showSimpleAlert({ message: x, icon: 'error' })
            })
            throw 'Error'
        }
        //@ts-ignore
        showSimpleAlert({ message: 'Error!', icon: 'error' })
        throw 'Error'
    }
} 

export const UseGetOneContract = async (id:string): Promise<any> => {
  try {
    await authChecked()
    const response: any = await api.get(
      `/contract/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}