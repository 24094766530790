export interface DataPaginateVerificationQuestionResponse {
    status:  string;
    message: string;
    data:    DataVerificationQuestion;
}

export interface DataAllVerificationQuestionResponse {
    status:  string;
    message: string;
    data:    VerificationQuestion[];
}

export interface DataVerificationQuestion {
    id?: string;
    name: string
    description: string
    isActive: boolean
    type: TypeResponseVerificationQuestion
    time: number
    order: number
    createdAt?:string;
    updatedAt?:string;
}

export interface VerificationQuestion  {
    id?: string;
    name: string
    description: string
    isActive: boolean
    type: TypeResponseVerificationQuestion
    time: number
    order: number
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: VerificationQuestion[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum TypeResponseVerificationQuestion {
    TEXT = 'TEXT',
    YES_OR_NOT = 'YES_OR_NOT',
    FILE = 'FILE',
    AUDIO = 'AUDIO',
    IMAGE = 'IMAGE',
};