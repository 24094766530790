import{ create} from 'zustand';
import { Pagination, TrailUpgradeStudent } from '../../interfaces';


interface FailModule {
    itemSelectedUpgrade?: TrailUpgradeStudent;
    mode:'ADD'|'EDIT'|'VIEW';
    modal:boolean,
    modalUpgrade:boolean,
    isActive:boolean,
    paginator:Pagination
    where:string
    currentPage:number
    limit:number
    total:number
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setModalUpgradeStudent:(mode:boolean)=>void;
    setSelectedToEditUpgrade:(item:TrailUpgradeStudent)=>void;
    setSelectedToViewUpgrade:(item:TrailUpgradeStudent)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setLimit:(value:number)=>void,
    setWhere:(value:string)=>void,
    setToAddUpgrade:()=>void,
    setUpgradeStudent:(item:TrailUpgradeStudent)=>void
}

export const UseTrailModule = create<FailModule>( (set) => ({
    mode:'ADD',
    modal:false,
    modalUpgrade: false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    total:0,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setModalUpgradeStudent(mode) {
        set({modalUpgrade:mode})
    },
    setToAddUpgrade:()=>{
        set({mode:'ADD',modalUpgrade:true,itemSelectedUpgrade:undefined})
    },
    setSelectedToEditUpgrade:(item)=>{
        set({itemSelectedUpgrade:item,mode:'EDIT',modalUpgrade:true})
    },
    setSelectedToViewUpgrade:(item)=>{
        set({itemSelectedUpgrade:item,mode:'VIEW',modalUpgrade:true})
    },
    resetData:()=>{
        set({itemSelectedUpgrade:undefined,mode:'ADD',modalUpgrade:false})
    },
    setPaginator:(pagination)=>{
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setUpgradeStudent(item) {
        set({itemSelectedUpgrade:item,modalUpgrade:true})
    },
}));