import { FileContentCourse, DataAllFileContentCourseResponse, DataPaginateFileContentCourseResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'

import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseFileContentCourseModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useFileContentCourseAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/file-content-course/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const UseFileContentCourseAllPaginate = async (): Promise<any> => {
  try {
    const { setPaginator, limit, currentPage, where, isActive, typeContent } = await UseFileContentCourseModule.getState()

    let url = `/file-content-course/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`
    if (typeContent) {
      url += `&type=${typeContent}`
    }
    const response: any = await api.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const FileContentCourseAdd = async (data: FileContentCourse): Promise<any> => {
  try {
    const response: any = await api.post(
      '/file-content-course',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {

      showSimpleAlert({message: e.response.data.message, icon: 'error'});

       e.response.data.message && e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const FileContentCourseUpdate = async (data: FileContentCourse): Promise<any> => {
  try {
    const { itemSelected } = UseFileContentCourseModule.getState()
    const response: any = await api.patch(
      `/file-content-course/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const FileContentCourseDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/file-content-course/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
