import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

import { useQueryClient } from '@tanstack/react-query'
import { Leads } from '../../../../admin/leads/interfaces'
import { UseBookingModule } from '../../../store'
import { LeadsAdd } from '../../../../admin/leads/services'
import { LevelSelected } from '../LevelSelected'
import { DetailBooking } from '../DetailsBooking'

export type FormFields = {
 
  email: string,
  isActive:boolean,
  description?: string,
  appointment:string,
  source:string,
  name:string,
  phoneNumber:string,
  prefix:string,
}
const phoneRegExp = /^\d{10}$/;
const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  prefix: Yup.string().required('Prefix number is required'),
  email: Yup.string()
  .email('Wrong email format')
  .min(3, 'Minimum 3 symbols')
  .max(50, 'Maximum 50 symbols' ),
  phoneNumber: Yup.string()
  .matches(phoneRegExp, 'Formato de número de teléfono incorrecto')
  .required('El número de teléfono es obligatorio'),
})

type Props ={
 
}
const BookingForm: React.FC<Props>  = (props:  Props) => {
      let urlString = window.location.href;
  let url = new URL(urlString);
  let params = new URLSearchParams(url.search);
  let ref = params.get('ref');
  let code = params.get('code');

  const queryClient = useQueryClient()
  const {level_id,booking_id, booking, level} = UseBookingModule();
  const [data, setData] = useState<Leads>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        email: "",
        isActive:true,
        description: "",
        appointment:booking_id??'',
        source:"CAMPAIGN",
        name:"",
        phoneNumber:"",
        prefix:"+57"
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
 
          try{
            setLoading(true);
            await LeadsAdd({
                ...formData,
                ref:ref??undefined,
                code:code??undefined,
                email:formData.email.length>0 ?formData.email:undefined,
                phoneNumber:formData.prefix+formData.phoneNumber,
                prefix:undefined
              })
            setLoading(false)
            window.location.replace('/thanks')
            }catch(e){
              setLoading(false)
           
            }
          
      },
    });



  return (
  <div className='row'>
    <div className="col-md-6">
      {level && booking && <DetailBooking  level={level} booking={booking}/>}
    </div>
    <div id='kt_account_profile_details' className='collapse show col-md-6'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-4 row'>

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div> */}
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name *</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className=' col-12 '>
              <div className="row">
                <div className=' col-4'>
                  <label className='form-label mb-3'>País</label>
                  <select
                    name={'prefix'}
                    value={values.prefix}
                    onChange={handleChange}
                    className="form-select" aria-label="Default select example">

                    <option value={'+57'}>+57 COL</option>
                    <option value={'+51'}>+51 PE</option>
                    <option value={'+593'}>+593 Ec</option>
                    <option value={'+58'}>+58 Ven</option>
                    <option value={'+57'}>+57 Col</option>
                    <option value={'+57'}>+57 Col</option>
                  </select>
                </div>
                <div className='mb-10 col-8'>
                  <label className='form-label mb-3'>Teléfono *</label>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='3211472336'
                    name={'phoneNumber'}
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                  {errors.phoneNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.phoneNumber}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Email </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder='dummy@gmail.com'
              name={'email'}
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

{/* 
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {errors.code &&<label style={{
              color:'red'
            }}>Campo inválido</label>}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {errors.description &&<label style={{
              color:'red'
            }}>Campo inválido</label>}
          </div>*/}
        </div> 

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Reservar'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
  )
}

export {BookingForm}
