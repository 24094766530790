import React from 'react';

import './ExerciseTwelve.css';
import { UseGroupWordsModules } from './main/main'
import { GroupWordsItem } from './components/group-words-item';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';

type Props = {
    userMode?: ModeTypeExercise
}
const GroupWords: React.FC<Props> = ({ userMode }) => {
    const { answer } = UseGroupWordsModules();
    
    return (
        <>
            <GroupWordsItem
                userMode={userMode}
                responses={answer}
            />
        </>
    );
};

export { GroupWords };