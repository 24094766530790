// Word.tsx
import React from 'react';
import { useDrag } from 'react-dnd';
import { WordItem } from './types';

interface WordProps {
  word: WordItem;
  isUsed: boolean; // Nuevo
}

const WordWordBox: React.FC<WordProps> = ({ word,isUsed }) => {
    const [{isDragging}, dragRef] = useDrag(() => ({
        type: 'word',
        item: word,
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging(),
        }),
        canDrag: !isUsed, // Desactiva el arrastre si la palabra está utilizada
      }));
    

  return (<>
 
    <div
      ref={dragRef}
      className='champ-light'
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isUsed ? 'default' : 'move', // Cambia el cursor si la palabra está utilizada
        margin: '0 5px',
        padding: '4px 10px 1px 10px',
        backgroundColor:isUsed?'transparent':'#2b22e5', // Fondo transparente
        border: isUsed?'1px solid #3e94ff':'', // Borde blanco
        color: isUsed?'#3e94ff':'white', // Texto blanco
        borderRadius: '30px',
        fontSize:12,
        // textDecoration:isUsed?'line-through':''
      }}
    >
      {word.text}
    </div>
 
    </>);
};

export default WordWordBox;
