import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {CheckWriting, CheckWritingType} from '../../interfaces'
import { CheckWritingAdd, CheckWritingUpdate } from '../../services'
import { UseCheckWritingModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { MultipleSelectionItemIE, MultipleSelectionQuestionIE } from '../../../../../../../courses/exercises/multiple-selection/interfaces'
import { ItemsWritingCheckListItems } from '../../../../writing-check-list/interfaces'
import { useParams } from 'react-router-dom'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'
import Swal from 'sweetalert2'
import { BooleanCell } from '../../../../../../../../components/common/BooleanCell'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  name: string
  isActive: boolean
  description:string
  questions: MultipleSelectionQuestionIE[];
  themes?: ItemsWritingCheckListItems[];
  type: CheckWritingType;
  level: TraditionalLevelEnum;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('Name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const CheckWritingForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const {id} = useParams();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseCheckWritingModule();
  const [question, setQuestion] = useState<MultipleSelectionQuestionIE>({
    title: '',
    order: 1,
    options: []
  });

  const [option, setOption] = useState<MultipleSelectionItemIE>({
    title: '',
    order: 1,
    isTrue: false
  });

  const [theme, setTheme] = useState<ItemsWritingCheckListItems>({
    title: '',
    order: 1,
  });

  const [modeQuestion, setModeQuestion] = React.useState<{question: boolean, option: boolean}>({
    question: true,
    option: false
  });

  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? 'ss' : 'ss',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        questions: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.questions.map(q => JSON.parse(q)) ?? [] : [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.A1 : TraditionalLevelEnum.A1,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? CheckWritingType.CHECK_WRITING_SINGLE : CheckWritingType.CHECK_WRITING_SINGLE,
        themes: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.themes?.map(q => JSON.parse(q)) ?? [] : []
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            const findThemes = formData.type === CheckWritingType.CHECK_WRITING_SINGLE ? 
              formData.themes && formData.themes.length > 0 ? [] : formData.themes : formData.themes;
            await CheckWritingAdd({
              ...formData,
              exercises: id??'',
              themes: findThemes
            });
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['check-writing'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              const findThemes = formData.type === CheckWritingType.CHECK_WRITING_SINGLE ? 
                formData.themes && formData.themes.length > 0 ? [] : formData.themes : formData.themes;
              await  CheckWritingUpdate({
                ...formData,
                exercises: id??'',
                themes: findThemes
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['check-writing'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const addTheme = () => {
    const findRepeatedOrder = 
      values.themes && values.themes.find(x => x.order === theme.order);
    if (findRepeatedOrder)
      return showSimpleAlert({message: 'Orden repetido', icon: 'error'});

    const valueThemes = values.themes ? [...values.themes, {...theme}] : [{...theme}]
    setFieldValue('themes', valueThemes);
    setTheme({
      title: '',
      order: 1
    });
  };

  const addQuestion = () => {
    const findRepeatedOrder = values.questions.find(x => x.order === question.order);
    if (findRepeatedOrder) return showSimpleAlert({message: 'Orden repetido', icon: 'error'}); 
    setFieldValue('questions', [...values.questions,{...question}]);
    setQuestion({
      title: '',
      order: 1,
      options: []
    })
  };

  const addOption = () => {
    const questionToUpdate = values.questions.find(x => x.order === question.order);
    if (!questionToUpdate) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' }); 
    const findRepeatedOrder = questionToUpdate.options.find(x => x.order === option.order);
    if (findRepeatedOrder) 
      return showSimpleAlert({message: 'Orden repetido', icon: 'error'}); 
    const findRepeatedIsTrue = questionToUpdate.options.some(x => x.isTrue);
    if (findRepeatedIsTrue && option.isTrue) 
      return showSimpleAlert({message: 'Solo se puede una respuesta verdadera', icon: 'error'});

    const updatedOptions = [...questionToUpdate.options, option];
    const updatedQuestion = { ...questionToUpdate, options: updatedOptions };

    const updatedQuestions = values.questions.map(question => {
        if (question.order === questionToUpdate.order) {
            return updatedQuestion;
        } else {
            return question;
        }
    });

    setFieldValue('questions', updatedQuestions);
    setOption({
      title: '',
      order: 1,
      isTrue: false
    });
  };

  const deleteQuestion = (order: number) => {
    const findOptions = values.questions.find(x => x.order === order);
    if (!findOptions) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' });

    if (findOptions.options.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Esta columna tiene items, ¿Desea borrarla?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          const newQuestions = values.questions.filter(x => x.order !== order);
          setFieldValue('questions', [...newQuestions]);
          return;
        }
      })
    }
    const newQuestions = values.questions.filter(x => x.order !== order);
    setFieldValue('questions', [...newQuestions]);
    return;
  };

  const deleteOption = (order: number) => {
    const questionToUpdate = values.questions.find(x => x.order === question.order);
    if (!questionToUpdate) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' });

    const newOptions = questionToUpdate.options.filter(x => x.order !== order);
    const updatedQuestion = { ...questionToUpdate, options: newOptions };

    const updatedQuestions = values.questions.map(question => {
        if (question.order === questionToUpdate.order) {
            return updatedQuestion;
        } else {
            return question;
        }
    });

    setFieldValue('questions', updatedQuestions);
  };

  const deleteTheme = (order: number) => {
    const findOptions = values.themes && values.themes.find(x => x.order === order);
    if (!findOptions) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' });

    const newTheme = values.themes && values.themes.filter(x => x.order !== order);
    setFieldValue('themes', newTheme);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> 

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              name='level'
              className='form-select'
              aria-label='Default select example'
              value={values.level}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
              <option value=''></option>
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>*/}
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Type</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
               {Object.keys(CheckWritingType).map((x) => ( 
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>

          <div className="d-flex">
            <hr className="my-auto flex-grow-1"/>
            <div className="px-4">Questions</div>
            <hr className="my-auto flex-grow-1"/>
          </div>

          {modeQuestion.option &&
            <div className='card-footer d-flex justify-content-start py-6 px-9'>
              <button type='button' 
                onClick={() => {
                  setModeQuestion({
                    question: true,
                    option: false
                  });
                  setQuestion({
                    title: '',
                    order: 1,
                    options: []
                  })
                }} 
                className='btn btn-primary' 
                disabled={loading ||mode==='VIEW'}>
                Questions
              </button>
            </div>}
          
            {modeQuestion.question ? <>
            <div className='mb-10 col-6 row'>
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title'}
                value={question.title}
                onChange={(e) => setQuestion({ ...question, title: e.target.value })}
              />
              
              <div className='col-6'>
                <label className='form-label mb-3 fw-bold'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order'}
                  value={question.order}
                  onChange={(e) => setQuestion({ ...question, order: parseInt(e.target.value) })}
                />
              </div>

              <div className='col-6 py-6 px-9'>
                <button type='button' onClick={addQuestion} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.sort((a,b) => a.order - b.order).map((x,i) => (
                    <tr key={i}>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {x.title}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {/* {x.color} */}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> {
                              setModeQuestion({option: true, question: false});
                              setQuestion(x);
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fa-solid fa-plus" style={{ color: 'blue', fontSize: 15 }}></i>
                          </button>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteQuestion(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </> : modeQuestion.option && <>
            <div className='mb-10 col-6 row'>
              {/* <div className='col-12 d-flex'>
                <label className='form-label mb-3 me-3 fw-bold'>is true ?</label>
                <div className="form-check form-switch">
                  <input
                    checked={option.isTrue}
                    onChange={(e) => setOption(prev =>({...prev, isTrue: e.target.checked}))}
                    className="form-check-input" 
                    type="checkbox" 
                    id="flexSwitchCheckDefault" />
                </div>
              </div> */}
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title_option'}
                value={option.title}
                onChange={(e) => setOption({ ...option, title: e.target.value })}
              />
              
              <div className='col-6'>
                <label className='form-label mb-3 fw-bold'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order_option'}
                  value={option.order}
                  onChange={(e) => setOption({ ...option, order: parseInt(e.target.value) })}
                />
              </div>

              <div className='col-6'>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='button' onClick={addOption} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                    {!loading && 'Save'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    {/* <th>Value</th> */}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.find(x => x.order === question.order)
                    ?.options.sort((a,b) => a.order - b.order).map((x, i) => (
                    <tr key={i}>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.title}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className=''>
                        {/* <BooleanCell value={x.isTrue} /> */}
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteOption(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}

          {values.type === CheckWritingType.CHECK_WRITING_DESCRIPTION && 
            <>
            <div className="d-flex">
              <hr className="my-auto flex-grow-1"/>
              <div className="px-4">Themes</div>
              <hr className="my-auto flex-grow-1"/>
            </div>
            <div className='mb-10 col-6 row'>
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title'}
                value={theme.title}
                onChange={(e) => setTheme({ ...theme, title: e.target.value })}
              />
              
              <div className='col-6'>
                <label className='form-label mb-3 fw-bold'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order'}
                  value={theme.order}
                  onChange={(e) => setTheme({ ...theme, order: parseInt(e.target.value) })}
                />
              </div>

              <div className='col-6 py-6 px-9'>
                <button type='button' onClick={addTheme} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.themes && values.themes.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {x.title}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {/* {x.color} */}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteTheme(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </>}

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {CheckWritingForm}
