import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { api } from "../../../../auth/services/auth.services";
import { Answer } from "../interfaces";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseTrueOrFalse {
  percentage: number;
  description?: string;
  isActive: boolean; 
  answer: Answer[];
  exercises: string;
  task: string;
}

export const AddTrueOrFalseResponse = async (data: ResponseTrueOrFalse): Promise<any> => {
    try {
      const response: any = await api.post(
        '/true-or-false-response',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }