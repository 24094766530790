import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {OrderSentence} from '../../interfaces'

import { OrderSentenceAdd, OrderSentenceUpdate } from '../../services'
import { UseOrderSentenceModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { QuestionOrderSentences } from '../../../../../../../courses/exercises/order-sentence/interfaces'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'

export type OrderSentenceFormFields = {
  name: string
  answer: QuestionOrderSentences[];
  isActive: boolean
  description?:string
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const OrderSentenceForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {id} = useParams();
  const {modal,itemSelected, mode,resetData,setSelectedToEdit,setSelectedToView} = UseOrderSentenceModule();
  const [answer, setAnswer] = useState<QuestionOrderSentences>({
    title: '',
    userOrder: 1,
    isTrue: false
  });
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<OrderSentenceFormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        answer: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.answer.map((x) => JSON.parse(x)) ?? [] : [],
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await OrderSentenceAdd({
              ...formData,
              exercises: id??'',
              level: TraditionalLevelEnum.A1
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['order-sentence'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  OrderSentenceUpdate({
                ...formData,
                exercises: id??'',
                level: TraditionalLevelEnum.A1
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['order-sentence'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const addSentence = () => {
    const orderRepeated = values.answer.find((x) => x.userOrder === answer.userOrder);
    if (orderRepeated) 
      return showSimpleAlert({message: 'Orden repetido', icon: 'error'});
    setFieldValue('answer', [...values.answer, answer]);
    setAnswer({
      title: '',
      userOrder: 1,
      isTrue: false,
    });
  }; 

  const deleteSentence = (order: number) => {
    const newValues = values.answer.filter((x) => x.userOrder !== order);
    setFieldValue('answer', newValues);
  };

  const truncateText = (text: string) => {
    if (text.length > 150) {
      return text.substring(0, 150) + '...';
    }
    return text;
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Pregunta</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'title'}
              value={answer.title}
              onChange={(e) => setAnswer({...answer, title: e.target.value})}
            />

            <label className='form-label mb-3 fw-bold'>Valor</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'order'}
              value={answer.userOrder}
              onChange={(e) => setAnswer({...answer, userOrder: parseInt(e.target.value)})}
            />
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addSentence} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Titulo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='scroll-y' style={{maxHeight: '10px'}}>
                {values.answer.length > 0 && [...values.answer].sort((a, b) => a.userOrder - b.userOrder).map((x, i) => (
                  <tr key={i}>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {truncateText(x.title)}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.userOrder}
                            </span>
                          </div>
                        </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteSentence(x.userOrder)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {OrderSentenceForm}
