import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
type Props = {
    value:boolean
}
const BooleanCell: React.FC<Props> = ({value}:Props) => {

return (<>
             
             {
                value ? <i style={{fontSize:22,color:'green'}} className="far fa-thumbs-up"></i>
                      :
                        <i style={{fontSize:22,color:'red'}} className="far fa-thumbs-down"></i>
            }
 </>)
}
export {BooleanCell}
