import {create} from 'zustand';
import { AudioInteraction } from '../../../../admin/exercises/templates-exercise/book/audio-interaction/interfaces';
interface AudioInteractionInterface {
    currentContent?:AudioInteraction;
    setContent:(value: AudioInteraction)=>void;
    // contents: TypeContentAudioInteraction[];
    // stepContent: number;
    // setStepContent:(value: number)=>void;
    // setContents:(value: TypeContentAudioInteraction[])=>void;
}

export const UseAudioInteractionModule = create<AudioInteractionInterface>( (set, get) => ({
  setContent:(value)=>{
    set({currentContent:value});
  }
}));
