import { create } from "zustand";

export interface GroupWordResponse {
    name: string, 
    values: {value: string, isTrue: boolean}[];
}
export interface QuestionIE {
    name: string;
    order: number;
    values: string[];
}
interface InterfaceModuleExercise {
    answer?: GroupWordResponse[];
    checked: boolean;
    questions: QuestionIE[]
    instruction: string;
    inputValues: any[];
    exerciseId?: string;
    setChecked: (value: boolean) => void;
    setInputValuesMo: (value: any[]) => void;
    setQuestions:(
        exerciseId: string, 
        instruction: string,
        value: QuestionIE[])=>void;
    setAnswer:(value: GroupWordResponse[])=>void;
}

export const UseGroupWordsModules = create<InterfaceModuleExercise>((set) => ({
    checked: true,
    instruction: '',
    questions: [],
    inputValues: [],
    setInputValuesMo(value) {
        set({ inputValues: value })
    },

    setChecked: (value) => {
        set({ checked: value })
    },
    setQuestions:(exerciseId, instruction, value) => {
        set({questions: value, exerciseId, instruction});
    },
    setAnswer:(value)=>{
        set({answer: value});
    }
}))
