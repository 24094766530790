import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { MovieWritingResponse } from '../../interfaces'

import { MovieWritingResponseAdd, MovieWritingResponseUpdate } from '../../services'
import { UseMovieWritingResponseModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'

export type FormFields = {
  percentage: number
  teacherObservation: string
}

const entitySchema = Yup.object().shape({
  teacherObservation: Yup.string().required(' '),
  percentage: Yup.number(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const MovieWritingResponseForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseMovieWritingResponseModule();
  const [data, setData] = useState<MovieWritingResponse>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        percentage: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.percentage ?? 0 : 0,
        teacherObservation: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.teacherObservation ?? '' : ''
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            //await MovieWritingResponseAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['movie-writing-response'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await MovieWritingResponseUpdate({
              ...formData,
              //@ts-ignore
              percentage: parseFloat(formData.percentage)

            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['movie-writing-response'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <div className="row p-5">
        <div className="col-6">
          {itemSelected && itemSelected.exercises?.name && <h1 className='champ-bold'>{itemSelected.exercises?.name}</h1>}
          {itemSelected && itemSelected.tasks && itemSelected.tasks.student_setting.user && <h5 className='poppins-light'>{itemSelected.tasks.student_setting.user.fullName ?? ''}</h5>}
          {itemSelected && itemSelected.exercises?.name && <h5 className='poppins-light'>{itemSelected.exercises?.level}</h5>}
          {itemSelected && itemSelected.exercises?.name && <p className='poppins-light'><strong>Instrucción:</strong> {itemSelected.exercises?.description}</p>}

          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Title the movie</th>
                <td>{itemSelected?.title??''}</td>
              </tr>
              <tr>
                <th scope="row">Genre</th>
                <td>{itemSelected?.genre??''}</td>
              </tr>
              <tr>
                <th scope="row">Duration</th>
                <td>{itemSelected?.title??''}</td>
              </tr>
              <tr>
                <th scope="row">Main characters</th>
                <td>{itemSelected?.mainCharter??''}</td>
              </tr>
              <tr>
                <th scope="row">Rating</th>
                <td>{itemSelected?.rating??''}/5</td>
              </tr>
            </tbody>
          </table>
          <hr/>
          <p className='poppins-light' style={{ fontSize: 20 }}>Respuesta</p>
          <p className='poppins-light'>{itemSelected?.description ?? ''}</p>
        </div>
        <div className="col-6">
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9 row'>

              <div className='mb-10 col-12'>
                <label className='form-label mb-3'>% 1/100</label>
                <input
                  autoComplete={''}
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'percentage'}
                  value={values.percentage}
                  onChange={handleChange}
                />
                {touched.percentage && errors.percentage && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.percentage}</span>
                    </div>
                  </div>
                )}
              </div>



              <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Valoraciones del profesor</label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'teacherObservation'}
                  value={values.teacherObservation}
                  onChange={handleChange}
                />
                {touched.teacherObservation && errors.teacherObservation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.teacherObservation}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export { MovieWritingResponseForm }
