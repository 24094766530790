import React, {FC} from 'react'
import { KTIcon,KTSVG,toAbsoluteUrl } from '../../../../_metronic/helpers' 
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationState } from '../hooks/useNotificationRepo';
import { showSimpleAlert } from '../../../commonHooks/alert';
import { NotificationDelete } from '../services';
import moment from 'moment';
import { t } from 'i18next';

export enum NotificationEnum {

  STANDARD= 'STANDARD',
  COMMENT_POST = 'COMMENT_POST',
  COMMENT_PROJECT = 'COMMENT_PROJECT',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  INTERNAL = 'INTERNAL',
  TICKET_CREATED = 'TICKET_CREATED',
  TICKET_RESPONSE = 'TICKET_RESPONSE',
  CALENDAR_EVENT_INVITED = 'CALENDAR_EVENT_INVITED',
  CALENDAR_EVENT_UPDATED = 'CALENDAR_EVENT_UPDATED',
  CALENDAR_EVENT_DELETED = 'CALENDAR_EVENT_DELETED'

};

export interface Notification {
  id?: string;
  name: string;
  description?: string;
  destiny: string;
  isActive: boolean;
  type: NotificationEnum;
  createdAt:string
};

type Props = {
  data: Notification;
};


const NotificationItem: FC<Props> = ({data}) => {

  const queryClient = useQueryClient();

  const deleteItem = async (id:string)=>{
  
        try{
          await NotificationDelete(id);
          showSimpleAlert({message:'Ok!',icon:'success'});
          queryClient.invalidateQueries({ queryKey: ['notification'] });
         }catch(e){
            
         }
      
    }
  
  return (
    // <div className='timeline-item pt-2'>
    //         <div className='timeline-line w-40px'></div>

    //         <div className='timeline-icon symbol symbol-circle symbol-40px'>
    //           <div className='symbol-label bg-light'>
    //             <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
    //           </div>
    //         </div>

    //         <div className='timeline-content mb-10 mt-n2'>
    //           <div className='overflow-auto pe-3'>
    //             <div className='fs-5 fw-bold mb-2'>
    //               {data?.name}{' '}
    //               <a href='#'
    //                 onClick={() => deleteItem(data?.id??'xx')}>
    //                 <KTSVG className='svg-icon-1 text-primary' path='/media/icons/duotune/arrows/arr061.svg' />
    //               </a>
                 
    //             </div>
              
              

    //             <div className='d-flex align-items-center mt-1 fs-6'>
    //               <div className='text-muted me-2 fs-7'>Sent at 4:23 PM by</div>

    //               <div
    //                 className='symbol symbol-circle symbol-25px'
    //                 data-bs-toggle='tooltip'
    //                 data-bs-boundary='window'
    //                 data-bs-placement='top'
    //                 title='Alan Nilson'
    //               >
    //                 <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='img' />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
 
    // </div>
    <div
    className='d-flex flex-stack py-2'
 
  >
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-50px symbol-circle'>
      {/* <img alt='Pic' src={toAbsoluteUrl('/media/svg/icons/earth.svg')} /> */}
         
          <div className='symbol symbol-35px symbol-circle'
   
          >
            <span
              style={{background:'purple', fontSize:20}}
              className={`symbol-label bg-light-  text-white  fw-bold poppins-light`}
            >
              {data.name.charAt(0)}
            </span>
          </div>
       
      </div>

      <div className='ms-5'>
        <a
          href='#'
          style={{color:'#2d30e1'}}
          className='poppins-bold text-hover-primary mb-2'
        >
          {data?.name}
        </a>
        
        <div className='fw-bold poppins-light'
       style={{color:'#2d30e1'}}
       >
        {t(data?.type)}
       </div>
       <div className='  poppins-light'
       style={{color:'#2d30e1'}}
       >
        {moment(data?.createdAt).format('DD/MM hh:mm')}
       </div>
      </div>
    </div>
   
  </div>
  )
}

export {NotificationItem}
