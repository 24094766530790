import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useThemeMode } from '../../../../_metronic/partials';
import { useTranslation } from 'react-i18next';
import { UseModeCourseModule } from '../../../modules/courses/components/front-page/store/main';
import useAuth from '../../../api/main';
import { logoAppSelector } from '../../../hooks/useAppSelectorAssetsMode';
import './accordion.css'

const btnClass = 'btn btn-icon  w-50px h-50px w-md-60px h-md-60px';

function CustomSidebar() {
    const {typeUser} = useAuth();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [color, setColor] = useState('#2d30e1');
    const {mode} = useThemeMode();
    const {modeCourse} = UseModeCourseModule();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => setIsOpen(!isOpen);
  
    useEffect(() => {
        if(mode==='light'){
            setColor('#2d30e1')
        }else{
            setColor('#1f1e2e')
        }
    }, [mode])
    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
        Launch
      </Button> */}

            {!show && 
                <div style={{ position: 'fixed', top: '1px', left: '10px', zIndex: '9999' }} >
                    <div className={clsx(btnClass, 'rounded-circle')} onClick={handleShow}>
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${modeCourse?'menu_icon_white':mode==='light'?'menu_icon_blue':'menu_icon_white'}.svg`)} className='h-35px logo' />
                    </div>
                </div>
            }
            
            <Offcanvas show={show} onHide={handleClose} style={{ background: color }}>
                <Offcanvas.Header closeButton >
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="list-group" style={{borderRadius:1, paddingLeft:30}}>
                        <a style={{background:color,border:0}}href="/" className="list-group-item list-group-item-action mb-5" aria-current="true">
                            <div className="d-flex  justify-content-between">
                                    <img src={toAbsoluteUrl(logoAppSelector('LIGHT'))} className='logo' style={{
                                        maxHeight: '100px',
                                        maxWidth: '230px'
                                    }} />
                            </div>
                        </a>

                        {typeUser === 'STUDENT' && 
                        <>
                            {/* <a style={{height:60,background:color,border:0}}href="/payments" className="list-group-item list-group-item-action " aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/payment.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/payment.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('PAYMENTS')}</p>
                                </div>
                            </a> */}
                            <a style={{height:60,background:color,border:0}}href="/tests" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/tests.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/tests.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('TESTS')}</p>
                                </div>
                            </a>
                            <a style={{height:60,background:color,border:0}}href="/certificate" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/constancy.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/constancy.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('CONSTANCY')}</p>
                                </div>
                            </a>
                            <a style={{height:60,background:color,border:0}}href="/trail-freezing" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/freezing.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/freezing.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('FREEZING')}</p>
                                </div>
                            </a>
                            <a style={{height:60,background:color,border:0}}href="/me-resume" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/schedule-history.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/schedule-history.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('SCHEDULE_HISTORY')}</p>
                                </div>
                            </a>

                            <a style={{height:60,background:color,border:0}} href="/chat" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/chat.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/chat.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('CHAT')}</p>
                                </div>
                            </a>

                            {/* <a style={{height:60,background:color,border:0}}href="/support-ticket" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/support.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/support.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('SUPPORT')}</p>
                                </div>
                            </a> */}

                            {/* <a style={{height:60,background:color,border:0}}href="trail-attendance/" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/schedule-history.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/schedule-history.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('ATTENDANCE')}</p>
                                </div>
                            </a> */}
                            
                        </>}

                        {typeUser === 'TEACHER' &&
                        <>
                            <a style={{height:60,background:color,border:0}} href="/learning-path" className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/tests.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/tests.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('LEARNING_PATH')}</p>
                                </div>
                            </a>
                            <div className="accordion-container ms-4 mb-5">
                            <button
                                onClick={toggleAccordion}
                                className="accordion-button"
                            >
                                <div className="d-flex w-100 align-items-center">
                                {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/constancy.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/constancy.svg')}></img>}
                                <p
                                    style={{ fontSize: 20, color: '#e1e3e9', marginLeft: 15 }}
                                    className="fw-1 poppins-light"
                                >
                                    {t('Qualify Tests')}
                                </p>
                                </div>
                            </button>
                            {isOpen && (
                                <div className="accordion-content">
                                <a
                                    href="/qualify/placement-test"
                                    style={{ height: 60, background: color, border: 0, textDecoration: 'none' }}
                                    className="list-group-item list-group-item-action"
                                >
                                    <p
                                    style={{ fontSize: 20, color: '#e1e3e9', marginLeft: 15 }}
                                    className="me-5 fw-1 poppins-light text-start"
                                    >
                                    {t('Placement test')}
                                    </p>
                                </a>
                                <a
                                    href="/qualify/final-presentation"
                                    style={{ height: 60, background: color, border: 0, textDecoration: 'none' }}
                                    className="list-group-item list-group-item-action"
                                >
                                    <p
                                    style={{ fontSize: 20, color: '#e1e3e9', marginLeft: 15 }}
                                    className="me-5 fw-1 poppins-light text-start"
                                    >
                                    {t('Final presentation')}
                                    </p>
                                </a>
                                <a
                                    href="/qualify/quiz"
                                    style={{ height: 60, background: color, border: 0, textDecoration: 'none' }}
                                    className="list-group-item list-group-item-action"
                                >
                                    <p
                                    style={{ fontSize: 20, color: '#e1e3e9', marginLeft: 15 }}
                                    className="me-5 fw-1 poppins-light text-start"
                                    >
                                    {t('Quizzes')}
                                    </p>
                                </a>
                                </div>
                            )}
                            </div>
                            <a style={{height:60,background:color,border:0}} href="/chat" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/chat.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/chat.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('CHAT')}</p>
                                </div>
                            </a>
                        </>}
                        {typeUser === 'ADMIN' &&
                        <>
                            <a style={{height:60,background:color,border:0}} href="/chat" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/chat.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/chat.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('CHAT')}</p>
                                </div>
                            </a>
                            {/* <a style={{height:60,background:color,border:0}}href="/support-ticket" className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 ">
                                    {mode==='light' ?<img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/color/support.svg')}></img>:
                                    <img style={{
                                        width:30,
                                        height:30
                                    }} src={toAbsoluteUrl('/media/svg/michigan-icons/sidebar/white/support.svg')}></img>}
                                    <p 
                                    style={{fontSize:20, color: '#e1e3e9',marginLeft:15}}
                                    className="fw-1 poppins-light">{t('SUPPORT')}</p>
                                </div>
                            </a> */}
                        </>}
 
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default CustomSidebar;