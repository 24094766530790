import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Campus } from '../../interfaces'

import { CampusAdd, CampusUpdate } from '../../services'
import { UseCampusModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'

export type FormFields = {
  description?: string
  department: string
  city: string
  name: string
  address: string
  phone: string
  email: string
  applyResolution: boolean;
  isActive: boolean
  hour_start_normal_day: string
  hour_finish_normal_day: string
  hour_start_weekend: string
  hour_finish_weekend: string
  logo?: any
  id?: string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const CampusForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseCampusModule();
  const [data, setData] = useState<Campus>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        department: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.department ?? '' : '',
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        address: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.address ?? '' : '',
        phone: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.phone ?? '' : '',
        city: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.city ?? '' : '',
        email: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.email ?? '' : '',
        hour_start_normal_day: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hour_start_normal_day ?? '' : '',
        hour_finish_normal_day: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hour_finish_normal_day ?? '' : '',
        hour_start_weekend: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hour_start_weekend ?? '' : '',
        hour_finish_weekend: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hour_finish_weekend ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        applyResolution: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.applyResolution ?? false : false,
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await CampusAdd({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['campus'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await CampusUpdate({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['campus'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Tiene resolucion ?</label>
            <div className="form-check form-switch">
              <input
                name={'applyResolution'}
                checked={values.applyResolution}
                value={values.applyResolution ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>City</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'city'}
              value={values.city}
              onChange={handleChange}
            />
            {touched.city && errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.city}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Department</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'department'}
              value={values.department}
              onChange={handleChange}
            />
            {touched.department && errors.department && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.department}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Phone</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'phone'}
              value={values.phone}
              onChange={handleChange}
            />
            {touched.phone && errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.phone}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-12'>
            <label className='form-label mb-3'>Email</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'email'}
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Start time of classes in week</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'hour_start_normal_day'}
              value={values.hour_start_normal_day}
              onChange={handleChange}
            />
            {touched.hour_start_normal_day && errors.hour_start_normal_day && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hour_start_normal_day}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>End of school time during the week </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'hour_start_normal_day'}
              value={values.hour_start_normal_day}
              onChange={handleChange}
            />
            {touched.hour_start_normal_day && errors.hour_start_normal_day && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hour_start_normal_day}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Start of school time during the weekend </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'hour_start_normal_day'}
              value={values.hour_start_weekend}
              onChange={handleChange}
            />
            {touched.hour_start_weekend && errors.hour_start_weekend && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hour_start_weekend}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>End of school time during the weekend </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'hour_finish_weekend'}
              value={values.hour_finish_weekend}
              onChange={handleChange}
            />
            {touched.hour_finish_weekend && errors.hour_finish_weekend && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hour_finish_weekend}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-12'>
            <label className='form-label mb-3'>Address</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'address'}
              value={values.address}
              onChange={handleChange}
            />
            {touched.address && errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.address}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { CampusForm }
