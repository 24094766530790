export interface DataPaginateSupportTicketResponse {
    status:  string;
    message: string;
    data:    DataSupportTicket;
}

export interface DataAllSupportTicketResponse {
    status:  string;
    message: string;
    data:    SupportTicket[];
}

export interface DataSupportTicket {
    id?: string;
    description:string;
    name:string;
    files?:string[];
    type?: SupportTicketType;
    status: SupportTicketStatus;
    responseSupportTicket?: SupportTicketResponse[];
    responseUserSupportTicket?: any[];
    priority: SupportTicketPriority;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface SupportTicket  {
    id?: string;
    description:string;
    name:string;
    files?:FilesType[];
    status: SupportTicketStatus;
    type: SupportTicketType;
    responseSupportTicket?: SupportTicketResponse[];
    responseUserSupportTicket?: any[];
    priority: SupportTicketPriority;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: SupportTicket[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum SupportTicketPriority {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW'

}

export enum SupportTicketStatus {
    IN_PROCESS = 'IN_PROCESS',
    CLOSED = 'CLOSED',
    ANSWERED = 'ANSWERED'
}

export interface SupportTicketResponse {
    id?: string;
    name: string;
    description: string;
    isActive: boolean;
    files?: any[];
    ticket: string;
    user?: any;
    createdAt?:string;
    updatedAt?:string;
}

export interface SupportTicketUserResponse {
    id?: string;
    name: string;
    description: string;
    files?: any[];
    ticket: string;
    user?: any;
    createdAt?:string;
    updatedAt?:string;
}

export interface FilesType {
    id?: string;
    location?: string;
    key?: string;
    extension?: string;
}

export enum SupportTicketType {

    CONGRATULATIONS = 'CONGRATULATIONS',
    SUGGESTION = 'SUGGESTION',
    REQUEST = 'REQUEST',
    COMPLAINT = 'COMPLAINT',
    DEMAND = 'DEMAND',
    PETITION = 'PETITION'
}