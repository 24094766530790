import {Route, Routes} from 'react-router-dom'
import { RoadMapStepDetails } from '../components/details'
import { RoadMapStepTableList } from '../components/list'
import { RoadMapStepDashboard } from '../components/dashboard'


const RoadMapStepPage = () => (
  <Routes>
      <Route path='/details' element={<RoadMapStepDetails />} />
      <Route path='/:id' element={<RoadMapStepDashboard />} />
      {/*<Route path='/' element={<RoadMapStepTableList />} />*/}
  </Routes>
)

export {RoadMapStepPage}
