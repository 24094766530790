export const AvatarTranslateEs = {
    FANTASY:"Fantasía",
    SCIENCE_FICTION:"Ciencia Ficción",
    ANIMALS:"Animales",
    PROFESSIONS:"Profesión",
    SPORTS:"Deportes",
    HISTORY_CULTURE:"Historia & Cultura",
    SUPERHEROES_VILLAINS:"Superheroes & Villanos",
    NATURE:"Naturaleza",
    MUSIC_ENTERTAINMENT:"Musica & Entertenimiento",
    LIFESTYLE_FASHION:"Lifestyle Fashion",
    COMICS_ANIME:"Comics & Anime",
    TECHNOLOGY_CYBERPUNK:"Technología",
    GHOSTS_SUPERNATURAL:"Fantasmas",
    VEHICLES_MACHINES:"Coches",
    ABSTRACT_ARTISTIC:"Arte Abastracto",
    ABSENTEEISM: 'Ausentismo'
  }