import { create } from 'zustand';
import { MultipleSelectionQuestionAnswerIE, MultipleSelectionQuestionIE, quiz } from '../interfaces';

interface MultipleSelectionInterface {
  questions: MultipleSelectionQuestionIE[]
  title: string;
  instructions?: string;
  percentage: number
  answer?: MultipleSelectionQuestionAnswerIE[];
  exerciseId?: string;
  resetData: () => void;
  setQuestions:(
    exerciseId: string, 
    instruction: string,
    value: MultipleSelectionQuestionIE[])=>void;
  setAnswer:(value: MultipleSelectionQuestionAnswerIE[])=>void;
}

export const UseMultipleSelectionModule = create<MultipleSelectionInterface>((set) => ({
  status: 'ANSWER',
  title: "Este es un titulo de quiz",
  instructions: "",
  questions: quiz,
  percentage: 0,
  resetData: () => {},
  setQuestions:(exerciseId, instructions, value)=>{
    set({questions: value, exerciseId, instructions});
  },
  setAnswer:(value)=>{
    set({answer: value});
  }
}));