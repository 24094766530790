import {Route, Routes} from 'react-router-dom'
import { ChatComponent } from '../components'


const ChatAbroadPage = () => (
  <Routes>
      <Route path='/' element={<ChatComponent />} />
  </Routes>
)

export {ChatAbroadPage}
