export interface DataPaginateTrailFreezingResponse {
    status:  string;
    message: string;
    data:    DataTrailFreezing;
}

export interface DataAllTrailFreezingResponse {
    status:  string;
    message: string;
    data:    TrailFreezing[];
}

export interface DataTrailFreezing {
    id?: string;
    description:string;
    start:string;
    end:string;
    status: FreezingStatus;
    days?: number;
    student: any;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
    userUpdate?:any
}

export interface TrailFreezing  {
    id?: string;
    description?:string;
    start:string;
    end:string;
    status: FreezingStatus;
    days?: number;
    student: any;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: TrailFreezing[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum FreezingStatus {
    RETROACTIVE = 'RETROACTIVE',
    ANTICIPATED = 'ANTICIPATED'
}