import React from 'react';
import { UseAudioTestModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';
import { AudioTestItemRight } from './components/item-right';

type Props = {
    titleContent: string;
    descriptionContent?: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
};
const AudioTestFullScreenExercise: React.FC<Props> = ({ renderType, titleContent, descriptionContent, colorSecondary }) => {
    let { 
            questions,
            step, 
            setAnswer, 
            status, 
            answers, 
            setStatus,
            startQuiz,
            title,
            instructions,
        } = UseAudioTestModule();
    
    return (
        <>
            {/* {answers.map((x)=>(<p>{x.audio}</p>))} */}
            {renderType && renderType !== 'left' &&
                <>
                    {status !== 'RESUME' && status !== 'TITLE' &&
                    <AudioTestItemRight
                        question={questions[step]}
                        handleSendAnswer={(e) => setAnswer(e)}
                        modeQuiz={status}
                        totalQuestions={questions.length}
                    />}
                    {status === 'RESUME' &&
                        <BasicVocabularyExerciseResume
                            total={questions.length}
                            correct={questions.length}
                            incorrect={0}
                            setStatus={(e) => setStatus(e)}
                            correctAnswer={answers}
                            falseAnswer={[]}
                        />}
                    {status === 'TITLE' && 
                        <IntroBasicVocabulary 
                            title={title}
                            instructions={instructions}
                            startQuiz={()=>startQuiz()}
                        />
                    }
                </>
            }
            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>
        </>
    );
};

export default React.memo(AudioTestFullScreenExercise);