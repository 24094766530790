import {Route, Routes} from 'react-router-dom'
import { MyPaymentsDetails } from '../components/details'
import { MyPaymentsTableList } from '../components/list'


const MyPaymentsPage = () => (
  <Routes>
      <Route path='/details' element={<MyPaymentsDetails />} />
      <Route path='/' element={<MyPaymentsTableList />} />
  </Routes>
)

export {MyPaymentsPage}
