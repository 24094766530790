 import {create} from 'zustand';
import { Answer, QuestionHistoryWriting, quiz,QuestionAndAnswer, questions } from '../interfaces';
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';

export interface MovieWritingData {
  title: string;
  genre: string;
  duration: string;
  mainCharter: string;
  rating: number;
  description: string;
}

interface TitleAndInstruction {
  title: string;
  instruction: string;
}
interface MovieWritingInterface {
  status:StatusQuiz
  questions:MovieWritingData
  title:string;
  instructions:string;
  step:number;
  limit:number;
  trueAnswer:QuestionAndAnswer[]
  falseAnswer:QuestionAndAnswer[]
  list:Answer[]
  movie:MovieWritingData
  total:number
  mode:'IMAGE'|'WORDS'
  setAnswerMovie:(MovieWritingData)=>void;
  setStatus:(StatusQuiz)=>void
  setStep:(number)=>void;
  startQuiz:()=>void;
  setQuestionsMovie:(value:MovieWritingData)=>void;
  setTitle:(value: TitleAndInstruction)=>void;
  resetData:()=>void;
  setDescriptionMovie:(value: string)=>void
}

export const UseMovieWritingModule = create<MovieWritingInterface>( (set) => ({
    status:'ANSWER',
    title:"Este es un titulo de quiz",
    instructions:"esta es una insturccion",
    questions:questions,
    step:0,
    trueAnswer:[],
    falseAnswer:[],
    list:[],
    limit:200,
    total:0,
    mode:'WORDS',
    movie:{
      title:"",
      genre:"",
      duration:"",
      mainCharter:"",
      rating:0,
      description:""
    },
    setAnswerMovie:(value:MovieWritingData)=>{
      set((state) => {
        return {
          movie:{
            title:value.title,
            duration:value.duration,
            mainCharter:value.mainCharter,
            rating:value.rating,
            description:state.movie.description,
            genre:value.genre
          }
      }})
    },
    setStep:(value:number)=>{
 
    },
    startQuiz(){
      set({
        status:'ANSWER'
      })
    },
    setStatus:(value:StatusQuiz)=>{
      if(value==='ANSWER'){
        set({
          trueAnswer:[],
          falseAnswer:[],
          step:0,
          status:'ANSWER'
        })
      }
      if(value==='THROW_ERROR'){
        set({
          step:0,
          status:'THROW_ERROR'
        })
      }
      if(value==='RESUME'){
        set({
          step:0,
          status:'RESUME'
        })
      }
    },
    setQuestionsMovie:(value)=>{
      set({questions: value})
    },
    setTitle:(value)=>{
      set({title: value.title, instructions: value.instruction})
    },
    setDescriptionMovie:(value)=>{
      set((state) => {
        return {
          movie: {
            ...state.movie,
            description: value
          }
        }
      })
    },
    resetData:()=>{
      set({
        status:'TITLE',
        title:"Este es un titulo de quiz",
        instructions:"esta es una insturccion",
        questions:questions,
        step:0,
        movie: {
          title:"",
          genre:"",
          duration:"",
          mainCharter:"",
          rating:0,
          description:""
        },
        trueAnswer:[],
        falseAnswer:[],
        list:[],
        total:0,
      })
    }
}));