import { Book } from "../../../../../admin/book/interfaces";
import { SequenceType, TraditionalLevelEnum } from "../../../../road-map-step/interfaces";

export interface DataPaginateChapterResponse {
    status:  string;
    message: string;
    data:    DataChapter;
}

export interface DataAllChapterResponse {
    status:  string;
    message: string;
    data:    Chapter[];
}

export interface DataChapter {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    approval: ApprovalType;
    sequence: SequenceType;
    resourceType?: ResourceType;
    fileContent?:any;
    level: TraditionalLevelEnum;
    book?: string;
    order: number;
    numberHour: number;
    numberTopics: number;
    resource?: string;
    path?: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface Chapter  {
    id: string;
    description?:string;
    name:string;
    isActive: boolean;
    approval: ApprovalType;
    sequence: SequenceType;
    resourceType?: ResourceType;
    fileContent?:any;
    level: TraditionalLevelEnum;
    book?: Book;
    order: number;
    numberHour: number;
    numberTopics: number;
    resource?: string;
    path?: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: Chapter[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum ApprovalType {
    BY_HOUR = 'BY_HOUR',
    BY_TOPICS = 'BY_TOPICS',
    EXAM = 'EXAM',
    STANDARD = 'STANDARD'
}

export enum ResourceType {
    IMAGE = 'IMAGE',
    PDF = 'PDF',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO'

}

export enum LevelStudent { // Decirle a Leo que si es igual a los otros niveles
    NA = 'NA',
    PRE_A1 = 'PRE_A1',
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1'
}