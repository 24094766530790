 import {create} from 'zustand';
import { Answer, QuestionTrueFalse, quiz,QuestionAndAnswer, questions } from '../interfaces';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';
interface TitleAndInstruction {
  title: string;
  instruction: string;
}
interface ResponseAnswer {
  answer: Answer[],
  percentage:number
}
interface BasicTrueFalseInterface {
  status:StatusQuiz
  questions:QuestionTrueFalse[]
  title:string;
  instructions:string;
  step:number;
  percentage:number;
  answer:Answer[]
  total:number
  mode:'IMAGE'|'WORDS';
  exerciseId?: string;
  setAnswer:(value:ResponseAnswer)=>void;
  setStatus:(value:StatusQuiz)=>void
  startQuiz:()=>void;
  setQuestionsTrueFalse:(
    exerciseId: string, 
    instruction: string,
    value:QuestionTrueFalse[])=>void;
  setTitle:(value: TitleAndInstruction)=>void;
  resetData:()=>void;
}

export const UseTrueFalseModule = create<BasicTrueFalseInterface>( (set) => ({
    status:'TITLE',
    title:"Este es un titulo de quiz",
    instructions:"",
    questions:[],
    step:0,
    percentage:0,
    answer:[],
    total:0,
    mode:'WORDS',
    setAnswer:(value:ResponseAnswer)=>{
      set({
        percentage:value.percentage,
        answer:value.answer,
        status:'THROW_ERROR'
      })
    },
    startQuiz(){
      set({
        status:'ANSWER'
      })
    },
    setStatus:(value:StatusQuiz)=>{
      if(value==='ANSWER'){
        set({
          answer:[],
          step:0,
          status:'ANSWER'
        })
      }
      if(value==='THROW_ERROR'){
        set({
          step:0,
          status:'THROW_ERROR'
        })
      }
      if(value==='RESUME'){
        set({
          step:0,
          status:'RESUME'
        })
      }
    },
    setQuestionsTrueFalse:(exerciseId, instructions, value)=>{
      set({questions: value, status: 'ANSWER', exerciseId, instructions})
    },
    setTitle:(value)=>{
      set({title: value.title, instructions: value.instruction})
    },
    resetData:()=>{
      set({
        // status:'TITLE',
        // title:"Este es un titulo de quiz",
        // instructions:"esta es una insturccion",
        // questions:questions,
        // step:0,
        answer:[],
        percentage: 0,
        // total:0,
      })
    }
}));