import React, { FC } from "react"
import { DataInfoType, DocumentType, Gender, MaritalStatus, PaymentType, PropertyType, ProposalSendStatus, ProposalStatus, ProposalType, Stratum, StudyPlanType } from '../../../interface';
import * as Yup from 'yup'
import { useFormik } from "formik";
import moment from "moment";
import { UseAutoVerification } from "../../../store/main";

export type ProposalFormField = {
	whatsapp?: string;
	firstname: string;
	lastname: string;
	birthDate: string;
	documentNumber: string;
	address: string;
	city: string;
	documentType: DocumentType;
	cellPhone: string;
	de: string;
	gender: Gender;
	maritalStatus: MaritalStatus;
	sons: number;
	birthplace: string;
	neighborhood?: string;
	propertyType?: PropertyType;
	stratum?: Stratum;
	phoneNumber: string;
	email: string;
	tutoring: boolean;
	occupation?: string;
	profession?: string;
	isEmployee?: boolean;
	position?: string;
	isFreelance?: boolean;
	freelanceDedication?: string;
	company?: string;
	companyAddress?: string;
	companyCity?: string;
	companyNeighborhood?: string;
	companyPhoneNumber?: string;
	serviceTime?: number;
	monthlySalary?: number;
	v_b?: string;
	underResponsibility?: boolean;

};

type Props = {
  onSubmit:()=>void;
}

const entitySchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
})

const StepInfoForm: FC<Props> = ({ onSubmit }) => {
  const { dataHolder, setResponseInfo } = UseAutoVerification();

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<ProposalFormField>({
      initialValues: {
        firstname: dataHolder.firstname ?? '',
        lastname: dataHolder.lastname ?? '',
        birthDate: moment(dataHolder.birthDate).format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD'),
        documentNumber: dataHolder.documentNumber ?? "",
        address: dataHolder.address ?? "",
        city: dataHolder.city ?? "",
        documentType: dataHolder.documentType ?? DocumentType.Id,
        cellPhone: dataHolder.cellPhone ?? "",
        de: dataHolder.de ?? "",
        gender: dataHolder.gender ?? Gender.Male,
        maritalStatus: dataHolder.maritalStatus ?? MaritalStatus.Single,
        sons: dataHolder.sons ?? 0,
        birthplace: dataHolder.birthplace ?? "",
        phoneNumber: dataHolder.phoneNumber ?? "",
        email: dataHolder.email ?? "",
        tutoring: dataHolder.tutoring ?? false,
        whatsapp: dataHolder.whatsapp ?? ""
      },
      validationSchema: entitySchema,

      onSubmit: (formData) => {
        const response = validationData(formData);
        setResponseInfo(response??[])
        console.log(response)
        onSubmit();
      },
    });

  const validationData = (valuesForm: ProposalFormField) => {
    if (!dataHolder) return;

    let response: DataInfoType[] = [];

    for(const key in valuesForm){
      if (dataHolder.hasOwnProperty(key)) {
        if (dataHolder[key] !== valuesForm[key]) {
          response.push({
            field: key,
            oldValue: dataHolder[key],
            newValue: valuesForm[key]
          });
        }
      }
    }

    return response;
  };

  return (
    <>
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className="row">
          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Nombre</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'firstname'}
              value={values.firstname}
              onChange={handleChange}
            />
            {touched.firstname && errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Apellidos</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'lastname'}
              value={values.lastname}
              onChange={handleChange}
            />
            {touched.lastname && errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.lastname}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label 
              className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Fecha de nacimiento</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'birthDate'}
              value={values.birthDate}
              onChange={handleChange}
            />
            {touched.birthDate && errors.birthDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.birthDate}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-2 col-6'>
            <label 
            className='stepper-desc fw-semibold poppins-light pb-5'
            style={{color:'#2d30e1'}}
            >Tipo de Documento</label>
            <select
              name='documentType'
              className='form-select'
              aria-label='Default select example'
              value={values.documentType}
              onChange={handleChange}
            >
              {Object.keys(DocumentType).map((x) => (
                <option key={x} value={x}>{x}</option>
              ))}
            </select>
          </div>

          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >No. Documento</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'documentNumber'}
              value={values.documentNumber}
              onChange={handleChange}
            />
            {touched.documentNumber && errors.documentNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.documentNumber}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >De</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'de'}
              value={values.de}
              onChange={handleChange}
            />
            {touched.de && errors.de && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.de}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-2 col-6'>
            <label 
            className='stepper-desc fw-semibold poppins-light pb-5'
            style={{color:'#2d30e1'}}
            >Género</label>
            <select
              name='gender'
              className='form-select'
              aria-label='Default select example'
              value={values.gender}
              onChange={handleChange}
            >
              {Object.keys(Gender).map((x) => (
                <option key={x} value={x}>{x}</option>
              ))}
            </select>
          </div>
          <div className='mb-2 col-6'>
            <label 
            className='stepper-desc fw-semibold poppins-light pb-5'
            style={{color:'#2d30e1'}}
            >Tipo de Documento</label>
            <select
              name='maritalStatus'
              className='form-select'
              aria-label='Default select example'
              value={values.maritalStatus}
              onChange={handleChange}
            >
              {Object.keys(MaritalStatus).map((x) => (
                <option key={x} value={x}>{x}</option>
              ))}
            </select>
          </div>

          <div className='mb-2 col-6'>
            <label 
            className='stepper-desc fw-semibold poppins-light pb-5'
            style={{color:'#2d30e1'}}
            >Hijos</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'sons'}
              value={values.sons}
              onChange={handleChange}
            />
            {touched.sons && errors.sons && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.sons}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Lugar de nacimiento</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'birthplace'}
              value={values.birthplace}
              onChange={handleChange}
            />
            {touched.birthplace && errors.birthplace && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.birthplace}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Ciudad</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'city'}
              value={values.city}
              onChange={handleChange}
            />
            {touched.city && errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.city}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Dirección</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'address'}
              value={values.address}
              onChange={handleChange}
            />
            {touched.address && errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.address}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >WhatsApp</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'whatsapp'}
              value={values.whatsapp}
              onChange={handleChange}
            />
            {touched.whatsapp && errors.whatsapp && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.whatsapp}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-2 col-6'>
            <label className='form-label mb-3'>Teléfono</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'cellPhone'}
              value={values.cellPhone}
              onChange={handleChange}
            />
            {touched.cellPhone && errors.cellPhone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.cellPhone}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-2 col-6'>
            <label className='form-label mb-3'>Teléfono Celular</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'phoneNumber'}
              value={values.phoneNumber}
              onChange={handleChange}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.phoneNumber}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-6 mb-2'>
            <label className='stepper-desc fw-semibold poppins-light pb-5'
              style={{color:'#2d30e1'}}
            >Correo</label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'email'}
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            {'Save'}
          </button>
        </div>

        </div>
      </form>
    </div>
    </>

  )
}

export { StepInfoForm }