import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useThemeMode } from "../../../../../../_metronic/partials";
import { UseMyRoadmapModule } from "../../../../profile/components/my-road-step/store";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { UseRoadMapOne } from "../../services";
import { RoadMap } from "../../../../profile/components/my-road-step/interfaces";

type Props = {};

const RoadMapDetails: React.FC<Props> = () => {
  const {mode} = useThemeMode();
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const [roadMapData, setRoadMapData] = React.useState<RoadMap>();

  React.useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await UseRoadMapOne(id ?? '');
              setRoadMapData(response);
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchData();
  }, [id]);

  // React.useEffect(() => {
  //   if (settings && settings.id) {
  //     const findHoursExperience = async () => {
  //       try {
  //         const response = await FindHoursExperienceByStudent(settings.id??'xxx');
  //         let updateResponse = response.map((x) => ({...x, hoursRemaining: Math.max(0, x.hoursToSee - x.hours)}))
  //         setExperienceData(updateResponse);
  //       } catch (e) {
  //         showSimpleAlert({ message: 'Error al encontrar las experecias del estudiante!', icon: 'error' })
  //         throw 'Error';
  //       }
  //     };
  //     findHoursExperience();
  //     setCurrentStep(settings?.roadMap?.step?.find((x) => x.isCurrentStep)?.order??0);
  //   };
  // }, [settings]);

    return (<>
    {/* <CardHeaderModule
        title={t('ROADMAP')} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/back_blue':'white/back_white'}.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/my_courses_blue':'white/roadmap_white'}.svg`}
        background={'#E6E6FA'}
        colorTitle={'#2d30e1'}
    /> */}

    <div className="card cards-general-radius-payments" style={{backgroundColor: mode==='light'?'#E6E6FA':''}}>
      <div className="card-body pt-0" style={{color:mode==='light'?'#2d30e1':'#FFFFFF'}}>
        <div className='d-none d-md-flex d-flex row mt-8 mb-0'>
          <div className='col-md-4 d-flex flex-column'>
            <h3 className='champ-light michigan-title-payments-top' 
              style={{color:mode==='light'?'#2d30e1':'#FFFFFF'}}>Michigan Master</h3>
            <div className='mb-0'>
              <div className='icon-left-payments'>
                <img
                  src={toAbsoluteUrl('/media/svg/michigan-icons/header/blue/back_blue.svg')}
                  alt=''
                  onClick={() => navigate('/dashboard')}
                  style={{
                    cursor: 'pointer',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                />
              </div>
            </div>
            <span className='champ-light title-card-payments'>{t('ROADMAP')}</span>
          </div>

          <div className='col-md-6 d-flex justify-content-center p-0'>
            <div className='row'>
              <div className='col-6 d-flex justify-content-end'>
                <div className="rounded-circle" style={{backgroundColor: '#ff336b',  width:'18px', height: '18px'}}></div>
              </div>
              <div className='col-6'>
                <span className='poppins-light fs-5'>Explorers</span>
              </div>
              <div className='col-6 d-flex justify-content-end'>
                <div className="rounded-circle" style={{backgroundColor: '#a700ba',  width:'18px', height: '18px'}}></div>
              </div>
              <div className='col-6'>
                <span className='poppins-light fs-5'>Prismatic</span>
              </div>
              <div className='col-6 d-flex justify-content-end'>
                <div className="rounded-circle" style={{backgroundColor: '#3e94ff',  width:'18px', height: '18px'}}></div>
              </div>
              <div className='col-6'>
                <span className='poppins-light fs-5'>Abroad</span>
              </div>
              <div className='col-6 d-flex justify-content-end'>
                <div className="rounded-circle" style={{backgroundColor: '#00edd5',  width:'18px', height: '18px'}}></div>
              </div>
              <div className='col-6'>
                <span className='poppins-light fs-5'>Preparacionn examen internacional</span>
              </div>
            </div>
          </div>

          <div className='col-md-2 d-flex justify-content-end'>
              <div className='icon-right-payments'>
                <img src={toAbsoluteUrl(`/media/svg/michigan-icons/header/${mode==='light'?'blue/my_courses_blue':'white/roadmap_white'}.svg`)} alt='' />
              </div>
          </div>
        </div>
      </div>
    </div>

    <div className='card mt-3' style={{ borderRadius: 30 }}>
        <div className='card-body'>
          <div className='d-flex justify-content-center align-items-center'>
            {roadMapData && roadMapData.step?.map((x, i) => {
           
              return(
              <div className='d-flex flex-column' key={i} style={{marginRight:'-1px', marginLeft: '-1px'}}>
                <div className={`mt-4 border-bottom-0`} style={{
                  borderRadius: '75px 75px 0 0',
                  border: mode==='light'?i%2===0 ?'2px solid #2d30e1':'' :'',
                  paddingLeft: i % 2 === 0 ?'':'2px',
                  paddingRight: i % 2 === 0 ?'':'2px'
                }}>
                  <div className='bg-primary mt-4 ms-4 me-4' style={{
                      height: '50px',
                      // backgroundColor: '#6D435A',
                      borderRadius: '75px 75px 0 0',
                      position: 'relative',
                      overflow: 'hidden', 
                  }}>
                    <div className='bg-primary' style={{
                        content: '',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        height: '150px',
                        backgroundColor: '#6D435A',
                        borderRadius: '0 0 75px 75px',
                    }}></div>
                  </div>
                </div>
                <div className="border-bottom-0 border-top-0 mt-0" style={{border:mode==='light'?'2px solid #2d30e1':''}}>
                  <div className="bg-primary ms-4 me-4">
                    <div className='pt-0 pb-20 p-8 d-flex flex-column justify-content-center align-items-center'>
                      <span className='fs-1 mb-8 symbol symbol-40px'>
                          <img src={toAbsoluteUrl(`/media/svg/michigan-icons/${'elements/ok_white'}.svg`)} alt="" />
                      </span>
                      <span className="fs-4 champ-light">
                        100%
                      </span>
                    </div>
                  </div>
                </div>
                <div className={` border-bottom-0 border-top-0`} 
                  style={{
                    border:mode==='light'?i % 2 === 0 ? '':'2px solid #2d30e1' :'',
                    paddingLeft:i % 2 === 0 ?'2px':'',
                    paddingRight:i % 2 === 0 ?'2px':''
                  }}
                >
                  <div className={`bg-primary ms-4 me-4 mb-4`} style={{
                      height: '50px',
                      // backgroundColor: '#6D435A',
                      borderRadius: '0 0 75px 75px',
                      position: 'relative',
                      overflow: 'hidden', 
                  }}>
                    <div className='bg-primary' style={{
                        content: '',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        height: '150px',
                        backgroundColor: '#6D435A',
                        borderRadius: '75px 75px 0 0',
                    }}></div>
                  </div>
                </div>

                <div className={`border-top-0 mt-0`} 
                style={{
                  borderRadius: '0 0 75px 75px',
                  border:mode==='light'?i % 2 === 0 ? '':'2px solid #2d30e1' :''
                }}>
                  <div className="ms-4 me-4 mb-4" style={{color:'#2d30e1'}}>
                      <span className='champ-bold d-flex flex-column justify-content-center align-items-center'>
                        {x.name} 
                      <span className='champ-light'>{t(x.level)}</span>
                      </span>
                      <span className='champ-light d-flex flex-column justify-content-center align-items-center fs-9'>
                        Lessons: {x.lessons}
                        <span className='champ-light'>Hours: {x.hours}</span>
                      </span>
                  </div>
                </div>
                {/* <div className='border border-dark border-bottom-0 border-top-0 mt-0' style={{ flex: '0 0 100%' }}>
                  <div className='bg-primary ms-4 me-4'>
                    <div className='p-5 d-flex flex-column justify-content-center align-items-center'>
                      <span className='fs-1 mb-8 champ-light'>100%</span>
                      <span className='fs-1 mb-8'>
                        <i className="fa-regular fa-circle-check text-white" style={{ fontSize: 35 }}></i>
                      </span>
                      <span className='champ-bold d-flex flex-column justify-content-center align-items-center' style={{borderBottom: '1px solid #2d30e1'}}>
                        Explorers
                        <span className='champ-light'>PRE A1-1</span>
                      </span>
                      <span className='champ-light d-flex flex-column justify-content-center align-items-center fs-9'>
                        Lessons: 40
                        <span className='champ-light'>Hours: 30</span>
                      </span>
                      </div>
                    </div>
                </div> */}
                {/* <div className={`border ${i % 2 === 0 ? 'border-white':'border-dark'} border-top-0`} style={{borderRadius: '0 0 75px 75px'}}>
                  <div className={`bg-primary ms-4 me-4 mb-4`} style={{
                      height: '50px',
                      // backgroundColor: '#6D435A',
                      borderRadius: '0 0 75px 75px',
                      position: 'relative',
                      overflow: 'hidden', 
                  }}>
                    <div className='bg-primary' style={{
                        content: '',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        width: '100%',
                        height: '150px',
                        backgroundColor: '#6D435A',
                        borderRadius: '75px 75px 0 0',
                    }}></div>
                  </div>
                </div> */}
              </div>
            )})}
            
          </div>
        </div>
      </div>
    </>)
};

export {RoadMapDetails}