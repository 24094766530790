import {Route, Routes} from 'react-router-dom'
import { ThanksCard } from '../components/wizard/Thanks'
import { ThanksLayout } from '../layout/thaks-layout'

const ThanksPage = () => (
  <Routes>
    <Route element={<ThanksLayout />}>
      <Route index element={<ThanksCard />} />
    </Route>
  </Routes>
)

export {ThanksPage}
