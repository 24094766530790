interface Quiz {
    theme:string
    title:string
    questions:QuestionVerificationTest[]
    instructions:string
}
export interface QuestionVerificationTest {
    id?: string
    description: string
    name: string
    isActive: boolean
    order: number
    time: number
    createdAt?: string
    updatedAt?: string
    type: string
}

export interface QuestionAndAnswer {
    answer :string
    question:string
    number:number
    type:string
}

export interface Answer {
    answer :string
    question:string
    number:number
    type:string
}

export interface Resume {
    step:number
    trueAnswer:Answer[]
    falseAnswer:Answer[]
    list:Answer[], 
    total:number
}


export const questions: QuestionVerificationTest[] = [
    {
        "id": "b0c90974-b14d-464c-a430-63fe1892321c",
        "description": "esta una descripcion",
        "name": "¿Estas de acuerdo con los terminos y condiciones?",
        "isActive": true,
        "order": 1,
        "time": 30,
        "createdAt": "2024-05-27T23:35:22.990Z",
        "updatedAt": "2024-05-28T01:52:49.006Z",
        "type": "YES_OR_NOT"
    },
    {
        "id": "3aff28d3-d315-4f28-812d-d5d66594e44f",
        "description": "",
        "name": "¿Esta consiente de la inversión requerida en este curso?",
        "isActive": true,
        "order": 2,
        "time": 33,
        "createdAt": "2024-05-28T00:24:18.408Z",
        "updatedAt": "2024-05-28T00:24:18.408Z",
        "type": "AUDIO"
    },
    {
        "id": "2e1e82a0-f0e4-4b64-8c66-18cad0a1204d",
        "description": "",
        "name": "Describe tus expectativas con el programa",
        "isActive": true,
        "order": 3,
        "time": 0,
        "createdAt": "2024-05-28T01:51:26.388Z",
        "updatedAt": "2024-05-28T01:51:26.388Z",
        "type": "TEXT"
    },
    {
        "id": "1890d921-fd21-4b99-b2f1-d18aa06027de",
        "description": "",
        "name": "Necesitamos una foto tuya tipo carnet",
        "isActive": true,
        "order": 4,
        "time": 0,
        "createdAt": "2024-05-28T01:51:47.791Z",
        "updatedAt": "2024-05-28T01:51:47.791Z",
        "type": "IMAGE"
    },
    {
        "id": "37d11509-cdec-408d-a4e4-1e7298ba81de",
        "description": "",
        "name": "Importa el archivo con tu acreditacion al siguiente archivo",
        "isActive": true,
        "order": 5,
        "time": 0,
        "createdAt": "2024-05-28T01:52:18.467Z",
        "updatedAt": "2024-05-28T01:52:18.467Z",
        "type": "FILE"
    }
];

export const quiz :Quiz={
    theme:"Speak Time",
    title:"Titulo del quiz que va a empezar",
    instructions:"Con ayuda del recurso vamos hacerte preguntas que debes responde con audio ",
    questions:questions
}