import { TraditionalLevelEnum } from "../../../abroad/road-map-step/interfaces";

export interface DataPaginateExercisesResponse {
    status:  string;
    message: string;
    data:    DataExercises;
}

export interface DataAllExercisesResponse {
    status:  string;
    message: string;
    data:    Exercises[];
}

export interface DataExercises {
    id?: string;
    description?:string;
    name:string;
    code?:string;
    feedBackImmediate: boolean;
    type:string
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Exercises  {
    id?: string;
    description?:string;
    name:string;
    code?:string;
    feedBackImmediate: boolean;
    fileContent?: any;
    type:string;
    level?: TraditionalLevelEnum;
    variant: VariantsExercisesType;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: Exercises[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface TeacherResponseExercise {
    teacherObservation: string;
    percentage: number;
}
export enum ExercisesType {
    SIMPLE_SELECTION = 'SIMPLE_SELECTION',
    SIMPLE_SELECTION_COMPLETION = 'SIMPLE_SELECTION_COMPLETION',
    MULTIPLE_SELECTION = 'MULTIPLE_SELECTION',
    SPEAKING = 'SPEAKING',
    LISTENING = 'LISTENING',
    DRAG_DROP = 'DRAG_DROP',
    TRUE_FALSE = 'TRUE_FALSE',
    HISTORY_WRITING = 'HISTORY_WRITING',
    MOVIE_WRITING = 'MOVIE_WRITING',
    ORDER_SENTENCES = 'ORDER_SENTENCES',
    WRITING_CHECK_LIST = 'WRITING_CHECK_LIST',
    HIGHLIGHT_WORD = 'HIGHLIGHT_WORD',
    CORRECT_SENTENCES= 'CORRECT_SENTENCES',
    TRUE_OPTIONS= 'TRUE_OPTIONS',
    AUDIO_TEST= 'AUDIO_TEST',
    CROSSWORD= 'CROSSWORD',
    TRUE_WORDS= 'TRUE_WORDS',
    AUDIO_INTERACTION = 'AUDIO_INTERACTION',
    GROUP_WORDS='GROUP_WORDS',
    COORDINATES='COORDINATES',
    SPEAK_OUT='SPEAK_OUT',
    CHECK_WRITING='CHECK_WRITING',
    BOOSTER='BOOSTER',
    COMPLETE_SENTENCES='COMPLETE_SENTENCES'
}

export enum VariantsExercisesType {
    // SINGLE_SCREEN = 'SINGLE_SCREEN',
    FULL_SCREEN = 'FULL_SCREEN',
    BOOK = 'BOOK'
}