/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import useAuth from '../../../../app/api/main';

const DrawerMessenger: FC = () => {
  const {id, email} = useAuth();
  const [state, setState] = useState('exit');
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(email){
        setState(email); 
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []); 
  const CLOVER_CHAT = process.env.REACT_APP_SUPABASE_URL;
  const url = `${CLOVER_CHAT}?u=${state}&p=${id}`;
  return(
  <div
    id='kt_drawer_chat'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='chat'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '390px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_chat_toggle'
    data-kt-drawer-close='#kt_drawer_chat_close'
  >
    <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
    {state && id && 
      <iframe
      id="myIframe"
      src={url} style={{ marginTop: 16, width: '390px', height: '100%' }}></iframe>
    }
    </div>
  </div>)
}

export {DrawerMessenger}
