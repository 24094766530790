import React, { useEffect, useState } from 'react';
import { UseExerciseTimerModule } from "../store/main";
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';

type Props = {
  onNext: (v:any) => any;
  exerciseId: string | undefined;
}

const ButtonIntroEvaluationItem: React.FC<Props> = ({ onNext, exerciseId }) => {

  const { timeLeft } = UseExerciseTimerModule();
  const currentTimer = timeLeft.find(element => element.exerciseId === exerciseId);  
  const [NotAllowed, setNotAllowed] = useState<boolean>(true)
  useEffect(()=>{
    if(currentTimer){
      setNotAllowed((currentTimer.time) > 0)
    }
  },[timeLeft])
  return (
    <div
    className='arrow-right-basic-vocabulary cursor-pointer'
    style={{ position: 'absolute', cursor:`${NotAllowed? 'not-NotAllowed' : 'pointer'}`}}
    >
    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)} alt='' />
    <button
        type='button'
        disabled = {NotAllowed}
        onClick={()=>onNext(true)}
        title={NotAllowed?"Vea todo el contenido" : "Next"}
        className='poppins-light text-cursor-basic-vocabulary-right align-items-end' 
        style={{ position: 'absolute', border: 'none', backgroundColor: 'transparent', color: `${NotAllowed? "#c1b7b7" : "#6090cb"}`, cursor:`${NotAllowed? 'not-NotAllowed' : 'pointer'}`, minWidth: '130px' }}
    >
        {NotAllowed?
        <span>
            {`Wait ${currentTimer?.time ?? ""}s`}
        </span>
        :
        <span>
            next
        </span>}
    </button>

</div>
  );
};

export { ButtonIntroEvaluationItem };
