import { SequenceType } from "../../../../road-map-step/interfaces";
import { ResourceType } from "../../chapter/interfaces";

export interface DataPaginateTopicsResponse {
    status:  string;
    message: string;
    data:    DataTopics;
}

export interface DataAllTopicsResponse {
    status:  string;
    message: string;
    data:    Topics[];
}

export interface DataTopics {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    approval: ApprovalTypeTopic;
    sequence: SequenceType;
    resourceType?: ResourceType;
    order: number;
    resource?: string;
    lesson: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface Topics  {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    approval: ApprovalTypeTopic;
    sequence: SequenceType;
    fileContent?: any;
    resourceType?: ResourceType;
    order: number;
    resource?: string;
    lesson: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: Topics[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum ApprovalTypeTopic {
    EXAM = 'EXAM',
    STANDARD = 'STANDARD'
}