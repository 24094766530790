import React from 'react';
import { UseCoordinatesEleven } from './main/main';
import './ExerciseEleven.css'
import { CoordinatesItem } from './components/coordiantes-item';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';


type Props = {
    userMode?:ModeTypeExercise
}
const Coordinates: React.FC<Props> = ({ userMode }) => {
    
    const { questions, answer, instruction } = UseCoordinatesEleven();
    return (
        <>
            <CoordinatesItem
                userMode={userMode}
                instruction={instruction}
                question={questions}
                responses={answer}
            />
        </>
    );
};

export { Coordinates };