import React from "react";
import { EvaluationItem } from "../../admin/evaluation/interfaces";
import { CheckExtension } from "./utils";
import LeftCardContent from "../../../components/common/left-card-content/indext";
import { TypeContentCourse } from "../../admin/file-folder/interfaces";
import { MediaContentHtml } from "../components/media/html";
import MediaContentAudio from "../components/media/audio";
import MediaContentVideo from "../components/media/video";
import { UseModeCourseModule } from "../components/front-page/store/main";
import { ExercisesType } from "../../admin/exercises/interfaces";

type Props = {
    exerciseType: ExercisesType;
    children?: React.ReactNode;
    isDragButton?: boolean
};

const RenderingContentFullScreen: React.FC<Props> = ({
  exerciseType,
  isDragButton, 
  children}) => {
  const { currentFile } = UseModeCourseModule.getState();

  const file = React.useMemo(() => {
    return currentFile;
  }, [currentFile]);

  return (
    <LeftCardContent 
      coverFile={file?.cover?.location}
      coverExtension={file?.cover?.extension}
      contentFile={file?.content?.location}
      iFrame={file?.iFrame}
      contentExtension={file?.content?.extension}
    >
      {typeof isDragButton === 'undefined' ? (exerciseType !== ExercisesType.HISTORY_WRITING && exerciseType !== ExercisesType.MOVIE_WRITING) ? file && (file?.content || file?.cover || file?.iFrame) ? 
        file?.type === TypeContentCourse.HTML ?
          <MediaContentHtml
            urlHtml={file?.content?.location}
          /> :
        file?.type === TypeContentCourse.AUDIO ?
          <MediaContentAudio
            urlAudio={file?.content?.location}
          /> :
        file?.type === TypeContentCourse.VIDEO &&
          <MediaContentVideo 
            heightVideo='370'
            urlVideo={file?.iFrame ??
              'https://player.vdocipher.com/v2/?otp=20160313versUSE323rM9exuhRUfuytV4Uo1mmWEDN3YKba8oNFIkNGAGcU87RPo&playbackInfo=eyJ2aWRlb0lkIjoiZDRjYTljNWE4YmE3NDc0OGI1Y2YwOGUyMTVhZWRjM2UifQ=='}
          />
      : 
        <>
          {children}
        </> : 
        <>
          {children}
        </>
      : file && (file?.content || file?.cover || file?.iFrame) &&
        <>
        {children}
        {!isDragButton && file?.type === TypeContentCourse.HTML ?
          <MediaContentHtml
            urlHtml={file?.content?.location}
          /> :
        !isDragButton && file?.type === TypeContentCourse.AUDIO ?
          <MediaContentAudio
            urlAudio={file?.content?.location}
          /> :
        !isDragButton && file?.type === TypeContentCourse.VIDEO &&
          <MediaContentVideo 
            heightVideo='370'
            urlVideo={file?.iFrame ??
              'https://player.vdocipher.com/v2/?otp=20160313versUSE323rM9exuhRUfuytV4Uo1mmWEDN3YKba8oNFIkNGAGcU87RPo&playbackInfo=eyJ2aWRlb0lkIjoiZDRjYTljNWE4YmE3NDc0OGI1Y2YwOGUyMTVhZWRjM2UifQ=='}
          />}</>}
    </LeftCardContent>
  );
};

// const areEqual = (prevProps: Props, nextProps: Props) => {
//   return prevProps?.currentItem?.id === nextProps?.currentItem?.id;
// };

export default React.memo(RenderingContentFullScreen);