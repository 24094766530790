import {Route, Routes} from 'react-router-dom'
import { MeReportDetails } from '../components/details'
import { MeReportTableList } from '../components/list'


const MeReportPage = () => (
  <Routes>
      <Route path='/details' element={<MeReportDetails />} />
      <Route path='/' element={<MeReportTableList />} />
  </Routes>
)

export {MeReportPage}
