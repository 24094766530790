import { Route, Routes } from 'react-router-dom'
import useAuth from '../../../api/main';
import { AutoVerification } from '../components';

const AutoVerificationPage = () => (
    <Routes>
        <Route path='/:id' element={<AutoVerification />} />
    </Routes>
)


export { AutoVerificationPage };