import React, { useState, useRef } from 'react';
import './index.css';

interface ZoomableImageProps {
  imageUrl: string;
  backGroundColor: string;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({ imageUrl, backGroundColor }) => {
  const [showModal, setShowModal] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setIsZoomed(false); 
  };

  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (imageRef.current) {
      const { offsetX, offsetY } = event.nativeEvent;
      const { naturalWidth, naturalHeight } = imageRef.current;
  
      const clickXPercentage = offsetX / imageRef.current.offsetWidth;
      const clickYPercentage = offsetY / imageRef.current.offsetHeight;
  
      const zoomedX = clickXPercentage * naturalWidth;
      const zoomedY = clickYPercentage * naturalHeight;
  
      setIsZoomed(!isZoomed);
      imageRef.current.style.transformOrigin = `${zoomedX}px ${zoomedY}px`;
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick}
        className='btn py-1'  
        style={{ backgroundColor: backGroundColor, color: 'white' }}
      >
        Ver Imagen
    </button>

      {showModal && (
        <div className="modal-image">
          <div className={`modal-content-image ${isZoomed ? 'zoomed' : ''}`}>
            <span className="close-image" onClick={handleModalClose}>
              &times;
            </span>
            <img ref={imageRef} src={imageUrl} alt="Imagen" onClick={handleImageClick} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoomableImage;
