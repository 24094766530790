import React, { useEffect, useState } from 'react';

import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { WritingCheckQuestions, WritingCheckQuestionsAnswer } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { AddWritingCheckListResponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseWritingCheckListModule } from '../store';
import { UseBookContainerModule } from '../../../../book/store/main';

type Props = {
    userMode?: ModeTypeExercise
    instruction: string
    question: WritingCheckQuestions[]
    responses?: WritingCheckQuestionsAnswer[];
    mode: 'WRITING_CHECK_LIST' | 'TIME_TO_SPEAK' | 'DID_YOU_UNDERSTAND';
};

const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth:'600px',
    minWidth: '600px',
    minHeight: '400px', 
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid',
    padding: '27px 20px 20px 20px'
};

const WritingCheckListItem: React.FC<Props> = ({
    userMode,
    question,
    instruction,
    responses,
    mode,
}: Props) => {
    const {
        lessonData,
        stepBookItem,
        currentTaskId,
        currentItemBook,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer
    } = UseBookStudentModule();

    const { exerciseId } = UseWritingCheckListModule();
    const [allAnswered, setAllAnswered] = useState<boolean>(false);
    const [selected, setSelected] = useState<number | null>(null);
    const [answers, setAnswers] = useState<WritingCheckQuestionsAnswer[]>([]);

    const tryAgain = () => {
        setAnswers([])
        setAllAnswered(false)

        setAnswers(question.map((x) => ({
            title: x.title,
            text: '',
            order: x.order
        })));
    }
    useEffect(() => {
        if (responses && responses.length > 0) {
            setAnswers(responses);
            setSelected(responses.length > 0 ? 0 : null);
        } else {
            setAnswers(question.map((x) => ({
                title: x.title,
                text: '',
                order: x.order
            })));
            setSelected(question.length > 0 ? 0 : null);
        }

    }, [question, responses]);

    useEffect(() => {
        const allAnsweredCheck = answers.every((x) => x.text !== '');
        setAllAnswered(allAnsweredCheck);
    }, [answers]);

    const handleChangeText = (text: string) => {
        if (selected !== null) {
            let newAnswers = [...answers];
            newAnswers[selected] = { ...newAnswers[selected], text };
            setAnswers(newAnswers);
        }
    };

    const selectQuestion = (index: number) => {
        setSelected(index);
    };

    const finderText = (index: number): boolean => {
        return answers.some((x) => x.order == index && (x.text && x.text?.length > 0))
    }
    const handleSubmit = async () => {
        // ResetStepByExercises();
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId ?? '')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        } 
        if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
            try {
                const response = await AddWritingCheckListResponse({
                    description: "",
                    isActive: true,
                    isReviewed: false,
                    isPending: true,
                    exercises: exerciseId ?? '',
                    task: currentTaskId,
                    answer: answers,
                });
                onChangeTaskByAnswer(response, 'writing_check');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
            }
        }
    };

    const { setOffDraggable } = UseBookContainerModule()

    return (
        <>
            <div className="container-fluid" style={{ display: 'flex' }}>
                <div style={divStyle}>
                    {(mode === 'TIME_TO_SPEAK' || mode === 'DID_YOU_UNDERSTAND') ?
                        <div className="p-1 d-flex ">
                            <img className="" src={
                                toAbsoluteUrl(`/media/svg/michigan-icons/header/white/time_speak.png`)
                            } style={{ width: '40px', height: '40px' }} />
                            <h5 className="mb-0 p-2 text-white champ-light" style={{ fontSize: "20px" }}>
                                {mode === 'TIME_TO_SPEAK' ? 'Time to speak!' : 'Did you understand?'}
                            </h5>
                        </div>
                        : <HeaderCard />
                    }
                    <div className='scroll-y overflow-y-auto mb-6' style={{maxHeight:'70px', maxWidth:'435px'}}>
                        <span
                            className="text-white cuestion mb-0 poppins-light"
                            style={{
                                marginLeft: '10px',
                                fontSize: '80%',
                                color: '#fffff',
                                marginBottom: '20px'
                            }}>
                            {(mode === 'TIME_TO_SPEAK' || mode === 'DID_YOU_UNDERSTAND') ?
                                mode === 'TIME_TO_SPEAK' ? 'Answer the questions below and interact with your teacher and classmates.' :
                                    'Interact wth your teacher to answer the questions below.' :
                                instruction}
                        </span>
                    </div>
                    <div className="row p-2">
                        <div className="col-6 row align-items-start  scroll-y overflow-y-auto mb-2" style={{maxHeight:'350px', maxWidth:'350px'}} >
                            <div  style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                {responses ?
                                    responses.map((x, index) => (
                                        <p key={index} className="text-white mb-2 p-0 poppins-light" style={{ padding: 0, fontSize: 12, cursor: 'pointer' }} onClick={() => selectQuestion(index)}>
                                            {finderText(x.order)
                                                ?
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-check">
                                                </i>
                                                :
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-circle">
                                                </i>
                                            }
                                            {x.title}
                                        </p>
                                    ))
                                    : question.map((x, index) => (
                                        <p key={index} className="text-white mb-2 p-0 poppins-light" style={{ padding: 0, fontSize: 12, cursor: 'pointer' }} onClick={() => selectQuestion(index)}>
                                            {finderText(x.order)
                                                ?
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-check"></i>
                                                :
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-circle"></i>
                                            }
                                            {x.title}
                                        </p>
                                    ))}
                            </div>
                        </div>
                        <div className="col-6 mt-3">
                            <textarea
                                value={selected !== null ? answers[selected].text : ''}
                                onChange={(e) => handleChangeText(e.target.value)}
                                onMouseEnter={() => setOffDraggable(true)}
                                onMouseLeave={() => setOffDraggable(false)}
                                className="form-control rounded-4 text-white" placeholder="Type here.."
                                style={{ background: "#3f8bff", height: "160px" }} id="exampleFormControlTextarea1"></textarea>
                        </div>
                    </div>
                    {allAnswered && <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <ButtonTryAgain onAction={tryAgain} />
                        </div>
                        <ButtonSaveExercise
                            isChecked={allAnswered}
                            onNext={() => { }}
                            onSubmit={handleSubmit}
                            exerciseId={exerciseId}
                        />
                        {/* <div
                        onClick={handleSubmit}
                        className="cursor-pointer">
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '42px', height: '42px' }} alt="Next" />
                        </div> */}
                    </div>}
                </div>
                {/* Imagen de botón para cerrar o cancelar */}
          </div> 
        </>
    );
};

export { WritingCheckListItem };