import React from 'react';
import { CorrectSentenceItem } from './components/item';
import { UseCorrectSentenceItemModule } from './store';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';

type Props = {
    userMode:ModeTypeExercise
};

const CorrectSentence: React.FC<Props> = ({ userMode }) => {
    let {
        questions,
        answer,
        instructions,
    } = UseCorrectSentenceItemModule();

    return (
        <>
            <CorrectSentenceItem
                userMode={userMode}
                title={"title"}
                instruction={instructions}
                question={questions}
                responses={answer}
                handleSendAnswer={(e) => console.log(e)}
            />
        </>
    );
};

export { CorrectSentence };