import React, { FC } from "react"
import { BeneficiaryType, DataBeneficiaryType, DataInfoType, DocumentType, Gender, MaritalStatus, PaymentType, PropertyType, ProposalSendStatus, ProposalStatus, ProposalType, Stratum, StudyPlanType } from '../../../interface';
import * as Yup from 'yup'
import { useFormik } from "formik";
import moment from "moment";
import { UseAutoVerification } from "../../../store/main";

type BeneficiaryForm = {
    firstName: string;
    gender: 'MALE' | 'FEMALE',
    stratum: string,
    relationship: string;
    phone: string;
    cellPhone: string;
    documentType: string;
    birthDate: string;
    email: string;
    occupation: string;
    birthplace: string;
    documentNumber: string;
  }

type Props = {
  onSubmit:()=>void;
  selectUser:BeneficiaryType
}

const entitySchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
})

const StepBeneficiaryForm: FC<Props> = ({ onSubmit, selectUser }) => {
  const { responseBeneficiary, beneficiaries, setResponseBeneficiary } = UseAutoVerification();

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<BeneficiaryForm>({
      initialValues: {
          firstName: selectUser.firstName ?? "",
          gender: selectUser.gender ?? "MALE",
          stratum: selectUser.stratum ?? "",
          relationship: selectUser.relationship ?? "",
          phone: selectUser.phone ?? "",
          cellPhone: selectUser.cellPhone ?? "",
          documentType: selectUser.documentType ?? "",
          birthDate: moment(selectUser.birthDate).format('YYYY-MM-DD') ?? "",
          email: selectUser.email ?? "",
          occupation: selectUser.occupation ?? "",
          birthplace: selectUser.birthplace ?? "",
          documentNumber: selectUser.documentNumber ?? ""
      },
      validationSchema: entitySchema,

      onSubmit: (formData) => {
        const beneficiariesCopy = responseBeneficiary.filter(x => x.idBeneficiary !== selectUser.id);
        const response = validationData(formData);
        setResponseBeneficiary([...beneficiariesCopy,...response]);
        console.log('response', beneficiariesCopy, response)
        onSubmit();
      },
    });

  const validationData = (valuesForm: BeneficiaryForm) => {
    if (!beneficiaries) return [];

    let response: DataBeneficiaryType[] = [];

    for(const key in valuesForm){
      if (selectUser.hasOwnProperty(key)) {
        if (selectUser[key] !== valuesForm[key]) {
          response.push({
            idBeneficiary: selectUser.id,
            field: key,
            oldValue: selectUser[key],
            newValue: valuesForm[key]
          });
        }
      }
    }

    return response;
  };

  return (
    <>
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className="row">
          <div className='col-6 mb-10'>
            <label className='form-label required'>Nombre</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'firstName'}
              value={values.firstName}
              onChange={handleChange}
            />
            {touched.firstName && errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.firstName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Fecha de nacimiento</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'birthDate'}
              value={values.birthDate}
              onChange={handleChange}
            />
            {touched.birthDate && errors.birthDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.birthDate}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Tipo de Documento</label>
            <select
              name='documentType'
              className='form-select'
              aria-label='Default select example'
              value={values.documentType}
              onChange={handleChange}
            >
              {Object.keys(DocumentType).map((x) => (
                <option key={x} value={x}>{x}</option>
              ))}
            </select>
          </div>

          <div className='col-6 mb-10'>
            <label className='form-label required'>No. Documento</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'documentNumber'}
              value={values.documentNumber}
              onChange={handleChange}
            />
            {touched.documentNumber && errors.documentNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.documentNumber}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Genero</label>
            <select
              name='gender'
              className='form-select'
              aria-label='Default select example'
              value={values.gender}
              onChange={handleChange}
            >
              {Object.keys(Gender).map((x) => (
                <option key={x} value={x}>{x}</option>
              ))}
            </select>
          </div>

          <div className='col-6 mb-10'>
            <label className='form-label required'>Lugar de nacimiento</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'birthplace'}
              value={values.birthplace}
              onChange={handleChange}
            />
            {touched.birthplace && errors.birthplace && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.birthplace}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Telefono</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'cellPhone'}
              value={values.cellPhone}
              onChange={handleChange}
            />
            {touched.cellPhone && errors.cellPhone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.cellPhone}</span>
                </div>
              </div>
            )}
          </div>

          <div className='col-6 mb-10'>
            <label className='form-label required'>Correo</label>
            <input
              type='email'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'email'}
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

          
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            {'Save'}
          </button>
        </div>

        </div>
      </form>
    </div>
    </>

  )
}

export { StepBeneficiaryForm }