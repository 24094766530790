import {Route, Routes} from 'react-router-dom'
import { ThanksLayout } from '../booking/layout/thaks-layout'
import { ExecutiveSearchView } from './component'
// import { ThanksCard } from '../components/wizard/Thanks'
// import { ThanksLayout } from '../layout/thaks-layout'

const ExecutiveSearchPage = () => (
  <Routes>
    <Route element={<ThanksLayout />}>
      <Route index element={<ExecutiveSearchView />} />
    </Route>
  </Routes>
)

export {ExecutiveSearchPage}
