

import React, { FC } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';


type Props = {
  index:number;
  icon: string;
  title: string;
  description: string;
  onDelete: (url: number) => void; // Función para emitir la URL al padre al eliminar
};

const DetailFiles: FC<Props> = ({ icon, title,index, description, onDelete }) => {
  const isImage = (url: string) => {
    return /\.(jpeg|jpg|gif|png|svg)$/i.test(url);
  };


  const renderContent = () => {
    if (isImage(icon)) {
      return <img src={toAbsoluteUrl(icon)} alt={title} className='symbol symbol-75px mb-6' />;
    } else {
      // Determina el ícono basado en la extensión del archivo
      let fileExtension = icon.split('.').pop()?.toLowerCase();
      let iconSrc = `/media/svg/files/folder-document.svg`; // Ruta a los íconos según la extensión del archivo

      return (
        <div className='symbol symbol-725px mb-0fue'>
          <a href={iconSrc} target='_black' >
              <img src={iconSrc} alt={fileExtension}  />
          </a>
        </div>
      );
    }
  };

  return (
    <div className='card h-90'>
      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        <a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
          {renderContent()}
        </a>
        <a
          href='#'
          onClick={() => onDelete(index)}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <i style={{ color: 'red', fontSize: 17 }} className="fas fa-trash-alt"></i>
        </a>

      </div>
    </div>
  );
};

export default DetailFiles;