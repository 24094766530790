import {create} from 'zustand';
import { BookingDetails, Lead, TrailClassDashboard } from '../interfaces';
 
interface AppointmentModules {
    mode:'ADD'|'EDIT'|'VIEW'
    itemSelected?: BookingDetails
    modal:boolean,
    lead?:Lead,
    trail_class?:TrailClassDashboard,
    setLead:(value:Lead)=>void;
    setTrialClass:(value:TrailClassDashboard)=>void;
    setItem:(value:BookingDetails)=>void;
    setModal:(mode:boolean)=>void;
    resetData:()=>void,
}

export const UseBookingDashboard = create<AppointmentModules>( (set) => ({
    mode:'ADD',
    modal:false,
    setItem:(value:BookingDetails)=>{
        set({itemSelected:value})
    },
    setTrialClass:(value:TrailClassDashboard)=>{
        set({trail_class:value,modal:true})
    },
    setLead:(value:Lead)=>{
        set({lead:value,modal:true})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false})
    },
}));