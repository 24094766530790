import React from 'react';
import './styleTable.css'
import HeaderCard from '../ui/HeaderCard';
import DataTable from './table';
import { UseModuleCompleteSentence } from './store/main';
import MyNext from '../img/NEXT_btn 1.svg';
import { UseBookStudentModule } from '../../../../../modules/book/modules/screen/store/main';
import { ModeTypeExercise } from '../../../../../modules/book/components/RenderExerciseBook';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddCompleteSentencesResponse } from './services';
import { ButtonTryAgain } from '../../../../../modules/courses/exercises/common/button-try-again/buttonTryAgain';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { ButtonSaveExercise } from '../../../../../modules/courses/exercises/common/button-save-exercise/buttonSaveExercise';

type Props = {
  instruction:string;
  userMode?: ModeTypeExercise
}

const ExerciseOne: React.FC<Props> = ({instruction, userMode}) => {

  const {
    lessonData, 
    stepBookItem, 
    currentTaskId, 
    currentItemBook,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
} = UseBookStudentModule();

  const {checked,
    percentage, 
    inputValues, 
    setChecked,
    questions, 
    exerciseId,
    numberRow, 
    setPercentage} = UseModuleCompleteSentence();
  const [showPercentage, setShowPercentage] = React.useState<boolean>(false);

  const getInputValue = (rowIndex: number, columnIndex: number): string => {
    return inputValues.find((x) => x.col === columnIndex.toString() && x.row === rowIndex.toString())?.value;
  };
  
  const handleCheck = () => {
    let totalCells = 0;
    let correctCells = 0;
    let isEmptyCell = false;
    if (questions && numberRow) {
      for (let rowIndex = 0; rowIndex < numberRow; rowIndex++) {
        for (let columnIndex = 0; columnIndex < questions.length; columnIndex++) {
          const inputValue = getInputValue(rowIndex, columnIndex);
          if (!inputValue || inputValue.trim() === '') {
            isEmptyCell = true;
            break; 
          }
          const rowsValue = questions[columnIndex].rowValue[rowIndex];
          totalCells++;
          if (inputValue.trim() === rowsValue) {
            correctCells++;
          }
        }
        if (isEmptyCell) {
          alert('¡Por favor complete todos los campos antes de avanzar!');
          return; // Evitar el avance si se encuentra un dato vacío
        }
      }
      setChecked(true);
      const percentageExercise = (correctCells / totalCells) * 100;
      setPercentage(percentageExercise);
      setShowPercentage(true);
    }
  };

  const resetExercise = ()=> {
    setChecked(false);
    setPercentage(0);
    setShowPercentage(false);
  }

  const customStyle = {
    backgroundColor: '#3f8bff',   width: '525px',
    height: 'auto', borderRadius: '30px', borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',padding: '27px 20px 48px 20px'
  };

  const handleSubmit = async () => {
    // ResetStepByExercises();
    if (lessonData && lessonData.evaluation && lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(currentItemBook?.exercise?.id ??"",)
      if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
    if(userMode === 'STUDENT' &&  currentTaskId && checked && exerciseId){
      try {
        const response = await AddCompleteSentencesResponse({
          name: "",
          percentage: percentage??0,
          isActive: true,
          answer: inputValues,
          exercises: exerciseId,
          task: currentTaskId??'xx'
        });
        onChangeTaskByAnswer(response, 'complete_sentence');
      } catch (e) {
          showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
      }
    }
  };

  // React.useEffect(() => {
  //   if (typeof percentage !== null) {
  //     handleCheck(); 
  //     setShowPercentage(true);
  //   }
  // }, [percentage]);

  return (
    <>
      <div className="container-fluid "style={{display:'flex'}}>
        <div style={customStyle}>
          <HeaderCard />
          <span className="text-white cuestion  poppins-light ">{instruction}</span>
          <DataTable />
          {/* <p className={`animate__animated text-white fs-7 poppins-light  ${showPercentage ? 'animate__bounce' : 'd-none'}`}>Porcentaje de aciertos: {percentage !== null ? `${percentage.toFixed(1)}%` : 'Calculando...'}</p> */}
        <div className='d-flex justify-content-between align-items-center mt-5'>
          <div>
              {checked &&
                  <ButtonTryAgain onAction={resetExercise} />
              }
          </div>
          <div>
          <h3 className='text-center' style={{ fontSize: 10 }}> 
              {checked &&
                  <span className=" text-white  mb-0 poppins-light">Porcentaje correcto: {percentage && percentage.toFixed(2)}%</span>
              }
          </h3>
          </div>
          <ButtonSaveExercise
            isChecked={checked}
            onNext={handleCheck}
            onSubmit={handleSubmit}
            exerciseId={exerciseId}
          />
          {/* <div
          onClick={() => {
              if (checked) {
                  handleSubmit();
              } else {
                  handleCheck();
              }
          }}
          className="cursor-pointer">
          <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '42px', height: '42px' }} alt="Next" />
          </div> */}
      </div>
        </div>
                
          {/* <NexBtnThow /> */}
      </div>
    </>
  );
};

export {ExerciseOne};