import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import IconFa from '../Icon/indext'
import { ResumeStats } from '../../../modules/resume/interfaces'
type Props = {
    color:'success'|'danger'|'primary'|'secondary'
    title:string
    stats:ResumeStats
}
const ProgressBar: React.FC<Props> = ({title,color,stats}:Props) => {

return (<>

            <div className='d-flex align-items-center w-100 flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>{title}</span>
                  <span className='fw-bolder fs-6'>{ stats.percentage}% ({stats.total})</span>
                </div>
                <div className={`h-5px mx-3 w-100 bg-light mb-3`}>
                  <div
                    className={`bg-${color} rounded h-5px`}
                    role='progressbar'
                    style={{width: `${stats.percentage}%`}}
                  ></div>
                </div>
              </div>

        </>)
}
export {ProgressBar}
