import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../../../_metronic/helpers'
import {BookPage} from '../../interfaces'

import { BookPageAdd, BookPageUpdate } from '../../services'
import { UseBookPageModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { UseBookModule } from '../../../../store/main'
import FileUploadComponent from '../../../../../../../components/common/SingleFile'
import { favIconAppSelector } from '../../../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  name: string
  isActive: boolean
  page:number
  description?:string
  file?:string
  book?:string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  page: Yup.number(),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const BookPageForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const { itemSelected } = UseBookModule.getState()
  const {modal,pageSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseBookPageModule();
  const [data, setData] = useState<BookPage>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [file, setFile] = React.useState<any>()
  const  changeAvatar =async (obj:any)=> {  
    setFile(obj)
    setFieldValue('file',obj.id)
  }
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        page: (mode==='EDIT' || mode==='VIEW')? pageSelected?.page??1: 1,
        name:(mode==='EDIT' || mode==='VIEW')? pageSelected?.name??'': '',
        file: (mode==='EDIT' || mode==='VIEW')? pageSelected?.file.id??'': '',
        book: (mode==='EDIT' || mode==='VIEW')? itemSelected?.id??itemSelected?.id: itemSelected?.id,
        isActive:(mode==='EDIT' || mode==='VIEW')? pageSelected?.isActive??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? pageSelected?.description??'':'',
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await BookPageAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['book-page'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  BookPageUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['book-page'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

    useEffect(() => {
      setFile(pageSelected?.file)
    }, [itemSelected])
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12 mx-auto d-flex'>
            { (file && (file.url || file.location)) ? 
                <img  className="img-fluid" width={'50%'} src={(file.url||file.location)??toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
                :
                <img  className="img-fluid" width={'50%'} src={toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
            }
                <FileUploadComponent onChangeFile={(e)=>changeAvatar(e)} onChangeLoading={(e)=>setLoading(e)}  />
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'># Página</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'page'}
              value={values.page}
              onChange={handleChange}
            />
            {touched.page && errors.page && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.page}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Descripción</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {BookPageForm}
