/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {DateRange} from '../../../../admin/appointment/components/list'
import {UseAppointmentModule} from '../../../../admin/appointment/store/main'
import {
  UseAppointmentAllByResponsiblePaginate,
  UseAppointmentAllByTeacherPaginate,
} from '../../../../admin/appointment/services'
import {AppointmentTeacherDashboard} from '../../../../dashboard/components/teacher/interfaces'
import {ClassListItem} from '../../../../dashboard/components/teacher/class-list/items'
import {useParams} from 'react-router-dom'
import { AppointmentStatus } from '../../../appointment/interfaces'

type Props = {
  height?: number
  typeUser: string
}
const ClassListTeacherDashboard: React.FC<Props> = ({height, typeUser}: Props) => {
  const {id} = useParams()
  const [currentUnfinishedClass , setCurrentUnfinishedClass] = useState<number>()
  const {after, before,statusAppointmentType, setStatusAppointmentType} = UseAppointmentModule()

  const [dateRange, setDateRange] = React.useState<DateRange>({
    after: after,
    before: before,
  })
  const [appointment, setAppointment] = React.useState<AppointmentTeacherDashboard[]>([])
  type Props = {
    className: string
  }

  const handleFilterChange = (status: AppointmentStatus | undefined) => {
    setStatusAppointmentType(status)
  };

  const fetch = async () => {
    try {
      if (typeUser === 'ASSISTANT') {
        let response = await UseAppointmentAllByResponsiblePaginate()
        const activities = response.result.sort((a, b) =>
          moment(a.time.start).diff(moment(b.time.start))
        )
        setAppointment(activities)
      }
      if (typeUser === 'TEACHER') {
        let response = await UseAppointmentAllByTeacherPaginate(id ?? '')
        const activities = response.result.sort((a, b) =>
          moment(a.time.start).diff(moment(b.time.start))
        )
        setAppointment(activities)
        // Identificar el índice de la última clase no finalizada
        const lastUnfinishedIndex = activities.findIndex((appointment) => appointment.status_appointment !== AppointmentStatus.FINISHED && appointment.status_appointment !== AppointmentStatus.CANCELLED);
        setCurrentUnfinishedClass(lastUnfinishedIndex);      
      }
    } catch (e) {
      console.error(e)
    }
  }

  
  useEffect(() => {
    fetch()
  }, [before, statusAppointmentType])
  
  
  return (
    <div className='row p-0'>
      <div className='col-sm-12 p-0'>
        <div className={`card`}>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold bg-light'>
                    <th className=' champ-light ps-4 min-w-200px rounded-start align-content-center'>Activity</th>
                    <th className=' champ-light min-w-100px align-content-center'>Date</th>
                    <th className=' champ-light min-w-100px align-content-center'>State/Rating</th>
                    <div className="col-sm-12 d-flex justify-content-center">
                      <select
                        className="form-select"
                        value={statusAppointmentType || ''}
                        onChange={(e) => {
                          if (e.target.value === '') {
                            handleFilterChange(undefined);
                          } else {
                            handleFilterChange(e.target.value as AppointmentStatus);
                          }
                        }}
                        style={{ width: '200px' }} // Ajusta el ancho según sea necesario
                      >
                        <option value="">All Appointments</option>
                        <option value={AppointmentStatus.PENDING}>Pending</option>
                        <option value={AppointmentStatus.CANCELLED}>Cancelled</option>
                        <option value={AppointmentStatus.IN_PROCESS}>In Process</option>
                        <option value={AppointmentStatus.FINISHED}>Finished</option>
                      </select>
                    </div>
                  </tr>
                </thead>
                <tbody>
                  {appointment.map(
                    (x, index) =>
                      // x.status_appointment === 'PENDING' && (
                        <ClassListItem appointment={x}
                        isCurrent={index === currentUnfinishedClass && x.status_appointment !== 'FINISHED'} index={index}/>
                      // )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ClassListTeacherDashboard}
