 

import { useStore } from 'zustand'
import { api } from '../../auth/services/auth.services'
 

 
export const UseExecutiveSearch = async (mode:string,value:string): Promise<any> => {
  try {
    const response: any = await api.get(
      `/student-settings/executives?type=${mode}&where=${value}`,
      {
      }
    )
    return response.data
  } catch (e) { 
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
