import React from 'react';
import { StepInfo } from './steps/step-info/StepInfo';
import { StepBeneficiary } from './steps/step-beneficiary/StepBeneficiary';
import { StepperComponent } from '../../../../_metronic/assets/ts/components';
import { ICreateAccount, createAccountSchemas, inits } from '../../wizards/components/CreateAccountWizardHelper';
import { StepPhoto } from './steps/step-photo';
import { StepQuestions } from './steps/step-questions';
import { UseAutoVerification } from '../store/main';
import { useParams } from 'react-router-dom';
import { UseGetOneContract } from '../services';

type Props = {};

const AutoVerification: React.FC<Props> = () => {
  const stepperRef = React.useRef<HTMLDivElement | null>(null)
  const stepper = React.useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = React.useState(createAccountSchemas[0])
  const [initValues] = React.useState<ICreateAccount>(inits)
  let { responseInfo,responseBeneficiary,setDataHolder,setBeneficiaries} = UseAutoVerification();
  let { id } = useParams();
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = () => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const getOneContract= async()=>{
    if(!id)return;
   let response = await UseGetOneContract(id)
   setDataHolder(response.contract)
   setBeneficiaries(response.beneficiaries)

  }

  React.useEffect(() => {
    getOneContract()
  }, [id])

  React.useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  return (
    <>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
 
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px' >
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number champ-light'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='champ-light' style={{ color: '#2d30e1' }}>Contrato</h3>

                    <div className='stepper-desc fw-semibold poppins-light'>Confirma los datos del titular...</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number champ-light'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='champ-light' style={{ color: '#2d30e1' }}>Beneficiarios</h3>
                    <div className='stepper-desc fw-semibold poppins-light'>Confirma datos de beneficiarios...</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number champ-light'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='champ-light' style={{ color: '#2d30e1' }}>Términos y condiciones</h3>
                    <div className='stepper-desc fw-semibold'>Acepta los terminos del contrato...</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number champ-light'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='champ-light' style={{ color: '#2d30e1' }}>Finalizar</h3>

                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}


                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}


            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}


        {/* <pre>
          {JSON.stringify(responseBeneficiary, null, 2)}
        </pre>
        <pre>
          {JSON.stringify(responseInfo, null, 2)}
        </pre> */}

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>

          <div className='py-20 w-100 w-xl-700px px-9' id='kt_create_account_form'>
            <div className='current' data-kt-stepper-element='content'>
              <StepInfo onSubmit={submitStep} />
            </div>

            <div data-kt-stepper-element='content'>
              <StepBeneficiary
                prevStep={prevStep}
                onSubmit={submitStep}
              />
            </div>

            <div data-kt-stepper-element='content'>
              <StepPhoto
                prevStep={prevStep}
                onSubmit={submitStep}
              />
            </div>

            <div data-kt-stepper-element='content'>
              <StepQuestions
                prevStep={prevStep}
                onSubmit={submitStep}
              />
            </div>

            <div className='d-flex flex-stack pt-10'>
              {/* <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !==
                        stepper.current?.totalStepsNumber! - 1 && 'Continue'}
                      {stepper.current?.currentStepIndex ===
                        stepper.current?.totalStepsNumber! - 1 && 'Submit'}
                      <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div> */}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export { AutoVerification };