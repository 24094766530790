/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { Dropdown1, useThemeMode } from '../../../../../../_metronic/partials'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../../../_metronic/assets/ts/_utils'
import ExcelDocument from '../../../../../components/common/ExcelExport/indext'
import moment from 'moment'
 

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  weight:number,
  leads?:any[]
  dateMasterClass?:string
  status?:string
  changeStatus: (value:string) => void
}

const DashboardResume: React.FC<Props> = ({leads,className,status, chartColor, chartHeight,weight,dateMasterClass,changeStatus}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight,weight))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode,weight])
  const theadName = [
      "ID",
      "NOMBRE",
      "EMAIL",
      "TELEFONO",
      "DESCRIPCION",
      "CODIGO",
      "CONTRATO",
      "REFERIDO",
      "ACTIVO",
      "ASISTIO",
      "MASTRICULADO",
      "ORIGEN",
      "NIVEL",
      "INTERES",
      "CREADO",
      "EDITADO"
    ]

  return (
    <div className={`card`} style={{height:'95%'}}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='champ-light fs-3 mb-1'>Attendance Rate</span>
        </h3>
      </div>
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
        <h2 className='text-center champ-light'>{status}</h2>
        <div className="d-grid gap-2">
          <button className="btn btn-secondary champ-bold" type="button" disabled={status === 'PENDING' ? false : true} onClick={()=>changeStatus('IN_PROCESS')}>In Process</button>
          <button className="btn btn-success champ-bold" type="button" disabled={status === 'IN_PROCESS' ? false : true} onClick={()=>changeStatus('FINISHED')}>Finish Class</button>
        </div>
        {leads && leads?.length>0 && <ExcelDocument
         dataExcel={leads} 
         filename={`Master Class Resume :${moment(dateMasterClass??moment()).format('DD-MM-YYYY')}-${moment()}`} 
         sheetName='Leads' 
         theadName={theadName}
        />}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string,weight:number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [weight],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {DashboardResume}
