import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { TrueWord } from '../../interfaces'

import { TrueWordAdd, TrueWordUpdate } from '../../services'
import { UseTrueWordModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TrueWordsInterface } from '../../../../../../../courses/exercises/true-words/interfaces'
import { useParams } from 'react-router-dom'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'

export type TrueWordFormFields = {
  name: string
  isActive: boolean
  description?: string;
  sentences: TrueWordsInterface[];
}

const entitySchema = Yup.object().shape({
  type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const TrueWordForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseTrueWordModule();
  const [dataSentence, setDataSentence] = useState<TrueWordsInterface>({
    order: 1,
    image: '',
    sentence: '',
  })
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<TrueWordFormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        sentences: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sentences.map(x => JSON.parse(x)) ?? [] : []
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await TrueWordAdd({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['true-word'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await TrueWordUpdate({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['true-word'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });

  const addSentence = () => {
    const findOrderRepeated = values.sentences.find(x => x.order === dataSentence.order);
    if (findOrderRepeated)
      return showSimpleAlert({ message: 'Orden repetido', icon: 'error' });

    if (dataSentence.sentence === '' || dataSentence.image === '')
      return showSimpleAlert({message: 'Rellena todos los campos', icon: 'error'}); 

    setFieldValue('sentences', [...values.sentences, dataSentence]);
    setDataSentence({
      order: 1,
      image: '',
      sentence: '',
    });
  };

  const deleteSentence = (order: number) => {
    const newValues = values.sentences.filter(x => x.order !== order);
    setFieldValue('sentences', newValues);
  };

  const truncateText = (text: string) => {
    if (text.length > 150) {
      return text.substring(0, 150) + '...';
    }
    return text;
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Respuesta</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'answer'}
              value={dataSentence.sentence}
              onChange={(e) => setDataSentence({ ...dataSentence, sentence: e.target.value })}
            />

            <label className='form-label mb-3 fw-bold'>Url de la imagen</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'image'}
              value={dataSentence.image}
              onChange={(e) => setDataSentence({ ...dataSentence, image: e.target.value })}
            />

            <label className='form-label mb-3 fw-bold'>Orden</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'order'}
              value={dataSentence.order}
              onChange={(e) => setDataSentence({ ...dataSentence, order: parseInt(e.target.value) })}
            />

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addSentence} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Respuesta</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='scroll-y' style={{maxHeight: '10px'}}>
                {values.sentences.length > 0 && values.sentences.map((x, i) => (
                  <tr key={i}>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={x.image} alt='Image' />
                        </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.sentence}
                            </a>
                          </div>
                        </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteSentence(x.order)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { TrueWordForm }
