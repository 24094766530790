import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {FreezingStatus, TrailFreezing} from '../../interfaces'

import { TrailFreezingAdd, TrailFreezingUpdate } from '../../services'
import { UseTrailFreezingModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store'

export type FormFields = {
  start: string;
  end: string;
  status: FreezingStatus;
  isActive: boolean
  description?:string
  days: number;
}

const entitySchema = Yup.object().shape({
  //name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TrailFreezingForm: React.FC<Props>  = (props:  Props) => {
  const { settings } = UseMyRoadmapModule.getState();
  const queryClient = useQueryClient()
  const {root,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseTrailFreezingModule();
  const [data, setData] = useState<TrailFreezing>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        start: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.start ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        end: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.end ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        status: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.status ?? FreezingStatus.ANTICIPATED : FreezingStatus.ANTICIPATED,
        days : (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.days ?? 0 : 0,
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          
          try{
            setLoading(true);
            await TrailFreezingAdd({
              ...formData,
              student: itemSelected ? itemSelected.student.id : 'xxx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['trail-freezing'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  TrailFreezingUpdate({
                ...formData,
                student: root ?? ''
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['trail-freezing'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    React.useEffect(() => {
      setFieldValue('days', moment(values.end).set({ hour: 12 }).diff(moment(values.start).set({ hour: 12 }), 'days') + 1);
    }, [values.start, values.end]);
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          
          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Inicio</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'start'}
              value={values.start}
              onChange={handleChange}
            />
            {touched.start && errors.start && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.start}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Fin</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'end'}
              value={values.end}
              onChange={handleChange}
            />
            {touched.end && errors.end && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.end}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
                <label className='form-label mb-0'>Status</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='status'
                  value={values.status}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {Object.keys(FreezingStatus).map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.status}</span>
                    </div>
                  </div>
                )}
              </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Observación</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {TrailFreezingForm}
