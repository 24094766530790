import {Route, Routes} from 'react-router-dom'
import { FaqDetails } from '../components/details'
import { FaqTableList } from '../components/list'
import { FaqCategoryTableList } from '../modules/faq-category/components/list'


const FaqPage = () => (
  <Routes>
      <Route path='/category' element={<FaqCategoryTableList />} />
      <Route path='/details' element={<FaqDetails />} />
      <Route path='/' element={<FaqTableList />} />
  </Routes>
)

export {FaqPage}
