import { useQueryClient } from 'react-query';
import {create} from 'zustand';
import { Evaluation, EvaluationItem, Pagination } from '../../../interfaces';
import { persist } from 'zustand/middleware';
import { UseBasicVocabularyCompletionModule } from '../../../../../courses/exercises/basic-vocabulary-completion/store';
import { ExercisesType } from '../../../../exercises/interfaces';
import { UseBasicVocabularyModule } from '../../../../../courses/exercises/basic-vocabulary/store';
import { SetQuestionsInQuiz } from '../../../../../courses/screen/store/main';
import { Lessons } from '../../../../../abroad/learning-path/modules/lessons/interfaces';


interface EvaluationTestModules {
    evaluationTest?: Evaluation;
    lessonCourse?: Lessons;
    stepEvaluationTest:number;
    questionsEvaluationTest:EvaluationItem | null;
    resetEvaluationTest:()=>void;
    setEvaluationTest:(value: Evaluation)=>void;
    setCurrentEvaluation:(id:string)=>void
    setLessonCourse:(value: Lessons)=>void;
    setStepEvaluationTest:(value:number)=>void;
}

export const UseEvaluationTestModule = create(persist<EvaluationTestModules>( (set) => ({
    stepEvaluationTest: 0,
    questionsEvaluationTest: null,
    setEvaluationTest:(value)=>{
        set((state) => {
            let currentQuestion: EvaluationItem |null = null
            if (value?.evaluation_item) {
                let current = value.evaluation_item.sort((a, b) => a.order - b.order)[state.stepEvaluationTest]
                currentQuestion = {
                    ...current,
                    exercise: {
                        ...current.exercise,
                        questions: current.exercise?.questions?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        questions_completion: current.exercise?.questions_completion?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        drag_drop_questions: current.exercise?.drag_drop_questions?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            words: x?.words?.map((q) => JSON.parse(q)),
                        })),
                        true_or_false: current.exercise?.true_or_false?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answer: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        history_writing: current.exercise?.history_writing?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            questions: x?.questions?.map((q) => JSON.parse(q)),
                        })),
                        // movie_wring: current.exercise?.movie_wring?.sort((a, b) => a.order - b.order).map((x) => ({
                        //     ...x
                        // }))
                    }
                }
            }
            if (currentQuestion) {
                SetQuestionsInQuiz(currentQuestion);
            }
            return {
                evaluationTest: value,
                questionsEvaluationTest: currentQuestion
            }
        })
    },
    resetEvaluationTest:()=>{
        set({evaluationTest: undefined, stepEvaluationTest: 0, questionsEvaluationTest: null})
    },
    setCurrentEvaluation:(id)=>{
        set((state) => {
            if (state.evaluationTest?.evaluation_item?.some(x => x.id === id)) {
                return {
                    evaluationTest: {
                        ...state.evaluationTest,
                        evaluation_item: {
                            ...state.evaluationTest.evaluation_item,
                            currentEvaluation: true
                        }
                    }
                }
            }
            return state
        })
    },
    setLessonCourse:(value)=>{
        set({lessonCourse: value})
    },
    setStepEvaluationTest:(value)=>{
        set((state) => {
            let currentQuestion: EvaluationItem |null = null
            if (state.evaluationTest?.evaluation_item) {
                let current = state.evaluationTest.evaluation_item.sort((a, b) => a.order - b.order)[value]
                currentQuestion = {
                    ...current,
                    exercise: {
                        ...current.exercise,
                        questions: current.exercise?.questions?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        questions_completion: current.exercise?.questions_completion?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answers: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        drag_drop_questions: current.exercise?.drag_drop_questions?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            words: x?.words?.map((q) => JSON.parse(q)),
                        })),
                        true_or_false: current.exercise?.true_or_false?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            answer: x?.answer?.map((q) => JSON.parse(q)),
                        })),
                        history_writing: current.exercise?.history_writing?.sort((a, b) => a.order - b.order).map((x) => ({
                            ...x,
                            questions: x?.questions?.map((q) => JSON.parse(q)),
                        })),
                        // movie_wring: current.exercise?.movie_wring?.sort((a, b) => a.order - b.order).map((x) => ({
                        //     ...x
                        // }))
                    }
                }
            }
            if (currentQuestion) {
                SetQuestionsInQuiz(currentQuestion);
            };
            return {
                stepEvaluationTest: value,
                questionsEvaluationTest: currentQuestion
            }
        })
    }
    }),
    {
        name: 'evaluation-test'
    }
));