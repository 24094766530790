 import {create} from 'zustand';
import { Answer, QuestionBasicVocabularyCompletion, quiz,QuestionAndAnswer, questions } from '../interfaces';

type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';

interface BasicVocabularyCompletionInterface {
  status:StatusQuiz
  questions:QuestionBasicVocabularyCompletion[]
  title:string;
  instructions:string;
  step:number;
  trueAnswer:QuestionAndAnswer[]
  falseAnswer:QuestionAndAnswer[]
  list:Answer[]
  total:number
  setAnswer:(Answer)=>void;
  setStatus:(StatusQuiz)=>void
  setStep:(number)=>void;
  startQuiz:()=>void;
  setQuestionBasicCompletion:(value:QuestionBasicVocabularyCompletion[])=>void,
  resetData:()=>void
}

export const UseBasicVocabularyCompletionModule = create<BasicVocabularyCompletionInterface>( (set, get) => ({
    status:'TITLE',
    title:"",
    instructions:"",
    questions:[],
    step:0,
    trueAnswer:[],
    falseAnswer:[],
    list:[],
    total:0,
    
    setAnswer:(value:Answer)=>{
      let step:number=get().step;
      let currentQuestion:QuestionBasicVocabularyCompletion =get().questions[step];
      let questionsLength:number=get().questions.length;
      let trueAnswer:QuestionAndAnswer[]=get().trueAnswer;
      let falseAnswer:QuestionAndAnswer[]=get().falseAnswer;
      let list:Answer[]=get().list;
      let status:StatusQuiz ='ANSWER';
      if(questionsLength===(step+1)){
        status = 'RESUME'
      }
      if(value.isTrue){
        
        trueAnswer.push({...currentQuestion,answer:value})
        list.push(value)
        set({
          trueAnswer:trueAnswer,
          step:step+1,
          status:status
        })
      } else{
        falseAnswer.push({...currentQuestion,answer:value})
        list.push(value)
        set({
          falseAnswer:falseAnswer,
          step:step+1,
          status:status
        })
      }
    },
    setStep:(value:number)=>{
      let step:number=get().step;
      let questionsLength:number=get().falseAnswer.length;
      let status:StatusQuiz ='THROW_ERROR';
      if(questionsLength===value){
        status = 'RESUME'
      }
      set({
        step:value,
        status:status
      })
    },
    startQuiz(){
      set({
        status:'ANSWER'
      })
    },
    setStatus:(value:StatusQuiz)=>{
      if(value==='ANSWER'){
        set({
          trueAnswer:[],
          falseAnswer:[],
          step:0,
          status:'ANSWER'
        })
      }
      if(value==='THROW_ERROR'){
        set({
          step:0,
          status:'THROW_ERROR'
        })
      }
      if(value==='RESUME'){
        set({
          step:0,
          status:'RESUME'
        })
      }
    },
    setQuestionBasicCompletion:(value)=>{
      set({
        questions: value
      })
    },
    resetData:()=>{
      set({
        status:'TITLE',
        title:"Este es un titulo de quiz",
        instructions:"esta es una insturccion",
        questions:questions,
        step:0,
        trueAnswer:[],
        falseAnswer:[],
        list:[],
        total:0,
      })
    }
   
}));