import React from 'react'
import {Exercises, ExercisesType} from '../../../admin/exercises/interfaces'
import {RenderExerciseBook} from '../RenderExerciseBook'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { AddTeacherCheckWritingResponse } from '../../../courses/exercises/check-writing/services'
import { UseBookTeacherModule } from '../../modules/class/store/main'
import { AddTeacherSpeakOutResponse } from '../../../courses/exercises/speak-out/services'
import { AddTeacherWritingCheckListResponse } from '../../../courses/exercises/WrintingCheckList/services'
import { AddTeacherHistoryWritingResponse } from '../../../courses/exercises/history-wrinting/services'

type ExerciseInfoProps = {
  exercise: any// Exercises // Propiedad para recibir el ejercicio
  task: any;
}

const ExerciseInfo: React.FC<ExerciseInfoProps> = ({exercise, task}) => {
  const [comments, setComments] = React.useState('')
  const [rating, setRating] = React.useState<number>(0);
  const {} = UseBookTeacherModule();

  const handleRatingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    setRating(value)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (exercise.type === ExercisesType.CHECK_WRITING) {
      try {
        AddTeacherCheckWritingResponse(task.id, {
          teacherObservation: comments,
          percentage: rating,
        });
      } catch (e) {
        showSimpleAlert({message: 'Error al responder la tarea', icon: 'error'});
      }
    } else if (exercise.type === ExercisesType.SPEAK_OUT) {
      try {
        AddTeacherSpeakOutResponse(task?.speak_out[0]?.id, {
          teacherObservation: comments,
          percentage: rating,
        });
      } catch (e) {
        showSimpleAlert({message: 'Error al responder la tarea', icon: 'error'});
      }
    } else if (exercise.type === ExercisesType.WRITING_CHECK_LIST) {
      try {
        AddTeacherWritingCheckListResponse(task?.writing_check[0]?.id, {
          teacherObservation: comments,
          percentage: rating,
        });
      } catch (e) {
        showSimpleAlert({message: 'Error al responder la tarea', icon: 'error'});
      }
    }
    setComments('');
    setRating(0);
  };

  return (
    <div className='d-flex flex-column m-2 mx-10'>
      {/* Sección para mostrar el ejercicio */}
      <div className='container p-0 mx-0 mt-2 w-100 align-items-center justify-content-center'>
        <RenderExerciseBook exercise={exercise} />
      </div>

      {/* Sección para poner la nota */}
      {[ExercisesType.CHECK_WRITING,
        ExercisesType.HISTORY_WRITING, //
        ExercisesType.SPEAK_OUT,//
        ExercisesType.WRITING_CHECK_LIST].includes(exercise.type as ExercisesType) &&
        <form onSubmit={handleSubmit} noValidate>
        <div className='my-5 w-100'>
        <label className='form-label poppins-bold fs-3'>Comments: </label>
          <textarea
            value={comments}
            className='w-100 h-80 px-5 pt-3 border-1 border-opacity-25 rounded poppins-light'
            onChange={(e) => setComments(e.target.value)}
            placeholder='Leave some comments...'
          />
        </div>
          <div className='mb-3 d-flex justify-content-between'>
            <div>
              <label className='form-label poppins-bold fs-3 me-5'>Score: </label>
              <input 
                type='number' 
                value={rating}
                onChange={handleRatingChange}
                className='poppins-light pe-0  w-25 text-end  border-1 border-opacity-25 rounded' 
                id='exampleInputPassword1' />%
            </div>
            <button type='submit' className='btn btn-primary py-2'>
              Submit
            </button>
          </div>
        </form>
      }
    </div>
  )
}

export default ExerciseInfo
