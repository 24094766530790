import React from 'react';
import { EvaluationItem } from '../../interfaces';
import { UseEvaluationModule } from '../../store/main';
import { KTIcon } from '../../../../../../_metronic/helpers';
import Swal from 'sweetalert2';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { EvaluationItemDelete } from '../../services';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
    items?: EvaluationItem[]
};

const EvaluationItemList: React.FC<Props> = ({items}) => {
    const {setUpdateItem, setToAddItem, setType, setModal} = UseEvaluationModule();
    const queryClient = useQueryClient();

    const [finalPercentage, setFinalPercentage] = React.useState<number>(0);

    const deleteItem = async (id: string) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              try{
                await EvaluationItemDelete(id)
                showSimpleAlert({message:'Ok!',icon:'success'});
                queryClient.invalidateQueries({ queryKey: ['evaluation-by-lesson'] });
                queryClient.invalidateQueries({ queryKey: ['evaluation'] })
                setModal(false);
               }catch(e){
                setModal(false);
                  
               }
            }
          })
    };

    React.useEffect(() => {
        if (items) {
            let totalPercentage = 0;
            items.forEach((x) => 
            totalPercentage += x.percentage);
            setFinalPercentage(totalPercentage);
        }
    }, [items]);

    return(<>
    <div className='card-body p-9'>
        <div className='d-flex justify-content-between'>
            <div className='flex-shrink-0 mb-2'>
                <button
                type='button'
                onClick={()=> setType(undefined)}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                >
                <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
                </button>
            </div>
            <div className='flex-shrink-0 mb-2'>
                <span className='d-flex justify-content-between'>
                    <h6 className='poppins-light text-danger'>
                        {finalPercentage !== 100 ? '¡Los ejercicios no suman el 100%! -' : ''}
                    </h6>
                    <h6 className={`ms-1 poppins-bold ${finalPercentage !== 100 ? 'text-danger' : ''}`}>
                        {finalPercentage}%
                    </h6>
                </span>
            </div>
            <div className='flex-shrink-0 mb-2'>
                <button
                    disabled={finalPercentage >= 100}
                    onClick={() => setToAddItem()}
                    className='btn btn-sm btn-light-primary mr-8'
                >
                    <KTIcon iconName='plus' className='fs-3' />
                </button>
            </div>
        </div>
        <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
                <tr className='fw-bold text-muted'>
                    <th></th>
                    <th>Exercise</th>
                    <th>Percentage</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {items?.sort((a,b) => a.order - b.order).map((x) => (
                <tr key={x.id} className=''>
                    <td>
                        <span>
                            {x?.order}
                        </span>
                    </td>
                    <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {x?.name}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {x?.exercise?.name}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span>
                            {x?.percentage}%
                        </span>
                    </td>
                    <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                            onClick={()=>setUpdateItem(x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-pen"></i>
                        </button>
                        <button
                            onClick={() => deleteItem(x.id??'xxx')}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <i style={{ color: 'red', fontSize: 17 }} className="fas fa-trash-alt"></i>
                        </button>
                        </div>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
    </>)
};

export {EvaluationItemList};