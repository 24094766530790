import React, { useEffect, useState } from 'react';

import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
// import { AddMovieWritingResponse } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { useNavigate } from 'react-router-dom';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { AnswerOrderSentences, QuestionOrderSentences } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { AddOrderSentencesResponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseOrderSentencesModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
    userMode?:ModeTypeExercise
    responses?: AnswerOrderSentences[]
    question: QuestionOrderSentences[]
};
interface   Answer {
    title: string;
    userOrder: number;
    isTrue?:boolean;
}
const divStyle = {
    backgroundColor: '#3f8bff', 
    maxWidth:'525px',
    minWidth: '525px',
    minHeight: '400px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid',
    padding: '27px 20px 20px 20px'
};
const inputStyle: React.CSSProperties = {
    display: 'flex', width: '32px',
    height: '16px', marginRight: '10px', borderRadius: '40px',
    borderWidth: '0px', borderStyle: 'solid', borderColor: '#ffffff',
    textAlign: 'center', backgroundColor: '#d0ff00', outline: 'none', marginBottom: '0',
    marginTop: '1px'
};

const OrderSentencesItem: React.FC<Props> = ({
    userMode,
    question,
    responses,

}: Props) => {
    const [allAnswered, setAllAnswered] = useState<boolean>(false);
    const [answers, setAnswers] = useState<Answer[]>([]);
    const [percentageCorrect, setPercentageCorrect] = useState<number | null>(null);

    const {exerciseId, instructions} = UseOrderSentencesModule();
    const {modeViewAnswer} = UseModeCourseModule();

    const {
        lessonData, 
        stepBookItem, 
        currentTaskId, 
        currentItemBook, 
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer
    } = UseBookStudentModule();

    const handleSubmit = async () => {
        // ResetStepByExercises();
        if (lessonData && lessonData.evaluation && 
        lessonData.evaluation.evaluation_item){
            addCompletedExerciseId(exerciseId ??"")
            if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId??'')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        }
        if(userMode==='STUDENT' && currentTaskId && exerciseId){
            try {
                const response = await AddOrderSentencesResponse({
                    description : '',
                    isActive: true,
                    answer: answers,
                    percentage: percentageCorrect??0,
                    exercises: exerciseId??'',
                    task: currentTaskId,
                    });
                    onChangeTaskByAnswer(response, 'order_sentences');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
            }
        }    
    };
    const tryAgain = () => {
        setAnswers([])
        setAllAnswered(false)
        const initialAnswers = question.map((x) => ({
            title: x.title,
            userOrder: 0, // Inicializa con 0 para indicar no respondido
        }));
        setAnswers(initialAnswers);
    }
    useEffect(() => {
        if (responses) { // initializes responses when it has any response
            const initialAnswers = responses.map((x) => ({
                title: x.title,
                userOrder: x.userOrder,
                isTrue: x.isTrue
            }));
            setAnswers(initialAnswers);
        } else { // Inicializa las respuestas cuando el componente se monta o cuando las preguntas cambian
            const initialAnswers = question.map((x) => ({
                title: x.title,
                userOrder: modeViewAnswer ? x.userOrder : 0, // Inicializa con 0 para indicar no respondido
            }));
            setAnswers(initialAnswers);
        }
    }, [question, responses, modeViewAnswer]); // Asegúrate de reaccionar a cambios en las preguntas

    const handleInputChange = (title: string, value: number) => {
        if (value < 0 )return
        // Actualiza las respuestas basándose en el input del usuario
        const updatedAnswers = answers.map((answer, i) => {
            const isTrue = question[i].userOrder === value;
            return answer.title === title ? { ...answer, userOrder: value, isTrue } : answer
        });
        setAnswers(updatedAnswers);
    };

    useEffect(() => {
        // Comprueba si todas las preguntas han sido respondidas
        const allAnsweredCheck = answers.every((answer) => answer.userOrder !== 0);
        setAllAnswered(allAnsweredCheck);

        if (allAnsweredCheck) {
            // Si todas están respondidas, calcula el porcentaje de correctas
            let correctCount = 0;
            answers.forEach((answer, index) => {
                if (question[index].userOrder === answer.userOrder) {
                    correctCount += 1;
                    
                }
            });
            setPercentageCorrect((correctCount / question.length) * 100);
        } else {
            // Si no, asegúrate de que el porcentaje de correctas esté en null
            setPercentageCorrect(null);
        }
    }, [answers]); // Reacciona a cambios en las respuestas

    const { setOffDraggable } = UseBookContainerModule()

    return (
        <>
            <div className="d-flex container-fluid ">
                <div style={divStyle}>
                    <HeaderCard />
                    <div className='scroll-y overflow-y-auto mb-2 px-2' style={{maxHeight:'70px', maxWidth:'435px'}}>
                    <span className=" text-white poppins-light">{instructions}</span><br />
                    </div>
                    {/* <span className=" text-white  mb-1 p-2 ">{"questions"}</span> */}
                    <div className="scroll-y overflow-y-auto mb-2" style={{maxHeight:'150px'}}>
                    {question.map((x, index) => (
                        <>
                            <div className=" row d-flex px-5 fs-7 text-white poppins-light container-fluid mb-2" style={{maxHeight:'75px', maxWidth:'435px'}}>
                                <div className="col-1">
                                    <input style={inputStyle}
                                        min={0}
                                        type="number"
                                        value={answers[index]?.userOrder ?? ''}
                                        onChange={(e) => handleInputChange(x.title, parseInt(e.target.value))}
                                        onMouseEnter={()=> setOffDraggable(true)}
                                        onMouseLeave={()=> setOffDraggable(false)}
                                        />
                                    </div>

                                <div className="col-11 d-flex  scroll-y overflow-y-auto mb-2" style={{maxHeight: '30px', maxWidth: '435px'}}>
                                    <span className="mb-0">{x.title}</span>
                                    {allAnswered && answers[index]?.isTrue ? <i className="bi bi-check  text-success"></i> :
                                    allAnswered && !answers[index]?.isTrue ? <i className="bi bi-x text-danger  "  ></i> : null}
                                </div>
                            </div>
                        </>
                    ))}
                    </div>
                    
                    {allAnswered && <div className='d-flex justify-content-between align-items-center mt-5'>
                        <div>
                            <ButtonTryAgain onAction={tryAgain} />
                        </div>
                        <div>
                            <h3 className='text-center' style={{ fontSize: 10 }}> <span className=" text-white  mb-0 poppins-light">Porcentaje correcto: {percentageCorrect && percentageCorrect.toFixed(2)}%</span></h3>
                        
                        </div>
                            <ButtonSaveExercise
                                isChecked={allAnswered}
                                onNext={()=>{}}
                                onSubmit={handleSubmit}
                                exerciseId={exerciseId}
                            />
                        {/* <div
                            onClick={handleSubmit}
                            className="cursor-pointer">
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '42px', height: '42px' }} alt="Next" />
                        </div> */}
                    </div>}
                    <></>
                </div>
            </div>
        </>
    );
};

export { OrderSentencesItem };