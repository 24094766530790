import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';

type ExerciseFiveProps = {
  
  question: string;
  textarea: string;
  buttonNext: string;
  instruction:string
}

const ExerciseFive: React.FC<ExerciseFiveProps> = ({question,textarea,buttonNext,instruction}) => {
  const divStyle = {
    backgroundColor: '#3f8bff',
    width: '525px',
    height: '290px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px'
  };
    //champ-bold    champ-light  poppins-light poppins-bold 
    return (
    <>  
     <div className="container-fluid" style={{display:'flex'}}>
            <div  style={divStyle}>
            <HeaderCard/>
                  <span className=" text-white cuestion mb-0 poppins-light" style={{marginLeft:'10px', fontSize:'80%',color:'#fffff',marginBottom:'20px'}}>{instruction}</span>
                  <div className="row p-2">
                        <div className="col-6"><span className="text-white cuestion mb-1 p-2 poppins-light">{question}b: What does the author refer to the as'an attempt?:</span></div>
                        <div className="col-6"> <textarea className="form-control rounded-4 text-white" placeholder="Type here.." style={{ background: "#3f8bff", height: "100px"}} id="exampleFormControlTextarea1">{textarea}</textarea></div>
                  </div>
            </div>
            <div className="position-relative" /*onClick={handleCheck}*/>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
        </div>
    </>
    );
};
export { ExerciseFive };
