import React, { useState, MouseEvent, useEffect } from 'react';
import { UseBookContainerModule } from '../../store/main';
import is from 'date-fns/esm/locale/is/index';

interface DraggableModalProps {
  children: React.ReactNode;
}

interface Position {
  x: number;
  y: number;
}

const DraggableModal: React.FC<DraggableModalProps> = ({ children }) => {
  
  const {offDraggable} = UseBookContainerModule();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<Position>({ x: 190, y: 200});
  const [startPosition, setStartPosition] = useState<Position>({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 991);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 425);
      setIsTablet(window.innerWidth <= 991);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseDown = (event: MouseEvent) => {
    if (isMobile && isTablet) return;
    setIsDragging(true);
    setStartPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging || offDraggable ||isMobile || isTablet) return;

    const deltaX = event.clientX - startPosition.x;
    const deltaY = event.clientY - startPosition.y;

    const maxX = window.innerWidth - 100;
    const maxY = window.innerHeight - 100;

    const constrainedX = Math.min(Math.max(position.x + deltaX, 0), maxX);
    const constrainedY = Math.min(Math.max(position.y + deltaY, 0), maxY);

    setPosition({ x: constrainedX, y: constrainedY });
    setStartPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  React.useEffect(() => {
    if (isDragging && !offDraggable && !isMobile && !isTablet) {
      //@ts-ignore
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      //@ts-ignore
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      //@ts-ignore
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, offDraggable, isMobile, isTablet]);
  
  const modalStyle: React.CSSProperties = {
 
    cursor: !offDraggable && !isMobile  ? (isDragging ? 'grabbing' : 'grab') : 'default',
    maxWidth: '90%',
    
  };

  const transformScale = isMobile ? 'scale(0.56)' : isTablet ? 'scale(0.76)' : 'scale(1)';

  return (
    <>
      {(isMobile || isTablet) ? (
      <div
        style={{
          ...modalStyle,
          cursor: 'default',
          position:'absolute',
          marginTop:'40px',
          alignContent: 'center'
        }}
        onMouseDown={!offDraggable ? handleMouseDown : undefined}
      >
        <div
           style={{ transform: transformScale }}
        className='d-flex justify-content-center align-items-center mt-7 '>
          {children}
        </div>
      </div>
    ) : (
      <div
        style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        cursor: !offDraggable ? isDragging ? 'grabbing' : 'grab' : 'default',
        }}
        onMouseDown={!offDraggable ? handleMouseDown : undefined}
      >
        {children}
      </div>
    )}
    </>
  );
};

export {DraggableModal};
