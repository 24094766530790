import React, { useEffect } from 'react';

import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionVerificationTest, QuestionAndAnswer } from '../interfaces';
import useSound from 'use-sound';


type Props = {
    question: QuestionVerificationTest
    handleNext: (x:number) => void
    handleSendAnswer: (x:Answer) => void
    modeQuiz:'RESUME'|'ANSWER'|'THROW_ERROR'|'TITLE'
    setStatus:(string)=>void
    setStep:(number)=>void
    totalQuestions:number
    handSelected:boolean
};

const BasicVocabularyExerciseCompletionItemLeft: React.FC<Props> = ({
        setStep,
        setStatus,
        question,
        handleNext,
        modeQuiz,
        handleSendAnswer,
        totalQuestions,
        handSelected
}: Props) => {
    const { mode } = useThemeMode();
    const [selected, setSelected] = React.useState<Answer>();
    const [hoverString, setHoverString] = React.useState<any|null>(null);
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    // const handleSend = () => {
    //     if(selected?.isTrue){
    //         handleSendAnswer(selected)
    //         setSelected(undefined)
    //     }
    //     if(selected?.isTrue ===false){
     
    //         handleSendAnswer(selected)
    //         setSelected(undefined)
    //     }
    // };
    // const filterTrueAnswers = (answersArray:Answer[]):string[] =>{
    //     let resume = answersArray.find((x)=>x.isTrue === true);
    //     return resume?.title??[]
    // }
    // const filterStringCut =  (paragraph:string):string=>{
    //    // let wordsToRemove: string[] = filterTrueAnswers(question.answers);
    //     const normalizeText = (text: string): string => {
    //         return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    //     };
    //     const cleanedParagraph = normalizeText(paragraph).replace(/[.,'"\?]/g, "");
    //     const paragraphElements = cleanedParagraph.split(/(\s+|\b)/);
    //   //  let normalizedWordsToRemove = wordsToRemove.map(word => normalizeText(word));
    //   //  let total = paragraphElements.map(element => normalizedWordsToRemove.includes(normalizeText(element)) ? "_".repeat(element.length) : element).join('');
    //   //  return total;
    // }
    

    useEffect(() => {
        setSelected(undefined)
    }, [question]);

    useEffect(() => {
        console.log('sahdfhasdfsdf', handSelected)
    }, [handSelected])

    return (
        <>
                <h2 className='champ-light title-basic-vocabulary-completion text mb-5D'
                    style={{
                        color: 'white',
                    }}>
                     {/* {handSelected? question.name : filterStringCut(question.name)} */}
                </h2> 

        </>
    );
};

export { BasicVocabularyExerciseCompletionItemLeft };