import React from 'react'
import {UseEvaluationCourseModule} from '../../screen/store/main'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {EvaluationType} from '../../../admin/evaluation/interfaces'
import {confirmWelcomeDay, FinishWelcome, UpdateTestStatus} from '../../../admin/placement-test/services'
import {useTranslation} from 'react-i18next'
import {UseModeCourseModule} from '../front-page/store/main'
import FeedbackResponses from './feedbackResponses'
import useIsImportantEvaluation from '../../hooks/useIsImportantEvaluation'
import { TaskStatus } from '../../../admin/tasks/interfaces'

const FinalTestRightContent: React.FC = () => {
  const {
    currentTaskId,
    evaluationLesson,
    evaluationResponses,
    setShowEndEvaluation,
    setStepEvaluation,
  } = UseEvaluationCourseModule()
  const {modeViewAnswer} = UseModeCourseModule()
  const isImportantQuiz = useIsImportantEvaluation()

  const navigate = useNavigate()
  const {t} = useTranslation()

  const isWelcomeDay = evaluationLesson?.type === EvaluationType.WELCOME_DAY

  return (
    <div
      className='d-flex flex-column  justify-content-between'
      style={{
        height: '420px',
        borderRadius: '0 50px 50px 0',
        background: 'white',
        position: 'relative',
        border: '1px solid',
        borderColor: '#3e94ff',
        padding: '50px',
        overflowY: 'auto',
      }}
    >
      <div className='d-flex justify-content-end bg-body champ-bold'>
        <h2 style={{color: '#3e94ff'}}>
          {modeViewAnswer ? 'Respuestas correctas' : isWelcomeDay ? 'Tus respuestas' : 'Feedback'}
        </h2>
      </div>
      <FeedbackResponses />
      <div className='d-flex justify-content-end bg-body'>
        <button
          type='button'
          className='cursor-pointer bg-body'
          style={{backgroundColor: 'none', border: 'none'}}
          onClick={async () => {
            if (currentTaskId) {
              if(isImportantQuiz){
                await UpdateTestStatus(currentTaskId, TaskStatus.IN_REVIEW)
              }
              if (evaluationLesson && evaluationLesson.type === EvaluationType.WELCOME_DAY) {
                if (evaluationResponses.every((x) => x.percentage === 100)) {
                  await FinishWelcome(currentTaskId)
                } else {
                  await confirmWelcomeDay(currentTaskId)
                }
                return
              }
            }
            setShowEndEvaluation(false)
            setStepEvaluation(0)
            navigate('/dashboard')
          }}
        >
          <img
            height={30}
            src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_session.svg`)}
            alt=''
          />
        </button>
      </div>
    </div>
  )
}

export default FinalTestRightContent
