import {useState, useEffect} from 'react'
import {KTIcon} from '../../../../../../../_metronic/helpers'
import {ExercisesType} from '../../../../exercises/interfaces'
import {showSimpleAlert} from '../../../../../../commonHooks/alert'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'
import AudioPlayer from '../../../../../book/components/interactions/AudioPlayer'
import {AddAudioTestTeacherResponse} from '../../../../../courses/exercises/audio-test/services'

interface GradesResponses {
  question: number
  percentage: number
  teacherObservation: string
  questionWeight: number
  name: string
  audio: string
}

type Props = {
  setShow: (v: boolean) => void
  exercise: {type: ExercisesType; id: string}
  feedback: any
}

const QualifyAudioModal = ({exercise, setShow, feedback}: Props) => {
  const [percentage, setPercentage] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [grades, setGrades] = useState<GradesResponses[]>()
  const {reload, setReload} = UseQualifyPlacementModule()

  useEffect(() => {
    setPercentage(feedback.percentage)
    let gradesArray: GradesResponses[] = []
    feedback.answers.map((y: any) => {
      const answer = JSON.parse(y)
      let gradesObject: GradesResponses = {
        question: answer.question ?? '',
        percentage: 0,
        teacherObservation:'',
        questionWeight: answer.questionPercentage ?? 0,
        name: answer.name,
        audio: answer.audio
      }
      feedback.response_teacher.map((x: any, index: number) => {
        const response = JSON.parse(x)
        if (response.question === answer.question) {
          gradesObject.percentage = response.percentage
          gradesObject.teacherObservation = response.observation
        }
      })
      gradesArray.push(gradesObject)
    })
    setGrades(gradesArray)
  }, [feedback])

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const response_teacher = grades?.map((grade) => ({
        observation: grade.teacherObservation,
        question: grade.question,
        percentage: grade.percentage,
      }))
      if (response_teacher) {
        await AddAudioTestTeacherResponse(exercise.id, {
          response_teacher: response_teacher,
          percentage: percentage,
        })
      }
      setShow(false)
      setReload(!reload)
    } catch {
      showSimpleAlert({message: 'Error al calificar la tarea', icon: 'error'})
    }
    setIsLoading(false)
  }

  const handlePercentageChange = (key: number, value: number) => {
    console.log("key", key);
    console.log("value", value)
    console.log("grades", grades);
    
    setGrades((prevGrades) =>
      prevGrades?.map((grade) =>
        grade.question === key
          ? {...grade, percentage: value > 100 ? 100 : value < 0 ? 0 : value}
          : {...grade}
      )
    )
  }

  const handleCommentsChange = (key: number, value: string) => {
    setGrades((prevGrades) =>
      prevGrades?.map((grade) =>
        grade.question === key ? {...grade, teacherObservation: value} : grade
      )
    )
  }

  useEffect(() => {
    if (!grades || grades.length === 0) return

    let totalWeight = 0
    let weightedSum = 0

    grades.forEach((grade) => {
      weightedSum += grade.percentage * grade.questionWeight
      totalWeight += grade.questionWeight
    })

    const newPercentage = totalWeight === 0 ? 0 : weightedSum / totalWeight
    console.log("newPercentage", newPercentage);
    
    setPercentage(newPercentage)
  }, [grades])

  return (
    <>
      <div className='modal-body'>
        <div>
          <button
            onClick={() => setShow(false)}
            type='button'
            className='btn btn-icon btn-light btn-sm border-0 me-1'
          >
            <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
          </button>
        </div>
        {grades && grades.length > 0 ? (
          <>
            <div>
              <div className='my-5 w-100'>
                <div className='row justify-content-between me-1'>
                  {grades.map((ans: GradesResponses, index: number) => (
                    <>
                      <div className='row w-100'>
                        <label className='form-label poppins-bold fs-5 my-2 align-content-center'>{`Question ${
                          ans.question
                        }:`}</label>
                        <div className='d-flex col-7'>
                          <textarea
                            disabled
                            value={ans.name}
                            className='ms-2 my-3 ps-5 w-100 align-content-center border-2 border-opacity-25 rounded poppins-light'
                            style={{minHeight: 'fit-content'}}
                            placeholder='No found question...'
                          />
                        </div>
                        <div
                          style={{
                            background: '#f8f9fb',
                            border: '1px solid gray',
                            borderRadius: '25px',
                          }}
                          className='col-5 align-content-center my-3'
                        >
                          <AudioPlayer audioUrl={ans.audio} color={'#ccccc'} />
                        </div>
                      </div>
                      <div className='row w-100 align-items-center justify-content-between'>
                        <label className='form-label poppins-bold fs-5'>Comments: </label>
                        <div className='mb-3 d-flex col-9 align-items-center'>
                          <textarea
                            id={`comments-input-${index}`}
                            value={
                              grades && grades.length > 0 && grades[index].teacherObservation
                                ? grades[index].teacherObservation
                                : undefined
                            }
                            className='ms-2 my-3 ps-5 w-100 align-content-center border-2 border-opacity-25 rounded poppins-light'
                            onChange={(e) =>
                              handleCommentsChange(ans.question, e.target.value)
                            }
                            placeholder='Leave some comments...'
                          />
                        </div>
                        <div className='mb-3 d-flex col-3 align-items-center justify-content-end'>
                          <div className='d-flex align-items-center'>
                            <label className='form-label poppins-bold fs-5 m-auto'>Score: </label>
                            <input
                              id={`score-input-${index}`}
                              min={0}
                              max={100}
                              type='number'
                              value={
                                grades && grades.length > 0 && grades[index].percentage
                                  ? grades[index].percentage
                                  : 0
                              }
                              onChange={(e) => handlePercentageChange(ans.question, parseInt(e.target.value))}
                              className='poppins-light px-0 ms-2 text-end border-1 border-opacity-25 rounded ml-auto'
                              style={{width: '50px'}}
                            />
                            <p className='poppins-bold fs-5 m-auto' style={{color: '#00b6f0'}}>
                              {ans.questionWeight}%
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='px-5'>
                        <hr className='flex-grow'></hr>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <label className='form-label poppins-bold fs-5 m-auto'> Total score: </label>
                <input
                  disabled
                  id={`score-input-total}`}
                  min={0}
                  max={100}
                  type='number'
                  value={percentage}
                  className='poppins-light px-0 ms-2 text-end border-1 border-opacity-25 rounded ml-auto'
                  style={{width: '50px'}}
                />
                %
              </div>
              <button
                type='button'
                onClick={() => handleSubmit()}
                className='btn btn-primary py-2 d-flex justify-content-end'
              >
                {!isLoading && 'Submit'}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </>
        ) : (
          <p className='row fs-2 w-100 my-5 mx-1 text-danger'>Error al cargar la respuesta</p>
        )}
      </div>
    </>
  )
}

export {QualifyAudioModal}
