import React from "react";
import { useParams } from "react-router";
import { LessonsTableList } from "../list";
import { LessonTopWidget } from "./top-widget";
import { Chapter } from "../../../chapter/interfaces";
import { UseChapterOne } from "../../../chapter/services";
import { KTIcon } from "../../../../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { UseLessonsModule } from "../../store/main";

const LessonsDashboard: React.FC = () => {
    const [chapter, setChapter] = React.useState<Chapter>();
    const {id} = useParams();
    const navigate = useNavigate();
    const {setChapterData} = UseLessonsModule();
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UseChapterOne(id??'');
                setChapterData(response);
                setChapter(response)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    return (
        <div className="row">
            <div>
                <a href={`#`} className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => navigate(-1)}>
                    <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
                </a>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
                <LessonsTableList />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
                {chapter && <LessonTopWidget item={chapter}/> }
            </div>
        </div>
    )
};

export {LessonsDashboard}