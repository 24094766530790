import { create } from "zustand"

export interface CellCrossword {
    value: string | number | null;
    isBlack: boolean;
}
export interface ClueCrossword {
    clueNumber: number;
    direction: 'across' | 'down';
    startRow: number;
    startCol: number;
    answer: string;
    clueText: string;
}

export interface ClueResponse {
    clueNumber: number; 
    correct: boolean;
    value: string;
    direction: 'across' | 'down'; 
}

interface InterfaceModuleExerciseCrossword {
    instruction: string;
    initialGrid: CellCrossword[][];
    initialClues: ClueCrossword[];
    hasResponse: boolean;
    answer: ClueResponse[];
    exerciseId?: string;
    resetData:()=>void;
    setAnswer:(value: ClueResponse[])=>void;
    setHasResponse:(value: boolean) => void;
    setQuestionsCross:(
        exerciseId: string,
        instruction: string,
        clues: ClueCrossword[], 
        numRows:number, 
        numCols:number)=>void;
}
export const UseModuleExerciseCrossword = create<InterfaceModuleExerciseCrossword>((set) => ({
    instruction: '',
    initialGrid: [],
    initialClues: [],
    answer: [],
    hasResponse: false,
    setQuestionsCross:(exerciseId, instruction, clues, rows, cols)=>{
        const initialGrid:CellCrossword[][] = [];
        for (let i = 0; i < rows; i++) {
            const row:CellCrossword[] = [];
            for (let j = 0; j < cols; j++) {
                row.push({ value: null, isBlack: true });
            }
            initialGrid.push(row);
        }
        set({initialClues: clues, initialGrid, exerciseId, instruction});
    },
    setAnswer:(value)=>{
        set({answer: value, hasResponse: true});
    },
    setHasResponse:(value)=>{
        set({hasResponse: value});
    },
    resetData:()=>{
        set({hasResponse: false, answer: []})
    }
}));