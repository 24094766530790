import React, { useState, useEffect } from 'react';
 
import moment from 'moment'; // Importa la biblioteca Moment.js
import { KTIcon } from '../../../../../../_metronic/helpers';

type DateFormat = {
    day:string
    hour:string
    year:string
    month:string
};

type Props = {
};
const TodayDashboard: React.FC<Props> = ({

}) => {
  const [currentDateTime, setCurrentDateTime] = useState<DateFormat>();
  useEffect(() => {
    const intervalId = setInterval(() => {
 
      setCurrentDateTime({
        day:moment().format('dddd').toString(),
        hour:moment().format('hh:mm ss').toString(),
        year:moment().format('YYYY').toString(),
        month:moment().format('MMMM').toString(),
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []); 

  return (
    <a href={'#'} className={`card hoverable card-xl-stretch mb-xl-8 d-md-none d-lg-block`} style={{background:'#2d30e1',borderRadius:30}}>
      <div className='card-body'>
        <div className={`text-center champ-light fw-semibold text-white`} style={{fontSize:30}}>{currentDateTime?.hour}</div>
        <div className={`text-center champ-light text-white fw-bold fs-2  mb-0`}>{currentDateTime?.day}</div>
        <div className={`text-center champ-light text-white `}style={{fontSize:15}}>{currentDateTime?.month}</div>
      </div>
    </a>
  );
};

export { TodayDashboard };