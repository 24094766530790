import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { api } from "../../../../auth/services/auth.services";
import { ClueResponse } from "../store/main";


const { token, logout, authChecked } = useAuth.getState();

interface ResponseCrossword {
    description: string
    isActive: boolean
    answer: Array<ClueResponse>
    percentage: number
    exercises: string
    task: string
}

export const AddCrossworodResponse = async (data: ResponseCrossword): Promise<any> => {
    try {
      const response: any = await api.post(
        '/crossword-response',
        {
          ...data,
        },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }