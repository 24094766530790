import React from 'react';

import { UseHistoryWritingModule } from './store';
import { BeyondReadingItemOne } from './components/beyond-reading-one';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';

type Props = {
    userMode?: ModeTypeExercise
};

const BeyondReadingWritingExerciseOne: React.FC<Props> = ({userMode}) => {
    let {
        questions,
        step,
        setAnswer,
    } = UseHistoryWritingModule();

    return (
        <>
            <BeyondReadingItemOne
                userMode={userMode}
                question={questions[step]}
                handleSendAnswer={(e) => {}}
            />
        </>
    );
};

export { BeyondReadingWritingExerciseOne };