import { useQuery, useQueryClient } from '@tanstack/react-query'
import { UseFindPaginatedTrailClass } from '../../../../me-booking-active/services'
import moment from 'moment'

export function useTrailClassStudentState(day: Date){
  return  useQuery({ queryKey: ['trail-active-dashboard'], queryFn: () => 
    UseFindPaginatedTrailClass({
        before: moment(day).format('YYYY-MM-DD').toString(),
        after: moment(day).endOf('week').format('YYYY-MM-DD').toString(),
      })
  })
}