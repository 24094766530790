//import { Country, DataAllCountryResponse, DataPaginateCountryResponse } from '../interfaces/index'
import useAuth from '../../../api/main'

import { catchServerError } from '../../../hooks/useCatchError'
import { useStore } from 'zustand'
 
import { api } from '../../auth/services/auth.services'
import { showSimpleAlert } from '../../../commonHooks/alert'

import { UseMyRoadmapModule } from '../../profile/components/my-road-step/store'
import { UseModeCourseModule } from '../../courses/components/front-page/store/main'

const { token, logout, authChecked } = useAuth.getState()
const { settings,settingChecked } = UseMyRoadmapModule.getState()
const {modeCourseChecked} = UseModeCourseModule.getState();
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export interface BookingFind {
    after:string
    before:string;
    isSuspense?:boolean;
    limit?:number;
    currentPage?:number;
  }
export const UseStudentDashboardCount = async (): Promise<any> => {
  try {
    // let responseSetting = await settingChecked();
        modeCourseChecked();
        let url = `/dashboard/student`;
        const response: any = await api.get(
          `${url}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data
  } catch (e) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseTeacherDashboardCount = async (): Promise<any> => {
    try {
      // let responseSetting = await settingChecked();
          modeCourseChecked();
          let url = `/dashboard/teacher`;
          const response: any = await api.get(
            `${url}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return response.data
    } catch (e) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }
  export const UseContractDashboardCount = async (): Promise<any> => {
    try {
      // let responseSetting = await settingChecked();
          modeCourseChecked();
          let url = `/dashboard/contract`;
          const response: any = await api.get(
            `${url}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return response.data
    } catch (e) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }
  export const UseAppointmentDashboardCount = async (before,after): Promise<any> => {
    try {
          modeCourseChecked();
          let url =  `/dashboard/appointment?after=${after}&before=${before}`
          const response: any = await api.get(
            `${url}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return response.data
    } catch (e) {
      //@ts-ignore
    //   if (e.response.status === 401) {
    //     logout()
    //   }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }

  export const UseTrailClassDashboardCount = async (before,after): Promise<any> => {
    try {
          modeCourseChecked();
          let url =  `/dashboard/trail-class?after=${after}&before=${before}`
          const response: any = await api.get(
            `${url}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return response.data
    } catch (e) {
      //@ts-ignore
    //   if (e.response.status === 401) {
    //     logout()
    //   }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }

  export const UseByActivityCount = async (before,after,activity): Promise<any> => {
    try {
          modeCourseChecked();
          let url =  `/dashboard/trail-class-by-activity-teacher?after=${after}&before=${before}&activity=${activity}`
          const response: any = await api.get(
            `${url}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          return response.data
    } catch (e) {
      //@ts-ignore
    //   if (e.response.status === 401) {
    //     logout()
    //   }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }

  