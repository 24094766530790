import React, { useCallback, useEffect } from 'react';
import { BasicVocabularyExerciseCompletionItem } from './components/item';
import { UseBasicVocabularyCompletionModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';
// import { LeftCardContent } from '../../../../components/common/left-card-content/indext';

type Props = {
    titleContent: string;
    descriptionContent: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
};
const BasicVocabularyCompletionFullScreenExercise: React.FC<Props> = ({ renderType, titleContent, descriptionContent, colorSecondary }) => {
    let { 
        questions,
        setStep, 
        step, 
        setAnswer, 
        status, 
        falseAnswer, 
        trueAnswer, 
        setStatus,
        startQuiz,
    } = UseBasicVocabularyCompletionModule();
    
    useEffect(()=>{
        console.log(status);
    },[])

    const handleSetStep = useCallback((value: number) => {
        setStep(value);
    }, [setStep]);


    return (
        <>
            {renderType && renderType !== 'left' &&
                <>
                {(status !== 'RESUME' && status !== 'TITLE') ?
                    <BasicVocabularyExerciseCompletionItem
                                question={questions[step]}
                                handleSendAnswer={(e) => setAnswer(e)}
                                modeQuiz={status}
                                totalQuestions={questions.length}
                                setStep={(e) => handleSetStep }
                                description={descriptionContent}
                            />
                : status === 'RESUME' ?
                    <BasicVocabularyExerciseResume
                        total={questions.length}
                        correct={trueAnswer.length}
                        setStatus={(e) => setStatus(e)}
                        correctAnswer={trueAnswer}
                        falseAnswer={falseAnswer}
                    />
                : status === 'TITLE' && 
                    <IntroBasicVocabulary 
                        title={titleContent}
                        instructions={descriptionContent}
                        startQuiz={()=>startQuiz()}
                    />
                }
            </>}
            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>
        </>
    );
};

export default React.memo(BasicVocabularyCompletionFullScreenExercise);