/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {EvaluationForm} from '../forms'
import {UseEvaluationModule} from '../../store/main'
import { FormItemEvaluation } from '../forms/formItem'
import { EvaluationItemList } from '../list/itemList'
import { EvaluationFormByLesson } from '../forms/formByLesson'
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const {itemSelected, mode,setModal,resetData, type} = UseEvaluationModule();
  const submit = () => {
    window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        {mode === 'ADD' && <h2>Crear Evaluacdasion</h2>}
        {mode === 'EDIT' && <h2>{(type === 'ITEM_ADD') ? 'Crear Item de la evaluación' : type === 'ITEM_UP' ? 'Editar Item de la evaluación' : 'Editar evaluación'}</h2>}
        {mode === 'VIEW' && <h2>Ver evaluación</h2>}
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>{
          setModal(false)
          resetData();
          }}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      {typeof type === 'undefined' && 
        <EvaluationForm mode={mode} />
      }
      {type === 'LESSON' && 
        <EvaluationFormByLesson mode={mode}/>
      }
      {type === 'ITEM_ADD' || type === 'ITEM_UP' ? 
        <FormItemEvaluation mode={type}/> : <></>
      }
      {type === 'LIST' && 
        <EvaluationItemList items={itemSelected?.evaluation_item} />
      }
    </Modal>,
    modalsRoot
  )
}

export {CreateModal}
