import React, { useEffect } from 'react';

import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionHistoryWriting, QuestionAndAnswer } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { MovieWritingData } from '../store';
import { ResetStepByExercises } from '../../utils';
import { AddMovieWritingResponse } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { useNavigate } from 'react-router-dom';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import useAuth from '../../../../../api/main';
import { countWords } from '../../history-wrinting/services';
import { ButtonNextFullScreen } from '../../../utils/buttonNextFullScreen';


type Props = {
    movie:MovieWritingData
    question: QuestionHistoryWriting
    handleNext: (x:number) => void
    handleSendAnswer: (x:Answer) => void
    modeQuiz:'RESUME'|'ANSWER'|'THROW_ERROR'|'TITLE'
    falseAnswer:QuestionAndAnswer
    setStatus:(string)=>void
    setStep:(number)=>void
    totalQuestions:number;
    handleChangeDescription:(x:string)=>void
};

const BasicVocabularyExerciseItem: React.FC<Props> = ({
    question,
    movie,
    handleSendAnswer,
    handleChangeDescription
}: Props) => {
    
    const { mode } = useThemeMode();
    const {evaluationLesson,
        questionsEvaluation,
        currentTaskId,
    } = UseEvaluationCourseModule();

    const {typeUser} = useAuth();

    const [selected, setSelected] = React.useState<Answer>();
    const [text, setText] = React.useState<string>("")
    const [error, setError] = React.useState<string | null>()
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const [success] = useSound(toAbsoluteUrl(`/media/audio/success.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const [wrong] = useSound(toAbsoluteUrl(`/media/audio/wrong.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    const handleSend = () => {
        if(selected){
            handleSendAnswer(selected)
            setSelected(undefined)
        }
    };
    

    useEffect(() => {
        setSelected(undefined)
    }, [question]);

    const handleSubmit = async () => {
        ResetStepByExercises();
        if (typeUser === 'STUDENT' && currentTaskId && questionsEvaluation?.exercise) {
            // if(movie.title === '' || movie.description === '' || movie.genre === '' || movie.duration === '' || movie.mainCharter === ''){
            //     showSimpleAlert({icon:'error', message: `Rellene todos los campos`})
            //     return
            // }
            try {
                await AddMovieWritingResponse({
                    exercises: questionsEvaluation?.exercise?.id??'xx',
                    task: currentTaskId??'xx',
                    title: movie.title,
                    description: movie.description,
                    genre: movie.genre,
                    duration: movie.duration,
                    mainCharter: movie.mainCharter,
                    rating: movie.rating.toString(),
                    isActive: true,
                    isReviewed: false,
                    isPending: true
                });
            } catch (e) {
                showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'});
            }
        }
    };

    const handleChangeText = (value: string) => {
        const wordCount = countWords(questionsEvaluation?.exercise?.movie_writing[0]?.minimum??1, questionsEvaluation?.exercise?.movie_writing[0]?.limit??100, value)

        if (wordCount.state === "allow") {
            setText(value)
            setError(null)
        } else if (wordCount.state === 'max'){
            setError(`${wordCount.count} extra words`)
            setText(value)
        } else if(wordCount.state === 'min'){
            setText(value)
            setError(`you need ${wordCount.count} more words`)
        }
    }

    return (
        <>  
            <div className=" card-basic-vocabulary"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                        border: `1px solid`,
                        borderColor: '#2d30e1',
                        position: 'relative'
                    }
                }
                >
                <textarea
                    className='poppins-light'
                    value={text}
                    style={{
                        borderRadius: '30px',
                        borderColor: '#3e94ff',
                        color:error? "gray":  evaluationLesson?.colorPrimary??'#3e94ff',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        fontSize:15,
                        padding: '30px',
                        width:"100%",
                        height:"90%",
                        outline: 'none',
                        resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
                    }}
                    placeholder="Type here..."
                    onChange={(e => {
                        handleChangeDescription(e.target.value)
                        handleChangeText(e.target.value)
                    })}
                />
                {
                    error &&
                    <div className='text-primary poppins-bold'>
                        {error}
                    </div>
                }
                {/* { (error === null && text !== "" &&(movie.title !== '' && movie.description !== '' && movie.genre !== '' && movie.duration !== '' && movie.mainCharter !== '')) && */}
                    <ButtonNextFullScreen onAction={handleSubmit}/>
                {/* // */}
            </div>
        </>
    );
};

export { BasicVocabularyExerciseItem };