import { Route, Routes } from 'react-router-dom';
import { StudentActionTrailFreezingTableList } from '../trail-freezing';
import { StudentActionBookingActiveTableList } from '../booking';
import { StudentActionReportTableList } from '../report';
import { PromotionHistoryTableList } from '../promotion-history/components/list';
import { StudentStatusManagerTableList } from '../studentStatusManager/components/list';

const StudentActionsPage = () => (
  <Routes>
    <Route path='/promotion-history/:id' element={<PromotionHistoryTableList />}/>
    <Route path='/status-manager/:id' element={<StudentStatusManagerTableList />}/>
    <Route path='/trail-freezing/:id' element={<StudentActionTrailFreezingTableList />} />
    <Route path='/booking/:id' element={<StudentActionBookingActiveTableList />}/>
    <Route path='/report/:id' element={<StudentActionReportTableList />}/>
  </Routes>
);

export { StudentActionsPage };
