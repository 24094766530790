import React, { useState } from 'react';
import MyNext from '../img/NEXT_btn 1.svg';
import HeaderCard from '../ui/HeaderCard';
import { UseModuleExerciseNine } from './store/main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';


const ExerciseNine: React.FC = () => {
    const { Arrayquestion, instruction,checked, setChecked} = UseModuleExerciseNine();
    const [selectedValues, setSelectedValues] = useState<string[]>(Arrayquestion.map(() => ""));
    const [validationResults, setValidationResults] = useState<(string | JSX.Element | null)[]>(Arrayquestion.map(() => null)); // Array para almacenar resultados de validación
    const correctImage = <FontAwesomeIcon icon={faCheck} style={{color: "#34f000", width:'15px',height:'15px'}} className='animate__bounce animate__animated '/>;
    const incorrectImage = <FontAwesomeIcon icon={faXmark} style={{color: "#ff0000",width:'15px',height:'15px'}} className='animate__bounce animate__animated'/>;
    
    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        height: 'auto',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 38px 20px'
    };

    const handleSelectChange = (index: number, value: string) => {
        const newSelectedValues = [...selectedValues];
        newSelectedValues[index] = value;
        setSelectedValues(newSelectedValues);
    };

    const handleCheck = () => {
        if (selectedValues.includes("")) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "te falta una opcio",
                showConfirmButton: false,
                timer: 1500,
              });
              
            return;
        }
        const newValidationResults = selectedValues.map((value, index) => {
            return value === Arrayquestion[index].value ? correctImage : incorrectImage;
        });
        setValidationResults(newValidationResults);
        setChecked(true);
    };

    React.useEffect(() => {
        console.log('valid date', selectedValues)
      }, [selectedValues])

    return (
        <>
            <div className="d-flex container-fluid">
                <div style={divStyle}>
                    <HeaderCard />
                    <span className=" text-white p-4 fs-8 mb-0 poppins-light">{instruction}</span><br />
                    {Arrayquestion.map(({ idWord, title }, index) => (
                        <div key={idWord} className=" row d-flex px-5 fs-8 text-white px-9 py-1  poppins-light">
                            <div className="col-9 d-flex ">
                                <li>{title}</li>
                            </div>
                            <div className="col-2">
                                <select
                                    name=""
                                    className='rounded-4 border-0 '
                                    style={{ background: '#D0FF00' }}
                                    id="d-inline"
                                    onChange={(e) => handleSelectChange(index, e.target.value)}
                                    disabled={checked}
                                >
                                    <option value="">--</option>
                                    <option value="A">A</option>
                                    <option value="P">P</option>
                                    <option value="R">R</option>
                                
                                </select>
                            </div>
                            <div className='col-1'>
                            {validationResults[index] !== null && (validationResults[index])}
                            </div>

                        </div>
                        
                    ))}
                </div>
                <div className="position-relative" onClick={handleCheck}>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
                </div>
                <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
            </div>
        </>
    );
};

export { ExerciseNine };
