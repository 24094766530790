import React, { useEffect } from 'react';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { UseTrueFalseModule } from '../store';
import { Answer, QuestionTrueFalse } from '../interfaces';
import { AddTrueOrFalseResponse } from '../services';
import './index.css';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
interface ResponseAnswer {
    answer: Answer[],
    percentage: number
}

type Props = {
    userMode?: ModeTypeExercise
    responses?: Answer[];
    questions: QuestionTrueFalse[]
    handleSendAnswer: (x: ResponseAnswer) => void
}
const TrueOrFalseBookItem: React.FC<Props> = ({
    userMode,
    questions,
    handleSendAnswer,
    responses,
}) => {
    const {
        lessonData,
        stepBookItem,
        currentTaskId,
        currentItemBook,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
    } = UseBookStudentModule();
    const [accuracy, setAccuracy] = React.useState<number | null>(null);
    const [answers, setAnswers] = React.useState<Answer[]>([]);
    const [allAnswered, setAllAnswered] = React.useState(false);
    const { answer, percentage, resetData, exerciseId, instructions } = UseTrueFalseModule();
    const { modeViewAnswer } = UseModeCourseModule();
    const divStyle = {
        backgroundColor: '#3f8bff',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 20px 20px',
        maxWidth: '509px'
    };
    const arrayLetters = [
        "Mr and Mrs Gates are from USA.",
        "The Gates like helping people.",
        "The Gates live in Medina, Washington.",
        "Melissa isn't wife."
    ];

    const handleAnswer = (order: number, answers_response: 'TRUE' | 'FALSE' | 'NOT_GIVEN') => {
        if (allAnswered) return;
        const question = questions.find(q => q.order === order);
        if (!question) return; // Si no se encuentra la pregunta, no hacer nada

        const existingAnswerIndex = answers.findIndex(ans => ans.order === order);
        const newAnswer: Answer = {
            title: question.title,
            order: question.order,
            answer: question.answer, // Suponiendo que quieras mantener el valor original aquí
            answers_response, // Esta es la respuesta seleccionada
        };

        if (existingAnswerIndex >= 0) {
            // Si ya existe una respuesta para esta pregunta, actualizarla
            const updatedAnswers = [...answers];
            updatedAnswers[existingAnswerIndex] = newAnswer;
            setAnswers(updatedAnswers);
        } else {
            // Si es una nueva respuesta, agregarla
            setAnswers([...answers, newAnswer]);
        }
    };

    useEffect(() => {
        if (answer && answer.length > 0) {
            setAnswers(answer);
        }
    }, [answer]);

    useEffect(() => {
        if (questions && modeViewAnswer) {
            setAnswers(questions.map((x) => ({ ...x, answers_response: x.answer })));
        }
    }, [questions, modeViewAnswer]);

    useEffect(() => {
        // Verificar si el número de respuestas es igual al número de preguntas y que todas las preguntas tienen una respuesta
        const isAllAnswered = questions.length > 0 && answers.length === questions.length;
        if (isAllAnswered) {
            calculateAccuracy()
        }
        setAllAnswered(isAllAnswered);
    }, [answers, questions]); // Dependencias: se ejecuta cada vez que cambian las respuestas o las preguntas

    const finderSelected = (order: number, answers_response: 'TRUE' | 'FALSE' | 'NOT_GIVEN'): boolean => {
        return answers.some((x) => x.order === order && x.answers_response === answers_response)
    }
    const finderSelectedIsTrue = (order: number): boolean => {
        return answers.some((x) => x.order === order && (x.answers_response === x.answer))
    }

    const calculateAccuracy = () => {
        const correctAnswersCount = answers.reduce((acc, answer) => {
            const question = questions.find(q => q.order === answer.order);
            return acc + (question && answer.answers_response === question.answer ? 1 : 0);
        }, 0);

        const accuracyPercentage = (correctAnswersCount / questions.length) * 100;
        setAccuracy(accuracyPercentage);
        if (answers) {
            handleSendAnswer({
                percentage: accuracyPercentage,
                answer: answers
            })
        }
    };

    const resetExercise = () => {
        setAllAnswered(false);
        resetData();
        setAnswers([]);
    };

    const handleSubmit = async () => {
        // ResetStepByExercises();
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId ?? '')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        } 
        if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
            try {
                const response = await AddTrueOrFalseResponse({
                    percentage: percentage,
                    isActive: true,
                    answer: answer,
                    exercises: exerciseId,
                    task: currentTaskId ?? 'xx'
                });
                onChangeTaskByAnswer(response, 'true_false');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
            }
        }
    }
    return (
        <>
            <div className="container-fluid" style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <HeaderCard />
                    <div className='scroll-y overflow-y-auto mb-2' style={{ maxHeight: '70px', maxWidth: '435px' }}>
                        <p className=" text-white  mb-0 poppins-light fs-9 p-2" >{instructions}</p>
                    </div>
                    <div className="d-flex text-white p-2 content">
                        <div className='row w-100 px-5'>
                            {questions.map((x) => (
                                <>
                                    <div className='col-8 poppins-light fs-8 scroll-y overflow-y-auto mb-2' style={{ maxHeight:'70px'}} >
                                        <li style={{ marginBottom: '5px' }}>{x.title}</li>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <button type='button'
                                            onClick={() => handleAnswer(x.order, 'TRUE')}
                                            className=" btn px-0 py-0 ml-3 rounded rounded-circle    "
                                            style={
                                                finderSelected(x.order, 'TRUE') ?
                                                    { fontSize: '11px', marginBottom: '3px', marginRight: 5, border: '1px solid #d0ff00', height: '25px', width: '25px', background: '#d0ff00', color: '#2d30e1' } :
                                                    { fontSize: '11px', marginBottom: '3px', marginRight: 5, border: '1px solid #3e94ff', height: '25px', width: '25px', background: 'white', color: '#2d30e1' }
                                            }
                                        >
                                            T
                                        </button>
                                        <button type='button'
                                            onClick={() => handleAnswer(x.order, 'FALSE')}
                                            className="   btn px-0 py-0 ml-3 rounded rounded-circle   "
                                            style={
                                                finderSelected(x.order, 'FALSE') ?
                                                    { fontSize: '11px', marginBottom: '3px', marginRight: 5, border: '1px solid #d0ff00', height: '25px', width: '25px', background: '#d0ff00', color: '#2d30e1' } :
                                                    { fontSize: '11px', marginBottom: '3px', marginRight: 5, border: '1px solid #3e94ff', height: '25px', width: '25px', background: 'white', color: '#2d30e1' }
                                            }
                                        >
                                            F
                                        </button>
                                        <button type='button'
                                            onClick={() => handleAnswer(x.order, 'NOT_GIVEN')}
                                            className=" btn px-0 py-0 ml-3 rounded rounded-circle  text-center"
                                            style={
                                                finderSelected(x.order, 'NOT_GIVEN') ?
                                                    { fontSize: '11px', marginBottom: '3px', backgroundColor: '#d0ff00', border: '1px solid #d0ff00', height: '25px', width: '25px', color: '#2d30e1' } :
                                                    { fontSize: '11px', marginBottom: '3px', border: '1px solid #3e94ff', height: '25px', width: '25px', background: 'white', color: '#2d30e1' }
                                            }
                                        >
                                            NG
                                        </button>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    {allAnswered && <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <ButtonTryAgain onAction={resetExercise} />
                        </div>
                        <div
                        >
                            <ButtonSaveExercise
                                isChecked={allAnswered}
                                onNext={() => { }}
                                onSubmit={handleSubmit}
                                exerciseId={exerciseId}
                            />
                        </div>
                        {/* <div
                onClick={handleSubmit}
                className="cursor-pointer">
                <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '42px', height: '42px' }} alt="Next" />
            </div> */}
                    </div>}
                </div>
                {/* <img className="" src={MyX} style={{ width: '40px', height: '40px' }}></img> */}
            </div>
        </>)
};
export { TrueOrFalseBookItem }