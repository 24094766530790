import {Route, Routes} from 'react-router-dom'
import { ActivityDetails } from '../components/details'
import { ActivityTableList } from '../components/list'
import { ActivityDashboard } from '../components/dashboard'
import { ActivityTableListWorkshop } from '../workshop/list'


const ActivityPage = () => (
  <Routes>
      <Route path='/details' element={<ActivityDetails />} />
      <Route path='/:id' element={<ActivityDashboard />} />
      <Route path='/' element={<ActivityTableListWorkshop/>}/>
  </Routes>
)

export {ActivityPage}
