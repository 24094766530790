import useAuth from "../../../../api/main";
import { showSimpleAlert } from "../../../../commonHooks/alert";
import { api } from "../../../auth/services/auth.services";
import { AddTaskManual } from "../interfaces";

const { token, logout, authChecked } = useAuth.getState();

interface TrailClassTransferStudentsData {
  appointmentOrigin: string;
  appointmentDestiny: string;
  students: string[];
}

export const UseGetAllTrailClassByAppointment = async (id:string): Promise<any> => {
    try {
      const response = await api.get(`/trail-class/by-appointment/${id}`,
      {
        headers: {
          Authorization:`Bearer ${token}`,
        },
      })
      return response.data
    } catch (e) {
      //@ts-ignore
      catchServerError(e.response)
    }
  }

export const TrailClassTransferStudents = async (data: TrailClassTransferStudentsData): Promise<any> => {
  try {
    const response = await api.patch(`/trail-class/transfer-students`,
    {
      ...data
    },
    {
      headers: {
        Authorization:`Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UseGetInformationTrailClass = async (id:string): Promise<any> => {
  try {
    authChecked()
    const response: any = await api.get(
      `/trail-class/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const AssistanceStudentTrailClass = async (id:string): Promise<any> => {
  try {
    await authChecked()
    const response: any = await api.patch(
      `/trail-class/assistant-students/${id}`,
      {
        
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const AssistantTeacherTrailClass = async (id:string,data:any): Promise<any> => {
  try {
    await authChecked()
    const response: any = await api.patch(
      `/trail-class/teacher-assistant/${id}`,
      {
        assistantTeacher:data.assistantTeacher,
        teacherObservation:data.teacherObservation,
        lateArrival:data.lateArrival
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UpdateStarsValueStudent = async (id:string, stars: number): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/trail-class/star-value/${id}`,
      {
        stars
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UpdateScoreStudent = async (id:string): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/trail-class/student-value/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseTrailClassPaginated = async (id:string, after: Date, before: Date): Promise<any> => {
  try {
    await authChecked()
    
    const response: any = await api.get(
      `/trail-class/paginated?isActive=${1}&limit=${10000}&page=${1}&where=&root=${id}&before=${before}&after=${after}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseTrailClassByStudentWithCourse = async (idSetting: string, idCourse?: string, idChapter?:string): Promise<any> => {
  try {
    // await authChecked()
    let url = `/trail-class/student?student_settings=${idSetting}&course=${idCourse}`;
    if (idChapter) {
      url += `&chapter=${idChapter}`
    }
    const response: any = await api.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const AddTaskByStudentSettingsManual = async (data: AddTaskManual): Promise<any> => {
  try {
    const response: any = await api.post(
      `/tasks`,
      {
        ...data
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}