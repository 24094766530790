import React, { useEffect } from 'react';


import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import useSound from 'use-sound';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { useNavigate } from 'react-router-dom';
import { AddSpeakOutResponse } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import SpeakHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/SpeakOutHeaderCard';
import WritingDownHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/WritingDownHeaderCard';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { ModeSpeakOut, SpeakOutAnswer, UseSpeakOutModule } from '../store';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ExerciseTimeAndWriting } from './writingAndTime';
import { countWords } from '../../history-wrinting/services';

type Props = {
    studentAnswer?: SpeakOutAnswer
    userMode?: ModeTypeExercise
    question: string
    type: ModeSpeakOut;
    handleSendAnswer: (x: any) => void
    setStudentAnswer: (value: SpeakOutAnswer) =>void
};
const divStyle = {
    backgroundColor: '#3f8bff',
    width: '625px',
    height: '400px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px'
};
const BasicVocabularyExerciseItem: React.FC<Props> = ({
    studentAnswer,
    userMode,
    question,
    type,
    handleSendAnswer,
    setStudentAnswer,
}: Props) => {
    
    const {
        lessonData, 
        stepBookItem, 
        currentTaskId, 
        currentItemBook,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer
    } = UseBookStudentModule();

    const {tips, instructions, limit, min, exerciseId} = UseSpeakOutModule();

    const [selected, setSelected] = React.useState<any>();
    const [error, setError] = React.useState<string | null>(null);
    const [descriptionData, setDescriptionData] = React.useState<string>('');
    const [titleData, setTitleData] = React.useState<string>('');
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const [success] = useSound(toAbsoluteUrl(`/media/audio/success.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const [wrong] = useSound(toAbsoluteUrl(`/media/audio/wrong.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        setStudentAnswer({
            title: titleData,
            description: descriptionData
        })
        play();
    };
    const handleSend = () => {
        if (selected?.isTrue) {

            handleSendAnswer(selected)
            setSelected(undefined)
        }
        if (selected?.isTrue === false) {

            handleSendAnswer(selected)
            setSelected(undefined)
        }
    };


    useEffect(() => {
        setSelected(undefined)
    }, [question]);

    useEffect(()=>{
        if(studentAnswer){
            setDescriptionData(studentAnswer.description)
            setTitleData(studentAnswer.title)
        } else {
            setDescriptionData("")
            setTitleData("")
        }
    },[studentAnswer])

    const handleSubmit = async () => {
        // ResetStepByExercises();
        if (error) {
            return;
        }
        if (lessonData && lessonData.evaluation && 
        lessonData.evaluation.evaluation_item){
            addCompletedExerciseId(exerciseId ??"")
            if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId??'')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        } 
        if(userMode === 'STUDENT' && currentTaskId && exerciseId){
            try {
                const response = await AddSpeakOutResponse({
                    name: titleData??'', 
                    description: descriptionData??'',
                    isActive: true,
                    isReviewed: false,
                    isPending: true,
                    exercises: exerciseId, 
                    task: currentTaskId??'', 
                });
                onChangeTaskByAnswer(response, 'speak_out');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
            }
        }
    };

    const handleChangeText = (value: string) => {
        const words = countWords(min, limit, value);
        setDescriptionData(value)
        if (words.state === 'allow') {
            setError(null)
        } else if (words.state === 'max') {
            setError(`${words.count} palabras extra.`);
        } else if (words.state === 'min') {
            setError(`Necesitas mínimo ${words.count} palabras mas.`);
        }
    };

    return (
        <>
        
            {/* // <div className="container-fluid" style={{ display: 'flex' }}>
            //     <div style={divStyle}>
            //         <SpeakHeaderCard />
            //         <span className=" text-white cuestion mb-0 poppins-light" style={{ marginLeft: '10px', fontSize: '80%', color: '#fffff', marginBottom: '20px' }}>{question}</span>
            //         <div className="row p-2">
            //         {type ==='SPEAK_OUT'&& <div className="col-12">
            //                 <input
            //                     value={titleData}
            //                     onChange={(e) => setTitleData(e.target.value)}
            //                     className='poppins-light'
            //                     type='text'
            //                     name="text"
            //                     style={{
            //                         borderRadius: '30px',
            //                         borderColor: 'white',
            //                         background:"#3f8bff",
            //                         color: "white",
            //                         borderWidth: '2px',
            //                         borderStyle: 'solid',
            //                         fontSize: 15,
            //                         padding: '10px',
            //                         width: "100%",
            //                         height: "100%",
            //                         outline: 'none',
            //                         resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
            //                     }}
            //                     placeholder="Type here..."
            //                 />
            //             </div>}
            //             <div className="col-12 d-flex flex-column">
            //                 <textarea
            //                     value={descriptionData}
            //                     onChange={(e) => {
            //                         handleChangeText(e.target.value)
            //                     }}
            //                     className='poppins-light'
            //                     rows={6}
            //                     style={{
            //                         borderRadius: '30px',
            //                         borderColor: type ==='SPEAK_OUT'?"#3f8bff":'white',
            //                         background:"#3f8bff",
            //                         color: "white",
            //                         borderWidth: '2px',
            //                         borderStyle: 'solid',
            //                         fontSize: 15,
            //                         padding: '10px',
            //                         width: "100%",
            //                         height: "100%",
            //                         outline: 'none',
            //                         resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
            //                     }}
            //                     placeholder="Type here..."
            //                 />
            //                 {error && 
            //                     <div style={{color: 'red'}}>
            //                         {error}
            //                     </div>
            //                 }
            //             </div>
            //         </div>
            //     </div>
            //     <div className="position-relative " style={{ position: 'relative' }}>
            //         <div className="position-absolute bottom-0 end-0 cursor-pointer"
            //             onClick={async (e) => {
            //                 e.preventDefault();
            //                 handleSubmit();
            //             }}
            //             style={{ marginRight: '20px', marginBottom: '12px', position: 'absolute' }}>
            //             <img height={30} onClick={handleClick} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/white/NEXT_btn_one.svg`)} alt='' />
            //         </div>
            //     </div>
            // </div> : */}
            {exerciseId&&
                <ExerciseTimeAndWriting
                    tips={tips}
                    error={error}
                    mode={type}
                    instruction={instructions}
                    onSubmit={handleSubmit}
                    description={descriptionData}
                    setDescription={(e) => handleChangeText(e)}
                    exerciseId={exerciseId}
                />
            }
        </>
    );
};

export { BasicVocabularyExerciseItem };