
 

export const catchServerError = (response:any) => {
    switch (response.status) {
      case 401: {
    
        window.location.replace(
            "/"
          );
        break;
      }
      case 403: {

        break;
      }
      default: {

      }
    }
  };