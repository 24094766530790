import { useState, useRef, useEffect } from 'react';
import { FileMultipleAdd } from '../services';

import DetailFiles from './detail-files';
import UploadFilesLottie from '../../../components/common/lottie/upload-files';
 

interface ImageFile {
    url: string,
    id: string
}

interface Props {
    files_: ImageFile[];
    onChangeFile: (file: ImageFile[]) => void;
    onChangeLoading: (isLoading: boolean) => void;
    onDeleteFile: (index: number) => void;
}

const MultipleFileInput: React.FC<Props> = ({ files_, onChangeFile, onChangeLoading, onDeleteFile }) => {
    const [loadMode, setLoadMode] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
 
    const handleFileUpload = async () => {
        setLoadMode(true);
        try {
            onChangeLoading(true);
            if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
                const formData = new FormData();
                for (let i = 0; i < fileInputRef.current.files.length; i++) {
                    let file_ = fileInputRef.current.files[i];
                    formData.append(`files[${i}]`, file_);
                }
                const response = await FileMultipleAdd(formData);
                setLoadMode(false);
                if (response && response.files.length > 0) {
                    await changeFile(response.files);
                }
                onChangeLoading(false);
            } else {
                setLoadMode(false);
                alert('No files selected or fileInputRef not found');
            }
        } catch (e) {
            setLoadMode(false);
            console.error(e);
        }
    };

    const changeFile = (file: ImageFile[]) => {
        onChangeFile(file);
    };

    const deleteFile = (index: number) => {
        onDeleteFile(index);
    };


    return (
        <div className="mt-3">
        

            <div className="border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                <div className="flex flex-wrap px-4">
                    {!loadMode && <div className='row '>
                        {files_.map((item, index) => (<>

                            <div className='col-6'>
                                <DetailFiles
                                    index={index}
                                    onDelete={(e) => deleteFile(e)}
                                    icon={item.url}
                                    title='Finance'
                                    description='7 files'
                                />
                            </div>

                        </>))}

                    </div>}
                </div>
                <div className="px-4 pb-4 flex items-center cursor-pointer relative">

                    {!loadMode ? (
                        <span className="text-primary mr-1">...</span>
                    ) : (
                        <UploadFilesLottie />
                    )}
                    <input
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        type="file"
                        multiple
                        className="w-full h-full top-0 left-0 absolute "
                    />
                </div>
            </div>
        </div>
    );
};

export default MultipleFileInput;
