export const AvatarTranslateEn = {
    FANTASY:"Fantasy",
    SCIENCE_FICTION:"Science Fiction",
    ANIMALS:"Animals",
    PROFESSIONS:"Professions",
    SPORTS:"Sports",
    HISTORY_CULTURE:"History & Culture",
    SUPERHEROES_VILLAINS:"Superheroes & Villains",
    NATURE:"Nature",
    MUSIC_ENTERTAINMENT:"Music & Entertainment",
    LIFESTYLE_FASHION:"Lifestyle Fashion",
    COMICS_ANIME:"Comics & Anime",
    TECHNOLOGY_CYBERPUNK:"Technology & Cyberpunk",
    GHOSTS_SUPERNATURAL:"Ghosts & Supernatural",
    VEHICLES_MACHINES:"Vehicles Machines",
    ABSTRACT_ARTISTIC:"Abstract Artistic",
    ABSENTEEISM: 'Absenteeism'
  }