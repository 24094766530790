import React from 'react';
import { HeaderBook } from '../header';
import { ToolbarBook } from '../toolbar';
import { RenderBook } from '../render-book';
import LoggedStudentsModal from '../loggedStudents';
import { UseBookTeacherModule } from '../../modules/class/store/main';
import { useLocation } from 'react-router-dom';
import { RenderItemExercise } from '../interactions/RenderItemExercise';

type Props = {}; 

const ContainerBookClass: React.FC<Props> = () => {
  
  const {
    lessonData,
    currentsItemsTest,
    statePage,
    currentPage,
    setStatePage,
    setCurrentsItemsTest,
    setCurrentPage,
    setDeleteCurrentItem,
    resetCurrentItems
  } = UseBookTeacherModule();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const appointment = searchParams.get('appointment') ?? '';

  React.useEffect(() => {
    setCurrentPage(1, appointment);
  }, []);

  React.useEffect(() => {

    window.addEventListener('beforeunload', resetCurrentItems);
    window.addEventListener('popstate', resetCurrentItems);
    window.addEventListener('hashchange', resetCurrentItems);

    return () => {
      window.removeEventListener('beforeunload', resetCurrentItems);
      window.removeEventListener('popstate', resetCurrentItems);
      window.removeEventListener('hashchange', resetCurrentItems);
    };
    
  }, [resetCurrentItems]);

  return (<>
    <div>
      <HeaderBook
        logoCourse={lessonData ? lessonData.chapter.path.logo.location : ''}
        colorCourse={lessonData ? lessonData.chapter.path.colorBase : ''}
        lessonName={lessonData ? lessonData.name : 'Michigan Master'}
      />
      <div className='d-none d-lg-flex'>
        <RenderBook
          numberPage={currentPage}
          numberRenderPage={statePage}
          pagesBook={lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []}
        />
      </div>
      <div className='d-flex d-lg-none'>
        <RenderBook
          numberPage={currentPage}
          numberRenderPage={1}
          pagesBook={lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []}
        />
      </div>
      <ToolbarBook 
        onChangeStatePage={(e) => setStatePage(e ? 2 : 1)}
        statePage={statePage === 1 ? false : true}
        currentPage={currentPage}
        setCurrentPage={(e) => setCurrentPage(e, appointment)}
        mode='TEACHER'
      />
      <LoggedStudentsModal />

      <RenderItemExercise
        typeUser='TEACHER'
        exercisesLesson={lessonData?.evaluation?.evaluation_item??[]}
        currentExercises={currentsItemsTest}
        onDeleteExercise={(e) => setDeleteCurrentItem(e)}
        setExercise={(e) => setCurrentsItemsTest(e)}
        addClickedInteractionsId={() => {}}
      />

    </div>
  </>)
};

export { ContainerBookClass };