import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {MultipleSelection} from '../../interfaces'
import { MultipleSelectionAdd, MultipleSelectionUpdate } from '../../services'
import { UseMultipleSelectionModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { MultipleSelectionItemIE, MultipleSelectionQuestionIE, MultipleSelectionType } from '../../../../../../../courses/exercises/multiple-selection/interfaces'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'
import { BooleanCell } from '../../../../../../../../components/common/BooleanCell'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  isActive: boolean;
  description:string;
  questions: MultipleSelectionQuestionIE[];
  type: MultipleSelectionType;
  level: TraditionalLevelEnum;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const MultipleSelectionForm: React.FC<Props>  = (props:  Props) => {
  const {id} = useParams();
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseMultipleSelectionModule();
  const [dataOption, setDataOption] = useState<MultipleSelectionItemIE>({
    title: '',
    order: 1,
    isTrue: false
  });
  const [dataQuestion, setDataQuestion] = React.useState<MultipleSelectionQuestionIE>({
    title: '',
    order: 1,
    options: []
  });
  const [modeQuestion, setModeQuestion] = React.useState<{question: boolean, option: boolean}>({
    question: true,
    option: false
  });

  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        questions: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.questions.map((x) => JSON.parse(x)) ?? [] : [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.A1 : TraditionalLevelEnum.A1,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? MultipleSelectionType.TWO_OPTIONS : MultipleSelectionType.TWO_OPTIONS
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await MultipleSelectionAdd({
              ...formData,
              exercises: id??'',
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['multiple-selection'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  MultipleSelectionUpdate({
                ...formData,
                exercises: id??'',
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['multiple-selection'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const addQuestion = () => {
    const findRepeatedOrder = values.questions.find(x => x.order === dataQuestion.order);
    if (findRepeatedOrder) return showSimpleAlert({message: 'Orden repetido', icon: 'error'}); 
    setFieldValue('questions', [...values.questions,{...dataQuestion}]);
    setDataQuestion({
      title: '',
      order: 1,
      options: []
    })
  };

  const addOption = () => {
    const questionToUpdate = values.questions.find(x => x.order === dataQuestion.order);
    if (!questionToUpdate) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' }); 
    const findRepeatedOrder = questionToUpdate.options.find(x => x.order === dataOption.order);
    if (findRepeatedOrder) 
      return showSimpleAlert({message: 'Orden repetido', icon: 'error'}); 
    const findRepeatedIsTrue = questionToUpdate.options.some(x => x.isTrue);
    if (findRepeatedIsTrue && dataOption.isTrue) 
      return showSimpleAlert({message: 'Solo se puede una respuesta verdadera', icon: 'error'});

    const updatedOptions = [...questionToUpdate.options, dataOption];
    const updatedQuestion = { ...questionToUpdate, options: updatedOptions };

    const updatedQuestions = values.questions.map(question => {
        if (question.order === dataQuestion.order) {
            return updatedQuestion;
        } else {
            return question;
        }
    });

    setFieldValue('questions', updatedQuestions);
    setDataOption({
      title: '',
      order: 1,
      isTrue: false
    });
  };

  const deleteQuestion = (order: number) => {
    const findOptions = values.questions.find(x => x.order === order);
    if (findOptions && findOptions.options.length > 0) 
      return showSimpleAlert({message: 'No se puede eliminar esta pregunta porque aun tiene opciones', icon: 'error'});
    const newQuestions = values.questions.filter(x => x.order !== order);
    
    setFieldValue('questions', [...newQuestions]);
  };

  const deleteOption = (order: number) => {
    const questionToUpdate = values.questions.find(x => x.order === dataQuestion.order);
    if (!questionToUpdate) 
      return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' });

    const newOptions = questionToUpdate.options.filter(x => x.order !== order);
    const updatedQuestion = { ...questionToUpdate, options: newOptions };

    const updatedQuestions = values.questions.map(question => {
        if (question.order === questionToUpdate.order) {
            return updatedQuestion;
        } else {
            return question;
        }
    });

    setFieldValue('questions', updatedQuestions);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Tipo</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode === 'VIEW'}
            >
              {Object.keys(MultipleSelectionType).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>

          <div className="d-flex">
            <hr className="my-auto flex-grow-1"/>
            <div className="px-4">Sentences</div>
            <hr className="my-auto flex-grow-1"/>
          </div>

          {modeQuestion.option &&
            <div className='card-footer d-flex justify-content-start py-6 px-9'>
              <button type='button' 
                onClick={() => setModeQuestion({
                  question: true,
                  option: false
                })} 
                className='btn btn-primary' 
                disabled={loading ||mode==='VIEW'}>
                Questions
              </button>
            </div>}

          {modeQuestion.question ? <>
            <div className='mb-10 col-6 row'>
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title'}
                value={dataQuestion.title}
                onChange={(e) => setDataQuestion({ ...dataQuestion, title: e.target.value })}
              />
              
              <div className='col-6'>
                <label className='form-label mb-3 fw-bold'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order'}
                  value={dataQuestion.order}
                  onChange={(e) => setDataQuestion({ ...dataQuestion, order: parseInt(e.target.value) })}
                />
              </div>

              <div className='col-6 py-6 px-9'>
                <button type='button' onClick={addQuestion} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {x.title}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {/* {x.color} */}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> {
                              setModeQuestion({option: true, question: false});
                              setDataQuestion(x);
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fa-solid fa-plus" style={{ color: 'blue', fontSize: 15 }}></i>
                          </button>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteQuestion(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </> : modeQuestion.option && <>
            <div className='mb-10 col-6 row'>
              <div className='col-12 d-flex'>
                <label className='form-label mb-3 me-3 fw-bold'>is true ?</label>
                <div className="form-check form-switch">
                  <input
                    checked={dataOption.isTrue}
                    onChange={(e) => setDataOption(prev =>({...prev, isTrue: e.target.checked}))}
                    className="form-check-input" 
                    type="checkbox" 
                    id="flexSwitchCheckDefault" />
                </div>
              </div>
              <label className='form-label mb-3 fw-bold'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'title_option'}
                value={dataOption.title}
                onChange={(e) => setDataOption({ ...dataOption, title: e.target.value })}
              />
              
              <div className='col-6'>
                <label className='form-label mb-3 fw-bold'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order_option'}
                  value={dataOption.order}
                  onChange={(e) => setDataOption({ ...dataOption, order: parseInt(e.target.value) })}
                />
              </div>

              <div className='col-6'>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='button' onClick={addOption} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                    {!loading && 'Save'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th>Value</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.find(x => x.order === dataQuestion.order)
                    ?.options.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.title}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className=''>
                        <BooleanCell value={x.isTrue} />
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteOption(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {MultipleSelectionForm}
