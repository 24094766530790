import { create  } from "zustand";

interface ColumnItem {
    idWord: string;
    title: string;
    value: string;
    titlee?: string; // Opcional si solo se utiliza en columnRight
}

interface ArrayQuestion {
    map: any;
  
    columnLeft: ColumnItem[];
    columnRight: ColumnItem[];
}

interface InterfaceModuleExercise {
    checked: boolean;
    Arrayquestion: ArrayQuestion;
    instruction: string;
    inputValues: any[];
    setChecked: (value: boolean) => void;
    setInputValuesMo: (value: any[]) => void;
}

export const UseModuleExerciseNineteen = create<InterfaceModuleExercise>((set)=> ({
    checked:false,
    instruction: 'look at the sentences from the text and clasify them into',
    
    Arrayquestion : {
        columnLeft: [
            { idWord: 'word_1', title: "karen", value: 'c' },
            { idWord: 'word_3', title: "alex", value: 'e' },
            { idWord: 'word_2', title: "jose", value: 'd' },
            { idWord: 'word_4', title: "juan", value: 'a' },
            { idWord: 'word_5', title: "david", value: 'b' }
        ],
        columnRight: [
            { idWord: 'word_1', title: "a. juan", value: 'a' },
            { idWord: 'word_3', title: "c. karen", value: 'c' },
            { idWord: 'word_2', title: "b. david", value: 'b' },
            { idWord: 'word_4', title: "d. jose", value: 'd' },
            { idWord: 'word_5', title: "e. alex", value: 'e' }
        ],
        map: undefined
    },

        inputValues: [],
        setInputValuesMo(value) {
            set({inputValues: value})
          },

        setChecked:(value) => {
            set({checked: value})
        },

    }))