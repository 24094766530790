 

export interface MultipleSelectionItemIE {
    title:string
    order:number
    isTrue:boolean
}
 
export interface MultipleSelectionQuestionIE {
    title :string
    order:number
    options:MultipleSelectionItemIE[]
}
 
 
export interface MultipleSelectionQuestionAnswerIE {
    title:string
    order:number
    text?:string
    isTrue:boolean
}

export enum MultipleSelectionType {
    GROUP_OPTIONS = 'GROUP_OPTIONS',
    TWO_OPTIONS = 'TWO_OPTIONS'
}

export const quiz :MultipleSelectionQuestionIE[]=
[
    {
        title:"Esta es la prgutna uno ",
        order:1,
        options:[
            {
                title:"uno",
                order:1,
                isTrue:false
            },
            {
                title:"dosxx",
                order:2,
                isTrue:true
            }
        ]
    },
    {
        title:"Esta es la pregunta dos",
        order:2,
        options:[
            {
                title:"uno",
                order:1,
                isTrue:false
            },
            {
                title:"3sssss",
                order:3,
                isTrue:true
            }
        ]
    },
    {
        title:"Esta es la pregunta tres",
        order:3,
        options:[
            {
                title:"doshhhh",
                order:2,
                isTrue:false
            },
            {
                title:"3jjjjj",
                order:3,
                isTrue:true
            }
        ]
    },
    {
        title:"Esta es la pregunta cuatro",
        order:4,
        options:[
            {
                title:"tema uno ",
                order:1,
                isTrue:false
            },
            {
                title:"tema 3",
                order:3,
                isTrue:true
            }
        ]
    }
]