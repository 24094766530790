import React from 'react';
import { CardHeaderModule } from '../../../../components/common/widgets/statistics/cardHeaderModule';
import { useThemeMode } from '../../../../../_metronic/partials';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../api/main';
import moment from 'moment';
import { UseNotificationModule } from '../../store/main';
import GenericPaginator from '../../../../components/common/Paginator';
import { useQueryClient } from '@tanstack/react-query';
import { useNotificationState } from '../../hooks/useNotificationRepo';

type Props = {};

const NotificationListGlobal: React.FC<Props> = () => {
  const {mode} = useThemeMode();
  const queryClient = useQueryClient();
  const {
    paginator,
    limit,
    currentPage,
    isActive,
    setLimit,
    setCurrentPage,
  } = UseNotificationModule();
  const {data, isLoading} = useNotificationState();
  const {t} = useTranslation();
  const { notifications, resetNotification } = useAuth();

  const changePage = (number: number) => {
    setCurrentPage(number);
  };
  const changeLimit =(number:number)=>{
      setLimit(number);
  };

  React.useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['notification'] });
  }, [isActive, currentPage, limit]);

  return(<>
    <CardHeaderModule
        title={t('NOTIFICATIONS')} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/back_blue':'white/back_white'}.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/notifications_one_blue':'white/notifications_white'}.svg`}
        background={'#E5E6FA'} 
        colorTitle={'#2d30e1'}
    />
    <div className='mt-5'>
      <div className='card'>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-bordered'>
              <thead>
                <tr></tr>
              </thead>
              <tbody className='mt-3'>
                {data && data?.result?.length > 0 && <>
                  {data?.result?.map((x,i) => (
                    <tr key={i}
                      className={`min-w-700px mt-3 ms-0 me-0 row fs-5`}
                      style={{ 
                        borderRadius: '50px', 
                        backgroundColor:mode==='light'? '#E5E6FA' : '',
                        color:mode==='light'?'#2d30e1':'#E5E6FA',
                        border: mode==='dark'?'1px solid #E5E6FA':''
                      }}
                    >
                      <td className='col-2 border-0'>
                        <div className='d-flex align-items-center text-start ms-15 symbol symbol-45px symbol-circle'>
                          <span
                            style={{background:'purple', fontSize:20}}
                            className={`symbol-label bg-light-  text-white  fw-bold poppins-light`}
                          >
                            {x.name.charAt(0)}
                          </span>
                        </div>
                      </td>
                      <td className='col-5 d-flex align-items-center border-0'>
                        <span className='poppins-bold fs-4 text-start'>{x.name}</span>
                      </td>
                      <td className='col-3 d-flex align-items-center border-0 position-relative'>
                        <span className='poppins-light fs-4 text-start'>{t(x.type)}</span>
                      </td>
                      <td className='col-2 d-flex align-items-center border-0'>
                        <span className='poppins-light fs-4'>
                          {moment(x.createdAt).format('DD/MM/YYYY')}
                        </span>
                      </td>
                    </tr>
                  ))}
                </>}
              </tbody>
            </table>
            <GenericPaginator
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
          </div>
        </div>
      </div>
    </div>
  </>)
};

export {NotificationListGlobal};