export interface DataPaginateCertificateItemResponse {
    status:  string;
    message: string;
    data:    DataCertificateItem;
}

export interface DataAllCertificateItemResponse {
    status:  string;
    message: string;
    data:    CertificateItem[];
}

export interface DataCertificateItem {
    id?: string;
    date: string;
    numberResolution: number;
    name: string;
    resolutionText: string;
    user_id?: string;
    dni: string;
    conf_certificate:any
    code: string;
    prefix: string;
    status:string
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface CertificateItem  {
    id?: string;
 
    conf_certificate:any
    status:string
    date: string;
    numberResolution: number;
    name: string;
    resolutionText: string;
    user_id?: string;
    dni: string;
    code: string;
    prefix: string;

    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: CertificateItem[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum CertificateItemStatus {
    PENDING = 'PENDING',
    PUBLIC = 'PUBLIC',
    NULLED = 'NULLED'
}