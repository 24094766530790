import React, { useEffect } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useTasksState } from '../../hooks/useTasksRepo'
import { UseTasksModule } from '../../store/main'
import { Tasks } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import { CardHeaderModule } from '../../../../../components/common/widgets/statistics/cardHeaderModule'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { setTypeToSpanish } from '../../../user/utils/setTypeToSpanish';
import moment from 'moment'
type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const TasksTableList: React.FC<Props> = ({ }) => {
  const { mode } = useThemeMode();
  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setWhere,
    setToAdd
  } = UseTasksModule()
  const { data, isLoading } = useTasksState();

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });


  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }


  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['tasks'] })
  }, [isActive, where, currentPage, limit])
  return (<>

    <CardHeaderModule
      title={'My tasks list'}
      leftIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'white/back_white' : 'dark-modules/back_purple'}.svg`}
      svgIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'white/support_white' : 'dark-modules/support_purple'}.svg`}
      background={'#a500b7'}
      colorTitle={'#FFFFFF'}
    />


      <div className='mt-5'>
        <div className='card content-support' style={{border: mode==='light'?'':'1px solid #FFFFFF'}}>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table'>
                <thead className=''>
                  <tr className={`fw-bold bg-light`}>
                    <th className='poppins-bold fs-5 min-w-800px text-start row'>
                      <span className='col-4'>Theme</span>
                      <span className='col-2'>is complete ?</span>
                      <span className='col-2'>Type</span>
 
        
                      <span className='col-2'>Status</span>
                      <span className='col-2'>Fecha</span>
                    </th> 
                  </tr>
                </thead>
                <tbody className=''>
                  {data?.result?.map((x:Tasks) => (
                    <tr
                      key={x.id}
                      className={`mt-3 row cursor-pointer fs-5`}
 
                      onClick={() => setSelectedToView(x)}
                    >
                      <td className='col-4 ps-9'>
                        <i className='fa fa-tasks' style={{ color:mode==='light'?'#2d30e1':'#E5E6FA', fontSize: 18 }}>
                        </i>
                            {x.type==='NORMAL'?x.lesson:x.placement_test??''}
                      </td>
                      <td className='col-2 ps-0'>
                        <span className='poppins-light fw-bold ps-5 d-flex align-items-center'>
                          {x?.isComplete?'yes':'no'}
                        </span>
                      </td>
                      <td className='col-2 ps-0'>
                        <span className='poppins-light fw-bold ps-5 d-flex align-items-center'>
                          {x?.type }
                        </span>
                      </td>
                      <td className='col-2'>
                        <span className='poppins-light fw-bold'>{setTypeToSpanish(x?.status)}</span>
                      </td>
                      <td className='col-2'>
                        <span className='poppins-light fw-bold'>{moment(x?.createdAt).format('DD/MM/YYYY')}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            <GenericPaginator
              limit={limit}
              currentPage={paginator.current_page}
              items={paginator.result.length}
              total={paginator.total}
              totalPage={paginator.total_page}
              changePage={changePage}
              changeLimit={changeLimit}
            />
            </div>
          </div>
        </div>
      </div>

 
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { TasksTableList }
