import React, { useEffect } from 'react';

import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionHistoryWriting, QuestionAndAnswer, HistoryWritingResponseExercise } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { useNavigate } from 'react-router-dom';
import { OneTaskByFindOneTask } from '../../basic-vocabulary/services';
import { ResetStepByExercises } from '../../utils';
import { AddHistoryWritingResponse, countWords } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import useAuth from '../../../../../api/main';
import { FinishWelcome } from '../../../../admin/placement-test/services';
import { EvaluationType } from '../../../../admin/evaluation/interfaces';
import { ButtonNextFullScreen } from '../../../utils/buttonNextFullScreen';
import { HistoryWriting } from '../../../../admin/exercises/templates-exercise/history-writing/interfaces';
import { UseHistoryWritingModule } from '../store';


type Props = {
    question: HistoryWriting
    handleSendAnswer: (x:HistoryWritingResponseExercise) => void
    step: number;
    setStep:(number)=>void
    totalQuestions:number
};

const HistoryWritingExerciseItem: React.FC<Props> = ({
        setStep,
        question,
        step,
        handleSendAnswer,
        totalQuestions
}: Props) => {
    const { mode } = useThemeMode();
    const {typeUser} = useAuth();
    const {evaluationLesson,
        questionsEvaluation,
        currentTaskId,
    } = UseEvaluationCourseModule();
    const {answerStudent} = UseHistoryWritingModule();

    const [text, setText] = React.useState<string>("")
    const [error, setError] = React.useState<string | null>()

    const handleSubmit = async() => {
        ResetStepByExercises();
        if (typeUser === 'STUDENT' && currentTaskId && questionsEvaluation?.exercise) {
            try {
                await AddHistoryWritingResponse({
                    exercises: questionsEvaluation?.exercise?.id??'xx',
                    task: currentTaskId??'xx',
                    answers: answerStudent,
                    isActive: true,
                    isReviewed: false,
                    isPending: true
                })
            } catch (e) {
                showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
            }
        }
    }

    const handleChangeText = (value: string) => {
        const wordCount = countWords(question?.minimum??1, question.limit??100, value)
        
        if (wordCount.state === "allow") {
            setText(value)
            setError(null)
        } else if (wordCount.state === 'max'){
            setError(`${wordCount.count} extra words`)
            setText(value)
        } else if(wordCount.state === 'min'){
            setText(value)
            setError(`you need ${wordCount.count} more words`)
        }
    }

    const addResponse = () => {
        handleSendAnswer({
            response: text,
            questions: question.questions
        })
        setText('');
        setStep(step + 1);
    };

    return (
        <>  
            <div className=" card-basic-vocabulary"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                        border: `1px solid`,
                        borderColor: '#2d30e1',
                        position: 'relative'
                    }
                }
                >
                <textarea
                    value={text}
                    onChange={(e => {
                        handleChangeText(e.target.value)
                    })}
                    className='poppins-light'
                    style={{
                    borderRadius: '30px',
                    borderColor: '#3e94ff',
                    color:error? "gray":  evaluationLesson?.colorPrimary??'#3e94ff',
                    borderStyle: 'solid',
                    fontSize:15,
                    padding: '30px',
                    width:"100%",
                    height:"90%",
                    outline: 'none',
                    resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
                    }}
                    placeholder="Type here..."
                />
                {
                    error &&
                    <div style={{color: "red"}}>
                        {error}
                    </div>
                }
            {(totalQuestions === step + 1) ? //(text !== '' && error === null) &&
                <ButtonNextFullScreen onAction={handleSubmit}/>
            : 
            <div
                onClick={()=> addResponse()}
                className='arrow-right-true-false cursor-pointer'
                style={{ position: 'absolute' }}>
                <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
            </div>
            
            }
            </div>
        </>
    );
};

export { HistoryWritingExerciseItem };