export const attendedParser =(item:string)=>{
    return item==='YES'?'SI':'NO'
}

export const interestParser=(interest: string): string=> {
    switch (interest) {
        case 'VERY_LOW':
            return 'Muy Bajo';
        case 'LOW':
            return 'Bajo';
        case 'MODERATE':
            return 'Moderado';
        case 'HIGH':
            return 'Alto';
        case 'VERY_HIGH':
            return 'Muy Alto';
        default:
            return 'Valor Desconocido';
    }
}

export const levelParse = (level: string): string=> {
    switch (level) {
        case 'PRE_A1':
            return 'Pre A1';
        case 'A1':
            return 'A1';
        case 'A2':
            return 'A2';
        case 'B1':
            return 'B1';
        case 'B2':
            return 'B2';
        case 'C1':
            return 'C1';
        default:
            return 'Nivel Desconocido';
    }
}

export function originParser(origen: string): string {
    switch (origen) {
        case 'CAMPAIGN':
            return 'Campaña';
        case 'EXECUTIVE':
            return 'Ejecutivo';
        case 'ORGANIC':
            return 'Orgánico';
        case 'REFERRED':
            return 'Referido';
        default:
            return 'Origen Desconocido';
    }
}

export function extractIDs(arr: any[]): string[] {
    return arr.map(obj => obj.id);
}

export function extractOrder(arr: any[]): number[] {
    return arr.map(obj => obj.order)
}