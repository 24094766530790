import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {ThemeModeComponent} from '../../../assets/ts/layout'
import { ThemeModeType, useThemeMode } from '../theme-mode/ThemeModeProvider'
import i18n from '../../../../app/hooks/i18n'
 

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const LangSwitcher = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <p className='my-auto text-muted'>{i18n.language}</p>
    
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
        <a
          href='#'
          className='menu-link px-3 py-2'
          onClick={() => changeLanguage('en')}
        >
          <i className="fa-solid fa-language mr-2"></i> En 
        </a>
      </div>
      <div className='menu-item px-3 my-0'>
        <a
          href='#'
          className='menu-link px-3 py-2'
          onClick={() => changeLanguage('es')}
        >
           <i className="fa-solid fa-language  mr-2"></i> Es 
        </a>
      </div>
      </div>
      {/* end::Menu */}
    </>
  )
}

export {LangSwitcher}
