import React from 'react';
import MyLogo from '../img/BEYONDTHEREADING_btn1.svg';
import MyX from '../img/EQUIS_btn1.svg';
import NexBtnThow from '../ui/NextBtnOne';
import HeaderCard from '../ui/HeaderCard';

type Props = {
    instruction: string;
    questions: string;   
}
const ExerciseTwentyTwo: React.FC<Props> = ({instruction, questions}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',width: '525px',
        height: '290px',borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
    return(<>
        <div className="d-flex container-fluid">
            <div style={divStyle}>
            <HeaderCard/>
                <span className=" text-white fs-8 poppins-light mb-0">{instruction}</span><br/>
                <span className=" text-white fs-8 poppins-light mb-1 p-2">{questions}</span>
                <textarea className=" text-white fs-8 form-control rounded-4" style={{ background: "#3f8bff"}}  id="exampleFormControlTextarea1" ></textarea>

            </div>
            <NexBtnThow/>
            <img className="" src={MyX} style={{ width: '40px', height: '40px' }}></img>
        </div>
   </>)
};

export {ExerciseTwentyTwo}