import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import FileUploadComponent from '../../../../../components/common/SingleFile';
import FileVerificationComponent from '../../../../../components/common/SingleFile/FileVerification';
import { ContentVerification, TypeFileContentRequest } from '../../../interface';
import { UseAutoVerification } from '../../../store/main';
import { VoiceRecorderInput } from '../../../../../components/common/voice-recorder';
import { VerificationVoiceInput } from '../../../../../components/common/voice-recorder/VerificationVoice';
import { RenderQuestionsVerification } from './RenderQuestions';

type Props = {
  onSubmit:()=>void;
  prevStep:()=>void;
}

const StepQuestions: FC<Props> = ({onSubmit, prevStep}) => {
  const [stepAudio, setStepAudio] = React.useState<number>(0);

  const [audios, setAudios] = React.useState<ContentVerification[]>([]);

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Foto</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div className='row'>
          <RenderQuestionsVerification
            onClickStep={(e, t) => 
              setStepAudio(t === 'addition' ? stepAudio + e : stepAudio - e)
            }
            onChangeContent={(e) => setAudios(prev => [...prev, e])}
          />
        </div>  
      </div>
      {/* <StepInfoForm onSubmit={onSubmit}/> */}
    </div>
  )
}

export {StepQuestions}