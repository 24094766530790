import {Route, Routes} from 'react-router-dom'
import { ExercisesDetails } from '../components/details'
import { ExercisesTableList } from '../components/list'
import { SimpleSelectionQuestionsTableList } from '../templates-exercise/simple-selection-questions/components/list'
import { SelectionQuestionCompletionTableList } from '../templates-exercise/simple-question-completion/components/list'
import { DragDropQuestionsTableList } from '../templates-exercise/drag-drop-questions/components/list'
import { TrueOrFalseTableList } from '../templates-exercise/true-or-false/components/list'
import { HistoryWritingTableList } from '../templates-exercise/history-writing/components/list'
import { MovieWritingTableList } from '../templates-exercise/movie-writing/components/list'
import { HighlightWordTableList } from '../templates-exercise/highlight-word/components/list'
import { WritingCheckListTableList } from '../templates-exercise/writing-check-list/components/list'
import { TrueOptionTableList } from '../templates-exercise/book/true-option/components/list'
import { CrosswordTableList } from '../templates-exercise/book/crossword/components/list'
import { AudioInteractionTableList } from '../templates-exercise/book/audio-interaction/components/list'
import { BoosterTableList } from '../templates-exercise/book/booster/components/list'
import { CoordinatesTableList } from '../templates-exercise/book/coordinates/components/list'
import { MultipleSelectionTableList } from '../templates-exercise/book/multiple-selection/components/list'
import { GroupWordTableList } from '../templates-exercise/book/group-word/components/list'
import { CompleteSentencesTableList } from '../templates-exercise/book/complete-sentences/components/list'
import { CheckWritingTableList } from '../templates-exercise/book/check-writing/components/list'
import { SpeakOutTableList } from '../templates-exercise/speak-out/components/list'
import { AudioTestTableList } from '../templates-exercise/audio-test/components/list'
import { CorrectSentenceTableList } from '../templates-exercise/book/correct-sentence/components/list'
import { TrueWordTableList } from '../templates-exercise/book/true-word/components/list'
import { OrderSentenceTableList } from '../templates-exercise/book/order-sentence/components/list'

const ExercisesPage = () => (
  <Routes>
      <Route path='/simple-selection-completion/:id' element={<SelectionQuestionCompletionTableList />} />
      <Route path='/multiple-selection/:id' element={<MultipleSelectionTableList />} />
      <Route path='/correct-sentences/:id' element={<CorrectSentenceTableList />} />
      <Route path='/complete-sentences/:id' element={<CompleteSentencesTableList />} />
      <Route path='/writing-check-list/:id' element={<WritingCheckListTableList />} />
      <Route path='/audio-interaction/:id' element={<AudioInteractionTableList />} />
      <Route path='/simple-selection/:id' element={<SimpleSelectionQuestionsTableList />} />
      <Route path='/order-sentences/:id' element={<OrderSentenceTableList />} />
      <Route path='/history-writing/:id' element={<HistoryWritingTableList />} />
      <Route path='/highlight-word/:id' element={<HighlightWordTableList />} />
      <Route path='/movie-writing/:id' element={<MovieWritingTableList />} />
      <Route path='/check-writing/:id' element={<CheckWritingTableList />} />
      <Route path='/true-options/:id' element={<TrueOptionTableList />} />
      <Route path='/coordinates/:id' element={<CoordinatesTableList />} />
      <Route path='/group-words/:id' element={<GroupWordTableList />} />
      <Route path='/true-words/:id' element={<TrueWordTableList />} />
      <Route path='/true-false/:id' element={<TrueOrFalseTableList />} />
      <Route path='/audio-test/:id' element={<AudioTestTableList />} />
      <Route path='/crossword/:id' element={<CrosswordTableList />} />
      <Route path='/speak-out/:id' element={<SpeakOutTableList />} />
      <Route path='/drag-drop/:id' element={<DragDropQuestionsTableList />} />  
      <Route path='/booster/:id' element={<BoosterTableList />} />
      <Route path='/details' element={<ExercisesDetails />} />
      <Route path='/' element={<ExercisesTableList />} />
  </Routes>
)

export {ExercisesPage}