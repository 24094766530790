export interface DataPaginateSettingsResponse {
    status:  string;
    message: string;
    data:    DataSettings;
}

export interface DataAllSettingsResponse {
    status:  string;
    message: string;
    data:    Settings[];
}

export interface DataSettings {
    id?: string;
    description?:string;
    value:number;
    alternativeValue?:number;
    code:string;
    type: SettingEnumType;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Settings  {
    id?: string;
    description?:string;
    value:number;
    alternativeValue?:number;
    code:string;
    type: SettingEnumType;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: Settings[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}


export enum SettingEnumType {
    NUMBER_SANCTION_STUDENT = 'NUMBER_SANCTION_STUDENT',
    NUMBER_WEEKLY_UNIT_RESERVATIONS_STUDENT = 'NUMBER_WEEKLY_UNIT_RESERVATIONS_STUDENT',
    NUMBER_DAYS_VALIDATE_ABSENTEEISM_BEFORE_SANCTIONING_STUDENT = 'NUMBER_DAYS_VALIDATE_ABSENTEEISM_BEFORE_SANCTIONING_STUDENT',
    NUMBER_ABSENTEEISM_ALLOWED_SANCTIONING_STUDENT = 'NUMBER_ABSENTEEISM_ALLOWED_SANCTIONING_STUDENT',
    NUMBER_WEEKLY_ACTIVITY_BOOKING_STUDENT = 'NUMBER_WEEKLY_ACTIVITY_BOOKING_STUDENT',
    NUMBER_HOURS_BEFORE_CANCELING_RESERVATION = 'NUMBER_HOURS_BEFORE_CANCELING_RESERVATION',
    MAXIMUM_NUMBER_HOURS_ADVANCE_TO_MAKE_RESERVATIONS = 'MAXIMUM_NUMBER_HOURS_ADVANCE_TO_MAKE_RESERVATIONS',
    MINUTER_PRIOR_THE_START_CLASS_BY_ZOOM = 'MINUTER_PRIOR_THE_START_CLASS_BY_ZOOM',
    NUMBER_OF_PENDING_TASKS = 'NUMBER_OF_PENDING_TASKS'
}