interface Quiz {
    theme:string
    title:string
    questions:QuestionAudioTest[]
    instructions:string
}
export interface QuestionAudioTest {
    title:string
    order:number
    percentage:number
    time:number
}

export interface QuestionAndAnswer {
    question :number
    audio:string
}

export interface Answer {
    questionPercentage: number,
    audio :string
    question:number,
    name: string
}

export interface Resume {
    step:number
    trueAnswer:Answer[]
    falseAnswer:Answer[]
    list:Answer[], 
    total:number
}


export const questions: QuestionAudioTest[] = [
    {
        title: "París es la capital de Francia ?",
        order: 1,
        time: 30,
        percentage:25
    },
    {
        title: "¿La tierra es el tercer planeta del sistema solar?",
        order: 2,
        time: 30,
        percentage:25
    },
    {
        title: "¿El río Amazonas es el rio más largo del mundo?",
        order: 3,
        time: 30,
        percentage:25
    },
    {
        title: "¿Como se dice buenos dia en inglés?",
        order: 4,
        time: 30,
        percentage:25
    },
];

export const quiz :Quiz={
    theme:"Speak Time",
    title:"Titulo del quiz que va a empezar",
    instructions:"Con ayuda del recurso vamos hacerte preguntas que debes responde con audio ",
    questions:questions
}