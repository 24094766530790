import {Route, Routes} from 'react-router-dom'
import { VerificationQuestionDetails } from '../components/details'
import { VerificationQuestionTableList } from '../components/list'


const VerificationQuestionPage = () => (
  <Routes>
      <Route path='/details' element={<VerificationQuestionDetails />} />
      <Route path='/' element={<VerificationQuestionTableList />} />
  </Routes>
)

export {VerificationQuestionPage}
