import useAuth from '../../../../../api/main'
import { api } from '../../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseMeReportModule } from '../../../../me-report/store/main'
import { UseActivityModule } from '../../../../abroad/activity/store/main'
import { TrailUpgradeStudent } from '../../../user/interfaces'
import { UsePromotionHistoryModule } from '../store/main'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}

export const usePromotionHistoryAllPaginate = async ({}): Promise<any> => {
  
}

export const StudentSettingsOneToCertificate =async (id: string): Promise<any> => {
  
  try {
    const response: any = await api.get(`/student-settings/to-certificate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    catchServerError(e.response)
    showSimpleAlert({message: e, icon: 'error'})
  }
}


export const StudentSettingsOneByUser =async (id: string): Promise<any> => {
  
  try {
    const response: any = await api.get(`/student-settings/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    catchServerError(e.response)
    showSimpleAlert({message: e, icon: 'error'})
  }
}


type TrailUpgradeStudentProps = {
  root: string 
  isActive: boolean 
  before?: Date, 
  after?: Date, 
  limit: number, 
  page: number
}

export const UseTrailUpgradeStudent = async ({ root, isActive, before, after, limit, page }: TrailUpgradeStudentProps): Promise<any> => {
  const { setPaginator } = UsePromotionHistoryModule.getState();
  try {
      const response = await api.get(`/trail-upgrade-student/promotion-history/user?root=${root}&isActive=${isActive ? 1 : 0}&before=${before}&after=${after}&limit=${limit}&page=${page}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });
      setPaginator(response.data);
      return response.data;
  } catch (e: any) {
      if (e.response.status === 401) {
          logout();
      }
      if (e.response.status === 400) {
          console.log(e.response);
          e.response.data.message.map((x) => {
              showSimpleAlert({ message: x, icon: 'error' });
          });
          throw 'Error';
      }
      showSimpleAlert({ message: 'Error!', icon: 'error' });
      throw 'Error';
  }
};

export const UpgradeStudentAdd = async (data: TrailUpgradeStudent): Promise<any> => {
  try {
    const response: any = await api.post(
      '/trail-upgrade-student',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }

    throw 'Error'
  }
}


export const UseManualUpgradeStudent = async ({studentSetting, newCurrentStep,  approvedLessons, date, additionalHours}) : Promise<any> =>{
  try {
    const response: any = await api.post(
      '/trail-upgrade-student/with-lessons',
      {
        ...{studentSetting, newCurrentStep,  approvedLessons, date, additionalHours},
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    showSimpleAlert({ message: response.data.message, icon: 'success' })
  } catch (e:any){
    if(e.response.status === 401){
      logout()
    }
    if(e.response.status === 400){
      console.error(e.response);
      showSimpleAlert({message: e.response.data.message, icon: 'error'})      
    }
  }
}