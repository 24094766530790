import { string } from "yup";
import { create  } from "zustand";
 
interface Item {
    [x: string]: any;
    title: string;
    values: string[];
  }
//   inputValueOne,
//     inputValueTwo,
//     clicked,
//     percentage,
//     correctTotal,
//     total,
//     setInputValueOne,
//     setInputValueTwo,
//     setClicked,
//     setPercentage,
//     setCorrectTotal,
//     setTotal,
interface InterfaceModuleExercise {
    
    checked: boolean;
    Arrayquestion: {
        itemOne: Item;
        itemTwo: Item;
    };
    instruction: string;
    inputValues: any[];
    setChecked: (value: boolean) => void;
    setInputValuesMo: (value: any[]) => void;
}

export const UseModuleExerciseTwelven = create<InterfaceModuleExercise>((set)=> ({
    checked:true,
    instruction: 'Highlight or underline the past form of the verbr that you find in the reading. the,number them and use the chart below to classfy them.',

    Arrayquestion : {
        itemOne: {
            title: 'Regular Verbs',
            values: [
                "J","J","J","J","J","J","J"
            ],
        },
      
        itemTwo: {
            title: 'Irregular Verbs',
            values: [
                "A","A","A","A","A","A","A",
            ],
        }
    },

        inputValues: [],
        setInputValuesMo(value) {
            set({inputValues: value})
          },

        setChecked:(value) => {
            set({checked: value})
        },


    }))
    