import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { AddSimpleCompletionQuestionsResponse } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { QuestionAndAnswer, questions } from '../interfaces';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { ResetStepByExercises } from '../../utils';
import useAuth from '../../../../../api/main';
import { validationPercentageObtained } from '../../../utils/validatePercentage';
import useIsImportantEvaluation from '../../../hooks/useIsImportantEvaluation';
import { ButtonNextFullScreen } from '../../../utils/buttonNextFullScreen';

type Props = {
    correctAnswer: QuestionAndAnswer[],
    falseAnswer: QuestionAndAnswer[],
    total:number
    correct:number
    setStatus:(string)=>void
};

const BasicVocabularyExerciseResume: React.FC<Props> = ({correctAnswer,falseAnswer, total,correct,setStatus }: Props) => {
    const { mode } = useThemeMode();
    const {typeUser} = useAuth();
    const [url, setUrl] = React.useState<string>(`/media/svg/michigan-icons/face-resume/blue/100.svg`);
    const [message, setMessage] = React.useState<string>('');
    const {isPercentageMin} = UseModeCourseModule();
    const {
        evaluationLesson, 
        questionsEvaluation, 
        currentTaskId, 
        setEvaluationResponses,
    } = UseEvaluationCourseModule();
    const {settings} = UseMyRoadmapModule.getState();
    const [percentageExercise, setPercentageExercise] = React.useState<number>(0);
    const isImportantQuiz = useIsImportantEvaluation();

    const calculatePercentageAndShowLevel = () =>{
        if (total <= 0) {
            throw new Error("The total number of questions must be greater than 0.");
        }
        const percentage = (correct / total) * 100;
        const roundedPercentage = parseFloat(percentage.toFixed(2));
        
        let level: number;
        if (roundedPercentage >= 100) {
            level = 5;
            setMessage( `You did perfect!`);
            setUrl(`/media/svg/michigan-icons/face-resume/blue/100.svg`)
        } else if (roundedPercentage >= 80) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/80.svg`)
            setMessage( `You did great!`);
            level = 4;
        } else if (roundedPercentage >= 50) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/50.svg`)
            setMessage( `You can do better!`);
            level = 3;
        } else if (roundedPercentage >= 40) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/40.svg`)
            setMessage( `You did okay,c´mon!`);
            level = 2;
        } else if (roundedPercentage >= 20) {
            setMessage( `You did okay!`);
            setUrl(`/media/svg/michigan-icons/face-resume/blue/20.svg`)
            level = 1;
        } else {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/20.svg`)
            setMessage( `you can do better`);
            level = 0;
        }
        if(!isImportantQuiz){
            validationPercentageObtained(questionsEvaluation?.minPercentage??50, roundedPercentage);
        }
        return roundedPercentage;
    }

    useEffect(() => {
        setPercentageExercise(calculatePercentageAndShowLevel());
    }, []);

    const handleSubmit = async () => {
        if(!isImportantQuiz){
            if (!isPercentageMin) return;
        }
        ResetStepByExercises();

        const feedbackAnswers = [...correctAnswer, ...falseAnswer].sort((a,b)=>a.order-b.order).map(x=>({
            question: x.name,
            value: x.answer.title[0] ?? "",
            isCorrect: x.answer.isTrue ?? false,
            feedback: x.answer.feedback ?? ""
        }))
        setEvaluationResponses({
            percentage:percentageExercise,
            exercise_title:  questionsEvaluation?.exercise.name ?? "Titulo ejercicio",
            responses: feedbackAnswers ?? [{value: "", isCorrect: false, feedback: ""}],
        })
        console.log("correctAnswers", correctAnswer);
        console.log("falseAnswers", falseAnswer);
        if (typeUser === 'STUDENT' && currentTaskId && questionsEvaluation?.exercise) {
            try {
                
                await AddSimpleCompletionQuestionsResponse({
                    exercises: questionsEvaluation?.exercise?.id??'xx',
                    task: currentTaskId??'xx',
                    description: '',
                    isActive: true,
                    percentage: percentageExercise,
                    true_answer: correctAnswer.map(x => ({question: x.name ,title: x.answer.title, isTrue: x.answer.isTrue, order: x.order})),
                    false_answer: falseAnswer.map(x => ({question: x.name ,title: x.answer.title, isTrue: x.answer.isTrue, order: x.order}))
                })
            } catch (e) {
                showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
            }
        }
    }

    return (
        <div className=" card-basic-vocabulary"
            style={
                mode !== 'dark' ? {
                    height: 420,
                    background: 'white',
                    position: 'relative'
                } : {
                    height: 420,
                    background: '#1e1e2d',
                    border: `1px solid`,
                    borderColor: '#3e94ff',
                    position: 'relative'
                }
            }
        >
            { isImportantQuiz ?
                <>
                    <img className='image-basic-vocabulary-resume' src={toAbsoluteUrl(`/media/svg/michigan-icons/face-resume/blue/100.svg`)} alt='' />
                    <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                        style={{
                            color: evaluationLesson?.colorPrimary??'#3e94ff',
                        }}>
                        Good job, keep going
                    </h2>
                </>
            :
                <>
                    <img className='image-basic-vocabulary-resume' src={toAbsoluteUrl(url)} alt='' />
                    <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                        style={{
                            color: evaluationLesson?.colorPrimary??'#3e94ff',
                        }}>
                        {message}
                    </h2>
                    <h2 className='champ-light title-basic-vocabulary-resume-number text'
                        style={{
                            color: evaluationLesson?.colorPrimary??'#3e94ff',
                        }}>
                        {correct}/{total}
                    </h2>
                </>
            }
            <>
                {!isImportantQuiz &&
                    <div
                        className='  arrow-left-basic-vocabulary cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={()=>setStatus('ANSWER')}
                    >
                        <img className='' height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/try_again.svg`)} alt='' />
                    </div>
                }
                {(isImportantQuiz || isPercentageMin) && 
                    <ButtonNextFullScreen onAction={handleSubmit}/>
                }
            </>
        </div>
    );
};

export { BasicVocabularyExerciseResume };