import React from 'react';
import { UseAutoVerification } from '../../../store/main';
import { VerificationVoiceInput } from '../../../../../components/common/voice-recorder/VerificationVoice';
import { ContentVerification, StateVerificationRequest, TypeFileContentRequest } from '../../../interface';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { VerificationRequestUpdate } from '../../../services';

type Props = {
  onChangeContent:(v: ContentVerification)=>void;
  onClickStep:(v: number, f: 'addition'|'subtraction')=>void;
};

const RenderQuestionsVerification: React.FC<Props> = ({
  onClickStep,
  onChangeContent}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const {contentPhoto, verificationRequest, questions, responseBeneficiary, responseInfo} = UseAutoVerification();
  const [stepAudio, setStepAudio] = React.useState<number>(0);

  const [audios, setAudios] = React.useState<ContentVerification[]>([]);

  React.useEffect(() => {
    console.log('audios', audios)
  }, [audios]);

  const onUpdate = async () => {
    try {
          await VerificationRequestUpdate({
            contract: verificationRequest.contract,
            responseHolder: responseInfo ?? undefined,
            responseBeneficiaries: responseBeneficiary ?? undefined,
            status: StateVerificationRequest.PENDING,
            photo: contentPhoto,
            audios: audios.map((x) => x.id),
        });
    } catch (err) {
      showSimpleAlert({message: 'Error al actualizar respuesta', icon: 'error'})
    }
  }

  return (<>
    <div className='col-12 mb-10'>
      <label className='form-label'>{questions[stepAudio].name}</label>
    </div>
    <div className='col-6 mb-10'>
      <VerificationVoiceInput
        onChangeContent={(e) => setAudios([...audios, e])}
        onChangeFile={(e) => console.log()}
        onChangeLoading={(e) => setLoading(e)}
        idInput='audioVerification'
        root={verificationRequest.id}
      />
    </div>

    <div className='d-flex flex-stack pt-10'>
      <div className='mr-2'>
        <button
          onClick={() => setStepAudio(stepAudio - 1)}
          type='button'
          className='btn btn-lg btn-light-primary me-3'
          data-kt-stepper-action='previous'
        >
          <KTIcon iconName='arrow-left' className='fs-4 me-1' />
          Back
        </button>
      </div>

      <div>
        <button type='button' 
          onClick={() => {
            if (stepAudio === (questions.length - 1)) {
              return onUpdate();
            }
            return setStepAudio(stepAudio + 1)
          }} 
          className='btn btn-lg btn-primary me-3'>
          <span className='indicator-label'>
            {stepAudio === (questions.length - 1) ? 'Guardar' : 'Continue'}
            <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
          </span>
        </button>
      </div>
    </div>
  </>)
}

export { RenderQuestionsVerification };