import { showSimpleAlert } from "../../../commonHooks/alert";
import { UseModeCourseModule } from "../components/front-page/store/main";

export function validationPercentageObtained(minPercentage: number, percentageObtained: number): boolean {
  const {setIsPercentageMin} = UseModeCourseModule.getState();
  if (minPercentage <= percentageObtained) {
    setIsPercentageMin(true)
    return true;
  }

  showSimpleAlert({message: `Debes alcanzar el porcentaje mínimo de aciertos para poder continuar`, icon: 'warning'});
  setIsPercentageMin(false);
  return false;
};