import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ReasonCancellation, TransferClass} from '../../interfaces'

import { TransferClassAdd, TransferClassUpdate } from '../../services'
import { UseTransferClassModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { UseUserTypePaginate } from '../../../user/services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseAppointmentModule } from '../../../appointment/store/main'
import { TransferTeacher, UseAppointmentAllByTeacher } from '../../../appointment/services'
import { Appointment, DataAppointment } from '../../../appointment/interfaces'
import { motion } from 'framer-motion';
import { Reorder } from "framer-motion";
import { animate, MotionValue, useMotionValue } from "framer-motion";
import { useRaisedShadow } from '../../../../../commonHooks/framerMotion/use-raised-shadow'
import { Item } from '../itemComponent'
import Multiselect from 'multiselect-react-dropdown'
import { extractIDs } from '../../../../../components/common/ExcelExport/utils'
import { DayPickerRangeSelector } from '../../../../../components/common/DayRangePicker/indext'
import { TeacherProfileByDay } from '../../../../abroad/block-hours/services'
import { DataTeacherProfileByDay } from '../../../../abroad/block-hours/interfaces'
import { TemplateTableTimeDay } from '../../../../abroad/template-table-time/interfaces'
import { setTypeToSpanish } from '../../../user/utils/setTypeToSpanish'
import Swal from 'sweetalert2'

export type FormFields = {
  date: string;
  teacherOrigin: string;
  teacherDestiny: string;
  reasonCancellation: ReasonCancellation; 
  //isActive: boolean;
  appointmentArray: string[];
  description?:string;
}

const entitySchema = Yup.object().shape({
  //name: Yup.string().required('First name is required'),
  date: Yup.date()
        .min(moment().subtract(1, 'day').toDate(), 'La fecha no puede ser anterior a hoy')
        .required('Fecha es requerida'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TransferClassForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseTransferClassModule();

  const [teacherData, setTeacherData] = React.useState<any[]>([]);
  const [teacherOriginData, setTeacherOriginData] = React.useState<DataAppointment[]>([]);
  const [teacherDestinyData, setTeacherDestinyData] = React.useState<DataAppointment[]>([]);

  const [appointmentArray, setAppointmentArray] = React.useState<string[]>([]);
  const [validateClass, setValidateClass] = React.useState<string[]>([]);

  // const [before, setBefore] = React.useState<any>(moment().toDate());
  const [after, setAfter] = React.useState<any>((mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.date ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

  const [selectedBlockHours, setSelectedBlockHours] = React.useState<DataTeacherProfileByDay>();

  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        date: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.date ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        teacherOrigin: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.teacherOrigin.id ?? '' : '',
        teacherDestiny: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.teacherDestiny.id ?? '' : '',
        reasonCancellation: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.reasonCancellation ?? ReasonCancellation.PAID_LICENSE : ReasonCancellation.PAID_LICENSE,
        appointmentArray: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelected?.appointmentArray.map((x) => x.id) ?? []).filter((x): x is string => !!x) : []
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then(async(result) => {
              if (result.isConfirmed) {
                setLoading(true);
                await TransferClassAdd({
                  ...formData,
                  appointmentArray: appointmentArray ? appointmentArray : []
                });
    
                await TransferTeacher({
                  teacher: values.teacherDestiny,
                  appointment: appointmentArray ? appointmentArray : []
                });

                resetData();
                setLoading(false);
                queryClient.invalidateQueries({ queryKey: ['transfer-class'] });
              }
            })
            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then(async(result) => {
                if (result.isConfirmed) {
                  setLoading(true);
                  await TransferClassUpdate({
                    ...formData,
                    appointmentArray: appointmentArray ? appointmentArray : []
                  })
    
                  await TransferTeacher({
                    teacher: values.teacherDestiny,
                    appointment: appointmentArray ? appointmentArray : []
                  })
                  resetData();
                  setLoading(false)
                  queryClient.invalidateQueries({ queryKey: ['transfer-class'] })
                }
              })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

  const fetchTeacher = async () => {
    try {
      let teacherResponse = await UseUserTypePaginate('TEACHER')
      setTeacherData(teacherResponse)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchAppointmentTeacherOrigin = async () => {
    try {
      let response = await UseAppointmentAllByTeacher(values.teacherOrigin, after, after);
      setTeacherOriginData(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar clases!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchAppointmentTeacherDestiny = async () => {
    try {
      let response = await UseAppointmentAllByTeacher(values.teacherDestiny, after, after);
      setTeacherDestinyData(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar clases!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchBlockHour = async () => {
    try {
      if (values.teacherDestiny && after) {
        const blockHourResponse = await TeacherProfileByDay(values.teacherDestiny, moment(after).format('dddd').toUpperCase() as TemplateTableTimeDay);
        setSelectedBlockHours(blockHourResponse);
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
      throw 'Error'
    };
  }

  React.useEffect(() => {
    fetchTeacher();
  }, []);

  React.useEffect(() => {
    if (values.teacherOrigin) {
      fetchAppointmentTeacherOrigin();
    }
  }, [values.teacherOrigin, after]);

  React.useEffect(() => {
    if (values.teacherDestiny) {
      fetchAppointmentTeacherDestiny();
    }
    if (values.teacherDestiny && after) {
      fetchBlockHour();
    }
  }, [values.teacherDestiny, after]);

  React.useEffect(() => {
    if (teacherOriginData && teacherDestinyData && after) {
      validationClasses();
    }
  }, [values.teacherDestiny, after,teacherOriginData, teacherDestinyData, selectedBlockHours]);

  React.useEffect(() => {
    if (mode === 'EDIT' || mode === 'VIEW') {
      setAppointmentArray((itemSelected?.appointmentArray.map((x) => x.id) ?? []).filter((x): x is string => !!x))
    }
  }, [itemSelected])

  const validationClasses = () => {
    if (!selectedBlockHours) return [];
  
    const response = teacherOriginData.filter((originItem) => {
      return teacherDestinyData.every((destinyItem) => {
        const startsMatch = originItem.start === destinyItem.start;
        const ordersMismatch = startsMatch
          ? originItem.blockHour.every((originBlock) =>
              destinyItem.blockHour.every((destinyBlock) => originBlock.order !== destinyBlock.order)
            )
          : true;
  
        return ordersMismatch;
      });
    });
  
    const validation = response.filter((origin) => {
      const destinyBlockHours = selectedBlockHours[moment(after).format('dddd').toLowerCase()];
      if (!destinyBlockHours) return false;

      return destinyBlockHours.some((destiny) =>
        origin.blockHour.some((originBlock) =>
          originBlock.order === destiny.order
        )
      );
    }).map((x) => x.id)

    setValidateClass(validation); 
  };
  
  const transferClass = (item: DataAppointment, values: boolean) => {
      if (values) {
        setTeacherOriginData(prevItem => prevItem.filter((x) => x.id !== item.id));
        setTeacherDestinyData(prevItem => [...prevItem, item]);
        setAppointmentArray(prevItem => [...prevItem, item.id]);
        setValidateClass(prevItem =>  prevItem.filter((x) => x !== item.id));
      }
      if (!values) {
        setTeacherOriginData(prevItem => [...prevItem, item]);
        setTeacherDestinyData(prevItem => prevItem.filter((x) => x.id !== item.id));
        setAppointmentArray(prevItem => prevItem.filter((x) => x !== item.id));
        setValidateClass(prevItem => [...prevItem, item.id]);
      }
  };

  const handleChangeFilterDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAfter(e.target.value)
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-1 col-12'>
            <label className='form-label mb-0'>Fecha</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'date'}
              value={values.date}
              onChange={handleChange}
            />
            {touched.date && errors.date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.date}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Tutor origen</label>
            <select
              disabled={mode === 'VIEW'}
              name={'teacherOrigin'}
              value={values.teacherOrigin}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option value=''></option>
              {teacherData.map((x) =>
                (<option value={x.id}>{`${x.fullName}` || `${x.firstName.toUpperCase()} ${x.lastName.toUpperCase()}`}</option>)
              )}
            </select>
            {errors.teacherOrigin && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.teacherOrigin}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Tutor destino</label>
            <select
              disabled={mode === 'VIEW'}
              name={'teacherDestiny'}
              value={values.teacherDestiny}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option value=''></option>
              {teacherData.filter(x => x.id !== values.teacherOrigin).map((x) =>
                (<option value={x.id}>{`${x.fullName}` || `${x.firstName.toUpperCase()} ${x.lastName.toUpperCase()}`}</option>)
              )}
            </select>
            {errors.teacherDestiny && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.teacherDestiny}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-3 col-6'>

            <label className='form-label mb-0'>Filtro de dia</label>
            <input
              type='date'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'after'}
              value={after}
              onChange={handleChangeFilterDate}
            />

                {/* <DayPickerRangeSelector 
                  after= {after}
                  before={before}
                  changeDate={(e)=>{
                    setBefore(e.before)
                    setAfter(e.after)
                  }} 
                /> */}
            
          </div>

          <div className='mb-3 col-6'>

          </div>

          <div className='mb-3 col-6'>
            <label className='form-label mb-0 fw-bold'>Clases del tutor origen</label>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'> {/* Modificarlo para que funcione tambuien en el update */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Activity</th>
                    <th className='min-w-120px'>Classroom</th>
                    <th className='min-w-120px'>{' '}</th>
                  </tr>
                </thead>
                <tbody>
                  {teacherOriginData && teacherOriginData.map((x, i) => {
                  const blockHours = x.blockHour.map((hour) => `${hour.start} - ${hour.end}`);
                    return (
                      <tr key={i} className={`${validateClass.includes(x.id) ? 'bg-light-success' : 'bg-light-danger'}`}>
                        <td className='p-0'>
                          <div className="d-flex justify-content-start flex-column">
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x?.activity?.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {blockHours.join(', ')}
                            </span>
                          </div>
                        </td>
                        <td className="p-0">
                          <span className='text-dark fw-semibold d-block fs-7'>
                            {x?.classroom?.name}
                          </span>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            {validateClass.includes(x.id) && 
                              <button
                                disabled={mode==='VIEW'}
                                type='button'
                                onClick={()=> transferClass(x, true)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className="fa-solid fa-arrow-right" style={{ color: 'green', fontSize: 15 }}></i>
                              </button>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
            </table>
          </div>

          <div className='mb-3 col-6'>
            <label className='form-label mb-0 fw-bold'>Clases del tutor destino</label>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Activity</th>
                    <th className='min-w-120px'>Classroom</th>
                    <th className='min-w-120px'>{' '}</th>
                  </tr>
                </thead>
                <tbody>
                  {teacherDestinyData && teacherDestinyData.map((x, i) => {
                  const blockHours = x.blockHour.map((hour) => `${hour.start} - ${hour.end}`);
                    return (
                      <tr key={i} className={`${appointmentArray.includes(x.id) ? 'bg-light-success' : 'bg-light-primary'}`}>
                        <td className='p-0'>
                          <div className="d-flex justify-content-start flex-column">
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x?.activity?.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {blockHours.join(', ')}
                            </span>
                          </div>
                        </td>
                        <td className="p-0">
                          <span className='text-dark fw-semibold d-block fs-7'>
                            {x?.classroom?.name}
                          </span>
                        </td>
                        <td className='p-0'>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            {appointmentArray.includes(x.id) && 
                              <button
                                disabled={mode==='VIEW'}
                                type='button'
                                onClick={()=> transferClass(x, false)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i className="fa-solid fa-xmark" style={{ color: 'red', fontSize: 15 }}></i>
                              </button>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
            </table>
          </div>

          {/* <div className='mb-1 col-sm-6'>
            <label className='form-label mb-0'>Clases que puede transferir al profesor destino</label>
              <Multiselect
                options={validationClasses()}
                selectedValues={values.appointmentArray}
                onSelect={(x) => setFieldValue('appointmentArray', x)}
                onRemove={(x) => setFieldValue('appointmentArray', x)}
                displayValue={`code`}
                disable={mode === 'VIEW'}
                className='custom-multiselect'
              />
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Razon de cancelacion</label>
            <select
              disabled={mode === 'VIEW'}
              name='reasonCancellation'
              value={values.reasonCancellation}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ReasonCancellation).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errors.reasonCancellation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.reasonCancellation}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {TransferClassForm}