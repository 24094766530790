/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { EvaluationType } from '../../../../evaluation/interfaces'
import { UseQualifyPlacementModule } from '../../placement-test/store/main'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { TasksDetails } from '../../../../tasks/components/details'
import { UseTrailModule } from '../../../../user/store/student/main'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

type Props = {
  show: boolean
  handleClose: () => void
  evaluationType: EvaluationType
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateModal = ({show, handleClose, evaluationType}: Props) => {
  const {mode,setModal,itemSelected, setModalQualify, setStudent} = UseQualifyPlacementModule();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {setModalUpgradeStudent} = UseTrailModule();
  const {t} = useTranslation()

  const handleQualifyModal = () => {
    setModal(false);
    setModalQualify(true)
  }

  const handleUpdateModule= () => {
    setModal(false);
    setModalUpgradeStudent(true);
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-xl modal-fullscreen-lg-down'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Calificar {t(evaluationType)}</h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModal(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      {itemSelected && 
        <div className='overflow-y-auto'>
          <TasksDetails item={itemSelected} setStudent={(e) => setStudent(e)} isLoading={isLoading} setIsLoading={setIsLoading}/>
            {!isLoading && 
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' onClick={handleQualifyModal} className='btn btn-primary me-5' >
                  Ver respuestas
                </button>     
                { evaluationType === EvaluationType.PLACEMENT_TEST &&
                  <button type='button' onClick={handleUpdateModule} className='btn btn-success' >
                    Elegir nivel del estudiante
                  </button>
                } 
              </div>
            }
        </div>
      }
    </Modal>,
    modalsRoot
  )
}

export {CreateModal}
