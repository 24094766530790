import React, { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers';
import { UseModeCourseModule } from '../../../../app/modules/courses/components/front-page/store/main';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { showSimpleAlert } from '../../../../app/commonHooks/alert';
import { AddTaskByStudentSettingsManual, UseTrailClassByStudentWithCourse } from '../../../../app/modules/abroad/trail/services';
import { UseMyRoadmapModule } from '../../../../app/modules/profile/components/my-road-step/store';
import { UseEvaluationCourseModule } from '../../../../app/modules/courses/screen/store/main';
import { EvaluationType } from '../../../../app/modules/admin/evaluation/interfaces';
import { UseChapterAllByLearningPath } from '../../../../app/modules/abroad/learning-path/modules/chapter/services';
import Offcanvas from 'react-bootstrap/Offcanvas';
import useAuth from '../../../../app/api/main';
import { TaskType } from '../../../../app/modules/admin/tasks/interfaces';
import { UseBookStudentModule } from '../../../../app/modules/book/modules/screen/store/main';

type Params = { lesson: string, mode: 'STUDENT' | 'ADMIN' };

type Props = {
  courseId?: string;
  mode: 'ADMIN' | 'STUDENT' | 'TEACHER';
  colorCourse: string;
};

export enum TaskStatus {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  REPROVED = 'REPROVED'

}

export interface TopicsDrawerTrail {
  lesson_id: string;
  nameLesson: string;
  chapter_id: string;
  statusTask: TaskStatus | 'NOT_TASK';
  taskId: string;
  code: string;
  orderLesson: number;
  typeScreen: EvaluationType;
  evaluation_id: string;
};

const TopicsDrawer: FC<Props> = ({ courseId, mode, colorCourse }) => {

  const { '*': id } = useParams();
  const { typeUser } = useAuth();
  const location = useLocation();
  const { 
    currentCourseId,
    modeCourseChecked,
    lessonsMenuTopics,
    setCurrentCourse,
    setLessonsMenu 
  } = UseModeCourseModule();
  const { settings } = UseMyRoadmapModule.getState();
  const { lessonCourse, setCurrentTask, setLessonsId, setStepEvaluation, setShowEndEvaluation, resetEvaluationResponses } = UseEvaluationCourseModule();
  const [show, setShow] = React.useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const findLessonsByChapterAdmin = React.useCallback(async (course: string) => {
    try {
      const response = await UseChapterAllByLearningPath(course);
      setLessonsMenu(response, 'NORMAL');
      // setLessonsId(response.result?.map(x => ({id: x.lesson_id, order: x.orderLesson})));

    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Topicos', icon: 'error' });
    }
  }, [lessonsMenuTopics])

  const findLessonsByChapter = async (id: string, idCourse: string, idChapter?:string) => {
    try {
      const response = await UseTrailClassByStudentWithCourse(id, idCourse, idChapter);
      setLessonsMenu(response.result, 'NORMAL');
      setLessonsId(response.result?.map(x => ({ 
        id: x.lesson_id, 
        order: x.orderLesson, 
        with_teacher: x.withTeacher 
      })));

    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Topicos', icon: 'error' });
    }
  };

  const addTaskByStudent = async (id: string, code: string, type: TaskType) => {
    try {
      await AddTaskByStudentSettingsManual({
        lesson: id,
        student_setting: settings?.id ?? 'xx',
        code: code,
        description: '',
        isArtificial: false,
        // exercises: false,
        isActive: true,
        type
      })
    } catch (e) {
      showSimpleAlert({ message: 'Error al cargar tarea', icon: 'error' });
    }
  };

  React.useEffect(() => {
    modeCourseChecked();
  }, [location, typeUser]);

  React.useEffect(() => {
    if (courseId && isCorrectUUID(courseId)) {
      setCurrentCourse(courseId);
    }
  }, [courseId, location, typeUser]);

  React.useEffect(() => {

    if (typeUser === 'STUDENT' && settings && isCorrectUUID(currentCourseId)) {
      findLessonsByChapter(settings.id, currentCourseId, settings?.currentChapter);
    } else if ((typeUser === 'TEACHER' || typeUser === 'ADMIN') && isCorrectUUID(currentCourseId)) {
      findLessonsByChapterAdmin(currentCourseId);
    }
  }, [settings, currentCourseId, location, typeUser]);

  const isCorrectUUID = (value: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  }

  React.useEffect(() => {
    const contentCourse = location.pathname.includes('/courses/container/');
    if (!contentCourse) {
      const evaluationCourse = location.pathname.includes('/evaluation/screen/course/');
      if (evaluationCourse) {
        const pathId: boolean = lessonCourse?.chapter?.path?.id === currentCourseId;
        setCurrentCourse(pathId ? lessonCourse?.chapter?.path?.id : currentCourseId);
      }
      if (!evaluationCourse) {
        if (id && isCorrectUUID(id)) {
          setCurrentCourse(id);
        }
      }
    }
    if (contentCourse) {
      if (lessonCourse && lessonCourse?.chapter && lessonCourse?.chapter?.path) {
        setCurrentCourse(lessonCourse.chapter.path.id);
      }
    }
  }, [id, lessonCourse, location, mode]);

  const [selectedChapter, setSelectedChapter] = React.useState<number | null>(0)
  const [selectedUnit, setSelectedUnit] = React.useState<number | null>(null)
  const [selectedLesson, setSelectedLesson] = React.useState<number | null>(null)

  const handleChapterClick = (chapterIndex: number) => {
    setSelectedChapter(chapterIndex === selectedChapter ? null : chapterIndex)
    setSelectedUnit(null) // Deseleccionar la unidad al seleccionar otro capítulo
    setSelectedLesson(null) // Deseleccionar lección al seleccionar unidad
  }

  const handleUnitClick = (unitIndex: number) => {
    setSelectedUnit(unitIndex === selectedUnit ? null : unitIndex)
    setSelectedLesson(null)
  }

  const handleLessonClick = (lessonIndex: number) => {
    setSelectedLesson(lessonIndex === selectedLesson ? null : lessonIndex)
  }

  const nonClickedStyles = { color: 'white', background: colorCourse !== '' ? colorCourse : '#3e94ff' }
  const clickedStyles = { color: '#D0FF00', background: colorCourse !== '' ? colorCourse : '#3e94ff' }

  const nonIconStyles = {
    color: 'white',
    width: '27px',
    height: '27px',
  }
  const IconStyles = {
    color: 'blue',
    width: '27px',
    height: '27px',
    background: '#D0FF00',
  }

  return (
    <>{!show &&
      <button type='button'
        className='btn bg-white cursor-pointer champ-light py-2 p-2'
        style={{ borderRadius: '30px', color: '#2d30e1' }}
        // id='kt_glossary_course_toggle'
        // id='kt_tips_course_toggle'
        // id={idDrawer}
        onClick={handleShow}
      >
        <img 
         className='me-3 ms-4'
         style={{ width: '10px' }} 
         src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} 
         alt='' 
         />
        <span className='pt-1 me-4'>Topics</span>
      </button>
    }
      <Offcanvas show={show} onHide={handleClose} placement='end' style={{ background: colorCourse !== '' ? colorCourse : '#3e94ff' }}>
        <Offcanvas.Header >
          <Offcanvas.Title>
            <div className='mt-10'>
              <button type='button'
                className='btn bg-white cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center'
                id='kt_topics_course_close'
                style={{ borderRadius: '30px', color: '#2d30e1' }}
                onClick={handleClose}
              >
                <img className='me-1 ms-4' style={{ width: '10px' }} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)} alt='' />
                <span className='pt-1 me-4'>Topics</span>
              </button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='card-body'>
            <div className='d-flex flex-column scroll-y' style={{ maxHeight: '550px' }}>
              {/* Array Principal */}
              {lessonsMenuTopics && lessonsMenuTopics.sort((a,b)=>a.chapter_order-b.chapter_order).map((chapter, i) => (
                <div key={chapter.chapter_id}>
                  <button
                    className='accordion-button mb-1 rounded-pill'
                    type='button'
                    style={{
                      backgroundColor: selectedChapter === i ? '#C6F711' : '',
                      color: selectedChapter=== i ? '#3E94FF':'white',
                      border: selectedChapter === i ? 'none':'1px solid',
                      
                    }}
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${chapter.chapter_id}`}
                    aria-expanded={selectedChapter === i}
                    aria-controls={`collapse${chapter.chapter_id}`}
                    onClick={() => handleChapterClick(i)}
                  >
                    <div className='d-flex'>
                      <div className='d-flex align-items-center ms-7'>
                        <span className={`${'champ-bold'} fs-6`} style={{marginTop:'4px' }}>
                          {chapter.chapter_name}
                        </span>
                      </div>
                    </div>
                  </button>

                  <div
                    id={`collapse${chapter.chapter_id}`}
                    className={`accordion-collapse collapse ${selectedChapter === i ? 'show' : ''
                      }`}
                    aria-labelledby={`heading${chapter.chapter_id}`}
                    data-bs-parent='#accordionSecondary'
                  >
                    <div className='accordion-body'>
                      <div className='accordion d-flex flex-column py-3'>
                        {chapter.lessons && chapter.lessons.sort((a,b)=>a.order-b.order).map((lesson, iL) => {
                          const isUnitSelected = selectedUnit === iL
                          const buttonStyle = isUnitSelected? clickedStyles: nonClickedStyles
                          const iconStyle = isUnitSelected ? IconStyles : nonIconStyles
                          
                          return (
                            <div key={lesson.id}>
                                <button
                                style={buttonStyle}
                                onClick={() => handleUnitClick(iL)}
                                className='rounded-pill  border border-0'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapseUnit${iL}`}
                                aria-expanded={isUnitSelected}
                                aria-controls={`collapseUnit${iL}`}
                              >
                                <div className='d-flex'>
                                  <div
                                    className='border border-white rounded-circle d-flex align-items-center justify-content-center'
                                    style={iconStyle}
                                  >
                                    <span className='champ-bold m-0'>U{iL + 1}</span>
                                  </div>
                                  <div className='d-flex align-items-center ms-2'>
                                    <span className='champ-bold fs-5'>{lesson.name}</span>
                                  </div>
                                </div>
                              </button>

                              <div
                                id={`collapseUnit${iL}`}
                                className={`accordion-collapse collapse ${isUnitSelected ? 'show' : ''}`}>

                                  <div className='accordion d-flex flex-column px-2 cursor-pointer'>
                                    {lesson.items && lesson.items.sort((a,b)=>a.order-b.order).map((evaluationI, iE) => (
                                      <div
                                      key={evaluationI.order}
                                      className='d-flex align-items-center px-12'
                                      onClick={async () => {
                                        if ( lesson.evaluation_id === null || lesson.typeScreen === null) {
                                          showSimpleAlert({ message: 'La leccion no tiene una evaluación', icon: 'error', })
                                          return
                                        }
                                        setStepEvaluation(0)
                                        setShowEndEvaluation(false)
                                        resetEvaluationResponses()
                                        if ( mode === 'STUDENT' && typeUser === 'STUDENT') {
                                          if ( lesson.statusTask === 'NOT_TASK') {
                                            await addTaskByStudent(
                                              lesson.id,
                                              'xx',
                                              TaskType.NORMAL
                                            )
                                          }
                                          if (lesson?.taskId) {
                                            setCurrentTask(lesson.taskId)
                                            // setTaskBook(lesson.taskId)
                                          }
                                          if ( lesson.typeScreen === EvaluationType.BOOK ) {
                                            navigate(`/book/container?lesson=${lesson.id}&mode=STUDENT`)
                                          } else if ( lesson.typeScreen === EvaluationType.SINGLE_QUIZ ) {
                                            navigate(`/courses/container/${lesson.id}`)
                                          } else if (lesson.withTeacher) {
                                            await addTaskByStudent(
                                              lesson.id,
                                              'xx',
                                              TaskType.NORMAL
                                            )
                                          } else {
                                            navigate(`/evaluation/screen/course/${lesson.evaluation_id}`)
                                          }
                                        } else if ( mode === 'ADMIN' || typeUser === 'ADMIN') {
                                          if ( lesson.typeScreen === EvaluationType.SINGLE_QUIZ ) {
                                            navigate(`/evaluation/test/${lesson.evaluation_id}`)
                                          } else if ( lesson.typeScreen === EvaluationType.BOOK) {
                                            navigate(`/book/test?lesson=${lesson.id}&mode=ADMIN`)
                                          } else {
                                            navigate(`/evaluation/screen/course/${lesson.evaluation_id}`)
                                          }
                                        } 
                                        handleClose()
                                      }}
                                    >
                                      {typeUser !== 'STUDENT' ?
                                        <div style={{width: '13px',height: '13px',background: '#ffffff',borderRadius: '50px',marginRight: '10px'}}/>
                                      :
                                        lesson.statusTask === 'PENDING' ?
                                          <div style={{ width: '13px', height: '13px', border: '2px solid #ffffff', borderRadius: '50%', background: 'transparent', marginRight: '10px'}}/>
                                        :
                                        lesson.statusTask === 'REPROVED' ?
                                        <i className="bi bi-x-circle-fill" style={{color: 'red'}}/>
                                        :
                                        <i className="bi bi-check-circle-fill" style={{color : '#D0FF00'}}/>
                                      }
                                      <span className='fs-6' style={{ color: 'white' }} >
                                        {evaluationI.name}
                                      </span>
                                    </div>
                                    ))}
                                  </div>
                                </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export { TopicsDrawer }

// type HasNestedProperties<T> = T extends object ? T : never;

// function hasNestedProperties<T extends object>(obj: any, reference: T): obj is T {
//     if (typeof obj !== 'object' || obj === null) return false;

//     return Object.keys(reference).every((key) => {
//         const property = key;
//         const expectedType = reference[property];

//         // Si la propiedad no existe en obj, ignorarla y continuar verificando las siguientes propiedades
//         if (!(property in obj)) return true;

//         if (Array.isArray(expectedType)) {
//             if (!Array.isArray(obj[property])) return false;
//             // Permitir que se cumpla la validación si el array esperado está vacío
//             if (expectedType.length === 0) return true;
//             if (expectedType.length > 0 && typeof expectedType[0] === 'object') {
//                 return obj[property].every((item: any) => hasNestedProperties(item, expectedType[0]));
//             }
//             return true;
//         }

//         if (typeof expectedType === 'object' && expectedType !== null) {
//             if (typeof obj[property] === 'object' && obj[property] !== null) {
//                 return hasNestedProperties(obj[property], expectedType);
//             }
//             return false;
//         }

//         return typeof obj[property] === typeof expectedType;
//     });
// }


// function determineType<T>(arr: any[], reference: T): arr is T[] {
//     return arr.every(item => hasNestedProperties(item, reference as HasNestedProperties<T>));
// }