import {Route, Routes} from 'react-router-dom'
import { CertificateItemDetails } from '../components/details'
import { CertificateItemTableList } from '../components/list'


const CertificateItemPage = () => (
  <Routes>
      <Route path='/details' element={<CertificateItemDetails />} />
      <Route path='/:id_student' element={<CertificateItemTableList />} />
  </Routes>
)

export {CertificateItemPage}
