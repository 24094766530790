import React, { useState } from 'react';
import MyX from '../img/EQUIS_btn1.svg';
import HeaderCard from '../ui/HeaderCard';
import { UseModuleExerciseNineteen } from './store/main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';

const ExerciseNineteen: React.FC = () => {
    const { Arrayquestion, instruction, checked, setChecked, setInputValuesMo } = UseModuleExerciseNineteen();
    const [inputValues, setInputValues] = useState<string[]>(Arrayquestion.columnLeft.map(() => '')); // Estado inicializado con un arreglo de strings
    const [validationResults, setValidationResults] = useState<(string | JSX.Element | null)[]>(Arrayquestion.columnLeft.map(() => null)); // Estado inicializado con un arreglo de valores nulos

    const correctImage = <FontAwesomeIcon icon={faCheck} style={{ color: "#34f000", width: '15px', height: '15px' }} className='animate__bounce animate__animated ' />;
    const incorrectImage = <FontAwesomeIcon icon={faXmark} style={{ color: "#ff0000", width: '15px', height: '15px' }} className='animate__bounce animate__animated' />;

    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        height: 'auto',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 38px 20px'
    };

    const handleInputChange = (index: number, value: string) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };

    const handleCheck = () => {
        if (inputValues.includes("")) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Debe llenar todos los campos",
                showConfirmButton: false,
                timer: 1500,
              backdrop: 'swal2-backdrop-show'
            });
            return;
        }
        const newValidationResults = Arrayquestion.columnLeft.map((elementLeft, index) => {
            const correspondingElementRight = Arrayquestion.columnRight.find(elementRight => elementRight.value === elementLeft.value);
            if (correspondingElementRight && inputValues[index] === correspondingElementRight.value) {
                return correctImage;
            } else {
                return incorrectImage; 
            }
        });
        setValidationResults(newValidationResults);
        setChecked(true);
    };
    React.useEffect(() => {
        setInputValuesMo(inputValues)
        console.log('valid date', inputValues)
    }, [inputValues])
   
    return (
        <>  <div className="d-flex container-fluid">
                <div style={divStyle}>
                    <HeaderCard />
                    <span  className=" text-white fs-9 px-3 py-3 poppins-light">{instruction}</span>
                    <div className='py-4'>
                        
                    {Arrayquestion.columnLeft.map(({ idWord, title }: any, index: number) => (
                        <div key={idWord}  className=" row  d-flex fs-9  text-white px-20   poppins-light">
                            <div className="col-3 d-flex justify-content-end">
                                <p style={{}} className='text-right'>{title}</p>
                            </div>
                            <div className="col-2 ">
                                <div className="row">
                                    <input
                                       style={{fontSize: '12px', marginLeft:'10px', border: '1px solid #FFFFFF',height:'17px',width:'28px',background:'#d0ff00',color:'#003990',fontWeight:'600'}}
                                        className='rounded-4 border-0  text-center '
                                        type="text"
                                        value={inputValues[index]} // Asignar el valor correspondiente al input
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        disabled={checked}/>
                                </div>
                            </div>
                            <div className="col-4">
                            {Arrayquestion.columnRight.map(({title}: any, columnIndex: number) => {
                                return (columnIndex === index) ? <p key={columnIndex}>{title}</p> : null;
                            })}
                              
                            </div>
                            <div className='col-3'>
                                {validationResults[index] !== null && (validationResults[index])}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
                <div className="position-relative" onClick={handleCheck}>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
                </div>
                <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
            </div></>
    );
};

export { ExerciseNineteen };