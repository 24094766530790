import { UseEvaluationCourseModule } from "../screen/store/main";
import { UseBasicVocabularyCompletionModule } from "./basic-vocabulary-completion/store"
import { UseBasicVocabularyModule } from "./basic-vocabulary/store";
import { UseHistoryWritingModule } from "./history-wrinting/store";
import { UseMovieWritingModule } from "./movie-writing/store";
import { UseParagraphDragDropModule } from "./paragraph-drag-drop/store";
import { UseExerciseTimerModule } from "./common/store/main";
import { UseTrueFalseModule } from "./true-false/store";
import { UseModeCourseModule } from "../components/front-page/store/main";
import { useNavigate } from "react-router-dom";

export const ResetStepByExercises = () => {
    const {resetData: resetCompletion} = UseBasicVocabularyCompletionModule.getState();
    const {resetData: resetSimple} = UseBasicVocabularyModule.getState();
    const {resetData: resetTrueFalse} = UseTrueFalseModule.getState();
    const {resetData: resetDragDrop} = UseParagraphDragDropModule.getState();
    const {resetData: resetMovie} = UseMovieWritingModule.getState();
    const {resetData: resetHistoryWriting} = UseHistoryWritingModule.getState();

    resetHistoryWriting();
    resetMovie();
    resetCompletion();
    resetSimple();
    resetTrueFalse();
    resetDragDrop();

    return null;
}

export const CheckExtension = (type: string, extension: string): boolean => {
    switch (type.toUpperCase()) {
        case 'IMG':
            return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'].includes(extension?.toLowerCase());
        case 'HTML':
            return ['html', 'htm'].includes(extension?.toLowerCase());
        case 'AUDIO':
            return ['mp3', 'wav', 'ogg', 'aac', 'wma'].includes(extension?.toLowerCase());
        default:
            return false; 
    }
}

export const IsExerciseTimeUp = (exerciseId: string, minutes: number, onFinish: (v: boolean) => void, setTimer: (v: { time: number; exerciseId: string }) => void ) => {
    const { currentTimers, addCurrentTimer } = UseExerciseTimerModule.getState();
    
    if (currentTimers.find((x) => x === exerciseId)) {
        return;
    }
    addCurrentTimer(exerciseId);    
    let timerSec = Math.floor(minutes * 60);
    const timerInterval = setInterval(() => {
    if (timerSec === 0) {
        setTimer({ time: timerSec, exerciseId });
        clearInterval(timerInterval);
        onFinish(false);
    } else {
        onFinish(true);
        timerSec -= 1;
        setTimer({ time: timerSec, exerciseId });
    }}, 1000);
};

export const nextLesson = () => {
    const {lessonsId, currentLesson} = UseModeCourseModule.getState();
    if (lessonsId && lessonsId.length > 0 && currentLesson) {
        const nextId = lessonsId.find(x => currentLesson.order + 1 === x.order )?.id
        if (nextId) {
            window.location.replace(`/lesson/container?lesson=${nextId}&mode=STUDENT`);
        }
    }
};