import {FeedbackEvaluation, UseEvaluationCourseModule} from '../../../../../../courses/screen/store/main'
import {ExercisesType} from '../../../../../exercises/interfaces'

export async function getEvaluationResponses(resumeItems: any[]) {
  const {evaluationResponses, setEvaluationResponses} = UseEvaluationCourseModule.getState()
  if (evaluationResponses.length !== 0) return
  resumeItems.forEach((evaluation) => {
    const exercise = evaluation.exercise
    let feedback: FeedbackEvaluation = {
      percentage: 0,
      exercise_title: '',
      responses: [],
    }
    const emptyFeedback = feedback

    switch (exercise.type) {
      case ExercisesType.SIMPLE_SELECTION:
      case ExercisesType.SIMPLE_SELECTION_COMPLETION:
      case ExercisesType.TRUE_FALSE:
        feedback = {
          percentage: evaluation.feedback.percentage,
          exercise_title: evaluation.feedback.exercise_title,
          responses: evaluation.feedback.responses,
        }
        break

      case ExercisesType.DRAG_DROP:
        feedback = {
          percentage: evaluation.feedback.percentage,
          exercise_title: evaluation.feedback.exercise_title,
          responses: evaluation.feedback.responses,
          isDragDropExercise: evaluation.feedback.isDragDropExercise,
        }
        break
    }
    if (feedback !== emptyFeedback) {
      setEvaluationResponses(feedback)
    }
  })
}
