import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionAudioTest, QuestionAndAnswer } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { VoiceRecorderInput } from '../../../../../components/common/voice-recorder';
import ContentAudioRecord from '../components/audio/index'
import { UseAudioTestModule } from '../store';
type Props = {
    question: QuestionAudioTest
    modeQuiz: 'RESUME' | 'ANSWER' | 'THROW_ERROR' | 'TITLE'
    handleSendAnswer: (x: Answer) => void
    totalQuestions: number
};

const AudioTestItemRight: React.FC<Props> = ({
    question,
    modeQuiz,
    handleSendAnswer,
    totalQuestions,
}: Props) => {
    const { mode } = useThemeMode();
    const {instructions} = UseAudioTestModule();
    const { evaluationLesson } = UseEvaluationCourseModule();
    const [urlAudio, setUrlAudio] = React.useState<string | undefined>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [hoverString, setHoverString] = React.useState<any | null>(null);
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    const handleSend = () => {
        if (urlAudio) {
            handleSendAnswer({
                questionPercentage: question.percentage,
                audio: urlAudio,
                question: question.order,
                name: question.title
            })
            setUrlAudio(undefined)
        }

    };
    useEffect(() => {
        setUrlAudio(undefined)
    }, [question]);

    const title = question.title.split('*')

    return (
        <>

            <div className=" card-basic-vocabulary-completion"
                style={
                    mode !== 'dark' ? {
                        height: 420,

                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,

                        background: '#1e1e2d',
                        border: `1px solid`,
                        borderColor: '#3e94ff',
                        position: 'relative'
                    }
                }
            >
                <h2 className='champ-light title-basic-vocabulary-completion text-end'
                    style={{
                        color: evaluationLesson?.colorPrimary ?? '#3e94ff',
                    }}>
                    #{question.order}/{totalQuestions}
                </h2>

                <h3 className='poppins-light subtitle-basic-vocabulary-completion text'
                    style={{
                        color: evaluationLesson?.colorPrimary ?? '#3e94ff',
                    }}
                >
                    <strong style={{ fontWeight: 'bold' }}>Instrucciones: </strong>{instructions}
                </h3>
                <div>
                    <h2 className='champ-light fs-15 text mb-5D d-flex flex-column'
                        style={{
                            maxHeight: '150px',
                            color: evaluationLesson?.colorPrimary ?? '#3e94ff',
                            overflow: 'auto'
                        }}>
                        {title.map((t) => <span className='mb-1'>{t}</span>)}
                    </h2>
                </div>
                {urlAudio &&
                    <ContentAudioRecord
                        urlAudio={urlAudio}
                    />
                }
                {!urlAudio && <VoiceRecorderInput
                    maxTime={question.time??1}
                    onChangeFile={(e) => setUrlAudio(e.url)}
                    onChangeLoading={(e) => setIsLoading(e)}
                    idInput='audioVerification'
                />}

                {/* {modeQuiz === 'ANSWER' && !selected && question.answers.map((x, i) => (<h2 
                    key={i}
                    onClick={()=>{
                                handleClick();
                                setSelected(x)
                            }}
                    className='poppins-light-italic option-basic-vocabulary-completion'
                    >
                        <i className="bi bi-check icon-option-basic-vocabulary-completion" style={{ color: 'transparent'}}></i>
                       <span className='cursor-pointer custom-hover badge rounded-pill px-1 my-1'
                            style={{
                                color: evaluationLesson?.colorPrimary??'#3e94ff',
                                fontSize: 20
                            }}
                       > {i+1}.) {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))} </span>
                </h2>))}

                {selected && question.answers.map((x,i) => (<h2
                    className='poppins-light-italic option-basic-vocabulary-completion d-flex'
                    style={{ color: evaluationLesson?.colorPrimary??'#3e94ff',top:-15}}>
                         {x.isTrue ?
                        <i className="bi bi-check icon-option-basic-vocabulary-completion mb-1" style={{ color: 'green'}}></i>:
                        <i className="bi bi-x icon-option-basic-vocabulary-completion" style={{ color: 'red'}}></i>}
                            
                            {selected.order === x.order ?
                                <span className="badge rounded-pill my-1 px-1" style={{background:'#bdbeff', color:evaluationLesson?.colorPrimary??'#3e94ff',fontSize:20}}>
                                    {i+1}. {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))}
                                </span>
                                :  (<span className="badge rounded-pill my-1 px-1"
                                        style={{fontSize:20, color:evaluationLesson?.colorPrimary??'#3e94ff'}}
                                    >
                                    {i+1}. {x.title.map((y,index)=>(
                                        <>{y}{x.title.length===index+1?'':'/'}</>
                                    ))}
                                    </span>)
                            }
                </h2>))}
 */}
                {urlAudio && <>
                    <div
                        className='arrow-left-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={()=>setUrlAudio(undefined)}
                    >
                        <a href='#' className='poppins-light text-cursor-basic-vocabulary-completion-left' style={{ position: 'absolute' }}> back</a>

                        <img className='' src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                    </div>
                </>}
                {modeQuiz === 'ANSWER' && urlAudio && <>
                    <div
                        className='arrow-right-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => handleSend()}
                    >
                        <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />


                    </div>
                </>}
            </div>
        </>
    );
};

export { AudioTestItemRight };