import React, { useEffect } from 'react';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { enGB } from 'date-fns/locale';
type Props = {
    day: Date;
    changeDate: (date: Date) => void;
  }
const DayPickerSelector: React.FC<Props> = ({day,changeDate}: Props) => {

  const changeDateEvent = (e)=>{
    changeDate(e)
  }
  return (
    <DayPicker
      locale={enGB}
      mode="single"
      selected={day}
      onSelect={changeDateEvent}
    />
  );
}
export { DayPickerSelector }