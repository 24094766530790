/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { UseBookingDashboard } from '../../../store';
import { LeadsModal } from '../../modal';


const DashboardLeads: React.FC = () => {
  const {t} = useTranslation();
    const { itemSelected,modal, setLead,setTrialClass} = UseBookingDashboard();
  return (
  
    <div className='mb-10 card p-10'>
    <div className='fs-6 fw-bold mb-2 champ-light'>Students</div>
    <LeadsModal show={modal} handleClose={()=>alert("sd")}  />

    <div className='mh-500px scroll-y me-n7 pe-7'>
      {itemSelected?.trail_class.map((x, i) => {
        return (
          <div
            className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
            key={i}
          >
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-35px symbol-circle'>
              {/* <img alt='Pic' src={toAbsoluteUrl('/media/svg/icons/earth.svg')} /> */}
                 
                  <div className='symbol symbol-35px symbol-circle'>
                    <span
                      className={`symbol-label bg-light-  text-  fw-bold `}
                    >
                      {x.student_setting.user.email.charAt(0)}
                    </span>
                  </div>
               
              </div>

              <div className='ms-5'>
                <a
                  href='#'
                  className='poppins-light  text-gray-900 text-hover-primary mb-2'
                >
                  {x?.lesson ? x.lesson.name : 'Lección no asignada.'}
                  {/* {x.student_setting.user.fullName} */}
                </a>
               <div className='fw-bold text-muted'>
                  {x.student_setting.user.fullName}
                  {/* {x.student_setting && x.student_setting.user && x.student_setting.user.email} */}
               </div>
              </div>
            </div>
            <div className='ms-2 w-100px popyns-light'>
              Asistió:{x.assistantTeacher?'Si':'NO'} 
            </div>
            <div className='ms-2 w-100px popyns-light'>
              Asistió Tarde:{x.lateArrival?'Si':'NO'} 
            </div>
            <div className='ms-2 w-150px popyns-light'>
              Nivel: {x.student_setting && t(x.student_setting.level)}   
            </div>
            <div className='ms-2 w-150px'>
                {x && <button

                      onClick={()=>setTrialClass(x)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <i style={{ color: 'green', fontSize: 17 }} className="fas fa-list"></i>
                      </button>}
            </div>
          </div>
        )
      })}
    </div>
  </div>
  )
}

export {DashboardLeads}
