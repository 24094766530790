 import React from 'react';
 import HeaderCard from '../ui/HeaderCard';
 import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';

  type Props = {question:string}
  const ExerciseThirteen: React.FC<Props> = ({question}) => {
    const divStyle = {
      backgroundColor: '#3f8bff',
      width: '525px',
     
      borderRadius: '30px',
      borderColor: 'rgb(255, 255, 255)',
      borderStyle: 'solid',
      padding: '27px 20px 40px 20px'
    };
    const input= {
      display:'flex',
      with:'250px',
      borderRadius:'40px',
      border:'0px',
      backgroundColor:'#D0FF00',
      outline:'none',
      padding:'2px'
    }
    const arrayspan =[
    "There arein total.",
    "The bathroll.",
    "The parking spe house.",
    "The parking space i."
    ];
    const arrayspantwo =[
      "Right version:",
      "Right version:",
      "Right version:",
      "Right version:"
    ];

    return(
    <>
          <div className="container-fluid"style={{display:'flex'}}>
            <div style={divStyle}>
             <HeaderCard/>
                <p className="text-white p-3 fs-8 mb-0 poppins-light" style={{marginLeft:'10px'}}>{question}</p>
                  {arrayspan.map((x,y) => (
                    
                    <><div className=" row d-flex px-9 fs-8 text-white container-fluid ">
                          <div className="col-10 py-1 poppins-light fs-8">
                              <li className="mb-0">{x}</li>
                              <div className='d-flex flex-row'>
                                <div className="col-3"><span className="mb-0 fw-bold">{arrayspantwo[y]}</span></div>
                                <div className="col-9"><input className="" style={input} type="text"/></div>
                              </div>           
                          </div>             
                          <div className="col-2 d-flex ">
                            {/*validacion*/}
                          </div>
                      </div></>
                    ))}
            </div>
            <div className="position-relative" /*onClick={handleCheck}*/>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
          </div>
   </>)
};
export {ExerciseThirteen}