import { TraditionalLevelEnum } from "../../../../../../abroad/road-map-step/interfaces";
import { Exercises } from "../../../../interfaces";

export interface DataPaginateTrueOptionResponse {
    status:  string;
    message: string;
    data:    DataTrueOption;
}

export interface DataAllTrueOptionResponse {
    status:  string;
    message: string;
    data:    TrueOption[];
}

export interface DataTrueOption {
    id?: string;
    description?:string;
    name:string;
    level?: TraditionalLevelEnum;
    type?: TrueOptionType;
    options: any[];
    sentences: any[];
    exercises: string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface TrueOption  {
    id?: string;
    description?:string;
    name:string;
    level?: TraditionalLevelEnum;
    type?: TrueOptionType;
    options: string[];
    sentences: string[];
    exercises: Exercises;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: TrueOption[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum TrueOptionType {
    IMAGE_OPTION = 'IMAGE_OPTION',
    COMPLETE_SENTENCE = 'COMPLETE_SENTENCE',
    OPTION_WORD = 'OPTION_WORD'
}

export interface TrueOptionItem {
    title :string
    order:number
    letter:string
}


export interface TrueOptionSentence {
    title :string
    letter:string
}