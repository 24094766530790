import React, { useEffect } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useStudentSettingState, useUserState } from '../../hooks/useUserRepo'
import { UseUserModule } from '../../store/main'
import { FilterStudentSetting, User } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { UserDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase'
import IconFa from '../../../../../components/common/Icon/indext'
import { ButtonSelector } from '../../../../../components/common/ButtonSelector'
import useAuth from '../../../../../api/main'
import { UpgradeStudentModal } from '../modal/UpgradeStudentModal'
import { UseTrailModule } from '../../store/student/main'
import { StudentSettingsOneByUser } from '../../services/student/StudentSettingsService'
import { Dropdown1 } from '../../../../../../_metronic/partials'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export type StudentSettingsResponse = {
  response: any
  newMode: 'ADD' | 'EDIT' | 'VIEW'
}

type Props = {}
const StudentSettingsTableList: React.FC<Props> = ({ }) => {
  const queryClient = useQueryClient()
  const {
    mode,
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    type,
    filterStudent,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setWhere,
    setType,
    setToAdd,
    setSelectedStudent,
    setFilterStudent,
  } = UseUserModule()

  const { modalUpgrade } = UseTrailModule()
  const { data, isLoading } = useStudentSettingState()
  const { typeUser } = useAuth.getState()

  const memorizedValues = React.useMemo(() => ({
    isActive, where, currentPage, limit, filterStudent
  }), [isActive, where, currentPage, limit, filterStudent]);

  const deleteItem = async (id: string, isActive: boolean) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: isActive ? 'Yes, inactivate it!' : 'Yes, activate it',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await UserDelete(id)
          showSimpleAlert({ message: 'Ok!', icon: 'success' })
          queryClient.invalidateQueries({ queryKey: ['user'] })
        } catch (e) {
          console.error(e)
        }
      }
    })
  }
  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  }
  const setTypeUser = (type: any) => {
    setType(type)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['student-setting'] });
  }, [memorizedValues]);

  React.useEffect(() => {
    setTypeUser('STUDENT')
  }, []);

  return (
    <>
      <div className={`card `}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Students</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de estudiantes </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <select
              name='teacher'
              value={filterStudent}
              onChange={(e) => setFilterStudent(e.target.value as FilterStudentSetting)}
              className='form-select-sm'
              aria-label='Default select example'
            >
              {Object.keys(FilterStudentSetting).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
            <div className='position-relative my-1'>

              <KTSVG
                path='/media/svg/icons/earth.svg'
                className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
              />
              <input
                type='text'
                onChange={(e) => setWhere(e.target.value)}
                className='form-control form-control-sm form-control-solid w-350px ps-10'
                name='Search'
                value={where}
                placeholder='Search'
              />
            </div>
            <button
              onClick={() => setToAdd()}
              className='btn btn-sm btn-light-primary mr-8'
              disabled={!(typeUser === 'ROOT' || typeUser === 'ADMIN' || typeUser === 'ASSISTANT')}
            >
              <KTIcon iconName='plus' className='fs-3' />
            </button>
            <button
              onClick={() => setIsActive(!isActive)}
              className='btn btn-sm btn-light-secondary  mr-8 p-2'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            >
              <BooleanCell value={isActive} />
            </button>
          </div>

        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {isLoading && (
            <div className='text-center'>
              <img
                className='rounded mx-auto d-block'
                width={'30%'}
                src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                alt=''
              />
            </div>
          )}
          {/* begin::Table container */}
          {!isLoading && data && (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-140px'>Name</th>
                    <th className='min-w-120px'>Number</th>
                    <th className='min-w-120px'>Active</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data.result.map((x: any) => (
                    <tr key={x.id} className='p-0'>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.user.fullName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.user.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {x.user.phoneNumber}
                        </span>
                      </td>
                      <td className='text-start'>
                        <BooleanCell value={x.isActive} />
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                          <OverlayTrigger overlay={<Tooltip>View</Tooltip>} placement='top'>
                            <button
                              title={`View`}
                              onClick={async () => {
                                // const {response, newMode} = await responseStudentSettings(
                                //   x.user.id ?? '',
                                //   'VIEW'
                                // )
                                setSelectedStudent(x, 'VIEW')
                                setSelectedToView(x.user)
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i style={{ color: 'grey', fontSize: 17 }} className=' fas fa-eye'></i>
                            </button>
                          </OverlayTrigger>

                          <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>} placement='top'>
                            <button
                              onClick={async () => {
                                // const {response, newMode} = await responseStudentSettings(
                                //   x.user.id ?? '',
                                //   'EDIT'
                                // )
                                setSelectedToEdit(x.user)
                                setSelectedStudent(x, 'EDIT')
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i style={{ color: 'blue', fontSize: 17 }} className='fas fa-pen'></i>
                            </button>
                          </OverlayTrigger>

                          {/* <OverlayTrigger overlay={<Tooltip>{x.isActive? "Inactivate" : "Activate"}</Tooltip>} placement='top'>
                            <button
                              onClick={() => {
                                deleteItem(x.id ?? 'xxx', x.isActive)
                              }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                          <BooleanCell value={!x.isActive} />

                            </button>
                          </OverlayTrigger> */}
                          {/* Desplegable de botones*/}

                          <div className='btn-group'>
                            <OverlayTrigger overlay={<Tooltip>More actions</Tooltip>} placement='top'>
                              <button
                                type='button'
                                data-bs-toggle='dropdown'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i
                                  style={{ color: 'grey', fontSize: 17 }}
                                  className='fas fa-ellipsis-h'
                                />
                              </button>
                            </OverlayTrigger>
                            <div className='dropdown-menu'>
                              <section className='d-flex'>
                                {/* <button
                                  title='Upgrade'
                                  onClick={async () => {
                                    setModalUpgradeStudent(true)
                                    const {response, newMode} = await responseStudentSettings(
                                      x.id ?? '',
                                      'EDIT'
                                    )
                                    setSelectedStudent(response, newMode)
                                  }}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                >
                                  <i
                                    style={{color: 'green', fontSize: 17}}
                                    className='fa-sharp fa-solid fa-circle-up'
                                  />
                                </button> */}

                                <OverlayTrigger overlay={<Tooltip>Freezing trail</Tooltip>} placement='bottom'>
                                  <button
                                    style={{ cursor: typeUser === 'ADMIN' ? 'pointer' : 'not-allowed' }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                    onClick={() =>
                                      (window.location.href = `/student-actions/trail-freezing/${x.user.id}`)
                                    }
                                    disabled={typeUser !== 'ADMIN'}
                                  >
                                    <img
                                      style={{ width: 15, height: 15 }}
                                      src={toAbsoluteUrl(
                                        '/media/svg/michigan-icons/sidebar/color/freezing.svg'
                                      )}
                                    ></img>
                                  </button>
                                </OverlayTrigger>

                                <OverlayTrigger overlay={<Tooltip>Cancel Booking</Tooltip>} placement='bottom'>
                                  <button
                                    style={{ cursor: typeUser === 'ADMIN' ? 'pointer' : 'not-allowed' }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                    onClick={() =>
                                      (window.location.href = `/student-actions/booking/${x.user.id}`)
                                    }
                                    disabled={typeUser !== 'ADMIN'}
                                  >
                                    <i className='bi bi-x-circle' style={{ color: 'red' }} />
                                  </button>
                                </OverlayTrigger>

                                <OverlayTrigger overlay={<Tooltip>Reservation History</Tooltip>} placement='bottom'>
                                  <button
                                    style={{ cursor: typeUser === 'ADMIN' ? 'pointer' : 'not-allowed' }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                    onClick={() =>
                                      (window.location.href = `/student-actions/report/${x.user.id}`)
                                    }
                                    disabled={typeUser !== 'ADMIN'}
                                  >
                                    <i className='bi bi-clock-history' style={{ color: 'orange' }} />
                                  </button>
                                </OverlayTrigger>

                                {/* <OverlayTrigger overlay={<Tooltip>Activities History</Tooltip>} placement='bottom'>
                                  <button
                                    style={{cursor: typeUser === 'ADMIN' ? 'pointer' : 'not-allowed'}}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                    onClick={() =>
                                      (window.location.href = `/student-actions/trail-activities/${x.id}`)
                                    }
                                    disabled={typeUser !== 'ADMIN'}
                                  >
                                    <i className="bi bi-layout-text-sidebar" style={{color: 'purple'}}/>
                                  </button>
                                </OverlayTrigger> */}

                                <OverlayTrigger overlay={<Tooltip>Promotion History</Tooltip>} placement='bottom'>
                                  <button
                                    style={{ cursor: typeUser === 'ADMIN' ? 'pointer' : 'not-allowed', background: x?.isSuspended ? '#DC3546' : '' }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                    onClick={() =>
                                      (window.location.href = `/student-actions/promotion-history/${x.user.id}`)
                                    }
                                    disabled={typeUser !== 'ADMIN'}
                                  >
                                    <i className="bi bi-graph-up-arrow" style={{ color: 'blue' }} />
                                  </button>
                                </OverlayTrigger>

                                <OverlayTrigger overlay={<Tooltip>Change Status</Tooltip>} placement='bottom'>
                                  <button
                                    style={{ cursor: typeUser === 'ADMIN' ? 'pointer' : 'not-allowed' }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm m-2'
                                    onClick={() =>
                                      (window.location.href = `/student-actions/status-manager/${x.id}`)
                                    }
                                    disabled={typeUser !== 'ADMIN'}
                                  >
                                    <i className="fa-solid fa-temperature-three-quarters" style={{ color: 'blue' }} />
                                  </button>
                                </OverlayTrigger>

                              </section>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              <GenericPaginator
                limit={limit}
                currentPage={paginator.current_page}
                items={paginator.result.length}
                total={paginator.total}
                totalPage={paginator.total_page}
                changePage={changePage}
                changeLimit={changeLimit}
              />
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CreateModal show={modal} handleClose={() => alert()} />
      <UpgradeStudentModal show={modalUpgrade} handleClose={() => alert()} />
    </>
  )
}

export { StudentSettingsTableList }

