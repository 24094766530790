import React, { useEffect } from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { UseBasicVocabularyModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';

type Props = {
    titleContent: string;
    descriptionContent: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
};

const BasicVocabularyFullExercise: React.FC<Props> = ({ titleContent, descriptionContent, colorSecondary, renderType }) => {
    let {
        questions,
        setStep,
        step,
        setAnswer,
        status,
        falseAnswer,
        trueAnswer,
        setStatus,
        startQuiz,
    } = UseBasicVocabularyModule();

    useEffect(()=>{
        console.log(status);
    },[status])

    return (
        <>
            {renderType && renderType === 'left' ?
            <>
                {/* <h3
                    style={{ fontSize: 30, paddingLeft: 30, paddingTop: 20 }}
                    className='champ-light text-white'>
                        Lorem Ipsum
                </h3>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center', // Centra los elementos horizontalmente
                    gap: '10px', // Espaciado entre elementos
                }}>
                    
                </div> */}
            </>
            : <>
                {/* {modeCourse && !modeTest && questionsEvaluation?.exercise?.fileContent ?
                status != 'RESUME' && status != 'TITLE' &&
                    <BasicVocabularyExerciseItem
                        falseAnswer={falseAnswer[step]}
                        question={questions[step]}
                        handleNext={(e) => setAnswer(e)}
                        handleSendAnswer={(e) => setAnswer(e)}
                        modeQuiz={status}
                        totalQuestions={questions.length}
                        setStatus={(e) => setStatus(e)}
                        setStep={(e) => setStep(step + e)}

                    />
                : <></>} */}
                    {/* {!modeCourse && modeTest && questionsEvaluationTest?.exercise?.fileContent ? */}
                { status !== 'RESUME' && status !== 'TITLE' &&
                    <BasicVocabularyExerciseItem
                        falseAnswer={falseAnswer[step]}
                        question={questions[step]}
                        handleNext={(e) => setAnswer(e)}
                        handleSendAnswer={(e) => setAnswer(e)}
                        modeQuiz={status}
                        totalQuestions={questions.length}
                        setStatus={(e) => setStatus(e)}
                        setStep={(e) => setStep(step + e)}
                        description={descriptionContent}
                    />}
                {status === 'RESUME' &&
                    <BasicVocabularyExerciseResume
                        total={questions.length}
                        correct={trueAnswer.length}
                        incorrect={falseAnswer.length}
                        setStatus={(e) => setStatus(e)}
                        correctAnswer={trueAnswer}
                        falseAnswer={falseAnswer}
                    />}
                {status === 'TITLE' &&
                    <IntroBasicVocabulary
                        title={titleContent}
                        instructions={descriptionContent}
                        startQuiz={() => startQuiz()}
                    />
                }
            </>}

            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>

        </>
    );
};


export default React.memo(BasicVocabularyFullExercise);