import {Route, Routes} from 'react-router-dom'
import { ResolutionCertificateDetails } from '../components/details'
import { ResolutionCertificateTableList } from '../components/list'


const ResolutionCertificatePage = () => (
  <Routes>
      <Route path='/details' element={<ResolutionCertificateDetails />} />
      <Route path='/' element={<ResolutionCertificateTableList />} />
  </Routes>
)

export {ResolutionCertificatePage}
