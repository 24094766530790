import moment from 'moment'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'
import {setTypeToSpanish} from '../../../../user/utils/setTypeToSpanish'

type Props = {
  data: any
}

const QualifyWelcomeDayForm: React.FC<Props> = ({data}: Props) => {
  const {setSelectedToView} = UseQualifyPlacementModule()
  return (
    <>
      <table className='table'>
      <thead className=''>
          <tr className={`fs-5 fw-bold bg-light poppins-bold`}>
            <th className='text-center align-middle'>Student</th>
            <th className='text-center align-middle'>Document</th>
            <th className='text-center align-middle'>Test code</th>
            <th className='text-center align-middle'>Status</th>
            <th className='text-center align-middle'>Date</th>
            {/* <th className='text-center align-middle'>Qualified?</th> */}
          </tr>
        </thead>
        <tbody className=''>
          {data?.result?.map((x: any) => (
            <tr className={`cursor-pointer `} onClick={() => setSelectedToView(x)}>
              <td className='text-center align-middle'>{x.student_name}</td>
              <td className='text-center align-middle'>{x.student_setting.user.document_id}</td>
              <td className='text-center align-middle'>{x.student_setting.code}</td>
              <td
                className={`text-center align-middle 
                  text-${
                      x?.status === 'PENDING'
                    ? 'primary'
                    : x?.status === 'QUALIFIED'
                    ? 'warning'
                    : x?.status === 'APPROVED' 
                    ? 'success'
                    :x?.status === 'REPROVED'
                    ? 'danger'
                    : 'dark'
                  }`
                }
              >
                { x?.status === 'PENDING'
                  ? 'Asignado'
                  : x?.status === 'QUALIFIED'
                  ? 'Calificado'
                  : x?.status === 'APPROVED' 
                  ? 'Aprobado'
                  :x?.status === 'REPROVED'
                  ? 'Reprobado'
                  : 'dark'
                }
              </td>
              <td className='text-center align-middle'><span className='poppins-light fw-bold'>{moment(x?.createdAt).format('DD/MM/YYYY')}</span></td>
              {/* <td className='text-center align-middle'>
                <span className='poppins-light fw-bold'>
                  <BooleanCell value={true} />
                </span>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default QualifyWelcomeDayForm
