export const LessonTranslateEn = {
  LESSONS: 'Units',
  LESSON: 'Unit',
  MANAGEMENT_LESSONS:"Units Management",
  TYPE:"Type",
  NAME:"Name",
  DESCRIPTION:"Description",
  ACTIVE:"Active",
  ACTIONS:"Actions",
  SEQUENCE:"Sequence",
  LEVEL:"Level",
  PRE_A1_1: 'Pre A1-1',
  PRE_A1_2: 'Pre A1-2',
  A1: 'A1',
  PRE_A2: 'Pre A2',
  A2: 'A2',
  PRE_B1: 'Pre B1', 
  B1: 'B1',
  B2: 'B2',
  C1: 'C1'
}