// Importa las dependencias necesarias
import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { UseModeCourseModule } from '../../../../modules/courses/components/front-page/store/main';

// Define la interfaz para las propiedades del componente
interface CardProps {
  imageUrl: string;
  title: string;
  className?: string;
  url?: string;
}

// Componente de tarjeta
const CardCourse: React.FC<CardProps> = ({ imageUrl, title, className, url }) => {
  const navigate = useNavigate();
  const {setModeCourse} = UseModeCourseModule();
  const handleClick = () => {
    if (url) {
      navigate(url);
      setModeCourse(true);
    }
    return;
  };
  return (
    <Card style={{}} 
      onClick={handleClick} 
      className={`${typeof url !== 'undefined' && 'cursor-pointer'}`}>
      <Card.Img
      style={{borderRadius:30}}
      variant="top" height={'375'} src={imageUrl} className={`card-img-top ${className}`} alt={`Imagen de ${title}`} />
    </Card>
  );
};

export default CardCourse;