import { DragDropQuestions, DataAllDragDropQuestionsResponse, DataPaginateDragDropQuestionsResponse } from '../interfaces/index'
import useAuth from '../../../../../../api/main'

import { catchServerError } from '../../../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseDragDropQuestionsModule } from '../store/main'
import { api } from '../../../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useDragDropQuestionsAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/drag-drop-questions/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const UseDragDropQuestionsAllPaginate = async (id: string): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseDragDropQuestionsModule.getState()

    const response: any = await api.get(
      `/drag-drop-questions/by-exercise?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&root=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const DragDropQuestionsAdd = async (data: DragDropQuestions): Promise<any> => {
  try {
    const response: any = await api.post(
      '/drag-drop-questions',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const DragDropQuestionsUpdate = async (data: DragDropQuestions): Promise<any> => {
  try {
    const { itemSelected } = UseDragDropQuestionsModule.getState()
    const response: any = await api.patch(
      `/drag-drop-questions/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const DragDropQuestionsDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/drag-drop-questions/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
