import { api } from '../../../../auth/services/auth.services';
import useAuth from '../../../../../api/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseUserModule } from '../../store/main'
import { TrailUpgradeStudent } from '../../interfaces';
import { PromotionHistoryType } from '../../../student-actions/promotion-history/interfaces';

const { token, logout, authChecked } = useAuth.getState()

type Props = {
  root: string 
  isActive: boolean 
  before?: Date, 
  after?: Date, 
  limit: number, 
  page: number
}

export const UpgradeStudentAdd = async (data: TrailUpgradeStudent): Promise<any> => {
    try {
      const response: any = await api.post(
        '/trail-upgrade-student',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }

  export const UpgradeStudentUpdate = async (data: TrailUpgradeStudent): Promise<any> => {
    try {
      const { itemSelected } = UseUserModule.getState()
      const response: any = await api.patch(
        `/trail-upgrade-student/${itemSelected?.id}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }

  export const UpgradeStudentAll = async (): Promise<any> => {
    try {
      const response: any = await api.patch(
        `/trail-upgrade-student`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }

export const UseTrailUpgradeStudent = async ({root, isActive, before, after, limit, page }:Props): Promise<any> => {
  
  try {
    const response = await api.get(`/trail-upgrade-student/user?root=${root}&isActive=${isActive?1 :0}&before=${before}&after=${after}&limit=${limit}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const FindHoursExperienceByStudent = async (id:string): Promise<any> => {
  try {
    const response = await api.get(`/trail-upgrade-student/find-hours/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data;
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UpgradeStudentManual = async (id:string, newStep: string, promotionType: PromotionHistoryType, date: string, hasLessons: boolean, taskId: string, additionalHours: number ,description?: string): Promise<any> => {
  try {
    const response = await api.patch(`/trail-upgrade-student/by-experience`,
    {...{
      studentSetting: id,
      newCurrentStep: newStep,
      promotionType: promotionType,
      date: date,
      hasLessons: hasLessons,
      taskId: taskId,
      description : description,
      additionalHours: additionalHours,
    }}, 
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data;
  } catch (e) {
    console.log("esta es bandera",e);
    
    //@ts-ignore
    showSimpleAlert({ message: e.response.data.message, icon: 'error' })
    //@ts-ignore
    catchServerError(e.response)
  }
}