import { BlockHour } from "../../block-hours/interfaces";

export interface DataPaginateTemplateTableTimeResponse {
    status:  string;
    message: string;
    data:    DataTemplateTableTime;
}

export interface DataAllTemplateTableTimeResponse {
    status:  string;
    message: string;
    data:    TemplateTableTime[];
}

export interface DataTemplateTableTime {
    id?: string;
    description:string; // 
    name:string; // 
    isActive: boolean; // 
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface TemplateTableTime  {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: TemplateTableTime[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface TemplateTableTimeItem {
    id?: string;
    isActive: boolean;
    activity: any;
    template: any;
    teacher: any;
    classroom: any;
    day: TemplateTableTimeDay;
    blockHour: number[];
}

export enum TemplateTableTimeDay {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY'
}

export interface TemplateTableTimeItemData {
    id?: string;
    isActive: boolean;
    day: TemplateTableTimeDay;
    createdAt: string;
    updatedAt: string;
    teacher: any;
    classroom: any;
    activity: any;
    template: any;
    blockHour: BlockHour[];
};
