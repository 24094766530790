import React, { FC } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { NotificationList } from '../../../../app/modules/notifications/components/NotificationList'
import useAuth from '../../../../app/api/main'

const ActivityDrawer: FC = () => {

  const { notifications, resetNotification } = useAuth();

  return (
    <div
      id='kt_activities'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'lg': '400px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_activities_toggle'
      data-kt-drawer-close='#kt_activities_close'
    >
      <div className='card shadow-none rounded-0'>
        {/* <div className='card-header' id='kt_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>Notificaciones</h3>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-active-light-primary me-n5'
              id='kt_activities_close'
            >
              <KTIcon iconName='cross' className='fs-1' />
            </button>
          </div>
        </div> */}
        <div className='card-body position-relative' id='kt_activities_body'>
          <div
            id='kt_activities_scroll'
            className='position-relative scroll-y me-n5 pe-5'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_activities_body'
            data-kt-scroll-dependencies='#kt_activities_header, #kt_activities_footer'
            data-kt-scroll-offset='5px'
          >
            <img
              src={toAbsoluteUrl('/media/svg/michigan-icons/notifications/blue/notifications.svg')}
              alt=''
              style={{ cursor: 'pointer', transition: 'transform 0.2s ease-in-out', width: 50, marginTop: 70 }}
            />
            <h1 className='poppins-light' style={{ color: '#2d30e1' }}>Notifications</h1>

            <h4 className='poppins-bold' style={{ color: '#2d30e1', marginTop: 30 }}>News</h4>
            <div className='timeline'>
              { notifications.length > 0 && <>
                {notifications?.map((x,i) => (
                  <NotificationList key={i} data={x} />
                ))}
              </>}
            </div>
            <a href="#" className='poppins-light' onClick={()=>resetNotification()}>  Mark as views  <i className='fa fa-eye'></i></a>
            <a href="#" className='poppins-light' onClick={()=>resetNotification()}> History<i className='fa fa-history'></i></a>
           
          </div>
        </div>
        {/* <div className='card-footer py-5 text-center' id='kt_activities_footer'>
          <Link to='/crafted/pages/profile' className='btn btn-bg-body text-primary'>
            View All Activities
            <KTIcon iconName='arrow-right' className='fs-3 text-primary' />
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export { ActivityDrawer }
