import { Campus } from "../../../abroad/campus/interfaces";
import { Country } from "../../country/interfaces";

export interface DataPaginateClassroomResponse {
    status:  string;
    message: string;
    data:    DataClassroom;
}

export interface DataAllClassroomResponse {
    status:  string;
    message: string;
    data:    Classroom[];
}

export interface DataClassroom {
    id?: string;
    name: string
    address: string
    description: string
    url: string
    country:any
    isActive: boolean;
    campus: string;
    type: string
    createdAt?: string
    updatedAt?: string
 
}

export interface Classroom  {
    id?: string;
    name: string
    address: string
    description: string
    url: string
    isActive: boolean
    campus: Campus;
    type: string
    country:any
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: Classroom[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum ClassroomType {
    VIRTUAL = 'VIRTUAL',
    FACE = 'FACE'
}