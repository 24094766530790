import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';

type Props = {
  logoCourse: string;
  colorCourse: string;
  lessonName: string;
};

const HeaderBook: React.FC<Props> = ({logoCourse, colorCourse, lessonName}) => {
  const navigate = useNavigate();
  return(<>
  <div
    style={{ 
      position: 'fixed', 
      top: 65, 
      left: 50, 
      display: 'grid', 
      gridTemplateRows: 'auto 1fr auto', 
      zIndex: 2
  }}
  >
    <div className='d-flex'>
      <div onClick={() => navigate(-1)} className='me-5 cursor-pointer'>
        <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/blue/back_blue.svg')} alt=""
          style={{width: '50px'}}
        />
      </div>
      <img src={logoCourse} alt="" 
        style={{width: '130px'}}
        className='me-5'
      />
      <div className='d-flex align-items-center me-5'>
            <div className='' style={{width:'2px', height: '80%', backgroundColor: colorCourse}}></div>
      </div>
      <div className='d-flex align-items-center'>
          <span className='poppins-light fs-2' 
            style={{color: colorCourse}}>
              {lessonName}
          </span>
      </div>
    </div>
  </div>
  </>)
};

export {HeaderBook};