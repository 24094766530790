import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionVerificationTest, QuestionAndAnswer } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { VoiceRecorderInput } from '../../../../../components/common/voice-recorder';
import ContentAudioRecord from '../components/audio/index'
import FileUploadComponent from '../../../../../components/common/SingleFile';
import { favIconAppSelector } from '../../../../../hooks/useAppSelectorAssetsMode';
type Props = {
    question: QuestionVerificationTest
    handleNext: (x: number) => void
    handleSendAnswer: (x: Answer) => void
    modeQuiz: 'RESUME' | 'ANSWER' | 'THROW_ERROR' | 'TITLE'
    setStatus: (string) => void
    setStep: (number) => void
    handleSelected: (boolean) => void
    totalQuestions: number
};

const AudioTestItemRight: React.FC<Props> = ({
    setStep,
    setStatus,

    question,
    handleNext,
    modeQuiz,
    handleSendAnswer,
    totalQuestions,
    handleSelected
}: Props) => {
    const { mode } = useThemeMode();
    const { evaluationLesson } = UseEvaluationCourseModule();
    const [urlAudio, setUrlAudio] = React.useState<string | undefined>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [cover, setCover] = React.useState<any>()
    const [hoverString, setHoverString] = React.useState<any | null>(null);
    const [playbackRate] = React.useState(0.75);
    const [loading, setLoading] = React.useState<boolean>(false)
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    const handleSend = () => {
        if (urlAudio) {
            handleSendAnswer({
                answer: urlAudio,
                number: question.order,
                type: question.type,
                question: question.name
            })
            setUrlAudio(undefined)
        }

    };
    useEffect(() => {
        setUrlAudio(undefined)
    }, [question]);
    const changeAvatar = async (obj: any) => {
        setUrlAudio(obj.url)
    }

    return (
        <>

            <div className=" card-basic-verification-test"
                style={
                    mode !== 'dark' ? {
                        height: 420,

                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,

                        background: '#1e1e2d',
                        border: `1px solid`,
                        borderColor: '#3e94ff',
                        position: 'relative'
                    }
                }
            >
                <h2 className='champ-light title-basic-vocabulary-completion text-end'
                    style={{
                        color: evaluationLesson?.colorPrimary ?? '#3e94ff',
                    }}>
                    #{question.order}/{totalQuestions}
                </h2>


                <h4 className='poppins-light subtitle-basic-vocabulary-completion text'
                    style={{
                        color: evaluationLesson?.colorPrimary ?? '#3e94ff',
                    }}
                >
                </h4>
                <h2 className='champ-light title-basic-verification-test text mb-5D'
                    style={{
                        color: evaluationLesson?.colorPrimary ?? '#3e94ff',
                    }}>
                    {question.name}
 
                </h2>


                {question.type === 'YES_OR_NOT' && <>
                    <div className="container mt-5 text-center">
                        <div className="d-flex justify-content-center gap-3">

                            <button onClick={() => setUrlAudio('Si')} type="button" className="btn btn-success btn-lg champ-light">
                                <i className="bi bi-check-circle-fill me-2"></i>Si
                            </button>

                            <button onClick={() => setUrlAudio('No')} type="button" className="btn btn-danger btn-lg champ-light">
                                <i className="bi bi-x-circle-fill me-2"></i>No
                            </button>
                        </div>
                    </div>
                </>}

                {question.type === 'TEXT' && <>

                    <label className='form-label mb-3'>Description</label>
                    <textarea
                        className='form-control form-control-lg form-control-solid'
                        placeholder=''

                        name={'description'}
                        value={urlAudio}
                        onChange={(e) => setUrlAudio(e.target.value)}
                    />

                </>}

                {question.type === 'AUDIO' && <>
                    {urlAudio &&
                        <ContentAudioRecord
                            urlAudio={urlAudio}
                        />
                    }
                    {!urlAudio && <VoiceRecorderInput
                        onChangeFile={(e) => setUrlAudio(e.url)}
                        onChangeLoading={(e) => setIsLoading(e)}
                        idInput='audioVerification'
                    />}
                </>}

                {question.type === 'IMAGE' && <>

                    <div className="container mt-5 text-center">
                        <div className="d-flex justify-content-center gap-3">

                            {(urlAudio) ?
                                <img className="img-fluid" width={'200px'} src={(urlAudio) ?? toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
                                :
                                <img className="img-fluid" width={'200px'} src={toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
                            }
                            <FileUploadComponent
                                pushCover
                                onChangeFile={(e) => changeAvatar(e)}
                                onChangeLoading={(e) => setLoading(e)} />
                        </div>
                    </div>


                </>}

                {/* {modeQuiz === 'ANSWER' && !selected && question.answers.map((x, i) => (<h2 
                    key={i}
                    onClick={()=>{
                                handleClick();
                                setSelected(x)
                            }}
                    className='poppins-light-italic option-basic-vocabulary-completion'
                    >
                        <i className="bi bi-check icon-option-basic-vocabulary-completion" style={{ color: 'transparent'}}></i>
                       <span className='cursor-pointer custom-hover badge rounded-pill px-1 my-1'
                            style={{
                                color: evaluationLesson?.colorPrimary??'#3e94ff',
                                fontSize: 20
                            }}
                       > {i+1}.) {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))} </span>
                </h2>))}

                {selected && question.answers.map((x,i) => (<h2
                    className='poppins-light-italic option-basic-vocabulary-completion d-flex'
                    style={{ color: evaluationLesson?.colorPrimary??'#3e94ff',top:-15}}>
                         {x.isTrue ?
                        <i className="bi bi-check icon-option-basic-vocabulary-completion mb-1" style={{ color: 'green'}}></i>:
                        <i className="bi bi-x icon-option-basic-vocabulary-completion" style={{ color: 'red'}}></i>}
                            
                            {selected.order === x.order ?
                                <span className="badge rounded-pill my-1 px-1" style={{background:'#bdbeff', color:evaluationLesson?.colorPrimary??'#3e94ff',fontSize:20}}>
                                    {i+1}. {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))}
                                </span>
                                :  (<span className="badge rounded-pill my-1 px-1"
                                        style={{fontSize:20, color:evaluationLesson?.colorPrimary??'#3e94ff'}}
                                    >
                                    {i+1}. {x.title.map((y,index)=>(
                                        <>{y}{x.title.length===index+1?'':'/'}</>
                                    ))}
                                    </span>)
                            }
                </h2>))}
 */}
                {urlAudio && <>
                    <div
                        className='  arrow-left-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => setUrlAudio(undefined)}
                    >
                        <a href='#' className='poppins-light text-cursor-basic-vocabulary-completion-left' style={{ position: 'absolute' }}> back</a>

                        <img className='' src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                    </div>
                </>}
                {modeQuiz === 'ANSWER' && urlAudio && <>
                    <div
                        className='arrow-right-basic-vocabulary-completion cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => handleSend()}
                    >
                        <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />


                    </div>
                </>}
            </div>
        </>
    );
};

export { AudioTestItemRight };