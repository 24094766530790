import React from 'react'
import {BookPage} from '../../../admin/book/modules/book-page/interfaces'
import useScrollAndZoom from '../../../../hooks/useScrollAndZoom';
import useImageLoader from '../../../../hooks/useImageLoader';

type Props = {
  pagesBook: BookPage[];

}

const MultiplePages: React.FC<Props> = ({pagesBook}) => {

  const containerLeftRef = React.useRef<HTMLDivElement>(null)
  const containerRightRef = React.useRef<HTMLDivElement>(null)

  const leftImages = pagesBook.filter((_, i) => i % 2 === 0).map(page => page.file.location);
  const rightImages = pagesBook.filter((_, i) => i % 2 !== 0).map(page => page.file.location);

  const { loaded: leftLoaded, images: leftLoadedImages } = useImageLoader(leftImages);
  const { loaded: rightLoaded, images: rightLoadedImages } = useImageLoader(rightImages);

  const {
    handleScroll: handleLeftScroll,
    handleMouseDown: handleLeftMouseDown,
    handleMouseMove: handleLeftMouseMove,
    handleMouseUp: handleLeftMouseUp,
    zoom: zoomLeft,
    isDragging: isDraggingLeft
  } = useScrollAndZoom(containerLeftRef);

  const {
    handleScroll: handleRightScroll,
    handleMouseDown: handleRightMouseDown,
    handleMouseMove: handleRightMouseMove,
    handleMouseUp: handleRightMouseUp,
    zoom: zoomRight,
    isDragging: isDraggingRight
  } = useScrollAndZoom(containerRightRef);

  if (!leftLoaded || !rightLoaded) {
    return <div
    className='d-none d-lg-flex justify-content-center wrap'
    id="book-container"
    style={{
      position: 'fixed',
      top: 120,
      left: 160,
      right: 296,
      bottom: 20,
      backgroundColor: '#ffffff',
      borderRadius: '50px',
      display: 'flex',
    }}
  > <span className='indicator-progress' style={{display: 'block'}}>
      <span className='spinner-border spinner-border-lg align-middle'></span>
    </span>
  </div>;
  }
  return (
    <div
    className='d-none d-lg-flex justify-content-center wrap'
    id="book-container"
    style={{
      position: 'fixed',
      top: 120,
      left: 160,
      right: 296,
      bottom: 20,
      backgroundColor: '#ffffff',
      borderRadius: '50px',
      display: 'flex',
    }}
  >
    <div
      ref={containerLeftRef}
      className='page-container d-flex justify-content-end'
      style={{
        flex: '1 1 50%',
        maxWidth: '50%',
        maxHeight: '100%',
        overflow: 'auto',
        position: 'relative',
        backgroundColor: '#ffffff',
        borderRadius: '50px',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }}
      onWheel={handleLeftScroll}
      onMouseDown={handleLeftMouseDown}
      onMouseMove={handleLeftMouseMove}
      onMouseUp={handleLeftMouseUp}
    >
      {leftLoadedImages.map((img, i) => (
        <img
          loading='lazy'
          key={i}
          draggable
          onDragStart={(e) => e.preventDefault()}
          src={img.src}
          alt=''
          style={{
            width: 'auto',
            height: '100%',
            transform: `scale(${zoomLeft / 100})`,
            transformOrigin: 'top left',
            cursor: isDraggingLeft ? 'grabbing' : 'grab',
          }}
        />
      ))}
    </div>

    <div
      ref={containerRightRef}
      className='page-container d-flex justify-content-start'
      style={{
        flex: '1 1 50%',
        maxWidth: '50%',
        maxHeight: '100%',
        overflow: 'auto',
        position: 'relative',
        backgroundColor: '#ffffff',
        borderRadius: '50px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      }}
      onWheel={handleRightScroll}
      onMouseDown={handleRightMouseDown}
      onMouseMove={handleRightMouseMove}
      onMouseUp={handleRightMouseUp}
    >
      {rightLoadedImages.map((img, i) => (
        <img
          loading='lazy'
          key={i}
          src={img.src}
          alt=''
          draggable
          onDragStart={(e) => e.preventDefault()}
          style={{
            width: 'auto',
            height: '100%',
            transform: `scale(${zoomRight / 100})`,
            transformOrigin: 'top left',
            cursor: isDraggingRight ? 'grabbing' : 'grab',
          }}
        />
      ))}
    </div>
  </div>
  )
}

export default MultiplePages
