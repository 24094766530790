/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {TrailFreezingForm} from '../forms'
import {UseTrailFreezingModule} from '../../store/main'
import moment from 'moment'
type Props = {
  show: boolean
  handleClose: () => void;
  dataTrailClass: any[]
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AssistantClassModal = ({show, handleClose, dataTrailClass}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const {modal, mode,setModal,resetData} = UseTrailFreezingModule();
  const submit = () => {
    window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal-lg'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Clases Asistidas</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModal(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className={`card`}>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold bg-light'>
                                <th className=' champ-light ps-4 min-w-200px rounded-start'>Clase</th>
                                <th className=' champ-light min-w-100px'>Fecha</th>
                                <th className=' champ-light min-w-100px'>Hora</th>
                                <th className=' champ-light min-w-100px'>Profesor</th>
                                <th className=' champ-light min-w-100px text-start rounded-end'>Estrellas</th>
                            </tr>
                        </thead>
                        <tbody>
                          {dataTrailClass?.map((x) => (
                            <tr>
                              <td>
                                  <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                          <a href='#' className='text-dark champ-light fw-bold bold text-gray-900 text-hover-primary mb-1 fs-6'>
                                              {x?.title}
                                          </a>
                                      </div>
                                  </div>
                              </td>
                              <td>
                                <span className='d-flex justify-content-center champ-bold fw-bold d-block mb-1 fs-6'>
                                  {x?.start?.split(' ')[0]}
                                </span>
                              </td>
                              <td>
                                  <span className='text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                                      {`${moment(x?.time?.start).format('DD/MM/YYYY')} - ${moment(x?.time?.end).format('DD/MM/YYYY')}`}
                                  </span>
                              </td>
                              <td>
                                  <span className='text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                                      {x?.teacher}
                                  </span>
                              </td>
                              <td>
                                {x?.stars}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      {dataTrailClass?.map((x) => (
        <span>{x.title}</span>
      ))}
    </Modal>,
    modalsRoot
  )
}

export {AssistantClassModal}