import {Route, Routes} from 'react-router-dom'
import { BasicVocabularyExercise } from '../../exercises/basic-vocabulary';
import { FrontPageEnglishExplorers } from './components/front-page';

const EnglishExplorersPage = () => (
    <Routes>
        <Route path='/' element={<FrontPageEnglishExplorers/>} />
    </Routes>
);

export {EnglishExplorersPage};