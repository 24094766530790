import React from "react";

type Props = {
  onAction: () => void;
}

const ButtonTryAgain: React.FC<Props> = ({ onAction }) => {
  return(<>
    <button type="button" onClick={onAction} 
      className="btn champ-bold py-1 btn-light-primary btn-outline-white"
      style={{fontSize: 14, backgroundColor: 'white', borderRadius: '20px'}}
    >
      Try again
    </button>
  </>)
};

export {ButtonTryAgain};