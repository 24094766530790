/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'


const FileContentCourseDetails: React.FC = () => {
  return (
   <h5>Hola Details</h5>
  )
}

export {FileContentCourseDetails}
