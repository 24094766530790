import React from 'react';
import { useParams } from 'react-router';
import { useRoadMapStepById } from '../../hooks/useRoadMapStepRepo';
import { RoadMapStepTableList } from '../list';
import { RoadMapStepWidget } from './top-widget';
import { UseRoadMapOne } from '../../../road-map/services';
import { RoadMap } from '../../../road-map/interfaces';

const RoadMapStepDashboard: React.FC = () => {
    let { id } = useParams();

    const [roadMapData, setRoadMapData] = React.useState<RoadMap>();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UseRoadMapOne(id ?? '');
                setRoadMapData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);
    return (
        <div className="row">
            <div className='col-sm-12 col-md-8 col-lg-8'>
                <RoadMapStepTableList />
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                {roadMapData && <RoadMapStepWidget item={roadMapData}/>}
            </div>
        </div>
    )
};

export {RoadMapStepDashboard};