export interface VerificationRequest {
  id?: string;
  contract: string;
  responseHolder?: ResponseHolderType[];
  responseBeneficiaries?: ResponseBeneficiariesType[];
  status: StateVerificationRequest;
  photo?: ContentVerification;
  audios?: ContentVerification[];
  isActive: boolean; 
  createdAt?: string;
  updatedAt?: string;
};

export interface ResponseHolderType {
  field: string;
  newValue?: string;
  oldValue: string;
};

export interface ResponseBeneficiariesType {
  idBeneficiary: string;
  field: string;
  newValue?: string;
  oldValue: string;
};

export enum StateVerificationRequest {
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
};

export interface ContentVerification {
  id: string;
  type: TypeFileContentRequest;
  question: string | null
  isActive: boolean;
  createdAt?: string;
  updatedAt?: string;
  content?: any;
}

export enum MaritalStatus {
    Single = 'SINGLE',
    Married = 'MARRIED',
    Divorced = 'DIVORCED',
    Widower = 'WIDOWER'
  }
  
  export enum DocumentType {
    ForeignerId = 'FOREIGNER_ID',
    Citizenship = 'CITIZENSHIP',
    Passport = 'PASSPORT',
    Id = 'ID'
  }
  
  export enum PropertyType {
    Own = 'OWN',
    Familiar = 'FAMILIAR',
    Lease = 'LEASE'
  }
  
  export enum ProposalType {
    Virtual = 'VIRTUAL',
    PersonToPerson = 'PERSON_TO_PERSON'
  }
  
  export enum ProposalSendStatus {
    Pending = 'PENDING',
    Send = 'SEND'
  }
  
  
  export enum ProposalStatus {
    ProcessPaidOut = 'PROCESS_PAID_OUT',
    PaidOut = 'PAID_OUT',
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
  }
  
  export enum PaymentType {
    Cash = 'CASH',
    Check = 'CHECK',
    Electronic = 'ELECTRONIC',
    Consignment = 'CONSIGNMENT',
    PaymentAtHeadquarters = 'PAYMENT_AT_HEADQUARTERS'
  }

  export enum Stratum {
    One = 'ONE',
    Two = 'TWO',
    Three = 'THREE',
    Four = 'FOUR',
    Five = 'FIVE',
    Six = 'SIX'
  }
  
  export enum Gender {
    Male = 'MALE',
    Female = 'FEMALE',
    TransGender = 'TRANS_GENDER',
    NotSpecified = 'NOT_SPECIFIED'
  }

  export enum StudyPlanType {
    Standard = 'STANDARD',
    Personalized = 'PERSONALIZED'
  }

export interface DataInfoType {
  field: string;
  oldValue: string;
  newValue: string;
}

export interface DataBeneficiaryType extends DataInfoType {
  idBeneficiary: string;
}

export interface BeneficiaryType {
  id: string;
  firstName: string;
  gender: 'MALE' | 'FEMALE',
  stratum: string,
  relationship: string;
  phone: string;
  cellPhone: string;
  documentType: string;
  birthDate: string;
  email: string;
  occupation: string;
  birthplace: string;
  documentNumber: string;
}

export enum TypeFileContentRequest {
  PHOTO = 'PHOTO',
  AUDIO = 'AUDIO',
};