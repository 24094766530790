import {Route, Routes} from 'react-router-dom'
import { TrailFreezingDetails } from '../components/details'
import { TrailFreezingTableList } from '../components/list'


const TrailFreezingPage = () => (
  <Routes>
      <Route path='/details' element={<TrailFreezingDetails />} />
      <Route path='/' element={<TrailFreezingTableList />} />
  </Routes>
)

export {TrailFreezingPage}
