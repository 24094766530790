import useAuth from "../../../../../api/main"
import { showSimpleAlert } from "../../../../../commonHooks/alert"
import { api } from "../../../../auth/services/auth.services"
import { FileContentCourseType } from "../../interfaces"

const { token, logout, authChecked } = useAuth.getState()

export const FileContentChildrenAdd = async (data: any): Promise<any> => {
    try {
      const response: any = await api.post(
        '/file-content-course/file',data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
        }
      )
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      if (e.response.status === 400) {
        console.log(e.response)
         e.response.data.message.map((x)=>{
           //@ts-ignore
           showSimpleAlert({ message: x, icon: 'error' })
         })
         throw 'Error'
       }
      //@ts-ignore
      showSimpleAlert({ message: 'Error!', icon: 'error' })
      throw 'Error'
    }
  }


export const FileContentCourseAdd = async (data: FileContentCourseType): Promise<any> => {
    try {
        const response: any = await api.post(
        '/file-content-course',
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        )
        showSimpleAlert({ message: 'Ok!', icon: 'success' })
        return response.data
    } catch (e:any) {
        //@ts-ignore
        if (e.response.status === 401) {
        logout()
        }
        if (e.response.status === 400) {
        console.log(e.response)
            e.response.data.message.map((x)=>{
            //@ts-ignore
            showSimpleAlert({ message: x, icon: 'error' })
            })
            throw 'Error'
        }
        //@ts-ignore
        showSimpleAlert({ message: 'Error!', icon: 'error' })
        throw 'Error'
    }
}