import {create} from 'zustand';
import { MasterClassLevel } from '../../admin/master-class-level/interfaces';
import { Booking } from '../../admin/appointment/interfaces';
import moment from 'moment'
import { MeSuspenseResponse } from '../../admin/trail-suspense/interfaces';
import { AgendaItem, StrikesResponse } from '../interfaces';
import { persist } from 'zustand/middleware';

type DateRange ={
    before:Date
    after:Date
}
interface BookingInterface {
    classByWeek?:any
    level_id?:string 
    booking_id?:string
    step:number 
    booking?:Booking
    level?:MasterClassLevel
    ref?:string
    before:Date
    after:Date
    meSuspense?:MeSuspenseResponse
    myAgenda?:AgendaItem[]
    strikes?:StrikesResponse
    setRef:(mode:string)=>void;
    setStep:(mode:number)=>void;
    setLevel:(mode:MasterClassLevel)=>void;
    setBooking:(mode:Booking)=>void;
    resetBooking:()=>void;
    resetLevel:()=>void;
    setDate:(value:DateRange)=>void;
    setMeSuspense:(value:MeSuspenseResponse)=>void;
    setMyAgenda:(value:AgendaItem[])=>void;
    setStrikes:(value:StrikesResponse)=>void;
    setClassByWeek:(value:any)=>void;
}



export const UseBookingModule = create(persist<BookingInterface>(
    (set) =>  ({
        step:1,
        after:moment().add(9,'d').toDate(),
        before:moment().subtract(9,'d').toDate(),
        setStep:(value:number)=>{
            set({step:value})
        },
        setLevel:(value:MasterClassLevel)=>{
            set({level:value,level_id:value.id})
        },
        setBooking:(value:Booking)=>{
            set({booking:value,booking_id:value.id, step:3})
        },
        resetBooking:()=>{
            set({
                    booking:undefined,
                    booking_id:undefined, 
                    step:2
                })
        },
        resetLevel:()=>{
            set({
                booking:undefined,
                booking_id:undefined, 
                level:undefined,
                level_id:undefined,
                step:1
            })
        },
        setRef:(ref:string)=>{
            set({ref:ref})
        },
        setDate:(value:DateRange)=>{
            set({
                after:value.after,
                before:value.before
            })
        },
        setMeSuspense:(value:MeSuspenseResponse)=>{
            set({
                meSuspense:value
            })
        },
        setMyAgenda:(value:AgendaItem[])=>{
            set({
                myAgenda:value
            })
        },
        setStrikes:(value:StrikesResponse)=>{
            set({
                strikes:value
            })
        },
        setClassByWeek:(value)=>{
            set({
                classByWeek:value
            })
        }
      }),
      {
        name:"booking-module",

      }
));

