import {Route, Routes} from 'react-router-dom'
import Timetable from '../components/time-table'



const BlockHourPage = () => (
  <Routes>
      <Route path='/details' element={<h2>sd</h2>} />
      <Route path='/:id' element={<Timetable/>} />
  </Routes>
)

export {BlockHourPage}
