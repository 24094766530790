import React, { useState } from 'react';
import { es } from 'date-fns/locale';
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

type FullDate = {
  before: Date;
  after: Date;
};

type Props = {
  before: Date;
  after: Date;
  changeDate: (date: FullDate) => void;
};

const DayPickerRangeSelector: React.FC<Props> = ({ before, after, changeDate }: Props) => {
  const [selectedDays, setSelectedDays] = useState<DateRange | undefined>({
    from: before,
    to: after,
  });

  const handleDayClick: SelectRangeEventHandler = (range) => {
    if (!range) return;

    const selectedBefore: Date | undefined = range.from ?? range.to;
    const selectedAfter: Date | undefined = range.to ?? range.from;

    setSelectedDays(range);
    changeDate({ before: selectedBefore as Date, after: selectedAfter as Date });
  };

  const handleDayChange = (selectedDate: Date) => {
    const selectedBefore = selectedDate;
    const selectedAfter = selectedDate;

    setSelectedDays({ from: selectedDate, to: selectedDate });
    changeDate({ before: selectedBefore, after: selectedAfter }); 
  };

  return (
    <DayPicker
      id='test'
      mode='range'
      locale={es}
      defaultMonth={new Date()}
      selected={selectedDays}
      onSelect={handleDayClick}
      onDayClick={handleDayChange}
      showOutsideDays={true}
    />
  );
};

export { DayPickerRangeSelector };