import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {FileContentCourse} from '../../interfaces'

import { FileContentCourseAdd, FileContentCourseUpdate } from '../../services'
import { UseFileContentCourseModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TypeContentCourse } from '../../../file-folder/interfaces'
import FileUploadComponent from '../../../../../components/common/SingleFile'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { favIconAppSelector } from '../../../../../hooks/useAppSelectorAssetsMode'

export type FileContentCourseFormFields = {
  name: string
  isActive: boolean
  // description?:string
  type: TypeContentCourse;
  applyCover: boolean;
  iFrame?: string;
  cover?: any;
  content?: any;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const FileContentCourseForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseFileContentCourseModule();
  const [loading, setLoading] = React.useState<boolean>(false)
  const [contentFile, setContentFile] = React.useState<any>();
  const [coverFile, setCoverFile] = React.useState<any>();
  
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FileContentCourseFormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        // description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? TypeContentCourse.HTML : TypeContentCourse.HTML,
        applyCover: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.applyCover ?? false : false,
        cover: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.cover?.id ?? undefined : undefined,
        content: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.content?.id ?? undefined : undefined,
        iFrame: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.iFrame ?? undefined : undefined,
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await FileContentCourseAdd({
              ...formData,
              name: formData.name.trim()
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['file-content-course'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  FileContentCourseUpdate({
                ...formData,
                name: formData.name.trim()
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['file-content-course'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const changeFileContent = (obj: any) => {
      setContentFile(obj);
      setFieldValue('content', obj.id);
    };
  
    const changeFileCover = (obj: any) => {
      setCoverFile(obj);
      setFieldValue('cover', obj.id);
    };

    React.useEffect(() => {
      setContentFile(itemSelected?.content);
      setCoverFile(itemSelected?.cover);
    }, [itemSelected]);

    React.useEffect(() => {
      if (values.type !== TypeContentCourse.VIDEO) {
        setFieldValue('iFrame', undefined);
      }
    }, [values.type]);

    React.useEffect(() => {
      if (!values.applyCover) {
        setFieldValue('cover', undefined);
      }
    }, [values.applyCover]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Nomenclatura</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Tipo de recurso</label>
            <select
              disabled={mode === 'VIEW'}
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
              name='type'
            >
              {Object.keys(TypeContentCourse).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
        
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Background?</label>
            <div className="form-check form-switch">
              <input
                checked={values.applyCover}
                onChange={handleChange}
                name='applyCover'
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          {values.type === TypeContentCourse.VIDEO ?
            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Link</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                name={'iFrame'}
                value={values.iFrame}
                onChange={handleChange}
              />
              {touched.iFrame && errors.iFrame && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.iFrame}</span>
                  </div>
                </div>
              )}
            </div>
            : <div className='mb-10 col-6 d-flex flex-column'>
              <label className=''>Contenido</label>
              {(contentFile && (contentFile.url || contentFile.location)) ? 
                <img className='img-fluid' style={{width:'50%'}} 
                  src={(contentFile.url || contentFile.location)??toAbsoluteUrl(favIconAppSelector())} 
                  alt='metronic'/>
              : 
                <img className='img-fluid' style={{width:'50%'}} 
                  src={toAbsoluteUrl(favIconAppSelector())} 
                  alt='metronic'/>}
                <FileUploadComponent
                  onChangeFile={(e) => changeFileContent(e)} 
                  onChangeLoading={(e) => setLoading(e)}
                  pushCover
                  idInput='contentFile' />
            </div>
          }

          {values.applyCover && <div className='mb-10 col-6 d-flex flex-column'>
                <label className='form-label mb-3'>Background</label>
                {(coverFile && (coverFile.url || coverFile.location)) ? 
                <img className='img-fluid' style={{width:'50%'}} 
                  src={(coverFile.url || coverFile.location)??toAbsoluteUrl(favIconAppSelector())} 
                  alt='metronic'/>
              : <img className='img-fluid' style={{width:'50%'}} 
                  src={toAbsoluteUrl(favIconAppSelector())} 
                  alt='metronic'/>}
                  <FileUploadComponent
                    onChangeFile={(e) => changeFileCover(e)} 
                    onChangeLoading={(e) => setLoading(e)}
                    pushCover
                    idInput='coverFile' />
            </div>}

            

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {FileContentCourseForm}
