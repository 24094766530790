import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { TemplateModifiedCourse, UseModeCourseModule } from '../front-page/store/main';
import './index.css';
import { TopicsDrawer } from '../../../../../_metronic/partials';
import { EvaluationType } from '../../../admin/evaluation/interfaces';

type Props = {
    colorCourse: string;
    logoCourse: string;
    nameTopic: string;
    evaluationType: EvaluationType
};

const HeaderCourses: React.FC<Props> = ({ colorCourse, logoCourse, nameTopic, evaluationType }) => {
    const { modeCourse, setModeCourse, modeTest } = UseModeCourseModule();
    React.useEffect(() => {
        if (modeCourse || modeTest) {
            TemplateModifiedCourse(colorCourse);
        };
    }, [modeCourse, modeTest, colorCourse]);
    return (<>
        <div className='d-none d-md-flex d-flex justify-content-between align-items-center'>
            <div className='d-flex'>
                <a href="/dashboard" className='me-5' onClick={() => setModeCourse(false)}>
                    <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/back_white.svg')}
                        alt=""
                        style={{ width: '60px' }}
                    />
                </a>
                <img src={logoCourse}
                    alt=""
                    className='me-5'
                    style={{ width: '250px' }}
                />
                <div className='d-flex align-items-center me-5'>
                    <div className='' style={{ width: '2px', height: '80%', backgroundColor: 'white' }}></div>
                </div>
                <div className='d-flex align-items-center'>
                    <span className='poppins-light text-white fs-2'>
                        {nameTopic}
                    </span>
                </div>
            </div>
            <div className='d-flex align-items-center'>
                {!(evaluationType === EvaluationType.FINAL_PRESENTATION  || evaluationType === EvaluationType.QUIZ || evaluationType === EvaluationType.PLACEMENT_TEST || evaluationType === EvaluationType.WELCOME_DAY) && (
                    <TopicsDrawer
                        colorCourse={colorCourse}
                        mode={(modeCourse && !modeTest) ? 'STUDENT' : (!modeCourse && modeTest) ? 'ADMIN' : 'ADMIN'}
                    />
                )}
            </div>
        </div>
        {/* Movil */}
        <div className='d-md-none d-flex justify-content-center' style={{ top: '14px', position: 'fixed', zIndex: 100, right: '20%', left: '19%' }}>
            {/* <a href="/dashboard" className='me-2' onClick={()=>setModeCourse(false)}>
            <img src={toAbsoluteUrl('/media/svg/michigan-icons/header/white/back_white.svg')}
                alt=""
                style={{width:'60px'}}
            />
        </a> */}

            <img src={logoCourse}
                alt=""
                className='me-2'
                style={{ width: '125px', zIndex: 100 }}
            />
            <div className='d-flex align-items-center me-2'>
                <div className='' style={{ width: '1px', height: '80%', backgroundColor: 'white', zIndex: 100 }}></div>
            </div>
            <div className='d-flex align-items-center'>
                <span className='poppins-light text-white' style={{ zIndex: 100, fontSize: '15px' }}>
                    {nameTopic}
                </span>
            </div>
        </div>
        <div className='d-md-none d-flex justify-content-end' style={{ marginTop: '25px' }}>
            <TopicsDrawer
                colorCourse={colorCourse}
                mode={(modeCourse && !modeTest) ? 'STUDENT' : (!modeCourse && modeTest) ? 'ADMIN' : 'ADMIN'}
            />
            {/* <button type='button'
            className='btn bg-white cursor-pointer p-2 champ-light d-flex justify-content-center align-items-center'
            style={{borderRadius: '30px', color: '#2d30e1'}}
            // id='kt_glossary_course_toggle'
            // id='kt_tips_course_toggle'
            id='kt_topics_course_toggle'
        >
            <img className='me-3 ms-4' style={{width:'10px'}} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
            <span className='pt-1 me-4'>Topics</span>
        </button> */}
        </div>
    </>)
};

export { HeaderCourses };