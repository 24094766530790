import { Chapter, DataAllChapterResponse, DataChapter, DataPaginateChapterResponse } from '../interfaces/index'
import { useStore } from 'zustand'
import { UseChapterModule } from '../store/main'
import useAuth from '../../../../../../api/main'
import { catchServerError } from '../../../../../../hooks/useCatchError'
import { api } from '../../../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const UseChapterAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/chapter`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const useChapterAllPaginate = async (): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseChapterModule.getState()

    const response: any = await api.get(
      `/chapter/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const ChapterAdd = async (data: DataChapter): Promise<any> => {
  try {
    const response: any = await api.post(
      '/chapter',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const ChapterUpdate = async (data: DataChapter): Promise<any> => {
  try {
    const { itemSelected } = UseChapterModule.getState()
    const response: any = await api.patch(
      `/chapter/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const ChapterDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/chapter/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UsePaginatedChapterByLearningPath = async (term: string): Promise<any> => {
  try {
    const { setPaginator, limit, currentPage, where, isActive } = await UseChapterModule.getState()
    const response: any = await api.get(`/chapter/paginated-by-path?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&root=${term}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    setPaginator(response.data);
    return response.data;
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseChapterOne = async (id:string): Promise<any> => {
  try {
    const response = await api.get(`/chapter/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UseChapterAllByLearningPath = async (id: string): Promise<any> => {
  try {
    const response = await api.get(`/chapter/by-learning/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
    showSimpleAlert({ message: 'Error!', icon: 'error' })

  }
}