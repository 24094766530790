import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {SettingEnumType, Settings} from '../../interfaces'

import { SettingsAdd, SettingsUpdate } from '../../services'
import { UseSettingsModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish'
import { t } from 'i18next'

export type FormFields = {
  description?:string;
  value:number;
  alternativeValue:number;
  code:string;
  type: SettingEnumType;
  isActive:boolean;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const SettingsForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseSettingsModule();
  const [data, setData] = useState<Settings>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        code: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.code ?? '' : '',
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        value: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.value ?? 0 : 0,
        alternativeValue: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.alternativeValue ?? 0 : 0,
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? false : false,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? SettingEnumType.MAXIMUM_NUMBER_HOURS_ADVANCE_TO_MAKE_RESERVATIONS : SettingEnumType.MAXIMUM_NUMBER_HOURS_ADVANCE_TO_MAKE_RESERVATIONS
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await SettingsAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['settings'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  SettingsUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['settings'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {touched.code && errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Type</label>
            <select
              disabled={mode === 'VIEW'}
              name='type'
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SettingEnumType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.type}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Value</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'value'}
              value={values.value}
              onChange={handleChange}
            />
            {touched.value && errors.value && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.value}</span>
                </div>
              </div>
            )}
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Alternative Value</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'alternativeValue'}
              value={values.alternativeValue}
              onChange={handleChange}
            />
            {touched.alternativeValue && errors.alternativeValue && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.alternativeValue}</span>
                </div>
              </div>
            )}
          </div> */}
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Descripción</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='mb-1 col-6'>
              <label className='form-label mb-0'>Inicio</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'start'}
                value={values.start}
                onChange={handleChange}
              />
              {touched.start && errors.start && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.start}</span>
                  </div>
                </div>
              )}
          </div>
          <div className='mb-1 col-6'>
              <label className='form-label mb-0'>Fin</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'end'}
                value={values.end}
                onChange={handleChange}
              />
              {touched.end && errors.end && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.end}</span>
                  </div>
                </div>
              )}
          </div> */}

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {SettingsForm}
