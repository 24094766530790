import { showSimpleAlert } from "../../../../commonHooks/alert";
import { ExercisesType } from "../interfaces";

export const UrlExercise = (typeExercise: ExercisesType) => {
  switch (typeExercise) {
    case ExercisesType.LISTENING:
    case ExercisesType.SPEAKING:
    case ExercisesType.MULTIPLE_SELECTION:
    case ExercisesType.SIMPLE_SELECTION:
    case ExercisesType.SIMPLE_SELECTION_COMPLETION:
    case ExercisesType.HISTORY_WRITING:
    case ExercisesType.TRUE_FALSE:
    case ExercisesType.DRAG_DROP:
    case ExercisesType.MOVIE_WRITING:
    case ExercisesType.ORDER_SENTENCES:
    case ExercisesType.WRITING_CHECK_LIST:
    case ExercisesType.HIGHLIGHT_WORD:
    case ExercisesType.TRUE_OPTIONS:
    case ExercisesType.CROSSWORD:
    case ExercisesType.AUDIO_INTERACTION:
    case ExercisesType.CORRECT_SENTENCES:
    case ExercisesType.TRUE_WORDS:
    case ExercisesType.BOOSTER:
    case ExercisesType.CHECK_WRITING:
    case ExercisesType.COORDINATES:
    case ExercisesType.GROUP_WORDS:
    case ExercisesType.SPEAK_OUT:
    case ExercisesType.COMPLETE_SENTENCES:
    case ExercisesType.AUDIO_TEST:
      return formattedTypeExercise(typeExercise);
      
    default:
      showSimpleAlert({message: "Tipo de ejercicio no reconocido", icon: 'error'});
  }
};

const formattedTypeExercise = (type: ExercisesType): string => {
  const formattedType = type.toString().toLowerCase();
  return formattedType.replace(/_/g, '-');
};
