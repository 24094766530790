
import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';
import { CSSProperties } from 'react';
    type Props = {
        instruction:string;
    }
const ExerciseTwentyFor: React.FC<Props> = ({instruction}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',width: '525px',height: '290px',
        borderRadius: '30px',borderColor: 'rgb(255, 255, 255)',borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
    const inputTwentyForStyle: CSSProperties = {
        display: 'flex',width: '60px',height: '16px',
        marginRight: '10px',borderRadius: '40px',border: '0px solid #ffffff',
        textAlign: 'center',backgroundColor: '#d0ff00',outline: 'none',marginBottom: '0',marginTop: '1px',
      };
    const  PictureFrameStyle: CSSProperties = {
        marginLeft: '2px',marginTop: '5px',width: '50px',
        height: '50px',border: '1px solid #ffffff',borderRadius: '50%',marginRight:'7px',marginBottom:'4px'
      };
      
    return(<>
        <div className="d-flex container-fluid">
            <div style={divStyle}>
                <HeaderCard/>
                <span className=" text-white cuestion mb-0 poppins-light" style={{marginLeft:'10px'}}>{instruction}</span>
                
                <div className="d-flex justify-content-center" style={{marginTop:'10px'}}>
                    <div>
                        <div className="p-2" style={PictureFrameStyle}></div>
                        <input className="text-primary"  style={inputTwentyForStyle}type="text" value="a" readOnly/>
                    </div>
                    <div>
                        <div className="p-2" style={PictureFrameStyle}></div>
                        <input className="text-primary" style={inputTwentyForStyle} type="text" value="b" readOnly/>
                    </div>
                    <div>
                        <div className="p-2" style={PictureFrameStyle}></div>
                        <input className="text-primary" style={inputTwentyForStyle}type="text" value="c" readOnly/>
                    </div>
                    <div>
                        <div className="p-2" style={PictureFrameStyle}></div>
                        <input className="text-primary" style={inputTwentyForStyle}type="text" value="d" readOnly/>
                    </div>
                </div>
            </div>
            <div className="position-relative" /*onClick={handleCheck}*/>
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div>
            <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
        </div></>)
};
export {ExerciseTwentyFor}