import { useQueryClient } from 'react-query';
import {create} from 'zustand';
import { Exercises, ExercisesType, Pagination, VariantsExercisesType } from '../interfaces';
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces';
import { UseFileContentCourseModule } from '../../file-content-course/store/main';


interface ExercisesModules {
    itemSelected?: Exercises 
    mode:'ADD'|'EDIT'|'VIEW'
    modal:boolean,
    level?: TraditionalLevelEnum,
    typeExercise?: ExercisesType,
    variantType?: VariantsExercisesType,
    isActive:boolean,
    paginator:Pagination
    where:string
    currentPage:number
    limit:number
    total:number
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setSelectedToEdit:(item:Exercises)=>void;
    setSelectedToView:(item:Exercises)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setLimit:(value:number)=>void,
    setWhere:(value:string)=>void,
    setToAdd:()=>void,
    setLevel:(value:TraditionalLevelEnum|undefined)=>void,
    setTypeExercise:(value: ExercisesType|undefined)=>void,
    setVariantType:(value: VariantsExercisesType|undefined)=>void,
}

export const UseExercisesModule = create<ExercisesModules>( (set) => ({
    mode:'ADD',
    modal:false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    total:0,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT',modal:true})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        const {setWhere} = UseFileContentCourseModule.getState();
        setWhere('');
        set({itemSelected:undefined,mode:'ADD',modal:false})
    },
    setPaginator:(pagination)=>{
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setLevel:(value)=>{
        set({level: value})
    },
    setTypeExercise:(value)=>{
        set({typeExercise: value})
    },
    setVariantType:(value)=>{
        set({variantType: value});
    }
}));