import { useQueryClient } from 'react-query';
import {create} from 'zustand';
import { Evaluation, EvaluationItem, EvaluationType, Pagination } from '../interfaces';
import { Lessons } from '../../../abroad/learning-path/modules/lessons/interfaces';


interface EvaluationModules {
    itemSelected?: Evaluation
    itemEvaluation?: EvaluationItem,
    evaluationType?: EvaluationType;
    mode:'ADD'|'EDIT'|'VIEW'
    modal:boolean,
    type?:'ITEM_ADD'|'ITEM_UP'|'LIST'|'LESSON'
    lesson?:Lessons;
    isActive:boolean,
    paginator:Pagination
    where:string
    currentPage:number
    limit:number
    total:number
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setSelectedToEdit:(item:Evaluation)=>void;
    setSelectedToView:(item:Evaluation)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setLimit:(value:number)=>void,
    setWhere:(value:string)=>void,
    setToAdd:()=>void,
    setToAddItem:()=>void,
    setUpdateItem:(item:EvaluationItem)=>void,
    setType:(value:'LESSON'|'ITEM_ADD'|'ITEM_UP'|'LIST'|undefined)=>void,
    setLesson:(value: Lessons)=>void;
    setEvaluationType:(value: EvaluationType)=>void;
}

export const UseEvaluationModule = create<EvaluationModules>( (set) => ({
    mode:'ADD',
    modal:false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    total:0,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT',modal:true})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false, itemEvaluation:undefined, type: undefined})
    },
    setPaginator:(pagination)=>{
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setToAddItem:()=>{
        set({type: 'ITEM_ADD', itemEvaluation: undefined})
    },
    setUpdateItem:(value)=>{
        set({type: 'ITEM_UP', itemEvaluation: value})
    },
    setType:(value)=> {
        set({type: value})
    },
    setLesson:(value)=>{
        set({lesson: value})
    },
    setEvaluationType:(value) => {
        set({evaluationType: value});
    },
}));