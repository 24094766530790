export const SettingsEs = {
  NUMBER_SANCTION_STUDENT : 'Número de strikes',
  NUMBER_WEEKLY_UNIT_RESERVATIONS_STUDENT : 'Número de reservas semanales',
  NUMBER_DAYS_VALIDATE_ABSENTEEISM_BEFORE_SANCTIONING_STUDENT : 'Número de días antes de sancionar al estudiante',
  NUMBER_ABSENTEEISM_ALLOWED_SANCTIONING_STUDENT : 'Cantidad de ausencia permitidas antes de sancionar al estudiante',
  NUMBER_WEEKLY_ACTIVITY_BOOKING_STUDENT : 'Estandar de hora de unidades a programar semanalmente',
  NUMBER_HOURS_BEFORE_CANCELING_RESERVATION : 'Número de horas antes de cancelar una reserva.',
  MAXIMUM_NUMBER_HOURS_ADVANCE_TO_MAKE_RESERVATIONS : 'Horas máximas de anticipación para reservar',
  MINUTER_PRIOR_THE_START_CLASS_BY_ZOOM : 'Minutos previos para el inicio de clases por zoom',
  NUMBER_OF_PENDING_TASKS : 'Número de tareas pendientes',
  PENDING_TASK: 'Ejercicios Pendientes'
}