export const LearningPathTranslateEs = {
    LEARNING_PATH:"Cursos",
    MANAGEMENT_LEARNING_PATH:"Gestion de cursos",
    TYPE:"Tipo",
    NAME:"Nombre",
    DESCRIPTION:"Descripción",
    ACTIVE:"Activo",
    ACTIONS:"Acciones",
    RANDOM:"Random",
    VIEW: "Ver",
    STANDARD:"Standard",
}