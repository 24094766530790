import {Route, Routes} from 'react-router-dom'
import { LeadsDetails } from '../components/details'
import { LeadsTableList } from '../components/list'


const LeadsPage = () => (
  <Routes>
      <Route path='/details' element={<LeadsDetails />} />
      <Route path='/' element={<LeadsTableList />} />
  </Routes>
)

export {LeadsPage}
