import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {LearningPath, LearningPathType} from '../../interfaces'

import { LearningPathAdd, LearningPathUpdate } from '../../services'
import { UseLearningPathModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish'
import FileUploadComponent from '../../../../../components/common/SingleFile'
import { favIconAppSelector } from '../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  bannerPrincipal?: string;
  bannerLobbyHorizontal?: string;
  bannerVertical?: string;
  type: LearningPathType;
  colorBase?: string;
  logo?:string;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const LearningPathForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseLearningPathModule();

  const [bannerPrincipal, setBannerPrincipal] = React.useState<any>();
  const [bannerVertical, setBannerVertical] = React.useState<any>();
  const [logo, setLogo] = React.useState<any>();
  const [bannerLobbyHorizontal, setBannerLobbyHorizontal] = React.useState<any>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
        bannerPrincipal: (mode==='EDIT' || mode==='VIEW')? itemSelected?.bannerPrincipal?.id??undefined:undefined,
        bannerVertical: (mode==='EDIT' || mode==='VIEW')? itemSelected?.bannerVertical?.id??undefined:undefined,
        bannerLobbyHorizontal: (mode==='EDIT' || mode==='VIEW')? itemSelected?.bannerLobbyHorizontal?.id??undefined:undefined,
        type: (mode==='EDIT' || mode==='VIEW')? itemSelected?.type??LearningPathType.ENGLISH_EXPLORER:LearningPathType.ENGLISH_EXPLORER,
        logo: (mode==='EDIT' || mode==='VIEW')? itemSelected?.logo?.id??undefined:undefined,
        colorBase: (mode==='EDIT' || mode==='VIEW')? itemSelected?.colorBase ?? '': ''
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await LearningPathAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['learning-path'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  LearningPathUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['learning-path'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

    const changeBannerPrincipal = (obj: any) => {
      setBannerPrincipal(obj)
      setFieldValue('bannerPrincipal', obj.id)
    };

    const changeBannerVertical = (obj: any) => {
      setBannerVertical(obj)
      setFieldValue('bannerVertical', obj.id)
    };

    const changeBannerLobbyHorizontal = (obj: any) => {
      setBannerLobbyHorizontal(obj);
      setFieldValue('bannerLobbyHorizontal', obj.id);
    };

    const changeLogo = (obj: any) => {
      setLogo(obj);
      setFieldValue('logo', obj.id);
    };
  
    React.useEffect(() => {
      setLogo(itemSelected?.logo);
      setBannerPrincipal(itemSelected?.bannerPrincipal);
      setBannerVertical(itemSelected?.bannerVertical);
      setBannerLobbyHorizontal(itemSelected?.bannerLobbyHorizontal);
    }, [itemSelected]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Color Base</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'colorBase'}
              value={values.colorBase}
              onChange={handleChange}
            />
            {touched.colorBase && errors.colorBase && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.colorBase}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Type</label>
            <select
              disabled={mode === 'VIEW'}
              name='type'
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(LearningPathType).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.type}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Logo</label>
            {(logo && (logo.url || logo.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(logo.url || logo.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileUploadComponent 
              onChangeFile={(e) => changeLogo(e)} 
              onChangeLoading={(e) => setLoading(e)}
              idInput='logoCourse'
               />
          </div>

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Banner Principal</label>
            {(bannerPrincipal && (bannerPrincipal.url || bannerPrincipal.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(bannerPrincipal.url || bannerPrincipal.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronics'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileUploadComponent 
              onChangeFile={(e) => changeBannerPrincipal(e)} 
              onChangeLoading={(e) => setLoading(e)} 
              idInput='bannerPrincipal'/>
          </div>

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Banner Vertical</label>
            {(bannerVertical && (bannerVertical.url || bannerVertical.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(bannerVertical.url || bannerVertical.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileUploadComponent 
            onChangeFile={(e) => changeBannerVertical(e)} 
            onChangeLoading={(e) => setLoading(e)}
            idInput='bannerVertical' />
          </div>

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Banner Lobby Horizontal</label>
            {(bannerLobbyHorizontal && (bannerLobbyHorizontal.url || bannerLobbyHorizontal.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(bannerLobbyHorizontal.url || bannerLobbyHorizontal.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}

            <FileUploadComponent 
              onChangeFile={(e) => changeBannerLobbyHorizontal(e)} 
              onChangeLoading={(e) => setLoading(e)}
              idInput='bannerLobby' />
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {LearningPathForm}
