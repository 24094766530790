import { EvaluationType } from "../../../../evaluation/interfaces"
import QualifyFinalPresentationForm from "./qualifyFinalPresentationFom"
import QualifyPlacementTestForm from "./qualifyPlacementTestForm"
import QualifyQuizForm from "./qualifyQuizForm"
import QualifyWelcomeDayForm from "./qualifyWelcomeDayForm"

type Props = {
  evaluationType: EvaluationType
  data: any
}

const QualifyEvaluationForms: React.FC<Props> = ({evaluationType, data}:Props) =>{
  return(
    <>
      {evaluationType === EvaluationType.PLACEMENT_TEST ?
        <QualifyPlacementTestForm data={data}/>
      :
        evaluationType === EvaluationType.FINAL_PRESENTATION ?
        <QualifyFinalPresentationForm data={data}/>
      :
        evaluationType === EvaluationType.WELCOME_DAY ?
        <QualifyWelcomeDayForm data={data}/>
      :
        evaluationType === EvaluationType.QUIZ ?
        <QualifyQuizForm data={data}/>
      : 
      <>/</>
    }
    </>
  )
} 

export default QualifyEvaluationForms