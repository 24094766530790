import { DataPlacementTest, PlacementTestRequestStatus, PlacementTestRequest } from '../interfaces/index'
import useAuth from '../../../../api/main'
import { catchServerError } from '../../../../hooks/useCatchError'
import { UsePlacementTestModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import Swal from 'sweetalert2'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
import { UseEvaluationCourseModule } from '../../../courses/screen/store/main'
import { TaskStatus } from '../../tasks/interfaces'

const { token, logout, authChecked } = useAuth.getState();

export const usePlacementTestAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/placement-test/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const usePlacementTestAllPaginate = async (): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where, isActive, type } = await UsePlacementTestModule.getState()

    let url =  `/placement-test/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`;
    if (type) {
      url += `&type=${type}`;
    }
    const response: any = await api.get(
     url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const PlacementTestAdd = async (data: DataPlacementTest): Promise<any> => {
  try {
    const response: any = await api.post(
      '/placement-test',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const PlacementTestUpdate = async (data: DataPlacementTest): Promise<any> => {
  try {
    const { itemSelected } = UsePlacementTestModule.getState()
    const response: any = await api.patch(
      `/placement-test/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const PlacementTestDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/placement-test/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const confirmWelcomeDay = async (task: string) => {
  const { settings, settingChecked } = UseMyRoadmapModule.getState();
  // const responseSetting = typeof settings === 'undefined' ? await settingChecked() : settings;
  try {
    // if (settings && settings.id) {
      const {setStepEvaluation, setShowEndEvaluation, resetEvaluationResponses} = UseEvaluationCourseModule.getState()
      // await api.patch(`/tasks/finish-welcome/${task}`, {}, {
        //   headers: {
          //     Authorization: `Bearer ${token}`
          //   }
          // });
          
          Swal.fire({
            title: `<strong class=''>LEA ATENTAMENTE</strong>`,
            html: `<p>En al menos una de sus respuestas usted ha manifestado no estar de acuerdo con los términos y condiciones.</p>`,
            showCancelButton: true,
            confirmButtonText: "<span class='poppins-light fs-4'>Quiero que me contacten</span>",
            cancelButtonText: "<span class='poppins-light fs-4'>Volver a intentar</span>",
            width: 600,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-success"
            }
          }).then(async(result) => {
            if (result.isConfirmed) {
              try{
                // showSimpleAlert({message: 'Te contactaremos pronto', icon: 'success'});
                // const res = await FindRequestPlacementTest(settings.id);
                // await ApprovedPlacementTestRequest(res.id, {status: PlacementTestRequestStatus.IN_PROCESS})
                // showSimpleAlert({message:'Ok!',icon:'success'})
                window.location.replace('/')
              }catch(e){
                
              }
            } else {
              setStepEvaluation(0);
              // const res = await FindRequestPlacementTest(settings.id);
              // await ApprovedPlacementTestRequest(res.id, {status: PlacementTestRequestStatus.DECLINE})
              // showSimpleAlert({message:'Ok!',icon:'success'})
              // window.location.replace('/')
            }
            
            resetEvaluationResponses()
            setShowEndEvaluation(false)
          })
          
        // }
        
      } catch (e: any) {
        //@ts-ignore
        if (e.response.status === 401) {
          logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message && e.response.data.message.length > 0) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
     } else {
      showSimpleAlert({message: e.response.data.message, icon: 'error'});
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const FinishWelcome = async (task: string) => {
  const {setShowEndEvaluation, resetEvaluationResponses} = UseEvaluationCourseModule.getState()
  const { settings, settingChecked } = UseMyRoadmapModule.getState();
  try {
    // if (responseSetting && responseSetting.id) {
      await api.patch(`/tasks/finish-welcome/${task}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const confirmedTwo = (type: 'PLACEMENT' | 'START') => {Swal.fire({
        title: `<strong class=''>LEA ATENTAMENTE</strong>`,
        html: `<p></p>`,
        showCancelButton: true,
        confirmButtonText: "<span class='poppins-light fs-4'>Sí</span>",
        cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
        allowOutsideClick: false,
        width: 600,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-primary"
        }
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
            const responseSetting = typeof settings === 'undefined' ? await settingChecked() : settings;
            const res = await FindRequestPlacementTest(responseSetting.id);
            await ApprovedPlacementTestRequest(res.id, {status: 
              type === 'PLACEMENT' ? PlacementTestRequestStatus.IN_PROCESS : 
              PlacementTestRequestStatus.DECLINE})
            showSimpleAlert({message:'Ok!',icon:'success'})
            window.location.replace('/')
            }catch(e){
              
            }
        } else {
          confirmedOne();
          // const res = await FindRequestPlacementTest(settings.id);
          // await ApprovedPlacementTestRequest(res.id, {status: PlacementTestRequestStatus.DECLINE})
          // showSimpleAlert({message:'Ok!',icon:'success'})
          // window.location.replace('/')
        }
        setShowEndEvaluation(false)
        resetEvaluationResponses()
      })}
  
      const confirmedOne = () => {Swal.fire({
        title: `<strong class=''>¿Quieres hacer un Placement Test?</strong>`,
        html: `<p>Este es un examen para calificar tu nivel de ingles</p>`,
        showCancelButton: true,
        confirmButtonText: "<span class='poppins-light fs-4'>Quiero hacer Placement Test</span>",
        cancelButtonText: "<span class='poppins-light fs-4'>Quiero empezar desde cero!</span>",
        width: 600,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-primary"
        }
      }).then(async(result) => {
        if (result.isConfirmed) {
          try{
            confirmedTwo('PLACEMENT');
            // const res = await FindRequestPlacementTest(settings.id);
            // await ApprovedPlacementTestRequest(res.id, {status: PlacementTestRequestStatus.IN_PROCESS})
            // showSimpleAlert({message:'Ok!',icon:'success'})
            // window.location.replace('/')
            }catch(e){
              
            }
        } else {
          confirmedTwo('START');
          // const res = await FindRequestPlacementTest(settings.id);
          // await ApprovedPlacementTestRequest(res.id, {status: PlacementTestRequestStatus.DECLINE})
          // showSimpleAlert({message:'Ok!',icon:'success'})
          // window.location.replace('/')
        }
      })}

      confirmedOne();
    // }

  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message && e.response.data.message.length > 0) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
     } else {
      showSimpleAlert({message: e.response.data.message, icon: 'error'});
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const FindRequestPlacementTest = async (setting: string) => {
  try {
    const response = await api.get(`/placement-test-request/setting/${setting}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data

  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message && e.response.data.message.length > 0) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
     } else {
      showSimpleAlert({message: e.response.data.message, icon: 'error'});
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const ApprovedPlacementTestRequest = async (placement: string, data: PlacementTestRequest) => {
  try {
    const response = await api.patch(`/placement-test-request/approved/${placement}`, 
    {
      ...data
    }
    , {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message && e.response.data.message.length > 0) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
     } else {
      showSimpleAlert({message: e.response.data.message, icon: 'error'});
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const AddPlacementRequestByStudent = async (student: string) => {
  try {
    const response = await api.post(`/placement-test-request/student/${student}`, 
    {
    }
    , {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message && e.response.data.message.length > 0) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
    } else {
      showSimpleAlert({message: e.response.data.message, icon: 'error'});
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UpdateTestStatus = async (id: string, status: TaskStatus) =>{
  try {
    await api.patch(`/tasks/${id}`, 
      {
        status
      }
      , {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message && e.response.data.message.length > 0) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
    } else {
    showSimpleAlert({message: e.response.data.message, icon: 'error'});
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}