import {Route, Routes} from 'react-router-dom'
import { AvatarDetails } from '../components/details'
import { AvatarTableList } from '../components/list'


const AvatarPage = () => (
  <Routes>
      <Route path='/details' element={<AvatarDetails />} />
      <Route path='/' element={<AvatarTableList />} />
  </Routes>
)

export {AvatarPage}
