import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {HistoryWriting, ItemsHistoryAdd, ModeQuestionHistoryWriting, QuestionHistoryWritingAdd} from '../../interfaces'

import { HistoryWritingAdd, HistoryWritingUpdate } from '../../services'
import { UseHistoryWritingModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../../abroad/road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  name: string
  description?:string
  isActive: boolean;
  questions: QuestionHistoryWritingAdd[];
  level: TraditionalLevelEnum;
  limit: number;
  minimum: number
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const HistoryWritingForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {id} = useParams();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseHistoryWritingModule();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [addItem, setAddItem] = React.useState({
    orderQuestion: 0,
    addItem: false
  });
  const [dataAnswer, setDataAnswer] = React.useState<QuestionHistoryWritingAdd>({
    order: 1,
    name: '',
    mode: ModeQuestionHistoryWriting.WORD,
    items: []
  });

  const [itemsAnswer, setItemsAnswer] = React.useState<ItemsHistoryAdd>({
    title: '',
    order: 1,
    isTrue: true
  })

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        questions: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.questions.map(x => JSON.parse(x)) ?? [] : [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        limit: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.limit ?? 1 : 1,
        minimum: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.minimum ?? 1 : 1,
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await HistoryWritingAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['history-writing'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  HistoryWritingUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['history-writing'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const addQuestion = () => {
    setFieldValue('questions', [...values.questions, dataAnswer]);
    setDataAnswer({
      order: 1,
      name: '',
      mode: ModeQuestionHistoryWriting.WORD,
      items: []
    })
  };

  const addQuestionItem = () => {
    const currentQuestion = values.questions.find(x => x.order === addItem.orderQuestion)
    if (!currentQuestion) return;
    const updateQuestion = {...currentQuestion, items: [ ...currentQuestion.items, itemsAnswer]};

    const updatedQuestions = values.questions.map(question =>
      question.order === addItem.orderQuestion ? updateQuestion : question
    );

    setFieldValue('questions', updatedQuestions);
    setItemsAnswer({
      isTrue: true,
      title: '',
      order: 1
    })
  };

  const deleteQuestion = (order: number) => {
    setFieldValue('questions', values.questions.filter(x => x.order !== order));
  };

  const deleteQuestionItem = (order: number) => {
    const currentQuestion = values.questions.find(x => x.order === addItem.orderQuestion)
    if (!currentQuestion) return;
    const deleteQuestionItem = {
      ...currentQuestion, 
      items: currentQuestion.items.filter(x => x.order !== order),
    };

    const updatedQuestions = values.questions.map(question => 
      question.order === addItem.orderQuestion ? deleteQuestionItem : question
    );

    setFieldValue('questions', updatedQuestions);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Limit</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'limit'}
              value={values.limit}
              onChange={handleChange}
            />
            {touched.limit && errors.limit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.limit}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Minimum</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'minimum'}
              value={values.minimum}
              onChange={handleChange}
            />
            {touched.minimum && errors.minimum && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.minimum}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>level</label>
            <select
              name='level'
              className='form-select'
              aria-label='Default select example'
              value={values.level}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>
          {!addItem.addItem ? <>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Answers</label>
            <div className='mb-2 col-12'>
              <label className='form-label mb-2'>Name</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.name}
                onChange={(e) => setDataAnswer(prev => ({...prev, name: e.target.value}))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Order</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.order}
                onChange={(e)=> setDataAnswer(prev=>({...prev, order: parseInt(e.target.value)}))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Mode</label>
                <select
                  className='form-select'
                  aria-label='Default select example'
                  value={dataAnswer.mode}
                  onChange={(e) => setDataAnswer(prev => ({...prev, mode: e.target.value as ModeQuestionHistoryWriting}))}
                  disabled={mode==='VIEW'}
                >
                  {Object.keys(ModeQuestionHistoryWriting).map((x) => (
                    <option key={x} value={x}>{t(x)}</option>
                  ))}
                </select>
            </div>
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addQuestion} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='mb-10 col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Title</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.questions.sort((a,b) => a.order - b.order).map((x) => (
                  <tr>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.name}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {x.order}
                            </span>
                          </div>
                        </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> setAddItem({ orderQuestion: x.order, addItem: true })}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas-solid fa-plus" style={{ color: 'blue', fontSize: 30 }}></i>
                        </button>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteQuestion(x.order)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          </> : <>
            <div className='col-12 mb-2 text-start'>
              <button
                disabled={loading ||mode==='VIEW'}
                type='button'
                onClick={()=> setAddItem({orderQuestion: 0, addItem: false})}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
                <i className="fa-solid fa-arrow-left" style={{ color: 'blue', fontSize: 15 }}></i>
              </button>
            </div>
            <div className='col-6 mb-10'>
              <label className='form-label mb-3 fw-bold'>Items</label>
              <div className='mb-2 col-12'>
                <label className='form-label mb-2'>Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={itemsAnswer.title}
                  onChange={(e) => setItemsAnswer(prev => ({...prev, title: e.target.value}))}
                />
              </div>
              <div className='mb-2 col-12'>
                <label className='mb-2 form-label'>Order</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  value={itemsAnswer.order}
                  onChange={(e)=> setItemsAnswer(prev=>({...prev, order: parseInt(e.target.value)}))}
                />
              </div>
              
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='button' onClick={addQuestionItem} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
            <div className='col-6 mb-10'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {values.questions.find(x => x.order === addItem.orderQuestion)?.items.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' 
                                onClick={() => navigator.clipboard.writeText(x.title)} 
                                className='text-dark fw-bold text-hover-primary fs-6'>
                                {`${x.title.slice(0, 20)}...`}
                              </a>
                              <span className='text-muted fw-semibold d-block fs-7'>
                                {x.order}
                              </span>
                            </div>
                          </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteQuestionItem(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {HistoryWritingForm}
