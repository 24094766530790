import {Route, Routes} from 'react-router-dom'
import { BasicVocabularyExercise } from '../../exercises/basic-vocabulary';
import { FrontPagePrismatic } from '../fron-page';
import { MainCardContent } from '../component/main-content';
 

const PrismaPage = () => (
    <Routes>
        {/* <Route path='/exercises' element={<BasicVocabularyExercise/>} /> */}
        {/* <Route path='/' element={<MainCardContent/>} /> */}
    </Routes>
);

export {PrismaPage};