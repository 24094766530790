import {Route, Routes} from 'react-router-dom'
import { HistoryWritingResponseDetails } from '../components/details'
import { HistoryWritingResponseTableList } from '../components/list'


const HistoryWritingResponsePage = () => (
  <Routes>
      <Route path='/details' element={<HistoryWritingResponseDetails />} />
      <Route path='/' element={<HistoryWritingResponseTableList />} />
  </Routes>
)

export {HistoryWritingResponsePage}
