import { TrailSuspense, DataAllTrailSuspenseResponse, DataPaginateTrailSuspenseResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'

import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseTrailSuspenseModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
const { settings } = UseMyRoadmapModule.getState()
const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useTrailSuspenseAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/trail-suspense/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UseTrailSuspenseMe = async (): Promise<any> => {
  if(!settings || !settings.id) throw 'Error'
  try {
    const response = await api.get(`/trail-suspense/me/${settings.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const UseTrailStrikeMe = async (): Promise<any> => {
  if(!settings || !settings.id) throw 'Error'
  try {
    const response = await api.get(`/trail-strikes/me/${settings.id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const useTrailSuspenseAllPaginate = async (): Promise<any> => {
  try {
    await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseTrailSuspenseModule.getState()

    const response: any = await api.get(
      `/trail-suspense/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const TrailSuspenseAdd = async (data: TrailSuspense): Promise<any> => {
  try {
    const response: any = await api.post(
      '/trail-suspense',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const TrailSuspenseUpdate = async (data: TrailSuspense): Promise<any> => {
  try {
    const { itemSelected } = UseTrailSuspenseModule.getState()
    const response: any = await api.patch(
      `/trail-suspense/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const TrailSuspenseDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/trail-suspense/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.log(e.response)
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
