export const ChapterTranslateEs = {
  CHAPTERS:"Libros",
  CHAPTER:"Libro",
  MANAGEMENT_CHAPTER:"Gestion de Libros",
  TYPE:"Tipo",
  NAME:"Nombre",
  DESCRIPTION:"Descripcion",
  ACTIVE:"Activo",
  ACTIONS:"Acciones",
  SEQUENCE:"Sequencia",
  LEVEL:"Nivel",
  PRE_A1_1: 'Pre A1-1',
  PRE_A1_2: 'Pre A1-2',
  A1: 'A1',
  PRE_A2: 'Pre A2',
  A2: 'A2',
  PRE_B1: 'Pre B1', 
  B1: 'B1',
  B2: 'B2',
  C1: 'C1',
  BY_HOUR: 'Por Horas',
  BY_TOPICS: 'Por Topicos',
  EXAM: 'Por Examen'
}