import React, { useEffect, useState } from 'react';
import { UseModuleCompleteSentence } from './store/main';

const DataTable: React.FC = () => {

  const [inputValues, setInputValues] = useState<{[key: string]: string}>({});
  const [dataInputs, setDataInputs] = useState<any[]>([]);
  const { answer, numberRow, questions, checked, setInputValuesMo, setNumberRow } = UseModuleCompleteSentence();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, columnIndex: number) => {
    const { value } = e.target;
    const newInputValues = { ...inputValues };
    newInputValues[`${columnIndex}-${rowIndex}`] = value;
    setInputValues(newInputValues);

    setDataInputs(Object.keys(newInputValues).map(key => {
      const [col, row] = key.split('-');
      return { col, row, value: newInputValues[key] };
    }));
  };

  useEffect(() => {
    setInputValuesMo(dataInputs);
  }, [dataInputs]);

  useEffect(() => {
    if (answer) {
      setInputValues(answer);
    }
  }, [answer]);

  useEffect(() => {
    let numberRow = 0;
    questions?.map(x => {
      numberRow = x.rowValue.length
    });
    setNumberRow(numberRow);
  }, [questions])

  const getInputValue = (rowIndex: number, columnIndex: number): string => {
    return inputValues[`${columnIndex}-${rowIndex}`] || '';
  };

  return (
      <div className="table-responsive rounded-4 border border-2 border-white " style={{marginTop:'10px'}}>
        <table className="table table-bordered border-white p-0">
          <thead>
            <tr className=" poppins-bold">
              {questions?.map((item, columnIndex) => (
                <th className='text-primary text-center bg-light p-2' key={columnIndex}>{item.title}</th>
              ))}
            </tr>
          </thead>
          <tbody className="text-white">
            {Array.from({ length: numberRow }, (_, rowIndex) => (
              <tr  key={rowIndex}>
                {questions?.map((item, columnIndex) => {
                  const inputValue = getInputValue(rowIndex, columnIndex);
                  const rowsValue = questions[columnIndex].rowValue[rowIndex];
                  const isValidValue = inputValue.trim() === rowsValue;
                  return (
                    <td className="poppins-bold py-1 " style={{height:'0%'}} key={columnIndex}>
                      <input 
                        id={`input-${rowIndex}-${columnIndex}`}
                        className={`inputExerciseOne ${checked && isValidValue ? 'true' : ''} ${checked && !isValidValue ? 'false' : ''}`}
                        type="text"
                        value={inputValue}
                        disabled={checked}
                        onChange={(e) => handleInputChange(e, rowIndex, columnIndex)}
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

  );
};

export default DataTable;