import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { UseLessonsModule } from "../../store/main";
import { LessonAddGlossaryTips } from "../../services";
import { GlossaryType } from "../../interfaces";
import { KTIcon } from "../../../../../../../../_metronic/helpers";

type Props = {
  type: 'GLOSSARY' | 'TIPS';
};

const GlossaryTipsForm: React.FC<Props> = ({type}) => {
  const queryClient = useQueryClient();
  const {itemSelected, resetData, mode, setTypeForm} = UseLessonsModule();

  const [glossaryData, setGlossaryData] = React.useState<GlossaryType[]>([]);
  const [tipsData, setTipsData] = React.useState<string[]>([]);
  const [dataForm, setDataForm] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (type === 'GLOSSARY') {
        await LessonAddGlossaryTips({glossary: glossaryData});
      }
      if (type === 'TIPS') {
        await LessonAddGlossaryTips({ tips: tipsData });
      }
      resetData();
      queryClient.invalidateQueries({ queryKey: ['lessons'] })
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const addedTip = () => {
    setTipsData(prev => [...prev, dataForm]);
    setDataForm(null);
  }

  const addedGlossary = () => {
    setGlossaryData(prev => [...prev, dataForm]);
    setDataForm(null);
  }
  
  const deleteSword = (order: number, tip: any, type: 'GLOSSARY'|'TIPS') => {
    if (type === 'GLOSSARY') {
      setGlossaryData(prev => prev.filter(x => x.order !== order));
    }
    if (type === 'TIPS') {
      setTipsData(prev => prev.filter(x => x !== tip));
    }
  }

  React.useEffect(() => {
    if (itemSelected && itemSelected.glossary) {
      setGlossaryData(itemSelected.glossary.map((x) => JSON.parse(x)));
    }
    if (itemSelected && itemSelected.tips) {
      setTipsData(itemSelected?.tips as string[]);
    }
  }, [itemSelected]);

  return(<>
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className="form">
        <div className="card-body border-top p-9 row">
          {mode !== 'ADD' && <div className='d-flex justify-content-start flex-shrink-0 mb-2'>
            <button
              type='button'
              onClick={()=> setTypeForm(undefined)}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
            </button>
          </div>}
          {type === 'GLOSSARY' && <>
            <div className="mb-10 col-12">
              <label className="form-label">Title</label>
              <input type="text"
                value={dataForm?.title}
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => setDataForm(prev => ({...prev, title: e.target.value}))}
              />           
            </div>
            <div className="mb-10 col-12">
              <label className="form-label">Description</label>
              <input type="text"
                value={dataForm?.description}
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => setDataForm(prev => ({...prev, description: e.target.value}))}
              />
            </div>
            <div className="mb-10 col-6">
              <label className="form-label">Order</label>
              <input type="number"
                  value={dataForm?.order}
                  className='form-control form-control-lg form-control-solid'
                  onChange={(e) => setDataForm(prev => ({...prev, order: parseInt(e.target.value)}))}
                />
            </div>
            <div className='col-6 card-footer d-flex justify-content-end py-8 px-9'>
              <button type='button' onClick={addedGlossary} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            <div className="col-12">
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Order</th>
                    <th>Title</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {glossaryData?.map((x, i) => (
                    <tr key={i}>
                      <td>
                        <span>
                          {x?.order}
                        </span>
                      </td>
                      <td className=''>
                        <span>
                          {x?.title}
                        </span>
                      </td>
                      <td>
                        <span>
                          {x?.description}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteSword(x.order, undefined, 'GLOSSARY')}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}
          {type === 'TIPS' && <>
            <div className="mb-10 col-12">
              <label className="form-label">Tip</label>
              <input type="text"
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => setDataForm(e.target.value)}
              />
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addedTip} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            <div className="col-12">
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Title</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tipsData?.map((x, i) => (
                  <tr key={i}>
                    <td className=''>
                      <span>
                        {x}
                      </span>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteSword(0, x, 'TIPS')}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </>}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  </>)
}

export {GlossaryTipsForm};