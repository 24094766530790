import React, { useEffect, useState } from 'react';
import IconFa from '../Icon/indext';
import { useThemeMode } from '../../../../_metronic/partials';
interface Props {
  limit: number,
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
  changePage: (newPage: number) => void;
  changeLimit: (newPage: number) => void;
}

const GenericPaginator: React.FC<Props> = ({
  limit,
  currentPage,
  items,
  total,
  totalPage,
  changePage,
  changeLimit
}) => {
  const [limitPage, setLimit] = useState<number>(limit)
  const initialIndex = (currentPage - 1) * limit + 1;
  const finalIndex = Math.min(currentPage * limit, total);
  useEffect(() => {
    setLimit(limit)
  }, [limit])
  const {mode} = useThemeMode();

  return (< div className='row'>
    <div className='col-lg-1 col-3'>
      <select
        name='limit'
        value={limit}
        onChange={(e) => {
          setLimit(parseInt(e.target.value));
          changeLimit(parseInt(e.target.value));
        }}
        style={{padding: 1.5}}
        className='form-select'
        aria-label='Default select example'
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      {/* <input
        value={limitPage > 30 ? 30 : limitPage||1}
        onChange={(e) => {
          const newlimit = Math.min(parseInt(e.target.value),30)
          setLimit(newlimit)
          changeLimit(newlimit)
        }}
        style={{ padding: 1.5 }}
        type="number" className="form-control" id="exampleFormControlInput1" /> */}
    </div>
    <div className='col-6' style={{ color:mode==='light'?'#2d30e1':'#FFFFFF' }}>
      <span className='poppins-light'>
        Mostrando {initialIndex} - {finalIndex} de {total} |
      </span>
      <span className='poppins-light'>
        Página {currentPage} de {totalPage}
      </span>
    </div>
    <div className='col-md-6'>
    </div>
    <div>
      <nav aria-label="Page navigation example">
        <ul className="pagination pl-0">
          {currentPage > 1 && <li className="page-item">
            <button
              type='button'
              onClick={() => changePage(currentPage - 1)}
              className="page-link" disabled={currentPage === 1} aria-label="Previous">
              <IconFa name={"fas fa-arrow-alt-circle-left"} />
            </button>
          </li>}
          {totalPage > currentPage && <li className="page-item">
            <button className="page-link"
              type='button'
              disabled={currentPage === totalPage}
              onClick={() => changePage(currentPage + 1)}
              aria-label="Previous">
              <IconFa name={"fas fa-arrow-alt-circle-right"} />
            </button>
          </li>}
        </ul>
      </nav>
    </div>
  </div>);
};

export default GenericPaginator;