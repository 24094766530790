import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { SettingModalityType } from '../../admin/user/interfaces'
import { ClassroomType } from '../../admin/classroom/interfaces'

export type ClassTypeBooking = 'CLASS'|'WORKSHOP'

interface BookingWindows {
  step: number
  classType:ClassTypeBooking
  classroomType?: ClassroomType;
  setStep: (by: number) => void;
  setClassType: (value: ClassTypeBooking) => void;
  setClassroomType:(value: ClassroomType)=>void;
}

export const UseBookingWindows = create<BookingWindows>()(
 
    persist(
      (set) => ({
        step: 0,
        classType:'CLASS',
        setStep:(value:number)=>{
            set({step:value})
        },
        setClassType:(value:ClassTypeBooking)=>{
            set({step:3,classType:value})
        },
        setClassroomType:(value)=>{
          set({step: 2, classroomType: value});
        }
      }),
      { name: 'booking-windows' },
    ),
 
)