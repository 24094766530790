
import React, { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Advertisements } from '../../../admin/advertisements/interfaces';
import { UseAdvertisementsPublic } from '../../../admin/advertisements/services';
import './styles.css'
import { t } from 'i18next';
import { useThemeMode } from '../../../../../_metronic/partials';
type Props = {
    title:string;
    className?:string
    icon:string
    colorTitle:string
    background:string;
    url?:string;
}
const LinkComponent: React.FC<Props> = ({ className, icon, background,title,colorTitle,url }: Props) => {
    const { mode } = useThemeMode()
    const [item, setItem] = React.useState<Advertisements[]>([]);
    const findAllItems = async () => {
        let response = await UseAdvertisementsPublic();
        setItem(response)
    }

    useEffect(() => {
        findAllItems()
    }, [])
    return (<>
        <div className={`${className}`}
        >
            <a href={url??'#'} className='d-flex flex-column text-decoration-none card  h-100' 
                style={
                    mode != 'dark' ?{ 
                        borderRadius: '50px',
                        background:background,
                        padding:3
                    }:{ 
                        borderRadius: '50px',
                        background:'#1e1e2d',
                        padding:3,
                        border: `1px solid`, 
                        borderColor: background
                    }
                }>
                <span className='pt-5 text-center poppins-bold link-title-icon-card' 
                    style={mode!='dark'?
                        {color:colorTitle}:
                        {color:background}
                        }>
                    {t(title)}
                </span>
                <div className='pb-5 d-flex justify-content-center align-items-center'>
                    <img className='link-icon-michigan' src={toAbsoluteUrl(icon)} alt="" style={{ width: '' }} />
                </div>
            </a>
        </div>
    </>);
}
export { LinkComponent }