import { ApprovalType, Chapter } from "../../learning-path/modules/chapter/interfaces";

export interface DataPaginateRoadMapStepResponse {
    status:  string;
    message: string;
    data:    DataRoadMapStep;
}

export interface DataAllRoadMapStepResponse {
    status:  string;
    message: string;
    data:    RoadMapStep[];
}

export interface DataRoadMapStep {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    roadMap: string;
    chapter: string;
    order: number;
    hours: number;
    lessons: number;
    approval: ApprovalType;
    hoursToSee: number;
    level: TraditionalLevelEnum;
    sequence: SequenceType;
    levelToSee: TraditionalLevelEnum;
    finalPresentation: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface RoadMapStep  {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    roadMap: string;
    chapter?: any;
    order: number;
    hours: number;
    lessons: number;
    hoursToSee: number;
    level: TraditionalLevelEnum;
    sequence: SequenceType;
    levelToSee: TraditionalLevelEnum;
    approval: ApprovalType;
    finalPresentation: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: RoadMapStep[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum TraditionalLevelEnum {
    PRE_A1_1 = 'PRE_A1_1',
    PRE_A1_2 = 'PRE_A1_2',
    A1 = 'A1',
    PRE_A2 = 'PRE_A2',
    A2 = 'A2',
    PRE_B1 = 'PRE_B1', 
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1'
}

export enum SequenceType {
    STANDARD = 'STANDARD',
    RANDOM = 'RANDOM'
}

export enum SettingPlacementTestStatus {
    PENDING = 'PENDING',
    IN_PROCESS = 'IN_PROCESS',
    FINISHED = 'FINISHED',
    CANCEL = 'CANCEL',
    NO_APPLY = 'NO_APPLY'
}

export enum SettingWelcomeDayStatus {
    PENDING = 'PENDING',
    IN_PROCESS = 'IN_PROCESS',
    FINISHED = 'FINISHED',
    NO_APPLY = 'NO_APPLY'
}

export enum SettingVerificationStatus {
    PENDING = 'PENDING',
    IN_PROCESS = 'IN_PROCESS',
    APPROVED = 'APPROVED',
    CANCELLED = 'CANCELLED'

}