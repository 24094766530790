import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {SupportTicket, SupportTicketPriority, SupportTicketStatus, SupportTicketType} from '../../interfaces'

import { SupportTicketAdd, SupportTicketUpdate } from '../../services'
import { UseSupportTicketModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish'
import MultipleFileInput from '../../../../files/components/multiple-files'
import useAuth from '../../../../../api/main'

export type FormFields = {
  files?: any[];
  //status: SupportTicketStatus;
  priority: SupportTicketPriority;
  isActive: boolean;
  type: SupportTicketType;
  description:string;
  name: string;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const SupportTicketForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const { email } = useAuth.getState()

  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseSupportTicketModule();
  const [data, setData] = useState<SupportTicket>();
  const [files, setFiles] = useState<any[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        //status: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.status ?? SupportTicketStatus.IN_PROCESS : SupportTicketStatus.IN_PROCESS,
        priority: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.priority ?? SupportTicketPriority.LOW : SupportTicketPriority.LOW,
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? SupportTicketType.CONGRATULATIONS : SupportTicketType.CONGRATULATIONS
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await SupportTicketAdd({
              ...formData,
              // name: email??'xxx',
              status: SupportTicketStatus.IN_PROCESS,
              files: files.map(x => x.id)
            });
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['support-ticket'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  SupportTicketUpdate({
                ...formData,
                // name: email??'xxx',
                status: itemSelected ? itemSelected.status : SupportTicketStatus.IN_PROCESS,
                files: files.map(x => x.id)
              });
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['support-ticket'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row poppins-light'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Prioridad</label>
            <select
              disabled={mode === 'VIEW'}
              name='priority'
              value={values.priority}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SupportTicketPriority).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errors.priority && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.priority}</span>
                </div>
              </div>
            )}
          </div> */}
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Tipo de PQRS</label>
            <select
              disabled={mode === 'VIEW'}
              name='type'
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SupportTicketType).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errors.priority && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.priority}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Asunto</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            { errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Status</label>
            <select
              disabled={mode === 'VIEW'}
              name='status'
              value={values.status}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SupportTicketStatus).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {errors.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.status}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Support Files</label>
            <MultipleFileInput
              files_={files}
              onChangeFile={(e) => { setFiles(files.concat(e)) }}
              onChangeLoading={(e) => console.log(e)}
              onDeleteFile={(e) => {
                alert(e)
                setFiles(prevFiles => {
                  const updatedFiles = prevFiles.filter((_, index) => index !== e);
                  return updatedFiles;
                });
              }}
            />
          </div>

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {SupportTicketForm}