export interface DataPaginateLearningPathResponse {
    status:  string;
    message: string;
    data:    DataLearningPath;
}

export interface DataAllLearningPathResponse {
    status:  string;
    message: string;
    data:    LearningPath[];
}

export interface DataLearningPath {
    id?: string;
    description:string;
    name:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface LearningPath  {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    bannerPrincipal?: any;
    bannerVertical?: any;
    bannerLobbyHorizontal?: any;
    colorBase?: string;
    logo?: any;
    type: LearningPathType;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: LearningPath[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum LearningPathType {
    ENGLISH_EXPLORER = 'ENGLISH_EXPLORER',
    PRISMATIC = 'PRISMATIC',
    ABROAD = 'ABROAD'
}