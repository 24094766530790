import {create} from 'zustand';
import { Pagination, TemplateTableTimeDay, TemplateTableTimeItemData } from '../../../interfaces'

type tabType = 'TEACHER' | 'ACTIVITY' | 'TEACHER_FORM' | 'ACTIVITY_VIEW' |'CLASSROOM'
interface DayAvailableType {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
}
interface TemplateTableTimeItemModules {
    root: string,
    activity?: string,
    campus?: string,
    blockHour?: number,
    country?: string,
    teacherId?: string,
    tabList: tabType,
    dayAvailable?: DayAvailableType,
    itemSelected?: TemplateTableTimeItemData,
    day: TemplateTableTimeDay,
    dayList?: TemplateTableTimeDay,
    mode:'ADD'|'EDIT'|'VIEW',
    type:'FACE'|'VIRTUAL'|'ALL',
    modal:boolean,
    searchTeacher?:string,
    isActive:boolean,
    paginator:Pagination,
    paginatorForm:Pagination,
    where:string,
    currentPage:number,
    currentPageForm:number,
    limit:number,
    limitForm:number,
    total:number,
    totalForm:number,
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setSelectedToEdit:(item:TemplateTableTimeItemData)=>void;
    setSelectedToView:(item:TemplateTableTimeItemData)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setPaginatorForm:(paginatorForm:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setCurrentPageForm:(value:number)=>void,
    setLimit:(value:number)=>void,
    setLimitForm:(value:number)=>void,
    setWhere:(value:string)=>void,
    setToAdd:()=>void,
    setTeacherId:(value:string)=>void,
    setDay:(value: TemplateTableTimeDay)=>void,
    setDayList:(value: TemplateTableTimeDay | undefined)=>void,
    setRoot:(value: string)=>void,
    setActivity:(value:string | undefined)=>void,
    setTabList:(value: tabType)=>void,
    setDayAvailable:(value: DayAvailableType)=>void,
    setBlockHour:(value: number)=>void,
    setActivityLabel:(value: string)=>void,
    resetPaginator:()=>void
    setType:(value:any)=>void,
    setSearchTeacher:(value:string)=>void,
    setCampus:(value:string)=>void,
    setCountry:(v: string | undefined)=>void,
}

export const UseTemplateTableTimeItemModule = create<TemplateTableTimeItemModules>( (set) => ({
    root: '',
    day: TemplateTableTimeDay.MONDAY,
    tabList: 'TEACHER',
    mode:'ADD',
    modal:false,
    currentPage:1,
    currentPageForm:1,
    where:'',
    limit:10,
    limitForm:15,
    isActive:true,
    total:0,
    type:'ALL',
    totalForm:0,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    paginatorForm:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setCountry:(v) => {
        set({country: v});
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT'})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        set({
            itemSelected:undefined,
            mode:'ADD', 
            day: TemplateTableTimeDay.MONDAY,
            activity:undefined,
        })
    },
    setPaginator:(pagination)=>{
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            limit:pagination.limit,
            current_page:pagination.current_page,
            total_page:pagination.total_page
        }})
    },
    setPaginatorForm:(paginationForm)=>{
        set({paginatorForm:{
            result:paginationForm.result,
            total:paginationForm.total,
            limit:paginationForm.limit,
            current_page:paginationForm.current_page,
            total_page:paginationForm.total_page
        }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setCurrentPageForm:(value)=>{
        set({currentPageForm:value})
    },
    setSearchTeacher:(value)=>{
        set({searchTeacher:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setLimitForm:(value)=>{
        set({limitForm:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setTeacherId(value) {
        set({teacherId:value,modal:true,tabList:'TEACHER_FORM'})
    },
    setDay(value) {
        set({ day: value })
    },
    setDayList(value) {
        set({dayList: value})
    },
    setRoot(value) {
        set({ root: value })
    },
    setActivity(value) {
        set({activity:value, modal:true,tabList:'ACTIVITY_VIEW'})
    },
    setTabList(value) {
        set({tabList:value})
    },
    setDayAvailable(value) {
        set({dayAvailable: value})
    },
    setBlockHour(value) {
        set({ blockHour: value })
    },
    setActivityLabel(value) {
        set({activity: value})
    },
    setType(value) {
        set({type: value})
    },
    setCampus(value) {
        set({campus: value})
    },
    resetPaginator() {
        set({paginatorForm: {
            result:[],
            total:0,
            limit:0,
            current_page:1,
            total_page:0
            },
            currentPageForm: 1,
            limitForm: 4,
            totalForm: 0
        })
    },
}));