import { useQuery, useQueryClient } from '@tanstack/react-query'
import { UseResumeLeadsAllPaginate, UseResumeLeadsExecutive, UseResumeLeadsTeacher } from '../services'

export function useAppointmentState(){
  return  useQuery({ queryKey: ['resume'], queryFn: UseResumeLeadsAllPaginate })
}

export function UseAppointmentTeacherState(){
  return  useQuery({ queryKey: ['resume-teacher'], queryFn: UseResumeLeadsTeacher })
}

export function UseAppointmentExecutiveState(){
  return  useQuery({ queryKey: ['resume-executive'], queryFn: UseResumeLeadsExecutive })
}