import {create} from 'zustand';

interface TimersInterface {
  currentTimers: string[]
  exerciseBeforeTime: boolean
  timeLeft: {time: number, exerciseId: string}[]
  addCurrentTimer: (exerciseId: string) => void
  resetTimers: () => void
  setExerciseBeforeTime: (value: boolean)=>void
  setTimeLeft: (value: {time: number, exerciseId: string}) =>void
}

export const UseExerciseTimerModule = create<TimersInterface>( (set, get) => ({
  exerciseBeforeTime: true,
  timeLeft: [],
  currentTimers: [],
  setExerciseBeforeTime : (value) => {
    set({ exerciseBeforeTime: value });
  }, 
  setTimeLeft: (value) => {
    set((state) => {
      const existingTimerIndex = state.timeLeft.findIndex(
        (timer) => timer.exerciseId === value.exerciseId
      );

      if (existingTimerIndex !== -1) {
        // Si el timer ya existe, actualiza el tiempo
        const updatedTimers = state.timeLeft.map((timer, index) =>
          index === existingTimerIndex ? value : timer
        );
        return { timeLeft: updatedTimers };
      } else {
        // Si el timer no existe, añádelo
        return { timeLeft: [...state.timeLeft, value] };
      }
    });
  },
  addCurrentTimer: (exerciseId) =>{
    const currentIds = get().currentTimers
    const newCurrentIds = [...currentIds, exerciseId]
    set({currentTimers: newCurrentIds})
  },
  resetTimers: ()=> {
    set({
      exerciseBeforeTime: true,
      timeLeft: [], 
      currentTimers: [],
    })
  }

}));