// Gap.tsx
import React, { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { WordItem ,Gap} from './types';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import { EvaluationType } from '../../../admin/evaluation/interfaces';

interface GapProps {
  size:number
  initialFill?: any;
  colorString:string
  display:string
  gap: Gap;
  reviewMode:boolean
  onFill: (gapId: string, wordId: string) => void; // Función para manejar el llenado de los huecos
  onRemove: (gapId: string, wordId: string) => void; 
}

const Gap_: React.FC<GapProps& { resetFlag: number,isCorrect?: boolean, hasChecked: boolean }> = ({ size,colorString,gap, onFill, onRemove,resetFlag ,isCorrect, hasChecked,display,initialFill,reviewMode}) => {
 
  const [filledWord, setFilledWord] = useState<WordItem | null>(null);
  const hasCheckedRef = useRef(hasChecked)
  const {
    evaluationLesson,
  } = UseEvaluationCourseModule();
  const isNotImportantQuiz =  evaluationLesson?.type !== EvaluationType.FINAL_PRESENTATION && evaluationLesson?.type !== EvaluationType.PLACEMENT_TEST && evaluationLesson?.type !== EvaluationType.QUIZ

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'word',
    drop: (item: WordItem, monitor) => {
    if (!filledWord && !hasCheckedRef.current) { //solo cuando la palabra este vacia y ademas el ejercicio no haya sido calificado
        setFilledWord(item);
        onFill(gap.id, item.id);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  // Manejador de clics para la palabra seleccionada
  const handleClick = () => {
    if(reviewMode){
      return
    }
    if(hasChecked){
      return
    }
    if (filledWord) {
    onRemove(gap.id, filledWord.id); // Llama a onRemove cuando se hace clic en una palabra seleccionada
    setFilledWord(null); // Limpia el hueco
    }
  };

  const calcularPixeles= (palabra: string, pixelesPorCaracter: number = 11): number=> {
    return palabra.length * pixelesPorCaracter;
  }
  useEffect(() => {
    // Esto se ejecutará cada vez que `resetFlag` cambie, indicando un reseteo
    setFilledWord(null);
  }, [resetFlag]);

  useEffect(() => {
    hasCheckedRef.current = hasChecked;
  }, [hasChecked])

  return (
    !reviewMode?(<div
      ref={drop}
      style={{
        display: display,
        width: `${calcularPixeles(filledWord?filledWord.text:"lorem",filledWord?14:10)}px`,
        minHeight: '18px', // Ajusta según la altura deseada incluyendo el padding
        lineHeight: '20px', // Ajusta según el tamaño de la fuente para centrar el texto verticalmente
        margin: '0 1px',
        padding: '0px 0px', // Asegura suficiente espacio arriba y abajo para mantener la altura constante
        backgroundColor: (filledWord && hasChecked && isNotImportantQuiz) ? (isCorrect ? 'green' : 'red') : '#C6F711',
        textAlign: 'center',
        border:'2px solid #000B9F',
        // border:(filledWord && hasChecked && isNotImportantQuiz) ?'':`1px solid ${colorString}`,
        borderRadius: '30px',
        color:(filledWord && hasChecked && isNotImportantQuiz) ?'white':colorString,
        cursor: 'pointer',
        fontSize:size
      }}
      onClick={handleClick} // Agrega el manejador de clics aquí
    >
      {/* {initialFill && <>{initialFill}</>}ç */}
        {filledWord ? filledWord.text : '.'}  
    </div>):(
      <div
      ref={drop}
      style={{
        display: display,
        width: `${calcularPixeles(initialFill?initialFill:"lorem",initialFill?14:10)}px`,
        minHeight: '18px', // Ajusta según la altura deseada incluyendo el padding
        lineHeight: '20px', // Ajusta según el tamaño de la fuente para centrar el texto verticalmente
        margin: '0 1px',
        padding: '0px 0px', // Asegura suficiente espacio arriba y abajo para mantener la altura constante
        backgroundColor: (filledWord && hasChecked && isNotImportantQuiz) ? (isCorrect ? 'green' : 'red') : '#f9f9f9',
        textAlign: 'center',
        border:(filledWord && hasChecked && isNotImportantQuiz) ?'':`1px solid ${colorString}`,
        borderRadius: '30px',
        color:(filledWord && hasChecked && isNotImportantQuiz) ?'white':colorString,
        cursor: 'pointer',
        fontSize:size
      }}
  // Agrega el manejador de clics aquí
    >
      {initialFill} 
    </div>
    )
  );
};

export default Gap_;
