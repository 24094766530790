import React from 'react';
import { useThemeMode } from '../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { UseBookingWindows } from '../store';
import { UseMyRoadmapModule } from '../../profile/components/my-road-step/store';
import { UseTrailStrikeMe } from '../../admin/trail-suspense/services';
import { UseBookingModule } from '../../me-booking/store';
import { SequenceType } from '../../abroad/road-map-step/interfaces';
import { useNavigate } from 'react-router-dom';

type Props = {

};

const StartBooking: React.FC<Props> = () => {
    const [windowMode, setWindowMode] = React.useState<'NORMAL' | 'WELCOME' | 'VERIFICATION' | 'PLACEMENT_TEST'>('NORMAL');
    const { mode } = useThemeMode();
    const navigate = useNavigate();
    let { setStep, step } = UseBookingWindows()
    const { level, setStrikes, strikes } = UseBookingModule();
    const { settings } = UseMyRoadmapModule();
    const findTrailStrikes = async () => {
        try {
            let response = await UseTrailStrikeMe();
            setStrikes(response);
        } catch (e) {
            console.log(e)
        }
    };
    const setterWindowsMode = () => {
        if (settings?.verification == 'PENDING' || settings?.verification == 'IN_PROCESS') {
            setWindowMode('VERIFICATION')
            return
        }
        if (settings?.welcomeDay == 'PENDING' || settings?.welcomeDay == 'IN_PROCESS') {
            setWindowMode('WELCOME')
            return
        }
        if (settings?.placementTest == 'PENDING' || settings?.placementTest == 'IN_PROCESS') {
            setWindowMode('PLACEMENT_TEST')
            return
        }
        setWindowMode('NORMAL')
    }
    React.useEffect(() => {
        setterWindowsMode();
    }, [settings]);
    React.useEffect(() => {
        findTrailStrikes();
    }, [level]);

    return (<>
        {windowMode == 'PLACEMENT_TEST' &&
            <div className='d-flex' style={{ height: '280px', maxHeight: '280px' }}>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                    <span className='poppins-bold fs-4' style={{ marginLeft: '-35px', color: '#2d30e1' }}>Placement test</span>
                </div>
                <div className='col-6 d-flex align-items-center'>
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/booking_blue.svg`)} alt="" style={{ width: '225px' }} className='cursor-pointer' 
                        onClick={() => navigate((settings?.taskId && settings?.evaluationId) ? `/evaluation/screen/course/${settings.evaluationId}` : '#')}
                    />
                </div>
            </div>
        }
        {windowMode == 'VERIFICATION' &&
            <div className='d-flex' style={{ height: '280px', maxHeight: '280px' }}>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                    <span className='poppins-bold fs-4' style={{ marginLeft: '-35px', color: '#2d30e1' }}>Got to verification</span>
                </div>
                <div className='col-6 d-flex align-items-center'>
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/booking_blue.svg`)} alt="" style={{ width: '225px' }} className='cursor-pointer' />
                </div>
            </div>
        }
        {windowMode == 'WELCOME' &&
            <div className='d-flex' style={{ height: '280px', maxHeight: '280px' }}>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                    <span className='poppins-bold fs-4' style={{ marginLeft: '-35px', color: '#2d30e1' }}> Welcome day!</span>
                </div>
                <div className='col-6 d-flex align-items-center'>
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/booking_blue.svg`)} alt="" style={{ width: '225px' }} className='cursor-pointer' 
                        onClick={() => navigate((settings?.taskId && settings?.evaluationId) ? `/evaluation/screen/course/${settings.evaluationId}` : '#')}
                    />
                </div>
            </div>
        }
        {windowMode =='NORMAL' && <>
            {settings && settings.isSuspense ?
            <div className='d-flex' style={{ height: '280px', maxHeight: '280px', backgroundColor: '#ffc3d3' }}>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/ban_red.svg`)} alt="" style={{ width: '180px' }} />
                </div>
                <div className='col-6 d-flex ms-3 justify-content-center flex-column'>
                    {/* <span className='poppins-light fs-4' style={{ color: '#ff336a' }}>You are suspended</span> */}
                    <span className='champ-bold d-flex align-items-center' style={{ color: '#ff336a', fontSize: 30 }}>You are suspended</span>
                    {/* <span className='champ-bold' style={{ fontSize: 22, color: '#ff336a' }}>23:08:30</span> */}
                </div>
            </div>
            : settings?.isFrozen ?
            <div className='d-flex' style={{ height: '280px', maxHeight: '280px', backgroundColor: '#3e94ff' }}>
                <div className='col-6 d-flex align-items-center justify-content-center'>
                    <i className="bi bi-snow text-white" style={{fontSize: '180px'}}></i>
                    {/* <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/ban_red.svg`)} alt="" style={{ width: '180px' }} /> */}
                </div>
                <div className='col-6 d-flex ms-3 justify-content-center flex-column'>
                    <span className='poppins-light fs-4' style={{ color: '#FFFFFF' }}>You are Frozen until</span>
                    <span className='champ-bold d-flex align-items-center' style={{ color: '#2d30e1', fontSize: 30 }}></span>
                    <span className='champ-bold' style={{ fontSize: 22, color: '#FFFFFF' }}>{settings && settings?.finishFreezing}</span>
                </div>
            </div>
            : settings?.limitTasksPending && settings.typeStep === SequenceType.RANDOM ?
                <div className='d-flex' style={{ height: '280px', maxHeight: '280px', backgroundColor: '#ffc3d3' }}>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/ban_red.svg`)} alt="" style={{ width: '180px' }} />
                    </div>
                    <div className='col-6 d-flex ms-3 justify-content-center flex-column'>
                        <span className='poppins-light fs-4' style={{ color: '#ff336a' }}>You already have</span>
                        <span className='champ-bold d-flex align-items-center' style={{ color: '#ff336a', fontSize: 30 }}>{settings.numberTasksPending ?? 0} pending tasks</span>
                    </div>
                </div>
                :
                <div className='d-flex' style={{ height: '280px', maxHeight: '280px' }}>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                        <span className='poppins-bold fs-4' style={{ marginLeft: '-35px', color: '#2d30e1' }}>Programador</span>
                    </div>
                    <div className='col-6 d-flex align-items-center'>
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/booking_blue.svg`)} alt="" style={{ width: '225px' }} className='cursor-pointer' onClick={() => setStep(1)} />
                    </div>
                </div>}
       </> }
    </>)
};

export { StartBooking };