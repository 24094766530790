import React, { useRef } from 'react';
import { FileFolderAdd } from '../../../modules/admin/file-folder/services';
import { FileContentChildrenAdd } from '../../../modules/admin/file-folder/services/file-content-course';

type FileUploadProps = {
  onChangeFile: (file: any) => void;
  onChangeLoading: (isLoading: boolean) => void;
  idInput?: string;
  pushCover?: boolean;
};

const FileUploadComponent: React.FC<FileUploadProps> = ({ onChangeFile, onChangeLoading, idInput, pushCover }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const handleFileUpload = async () => {
    try {
      if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
        onChangeLoading(true);
        const formData = new FormData();
        formData.append('file', fileInputRef.current.files[0]);
        const response = typeof pushCover !== 'undefined' && pushCover ? await FileContentChildrenAdd(formData) : await FileFolderAdd(formData);
        await changeFile(response);
        onChangeLoading(false);
      }
    } catch (error) {
      console.error(error);
      onChangeLoading(false);
    }
  };

  const changeFile = (file: any) => {
    onChangeFile(file);
  };

  return (<>
    <label htmlFor={idInput ?? 'username'} className=' btn    border-white' >
         
            <i style={{ color: '#009ef7', fontSize: 40 }} className="fas fa-image"></i>
     
    </label>
        <input ref={fileInputRef} onChange={handleFileUpload} type="file" style={{display:'none'}}   id={idInput ?? 'username'}  />
  </>
  );
};

export default FileUploadComponent;