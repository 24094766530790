import { TraditionalLevelEnum } from "../../../../../abroad/road-map-step/interfaces";

export interface DataPaginateWritingCheckListResponse {
    status:  string;
    message: string;
    data:    DataWritingCheckList;
}

export interface DataAllWritingCheckListResponse {
    status:  string;
    message: string;
    data:    WritingCheckList[];
}

export interface DataWritingCheckList {
    id?: string;
    description:string;
    limit: number;
    name:string;
    minimum: number;
    level: TraditionalLevelEnum;
    questions: ItemsWritingCheckListItems[];
    exercises: string;
    type: WritingCheckListType;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface WritingCheckList  {
    id?: string;
    description?:string;
    limit: number;
    name:string;
    minimum: number;
    level: TraditionalLevelEnum;
    questions: any[];
    exercises: string;
    type: WritingCheckListType;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: WritingCheckList[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum WritingCheckListType {
    WRITING_CHECK_LIST = 'WRITING_CHECK_LIST',
    TIME_TO_SPEAK = 'TIME_TO_SPEAK',
    DID_YOU_UNDERSTAND = 'DID_YOU_UNDERSTAND',
}

export interface ItemsWritingCheckListItems {
    title :string
    order:number
}