import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Evaluation, EvaluationType} from '../../interfaces'

import { EvaluationAdd, EvaluationUpdate } from '../../services'
import { UseEvaluationModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../abroad/road-map-step/interfaces'
import { useTranslation } from 'react-i18next'
import FileUploadComponent from '../../../../../components/common/SingleFile'
import { favIconAppSelector } from '../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  colorPrimary: string;
  colorSecondary: string;
  obligatory: boolean;
  cover?: string;
  type: EvaluationType;
  level: TraditionalLevelEnum;
  minApprove: number;
  feedbackImmediate: boolean;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  colorPrimary: Yup.string().required('Color Primary is required'),
  colorSecondary: Yup.string().required('Color Secondary is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const EvaluationForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();

  const {itemSelected, mode,resetData,setType} = UseEvaluationModule();
  const [cover, setCover] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        colorPrimary: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.colorPrimary ?? '' : '',
        colorSecondary: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.colorSecondary ?? '': '',
        obligatory: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.obligatory ?? true : true,
        cover: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.cover?.id ?? undefined : undefined,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? EvaluationType.QUIZ : EvaluationType.QUIZ,
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_2 : TraditionalLevelEnum.PRE_A1_2,
        minApprove: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.minApprove ?? 80 : 80,
        feedbackImmediate: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.feedbackImmediate??false : false
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await EvaluationAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['evaluation'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  EvaluationUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['evaluation'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const changeCover = (obj: any) => {
      setCover(obj);
      setFieldValue('cover', obj.id);
    };

    React.useEffect(() => {
      setCover(itemSelected?.cover);
    }, [itemSelected]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>
          {mode!== 'ADD' && <div className='d-flex justify-content-start mb-4'>
            <div className=''>
              <button type='button' className='btn bg-light btn-active-primary' onClick={() => setType('LIST')}>
                <span className='py-1 px-2'>
                  Item
                </span>
              </button>
            </div>
          </div>}
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Feedback Inmediato ?</label>
            <div className="form-check form-switch">
              <input
                name={'feedbackImmediate'}
                checked={values.feedbackImmediate}
                value={values.feedbackImmediate?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Obligatorio ?</label>
            <div className="form-check form-switch">
              <input
                name={'obligatory'}
                checked={values.obligatory}
                value={values.obligatory?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Tópico</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Nivel</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={values.level}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.level}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Color Primario</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'colorPrimary'}
              value={values.colorPrimary}
              onChange={handleChange}
            />
            {touched.colorPrimary && errors.colorPrimary && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.colorPrimary}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Color Secundario</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'colorSecondary'}
              value={values.colorSecondary}
              onChange={handleChange}
            />
            {touched.colorSecondary && errors.colorSecondary && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.colorSecondary}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Porcentaje de aprobación</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'minApprove'}
              value={values.minApprove}
              onChange={handleChange}
            />
            {touched.minApprove && errors.minApprove && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.minApprove}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Tipo</label>
            <select
              disabled={mode === 'VIEW'||mode==='EDIT'}
              name='type'
              value={values.type}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(EvaluationType).filter(key => key !== 'SINGLE_QUIZ').map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.type && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.type}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Banner Principal</label>
            {(cover && (cover.url || cover.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(cover.url || cover.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileUploadComponent 
              onChangeFile={(e) => changeCover(e)} 
              onChangeLoading={(e) => setLoading(e)} 
              idInput='cover'/>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Descripción</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {EvaluationForm}
