import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../ui/HeaderCard';
   type Props = {
       instruction: string
   }
const Exercisefifteen: React.FC<Props> = ({instruction}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '570px',
        
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
      const input= {
        
        with:'10%',
        borderRadius:'40px',
        border:'0px',
        backgroundColor:'#D0FF00',
        outline:'none',
      
      }
 
        return(<>
                <div className="container-fluid" style={{display: 'flex'}}>
                <div style={divStyle}>
                <HeaderCard/>
                <p className=" text-white  mb-0 poppins-light fs-9 p-2" >{instruction}</p>
                <div className="row px-2">
                        <div className="col-5  ">
                           
                                <div className="col text-center rounded-4 border border-2 border-white poppins-bold text-white fs-8"><span>Column A </span></div>
                                
                                <div className="row  p-2 ">

                                    <div className='col-2 fs-8 text-center text-white' style={{ width: '55px', height: '50px' ,marginTop:'13px'}} >
                                        <div className="rounded-circle border border-1 border-white" style={{ width: '45px', height: '45px' }} >
                                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="img"/>
                                        </div>
                                        <div className=''>
                                            <span className=' align-text-bottom ' >descri</span>
                                        </div>
                                    </div>

                                    <div className='col-2  fs-8 text-center text-white' style={{ width: '55px', height: '50px',marginTop:'13px' }} >
                                        <div className="rounded-circle border border-1 border-white" style={{ width: '45px', height: '45px' }} >
                                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} alt="img"/>
                                        </div>
                                        <div className=''>
                                            <span className=' align-text-bottom' >b. Rok</span>
                                        </div>
                                    </div>

                                    <div className='col-2 fs-8 text-center text-white' style={{ width: '55px', height: '50px', marginTop:'13px' }} >
                                        <div className=" rounded-circle border border-1 border-white mb-0 p-0" style={{ width: '45px', height: '45px' }} >
                                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} alt="img"/>
                                        </div>
                                        <div className=''>
                                            <span className=' align-text-bottom' >descri</span>
                                        </div>
                                    </div>

                                    <div className='col-2 fs-8 text-center text-white' style={{ width: '55px', height: '50px', marginTop:'13px'}} >
                                        <div className=" rounded-circle border border-1 border-white mb-0 p-0" style={{ width: '45px', height: '45px' }} >
                                            <img src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} alt="img"/>
                                        </div>
                                        <div className=''>
                                            <span className=' align-text-bottom' >descri</span>
                                        </div>
                                    </div>
                                    
                                </div>
                        </div>
                        <div className="col-7 p">
                                <div className="col text-center rounded-4 border border-2 border-white poppins-bold text-white fs-8"><span>Column B</span></div>
                                <div className="p-2">
                                <p className="text-white fs-8">1. it has two possible meanings: Acoloerful oufit or a showy outfit that reveals too much skin  <input type="text" style={{width:'34px', borderRadius:'50px',border:'0px',outline:'none', background:'#D0FF00'}}  className='mb-0'/></p>
                                <p className="text-white fs-8">1. it has two possible meanings: Acoloerful oufit or a showy outfit that reveals too much skin  <input type="text" style={{width:'34px', borderRadius:'50px',border:'0px',outline:'none', background:'#D0FF00'}}  className='mb-0'/></p>
                                <p className="text-white fs-8">1. it has two possible meanings: Acoloerful oufit or a showy outfit that reveals too much skin  <input type="text" style={{width:'34px', borderRadius:'50px',border:'0px',outline:'none', background:'#D0FF00'}}  className='mb-0'/></p>
                                <p className="text-white fs-8">1. it has two possible meanings: Acoloerful oufit or a showy outfit that reveals too much skin <input type="text" style={{width:'34px', borderRadius:'50px',border:'0px',outline:'none', background:'#D0FF00'}}  className='mb-0'/></p>
                                <p className="text-white fs-8">1. it has two possible meanings: Acoloerful oufit or a showy outfit that reveals too much skin <input type="text" style={{width:'34px', borderRadius:'50px',border:'0px',outline:'none', background:'#D0FF00'}}  className='mb-0'/></p>
                                </div>
                        </div>
                </div>
                </div>
                    
                     
                    <div className="position-relative" /*onClick={handleCheck}*/>
                            <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
                                <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                            </div>
                    </div>
                    <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/equis_btn1.svg')} style={{ width: '40px', height: '40px' }}></img>
            </div>
        </>)
        };
        export {Exercisefifteen}