import {EvaluationItem} from '../../admin/evaluation/interfaces'
import {ExercisesType} from '../../admin/exercises/interfaces'
import {FeedbackResponse, UseEvaluationCourseModule} from '../screen/store/main'

export async function getTrueQuizAnswers(evaluationItems: EvaluationItem[]): Promise<void> {

  const {evaluationResponses, setEvaluationResponses} = UseEvaluationCourseModule.getState()
  
  if(evaluationResponses.length !== 0) return

  evaluationItems.forEach((evaluation) => {
    let responses:FeedbackResponse[] = []
    const exercise = evaluation.exercise
    switch (exercise?.type) {
      case ExercisesType.SIMPLE_SELECTION:
        exercise.questions.map((question) => {
          const parsedAnswer = question.answer.map((ans) => JSON.parse(ans))
          const rightAnswer = parsedAnswer.find((x) => x.isTrue === true)
          responses = [...responses, {question: question.name, value: rightAnswer.title, isCorrect: true, feedback: ''}]
        })
        setEvaluationResponses({
          percentage: 100,
          exercise_title: exercise.name,
          responses: responses,
        })
        break

      case ExercisesType.SIMPLE_SELECTION_COMPLETION:
        
        exercise.questions_completion.map((question, index) => {
          const parsedAnswer = question.answer.map((ans) => JSON.parse(ans))
          const rightAnswer = parsedAnswer.find((x) => x.isTrue === true)
          responses = [...responses, {question: question.name ,value: rightAnswer.title, isCorrect: true, feedback: ''}]
        })
        setEvaluationResponses({
          percentage: 100,
          exercise_title: exercise.name,
          responses: responses,
        })
        break

      case ExercisesType.DRAG_DROP:
        setEvaluationResponses({
          percentage: 100,
          exercise_title: exercise.name ?? 'Titulo ejercicio',
          responses: [],
          isDragDropExercise: {
            sentences: [],
            responses: [''],
            percentage: 100,
            isPercentageMin: true,
          },
        })
        break
      case ExercisesType.TRUE_FALSE:
        const rightAnswer = exercise.true_or_false[0].answer.map((ans)=>JSON.parse(ans))
        rightAnswer.map(x=>{
            responses = [...responses, {question: x.title, value: x.answer, isCorrect: true, feedback: ""}]
          })
        setEvaluationResponses({
          percentage: 100,
          exercise_title: exercise.name,
          responses,
        })
        break
    }
  })
}
