import React from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { HighlightWordAdd, HighlightWordUpdate } from '../../services'
import { UseHighlightWordModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { CategoriesHighlightWord } from '../../../../../../../components/abroad/e-book/beyong-the-reading/ExerciseSeven/store/main'
import { TraditionalLevelEnum } from '../../../../../../abroad/road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'

export type FormFields = {
  questionText: string;
  instruction: string;
  isActive: boolean;
  categories: any[];
  level: TraditionalLevelEnum;
}

const entitySchema = Yup.object().shape({
  questionText: Yup.string().required('Este campo es requerido'),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const HighlightWordForm: React.FC<Props>  = (props:  Props) => {
  const {id} = useParams();
  const {t} = useTranslation();
  const queryClient = useQueryClient()
  const {itemSelected, mode,resetData} = UseHighlightWordModule();
  
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataAnswer, setDataAnswer] = React.useState<CategoriesHighlightWord>({
    title: '',
    order: 1,
    color: 'red'
  })

  const {values, errors, touched, handleChange, handleSubmit, setFieldValue} =
    useFormik<FormFields>({
      initialValues: {
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        categories: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.categories.map(x => JSON.parse(x)) ?? []: [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        questionText: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.questionText ?? '' : '',
        instruction: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.instruction ?? '' : ''
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await HighlightWordAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['highlight-word'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  HighlightWordUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['highlight-word'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const handleAddCategory = () => {
    if (values.categories.length >= 5) {
      return showSimpleAlert({message: 'No puedes agregar mas de 5 categorias', icon: 'error'})
    }
    setFieldValue('categories', [...values.categories, dataAnswer]);
    setDataAnswer({
      title: '',
      color: 'red',
      order: 1
    })
  }

  const deleteCategory = (order: number) => {
    setFieldValue('categories', values.categories.filter(x => x.order !== order));
  };
    
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='col-12 mb-3'>
            <label className='form-label fw-bold'>{'Cat No. 1 = {}'}</label>
            <label className='form-label ms-2 fw-bold'>{'Cat No. 2 = []'}</label>
            <label className='form-label ms-2 fw-bold'>{'Cat No. 3 = &&'}</label>
            <label className='form-label ms-2 fw-bold'>{'Cat No. 4 = %%'}</label>
            <label className='form-label ms-3 fw-bold'>{'Cat No. 5 = ##'}</label>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Categorias</label>
            <div className='mb-2 col-12'>
              <label className='form-label mb-2'>Name</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.title}
                onChange={(e) => setDataAnswer(prev => ({...prev, title: e.target.value}))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Order</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.order}
                onChange={(e)=> setDataAnswer(prev=>({...prev, order: parseInt(e.target.value)}))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Color</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.color}
                onChange={(e)=> setDataAnswer(prev=>({...prev, color: e.target.value}))}
              />
            </div>
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={handleAddCategory} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='mb-10 col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Pregunta</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.categories.sort((a,b) => a.order - b.order).map((x) => (
                  <tr>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.title}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {x.color}
                            </span>
                          </div>
                        </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteCategory(x.order)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='col-12'>
            <label className='form-label mb-3'>Parrafo</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'questionText'}
              value={values.questionText}
              onChange={handleChange}
            />
            {touched.questionText && errors.questionText && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.questionText}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {HighlightWordForm}
