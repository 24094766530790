import React from 'react';
import { UseBookTestModule } from './store/main';
import { useLocation } from 'react-router-dom';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { UseLessonOne } from '../../../abroad/learning-path/modules/lessons/services';
import { ContainerBookTest } from '../../components/container/test';

type Props = {};

const ContainerTestBook: React.FC<Props> = () => {
  const location = useLocation();
  const {lessonData, resetCurrentItems, setLessonData} = UseBookTestModule();
  
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const findLesson = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await UseLessonOne(id);
      setLessonData(response);
    } catch(e) {
      showSimpleAlert({message: 'Error al encontrar Leccion', icon: 'error'})
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    if (params) {
      findLesson(params.lesson);
    }
  }, [location]);

  React.useEffect(() => {

    window.addEventListener('beforeunload', resetCurrentItems);
    window.addEventListener('hashchange', resetCurrentItems);
    window.addEventListener('popstate', resetCurrentItems);
    return () => {
      window.removeEventListener('beforeunload', resetCurrentItems);
      window.removeEventListener('hashchange', resetCurrentItems);
      window.removeEventListener('popstate', resetCurrentItems);
    };
  }, [resetCurrentItems]);

  return(<>
  {isLoading ?
    <div className='d-flex justify-content-center align-items-center'>
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border spinner-border-lg align-middle'></span>
      </span>
    </div>
  : 
    lessonData && 
      <ContainerBookTest/>
  }
  </>)
};

export {ContainerTestBook};