import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { FindOneEvaluation } from '../../services';
import { UseEvaluationCourseModule } from '../../../../courses/screen/store/main';
import { UseModeCourseModule } from '../../../../courses/components/front-page/store/main';
import ContainerFather from '../../../../courses/components/container';
import useAuth from '../../../../../api/main';

type Props = {};

const ScreenEvaluationCourse: React.FC<Props> = () => {
    const {id} = useParams();
    const {
        resetEvaluationTest,
        lessonCourse,
        evaluationLesson,
        currentTaskId, 
        setEvaluationCourse,
        setLessonCourse
    } = UseEvaluationCourseModule();
    const {modeCourseChecked} = UseModeCourseModule();
    const {typeUser} = useAuth();
    const navigate = useNavigate();
    const findEvaluation = async () => {
        try {
            if (id) {
                if (typeUser === 'STUDENT' && !currentTaskId) {
                    return navigate('/')
                }
                const response = await FindOneEvaluation(id);
                if (response.lesson.length > 0) {
                    setLessonCourse(response?.lesson[0])
                }
                delete response.lesson;
                setEvaluationCourse(response)
            }
        } catch (e) {
            showSimpleAlert({message: 'Error al encontrar Evaluation', icon: 'error'});
            console.error(e);
        }
    };

    React.useEffect(() => {
        findEvaluation();
        modeCourseChecked();
        // resetEvaluationTest();
    }, [id]);

    return(<>
    {evaluationLesson && 
        <ContainerFather
            colorPrimary={evaluationLesson.colorPrimary} 
            colorSecondary={evaluationLesson.colorSecondary} 
            logoCourse={lessonCourse?.chapter?.path?.logo?.location??''} 
            nameTopic={evaluationLesson.name??'Michigan Master'}
            typeEvaluation={evaluationLesson.type}
            typeCourse={lessonCourse?.chapter.path.type}
        />
    }
    </>)
};

export {ScreenEvaluationCourse};