import React, { useEffect } from 'react';
import { MasterClassProfile } from '../../profile/ProfileCustom/MasterClass';
import useAuth from '../../../api/main';
import { StatsDashboardTeacher } from './stats/indext';
import { UseMyRoadmapModule } from '../../profile/components/my-road-step/store';
import { StudentDashboardMain } from './student';
import { TeacherDashboardMain } from './teacher/container';
import { UseModeCourseModule } from '../../courses/components/front-page/store/main';
import { TopicsEbookDrawer } from '../../../../_metronic/partials';


type Props = {

}
const CustomDashboardMain: React.FC<Props> = ({ }: Props) => {
 
  let { typeUser } = useAuth()
  const { settings, settingChecked } = UseMyRoadmapModule.getState();
  const {modeCourseChecked} = UseModeCourseModule();
  const fetchSettings = React.useCallback(async() => {
    await settingChecked();
  }, [settings]);
  useEffect(() => {
    if (typeUser === 'STUDENT') {
      fetchSettings();
    }
  }, []);

  return (
    <>

    {typeUser === 'STUDENT' && 
    <StudentDashboardMain />}      

    
    {typeUser === 'TEACHER' && 
    <TeacherDashboardMain />}    

    {typeUser === 'ADMIN' && <div className={`card`}>
 
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_4_tab_1'>
            {/* begin::Table container */}
            <StatsDashboardTeacher />
            {/* end::Table */}
          </div>
 
 
        </div>
      </div>
      {/* end::Body */}
    </div>}

      
     
    </>
  );
}
export { CustomDashboardMain }