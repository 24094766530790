import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {AnswerSelectionCompletion} from '../../interfaces'
import { SelectionQuestionCompletionAdd, SelectionQuestionCompletionUpdate } from '../../services'
import { UseSelectionQuestionCompletionModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { BooleanCell } from '../../../../../../../components/common/BooleanCell'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'
import { AnswerSimpleSelection } from '../../../simple-selection-questions/interfaces'

export type FormFields = {
  value: number;
  order: number;
  name: string
  isActive: boolean
  description?:string
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
};

const SelectionQuestionCompletionForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {id} = useParams();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseSelectionQuestionCompletionModule();
  const [dataAnswer, setDataAnswer] = useState<AnswerSelectionCompletion[]>([]);
  const [formAnswer, setFormAnswer] = React.useState<AnswerSimpleSelection>({
    title: '',
    order: 1,
    isTrue: false
  });
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        value: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.value ?? 0 : 0,
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        order: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.order ?? 1 : 1
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            if (dataAnswer.length < 1) {
              showSimpleAlert({message: 'No tienes ninguna respuesta en este ejercicio', icon: 'error'})
              return;
            }
            if (!dataAnswer.some(x=>x.isTrue)) {
              showSimpleAlert({message: 'No puedes subir una pregunta sin alguna opcion verdadera', icon: 'error'})
              return;
            }
            setLoading(true);
            await SelectionQuestionCompletionAdd({
              ...formData,
              exercises: id??'',
              answer: dataAnswer
            })
            queryClient.invalidateQueries({ queryKey: ['simple-question-completion'] })
            resetData();
            setLoading(false)

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              if (dataAnswer.length < 1) {
                showSimpleAlert({message: 'No tienes ninguna respuesta en este ejercicio', icon: 'error'})
                return;
              }
              if (!dataAnswer.some(x=>x.isTrue)) {
                showSimpleAlert({message: 'No puedes subir una pregunta sin alguna opcion verdadera', icon: 'error'})
                return;
              }
              setLoading(true);
              await  SelectionQuestionCompletionUpdate({
                ...formData,
                exercises: id??'',
                answer: dataAnswer
              })
              queryClient.invalidateQueries({ queryKey: ['simple-question-completion']})
              resetData();
              setLoading(false)
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  React.useEffect(() => {
    if (itemSelected && itemSelected.answer) {
      setDataAnswer(itemSelected.answer.map((x) => JSON.parse(x)));
    }
  }, [itemSelected]);

  const handleSaveAnswer = () => {
    const hasTrue = dataAnswer.some(x => x.isTrue);
    let titleArray: string[] = [];
    if (formAnswer.title) {
      titleArray = formAnswer.title.split('/')
    }
    if (hasTrue && formAnswer.isTrue) {
      showSimpleAlert({message: 'No puedes tener mas de una respuesta verdadera', icon: 'error'})
    } else {
      setDataAnswer(prev => [ ...prev, {...formAnswer, title: titleArray} ]);
      setFormAnswer({title: '', order: 1, isTrue: false});
    }
  };

  const deleteAnswer = (order: number) => {
    setDataAnswer(prev => prev.filter(x => x.order !== order));
  };

  const filterStringCut = (paragraph: string): string => {
    let wordsToRemove: string[] = dataAnswer.find(x => x.isTrue)?.title ?? [];
  
    const normalizeText = (text: string): string => {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };
  
    const cleanedParagraph = normalizeText(paragraph);
  
    let normalizedWordsToRemove = wordsToRemove.map(word => normalizeText(word.trim()));
    let regexPattern = new RegExp(normalizedWordsToRemove.map(word => `\\b${word.replace(/'/g, "\\s*'")}\\b`).join('|'), 'gi');
  
    const total = cleanedParagraph.replace(regexPattern, match => "_".repeat(match.length));
  
    return total;
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Pregunta</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label'>Vista de la pregunta: </label>
            <label className='d-flex justify-content-center form-control-lg form-control-solid'>{filterStringCut(values.name)}</label>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Order</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>value</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'value'}
              value={values.value}
              onChange={handleChange}
            />
            {touched.value && errors.value && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.value}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-2 col-12 row'>
            <label className='form-label mb-3 fw-bold'>Answers</label>
            <div className='mb-2 col-6'>
              <label className='form-label mb-3'>is true</label>
              <div className="form-check form-switch">
                <input
                  checked={formAnswer.isTrue}
                  onChange={(e) => setFormAnswer(prev =>({...prev, isTrue: e.target.checked}))}
                  className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              </div>
            </div>
            <div className='mb-2 col-6'>
              <label className='mb-2 form-label'>Order</label>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={formAnswer.order}
                onChange={(e)=> setFormAnswer(prev=>({...prev, order: parseInt(e.target.value)}))}
              />
            </div>
            <div className='mb-2 col-10'>
              <label className='form-label mb-2'>Title</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={formAnswer.title}
                onChange={(e) => setFormAnswer(prev => ({...prev, title: e.target.value}))}
              />
            </div>
            
            <div className='col-2 d-flex align-items-center justify-content-center mt-6'>
              <button type='button' onClick={handleSaveAnswer} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>  

          <div className='mb-10 col-12'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Title</th>
                  <th>Is True</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataAnswer.sort((a,b) => a.order - b.order).map((x) => (
                  <tr>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.title.join('/')}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {x.order}
                            </span>
                          </div>
                        </div>
                    </td>
                    <td>
                      <span>
                        <BooleanCell value={x.isTrue} />
                      </span>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteAnswer(x.order)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Feedback</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {SelectionQuestionCompletionForm}
