import React, { useState } from 'react';
import { useParams } from 'react-router';
import { UseEvaluationCourseModule } from '../store/main';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { UseModeCourseModule } from '../../components/front-page/store/main';
import ContainerFather from '../../components/container';
import { FindTaskMeByLesson } from '../services';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';

type Props = {};

const ContainerCourse: React.FC<Props> = () => {
    const {id} = useParams();
    const {
      setEvaluationCourse,
      evaluationLesson,
      lessonCourse,
      setCurrentTask,
      setLessonCourse,
    } = UseEvaluationCourseModule();
    const {modeCourseChecked} = UseModeCourseModule();
    const {settings} = UseMyRoadmapModule.getState();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const findEvaluation = async () => {
      try {
        
        if (id) {
          // const response = await UseLessonOne(id??'xx');
          // setEvaluation(response);
          const response = await FindTaskMeByLesson(settings?.id??'xx',id);
          setEvaluationCourse(response.task.lesson.evaluation);
          delete response.task.lesson.evaluation;
          setLessonCourse(response.task.lesson);
          setCurrentTask(response.task.id);  
        }
        
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar evaluación', icon: 'error' });
        console.error('err', e)
      } finally {
        setIsLoading(false)
      }
    };

    React.useEffect(() => {
      setIsLoading(true)
      modeCourseChecked();
      findEvaluation();
      // resetEvaluationTest();
    }, [id]);

    return(<>
      {
        isLoading ?
        <>
          <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
            <span className='spinner-border spinner-border-lg align-middle' style={{color: evaluationLesson?.colorPrimary}}> </span>
          </span>  
        </> : evaluationLesson && lessonCourse &&
        <ContainerFather
          colorPrimary={evaluationLesson.colorPrimary} 
          colorSecondary={evaluationLesson.colorSecondary} 
          logoCourse={lessonCourse.chapter?.path?.logo?.location} 
          nameTopic={lessonCourse.name??'Michigan Master'}
          typeEvaluation={evaluationLesson.type}
          typeCourse={lessonCourse.chapter.path.type}
        />
      }
    </>)
};

export {ContainerCourse};