export interface DataPaginateFileFolderResponse {
    status:  string;
    message: string;
    data:    DataFileFolder;
}

export interface DataAllFileFolderResponse {
    status:  string;
    message: string;
    data:    FileFolder[];
}

export interface DataFileFolder {
    id?: string;
    description:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface FileFolder  {
    id?: string;
    description?:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: FileFolder[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum TypeContentCourse {
    // PDF = 'PDF',
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
    HTML = 'HTML',
    IMAGE = 'IMAGE',
    // EMBED = 'EMBED'
}

export enum ToTypeContentCourse {
    LESSON = 'LESSON',
    TOPIC = 'TOPIC',
    CHAPTER = 'CHAPTER',
    EXERCISE = 'EXERCISE'
}
export interface FileContentCourseType {
    type: TypeContentCourse;
    to: ToTypeContentCourse;
    isActive: boolean;
    applyCover: boolean;
    root: string;
    cover?: string;
    content?: string;
    iFrame?: string;
}