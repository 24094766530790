import {create} from 'zustand';
import { Answer, QuestionBasicVocabularyCompletion, quiz,QuestionAndAnswer, questions, AnswerDragDrop } from '../interfaces';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';
export interface WordItem {
    id: string;
    text: string;
}
interface Question {
    sentences:string[]
    words:WordItem[]
}
interface ResponseWordsInterface {
    allWords:AnswerDragDrop
    hasChecked:boolean
    percentage:number
}

interface ParagraphDragDropInterface {
    status:StatusQuiz
    title:string;
    instructions:string;
    percentage:number;
    answerStudent?:AnswerDragDrop
    allWords: AnswerDragDrop
    hasChecked:boolean
    sentences:string[]
    words:WordItem[]
    sentencesConstructed:string
    total:number
    exerciseId?: string;
    setSentences:(value:Question)=>void;
    setSentencesConstructed:(value:string)=>void;
    setStatus:(StatusQuiz)=>void
    setStep:(number)=>void;
    startQuiz:()=>void;
    setWords:(response:ResponseWordsInterface)=>void;
    setQuestionsParagraphDrag:(
        exerciseId: string,
        words:WordItem[], 
        sentences: string[], 
        title: string, 
        instructions: string)=>void;    
    resetData:()=>void;
    setAnswerStudent:(value: AnswerDragDrop)=>void;
}

export const UseParagraphDragDropModule = create<ParagraphDragDropInterface>( (set) => ({
    status:'TITLE',
    title:"",
    instructions:"",
    questions:[],
    sentencesConstructed:'',
    allWords:{
        filledGaps: {},
        usedWords: {}
    },
    hasChecked:true,
    sentences:[
        //  "Leo: Es un hecho establecido hace {demasiado} tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su {diseño}. Juan : {Rios} este es un apellido.",
        // "Luis: {Rel Madrid} es el equipo mas grande del mundo.",
        // "Leo: {Lionel Pessi} es la {cabra} Lionel Pessi."
    ],
    words:[
        //  { id: 'word_1', text: 'diseño' },
        //  { id: 'word_2', text: 'demasiado' },
        //  { id: 'word_3', text: 'Rel Madrid' },
        //  { id: 'word_4', text: 'very' },
        //  { id: "word_5", text:"Lionel Pessi"},
        //  { id: "word_7", text:"Lionel Pessi"},
        //  { id: "word_10", text:"Lionel Pessi"},
        //  { id: "word_6", text:"cabra"},
        //  { id: "word_12", text:"Rios"}
    ],
    step:0,
    percentage:0,
    list:[],
    total:0,
    setWords:({...value}:ResponseWordsInterface)=>{
        set({
            allWords: value.allWords,
            hasChecked:value.hasChecked,
            percentage:value.percentage
        })
    },
    setSentencesConstructed:(value:string)=>{
        set({
            sentencesConstructed:value
        })
    },
    setSentences:(value:Question)=>{
        set({
            words:value.words,
            sentences:value.sentences
        })
    },
    setAnswer:(value:Answer)=>{
        let status:StatusQuiz ='ANSWER';
        if(value.isTrue){
            set({ 
                status:status
            })
        }
        if(!value.isTrue){
            set({
                status:status
            })
        }
    },
    setStep:(value:number)=>{
        let status:StatusQuiz ='THROW_ERROR';
        set({
            status:status
        })
    },
    startQuiz(){
        set({
            status:'ANSWER'
        })
    },
    setStatus:(value:StatusQuiz)=>{
        if(value==='ANSWER'){
            set({
                status:'ANSWER'
            })
        }
        if(value==='THROW_ERROR'){
            set({
                status:'THROW_ERROR'
            })
        }
        if(value==='RESUME'){
            set({
                status:'RESUME'
            })
        }
    },
    setQuestionsParagraphDrag:(exerciseId, words, sentences, title, instruction) =>{
        set({ words, sentences, title, instructions: instruction, exerciseId });
    },
    resetData:()=>{
        set({
            sentences: [],
            words: [],
            allWords:{
                filledGaps: {},
                usedWords: {}
            },
        })
    },
    setAnswerStudent:(value)=>{
        set({answerStudent: value});
    }
}));