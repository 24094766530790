import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UseParagraphDragDropModule } from './store';
import WordBoxReadingDragAndDrop from './WordBoxReadingDragDrop';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';


interface DraggableWordsListProps {
  userMode?: ModeTypeExercise;
  colorSecondary?: string;
  titleContent?: string;
  descriptionContent?: string;
}

export const DragDropBeyondReading: React.FC<DraggableWordsListProps> = ({
  userMode,
  colorSecondary,
  titleContent,
  descriptionContent
 }) => {

  let {
    sentences,
    words
  } = UseParagraphDragDropModule();

  return (
    <DndProvider backend={HTML5Backend}>
      <WordBoxReadingDragAndDrop
        userMode={userMode}
        colorSecondary={colorSecondary??'blue'}
        titleContent={titleContent??'Lionel Pessi'}
        descriptionContent={descriptionContent??'El genio mundial'}
        sentence={sentences}
        words={words}
      />
    </DndProvider>
  );
};