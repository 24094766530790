import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {StudentStatusManager, SuspenseStudent} from '../../interfaces' 
import { StudentStatusManagerAdd, StudentStatusManagerUpdate } from '../../services'
import { UseStudentStatusManagerModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { UseSuspenseStudentPaginated } from '../../../../user/services/student/StudentSettingsService'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'

export type StudentStatusManagerFormFields = {
  isActive: boolean
  observation?:string;
  suspense: string;
}

const entitySchema = Yup.object().shape({
  observation: Yup.string().required('Observation is required').min(2, 'Observation must have at least 2 character'),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const StudentStatusManagerForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  const {id} = useParams();
  const {t} = useTranslation();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseStudentStatusManagerModule();
  const [suspensesStudent, setSuspenseStudent] = useState<SuspenseStudent[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<StudentStatusManagerFormFields>({
      initialValues: {
        suspense: (mode==='EDIT' || mode==='VIEW')? itemSelected?.suspense?.id ?? '' : '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        observation:(mode==='EDIT' || mode==='VIEW')? itemSelected?.observation??'':'',
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          Swal.fire({
            title: `<h4 class='champ-bold'>Are you sure?</h5>`,
            html: `<span class='poppins-light'>You won't be able to revert this!</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (res) => {
            if (res.isConfirmed) {
              try{
                setLoading(true);
                await StudentStatusManagerAdd({...formData})
                resetData();
                setLoading(false)
                queryClient.invalidateQueries({ queryKey: ['student-status-manager', id] })
    
                }catch(e){
                  setLoading(false);
                }
            } else {
              resetData();
            }
          })

          }
          else{
            try{
              setLoading(true);
              await  StudentStatusManagerUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['student-status-manager', id] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  const fetchSuspenseStudent = React.useCallback(async() => {
    // No se pueden tener mas de dos suspensiones en una misma fecha pero
    // igualmente busco un array por si llegase a devolverme un array de suspensiones.
    const response = await UseSuspenseStudentPaginated(id??'', moment().toISOString());
    if (response.result.length > 0) {
      setSuspenseStudent(response.result);
      setFieldValue('suspense', response.result[0]?.id);
    }
  }, [id]);

  React.useEffect(() => {
    if (mode === 'ADD') {
      fetchSuspenseStudent();
    } else if (itemSelected) {
      setSuspenseStudent([itemSelected.suspense]);
    }
  }, [id, mode, itemSelected]);
  
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div> */}
           <div className='mb-10 col-12'>
            <label className='form-label mb-3 champ-bold'>{mode === 'ADD' ? 'Suspensión activa: ' : 'Suspencion que se inactivo: '}</label>
            {suspensesStudent.map((x) => (
              <div key={x.id} className='row'>
                <div className='col-4 d-flex flex-column'> 
                  <h5 className='poppins-bold'>Tipo de suspensión</h5>
                  <p className='poppins-light'>{t(x.type)}</p>
                </div>
                <div className='col-4 d-flex flex-column'>
                  <h5 className='poppins-bold'>Desde</h5>
                  <p className='poppins-light'>{x.start}</p>
                </div>
                <div className='col-4 d-flex flex-column'>
                  <h5 className='poppins-bold'>Hasta</h5>
                  <p className='poppins-light'>{x.end}</p>
                </div>
              </div>
            ))}
          </div>

          {/*<div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {touched.code && errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Observación</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'observation'}
              value={values.observation}
              onChange={handleChange}
            />
            {touched.observation && errors.observation && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.observation}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {StudentStatusManagerForm}
