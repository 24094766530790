import React, { useEffect } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { TrailFreezingModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useTrailFreezingState } from '../../hooks/useTrailFreezingRepo'
import { UseTrailFreezingModule } from '../../store/main'
import { DataTrailFreezing, FreezingStatus, TrailFreezing } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { TrailFreezingAdd, TrailFreezingDelete, UpdateActiveTrailFreezing } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import useAuth from '../../../../../api/main'
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store'
import { CardHeaderModule } from '../../../../../components/common/widgets/statistics/cardHeaderModule'
import CustomDatePicker from '../../../../../components/common/DayPicker/DayPickerInputWithImage'
import moment from 'moment'
import { UseTrailClassPaginated } from '../../../../abroad/trail/services'
import { toDate } from 'date-fns'
import { AssistantClassModal } from '../modal/assistantClassModal'
import { useThemeMode } from '../../../../../../_metronic/partials';
import './index.css'

type PaginatorSetter ={
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const TrailFreezingTableList: React.FC<Props> = ({ }) => {

  const {mode} = useThemeMode();
  const queryClient = useQueryClient()
  const { 
      paginator, 
      modal,
      modalForm,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit, 
      setIsActive, 
      setSelectedToEdit, 
      setSelectedToView,
      setModal,
      setWhere,
      setToAdd,
      setRoot
  } = UseTrailFreezingModule()

  const {typeUser, profile, fullName} = useAuth();
  const {settings, settingChecked} = UseMyRoadmapModule.getState()

  const [before, setBefore] = React.useState<Date>(moment().toDate());
  const [after, setAfter] = React.useState<Date>(moment().toDate());
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const { data, isLoading } = useTrailFreezingState();

  const [numberTotalDaysFreezing, setNumberTotalDaysFreezing] = React.useState<number>(0);
  const [dataTrailClass, setDataTrailClass] = React.useState<any[]>([]); 
  const [numberAppointmentAssistant, setNumberAppointmentAssistant] = React.useState<number>(0);
  const [numberDaysFreezing, setNumberDaysFreezing] = React.useState<number>(0);

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage:1,
    items:0,
    total:0,
    totalPage:0
});

  const activeItem = async (id:string, value: boolean)=>{
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          await UpdateActiveTrailFreezing(id,value);
          queryClient.invalidateQueries({ queryKey: ['trail-freezing'] });
         }catch(e){
            
         }
      }
    })
  }
  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  const handleBeforeSelectDate = (date: Date) => {
    if (moment(date).isAfter(after)) {
      showSimpleAlert({message:'La primera fecha es mayor a la segunda fecha',icon:'error'})
    } else {
      setBefore(date);
    }
  };

  const handleAfterSelectDate = (date: Date) => {
    if (moment(date).isBefore(before)) {
      showSimpleAlert({message:'La segunda fecha es mayor a la primera fecha',icon:'error'})
    } else {
      setAfter(date);
    }
  };

  const handleSubmitFreezing = async () => {
    //@ts-ignore
    Swal.fire({
      title: '¿Estas Seguro?',
      text: "Estos cambios son irreversibles!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          if (before && after && settings) {
            if ((numberDaysFreezing + numberTotalDaysFreezing) 
              > settings.daysFreezingAvailable) {
                showSimpleAlert({message: 'Los dias a congelar exceden los dias maximos permitidos', icon: 'error'});
                return;
            }
            setLoading(true);
            await TrailFreezingAdd({
              start: moment(before).format('YYYY-MM-DD'),
              end: moment(after).format('YYYY-MM-DD'),
              status: FreezingStatus.RETROACTIVE,
              student: settings.id,
              isActive: true,
              days: numberDaysFreezing,
            });
            const newFinishDateStudent = moment(settings.finish).add(numberDaysFreezing, 'days').format('YYYY-MM-DD');
            settings.finish = newFinishDateStudent;
            setLoading(false);
          }
          queryClient.invalidateQueries({ queryKey: ['trail-freezing'] });
          showSimpleAlert({message:'Congelamiento Procesado!',icon:'success'})
         }catch(e){
          setLoading(false)
          showSimpleAlert({message: `${e}`, icon: "error"})
         }
      }
    })
  };

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['trail-freezing'] });
  }, [currentPage,limit]);

  useEffect(() => {
    if (data && data?.result) {
      let totalDays = 0;
      data?.result.map(x => {
        if (x.isActive) {
          totalDays += x?.days??0
        }
      })
      setNumberTotalDaysFreezing(totalDays);
    };    
  }, [data]);

  useEffect(() => {
    if (typeUser === 'STUDENT' && settings) {
      setRoot(settings.id);
    };
  }, [settings]);

  useEffect(() => {
    if (after && before && settings) {
      const findTrailClass = async () => {
        try {
          const responseTrail = await UseTrailClassPaginated(settings?.id, after, before);
          setDataTrailClass(responseTrail.result?.filter((x) => x.assistantStudent === true));
          setNumberAppointmentAssistant(responseTrail.result?.filter((x) => x.assistantStudent === true)?.length??0)
        } catch (e) {

        }
      };
      findTrailClass();
      setNumberDaysFreezing(moment(after).set({ hour: 12 }).diff(moment(before).set({ hour: 12 }), 'days') + 1);
    }
  }, [after, before, settings]);

  useEffect(() => {
    if (settings) {
      if ((numberDaysFreezing + numberTotalDaysFreezing) 
        > settings.daysFreezingAvailable) {
          showSimpleAlert({message: 'Los dias a congelar exceden los dias maximos permitidos', icon: 'warning'});
      }
    }
  }, [numberDaysFreezing, settings, numberTotalDaysFreezing]);

  return (<>
    <CardHeaderModule
        title={'Congelamientos'} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'blue/back_blue':'dark-modules/back_aquamarine'}.svg`}
        svgIcon={`media/svg/michigan-icons/header/${mode==='light'?'blue/freezing_blue':'dark-modules/freezing_aquamarine'}.svg`}
        background={'#1bebd4'}
        colorTitle={'#2d30e1'}
    />

    {typeUser === 'STUDENT' && <>
      <div className='card mt-8 content-student'
        style={{ border:mode==='light'?'2px solid #2d30e1':'2px solid #FFFFFF' }}
      >
        <div className='row p-6' style={{ color:mode==='light'?'#2d30e1':'#FFFFFF' }}>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Nombre:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3'
              >{profile?.firstName && profile.lastName ? `${profile?.firstName} ${profile?.lastName}` : fullName}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Dias de programa:</span>
            </div>
            <div className='col-md-6'>
              <span className='poppins-light fw-bold fs-3'
              >{moment().diff(settings?.start, 'days')}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Inicio del programa:</span>
            </div>
            <div className='col-md-6'>
              <span className='poppins-light fw-bold fs-3'
              >{moment(settings?.start).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5' style={{ whiteSpace: 'nowrap' }}>
                Dias {' '}
                <span className='d-md-none d-block'></span>
                Congelados:
              </span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3' >{numberTotalDaysFreezing}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Fin del programa:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bold fs-3'
              >{moment(settings?.finish).format('DD/MM/YYYY')}</span>
            </div>
          </div>
          <div className='col-md-6 mt-2 d-flex'>
            <div className='col-6'>
              <span className='poppins-bold fs-3 ms-5'
              >Maximo a congelar:</span>
            </div>
            <div className='col-6'>
              <span className='poppins-light fw-bolder fs-3'
              >{settings?.daysFreezingAvailable??0}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row'>
          <div className='mt-8 mb-lg-0' style={{ flex: '0 0 49%' }}>
            <div className='d-flex card' style={{ borderRadius:30,border:mode==='dark'?'1px solid #E6E6FA':'', backgroundColor:mode==='light'?'#E6E6FA':'' }}>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='poppins-bold ps-12 py-4 d-flex align-items-center fs-3'
                  style={{ color:mode==='light'?'#2d30e1':'#FFFFFF' }}
                >
                  {`Desde: ${moment(before).format('DD-MM-YYYY')}`}
                </span>
                <div className='pe-5'>
                  <CustomDatePicker
                    symbolWidth='30px'
                    onSelectDate={handleBeforeSelectDate}
                    calendarIconSrc="/media/svg/michigan-icons/elements/calendar_blue.svg"
                    buttonStyle={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
            <div className='d-flex card mt-3' style={{ borderRadius:30,border:mode==='dark'?'1px solid #E6E6FA':'', backgroundColor:mode==='light'?'#E6E6FA':'' }}>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='poppins-bold ps-12 py-4 d-flex align-items-center fs-3'
                  style={{ color: mode==='light'?'#2d30e1':'#FFFFFF' }}
                >
                  {`Hasta: ${moment(after).format('DD-MM-YYYY')}`}
                </span>
                <div className='pe-5'>
                  <CustomDatePicker
                    symbolWidth='30px'
                    onSelectDate={handleAfterSelectDate}
                    calendarIconSrc="/media/svg/michigan-icons/elements/calendar_blue.svg"
                    buttonStyle={{ marginLeft: '5px' }} />
                </div>
              </div>
            </div>
            <div className='mt-3 d-flex'>
              {dataTrailClass.length > 0 &&
                <button className={`btn w-50 py-3 champ-bold fs-3`} 
                  style={{ backgroundColor:mode==='light'?'red':'', 
                          borderRadius: 30, 
                          color:mode==='light'?'white':'red',
                          border:mode==='dark'?'1px solid red':''
                        }} 
                  onClick={() => setModal(true)}>
                  Asististe a {numberAppointmentAssistant} {numberAppointmentAssistant === 1 ? 'clase' : 'clases'}
                </button>
              }
              <button 
                disabled={
                  numberAppointmentAssistant > 0 ? true : false || 
                  settings && settings.daysFreezingAvailable < (numberDaysFreezing + numberTotalDaysFreezing) ? true : false ||
                  numberAppointmentAssistant > numberTotalDaysFreezing || 
                  numberDaysFreezing < 7
                } 
                className={`btn ${dataTrailClass.length > 0 ? 'w-50' : 'w-100'} py-3 champ-bold fs-2`} 
                style={{ backgroundColor:mode==='light'?'#1bebd4':'', borderRadius: 30, color:mode==='light'?'#2d30e1':'#1bebd4', border:mode==='dark'?'1px solid #1bebd4':'' }} 
                onClick={() => handleSubmitFreezing()}
              >
                {!loading && `Generar ${numberDaysFreezing}${' día' + (numberDaysFreezing === 1 ? '' : 's')}`}
                {loading && 
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>}
              </button>
            </div>
          </div>

          <div style={{ flex: '0 0 2%' }}></div>

          <div className='card mt-8 mb-lg-0' style={{ flex: '0 0 49%', backgroundColor:mode==='light'?'#E6E6FA':'', borderRadius: '50px', border:mode==='dark'?'1px solid #E6E6FA':''  }}>
            <div className='card-body pt-3'>
              <div className='table-responsive'>
                <span className='fs-4 poppins-bold d-flex justify-content-center align-items-center' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA' }}>Historial de Congelamientos</span>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold poppins-bold fs-5' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA', borderBottom: `1px solid ${mode==='light'?'#2d30e1':'#E6E6FA'}` }}>
                      <th className='min-w-120px ps-0' style={{ paddingBottom: '5px' }}>Desde</th>
                      <th className='min-w-120px ps-0' style={{ paddingBottom: '5px' }}>Hasta</th>
                      <th className='min-w-120px ps-0' style={{ paddingBottom: '5px' }}>Dias</th>
                      <th className='min-w-120px ps-0' style={{ paddingBottom: '5px' }}>Facilitador</th>
                    </tr>
                  </thead>
                  <tbody className='mt-2'>
                    {data && data?.result?.map((x: DataTrailFreezing) => (
                      <tr key={x.id} className='poppins-light' style={{ color:mode==='light'?'#2d30e1':'#E6E6FA' }}>
                        <td className='p-0'>
                          <span className='d-block fs-5'>
                            {moment(x.start).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td className='p-0'>
                          <span className='d-block fs-5'>
                            {moment(x.end).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td className='p-0'>
                          <span className='d-block fs-5'>
                            {x.days}
                          </span>
                        </td>
                        <td className='p-0'>
                          <span className=' d-block fs-3'>
                            {x.userUpdate ? x.userUpdate.fullName : "Empty"}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <GenericPaginator
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit} />
              </div>
            </div>
          </div>
        </div>
    </>}

    {typeUser !== 'STUDENT' && <div className={`card mt-3`} style={{ borderRadius:30 }}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>trail-freezing</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de trail-freezing </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >

          <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div>
          {/* <button
            onClick={() => setToAdd()}
            className='btn btn-sm btn-light-primary mr-8'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
          </button> */}
          <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
             <BooleanCell value={isActive} />
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
          <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-4'>
                  <thead>
                      <tr className='fw-bold bg-light'>
                          <th className=' champ-light ps-4 min-w-200px rounded-start'>Estudiante</th>
                          <th className=' champ-light min-w-100px'>Desde</th>
                          <th className=' champ-light min-w-100px'>Hasta</th>
                          <th className=' champ-light min-w-100px text-center'>Dias Congelados</th>
                          <th className=' champ-light min-w-100px text-center rounded-end'>Actions</th>
                      </tr>
                  </thead>
                  <tbody>
                    {data?.result?.map((x: DataTrailFreezing) => (
                      <tr key={x.id}>
                          <td>
                              <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                      <a href='#' className='text-dark champ-light fw-bold bold text-gray-900 text-hover-primary mb-1 fs-6'>
                                          {x.student.user.fullName}
                                      </a>

                                      <div className='rating'>
                                          <div className='rating-label me-2 checked text-muted'>
                                              {x.student.codeContract}
                                          </div>
                                          <div className='rating-label me-2 '>
                                              <i className="bi bi-person-circle"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </td>
                          <td>
                              <span className='text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                                  {moment(x?.start).format('DD/MM/YYYY')}
                              </span>
                             
                          </td>
                          <td>
                              <span className='text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                                  {moment(x?.end).format('DD/MM/YYYY')}
                              </span>
                          </td>
                          <td>
                              <a href='#' className='text-dark text-center champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                                  {x.days}
                              </a>
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <a
                                href='#'
                                onClick={()=>setSelectedToView(x)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i style={{ color: 'grey', fontSize: 17 }} className="fas fa-eye"></i>
                              </a>
                              <a
                                href='#'
                                onClick={()=>activeItem(x?.id??'xxx', x?.isActive ? false : true)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <i style={{ color: x?.isActive ? 'red' : 'green', fontSize: 17 }} className={x?.isActive ? 'fa-solid fa-ban' : 'fa-solid fa-circle-check'}></i>
                              </a>
                            </div>
                          </td>
                      </tr>
                    ))}
                  </tbody>
              </table>
              <GenericPaginator 
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
          </div>
      </div>
                    
   
    </div>}
    <TrailFreezingModal show={modalForm} handleClose={() => alert()} />
    {dataTrailClass && 
      <AssistantClassModal dataTrailClass={dataTrailClass} show={modal} handleClose={() => alert()} />
    }
  </>
  )
}

export { TrailFreezingTableList }
