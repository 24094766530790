import React, { useState } from 'react';

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

import '../ExerciseEleven.css'
import { auto } from '@popperjs/core';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { ArrayQuestion, UseCoordinatesEleven } from '../main/main';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddCoordinatesResponse } from '../services';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
export interface AnswerCoordinates {
  columnIndex: number
  idWord: string
  isValid?: boolean
  rowIndex: number
}
type Props = {
  responses?: AnswerCoordinates[]
  userMode?: ModeTypeExercise
  question: ArrayQuestion
  instruction: string
}
const CoordinatesItem: React.FC<Props> = ({ responses, userMode, question, instruction }: Props) => {

  const { checked, exerciseId, setChecked } = UseCoordinatesEleven();
  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    currentItemBook,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
  } = UseBookStudentModule();
  const {modeViewAnswer} = UseModeCourseModule();
  
  const [tableEnabled, setTableEnabled] = useState(true);
  const [selectedCellId, setSelectedCellId] = useState<AnswerCoordinates[]>([]);
  const [accuracy, setAccuracy] = React.useState<number>(0);

  const resetExercise = () => {
    setTableEnabled(true);
    setAccuracy(0);
    setSelectedCellId([]);
    setChecked(false);
  }

  const handleCellClick = (rowIndex: number, columnIndex: number, idWord: string) => {
    setSelectedCellId(prev => {
      const indexToUpdate = prev.findIndex(cell => cell.columnIndex === columnIndex);
      if (indexToUpdate !== -1) {
        return prev.map((cell, index) =>
          index === indexToUpdate ? { ...cell, rowIndex, idWord } : cell
        );
      } else {
        return [...prev, { rowIndex, columnIndex, idWord }];
      }
    });
  };

  const handleCheck = () => {
    const unselectedCellsExist = question.column.some((x, i) =>
      !selectedCellId.some(cell => cell.columnIndex === i)
    );

    if (unselectedCellsExist) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '¡Por favor selecciona una celda en cada columna antes de verificar!',
      });
      return;
    } else {
      // Calcular el número de celdas correctas
      const correctCellsCount = selectedCellId.reduce((count, cell) => {
        // Obtener el valor correcto para esta fila
        const correctValue = question.row.find(row => row.idWord === cell.idWord)?.value;
        // Verificar si el valor seleccionado es correcto
        const isCorrect = correctValue === question.column[cell.columnIndex]?.value;
        // Incrementar el contador si la celda es correcta
        return isCorrect ? count + 1 : count;
      }, 0);

      const totalCellsCount = selectedCellId.length; // Calcular el porcentaje de celdas correctas
      const accuracyPercentage = totalCellsCount > 0 ? (correctCellsCount / totalCellsCount) * 100 : 0;
      setAccuracy(accuracyPercentage);
      Swal.fire({
        title: `Porcentaje de aciertos: ${accuracyPercentage.toFixed(1)}%`,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#3f8bff',
        customClass: {
          title: 'poppins-light'
        },
        showClass: {
          popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
                `
        },
        hideClass: {
          popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
                `
        }
      });
      setTableEnabled(false);
      setChecked(true)
    }

    const updatedSelectedCells = selectedCellId.map(cell => { // Actualizar el estado de las celdas seleccionadas con el resultado de la validación
      const { rowIndex, columnIndex, idWord } = cell;
      const correctValue = question.row.find(row => row.idWord === idWord)?.value;
      const isCorrect = correctValue && correctValue === question.column[columnIndex].value ? true : false;
      return {
        ...cell,
        isValid: isCorrect
      };
    });

    setSelectedCellId(updatedSelectedCells);
    setChecked(true);
  };

  const handleSubmit = async () => {
    // ResetStepByExercises();
    if (lessonData && lessonData.evaluation &&
    lessonData.evaluation.evaluation_item){
      addCompletedExerciseId(exerciseId ?? "")
      if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        const response = await AddCoordinatesResponse({
          exercises: exerciseId,
          task: currentTaskId ?? '',
          percentage: accuracy ? accuracy : 0,
          isActive: true,
          answers: selectedCellId,
        });
        onChangeTaskByAnswer(response, 'coordinates');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
      }
    }
  }
  React.useEffect(() => {
    if (responses) {
      setSelectedCellId(responses);
    }
  }, [responses]);

  React.useEffect(() => {
    if (modeViewAnswer) {
      question.row.forEach((row, iRow) => {
        const value = question.column.findIndex(col => col.value === row.value);
          if (value !== -1) {
            handleCellClick(iRow, value, row.idWord)
          }         
      })
    };
  }, [modeViewAnswer]);

  const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '525px',
    minHeight: '525',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 20px 38px 20px'
  };

  return (
    <>
      <div className="container-fluid " style={{ display: 'flex' }}>
        <div style={divStyle}>
          <HeaderCard />

          <div className="scroll-y overflow-y-auto mb-4" style={{maxHeight:'80px'}}>
              <p className=" text-white  mb-0 poppins-light fs-8 p-2">{instruction}</p>
          </div>

          <div className="scroll-y overflow-y-auto table-responsive rounded-4 border border-2 border-white overflow-auto" style={{maxHeight:'200px'}}>
            <table className={`table-bordered border-white p-0 ${tableEnabled ? '' : 'disabled-table'}`} style={{ width: 'auto' }}>
              <colgroup>
                <col style={{ width: '70px' }} />
              </colgroup>
              <thead>
                <tr className="poppins-bold fs-9">
                  <th></th>
                  {question.column.map(({ title }) => (
                    <th
                      style={{ background: '#D0FF00', width: '80px' }}
                      className="text-primary text-center bg-light p-"
                      key={title}
                    >
                      {title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-center text-white poppins-light fs-9">
                {question.row.map(({ idWord, title }, rowIndex) => (
                  <tr className='td-size' key={idWord}>
                    <td className='text-primary poppins-bold p-1' style={{ background: '#D0FF00', width: '80px' }}>{title}</td>
                    {question.column.map(({ title: columnTitle, value: columnValue }, columnIndex) => (
                      <td
                        key={columnValue}
                        id={`${idWord}-${columnValue}`} // ID único para cada celda
                        onClick={() => handleCellClick(rowIndex, columnIndex, idWord)}
                        className={selectedCellId.some(cell =>  cell.rowIndex === rowIndex && cell.columnIndex === columnIndex) ? (selectedCellId.find(cell => cell.rowIndex === rowIndex && cell.columnIndex === columnIndex)?.isValid ? 'bg-success' : 'bg-danger') : 'bg-primary'}
                      >
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-5'>
              <div>
                {checked &&
                
                  <ButtonTryAgain onAction={resetExercise} />
                }
              </div>
              <div>
                <ButtonSaveExercise
                  isChecked={checked}
                  onNext={handleCheck}
                  onSubmit={handleSubmit}
                  exerciseId={exerciseId}
                />
              </div>
            {/* <div
              onClick={(e) => {
                e.preventDefault();
                if (!checked) {
                  handleCheck();
                  // setTableEnabled(false);
                } else {
                  handleSubmit();
                }
              }}
              className="cursor-pointer">
              <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '42px', height: '42px' }} alt="Next" />
            </div> */}
          </div>
        </div>

      </div>
    </>
  );
};
export { CoordinatesItem };