import React from 'react';
import { UseBoosterExercise } from './main/main';
import TextCompletion from './items/booster-item';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';

type Props = {
  userMode? : ModeTypeExercise
}
const BoosterEx: React.FC<Props> = ({ userMode}) => {
  const { texts,answers , studentResponse } = UseBoosterExercise();
  // const texts = [
  //     "Es un hecho establecido hace {demasiado} tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su {diseño}.",
  //     "Este es un apellido.",
  //     " THIS este es un apellido. {demasiado} ",
  //     "QUEeste es un apellido."
  //   ];
  //   // const answers = {
  //   //   demasiado: "demasiado",
  //   //   diseño: "diseño",
  //   //   // Rios: "Rios"
  //   // };

  //   const answers = {
  //     demasiado_1: "demasiado",
  //     diseño_1: "diseño",
  //     demasiado_2: "demasiado"
  // };

  // Generar textos y respuestas con identificadores únicos
  // const generateUniquePlaceholders = (texts) => {
  //   const placeholderCount = {};
  //   return texts.map(text => {
  //     return text.replace(/\{([^}]+)\}/g, (match, p1) => {
  //       if (!placeholderCount[p1]) {
  //         placeholderCount[p1] = 1;
  //       } else {
  //         placeholderCount[p1]++;
  //       }
  //       return `{${p1}_${placeholderCount[p1]}}`;
  //     });
  //   });
  // };

  // const uniqueTexts = generateUniquePlaceholders(texts);

  return (
    <>
      <TextCompletion 
        userMode={userMode}
        texts={texts} 
        answers={answers} 
        studentRes={studentResponse}
        />
    </>
  );
};

export { BoosterEx };