/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {KTIcon} from '../../../../../../_metronic/helpers'
import { useParams } from 'react-router-dom';
import { LevelSelected } from '../LevelSelected';
import { DayPickerSelector } from '../../../../../components/common/DayPicker';
import { DateRange } from '../../../../admin/appointment/components/list';
import moment from 'moment';
import { BookingItem } from '../BookingItem';
import { DayPickerRangeBookingSelector } from '../../../../../components/common/DayRangePickerBooking/indext';
import { UseBookingPaginate } from '../../../../admin/appointment/services';
import { UseBookingModule } from '../../../store';
import { Booking } from '../../../../admin/appointment/interfaces';

const Step1: FC = () => {

  const { level,setBooking } = UseBookingModule();
  const { code } = useParams();
  const [dateRange, setDateRange] = React.useState<DateRange>({
    after:moment().toDate(),
    before:moment().toDate()
  })
  const [bookingAvailable, setBookingAvailable] = React.useState<Booking[]>([])
  const [day, setDay] = React.useState<Date>(moment().toDate())
  const [availableList, setAvailableList] = React.useState<any[]>([
    moment(),
    moment().add('days',2)
  ])
  const findStarterList =async ()=>{
 
      let  starterList =await  UseBookingPaginate({
       before:moment().format('YYYY-MM-DD').toString(),
       after:moment().add('months',10).format('YYYY-MM-DD').toString()
      })
      let list:any[]=[];
      starterList.map((x)=>{
        list.push(moment(x.start))
      })
      setAvailableList(list)

  }
  const findBookingAvailable =async ()=>{
  
      let  starterList =await  UseBookingPaginate({
       before:moment(day).format('YYYY-MM-DD').toString(),
       after:moment(day).format('YYYY-MM-DD').toString()
      })
      await Promise.all(
        starterList.sort((a, b) => {
         const timeA:any = moment(a.time.start);
         const timeB:any = moment(b.time.start);
         return timeA - timeB;
       })
      )
      setBookingAvailable(starterList)

  }
  useEffect(() => {
    findStarterList()
  }, [day])
  useEffect(() => {
    findBookingAvailable()
  }, [day])
  return (
    <div className='w-100'>


      {<>{`${day}`}</>}
      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-4 card'>
              <DayPickerRangeBookingSelector list={availableList} 
                  day={moment(day).toDate()} 
                  changeDate={(e)=>setDay(e)}
               /> 
          </div>

          <div className='col-lg-8'>
          {bookingAvailable.map((x)=>(<BookingItem setBooking={setBooking} item={x} />))}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
