import React from 'react';
import { useParams } from 'react-router-dom';
import { showSimpleAlert } from '../../../../../../commonHooks/alert';
import { FindOneEvaluation } from '../../../services';
import { HeaderCourses } from '../../../../../courses/components/header';
import { MainCardContent } from '../../../../../courses/prismatics/component/main-content';
import { UseEvaluationTestModule } from '../store/main';
import { UseModeCourseModule } from '../../../../../courses/components/front-page/store/main';
import ContainerFather from '../../../../../courses/components/container';

type Props = {};

const TestContainer: React.FC<Props> = () => {
  const {id} = useParams();
  const {
    evaluationTest,
    resetEvaluationTest,
    lessonCourse,
    setEvaluationTest,
    setLessonCourse
  } = UseEvaluationTestModule();
  const {modeCourseChecked} = UseModeCourseModule();
  const findEvaluation = async () => {
    try {
      if (id) {
        const response = await FindOneEvaluation(id);
        setEvaluationTest(response);
        if (response?.lesson && response?.lesson.length > 0) {
          setLessonCourse(response.lesson[0]??[]);
        }
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar evaluación', icon: 'error' });
      console.log('err', e)
    }
  }

  React.useEffect(() => {
    modeCourseChecked();
    findEvaluation();
    // resetEvaluationTest();
    // setShowEndEvaluation(true);
  }, [id]);
  
  return(<>
    {evaluationTest && 
      <ContainerFather
        colorPrimary={evaluationTest.colorPrimary}
        colorSecondary={evaluationTest.colorSecondary}
        typeEvaluation={evaluationTest.type} 
        logoCourse={lessonCourse?.chapter?.path?.logo?.location??''}
        nameTopic={lessonCourse?.name??'MIchigan Master'}
        typeCourse={lessonCourse?.chapter.path.type}
      />
    }
  </>)
};

export default React.memo(TestContainer);