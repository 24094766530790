import React, { useEffect } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useSupportTicketState } from '../../hooks/useSupportTicketRepo'
import { UseSupportTicketModule } from '../../store/main'
import { SupportTicket, SupportTicketPriority, SupportTicketStatus } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { SupportTicketDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import moment from 'moment'
import { InteractiveModal } from '../modal/interactiveModal'
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish'
import useAuth from '../../../../../api/main'
import { CardHeaderModule } from '../../../../../components/common/widgets/statistics/cardHeaderModule'
import { ResponseSupport } from './responseSupport'
import { useThemeMode } from '../../../../../../_metronic/partials'
import './index.css';

type PaginatorSetter ={
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const SupportTicketTableList: React.FC<Props> = ({ }) => {

  const {mode} = useThemeMode();
  const queryClient = useQueryClient()
  const { 
      paginator, 
      itemSelected,
      modal,
      modalInteractive,
      ticketPriority,
      ticketStatus,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit, 
      setIsActive, 
      setSelectedToEdit, 
      setSelectedToView,
      setModal,
      setWhere,
      setToAdd,
      setTicketStatus,
      setTicketPriority,
      setInteractive,
      setRoot,
  } = UseSupportTicketModule()
  const { data, isLoading } = useSupportTicketState();

  const {typeUser,id} = useAuth.getState();

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage:1,
    items:0,
    total:0,
    totalPage:0
});

  const deleteItem = async (id:string)=>{
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          await SupportTicketDelete(id)
          showSimpleAlert({message:'Ok!',icon:'success'})
          queryClient.invalidateQueries({ queryKey: ['support-ticket'] })
         }catch(e){
            
         }
      }
    })
  }
  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['support-ticket'] })
  }, [isActive,where,currentPage,limit,ticketPriority,ticketStatus]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value === 'Ninguno' ? undefined : (e.target.value as SupportTicketStatus);
    setTicketStatus(selectedValue);
  };

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value === 'Ninguna' ? undefined : (e.target.value as SupportTicketPriority);
    setTicketPriority(selectedValue);
  };

  useEffect(() => {
    if (typeUser === 'STUDENT') {
      setRoot(id??'xxx')
    }
  }, []);
  
  return (<>
    <CardHeaderModule
        title={'Soporte (PQRS)'} 
        leftIcon={`/media/svg/michigan-icons/header/${mode==='light'?'white/back_white':'dark-modules/back_purple'}.svg`} 
        svgIcon={`/media/svg/michigan-icons/header/${mode==='light'?'white/support_white':'dark-modules/support_purple'}.svg`} 
        background={'#a500b7'}
        colorTitle={'#FFFFFF'}
    />

    {!itemSelected && <>
      <div className='d-flex mt-3 justify-content-between'>
        <div className='d-flex'>
          <div className='card d-flex justify-content-start' style={{border:mode==='light'?'':'1px solid #FFFFFF', borderRadius:'50px'}}>
            <div className='d-flex p-5'>
              <label className='me-2 d-flex align-items-center champ-light w-100 fw-bold'>Estado:</label>
              <select
                name='ticketPriority'
                value={ticketPriority}
                onChange={handleChange}
                className='form-select form-select-sm w-100 champ-light fw-bold'
                aria-label='Default select example'
              >
                <option value={undefined}>
                  {'Ninguno'}
                </option>
                {Object.keys(SupportTicketStatus).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <button
            type='button'
            onClick={() => setToAdd()}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            style={{
              textDecoration: 'none',
              transition: 'background-color 0.3s',
            }}
          >
            <i style={{ color: 'blue', fontSize: 17 }} className="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>

      {/* <div className='mt-3'>
        <div className={`card`}>
            <div className='card-body py-3'>
                <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-4'>
                        <thead>
                            <tr className='fw-bold bg-light'>
                                <th className=' champ-light ps-4 min-w-200px rounded-start'>Asunto</th>
                                <th className=' champ-light min-w-100px d-flex justify-content-center'>Status</th>
                                <th className=' champ-light min-w-100px'>Date</th>
                                <th className=' champ-light min-w-100px text-start rounded-end'>Answer</th>
                            </tr>
                        </thead>
                        <tbody>
                          {data?.result?.map((x: SupportTicket) => (
                            <tr>
                              <td>
                                  <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                          <a href='#' className='text-dark champ-light fw-bold bold text-gray-900 text-hover-primary mb-1 fs-6'>
                                              {x?.name}
                                          </a>
                                      </div>
                                  </div>
                              </td>
                              <td>
                                <span className='d-flex justify-content-center champ-bold fw-bold d-block mb-1 fs-6' style={{ color: getStatusColor(x?.status), borderRadius:2 }}>
                                  {setTypeToSpanish(x?.status)}
                                </span>
                              </td>
                              <td>
                                  <a href='#' className='text-dark champ-light fw-bold text-hover-primary d-block mb-1 fs-6'>
                                      {moment(x?.createdAt).format('DD/MM/YYYY')}
                                  </a>
                              </td>
                              <td>
                                <button type='button' className='btn btn-outline-light' onClick={() => setInteractive(x)}>   
                                  <i className='fa-regular fa-envelope' style={{ color: 'blue', fontSize: 18 }}>
                                    {x?.responseSupportTicket && x?.responseSupportTicket?.length > 0 &&
                                      <span className='badge badge-light-danger badge-circle fw-bolder fs-9 top-0 translate-middle'>
                                        {x?.responseSupportTicket?.length}
                                      </span>}
                                  </i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                    </table>
                    <GenericPaginator
                      limit={limit}
                      currentPage={paginator.current_page}
                      items={paginator.result.length}
                      total={paginator.total}
                      totalPage={paginator.total_page}
                      changePage={changePage}
                      changeLimit={changeLimit} 
                    />
                </div>
            </div>
        </div>
      </div> */}

      <div className='mt-5'>
        <div className='card content-support' style={{border: mode==='light'?'':'1px solid #FFFFFF'}}>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table'>
                <thead className=''>
                  <tr className={`fw-bold bg-light`}>
                    <th className='poppins-bold fs-5 min-w-800px text-start row'>
                      <span className='col-2'></span>
                      <span className='col-6'>Asunto</span>
                      <span className='col-2'>Estado</span>
                      <span className='col-2'>Fecha</span>
                    </th> 
                  </tr>
                </thead>
                <tbody className=''>
                  {data?.result?.map((x) => (
                    <tr
                      key={x.id}
                      className={`mt-3 row cursor-pointer fs-5 ${mode==='dark'&& x?.responseSupportTicket?.length > 0 && 'bg-light'}`}
                      style={{ 
                        borderRadius: '50px', 
                        backgroundColor:mode==='light'? (x?.responseSupportTicket?.length > 0 ? '#E5E6FA' : '') : '',
                        color:mode==='light'?'#2d30e1':'#E5E6FA'
                      }}
                      onClick={() => setInteractive(x)}
                    >
                      <td className='col-2 ps-9'>
                        <i className='fa-regular fa-envelope' style={{ color:mode==='light'?'#2d30e1':'#E5E6FA', fontSize: 18 }}>
                          {x?.responseSupportTicket && x?.responseSupportTicket?.length > 0 && (
                            <span className={`badge badge-light-${mode==='light'?'danger':'success'} badge-circle fw-bolder fs-9 top-0 translate-middle`}>
                              {x?.responseSupportTicket?.length}
                            </span>
                          )}
                        </i>
                      </td>
                      <td className='col-6 ps-0'>
                        <span className='poppins-light fw-bold ps-5 d-flex align-items-center'>
                          {x?.name}
                        </span>
                      </td>
                      <td className='col-2'>
                        <span className='poppins-light fw-bold'>{setTypeToSpanish(x?.status)}</span>
                      </td>
                      <td className='col-2'>
                        <span className='poppins-light fw-bold'>{moment(x?.createdAt).format('DD/MM/YYYY')}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            <GenericPaginator
              limit={limit}
              currentPage={paginator.current_page}
              items={paginator.result.length}
              total={paginator.total}
              totalPage={paginator.total_page}
              changePage={changePage}
              changeLimit={changeLimit}
            />
            </div>
          </div>
        </div>
      </div>

      {/* <div className='mt-3 overflow-auto'>
        {data?.result?.map((x: SupportTicket) => (
          <a key={x.id} href='#' className='card mb-3 p-4 position-relative' style={{ borderRadius: 50, backgroundColor: '#E5E6FA' }} onClick={() => setInteractive(x)}>
            <div className='position-relative'>
              <div className='row'>
                <div className='col-1 align-items-center ps-8 position-relative'>
                  <i className='fa-regular fa-envelope' style={{ color: 'blue', fontSize: 15 }}>
                    {x?.responseSupportTicket && x?.responseSupportTicket?.length > 0 &&
                      <span className='badge badge-light-danger badge-circle fw-bolder fs-8 position-absolute top-0 start-50 translate-middle'>
                        {x?.responseSupportTicket?.length}
                      </span>}
                  </i>
                </div>
                <div className='col-7 p-0'>
                  <span className='poppins-bold fs-bold ps-5 d-flex align-items-center' style={{ color: 'blue' }}>
                    {x?.name}
                  </span>
                </div>
                <div className='col-2 p-0'>
                  <span className='poppins-light'>
                    {setTypeToSpanish(x?.status)}
                  </span>
                </div>
                <div className='col-2 pe-0 poppins-light'>
                  {moment(x?.createdAt).format('DD/MM/YYYY')}
                </div>
              </div>
            </div>
          </a>
        ))}
        <GenericPaginator
          limit={limit}
          currentPage={paginator.current_page}
          items={paginator.result.length}
          total={paginator.total}
          totalPage={paginator.total_page}
          changePage={changePage}
          changeLimit={changeLimit} 
        />
      </div> */}
      </>}

      {itemSelected && <>
        <ResponseSupport/>
      </>}

    <CreateModal show={modal} handleClose={() => alert()} />
    <InteractiveModal show={modalInteractive} handleClose={() => alert()} />
  </>
  )
}

export { SupportTicketTableList }