import React, { useEffect } from 'react';
import { WritingCheckListItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseWritingCheckListModule } from './store';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';


type Props = {
    userMode?: ModeTypeExercise
};

const WritingCheckList: React.FC<Props> = ({ userMode }) => {
    let {
        mode,
        questions,
        responseStudent,
        instructions
    } = UseWritingCheckListModule();

    return (
        <>
        {mode &&
            <WritingCheckListItem
                userMode={userMode}
                responses={responseStudent}
                instruction={instructions}
                question={questions}
                mode={mode}
            />
        }
        </>
    );
};

export { WritingCheckList };