import moment from 'moment'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'

type Props = {
  data: any
}

const QualifyPlacementTestForm: React.FC<Props> = ({data}: Props) => {
  const {setSelectedToView} = UseQualifyPlacementModule()

  return (
    <div className='table-responsive'>
      <table className='table align-middle gs-0 gy-4'>
        <thead className=''>
          <tr className={`fs-5 fw-bold bg-light poppins-bold`}>
            <th className='text-start text-center align-middle'>Student </th>
            <th className='text-center align-middle'>Document </th>
            <th className='text-center align-middle'>Cellphone </th>
            <th className='text-center align-middle'>Test name </th>
            <th className='text-center align-middle'>Test code </th>
            <th className='text-center align-middle'>Status </th>
            <th className='text-center align-middle'>Date</th>
          </tr>
        </thead>
        <tbody className=''>
          {data?.result?.map((x: any) => (
            <tr
              key={x.id}
              className={`mt-3 cursor-pointer poppins-light fw-bold`}
              onClick={() => setSelectedToView(x)}
            >
              <td className='text-center align-middle'>{x.student_name}</td>
              <td className='text-center align-middle'>{x.student_setting.user.document_id}</td>
              <td className='text-center align-middle'>{x.student_setting.user.phoneNumber}</td>
              <td className='text-center align-middle'>{x.placement_test}</td>
              <td className='text-center align-middle'>{x.student_setting.code}</td>
              <td
                className={`text-center align-middle 
                  text-${
                      x?.status === 'PENDING'
                    ? 'primary'
                    : x?.status === 'IN_REVIEW' 
                    ? 'danger'
                    : x?.status === 'QUALIFIED'
                    ? 'warning'
                    : x?.status === 'APPROVED' || x?.status === 'REPROVED'
                    ? 'success'
                    : 'dark'
                  }`
                }
              >
                {x?.status === 'PENDING'
                    ? 'Asignado'
                    : x?.status === 'IN_REVIEW' 
                    ? 'Pendiente por revisar'
                    : x?.status === 'QUALIFIED'
                    ? 'Calificado'
                    : x?.status === 'APPROVED' || x?.status === 'REPROVED'
                    ? 'Nivelado'
                    : 'Cancelada'}
              </td>
              <td className='text-center align-middle'>
                {moment(x?.createdAt).format('DD/MM/YYYY')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default QualifyPlacementTestForm
