import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {DayPushedType, TemplatePusher} from '../../interfaces'

import { TemplatePusherAdd, TemplatePusherUpdate } from '../../services'
import { UseTemplatePusherModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseTemplateTableTimeAll } from '../../../template-table-time/services'
import { TemplateTableTime } from '../../../template-table-time/interfaces'
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish'

export type FormFields = {
  start: string;
  mondayStatus?: DayPushedType;
  tuesdayStatus?: DayPushedType;
  wednesdayStatus?: DayPushedType;
  thursdayStatus?: DayPushedType;
  fridayStatus?: DayPushedType;
  saturdayStatus?: DayPushedType;
  template: string;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const TemplatePusherForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseTemplatePusherModule();
  const [templateTableTimeData, setTemplateTableTimeData] = useState<TemplateTableTime[]>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        start: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.start ?? '' : '',
        mondayStatus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.mondayStatus ?? DayPushedType.NO_AVAILABLE : DayPushedType.NO_AVAILABLE,
        tuesdayStatus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.tuesdayStatus ?? DayPushedType.NO_AVAILABLE : DayPushedType.NO_AVAILABLE,
        wednesdayStatus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.wednesdayStatus ??DayPushedType.NO_AVAILABLE : DayPushedType.NO_AVAILABLE,
        thursdayStatus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.thursdayStatus ??DayPushedType.NO_AVAILABLE : DayPushedType.NO_AVAILABLE,
        fridayStatus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.fridayStatus ?? DayPushedType.NO_AVAILABLE : DayPushedType.NO_AVAILABLE,
        saturdayStatus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.saturdayStatus ?? DayPushedType.NO_AVAILABLE : DayPushedType.NO_AVAILABLE,
        template: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.template?.id ?? '' : ''
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await TemplatePusherAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['template-pusher'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  TemplatePusherUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['template-pusher'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const fetchTemplateItem = async () => {
      try {
        const templateResponse = await UseTemplateTableTimeAll();
        setTemplateTableTimeData(templateResponse);
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar Templates!', icon: 'error' })
        throw 'Error'
      }
    };

    React.useEffect(() => {
      fetchTemplateItem();
    }, []);

    React.useEffect(() => {
      if (mode === 'ADD' && values.template) {
        let selectedTemplate = templateTableTimeData?.find(x=>x.id === values.template);
        setFieldValue('mondayStatus', selectedTemplate?.monday ? DayPushedType.PENDING : DayPushedType.NO_AVAILABLE)
        setFieldValue('tuesdayStatus', selectedTemplate?.tuesday ? DayPushedType.PENDING : DayPushedType.NO_AVAILABLE)
        setFieldValue('wednesdayStatus', selectedTemplate?.wednesday ? DayPushedType.PENDING : DayPushedType.NO_AVAILABLE)
        setFieldValue('thursdayStatus', selectedTemplate?.thursday ? DayPushedType.PENDING : DayPushedType.NO_AVAILABLE)
        setFieldValue('fridayStatus', selectedTemplate?.friday ? DayPushedType.PENDING : DayPushedType.NO_AVAILABLE)
        setFieldValue('saturdayStatus', selectedTemplate?.saturday ? DayPushedType.PENDING : DayPushedType.NO_AVAILABLE)
      }

    }, [values.template]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form poppins-light '>
        <div className='card-body border-top p-9 row'>
          <span className='text-dark fw-semibold d-block fs-6 poppins-light'>
              <i className='fa solid fa-arrow-right text-blue '></i> Selecione un dia lunes para crear satisfactoriamente la plantilla.      
          </span>
          {/* //champ-bold    champ-light  poppins-light poppins-bold  */}
          <div className='mb-1 col-6 mt-5'>
              <label className='form-label mb-0 champ-bold text-primary'>Inicio</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'start'}
                value={values.start}
                onChange={handleChange}
              />
              {touched.start && errors.start && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.start}</span>
                  </div>
                </div>
              )}
          </div>
          <div className='mb-5 col-6 mt-5'>
            <label className='form-label mb-0 champ-bold text-primary'>Template</label>
            <select
              disabled={mode === 'VIEW'}
              name={'template'}
              value={values.template}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option selected></option>
              {templateTableTimeData?.map((x) =>
                (<option value={x?.id}>{`${x?.name}`}</option>)
              )}
            </select>
            {errors.template && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.template}</span>
                </div>
              </div>
            )}
          </div>

          {mode !== 'ADD' && <>
            <div className='mb-10 col-4'>
                <label className='form-label mb-0 champ-bold' style={{color:'#989898'}}>Monday</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='mondayStatus'
                  value={values.mondayStatus}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {mode === 'VIEW' ? 
                    Object.keys(DayPushedType).map((x) => (
                      <option key={x} value={x}>
                        {setTypeToSpanish(x)}
                      </option>
                    )) :
                    [DayPushedType.NO_AVAILABLE, DayPushedType.PENDING].map((x)=> (
                      <option key={x} value={x}>{setTypeToSpanish(x)}</option>
                    ))
                  }
                </select>
                {errors.mondayStatus && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.mondayStatus}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-10 col-4'>
                <label className='form-label mb-0 champ-bold 'style={{color:'#989898'}}>Tuesday</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='tuesdayStatus'
                  value={values.tuesdayStatus}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {mode === 'VIEW' ? 
                    Object.keys(DayPushedType).map((x) => (
                      <option key={x} value={x}>
                        {setTypeToSpanish(x)}
                      </option>
                    )) :
                    [DayPushedType.NO_AVAILABLE, DayPushedType.PENDING].map((x)=> (
                      <option key={x} value={x}>{setTypeToSpanish(x)}</option>
                    ))
                  }
                </select>
                {errors.tuesdayStatus && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.tuesdayStatus}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-10 col-4'>
                <label className='form-label mb-0 champ-bold 'style={{color:'#989898'}}>Wednesday</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='wednesdayStatus'
                  value={values.wednesdayStatus}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {mode === 'VIEW' ? 
                    Object.keys(DayPushedType).map((x) => (
                      <option key={x} value={x}>
                        {setTypeToSpanish(x)}
                      </option>
                    )) :
                    [DayPushedType.NO_AVAILABLE, DayPushedType.PENDING].map((x)=> (
                      <option key={x} value={x}>{setTypeToSpanish(x)}</option>
                    ))
                  }
                </select>
                {errors.wednesdayStatus && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.wednesdayStatus}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-5 col-4'>
                <label className='form-label mb-0 champ-bold 'style={{color:'#989898'}}>Thursday</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='thursdayStatus'
                  value={values.thursdayStatus}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {mode === 'VIEW' ? 
                    Object.keys(DayPushedType).map((x) => (
                      <option key={x} value={x}>
                        {setTypeToSpanish(x)}
                      </option>
                    )) :
                    [DayPushedType.NO_AVAILABLE, DayPushedType.PENDING].map((x)=> (
                      <option key={x} value={x}>{setTypeToSpanish(x)}</option>
                    ))
                  }
                </select>
                {errors.thursdayStatus && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.thursdayStatus}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-5 col-4'>
                <label className='form-label mb-0 champ-bold 'style={{color:'#989898'}}>Friday</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='fridayStatus'
                  value={values.fridayStatus}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {mode === 'VIEW' ? 
                    Object.keys(DayPushedType).map((x) => (
                      <option key={x} value={x}>
                        {setTypeToSpanish(x)}
                      </option>
                    )) :
                    [DayPushedType.NO_AVAILABLE, DayPushedType.PENDING].map((x)=> (
                      <option key={x} value={x}>{setTypeToSpanish(x)}</option>
                    ))
                  }
                </select>
                {errors.fridayStatus && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.fridayStatus}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-5 col-4'>
                <label className='form-label mb-0 champ-bold 'style={{color:'#989898'}}>Saturday</label>
                <select
                  disabled={mode === 'VIEW'}
                  name='saturdayStatus'
                  value={values.saturdayStatus}
                  onChange={handleChange}
                  className='form-select'
                  aria-label='Default select example'
                >
                  {mode === 'VIEW' ? 
                    Object.keys(DayPushedType).map((x) => (
                      <option key={x} value={x}>
                        {setTypeToSpanish(x)}
                      </option>
                    )) :
                    [DayPushedType.NO_AVAILABLE, DayPushedType.PENDING].map((x)=> (
                      <option key={x} value={x}>{setTypeToSpanish(x)}</option>
                    ))
                  }
                </select>
                {errors.saturdayStatus && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.saturdayStatus}</span>
                    </div>
                  </div>
                )}
              </div>
          </>}
          

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {TemplatePusherForm}
