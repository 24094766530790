import {Route, Routes} from 'react-router-dom'
import { QualifyEvaluationTableList } from '../modules/common/list'
import { EvaluationType } from '../../evaluation/interfaces'

const QualifyPage = () => (
  <Routes>
      <Route path='/final-presentation' element={<QualifyEvaluationTableList evaluationType={EvaluationType.FINAL_PRESENTATION}/>} />
      <Route path='/placement-test' element={<QualifyEvaluationTableList evaluationType={EvaluationType.PLACEMENT_TEST}/>} />
      <Route path='/welcome-day' element={<QualifyEvaluationTableList evaluationType={EvaluationType.WELCOME_DAY}/>} />
      <Route path='/quiz' element={<QualifyEvaluationTableList evaluationType={EvaluationType.QUIZ}/>} />
  </Routes>
)

export {QualifyPage}
