import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {AudioInteractionEnum} from '../../interfaces'
import { AudioInteractionAdd, AudioInteractionUpdate } from '../../services'
import { UseAudioInteractionModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../../../../_metronic/helpers'
import FileUploadComponent from '../../../../../../../../components/common/SingleFile'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { useTranslation } from 'react-i18next'
import { favIconAppSelector } from '../../../../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  title: string
  isActive: boolean;
  htmlContent: string;
  audio: string;
  level: TraditionalLevelEnum;
  description?:string
  type: AudioInteractionEnum
  colorPrimary: string;
  borderColor: string;
}

const entitySchema = Yup.object().shape({
  title: Yup.string().required('Field is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const AudioInteractionForm: React.FC<Props>  = (props:  Props) => {
  const {id} = useParams();
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const {itemSelected, mode,resetData} = UseAudioInteractionModule();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [htmlFile, setHtmlFile] = React.useState<any>();
  const [audioFile, setAudioFile] = React.useState<any>();

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        title: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.title ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        htmlContent: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.htmlContent?.id ?? '' : '',
        audio: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.audio?.id ?? '' : '',
        colorPrimary: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.colorPrimary ?? '' : '',
        borderColor: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.borderColor ?? '' : '',
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? AudioInteractionEnum.CONVERSATION : AudioInteractionEnum.CONVERSATION
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await AudioInteractionAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['audio-interaction'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  AudioInteractionUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['audio-interaction'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

  React.useEffect(() => {
    setHtmlFile(itemSelected?.htmlContent);
    setAudioFile(itemSelected?.audio);
  }, [itemSelected]);

  const handleChangeAudioFile = (obj: any) => {
    setFieldValue('audio', obj.id);
    setAudioFile(obj);
  };
  
  const handleChangeHtmlFile = (obj: any) => {
    setFieldValue('htmlContent', obj.id);
    setHtmlFile(obj);
  };
  
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Title</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'title'}
              value={values.title}
              onChange={handleChange}
            />
            {touched.title && errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.title}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              name='level'
              className='form-select'
              aria-label='Default select example'
              value={values.level}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Color Primario</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'colorPrimary'}
              value={values.colorPrimary}
              onChange={handleChange}
            />
            {touched.colorPrimary && errors.colorPrimary && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.colorPrimary}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Color del borde</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'borderColor'}
              value={values.borderColor}
              onChange={handleChange}
            />
            {touched.borderColor && errors.borderColor && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.borderColor}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Type</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              {Object.keys(AudioInteractionEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>
          <div className='mb-10 col-6 d-flex flex-column'>
              <label className='form-label mb-3'>Audio</label>
              {(audioFile && (audioFile.url || audioFile.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(audioFile.url || audioFile.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileUploadComponent
                  onChangeFile={(e) => handleChangeAudioFile(e)} 
                  onChangeLoading={(e) => setLoading(e)}
                  pushCover
                  idInput='audio' />
          </div>
          <div className='mb-10 col-6 d-flex flex-column'>
              <label className='form-label mb-3'>Html Content</label>
              {(htmlFile && (htmlFile.url || htmlFile.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(htmlFile.url || htmlFile.location)??toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl(favIconAppSelector())} 
                alt='metronic'/>}
            <FileUploadComponent
                  onChangeFile={(e) => handleChangeHtmlFile(e)}
                  onChangeLoading={(e) => setLoading(e)}
                  pushCover
                  idInput='htmlContent' />
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {AudioInteractionForm}
