import { TraditionalLevelEnum } from "../../../../../../abroad/road-map-step/interfaces";
import { MultipleSelectionQuestionIE } from "../../../../../../courses/exercises/multiple-selection/interfaces";
import { ItemsWritingCheckListItems } from "../../../writing-check-list/interfaces";

export interface DataPaginateCheckWritingResponse {
    status:  string;
    message: string;
    data:    DataCheckWriting;
}

export interface DataAllCheckWritingResponse {
    status:  string;
    message: string;
    data:    CheckWriting[];
}

export interface DataCheckWriting {
    id?: string;
    description:string;
    name:string;
    level: TraditionalLevelEnum;
    questions: MultipleSelectionQuestionIE[];
    themes?: ItemsWritingCheckListItems[];
    type: CheckWritingType;
    exercises: any;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface CheckWriting  {
    id?: string;
    description:string;
    name:string;
    level: TraditionalLevelEnum;
    questions: string[] //MultipleSelectionQuestionIE[];
    themes?:string[]// ItemsWritingCheckListItems[];
    type: CheckWritingType;
    exercises: any;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: CheckWriting[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum CheckWritingType {
    CHECK_WRITING_SINGLE = 'CHECK_WRITING_SINGLE',
    CHECK_WRITING_DESCRIPTION = 'CHECK_WRITING_DESCRIPTION'
}