import React from "react";
import { UseEvaluationModule } from "../../store/main";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { EvaluationItem, EvaluationType, ItemCategoryType } from "../../interfaces";
import { EvaluationItemAdd, EvaluationItemUpdate } from "../../services";
import { useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import { Exercises, VariantsExercisesType } from "../../../exercises/interfaces";
import { FindExerciseByCode } from "../../../exercises/services";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { useTranslation } from "react-i18next";

type Props = {
  mode: 'ITEM_ADD'|'ITEM_UP'
};

type FormFields = {
  percentage: number;
  order: number;
  timer: number;
  evaluation_auto: boolean;
  category: ItemCategoryType;
  exercise: string;
  name: string;
  description: string;
  minPercentage: number;
};

const FormItemEvaluation: React.FC<Props> = ({mode}) => {
  const queryClient = useQueryClient();
  const {t} = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [codeExercise, setCodeExercise] = React.useState<string>();
  const [dataExercise, setDataExercise] = React.useState<Exercises>();

  const {type, setType, itemSelected, resetData, itemEvaluation} = UseEvaluationModule();
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        percentage: (mode === 'ITEM_UP') ? itemEvaluation?.percentage ?? 0 : 0,
        order: (mode === 'ITEM_UP') ? itemEvaluation?.order ?? 1 : itemSelected ? itemSelected.evaluation_item ? 
          itemSelected.evaluation_item.length + 1 : 1 : 1,
        timer: (mode === 'ITEM_UP') ? itemEvaluation?.timer ?? 0 : 0,
        evaluation_auto: (mode === 'ITEM_UP') ? itemEvaluation?.evaluation_auto ?? true : true,
        exercise: (mode === 'ITEM_UP') ? itemEvaluation?.exercise?.id ?? '' : '',
        name: (mode === 'ITEM_UP') ? itemEvaluation?.name ?? '' : '',
        description: (mode === 'ITEM_UP') ? itemEvaluation?.description ?? '' : '',
        minPercentage: (mode === 'ITEM_UP') ? itemEvaluation?.minPercentage ?? 50 : 50,
        category: (mode === 'ITEM_UP') ? itemEvaluation?.category ?? ItemCategoryType.GRAMMAR : ItemCategoryType.GRAMMAR,
      },
      onSubmit:async (formData) => {
        if (mode === 'ITEM_ADD') {
          try {
            setLoading(true);
            await EvaluationItemAdd({
              ...formData,
              evaluation: itemSelected?.id??'xx',
              exercise: dataExercise?.id??'xx'
            });
            resetData();
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: ['evaluation'] });
            queryClient.invalidateQueries({ queryKey: ['evaluation-by-lesson'] });
          } catch (e) {
            setLoading(false);
          }
        } else {
          try {
            setLoading(true);
            await EvaluationItemUpdate({
              ...formData,
              evaluation: itemSelected?.id??'xx',
              exercise: dataExercise?.id??'xx'
            });
            resetData();
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: ['evaluation'] });
            queryClient.invalidateQueries({ queryKey: ['evaluation-by-lesson'] });
          } catch (e) {
            setLoading(false);
          }
        }
      }
    });

  const findExerciseByCode = async (code: string, type?: VariantsExercisesType) => {
    try {
      const response = await FindExerciseByCode(code, type);
      setDataExercise(response);
    } catch (e) {
      console.error(e)
      // showSimpleAlert({ message: 'Error al encontrar Exercise', icon: 'error' })
    }
  };
  
  React.useEffect(() => {
    const type = itemSelected?.type === EvaluationType.BOOK ? 
      VariantsExercisesType.BOOK : VariantsExercisesType.FULL_SCREEN; 
    if (codeExercise && type && !loading) {
      findExerciseByCode(codeExercise, type);
    }
  }, [codeExercise, itemSelected, loading]);

  React.useEffect(() => {
    if (itemEvaluation && itemEvaluation.exercise) {
      setCodeExercise(itemEvaluation.exercise.code);
    }
  }, [itemEvaluation]);

  return(<>
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>
          <div className='d-flex justify-content-start flex-shrink-0 mb-2'>
            <button
              type='button'
              onClick={()=> setType('LIST')}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            >
              <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
            </button>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Automatica ?</label>
            <div className="form-check form-switch">
              <input
                name={'evaluation_auto'}
                checked={values.evaluation_auto}
                value={values.evaluation_auto?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Categoria</label>
            <select
              name='category'
              value={values.category}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ItemCategoryType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.category && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.category}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Titulo del Ejercicio</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Porcentage</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'percentage'}
              value={values.percentage}
              onChange={handleChange}
            />
            {touched.percentage && errors.percentage && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.percentage}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Minimo porcentaje de aprobación</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'minPercentage'}
              value={values.minPercentage}
              onChange={handleChange}
            />
            {touched.minPercentage && errors.minPercentage && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.minPercentage}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Order</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Timer</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'timer'}
              value={values.timer}
              onChange={handleChange}
            />
            {touched.timer && errors.timer && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.timer}</span>
                </div>
              </div>
            )}
          </div>
          
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Codigo del ejercicio</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              value={codeExercise}
              onChange={(e) => setCodeExercise(e.target.value)}
            />
          </div>

          <div className="mb-10 col-6">
            <label className="form-label mb-8"></label>
            <select 
              name="exercise"
              value={values.exercise}
              aria-label='Default select example'
              className="form-select"
              onChange={handleChange}
            > 
              <option value={dataExercise?.id}>{dataExercise?.name}</option>
            </select>
            {touched.exercise && errors.exercise && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.exercise}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Instrucción del contenido</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>

          <div className="mb-10 col-6 d-flex flex-column">
            <div>

            </div>
            <div>

            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  </>)
};

export {FormItemEvaluation};