import React, { useEffect } from 'react';
import { OrderSentencesItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseOrderSentencesModule } from './store';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';
 

type Props = {
    userMode?: ModeTypeExercise
};

const OrderSentences: React.FC<Props> = ({ userMode}) => {
    const { mode } = useThemeMode();
    let { 
        questions,
        responseStudent,
        falseAnswer, 
    } = UseOrderSentencesModule();
    
    return (
        <>
            <OrderSentencesItem
                userMode={userMode}
                responses={responseStudent}
                question={questions}
            /> 
        </>
    );
};

export { OrderSentences };