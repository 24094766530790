import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {RoadMapStep, SequenceType, TraditionalLevelEnum} from '../../interfaces'

import { RoadMapStepAdd, RoadMapStepUpdate } from '../../services'
import { UseRoadMapStepModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { UseChapterAll } from '../../../learning-path/modules/chapter/services'
import { ApprovalType, Chapter } from '../../../learning-path/modules/chapter/interfaces'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { useTranslation } from 'react-i18next'

export type FormFields = {
  name: string
  isActive: boolean
  chapter?: string;
  order: number;
  hours: number;
  lessons: number;
  hoursToSee: number;
  level: TraditionalLevelEnum;
  sequence: SequenceType;
  levelToSee: TraditionalLevelEnum;
  finalPresentation: boolean;
  description?:string
  approval: ApprovalType;
}




const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  hours: Yup.number().required('Hours is required'),
  hoursToSee: Yup.number().required('Hours to see is required'),
  lessons: Yup.number().required('Lessons is required'),
  order: Yup.number().required('Order is required')
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const RoadMapStepForm: React.FC<Props>  = (props:  Props) => { // Poner que traiga los capitulos, preguntar si son todos o por alguna validacion <--------
  const {t} = useTranslation();
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseRoadMapStepModule();
  const [data, setData] = useState<RoadMapStep>()
  const [loading, setLoading] = React.useState<boolean>(false);
  const {id} = useParams();
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        chapter: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.chapter.id ?? '' : '',
        order: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.order ?? 0 : 0,
        hours: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hours ?? 0 : 0,
        lessons: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.lessons ?? 0 : 0,
        hoursToSee: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.hoursToSee ?? 0 : 0,
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        sequence: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sequence ?? SequenceType.RANDOM : SequenceType.RANDOM,
        levelToSee: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.levelToSee ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        finalPresentation: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.finalPresentation ?? false : false,
        approval: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.approval ?? ApprovalType.BY_HOUR : ApprovalType.BY_HOUR,
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await RoadMapStepAdd({...formData, roadMap:id??'' })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['road-map-step'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  RoadMapStepUpdate({...formData, roadMap: id??''})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['road-map-step'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

    const [chapters, setChapters] = React.useState<Chapter[]>([])
    const fetchChapters = async () => {
      try {
        let x = await UseChapterAll()
        setChapters(x)
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar Caoitulos!', icon: 'error' })
        throw 'Error'
      }
    }

    useEffect(() => {
      fetchChapters();
    }, [])
  
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-1 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Nombre</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Tipo de aprobación</label>
            <select
              disabled={mode === 'VIEW'}
              name='approval'
              value={values.approval}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ApprovalType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.approval && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.approval}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Nivel</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={values.level}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.sequence && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.sequence}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Secuencia</label>
            <select
              disabled={mode === 'VIEW'}
              name='sequence'
              value={values.sequence}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SequenceType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.sequence && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.sequence}</span>
                </div>
              </div>
            )}
          </div>
          
          <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Unidad</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'chapter'}
                  value={values.chapter}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {chapters.map((x) =>
                    (<option value={x.id}>{x.name.toUpperCase() ?? ''}</option>)
                  )}
                </select>
                {errors.chapter && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.chapter}</span>
                    </div>
                  </div>
                )}
              </div>




          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Orden</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Límite de horas</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'hours'}
              value={values.hours}
              onChange={handleChange}
            />
            {touched.hours && errors.hours && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hours}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Horas mínimas</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'hoursToSee'}
              value={values.hoursToSee}
              onChange={handleChange}
            />
            {touched.hoursToSee && errors.hoursToSee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hoursToSee}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Nivel minimo</label>
            <select
              disabled={mode === 'VIEW'}
              name='levelToSee'
              value={values.levelToSee}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.levelToSee && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.levelToSee}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Tiene Final Presentation?</label>
            <div className="form-check form-switch">
              <input
                name={'finalPresentation'}
                checked={values.finalPresentation}
                value={values.finalPresentation?1:0}
                disabled={mode==='VIEW'}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className="d-flex">
            <hr className="my-auto flex-grow-1"/>
            <div className="px-4">Datos Informativos</div>
            <hr className="my-auto flex-grow-1"/>
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-3'>Lecciones</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'lessons'}
              value={values.lessons}
              onChange={handleChange}
            />
            {touched.lessons && errors.lessons && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.lessons}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-12'>
            <label className='form-label mb-3'>Descripción</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {RoadMapStepForm}
