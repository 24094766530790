import React, { useState } from "react"
import { UseResumeTableTimeTemplate } from "../../services"
import { StatTemplateResumeIE } from "./interfaces"

type Props = {
    id: string
    timer:string
}
const StatTemplateTableTime = ({ id,timer }: Props) => {
    const [resume, setResume] = useState<StatTemplateResumeIE>()
    const [show, setShow] = useState<boolean>()
    const [totalHours, setTotalHours] = useState<number>()
    let findTemplateResume = async () => {
        if (id) {
            let response = await UseResumeTableTimeTemplate(id)
            setResume(response)
        }
    }
    let sumTotalHour = () => {
        if (resume) {
            const totalSum = resume.resultByCampus.reduce((accumulator, currentValue) => {
                return accumulator + parseInt(currentValue.total, 10);
            }, 0);
            setTotalHours(totalSum)
        }

    }
    React.useEffect(() => {
        findTemplateResume();
    }, [timer]);
    React.useEffect(() => {
        sumTotalHour();
    }, [resume]);

    return (
        <div className="card m-10" style={{ width: 320, zIndex: 2 }}>
            <div className="d-grid gap-2">
                <button className="btn btn-primary" type="button" onClick={()=>setShow(!show)}>
                    {show?<i className="fa fa-eye-slash" style={{fontSize:24}} aria-hidden="true"></i>:
                    <i className="fa fa-eye" style={{fontSize:24}} aria-hidden="true"></i>}
                </button>
            </div>
            {show && <>
        
            <div className="card-body">
                <h3 className="">Resumen de la plantilla</h3>
            </div>
            <ol className="list-group list-group-flush ">
                <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Profesores vinculados</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill text-white">{resume?.result_teacher.teacher_count}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Horas Totales a programar</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill text-white">{resume?.result_teacher.teacher_total_hours}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Horas programadas</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill text-white">{totalHours}</span>
                </li>
            </ol>

            <div className="accordion" id="accordionExample">
                {resume?.resultByCampus.map((x, index) => (
                    <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button
                                className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded={index === 0}
                                aria-controls={`collapse${index}`}
                            >
                                {x.campus}
                            </button>
                        </h2>
                        <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body">
                                <ol className="list-group list-group-numbered">
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Profesores vinculados</div>

                                        </div>
                                        <span className="badge text-bg-primary rounded-pill text-white">{x.teacher_attached}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Actividades vinculadas</div>

                                        </div>
                                        <span className="badge text-bg-primary rounded-pill text-white">{x.activity_attached}</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Total</div>

                                        </div>
                                        <span className="badge text-bg-primary rounded-pill text-white">{x.total}</span>
                                    </li>
                                </ol>

                            </div>
                        </div>
                    </div>
                ))}

            </div>
            </>}
        </div>
    )
}

export { StatTemplateTableTime }
