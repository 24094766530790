import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js'
import { useThemeMode } from '../../../../../../../_metronic/partials';

type Props = {
    urlAudio?: string;
};

const ContentAudioRecord: React.FC<Props> = ({ urlAudio }: Props) => {
    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState(0);
    const { mode } = useThemeMode();

    useEffect(() => {
        if (waveformRef.current) {
            // Initialize WaveSurfer
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#3e94ff',
                progressColor: '#3e94ff',
                // Set a bar width
                barWidth: 6,
                // Optionally, specify the spacing between bars
                barGap: 5,
                height: 80,
                // And the bar radius
                barRadius: 1,
                // minPxPerSec: 100, // Usa el estado de zoom para inicializar
                renderFunction: (channels, ctx) => {
                    const { width, height } = ctx.canvas
                    const scale = channels[0].length / width
                    const step = 4

                    ctx.translate(0, height / 2)
                    ctx.strokeStyle = ctx.fillStyle
                    ctx.beginPath()

                    for (let i = 0; i < width; i += step * 2) {
                        const index = Math.floor(i * scale)
                        const value = Math.abs(channels[0][index])
                        let x = i
                        let y = value * height

                        ctx.moveTo(x, 0)
                        ctx.lineTo(x, y)
                        ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
                        ctx.lineTo(x + step, 0)

                        x = x + step
                        y = -y
                        ctx.moveTo(x, 0)
                        ctx.lineTo(x, y)
                        ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
                        ctx.lineTo(x + step, 0)
                    }

                    ctx.stroke()
                    ctx.closePath()
                },
            });

            // Load the audio file
            wavesurfer.current.load(urlAudio ?? '');

            wavesurfer.current.on('play', () => setIsPlaying(true));
            wavesurfer.current.on('pause', () => setIsPlaying(false));
            wavesurfer.current.on('finish', () => setIsPlaying(false));
            wavesurfer.current.on('audioprocess', () => {
                if (wavesurfer.current) setCurrentTime(wavesurfer.current.getCurrentTime());
            });


        }
        return () => wavesurfer.current?.destroy();
    }, [urlAudio]);

    const togglePlayback = () => {
        if (wavesurfer.current) {
            if (wavesurfer.current.isPlaying()) {
                wavesurfer.current.pause();
            } else {
                wavesurfer.current.play();
            }
        }
    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${pad(minutes)}:${pad(seconds)}`;
    };
    const pad = (number) => {
        return number < 10 ? '0' + number : number;
    };
    return (
        <>
       
            <div className="card-media-picture "
                style={
                    mode !== 'dark' ? {

                        // background: `url("https://th.bing.com/th/id/R.a8bcf7848aed1a59bda09b9faf84f29b?rik=FlwMYQWj4bp9Tw&riu=http%3a%2f%2fwww.techenet.com%2fwp-content%2fuploads%2f2014%2f10%2fSapo-flecha-venenoso-2.jpg&ehk=kI%2fKgqZXg0o2ekhJ3sct%2fvulptL9ePp4wK2BW5OlNVk%3d&risl=&pid=ImgRaw&r=0") center center / cover no-repeat`,
                        position: 'relative',
                        overflow: 'hidden',
                    } : {

                        // background: `url("https://th.bing.com/th/id/OIP.iY_CwSPqVbecrqDlqHkzeQHaE9?w=1000&h=669&rs=1&pid=ImgDetMain") center center / cover no-repeat`,
                        position: 'relative',
                        overflow: 'hidden',
                    }
                }>
                <div id="waveform" className='wave-surfer-record' ref={waveformRef}></div>
                <div className='d-flex justify-content-center align-items-center' style={{ width: '100%' }}>
                    {isPlaying === false ? <i className="bi bi-play-circle-fill button-play-audio pointer-cursor" onClick={togglePlayback}>  </i> :
                        <i className="bi bi-pause-circle-fill button-play-audio pointer-cursor" onClick={togglePlayback}>  </i>}
                </div>
                <div className='d-flex justify-content-center align-items-center' style={{ width: '100%' }}>
                    <h1 className=''
                    style={{ color: '#3e94ff' }}
                    >{formatTime(currentTime)}</h1>
                </div>
            </div>

        </>
    );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
    return prevProps.urlAudio === nextProps.urlAudio;
};

export default React.memo(ContentAudioRecord, areEqual);