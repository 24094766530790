import { MovieWritingData } from "../store"

interface Quiz {
    title:string
    questions:MovieWritingData
    instructions:string
}
export interface QuestionHistoryWriting {
    name :string
    order:number
    mode:'WORD'|'IMG'
    answers:Answer[]
 
}

export interface QuestionAndAnswer {
    name :string
    order:number
    answers:Answer[],
    timer:number
    answer:Answer
}

export interface Answer {
    title :string
    order:number
    isTrue:boolean
    root:number
}

export interface Resume {
    step:number
    trueAnswer:Answer[]
    falseAnswer:Answer[]
    list:Answer[], 
    total:number
}


export const questions: MovieWritingData =
    {
        title: "string",
        genre: "string",
        duration: "string",
        mainCharter: "string",
        rating: 0,
        description: "string"
    } 

export const quiz :Quiz={
    title:"Titulo del quiz que va a empezar",
    instructions:"Esta es una instrucion para hacer testing en el quiz que necesito entregar hoy.",
    questions:questions
}