import React from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { ButtonIntroEvaluationItem } from '../../common/button-intro-evaluation-item/buttonIntroEvaluationItem';

type Props = {
    title:string
    instructions:string
    startQuiz:(boolean)=>void
    // exerciseTimer: number
};

const IntroBasicVocabulary: React.FC<Props> = ({ title,instructions,startQuiz }: Props) => {
    const { mode } = useThemeMode();
    const {evaluationLesson, questionsEvaluation} = UseEvaluationCourseModule();
    return (
        <>

            <div className=" card-basic-vocabulary"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                        position: 'relative'
                    }
                }>

                <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#2d30e1',

                    }}>
                    {title}
                </h2>
                <h3 className='poppins-light subtitle-basic-vocabulary text'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#2d30e1',
                    }}

                >
                    <strong style={{ fontWeight: 'bold' }}>{instructions}</strong>
                </h3>
                <ButtonIntroEvaluationItem onNext={startQuiz} exerciseId={questionsEvaluation?.id}/>
                {/* <div
                    className='arrow-right-basic-vocabulary cursor-pointer'
                    style={{ position: 'absolute', cursor:`${exerciseBeforeTime? 'not-allowed' : 'pointer'}`}}
                    >
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)} alt='' />
                    <button
                        type='button'
                        disabled = {exerciseBeforeTime}
                        onClick={()=>startQuiz(true)}
                        title={exerciseBeforeTime?"Vea todo el contenido" : "Next"}
                        className='poppins-light text-cursor-basic-vocabulary-right align-items-end' 
                        style={{ position: 'absolute', border: 'none', backgroundColor: 'transparent', color: `${exerciseBeforeTime? "#c1b7b7" : "#6090cb"}`, cursor:`${exerciseBeforeTime? 'not-allowed' : 'pointer'}`, minWidth: '130px' }}
                    >
                        {currentTimer?
                        (   currentTimer.time !== 0 ? (
                            <span>
                                {`Wait ${currentTimer.time}s`}
                            </span>
                        ) : (
                            <span>
                            next
                        </span>
                        )

                    )
                        :
                        <span>
                            next
                        </span>}
                    </button>
                </div> */}
            </div>
        </>
    );
};

export { IntroBasicVocabulary };