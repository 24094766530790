import {Route, Routes} from 'react-router-dom'
import { StrikesList } from '../components/list'

const StrikesPage = () => (
  <Routes>
      <Route path='/' element={<StrikesList />} />
  </Routes>
)

export {StrikesPage}
