import { TraditionalLevelEnum } from "../../../../../../abroad/road-map-step/interfaces";
import { Exercises } from "../../../../interfaces";

export interface DataPaginateAudioInteractionResponse {
    status:  string;
    message: string;
    data:    DataAudioInteraction;
}

export interface DataAllAudioInteractionResponse {
    status:  string;
    message: string;
    data:    AudioInteraction[];
}

export interface DataAudioInteraction {
    id?: string;
    description:string;
    title:string;
    isActive: boolean;
    htmlContent?: any;
    colorPrimary: string;
    borderColor: string;
    level: TraditionalLevelEnum;
    audio: any;
    createdAt?:string;
    updatedAt?:string;
}

export interface AudioInteraction  {
    id?: string;
    description?:string;
    title:string;
    isActive: boolean;
    htmlContent?: any;
    colorPrimary: string;
    borderColor: string;
    level: TraditionalLevelEnum;
    type: AudioInteractionEnum;
    exercises: any;
    audio: any;
    createdAt?:any;
    updatedAt?:string;
}

export interface Pagination {
    result: AudioInteraction[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}


export enum AudioInteractionEnum {
    READING = 'READING',
    NOW_PRONOUNCE = 'NOW_PRONOUNCE',
    CONVERSATION = 'CONVERSATION',
    IN_A_NUTSHELL = 'IN_A_NUTSHELL',
};