import { TemplateTableTime } from "../../template-table-time/interfaces";

export interface DataPaginateTemplatePusherResponse {
    status:  string;
    message: string;
    data:    DataTemplatePusher;
}

export interface DataAllTemplatePusherResponse {
    status:  string;
    message: string;
    data:    TemplatePusher[];
}

export interface DataTemplatePusher {
    id?: string;
    isActive?: boolean;
    start: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    pushed: boolean;
    mondayStatus: DayPushedType;
    tuesdayStatus: DayPushedType;
    wednesdayStatus: DayPushedType;
    thursdayStatus: DayPushedType;
    fridayStatus: DayPushedType;
    saturdayStatus: DayPushedType;
    template: TemplateTableTime;
    createdAt?:string;
    updatedAt?:string;
}

export interface TemplatePusher  {
    id?: string;
    isActive?: boolean;
    start: string;
    monday?: string;
    tuesday?: string;
    wednesday?: string;
    thursday?: string;
    friday?: string;
    saturday?: string;
    pushed?: boolean;
    mondayStatus?: DayPushedType;
    tuesdayStatus?: DayPushedType;
    wednesdayStatus?: DayPushedType;
    thursdayStatus?: DayPushedType;
    fridayStatus?: DayPushedType;
    saturdayStatus?: DayPushedType;
    template: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: TemplatePusher[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum DayPushedType {
    NO_AVAILABLE = 'NO_AVAILABLE',
    PENDING = 'PENDING',
    PUSHED = 'PUSHED'
}