import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import '../ExerciseTwelve.css';

import { GroupWordResponse, QuestionIE, UseGroupWordsModules } from '../main/main'
import Swal from 'sweetalert2';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { ResetStepByExercises } from '../../utils';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddGroupWordsResponse } from './services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
  responses?: GroupWordResponse[];
  userMode?: ModeTypeExercise
}

const GroupWordsItem: React.FC<Props> = ({ userMode, responses }) => {
  const [groups, setGroups] = useState<GroupWordResponse[]>([]);
  const [clicked, setClicked] = useState(false);
  const [percentage, setPercentage] = React.useState<number | null>(null);
  const [correctTotal, setCorrectTotal] = useState<number>(0); // Declarar correctTotal
  const [total, setTotal] = useState<number>(0); // Declarar total
  const { questions, instruction, exerciseId } = UseGroupWordsModules();
  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    currentItemBook,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
  } = UseBookStudentModule();

  const { modeViewAnswer } = UseModeCourseModule();

  useEffect(() => {
    setGroups(questions.map((x) => ({
      ...x,
      isTrue: false,
      values: x.values.map(() => ({value: '', isTrue: false})),
    })))
  }, [questions])

  // const handleEdit = () => {
  //     setClicked(false);
  //     clearInputValues();
  // };
  const handleCheck = () => {
    let correctAnswers = 0;
    let totalAnswers = 0;
    let emptyFields = false;

    groups.forEach(group => {
      group.values.forEach(item => {
        if (item.value.trim() === '') {
          emptyFields = true;
        }
      });
    });

    if (emptyFields) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '¡Debe ingresar todos los campos!',
      });
      return;
    }

    const updatedGroups = groups.map((group, groupIndex) => {
      const correctValues = [...questions[groupIndex].values].map(v => v.toLowerCase());

      let groupIsTrue = true;

      const updatedValues = group.values.map((value) => {
        const correctIndex = correctValues.indexOf(value.value.toLowerCase());
        const isCorrect = correctIndex !== -1;
        if (isCorrect) {
          correctValues.splice(correctIndex, 1);
        } else {
          groupIsTrue = false;
        }
        totalAnswers++;
        if (isCorrect) correctAnswers++;
        return { value: value.value, isTrue: isCorrect };
      });

      return {
        ...group,
        values: updatedValues,
        isTrue: groupIsTrue,
      };
    });

    setGroups(updatedGroups);

    const percentage = (correctAnswers / totalAnswers) * 100;
    setPercentage(percentage);
    setClicked(true);
  };

  const handleInputChange = (groupIndex: number, valueIndex: number, newValue: string) => {
    const updatedGroups = [...groups];
    updatedGroups[groupIndex].values[valueIndex].value = newValue;
    setGroups(updatedGroups);
  };

  React.useEffect(() => {
    if (responses) {
      setGroups(responses);
    }
  }, [responses]);

  React.useEffect(() => {
    if (responses && groups) {
      handleCheck();
    }
  }, [groups, responses]);


  const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '525px',
    minWidth: '500px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 20px 20px 20px'
  };

  const handleSubmit = async () => {
    // ResetStepByExercises();
    if (lessonData && lessonData.evaluation &&
      lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(exerciseId ?? "")
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId ?? '')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    }
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        const response = await AddGroupWordsResponse({
          isActive: true,
          exercises: exerciseId ?? '',
          percentage: percentage ? percentage : 0,
          task: currentTaskId ?? '',
          answers: groups
        });
        onChangeTaskByAnswer(response, 'group_word');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
      }
    }
  }

  const resetExercise = () => {
    setPercentage(0);
    setCorrectTotal(0);
    setTotal(0);
    setClicked(false);
  }

  const { setOffDraggable } = UseBookContainerModule()

  return (<>
    <div className="container " style={{ }}>
      <div style={divStyle}>
        <HeaderCard />
        <p className="scroll-y overflow-y-auto text-white  poppins-light fs-8 " style={{ marginLeft: '8px', maxHeight:'60px'}}>{instruction}</p>
        <table className="table table-responsive table-rounded-table table-sm">
          <thead>
            <tr className="poppins-bold">
              {groups.map((group, i) => (
                <th key={i} className='text-center bg-light table-rounded-table-th-one fs-8' style={{ width: '200px', color: '#3F8BFF' }}>
                  {group.name ?? ''}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-center text-white poppins-light fs-9'>
          <tr>
              {groups.map((group, groupIndex) => (
                <td key={groupIndex} className='border border-top-0' style={{ borderRadius: '0px 0px 0px 13px', border: '2px solid #ffffff', paddingTop: '6px' }}>
                  {group.values.map((item, valueIndex) => (
                    <input
                      key={valueIndex}
                      type="text"
                      style={{
                        color: 'rgb(0, 0, 168)',
                        width: '68px',
                        borderRadius: '50px',
                        border: '0px',
                        outline: 'none',
                        background: clicked ? item.isTrue ? '#D0FF00' : '#d65353' :'#D0FF00',
                        marginRight: '3px'
                      }}
                      className='mb-1 text-center fs-9 poppins-bold'
                      value={modeViewAnswer ? item.value : group.values[valueIndex].value}
                      onChange={(e) => handleInputChange(groupIndex, valueIndex, e.target.value)}
                      onMouseEnter={()=> setOffDraggable(true)}
                      onMouseLeave={()=> setOffDraggable(false)}
                      disabled={clicked}
                    />
                  ))}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className='mb-4'>
          <span className={`animate__animated text-white fs-8 poppins-light  ${percentage ? 'animate__bounce' : 'd-none'}`}>Porcentaje de aciertos: {percentage !== null ? `${percentage.toFixed(1)}%` : 'Calculando...'}</span><br />
          {/* <span style={{ marginBottom: '40px' }} className={`animate__animated text-white fs-8 poppins-light  ${percentage ? 'animate__bounce' : 'd-none'}`}>{`Acertaste ${correctTotal}/${total}`}</span> */}
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-end'
        style={{
          position: 'absolute',
          bottom: '5%',
          right: '9%'
        }}
      >
        <ButtonSaveExercise
          isChecked={clicked}
          onNext={handleCheck}
          onSubmit={handleSubmit}
          exerciseId={exerciseId}
        />
      </div>
      {!clicked ?
        <></>
        // <div className="position-relative" onClick={(e) => {
        //     e.preventDefault();
        //     // if (!clicked) {
        //         handleCheck()
        //     // } else {
        //         // handleSubmit();
        //     // }
        // }}>
        //     <div className="position-absolute bottom-0 end-0" style={{ marginRight: '20px', marginBottom: '12px' }}>
        //         <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
        //     </div>
        // </div> 
        :
        <>
          {/* <div
                    className='arrow-right-next-sessions cursor-pointer'
                    style={{ position: 'absolute', left:400 }}
                    onClick={handleSubmit}
                >
                    <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_session.svg`)} alt='' />
                </div> */}
          <div style={{ position: 'absolute', left: 50, bottom: 10 }}>
            <ButtonTryAgain onAction={resetExercise} />
          </div>

        </>
      }
      {/* <div className="position-relative" onClick={(e) => {
                                e.preventDefault();
                                handleEdit();
                                
                            }}> 
                    <div className="position-absolute bottom-0 end-0" style={{ marginRight: '90px', marginBottom: '12px' }}>
                        <img className="" src={toAbsoluteUrl('/media/svg/michigan-icons/ebook/next_btn1.svg')} style={{ width: '40px', height: '40px' }} alt="Next" />
                    </div>
            </div> */}

    </div>
  </>)
};
export { GroupWordsItem }