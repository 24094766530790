import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { Coordinates, CoordinatesColumIE, CoordinatesRowIE } from '../../interfaces'
import { CoordinatesAdd, CoordinatesUpdate } from '../../services'
import { UseCoordinatesModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { useTranslation } from 'react-i18next'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'

export type FormFields = {
  name: string
  isActive: boolean
  description?: string
  row: CoordinatesRowIE[];
  column: CoordinatesColumIE[];
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const CoordinatesForm: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseCoordinatesModule();
  const [row, setRow] = useState<CoordinatesRowIE>({
    idWord: '1',
    title: '',
    value: 'a'
  });
  const [column, setColumn] = useState<CoordinatesColumIE>({
    title: '',
    value: 'a'
  });

  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        row: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.row.map(x => JSON.parse(x)) ?? [] : [],
        column: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.column.map(x => JSON.parse(x)) ?? [] : []
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await CoordinatesAdd({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['coordinates'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await CoordinatesUpdate({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['coordinates'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });

  const addRow = () => {
    if (row.title === '') return;
    setFieldValue('row', [
      ...values.row,
      {
        title: row.title,
        idWord: `${values.row.length + 1}`,
        value: row.value
      }]
    );
    setRow({
      idWord: '1',
      title: '',
      value: 'a'
    });
  };

  const addColumn = () => {
    const findValue = values.column.find(x => x.value === column.value);
    if (findValue)
      return showSimpleAlert({ message: 'No se pueden repetir el valor de la columna', icon: 'error' });
    setFieldValue('column', [
      ...values.column,
      {
        title: column.title,
        value: getAlphabetLetter(values.column.length)
      }]
    );
    setColumn({
      title: '',
      value: 'a'
    });
  };

  const getAlphabetLetter = (index: number): string => {
    return String.fromCharCode(65 + index);
  };

  const deleteColumn = (value: string) => {
    const newValues = values.column.filter(x => x.title !== value);
    setFieldValue('column', newValues);
  };

  const deleteRow = (value: string) => {
    const newValues = values.row.filter(x => x.idWord !== value);
    setFieldValue('row', newValues);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Agregar Columna</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'column'}
              value={column.title}
              onChange={(e) => setColumn({ ...column, title: e.target.value })}
            />

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addColumn} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Agregar Fila</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'row'}
              value={row.title}
              onChange={(e) => setRow({...row, title: e.target.value})}
            />

            <div className='mb-2'>
              <label className='form-label mb-0'>Respuesta</label>
              <select
                name='row'
                className='form-select'
                aria-label='Default select example'
                value={row.value}
                onChange={(e) => setRow(({...row, value: e.target.value}))}
                disabled={mode === 'VIEW'}
              >
                <option value=''></option>
                {values.column && values.column.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.title}
                  </option>
                ))}
              </select>
            </div>
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addRow} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-12'>
            <label className='form-label mb-3 fw-bold'>Table</label>
            <div className="table-responsive rounded-4 border border-2 border-white overflow-auto">
              <table className={`table-bordered border-white p-0`} style={{ width: 'auto' }}>
                <colgroup>
                  <col style={{ width: '70px' }} />
                </colgroup>
                <thead>
                  <tr className="poppins-bold fs-9">
                    <th></th>
                    {values.column && values.column.map(({ title }, index) => (
                      <th
                        style={{ background: '#D0FF00', width: '80px' }}
                        className="text-primary text-center bg-light cursor-pointer"
                        key={title}
                        onClick={() => deleteColumn(title)}
                      >
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center text-white poppins-light fs-9">
                  {values.row.map(({ idWord, title }, rowIndex) => (
                    <tr className='td-size' key={idWord}>
                      <td className='text-primary poppins-bold p-1 cursor-pointer' style={{ 
                        background: '#D0FF00', 
                        width: '80px' 
                      }}
                      onClick={() => deleteRow(idWord)}
                      
                      >{title}</td>
                      {values.column.map(({ title: columnTitle, value: columnValue }, columnIndex) => (
                        <td
                          key={columnValue}
                          id={`${idWord}-${columnValue}`}
                        >
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { CoordinatesForm }
