import React from 'react';
import moment from 'moment';
import { UseAppointmentModule } from '../../../appointment/store/main';
import useAuth from '../../../../../api/main';
import { TodayDashboard } from '../../../../dashboard/components/teacher/elements/today';
import { DayPickerSelector } from '../../../../../components/common/DayPicker';
import { ClassListTeacherDashboard } from '../forms';
import { useParams } from 'react-router-dom';
import { StudentSettingsOneByUser } from '../../../student-actions/promotion-history/services';
import { UseGetOneUserByID } from '../services';
type Props = {

}
const StudentAttendanceTableList: React.FC<Props> = ({ }: Props) => {
  
    const {id} = useParams()
    const [teacher, setTeacher] = React.useState<any>()

    const getTeacher = async()=>{
        const response = await UseGetOneUserByID(id ?? '')
        setTeacher(response)
    }

    React.useEffect(()=>{
        if(id){
            getTeacher()
        }
    },[id])
    
    const {
        after,
        before,
        setDate
    } = UseAppointmentModule();
    let { profile } = useAuth();
    return (<>
        <div className="card">
            <div className="card-body">
                <div className='row'>
                    <div className='col-12 '>
                        {teacher &&
                            <a href={'#'} className={`card text-white  mb-xl-8`} style={{ background: '#2d30e1', borderRadius: 30 }}>
                                <div className='card-body'>
                                    <div className={`champ-light fw-semibold text-white`} style={{ fontSize: 40 }}>
                                        {teacher.firstName} {teacher.lastName}'s 
                                    </div>
                                    <div className={`champ-bold fw-semibold text-white`} style={{ fontSize: 25 }}>
                                        Dashboard
                                    </div>
                                </div>
                            </a>
                        }
                    </div>
                    <div className='col-3 d-none d-sm-none d-xs-none d-md-none d-lg-block'>
                        <TodayDashboard />
                        <DayPickerSelector day={moment(before).toDate()} 
                            changeDate={(e) =>
                                setDate({
                                    after: e,
                                    before: e
                                })
                            } 
                    />
                    </div>
                    <div className='col-6 d-xl-none d-lg-none my-3 '>
                        <div className="input-group pl-4 pr-4">
                            <label className="input-group-text champ-light">Date</label>
                            <input className='form-select'
                                value={moment(before).format('YYYY-MM-DD')}
                                onChange={(e) =>
                                    setDate({
                                        after: moment(e.target.value).toDate(),
                                        before: moment(e.target.value).toDate()
                                    })
                                } 
                                type="date"
                            />
                        </div>
                    </div>
                    <div className="col ">
                        <ClassListTeacherDashboard typeUser='TEACHER' />
                    </div>
                </div>
            </div>
        </div>
    </>)
};

export { StudentAttendanceTableList }