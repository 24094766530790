import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Activity} from '../../interfaces'

import { ActivityAdd, ActivityUpdate } from '../../services'
import { UseActivityModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useStepById } from '../../hooks/useActivityRepo'
import { UseLessonByChapter } from '../../../learning-path/modules/lessons/services'
import { Lessons } from '../../../learning-path/modules/lessons/interfaces'
import Multiselect from 'multiselect-react-dropdown'
import { extractIDs } from '../../../../../components/common/ExcelExport/utils'
import { Workshop } from '../../../learning-path/modules/workshop/interfaces'
import { UseWorkshopAllPaginate, WorkshopAll } from '../../../learning-path/modules/workshop/services'

export type FormFields = {
 
  name: string
  order: number
  hourUnity: number
  toWorkshop: boolean
  roadMap?: any
  chapter?: any
  lessons: any[]
  limit?: number;
  isActive: boolean
  description?:string;
  workshop?: string;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  order: Yup.number().required('Order is required'),
  hourUnity: Yup.number().required('Hours is required'),
  toWorkshop: Yup.boolean().required('To workshop name is required'),
  isActive: Yup.boolean().required('Is Active name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW',
}
const ActivityForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient();
  let { id } = useParams();
  const { data, isLoading } = useStepById(id??'');
  const {modal,itemSelected, mode,levelStep,resetData,setSelectedToEdit,setSelectedToView} = UseActivityModule();
  const [loading, setLoading] = React.useState<boolean>(false)
  const [lessons, setLessons] = React.useState<Lessons[]>([])
  const [workshop, setWorkshop] = React.useState<Workshop[]>([]);

  const findLessonByIdLessons =async (id:string)=>{
   let lessons= await UseLessonByChapter(id, 1000);
  //  console.log('les', lessons)
   setLessons(lessons?.result)
  }
  useEffect(() => {
    if(data && data.chapter){
       findLessonByIdLessons(data.chapter.id)
    }
  }, [data])
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        chapter: (mode==='EDIT' || mode==='VIEW')? itemSelected?.chapter?.id??'': '',
        roadMap: (mode==='EDIT' || mode==='VIEW')? itemSelected?.roadMap?.id??'': '',
        lessons: (mode==='EDIT' || mode==='VIEW')? itemSelected?.lessons??[]: [],
        hourUnity: (mode==='EDIT' || mode==='VIEW')? itemSelected?.hourUnity??1: 1,
        order: (mode==='EDIT' || mode==='VIEW')? itemSelected?.order??1: 1,
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        toWorkshop:(mode==='EDIT' || mode==='VIEW')? itemSelected?.toWorkshop??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
        workshop: (mode==='EDIT' || mode==='VIEW')? itemSelected?.workshop?.id??'':'',
        limit: (mode==='EDIT' || mode==='VIEW')? itemSelected?.limit??0:0
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await ActivityAdd({
                ...formData,
                chapter:data.chapter.id,
                roadMap:data.id,
                toWorkshop: false,
                workshop: undefined,
                lessons:values.lessons ? extractIDs(values?.lessons) : []
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['activity'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  ActivityUpdate({
                ...formData,
                chapter:data.chapter.id,
                roadMap:data.id,
                workshop: undefined,
                lessons:values.lessons ? extractIDs(values?.lessons) : []
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['activity'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })

    React.useEffect(() => {
      if (values.toWorkshop) {
        setFieldValue('chapter', undefined);
        setFieldValue('lessons', []);
      };
      if (!values.toWorkshop) {
        setFieldValue('workshop', undefined);
      }
    }, [values.toWorkshop]);

    // React.useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const data = await UseWorkshopAllPaginate(levelStep);
    //       if (data && data?.result) {
    //         setWorkshop(data.result);
    //       }
    //     } catch (err) {
    //       console.error('Error al obtener los datos: ', err);
    //     }
    //   };
    //   fetchData();
    // }, [levelStep])
   
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className=' col-4'>
            <label className='form-label my-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className=' col-4'>
            <label className='form-label mb-3'>Workshop ?</label>
            <div className="form-check form-switch">
              <input
                name={'toWorkshop'}
                checked={values.toWorkshop}
                value={values.toWorkshop?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div> */}
          <div className=' col-12'>
            <label className='form-label my-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className=' col-6'>
            <label className='form-label my-3'>Orden</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>

          <div className=' col-6'>
            <label className='form-label my-3'>Duración de la clase en horas</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'hourUnity'}
              value={values.hourUnity}
              onChange={handleChange}
            />
            {touched.hourUnity && errors.hourUnity && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.hourUnity}</span>
                </div>
              </div>
            )}
          </div>
          <div className=' col-6'>
            <label className='form-label my-3'>Límite de estudiantes por clase</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'limit'}
              value={values.limit}
              onChange={handleChange}
            />
            {touched.limit && errors.limit && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.limit}</span>
                </div>
              </div>
            )}
          </div>

          {/* {values.toWorkshop && 
           <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Workshop</label>
            <select
              name='workshop'
              className='form-select'
              aria-label='Default select example'
              onChange={handleChange}
              value={values.workshop}
            >
              <option value='' selected>
                Seleccione un Workshop
              </option>
              {workshop?.map((x, i) => (
                <option value={x.id} key={i}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
          } */}

          
            <div className='mb-1 col-12'>
              <label className='form-label my-3'>Chapter</label>
              <Multiselect
                options={lessons} // Options to display in the dropdown
                selectedValues={values.lessons} // Preselected value to persist in dropdown
                onSelect={(x)=>setFieldValue('lessons',x)} // Function will trigger on select event
                onRemove={(x)=>setFieldValue('lessons',x)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </div> 

          <div className=' col-12'>
            <label className='form-label my-3'>Descripción</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {ActivityForm}
