import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { CountryPage } from '../modules/admin/country/pages'
import { UserPage } from '../modules/admin/user/pages'
import { MasterClassLevelPage } from '../modules/admin/master-class-level/pages'
import { AppointmentPage } from '../modules/admin/appointment/pages'
import { ClassroomPage } from '../modules/admin/classroom/pages'
import { BookingDashboardPage } from '../modules/booking-dashboard/pages'
import { LeadsPage } from '../modules/admin/leads/pages'
import { LeadSourcePage } from '../modules/admin/lead-source/pages'
import { ResumePage } from '../modules/resume/pages'
import { CustomDashboard } from '../modules/dashboard/pages'
import { RoadMapPage } from '../modules/abroad/road-map/pages'
import { RoadMapStepPage } from '../modules/abroad/road-map-step/pages'
import { LearningPathPage } from '../modules/abroad/learning-path/pages'
import { CampusPage } from '../modules/abroad/campus/pages'
import { BlockHourPage } from '../modules/abroad/block-hours/pages'
import { ActivityPage } from '../modules/abroad/activity/pages'
import { WorkshopPage } from '../modules/abroad/learning-path/modules/workshop/pages'
import { TemplateTableTimePage } from '../modules/abroad/template-table-time/pages'
import { TemplatePusherPage } from '../modules/abroad/template-pusher/pages'
import { SettingsPage } from '../modules/abroad/settings/pages'
import { CategoryPage } from '../modules/abroad/category/pages'
import { FaqPage } from '../modules/abroad/faq/pages'
import { SupportTicketPage } from '../modules/abroad/support-ticket/pages'
import { TrailSuspenseReasonPage } from '../modules/abroad/trail/trail-suspense-reason/pages'
import { TransferClassPage } from '../modules/admin/transfer-class/pages'
import { TransferStudentPage } from '../modules/admin/transfer-student/pages'
import { TrailSuspensePage } from '../modules/admin/trail-suspense/pages'
import { MeReportPage } from '../modules/me-report/pages'
import { LobbyClassPage } from '../modules/lobby/pages'
import { TrailFreezingPage } from '../modules/admin/trail-freezing/pages'
import { MeBookingActivePage } from '../modules/me-booking-active/pages'
import { AvatarPage } from '../modules/admin/avatar/pages'
import { MyPaymentsPage } from '../modules/student/my-payments/pages'
import { CertificatePage } from '../modules/student/certificate/pages'
import { StrikesPage } from '../modules/me-report/modules/strikes/pages'
import { AdvertisementsPage } from '../modules/admin/advertisements/pages'
import { NotificationGlobalPage } from '../modules/notifications/pages'
import { EnglishExplorersPage } from '../modules/courses/english-explorers/page'
import { PrismaticPage } from '../modules/courses/prismatic/page'
import { PrismaPage } from '../modules/courses/prismatics/page'
import { CoursesPage } from '../modules/courses/page'
import { ExercisesPage } from '../modules/admin/exercises/pages'
import { EvaluationPage } from '../modules/admin/evaluation/pages'
import { ParagraphDragDrop } from '../modules/courses/exercises/paragraph-drag-drop'
import { TestZonePage } from '../modules/test-zone/pages'
import { FeatureInterfacePage } from '../modules/abroad/feature-interface'
import { HistoryWritingResponsePage } from '../modules/admin/history-writing-response/pages'
import { MovieWritingResponsePage } from '../modules/admin/movie-writing-response/pages'
import { BookContainerPage } from '../modules/book/pages'
import { BookPage } from '../modules/admin/book/pages'
import { AutoVerificationPage } from '../modules/auto-verification/pages'
import { FileContentCoursePage } from '../modules/admin/file-content-course/pages'
import { StudentActionsPage } from '../modules/admin/student-actions/pages'
import { StudentAttendancePage } from '../modules/admin/student-attendance/pages'
import { PlacementTestPage } from '../modules/admin/placement-test/pages'
import { VerificationQuestionPage } from '../modules/admin/verification-question/pages'
import { QualifyPage } from '../modules/admin/qualify/pages'
import { ChatAbroadPage } from '../modules/chat/pages'
import { ResolutionCertificatePage } from '../modules/admin/resolution-certificate/pages'
import { CertificateItemPage } from '../modules/admin/certificate-item/pages'
import { ReContractPage } from '../modules/admin/re-contract/pages'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='dashboard' element={<CustomDashboard />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        <Route
          path='country/*'
          element={
            <SuspensedView>
              <CountryPage />
            </SuspensedView>
          }
        />

        <Route
          path='chat/*'
          element={
            <SuspensedView>
              <ChatAbroadPage />
            </SuspensedView>
          }
        />

        <Route
          path='avatar/*'
          element={
            <SuspensedView>
              <AvatarPage />
            </SuspensedView>
          }
        />


      <Route
          path='re-contract/*'
          element={
            <SuspensedView>
              <ReContractPage />
            </SuspensedView>
          }
        />

        <Route
          path='qualify/*'
          element={
            <SuspensedView>
              <QualifyPage />
            </SuspensedView>
          }
        />

        <Route
          path='placement/*'
          element={
            <SuspensedView>
              <PlacementTestPage />
            </SuspensedView>
          }
        />

        <Route
          path='auto-verification/*'
          element={
            <SuspensedView>
              <AutoVerificationPage />
            </SuspensedView>
          }
        />

      <Route
          path='book/*'
          element={
            <SuspensedView>
              <BookContainerPage />
            </SuspensedView>
          }
        />

        <Route
          path='verification-question/*'
          element={
            <SuspensedView>
              <VerificationQuestionPage />
            </SuspensedView>
          }
        />
        

        <Route
          path='evaluation/*'
          element={
            <SuspensedView>
              <EvaluationPage />
            </SuspensedView>
          }
        />

      <Route
          path='file-content/*'
          element={
            <SuspensedView>
              <FileContentCoursePage />
            </SuspensedView>
          }
        />

        <Route
          path='abroad-interface/*'
          element={
            <SuspensedView>
              <FeatureInterfacePage />
            </SuspensedView>
          }
        />

        <Route
          path='exercises/*'
          element={
            <SuspensedView>
              <ExercisesPage />
            </SuspensedView>
          }
        />

        <Route
          path='student-actions/*'
          element={
            <SuspensedView>
              <StudentActionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='writing-response/*'
          element={
            <SuspensedView>
              <HistoryWritingResponsePage />
            </SuspensedView>
          }
        />

        <Route
          path='movie-response/*'
          element={
            <SuspensedView>
              <MovieWritingResponsePage />
            </SuspensedView>
          }
        />

        <Route
          path='book-admin/*'
          element={
            <SuspensedView>
              <BookPage />
            </SuspensedView>
          }
        />

        <Route
          path='resolution-certificate/*'
          element={
            <SuspensedView>
              <ResolutionCertificatePage />
            </SuspensedView>
          }
        />

        <Route
          path='certificate-item/*'
          element={
            <SuspensedView>
              <CertificateItemPage />
            </SuspensedView>
          }
        />




        <Route
          path='test-zone/*'
          element={
            <SuspensedView>
              <TestZonePage />
            </SuspensedView>
          }
        />

        <Route
          path='english-explorers/*'
          element={
            <SuspensedView>
              <EnglishExplorersPage />
            </SuspensedView>
          }
        />

        <Route
          path='prismatic/*'
          element={
            <SuspensedView>
              <PrismaticPage />
            </SuspensedView>
          }
        />

        <Route
          path='prismatics/*'
          element={
            <SuspensedView>
              <PrismaPage />
            </SuspensedView>
          }
        />

        <Route
          path='courses/*'
          element={
            <SuspensedView>
              <CoursesPage />
            </SuspensedView>
          }
        />


        <Route
          path='strikes/*'
          element={
            <SuspensedView>
              <StrikesPage />
            </SuspensedView>
          }
        />

        <Route
          path='notifications/*'
          element={
            <SuspensedView>
              <NotificationGlobalPage />
            </SuspensedView>
          }
        />

        <Route
          path='advertisements/*'
          element={
            <SuspensedView>
              <AdvertisementsPage />
            </SuspensedView>
          }
        />



        {/* abroad booking */}
        <Route
          path='road-map/*'
          element={
            <SuspensedView>
              <RoadMapPage />
            </SuspensedView>
          }
        />

        <Route
          path='me-booking/*'
          element={
            <SuspensedView>
              <MeBookingActivePage />
            </SuspensedView>
          }
        />

        <Route
          path='road-map-step/*'
          element={
            <SuspensedView>
              <RoadMapStepPage />
            </SuspensedView>
          }
        />

        <Route
          path='lobby-class/*'
          element={
            <SuspensedView>
              <LobbyClassPage />
            </SuspensedView>
          }
        />
        {/* abroad booking */}

        {/* Learning Path */}
        <Route
          path='learning-path/*'
          element={
            <SuspensedView>
              <LearningPathPage />
            </SuspensedView>
          }
        />

        <Route
          path='activity/*'
          element={
            <SuspensedView>
              <ActivityPage />
            </SuspensedView>
          }
        />
        {/* End Learning Path */}
        <Route
          path='campus/*'
          element={
            <SuspensedView>
              <CampusPage />
            </SuspensedView>
          }
        />

        <Route
          path='payments/*'
          element={
            <SuspensedView>
              <MyPaymentsPage />
            </SuspensedView>
          }
        />

        <Route
          path='certificate/*'
          element={
            <SuspensedView>
              <CertificatePage />
            </SuspensedView>
          }
        />
        
        <Route
          path='workshop/*'
          element={
            <SuspensedView>
              <WorkshopPage />
            </SuspensedView>
          }
        />

        <Route
          path='template-table-time/*'
          element={
            <SuspensedView>
              <TemplateTableTimePage />
            </SuspensedView>
          }
        />

        <Route
          path='admin-suspense/*'
          element={
            <SuspensedView>
              <TrailSuspensePage />
            </SuspensedView>
          }
        />
        
        
        {/* abroad booking */}

        <Route
          path='table-time/*'
          element={
            <SuspensedView>
              <BlockHourPage />
            </SuspensedView>
          }
        />

        <Route
          path='template-pusher/*'
          element={
            <SuspensedView>
              <TemplatePusherPage />
            </SuspensedView>
          }
        />

        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        <Route
          path='category/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='faq/*'
          element={
            <SuspensedView>
              <FaqPage />
            </SuspensedView>
          }
        />

        <Route
          path='support-ticket/*'
          element={
            <SuspensedView>
              <SupportTicketPage />
            </SuspensedView>
          }
        />

        <Route
          path='trail-attendance/*'
          element={
            <SuspensedView>
              < StudentAttendancePage/>
            </SuspensedView>
          }
        />

        <Route
          path='trail-suspense-reason/*'
          element={
            <SuspensedView>
              <TrailSuspenseReasonPage />
            </SuspensedView>
          }
        />

        <Route
          path='trail-freezing/*'
          element={
            <SuspensedView>
              <TrailFreezingPage />
            </SuspensedView>
          }
        />

        <Route
          path='transfer-class/*'
          element={
            <SuspensedView>
              <TransferClassPage />
            </SuspensedView>
          }
        />

        <Route
          path='transfer-student/*'
          element={
            <SuspensedView>
              <TransferStudentPage />
            </SuspensedView>
          }
        />

        <Route
          path='resume/*'
          element={
            <SuspensedView>
              <ResumePage />
            </SuspensedView>
          }
        />

        <Route
          path='booking-dashboard/*'
          element={
            <SuspensedView>
              <BookingDashboardPage />
            </SuspensedView>
          }
        />

        <Route
          path='lead-source/*'
          element={
            <SuspensedView>
              <LeadSourcePage />
            </SuspensedView>
          }
        />

        <Route
          path='leads/*'
          element={
            <SuspensedView>
              <LeadsPage />
            </SuspensedView>
          }
        />


        {/* <Route
          path='me-booking/*'
          element={
            <SuspensedView>
              <MeBookingPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='me-resume/*'
          element={
            <SuspensedView>
              <MeReportPage />
            </SuspensedView>
          }
        />

        <Route
          path='classroom/*'
          element={
            <SuspensedView>
              <ClassroomPage />
            </SuspensedView>
          }
        />
        <Route
          path='appointment/*'
          element={
            <SuspensedView>
              <AppointmentPage />
            </SuspensedView>
          }
        />


        <Route
          path='master-class-level/*'
          element={
            <SuspensedView>
              <MasterClassLevelPage />
            </SuspensedView>
          }
        />

        <Route
          path='user/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
