import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { api } from "../../../../auth/services/auth.services";
import { AnswerDragDrop } from "../interfaces";

const { token, logout, authChecked } = useAuth.getState();

export interface TypeAddDragDropResponse {
    percentage: number;
    description?: string;
    isActive: boolean;
    true_answer: AnswerDragDrop;
    exercises: string;
    task: string;
}

export const AddDragDropResponse = async (data: TypeAddDragDropResponse): Promise<any> => {
    try {
      const response: any = await api.post(
        '/drag-drop-response',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }