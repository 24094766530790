import React from 'react'
import {UseEvaluationCourseModule} from '../../screen/store/main'
import {EvaluationType} from '../../../admin/evaluation/interfaces'
import {useTranslation} from 'react-i18next'
import './style.css'

const FeedbackResponses: React.FC = () => {
  const {evaluationLesson, evaluationResponses} = UseEvaluationCourseModule()
  const {t} = useTranslation()

  const isWelcomeDay = evaluationLesson?.type === EvaluationType.WELCOME_DAY
  return (
    <div className='d-flex flex-wrap flex-row'>
      {evaluationResponses &&
        evaluationResponses.map((exercise, index) => (
          <div key={index} className='d-flex flex-column m-2' style={{flex: '1 1 80%'}}>
            <div className='pl-3'>
              <div className='champ-bold mb-3'>{index + 1 + '. ' + exercise?.exercise_title}</div>
              <ol style={{listStyleType: 'none', paddingLeft: '20px'}}>
                {exercise?.isDragDropExercise ? (
                  <div className='d-flex align-middle mb-2'>
                    <i
                      className={`bi ${
                        isWelcomeDay
                          ? ''
                          : exercise.isDragDropExercise.isPercentageMin
                          ? 'bi-check2 mt-2'
                          : 'bi-x-lg'
                      } me-3`}
                      style={{
                        color: isWelcomeDay
                          ? '#3e94ff'
                          : exercise.isDragDropExercise.isPercentageMin
                          ? 'green'
                          : 'red',
                        transform: 'scale(2)',
                      }}
                    />
                    <span
                      className='d-flex justify-content-center align-middle rounded-circle champ-bold'
                      style={{
                        width: '24px',
                        height: '24px',
                        backgroundColor: '#3e94ff',
                        color: 'white',
                        marginRight: '8px',
                        fontSize: '14px',
                        minWidth: '24px',
                      }}
                    >
                      a
                    </span>
                    <div className='poppins-light'>
                      {Math.round(exercise.isDragDropExercise.percentage)} %
                    </div>
                  </div>
                ) : (
                  <>
                    {exercise.responses ? (
                      exercise.responses.map((response, responseIndex) => (
                        <li key={responseIndex} className='row justify-content-start my-5'>
                          <div className='col-6'>
                            <div className='row  justify-content-start align-items-center'>
                              <i
                                className={`col-1 me-5 bi ${
                                  isWelcomeDay
                                    ? ''
                                    : response.isCorrect
                                    ? 'bi-check2 mt-2'
                                    : 'bi-x-lg'
                                } me-1`}
                                style={{
                                  color: isWelcomeDay
                                    ? '#3e94ff'
                                    : response.isCorrect
                                    ? 'green'
                                    : 'red',
                                  transform: 'scale(2)',
                                }}
                              />
                              <span
                                className='col-1 ms-1 d-flex justify-content-center align-items-center rounded-circle champ-bold'
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  backgroundColor: '#3e94ff',
                                  color: 'white',
                                  fontSize: '14px',
                                  minWidth: '24px',
                                }}
                              >
                                {String.fromCharCode(97 + responseIndex)}
                              </span>
                              <div className='col-10 justify-content-between align-middle'>
                                <h5
                                  className='poppins pt-2 me-5 align-content-center'
                                  style={{fontSize: '13px'}}
                                >
                                  {t(response.value)}
                                </h5>
                              </div>  
                            </div>
                          </div>
                          <div className='col-6'>
                            <div className='row align-items-center'>
                                <div className='truncate-3-lines'>
                                  {response.question ?? 'No Question'}
                              </div>
                            </div>
                          </div>
                          {!response.isCorrect && !isWelcomeDay && response.feedback && (
                            <div className='row w-100 ps-3 bg-danger-subtle rounded' style={{color: 'red'}}>
                              {response.feedback}
                            </div>
                          )}
                        </li>
                      ))
                    ) : (
                      <p className='row w-100 mt-1 ms-5 ps-3 text-dark-emphasis'>
                        <u>No trae respuestas</u>
                      </p>
                    )}
                  </>
                )}
              </ol>
            </div>
          </div>
        ))}
    </div>
  )
}

export default FeedbackResponses
