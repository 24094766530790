import React from 'react';
import '../index.css'
import MyX from '../img/EQUIS_btn1.svg';
import NexBtnThow from '../ui/EquisBtnOne';
import HeaderCard from '../ui/HeaderCard';
import MyNext from '../img/NEXT_btn 1.svg';
   type Props = {
       
   }
const ExerciseThree: React.FC<Props> = ({}) => {
    const divStyle = {
        backgroundColor: '#3f8bff',
        width: '525px',
        height: '290px',
        borderRadius: '30px',
        borderColor: 'rgb(255, 255, 255)',
        borderStyle: 'solid',
        padding: '27px 20px 20px 20px'
      };
    const arrayLetters = [
        "Mr and Mrs Gates are from USA.",
        "The Gates like helping people.",
        "The Gates live in Medina, Washington.",
        "Melissa isn't wife."
    ];

return(<>
        <div className="container-fluid" style={{display: 'flex'}}>
           <div style={divStyle}>
           <HeaderCard/>
                 <div className=" text-white cuestion mb-0 poppins-light" style={{marginLeft:'10px'}}>{}2. Read the following statements and choose tru (T),False (F),or Not Given (NG) based on the text.
                 </div>
                <div className="d-flex text-white p-2 content">
                    <div className='row w-100 px-5'>
                        {arrayLetters.map((x) => (
                            <>
                            <div className='col-8 poppins-light fs-8'style={{}} >
                                <li style={{marginBottom:'5px'}}>{x}</li> 
                            </div>
                            <div className='col-4 d-flex'>
                                    
                                    <button type='button' 
                                        className="btn-success btn px-0 py-0 rounded rounded-circle bg-success text-white"
                                        style={{fontSize: '10px', marginBottom:'3px', border: '1px solid #FFFFFF',height:'20px',width:'20px'}}>
                                            T
                                    </button>
                                  
                            </div></>
                        ))}
                    </div>
                </div>
            </div>
            <div className="position-relative ">
                <div className="position-absolute bottom-0 end-0 "style={{marginRight:'20px',marginBottom:'12px'}}> 
                <img className="" src={MyNext} style={{ width: '40px', height: '40px'}}/></div>
           </div>
           <img className="" src={MyX} style={{ width: '40px', height: '40px' }}></img>
       </div>
  </>)
};
export {ExerciseThree}