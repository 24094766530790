import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import { FaqAdd, FaqUpdate } from '../../services'
import { UseFaqModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { Category } from '../../../category/interfaces'
import { UseFaqCategoryAll } from '../../modules/faq-category/services'

export type FormFields = {
  question: string
  answer: string
  order: number;
  isActive: boolean
  category:string
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const FaqForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseFaqModule();
  const [faqCategoryData, setFaqCategoryData] = useState<Category[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        question: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.question ?? '' : '',
        answer: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.answer ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        category: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.category.id ?? '' : '',
        order: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.order ?? 0  : 0
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await FaqAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['faq'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  FaqUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['faq'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const fetchCategory = async () => {
      try {
        const faqCategoryResponse = await UseFaqCategoryAll();
        setFaqCategoryData(faqCategoryResponse);
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontrar Categorias!', icon: 'error' })
        throw 'Error'
      }
    };

    React.useEffect(() => {
      fetchCategory();
    }, []);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Pregunta</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'question'}
              value={values.question}
              onChange={handleChange}
            />
            {touched.question && errors.question && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.question}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Order</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Respuesta</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'answer'}
              value={values.answer}
              onChange={handleChange}
            />
            {touched.answer && errors.answer && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.answer}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Tipo de Categoria</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'category'}
                  value={values.category}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {faqCategoryData.map((x) =>
                    (<option value={x.id}>{x.name}</option>)
                  )}
                </select>
                {errors.category && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.category}</span>
                    </div>
                  </div>
                )}
              </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {FaqForm}
