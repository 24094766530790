import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { UseEvaluationCourseModule } from '../../../screen/store/main';

type Props = {
    title:string
    instructions:string
    startQuiz:(boolean)=>void
};

const IntroBasicVocabulary: React.FC<Props> = ({ title,instructions,startQuiz }: Props) => {
    const { mode } = useThemeMode();
    const {evaluationLesson} = UseEvaluationCourseModule();

    return (
        <>

            <div className=" card-basic-vocabulary"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                        position: 'relative'
                    }
                }>
                <h2 className='champ-light title-basic-vocabulary-resume text mb-5D'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',

                    }}>
                    {title}
                </h2>
                <h3 className='poppins-light subtitle-basic-vocabulary text'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#3e94ff',
                    }}
                >
                    <strong style={{ fontWeight: 'bold' }}>{instructions}</strong>
                </h3>
                <>
                    <div
                        className='arrow-right-basic-vocabulary cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={()=>startQuiz(true)}
                    >
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)} alt='' />
                        <button type='button'
                        className='btn poppins-light text-cursor-basic-vocabulary-right' style={{ position: 'absolute', color: "blue" }}> next</button>
                    </div>
                </>
            </div>

        </>
    );
};

export { IntroBasicVocabulary };