import React from 'react';
import * as XLSX from 'xlsx';
import { Lead } from '../../../modules/booking-dashboard/interfaces';
import moment from 'moment';
import { attendedParser, interestParser, levelParse, originParser } from './utils';

type Props = {
    dataExcel: Array<Lead>;
    filename: string;
    sheetName: string;
    theadName: Array<string>;
    // moreSheet: boolean;
}
const ExcelDocument: React.FC<Props> = ({dataExcel, filename, sheetName, theadName}: Props) => {

    const copyData =  JSON.parse(JSON.stringify(dataExcel));

    const modified = copyData.map(item => {
        item.attended = attendedParser(item.attended)
        item.interest = interestParser(item.interest)
        item.level    = levelParse(item.level)
        item.source    = originParser(item.source)
        item.createdAt = moment(item.createdAt).format('DD/MM/YYYY')
        item.updatedAt = moment(item.updatedAt??moment()).format('DD/MM/YYYY')
        //@ts-ignore
        item.isActive  = item.isActive?'Activo':'Inactivo'
        return item;
    });

    const dataArray = modified.map(obj => Object.values(obj));
    const exportToExcel = () => {
        const sheetsData: Array<{
          name: string;
          data: Array<Array<any>>;
        }> = [
          {
            name: sheetName,
            data: [[...theadName], 
            ...dataArray],
          }
        ];
        const wb = XLSX.utils.book_new();
        sheetsData.forEach((sheet) => {
          const ws = XLSX.utils.aoa_to_sheet(sheet.data);
          XLSX.utils.book_append_sheet(wb, ws, sheet.name);
        });
    
        XLSX.writeFile(wb, `${filename}.xlsx`);
      };
      

    return (

        <div className='pt-5'>
          <button onClick={exportToExcel} className={`btn btn-primary w-100 py-3 champ-bold`}>
            Exportar a Excel
          </button>
        </div>

    )
}

export default ExcelDocument;