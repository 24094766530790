import { toast } from 'react-toastify';
import Swal from 'sweetalert2'

export interface AlertInterface {
    message:string
    icon:'warning'|'error'|'success'|'info'
}

 
 export  const showSimpleAlert = ({icon,message}:AlertInterface) => {
  if(icon==='error'){
    toast.error(message,
      {
       position: toast.POSITION.BOTTOM_RIGHT
      }  
    )
  }
  if(icon==='warning'){
    toast.warning(message,
      {
        position: toast.POSITION.BOTTOM_RIGHT
      }    
    )
  }
  if(icon==='success'){
    toast.success(message,
      {
        position: toast.POSITION.BOTTOM_RIGHT
      }   
    )
  }
  if(icon==='info'){
    toast.info(message,
      {
        position: toast.POSITION.BOTTOM_RIGHT
      }     
    )
  }
 };
  
 
  