import {Route, Routes} from 'react-router-dom'
import { MovieWritingResponseDetails } from '../components/details'
import { MovieWritingResponseTableList } from '../components/list'


const MovieWritingResponsePage = () => (
  <Routes>
      <Route path='/details' element={<MovieWritingResponseDetails />} />
      <Route path='/' element={<MovieWritingResponseTableList />} />
  </Routes>
)

export {MovieWritingResponsePage}
