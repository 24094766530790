import { TraditionalLevelEnum } from "../../../../../abroad/road-map-step/interfaces";
import { Exercises } from "../../../interfaces";

export interface DataPaginateHistoryWritingResponse {
    status:  string;
    message: string;
    data:    DataHistoryWriting;
}

export interface DataAllHistoryWritingResponse {
    status:  string;
    message: string;
    data:    HistoryWriting[];
}

export interface DataHistoryWriting {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    level: TraditionalLevelEnum;
    questions: QuestionHistoryWritingAdd[];
    limit: number;
    minimum: number
    exercises: string; 
    createdAt?:string;
    updatedAt?:string;
}

export interface HistoryWriting  {
    id?: string;
    description?:string;
    name:string;
    isActive: boolean;
    level: TraditionalLevelEnum;
    questions: QuestionHistoryWritingAdd[] | string[];
    exercises: Exercises; 
    limit: number;
    minimum: number
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: HistoryWriting[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface QuestionHistoryWritingAdd {
    name :string
    order:number
    mode: ModeQuestionHistoryWriting;
    items:ItemsHistoryAdd[]
 
}

export enum ModeQuestionHistoryWriting {
    WORD = 'WORD',
    IMG = 'IMG'
}

export interface ItemsHistoryAdd {
    title :string
    order:number
    isTrue:boolean
}