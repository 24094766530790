import { EvaluationType } from "../../admin/evaluation/interfaces";
import { UseEvaluationCourseModule } from "../screen/store/main";

const useIsImportantEvaluation = (): boolean => {
    const {
        evaluationLesson, 
    } = UseEvaluationCourseModule();

    if(!evaluationLesson) return false;
    const isImportantQuiz = evaluationLesson?.type === EvaluationType.FINAL_PRESENTATION || evaluationLesson?.type === EvaluationType.PLACEMENT_TEST || evaluationLesson?.type === EvaluationType.QUIZ || evaluationLesson?.type === EvaluationType.WELCOME_DAY

    // const isNotImportantQuiz = evaluationLesson?.type !== EvaluationType.PLACEMENT_TEST && evaluationLesson?.type !== EvaluationType.FINAL_PRESENTATION && evaluationLesson?.type !== EvaluationType.QUIZ  && evaluationLesson?.type !== EvaluationType.WELCOME_DAY
    return isImportantQuiz;
};
export default useIsImportantEvaluation;