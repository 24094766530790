import {Route, Routes} from 'react-router-dom'
import { PlacementTestDetails } from '../components/details'
import { PlacementTestTableList } from '../components/list'


const PlacementTestPage = () => (
  <Routes>
      <Route path='/details' element={<PlacementTestDetails />} />
      <Route path='/' element={<PlacementTestTableList />} />
  </Routes>
)

export {PlacementTestPage}
