/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {UserForm} from '../forms'
import {UseUserModule} from '../../store/main'
import { setTypeToSpanish } from '../../utils/setTypeToSpanish'
import { StudentSettingsForm } from '../forms/student/StudentSettingForm'
import { ChatLinkedComponent } from '../actions/chat-linked'
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ActionsUserModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const {modal, mode,setModal,resetData,modeActions,setActionsModal} = UseUserModule();
  const { type } = UseUserModule()
  const submit = () => {
    window.location.reload()
  }



  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>

        {modeActions}
        
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setActionsModal(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <ChatLinkedComponent />
    </Modal>,
    modalsRoot
  )
}

export {ActionsUserModal}
