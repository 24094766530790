import React from 'react'
import FinalFeedbackContent from './rightContent'

type Props = {
  colorSecondary: string
  renderType?: 'left' | 'right'
}

const FinalEvaluationStats: React.FC<Props> = ({
  colorSecondary,
  renderType
}) => {
  return (
    <>
      {renderType && renderType === 'left' ? (
        <>
          <h3
            style={{fontSize: 30, paddingLeft: 30, paddingTop: 20}}
            className='champ-light text-white'
          >
            Feedback de tu PLACEMENT TEST
          </h3>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center', // Centra los elementos horizontalmente
              gap: '10px', // Espaciado entre elementos
            }}
          ></div>
        </>
      ) : (
        <FinalFeedbackContent/>
      )}

      <style>
        {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
      </style>
    </>
  )
}

export default FinalEvaluationStats
