/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { TemplateTableTimeItemForm } from '../forms'
import { KTSVG } from '../../../../../../../../_metronic/helpers'
import { UseTemplateTableTimeItemModule } from '../../store/main'
import { useTemplateTableTimeItemStateTeacher } from '../../hooks/useTemplateTableTimeItemRepo'
import GenericPaginator from '../../../../../../../components/common/Paginator'
import { useQueryClient } from '@tanstack/react-query'
import { setTypeToSpanish } from '../../../../../../admin/user/utils/setTypeToSpanish'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ViewModal = ({show, handleClose}: Props) => {
  const queryClient = useQueryClient();

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const {
    dayList,
    activity,
    paginatorForm, 
    limitForm,
    currentPageForm,
    resetPaginator,
    setModal,
    resetData,
    setLimitForm,
    setCurrentPageForm,
    setTabList
  } = UseTemplateTableTimeItemModule();
  const submit = () => {
    window.location.reload()
  }

  const { data, isLoading } = useTemplateTableTimeItemStateTeacher();
  
  const changePage = (number: number) => {
    setCurrentPageForm(number)
  };
  const changeLimit =(number:number)=>{
      setLimitForm(number)
  }

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['template-table-time-item-teacher'] });
  }, [dayList, limitForm, currentPageForm, activity])

  return createPortal(
    <Modal
    id='kt_modal_create_app'
    tabIndex={1}
    aria-hidden='true'
    dialogClassName='modal-dialog modal-dialog-centered modal-xl'
    show={show}
    backdrop={true}
  >
    <div className='modal-header'>
      <h2>Ver Item</h2>
      {/* begin::Close */}
      <div
        className='btn btn-sm btn-icon btn-active-color-primary'
        onClick={() => {
          setModal(false);
          resetData();
          setTabList('ACTIVITY');
          resetPaginator();
        }}
      >
        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
      </div>
      {/* end::Close */}
    </div>
    <div className='modal-body d-flex justify-content-center'>
      <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-120px'>Teacher</th>
            <th className='min-w-120px'>Day</th>
            <th className='min-w-120px'>Activity</th>
            <th className='min-w-120px'>Classroom</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.result?.map((x, i) => {
              const blockHours = x.blockHour.map((hour) => `${hour.start} - ${hour.end}`);
              return (
                <tr key={i}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {x?.teacher?.fullName}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {x?.teacher?.email}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {setTypeToSpanish(x?.day)}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {x?.activity?.name}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {blockHours.join(', ')}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-semibold d-block fs-7'>{x.classroom.name}</span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
    <div className='d-flex justify-content-center mt-4'>
      <GenericPaginator
        limit={limitForm}
        currentPage={paginatorForm.current_page}
        items={paginatorForm.result.length}
        total={paginatorForm.total}
        totalPage={paginatorForm.total_page}
        changePage={changePage}
        changeLimit={changeLimit}
      />
    </div>
    {/* <TemplateTableTimeItemForm mode={mode} /> */}
  </Modal>,
    modalsRoot
  )
}

export {ViewModal}
