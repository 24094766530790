import React, { useEffect } from 'react'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useChapterState } from '../../hooks/useChapterRepo'
import { UseChapterModule } from '../../store/main'
import { Chapter } from '../../interfaces'
import * as Swal from 'sweetalert2'
import { ChapterDelete } from '../../services'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../../../_metronic/helpers'
import { BooleanCell } from '../../../../../../../components/common/BooleanCell'
import GenericPaginator from '../../../../../../../components/common/Paginator'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { LearningPathHeader } from '../../../../LearningPathHeader'
import { setTypeToSpanish } from '../../../../../../admin/user/utils/setTypeToSpanish'
import { useTranslation } from 'react-i18next'

type PaginatorSetter ={
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const ChapterTableList: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const { 
      paginator, 
      modal,
      limit,
      currentPage,
      isActive,
      where,
      setCurrentPage,
      setLimit, 
      setIsActive, 
      setSelectedToEdit, 
      setSelectedToView,
      setModal,
      setWhere,
      setToAdd
  } = UseChapterModule()

  const {id} = useParams();
  const { data, isLoading } = useChapterState(id??'');

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage:1,
    items:0,
    total:0,
    totalPage:0
});

  const deleteItem = async (id:string)=>{
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        try{
          await ChapterDelete(id)
          showSimpleAlert({message:'Ok!',icon:'success'})
          queryClient.invalidateQueries({ queryKey: ['chapter'] })
        }catch(e){
            
         }
      }
    })
  }
  const changePage = (number: number) => {
      setCurrentPage(number)
  };
  const changeLimit =(number:number)=>{
      setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['chapter'] })
  }, [isActive,where,currentPage,limit])


  return (<>

    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{'Nivel'}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{'Administración de niveles'}</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >

          {/* <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div> */}
          <button
            onClick={() => setToAdd()}
            className='btn btn-sm btn-light-primary mr-8'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
          </button>
          <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
             <BooleanCell value={isActive} />
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        {!isLoading && data && <div className='table-responsive'>
          {/* begin::Table */}

          <div className={`card`}>
            <div className='card-body pt-3'>
              {isLoading && (
                <div className='symbol symbol-60px symbol-2by3 me-4'>
                  <div className='symbol-label' style={{ backgroundImage: `url(${toAbsoluteUrl('/media/svg/icons/loading.svg')})` }}></div>
                </div>
              )}
              {data?.result?.map((x, index) => (
                <div key={index} className='d-flex align-items-sm-center mb-7'>
                  <div className='symbol symbol-60px symbol-2by3 me-4'>
                    <div className='symbol-label' style={{ backgroundImage: `url(${toAbsoluteUrl('/media/svg/icons/earth.svg')})` }}></div>
                  </div>
                  <div className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                    <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                      <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                        {x.name}
                      </a>
                      <span className='text-muted fw-semibold d-block pt-1'>{x.description}</span>
                    </div>
                    <div className='d-flex flex-row-fluid align-items-center flex-wrap justify-content-center'>
                      <div className='text-center'>
                        <div className='mb-1'>{t('SEQUENCE')}: {setTypeToSpanish(x?.sequence)}</div>
                        <div className='mb-1'>{t('LEVEL')}: {t(x?.level)}</div>
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <button className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => setSelectedToView(x)}>
                        <KTIcon iconName='eye' className='fs-2 text-dark' />
                      </button>
                      <button className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => setSelectedToEdit(x)}>
                        <KTIcon iconName='pencil' className='fs-2 text-primary' />
                      </button>
                      <button className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => deleteItem(x.id??'xxx')}>
                        <KTIcon iconName='trash' className='fs-2 text-danger' />
                      </button>
                      <a href={`/learning-path/lesson/${x.id}`} className='btn btn-icon btn-light btn-sm border-0 me-1'>
                        <KTIcon iconName='arrow-right' className='fs-2 text-primary' />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          
              <GenericPaginator 
                  limit={limit}
                  currentPage={paginator.current_page}
                  items={paginator.result.length}
                  total={paginator.total}
                  totalPage={paginator.total_page}
                  changePage={changePage}
                  changeLimit={changeLimit}
              />
          {/* end::Table */}
        </div>}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
  </>
  )
}

export { ChapterTableList }
