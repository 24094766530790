import React from 'react';
import { UseHistoryWritingModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';
import { HistoryWritingItem } from './components/history-item';
import { HistoryWritingExerciseItem } from './components/item';

type Props = {
    titleContent: string;
    descriptionContent: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
};

const HistoryWritingFullExercise: React.FC<Props> = ({ renderType, titleContent, descriptionContent, colorSecondary }) => {
    let {
        questionFull,
        setStep,
        step,
        setAnswer,
        status,
        setStatus,
        startQuiz,
    } = UseHistoryWritingModule();
    return (
        <>
            {renderType && renderType === 'left' ?
                <div className='d-flex justify-content-center align-items-start flex-column w-100 h-100'>
                    {questionFull[step]?.questions.map((x)=>(
                        <HistoryWritingItem questions={x} />
                    ))}
                </div>
            : <>
                {status !== 'RESUME' && status !== 'TITLE' &&
                    <HistoryWritingExerciseItem
                        step={step}
                        question={questionFull[step]}
                        handleSendAnswer={(e) => setAnswer(e)}
                        totalQuestions={questionFull.length}
                        setStep={(e) => setStep(e)}
                    />
                }
                {status === 'RESUME' &&
                    <BasicVocabularyExerciseResume
                        total={questionFull.length}
                        correct={questionFull.length}
                        incorrect={0}
                        setStatus={(e) => setStatus(e)}
                        correctAnswer={[]}
                        falseAnswer={[]}
                    />}
                {status === 'TITLE' &&
                    <IntroBasicVocabulary
                        title={titleContent}
                        instructions={descriptionContent}
                        startQuiz={() => startQuiz()}
                    />
                }
            </>}

            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>

        </>
    );
};

export default React.memo(HistoryWritingFullExercise);