// FillTheGaps.tsx
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FillTheGapsProps } from './types';
import Word from './Word';
import Gap from './Gap';
import './index.css';
import { RightCardContent } from '../../../../components/common/right-card-content/indext';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { UseParagraphDragDropModule } from './store';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import { UseModeCourseModule } from '../../components/front-page/store/main';
import { ResetStepByExercises } from '../utils';
import { AddDragDropResponse } from './services';
import useAuth from '../../../../api/main';
import { validationPercentageObtained } from '../../utils/validatePercentage';
import useIsImportantEvaluation from '../../hooks/useIsImportantEvaluation';
import { ButtonNextFullScreen } from '../../utils/buttonNextFullScreen';

const FillTheGaps: React.FC<FillTheGapsProps> = ({ showContent, renderType, sentence, words, colorSecondary }) => {

  const { typeUser } = useAuth();
  let {
    setSentencesConstructed, allWords ,setWords, percentage
  } = UseParagraphDragDropModule();
  const {
    questionsEvaluation,
    currentTaskId,
    setEvaluationResponses
  } = UseEvaluationCourseModule();
  
  const { isPercentageMin } = UseModeCourseModule();
  const [usedWords, setUsedWords] = useState<{ [key: string]: boolean }>({});
  const [filledGaps, setFilledGaps] = useState<{ [key: string]: string }>({});
  const [resetFlag, setResetFlag] = useState(0);
  const [gapsCorrectness, setGapsCorrectness] = useState<{ [key: string]: boolean }>({});
  const [hasChecked, setHasChecked] = useState(false);
  const [showWords, setShowWords] = useState(true);
  const handleFillGap = (gapId: string, wordId: string) => {
    setFilledGaps((prevFilledGaps) => ({
      ...prevFilledGaps,
      [gapId]: wordId,
    }));
    setUsedWords((prevUsedWords) => ({
      ...prevUsedWords,
      [wordId]: true,
    }));
    setSentencesConstructed(getUserConstructedSentence())
  
  };
  
  const isImportantQuiz = useIsImportantEvaluation();
  
  function calculateAccuracyPercentage(totalQuestions: number, correctAnswers: number): number {
    if (totalQuestions > 0) {
      const percentage: number = (correctAnswers / totalQuestions) * 100;
      return percentage;
    } else {
      return 0;
    }
  }
  
  const checkSentence = () => {
  
    const totalGaps = sentence.reduce((acc, sent) => acc + (sent.match(/\{.*?\}/g) || []).length, 0);
    
    // if (totalGaps > Object.keys(filledGaps).length) {
    //   showSimpleAlert({ message: 'Error! Please fill all the gaps before checking.', icon: 'error' });
    //   return;
    // }
    
    setHasChecked(true);
    let _trueWords: string[] = [];
    let _falseWords: string[] = [];
    let _allWords: string[] = [];
    const newGapsCorrectness = Object.keys(filledGaps).reduce((acc, gapId) => {
      _allWords.push(gapId)
      // alert(gapId)
      const wordId = filledGaps[gapId];
      const wordItem = words.find(word => word.id === wordId);
      const isCorrect = wordItem ? wordItem.text === gapId : false;
      if (isCorrect) {
        _trueWords.push(gapId)
      }
      if (isCorrect === false) {
        _falseWords.push(gapId)
      }
      acc[gapId] = isCorrect;
      return acc;
    }, {} as { [key: string]: boolean });
    
    setGapsCorrectness(newGapsCorrectness);
    const isOverallCorrect = Object.values(newGapsCorrectness).every(value => value);
    // alert(isOverallCorrect ? 'Correct!' : 'Incorrect, try again.');
    let totalPercentage = calculateAccuracyPercentage(totalGaps, _trueWords.length)
    if(!isImportantQuiz){
      validationPercentageObtained(questionsEvaluation?.minPercentage ?? 50, totalPercentage);
    }
    
    setWords({
      percentage: totalPercentage,
      allWords: {
        filledGaps,
        usedWords
      },
      hasChecked: true
    })
  };
  
  const resetExercise = () => {
    setHasChecked(false)
    setFilledGaps({});
    setUsedWords({});
    setResetFlag(flag => flag + 1);
  };
  
  const handleRemoveWord = (gapId: string, wordId: string) => {
    setFilledGaps((prev) => {
      const newState = { ...prev };
      delete newState[gapId];
      return newState;
    });
    setUsedWords((prev) => {
      const newState = { ...prev };
      delete newState[wordId];
      return newState;
    });
  };
  
  const getUserConstructedSentence = () => {
    return sentence.map((sent) => {
      const parts = sent.split(/(\{.*?\})/).filter(part => part);
      const completedParts = parts.map((part) => {
        if (part.startsWith('{') && part.endsWith('}')) {
          const gapId = part.replace(/[{}]/g, '');
          const wordId = filledGaps[gapId];
          const wordItem = words.find(word => word.id === wordId);
          return wordItem ? wordItem.text : part;
        }
        return part;
      });
      return completedParts.join('');
    }).join(' ');
  };
  
  const handleSubmit = async () => {
    
    showContent && showContent(undefined)
    if(!isImportantQuiz){
      if (!isPercentageMin) return;
    }
    setEvaluationResponses({
      percentage: percentage,
      exercise_title:  questionsEvaluation?.exercise.name ?? "Titulo ejercicio",
      responses:[],
      isDragDropExercise: {
        sentences: sentence,
        responses: [''],
        percentage: percentage,
        isPercentageMin: isPercentageMin
      }
    })
    
    ResetStepByExercises();
    if (typeUser === 'STUDENT' && currentTaskId && questionsEvaluation?.exercise) {
      try {
        await AddDragDropResponse({
          percentage: percentage,
          description: '',
          isActive: true,
          true_answer: allWords,
          exercises: questionsEvaluation?.exercise?.id ?? 'xx',
          task: currentTaskId ?? 'xx'
        });
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta.', icon: 'error' });
        console.error(e);
      }
    }
  };

  React.useEffect(() => {
    showContent && showContent(showWords)
  }, [showWords])

  return (<>
    <DndProvider backend={HTML5Backend}>
      {renderType && renderType === 'left' ? <>
        <button
          onClick={() => { { setShowWords(!showWords) } }}
          style={{
            margin: '-60px 30px',
            padding: '10px 10px 4px 10px',
            backgroundColor: 'transparent', // Fondo transparente
            border: '1px solid white', // Borde blanco
            color: 'white', // Texto blanco
            borderRadius: '30px',
            fontSize: 15
          }} className='btn champ-light button-change-content'>
          {showWords ? 'Content' : 'Words'}
        </button>

        {showWords &&
          <>
            <h3
              style={{ fontSize: 30, paddingLeft: 20, paddingTop: 20 }}
              className='champ-light text-white'>Options</h3>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center', // Centra los elementos horizontalmente
              gap: '10px', // Espaciado entre elementos
              maxHeight: '320px',
              overflowY: 'auto'
            }}>
              {words.map((word) => (
                <Word key={word.id} word={word} isUsed={!!usedWords[word.id]} />
              ))}
            </div>
          </>
        }
      </> : <>
        <RightCardContent>
          <div style={{  
            maxHeight: '300px',
            overflowY: 'auto'
          }}>
  {sentence.map((sentence, sIndex) => (
            <div className='poppins-light' key={sIndex}
              style={{
                color: '#a700ba',
                fontSize: 18,
              }}>
              {sentence.split(/(\{.*?\})/).filter(part => part).map((part, pIndex) => {
                // Comprobación para partes que deben ir en negrita
                const boldPattern = /.+:\s+/; // Busca cualquier texto seguido de dos puntos y un espacio
                const matchResult = part.match(boldPattern); // Almacena el resultado de match

                if (matchResult) { // Verifica si matchResult no es null
                  const boldPart = matchResult[0]; // Asume que hay al menos una coincidencia
                  const restOfPart = part.substring(boldPart.length);

                  return (
                    <span key={`${sIndex}-${pIndex}`} >
                      <strong>{boldPart}</strong>{restOfPart}
                    </span>
                  );
                } else if (part.startsWith('{') && part.endsWith('}')) {
                  // Manejo de los huecos
                  const gapId = part.replace(/[{}]/g, '');
                  return <Gap key={`${sIndex}-${pIndex}`}
                    display='inline-block'
                    size={14}
                    gap={{ id: gapId, correctWordId: gapId }}
                    resetFlag={resetFlag}
                    colorString='#a700ba'
                    reviewMode={false}
                    onFill={handleFillGap}
                    onRemove={handleRemoveWord}
                    isCorrect={gapsCorrectness[gapId]}
                    hasChecked={hasChecked}
                  />;
                } else {
                  // Partes de la oración que no requieren tratamiento especial
                  return <span key={`${sIndex}-${pIndex}`}>{part}</span>;
                }
              })}
            </div>
          ))}
          {(hasChecked && !isImportantQuiz) &&
            <h2
              className='  arrow-left-basic-vocabulary cursor-pointer poppins-light'
              onClick={() => resetExercise()}
              style={{
                position: 'absolute',
                color: '#3e94ff', // Texto blanco
                fontSize: 16,
                marginBottom: 10,
                whiteSpace: 'nowrap'
              }}
            >
              Try again
            </h2>
          }
          </div>
          
          {hasChecked ?
          (isImportantQuiz || isPercentageMin) && 
            <ButtonNextFullScreen onAction={handleSubmit}/>
            :
            <div
              className='arrow-right-basic-vocabulary cursor-pointer'
              style={{ position: 'absolute' }}
              onClick={() => checkSentence()}
            >
              <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
            </div>
          }
        </RightCardContent>
      </>}

      <style>
        {`.card-with-extended-background::before {
          background-color: ${colorSecondary};
        }`}
      </style>

    </DndProvider>
  </>);
};

export default React.memo(FillTheGaps);
