export interface DataPaginateTransferClassResponse {
    status:  string;
    message: string;
    data:    DataTransferClass;
}

export interface DataAllTransferClassResponse {
    status:  string;
    message: string;
    data:    TransferClass[];
}

export interface DataTransferClass {
    id?: string;
    description:string;
    appointmentArray: any[];
    teacherOrigin:any;
    teacherDestiny:any;
    date:string;
    reasonCancellation: ReasonCancellation; 
    // isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface TransferClass  {
    id?: string;
    description?:string;
    appointmentArray: any[];
    teacherOrigin:any;
    teacherDestiny:any;
    date:string;
    reasonCancellation: ReasonCancellation; 
    // isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: TransferClass[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum ReasonCancellation {
    PAID_LICENSE= 'PAID_LICENSE', //lic no renumerada
    WITHOUT_JUST_CAUSE = 'WITHOUT_JUST_CAUSE', // sin justa causa
    COMMON_DISEASE = 'COMMON_DISEASE', //enfermedad comun
    MEDICAL_APPOINTMENT = 'MEDICAL_APPOINTMENT' // cita medica
}