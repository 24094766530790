/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { LeadsForm } from '../forms'
import { KTSVG } from '../../../../../_metronic/helpers'
import { UseBookingDashboard } from '../../store'
//import {KTSVG} from '../../../../../../_metronic/helpers'
//import {AppointmentForm} from '../forms'
//import {UseAppointmentModule} from '../../store/main'
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const LeadsModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [hasError, setHasError] = useState(false)
  const { setModal} = UseBookingDashboard();
  const submit = () => {
    window.location.reload()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>

        { <h2 className='champ-bold'>Edit</h2>}

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>setModal(false)}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
        <LeadsForm />

     
    </Modal>,
    modalsRoot
  )
}

export {LeadsModal}
