import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {Crossword} from '../../interfaces'
import { CrosswordAdd, CrosswordUpdate } from '../../services'
import { UseCrosswordModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../../../abroad/road-map-step/interfaces'
import { CellCrossword, ClueCrossword } from '../../../../../../../../components/abroad/e-book/beyong-the-reading/ExerciseTwo/store/main'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type DirectionClueType = 'across' | 'down';

export type FormFields = {
  name: string
  isActive: boolean
  rows: number;
  sentences: ClueCrossword[];
  level: TraditionalLevelEnum;
  cols: number;
  description:string
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  cols: Yup.number().min(0).max(20),
  rows: Yup.number().min(0).max(20),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const CrosswordForm: React.FC<Props>  = (props:  Props) => {
  const {id} = useParams();
  const {t} = useTranslation();
  const queryClient = useQueryClient()
  const {itemSelected, mode,resetData} = UseCrosswordModule();

  const [initialGrid, setInitialGrid] = React.useState<CellCrossword[][]>([]);
  const [dataClues, setDataClues] = React.useState<ClueCrossword>({
    clueNumber: 1,
    direction: 'down',
    startRow: 1,
    startCol: 1,
    answer: '',
    clueText: ''
  });
  const [loading, setLoading] = React.useState<boolean>(false);

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        rows: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.rows ?? 8 : 8,
        sentences: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sentences.map(x => JSON.parse(x)) ?? [] : [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        cols: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.cols ?? 8 : 8
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await CrosswordAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['crossword'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  CrosswordUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['crossword'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    
  React.useEffect(() => {
    createInitialGrid(values.rows, values.cols);
  }, [values.cols, values.rows]);

  React.useEffect(() => {
    if (values.cols !== 0 && values.rows !== 0) {
      const grid = createInitialGrid(values.rows, values.cols);
      updateGridWithClues(grid, values.sentences);
    }
  }, [values.sentences, values.cols, values.rows]);

  const updateGridWithClues = (initialGrid: CellCrossword[][], initialClues: ClueCrossword[]) => {
    const updatedGrid = JSON.parse(JSON.stringify(initialGrid));
      
    initialClues.forEach(clue => {
      const { direction, startRow, startCol, answer, clueNumber } = clue;
      let row = startRow;
      let col = startCol;
  
      if (direction === 'down' && startRow > 0) {
        updatedGrid[startRow - 1][startCol].value = clueNumber;
        updatedGrid[startRow - 1][startCol].isBlack = false;
      } else if (direction === 'across' && startCol > 0) {
        updatedGrid[startRow][startCol - 1].value = clueNumber;
        updatedGrid[startRow][startCol - 1].isBlack = false;
      }
  
      for (let i = 0; i < answer.length; i++) {
        if (direction === 'across' && col + i < updatedGrid[row].length) {
          updatedGrid[row][col + i].isBlack = false;
          updatedGrid[row][col + i].value = answer[i];
        } else if (direction === 'down' && row + i < updatedGrid.length) {
          updatedGrid[row + i][col].isBlack = false;
          updatedGrid[row + i][col].value = answer[i];
        }
      }
    });
  
    setInitialGrid(updatedGrid);
    return updatedGrid;
  };

  const createInitialGrid = (numRows: number, numCols: number) => {
    const initialGrid:CellCrossword[][] = [];
    for (let i = 0; i < numRows; i++) {
      const row:CellCrossword[] = [];
      for (let j = 0; j < numCols; j++) {
        row.push({ value: null, isBlack: false });
      }
      initialGrid.push(row);
    }
    setInitialGrid(initialGrid);
    return initialGrid;
  };

  const handleAddClue = () => {
    setFieldValue('sentences', [
      ...values.sentences, 
      {...dataClues, clueNumber: values.sentences.length + 1}
    ]);
  
    const updatedGrid = [...initialGrid];
  
    const newClue = dataClues;
    
    const directionOffset = newClue.direction === 'across' ? { row: 0, col: 1 } : { row: 1, col: 0 };
  
    for (let i = 0; i < newClue.answer.length; i++) {
      const row = newClue.startRow + directionOffset.row * i;
      const col = newClue.startCol + directionOffset.col * i;
      updatedGrid[row][col].value = newClue.answer[i];
    }
  
    setInitialGrid(updatedGrid);
    
    setDataClues({
      clueNumber: 1,
      direction: 'down',
      startRow: 1,
      startCol: 1,
      answer: '',
      clueText: ''
    });
  };

  const deleteSentence = (clueNumber: number) => {
    const deleteSentence = values.sentences.filter(x => x.clueNumber !== clueNumber);
    setFieldValue('sentences', deleteSentence);
    updateGridWithClues(initialGrid, deleteSentence);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='mb-2 form-label'>Numero de Columnas</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name='cols'
              // disabled={mode==='VIEW'}
              value={Math.min(Math.max(0, values.cols), 20)}
              onChange={handleChange}
            />
          </div>
          <div className='mb-10 col-6'>
            <label className='mb-2 form-label'>Numero de Filas</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              name='rows'
              disabled={mode==='VIEW'}
              value={Math.min(Math.max(0, values.rows), 20)}
              onChange={handleChange}
            />
          </div>

          <div className='mb-10 col-6 row'>
            <label className='form-label mb-3 fw-bold'>Palabras</label>
            <div className='mb-2 col-12'>
              <label className='form-label mb-2'>Palabra</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataClues.answer}
                onChange={(e) => setDataClues(prev => ({...prev, answer: e.target.value}))}
              />
            </div>
            <div className='mb-2 col-6'>
              <label className='mb-2 form-label'>Inicio Columna</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={Math.max(dataClues.startCol, 1)}
                onChange={(e)=> setDataClues(prev=>({...prev, startCol: parseInt(e.target.value)}))}
              />
            </div>
            <div className='mb-2 col-6'>
              <label className='mb-2 form-label'>Inicio Fila</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={Math.max(dataClues.startRow, 1)}
                onChange={(e)=> setDataClues(prev=>({...prev, startRow: parseInt(e.target.value)}))}
              />
            </div>
            <div className='mb-2 col-6'>
              <label className='mb-2 form-label'>Pista</label>
              <textarea
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                name={'description'}
                value={dataClues.clueText}
                onChange={(e) => setDataClues(prev => ({...prev, clueText: e.target.value}))}
              />
            </div>
            <div className='mb-2 col-6'>
              <label className='mb-2 form-label'>Tipo</label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={dataClues.direction}
                onChange={(e) => setDataClues(prev=>({...prev, direction: e.target.value as DirectionClueType}))}
                disabled={mode==='VIEW'}
              >
                <option value="across">Across</option>
                <option value="down">Down</option>
              </select>
            </div>
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={handleAddClue} className='btn btn-primary' disabled={loading}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='col-6 mb-2 d-flex justify-content-center align-items-center'>
            <div style={{ overflow: 'auto', maxHeight: '240px', maxWidth: '290px' }}>
              <table className='d-flex justify-content-center'>
                <tbody>
                  <tr>
                    <td style={{ width: 26, height: 26, margin: 0, padding: 0 }}></td>
                    {initialGrid[0]?.map((_, colIndex) => (
                      <td key={colIndex} style={{ width: 26, height: 26, textAlign: 'center', border: 'none', borderRadius: '50%', margin: 0, padding: 0 }}>
                        {colIndex + 1}
                      </td>
                    ))}
                  </tr>
                  {initialGrid.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td 
                        style={{ 
                          width: 26, 
                          height: 26, 
                          textAlign: 'center', 
                          border: 'none', 
                          borderRadius: '50%', 
                          margin: 0, 
                          padding: 0 }}>
                        {rowIndex + 1}
                      </td>
                      {row.map((cell, colIndex) => (
                        <td key={colIndex} 
                          style={{ 
                            borderRadius: '50%', 
                            backgroundColor: cell.isBlack ? 'transparent' : 'transparent', 
                            margin: 0, 
                            padding: 0 }}>
                          {cell.isBlack ? '' : typeof cell.value === 'number' ?
                            <label
                              className='poppins-light text-dark'
                              style={{ 
                                width: 26, 
                                height: 26, 
                                textAlign: 'center', 
                                border: 'none', 
                                borderRadius: '50%', 
                                margin: 0, 
                                padding: 0, 
                                marginBottom: '-6px' }}
                            >{cell.value}</label> : (
                              <label
                                className=''
                                style={{ 
                                  width: 26, 
                                  height: 26, 
                                  textAlign: 'center', 
                                  border: 'none', 
                                  borderRadius: '50%', 
                                  backgroundColor: '#d0ff00', 
                                  margin: 0, 
                                  padding: 0, 
                                  marginBottom: '-6px' }}
                              >{cell.value || ''}</label>
                            )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          
          <div className='col-12 row'>
            <label className='mb-2 form-label'>Palabras del Crucigrama</label>
            {values.sentences && values.sentences.map(x => (
              <div key={x.clueNumber} className='col-4 mb-2'>
                <label className='mb-2 form-label'>{`${x.clueNumber}: ${x.answer}`}</label>
                <button
                  disabled={loading ||mode==='VIEW'}
                  type='button'
                  onClick={()=> deleteSentence(x.clueNumber)}
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2'
                >
                  <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                </button>
              </div>
            ))}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {CrosswordForm}
