

export const setTypeToSpanish =(type:any)=>{
    if( type==='ADMIN'){
      return "Admin"
    }
    if( type==='ROOT'){
      return "Super Administrador"
    }
    if( type==='TEACHER'){
      return "Tutor"
    }
    if( type==='EXECUTIVE'){
      return "Ejecutivo"
    }
    if( type==='CLIENT'){
      return "Client"
    }
    if( type==='STUDENT'){
      return "Estudiante"
    }
    if(type==='PENDING'){
      return "Pendiente"
    }
    if(type==='NO_AVAILABLE'){
      return "No habilitado"
    }
    if(type==='PUSHED'){
      return "Publicado"
    }
    if(type==='MONDAY'){
      return "Lunes"
    }
    if(type==='TUESDAY'){
      return "Martes"
    }
    if(type==='WEDNESDAY'){
      return "Miercoles"
    }
    if(type==='THURSDAY'){
      return "Jueves"
    }
    if(type==='FRIDAY'){
      return "Viernes"
    }
    if(type==='SATURDAY'){
      return "Sabado"
    }
    if(type==='ENGLISH_EXPLORER'){
      return "English Explorers"
    }
    if(type==='PRISMATIC'){
      return "Prismatic"
    }
    if(type==='ABROAD'){
      return "Abroad"
    }
    if(type==='RANDOM'){
      return "Random"
    }
    if(type==='STANDARD'){
      return "Standard"
    }
    if(type==='BY_HOUR'){
      return "Por Horas"
    }
    if(type==='BY_TOPICS'){
      return "Por Topicos"
    }
    if(type==='EXAM'){
      return "Por Examen"
    }
    if(type==='HIGH'){
      return "Alta"
    }
    if(type==='MEDIUM'){
      return "Media"
    }
    if(type==='LOW'){
      return "Baja"
    }
    if(type==='IN_PROCESS'){
      return "En Tramite"
    }
    if(type==='CLOSED'){
      return "Cerrado"
    }
    if(type==='ARCHIVE'){
      return "Archivado"
    }
    if(type==='PAID_LICENSE'){
      return "Lic. no renumerada"
    }
    if(type==='WITHOUT_JUST_CAUSE'){
      return "Sin justa causa"
    }
    if(type==='ARCHCOMMON_DISEASEIVE'){
      return "Enfermedad comun"
    }
    if(type==='MEDICAL_APPOINTMENT'){
      return "Cita medica"
    }
    if(type==='ACTIVE'){
      return "Activo"
    }
    if(type==='INACTIVE'){
      return "Inactivo"
    }
    if(type==='SANCTIONED'){
      return "Sancionado"
    }
    if(type==='FROZEN'){
      return "Congelado"
    }
    if(type==='SUSPENDED'){
      return "Suspendido"
    }
    if(type==='GRADUATED'){
      return "Egresado"
    }
    if(type==='PRE_A1_1'){
      return "Pre A1 1"
    }
    if(type==='PRE_A1_2'){
      return "Pre A1 2"
    }
    if(type==='A1'){
      return "A1"
    }
    if(type==='PRE_A2'){
      return "Pre A2"
    }
    if(type==='A2'){
      return "A2"
    }
    if(type==='PRE_B1'){
      return "Pre B1"
    }
    if(type==='B1'){
      return "B1"
    }
    if(type==='B2'){
      return "B2"
    }
    if(type==='C1'){
      return "C1"
    }
    if(type==='NIT'){
      return "Nit - No. Identificacion Tributaria"
    }
    if(type==='CC'){
      return "Cedula Ciudadania"
    }
    if(type==='CE'){
      return "Cedula extranjeria"
    }
    if(type==='RC'){
      return "Registro civil"
    }
    if(type==='IC'){
      return "Tarjeta Identidad"
    }
    if(type==='DNI'){
      return "Numero Unico de Indentificacion"
    }
    if(type==='MR'){
      return "Registro Mercantil"
    }
    if(type==='FS'){
      return "Sociedad Extranjera"
    }
    if(type==='PRESCHOOL'){
      return "Preescolar"
    }
    if(type==='PRIMARY_BASIC'){
      return "Basica Primaria"
    }
    if(type==='BASIC_HIGH_SCHOOL'){
      return "Basica Secundaria"
    }
    if(type==='MEDIA'){
      return "Media"
    }
    if(type==='UNDERGRADUATE'){
      return "Pregrado"
    }
    if(type==='POSTGRADUATE'){
      return "Postgrado"
    }
    if(type==='LEVEL_ONE'){
      return "Nivel 1"
    }
    if(type==='LEVEL_TWO'){
      return "Nivel 2"
    }
    if(type==='LEVEL_THREE'){
      return "Nivel 3"
    }
    if(type==='EMPLOYEE'){
      return "Empleado"
    }
    if(type==='SENIOR_STUDENT'){
      return "Estudiante Superior"
    }
    if(type==='UNEMPLOYED'){
      return "Desempleado"
    }
    if(type==='INDEPENDENT'){
      return "Independiente"
    }
    if(type==='TOMORROW'){
      return "Mañana"
    }
    if(type==='AFTERNOON'){
      return "Tarde"
    }
    if(type==='NIGHT'){
      return "Noche"
    }
    if(type==='INDIGENOUS'){
      return "Indigena"
    }
    if(type==='AFRODESCENT'){
      return "Afrodescendiente"
    }
    if(type==='DISPLACED'){
      return "Desplazado"
    }
    if(type==='BORDER_POPULATION'){
      return "Poblacion Frontera"
    }
    if(type==='HEAD_HOUSEHOLD'){
      return "Cabeza Familiar"
    }
    if(type==='REINSERTED'){
      return "Reinsertado"
    }
    if(type==='ROM_POPULATION'){
      return "Población ROM"
    }
    if(type==='ANSWERED'){
      return "Respondido"
    }
    if(type==='CONGRATULATIONS'){
      return "Felicitación"
    }
    if(type==='SUGGESTION'){
      return "Sugerencia"
    }
    if(type==='REQUEST'){
      return "Solicitud"
    }
    if(type==='COMPLAINT'){
      return "Queja"
    }
    if(type==='DEMAND'){
      return "Reclamo"
    }
    if(type==='PETITION'){
      return "Petición"
    }

    if(type==='NO_APPLY'){
      return "No aplíca"
    }

    if(type==='PENDING'){
      return "Pendiente"
    }

    if(type==='IN_PROCESS'){
      return "En proceso"
    }

    if(type==='FINISHED'){
      return "Finalizado"
    }

    if(type==='CANCEL'){
      return "Cancelado"
    }

    if(type==='APPROVED'){
      return "Aprobado"
    }

    if(type==='CANCELLED'){
      return "Cancelado"
    }
    
    if(type==='AUDIO'){
      return "Audio"
    }

    if(type==='IMAGE'){
      return "Imagen"
    }

    if(type==='FILE'){
      return "Archivo"
    }

    if(type==='YES_OR_NOT'){
      return "Si/No"
    }

    if(type==='TEXT'){
      return "Texto"
    }
  }