import React from "react";
import { ChapterTableList } from "../list";
import { LearningPath } from "../../../../interfaces";
import { UseLearningPathOne } from "../../../../services";
import { useParams } from "react-router";
import { ChapterTopWidget } from "./top-widget";
import { KTIcon } from "../../../../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const ChapterDashboard: React.FC = () => {
    const [learningPath, setLearningPath] = React.useState<LearningPath>();
    const {id} = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UseLearningPathOne(id??'')
                setLearningPath(response)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id])

    return (
        <div className="row">
            <div>
                <a href={`#`} className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => navigate(-1)}>
                    <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
                </a>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8">
                <ChapterTableList />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
                {learningPath && <ChapterTopWidget item={learningPath}/> }
            </div>
        </div>
    )
};

export {ChapterDashboard}