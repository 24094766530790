import React, { useRef } from 'react';
import { FileFolderAdd } from '../../../modules/admin/file-folder/services';
import { FileContentChildrenAdd, FileContentCourseAdd } from '../../../modules/admin/file-folder/services/file-content-course';
import { ToTypeContentCourse, TypeContentCourse } from '../../../modules/admin/file-folder/interfaces';
import { showSimpleAlert } from '../../../commonHooks/alert';
import { allowedFileTypes } from './utilsFile';

type FileUploadProps = {
  onChangeFile: (file: any) => void;
  onChangeLoading: (isLoading: boolean) => void;
  onChangeContent: (id: string) => void;
  idInput?: string;
  typeContent: TypeContentCourse;
  to: ToTypeContentCourse;
  root: string;
  applyCover: boolean;
  cover?: string;
  iFrameContent?: string;
};

const FileContentComponent: React.FC<FileUploadProps> = ({
  onChangeContent, 
  applyCover, 
  onChangeFile, 
  onChangeLoading, 
  idInput, 
  typeContent,
  to,
  iFrameContent,
  cover,
  root }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isLoadingVideo, setIsLoadingVideo] = React.useState<boolean>(false);
  const [frameUrl, setIframeUrl] = React.useState<string>(iFrameContent??'');

  const handleFileUpload = async () => {
    try {
      if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
        if (applyCover === true && typeof cover === 'undefined') {
          return showSimpleAlert({ message: 'Aplica a cover pero no tienes un archivo adjunto', icon: 'error' });
        }
        onChangeLoading(true);
        const formData = new FormData();
        formData.append('file', fileInputRef.current.files[0]);
        const response = await FileContentChildrenAdd(formData);
        await changeFile(response);
        const responseContent =  await FileContentCourseAdd({
            type: typeContent,
            to: to,
            isActive: true,
            applyCover: applyCover,
            root: root,
            content: response.id,
            cover: applyCover?cover??undefined:undefined
        });
        onChangeContent(responseContent.id);
        onChangeLoading(false);
      }
    } catch (error) {
      console.error(error);
      onChangeLoading(false);
    }
  };

  const handleSubmitVideo = async () => {
    try {
      onChangeLoading(true);
      setIsLoadingVideo(true);
      const response = await FileContentCourseAdd({
        type: typeContent,
        to: to,
        isActive: true,
        applyCover: applyCover,
        cover: applyCover?cover??undefined:undefined,
        content: undefined,
        root: root,
        iFrame: frameUrl
      });
      onChangeContent(response.id);
      setIsLoadingVideo(false);
      onChangeLoading(false);
    } catch (e) {
      console.error(e)
      setIsLoadingVideo(false);
      onChangeLoading(false);
    }
  };

  const changeFile = (file: any) => {
    onChangeFile(file);
  };

  return (<>
    {typeContent !== TypeContentCourse.VIDEO ?
    <><label htmlFor={idInput ?? 'username'} className=' btn border-white' >
      <i style={{ color: '#009ef7', fontSize: 40 }} className="fas fa-image"></i>
    </label>
      
        <input 
          ref={fileInputRef} 
          onChange={handleFileUpload} 
          type="file" style={{display:'none'}} 
          id={idInput ?? 'username'}
          accept={allowedFileTypes[typeContent]?.join(',')}
        />
      </> : <>
        <input
          type='text'
          id={idInput ?? 'username'}
          className='form-control form-control-lg form-control-solid mb-3'
          onChange={(e) => setIframeUrl(e.target.value)}
          value={frameUrl}
        />
        <button 
          className='btn btn-primary' 
          type='button' 
          disabled={isLoadingVideo}
          onClick={handleSubmitVideo}
        >
          {!isLoadingVideo && 'Save'}
          {isLoadingVideo && 
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>}
        </button>
        </>}
  </>
  );
};

export default FileContentComponent;