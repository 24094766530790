/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'

const QuickLinks: FC = () => {
  // const [showAll, setShowAll] = React.useState(false); 

  return(
    <div
    className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
    data-kt-menu='true'
  >
    {/* <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold mb-3'>Quick Links</h3>

      <span className='badge bg-primary py-2 px-3'>25 pending tasks</span>
    </div> */}

    <div className='row g-0'>
      <div className='col-6'>
        <a
          href='/user'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
        >
          <KTIcon iconName='user' className='fs-3x text-primary mb-2' />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Users</span>
          <span className='fs-7 text-gray-400'>Users</span>
        </a>
      </div>

      <div className='col-6'>
        <a
          href='/road-map'
          className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
        >
          <KTIcon iconName='sms' className='fs-3x text-primary mb-2' />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Road Map</span>
          <span className='fs-7 text-gray-400'>Items</span>
        </a>
      </div>

      <div className='col-6'>
        <a href='/learning-path' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
          <KTIcon iconName='abstract-41' className='fs-3x text-primary mb-2' />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Learning Path</span>
          <span className='fs-7 text-gray-400'>Courses</span>
        </a>
      </div>

      {/* <div className='col-6'>
        <a href='/table-time' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
          <KTIcon iconName='briefcase' className='fs-3x text-primary mb-2' />
          <span className='fs-5 fw-bold text-gray-800 mb-0'>Teacher Time</span>
          <span className='fs-7 text-gray-400'>Work Time</span>
        </a>
      </div> */}
      {/* {showAll && <> */}
        <div className="col-6">
          <a href="/workshop" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'>
            <KTIcon iconName='briefcase' className='fs-3x text-primary mb-2' />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Workshop</span>
            <span className='fs-7 text-gray-400'>Workshop</span>
          </a>
        </div>
        <div className="col-6">
          <a href="/user/student-setting" className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-top border-end'>
            <i className="fa-solid fa-user-graduate fs-3x text-primary mb-2"></i>
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Students</span>
            <span className='fs-7 text-gray-400'>Students</span>
          </a>
        </div>
      {/* </>} */}
    </div>
    {/* <div className='py-2 text-center border-top'>
      <a href='#' className='btn btn-color-gray-600 btn-active-color-primary' onClick={() => setShowAll(!showAll)}>
        {showAll ? 'Show Less' : 'View All'}{' '}
        <KTIcon iconName={showAll ? 'arrow-up' : 'arrow-down'} className='fs-5' />
      </a>
    </div> */}
  </div>
  )
}

export {QuickLinks}
