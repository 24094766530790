import {Route, Routes} from 'react-router-dom'
import { ClassroomDetails } from '../components/details'
import { ClassroomTableList } from '../components/list'


const ClassroomPage = () => (
  <Routes>
      <Route path='/details' element={<ClassroomDetails />} />
      <Route path='/' element={<ClassroomTableList />} />
  </Routes>
)

export {ClassroomPage}
