import React, { useRef } from "react";
type ColorType = 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'DANGER';
type SizeType = 'sm' | 'md' | 'lg' | 'xl';
interface CopyButtonProps {
  type: SizeType
  color: ColorType
  title: string
  handleClick: () => void;
  buttonDisabled?:boolean;
}
type SizeSettings = {
  paddingLeft: number
  paddingRight: number
  fontSize: string
}
const CustomButton: React.FC<CopyButtonProps> = ({ type, color, handleClick, title, buttonDisabled }) => {
  const textInputRef = useRef<HTMLInputElement | null>(null);

  const colorSelector = (type: ColorType) => {
    switch (type) {
      case 'PRIMARY':
        return '#02075e';
      case 'SECONDARY':
        return "#a500b7"
      case 'TERTIARY':
        return '#2d30e1'
      case 'DANGER':
        return '#ff336a';
      default:
        return '#02075e';
    }
  }

  const sizeSelector = (type: SizeType): SizeSettings => {
    switch (type) {
      case 'sm':
        return {
          paddingLeft: 15,
          paddingRight: 12,
          fontSize: '0.8em'
        }
      case 'md':
        return {
          paddingLeft: 12,
          paddingRight: 12,
          fontSize: '0.5em'
        }
      case 'lg':
        return {
          paddingLeft: 30,
          paddingRight: 30,
          fontSize: '0.5em'
        }
      case 'xl':
        return {
          paddingLeft: 65,
          paddingRight: 65,
          fontSize: '1.8em'
        }
    }
  }
  return (
    <div>
      <button className='btn champ-light my-1' onClick={handleClick}
        disabled={buttonDisabled ? buttonDisabled : false}
        style={{
          background: colorSelector(color),
          borderRadius: 30,
          color: 'white',
          paddingTop:5,
          paddingBottom:5,
          paddingLeft: sizeSelector(type).paddingLeft,
          paddingRight: sizeSelector(type).paddingRight,
          fontSize: sizeSelector(type).fontSize,
        }}
      >{title}</button>
    </div>
  );
};

export default CustomButton;