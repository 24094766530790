import React, { useEffect } from 'react';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';

type Props = {
  children?: React.ReactNode; // Agregando la capacidad de recibir hijos
  imageUrl: string;
};

const MediaContentPicture: React.FC<Props> = ({ children, imageUrl }: Props) => {
    const { mode } = useThemeMode();

    return (
        <div className="card-media-picture "
             style={
                 mode !== 'dark' ? {
                     background: `url(${imageUrl}) center center / cover no-repeat`,
                     position: 'relative',
                     overflow: 'hidden',
                 } : {
                     background: `url(${imageUrl}) center center / cover no-repeat`,
                     position: 'relative',
                     overflow: 'hidden',
                 }
             }>
            {children}
        </div>
    );
};

export { MediaContentPicture };
