import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {Lessons} from '../../interfaces'

import { LessonsAdd, LessonsUpdate } from '../../services'
import { UseLessonsModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { ApprovalType, LevelStudent, ResourceType } from '../../../chapter/interfaces'
import { SequenceType, TraditionalLevelEnum } from '../../../../../road-map-step/interfaces'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FileContentComponent from '../../../../../../../components/common/SingleFile/FileContent'
import { toAbsoluteUrl } from '../../../../../../../../_metronic/helpers'
import { ToTypeContentCourse, TypeContentCourse } from '../../../../../../admin/file-folder/interfaces'
import FileUploadComponent from '../../../../../../../components/common/SingleFile'
import { FindAllBookPageByBookUseCase } from '../../../../../../admin/book/modules/book-page/services'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'
import { BookPage } from '../../../../../../admin/book/modules/book-page/interfaces'
import { GenericSelector } from '../../../../../../../components/common/GenericSelector'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  // approval: ApprovalType;
  sequence: SequenceType;
  resourceType?: ResourceType;
  level: TraditionalLevelEnum;
  order: number;
  numberHour: number;
  book_pages?: string[]
  numberTopics: number;
  resource?: string;
  fileContent?: string
  singleQuiz: boolean
  withTeacher:boolean
  finalPresentation: boolean
  quiz: boolean
  review: boolean
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const LessonsForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setTypeForm, chapterData} = UseLessonsModule();
  const [data, setData] = useState<Lessons>()
  const [loading, setLoading] = React.useState<boolean>(false);
  const [contentFile, setContentFile] = React.useState<any>();
  const [applyCoverInput, setApplyCoverInput] = React.useState<boolean>(false);
  const [coverFile, setCoverFile] = React.useState<any>();
  const [typeFileInput, setTypeFileInput] = React.useState<TypeContentCourse>(TypeContentCourse.IMAGE);
  const [pageData, setPageData] = React.useState<BookPage[]>([
  
  ]);

  const {id} = useParams()

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        // approval: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.approval ?? ApprovalType.BY_HOUR : ApprovalType.BY_HOUR,
        sequence: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.sequence ?? SequenceType.RANDOM : SequenceType.RANDOM,
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        order: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.order ?? 0 : 0,
        numberHour: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.numberHour ?? 0 : 0,
        numberTopics: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.numberTopics ?? 0 : 0,
        fileContent: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.fileContent?.id ?? undefined : undefined,
        singleQuiz: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.singleQuiz??false : false,
        withTeacher: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.withTeacher??true : true,
        finalPresentation: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.finalPresentation ?? false : false,
        quiz: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.quiz ?? false : false,
        review: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.review ?? false : false,
        book_pages: (mode === 'EDIT' || mode === 'VIEW') ? Array.from(itemSelected?.book_pages ?? []).map(x => x?.id ?? '') : []
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await LessonsAdd({...formData, chapter: id??'',resource:undefined})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['lessons'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  LessonsUpdate({...formData, chapter: id??'',resource:undefined, evaluation: itemSelected?.evaluation?.id??undefined})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['lessons'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const changeFileContent = (obj: any) => {
      setContentFile(obj)
    };
  
    const changeFileCover = (obj: any) => {
      setCoverFile(obj)
    };
  
    const changeContent = (id: string) => {
      setFieldValue('fileContent', id)
    };
  
    const findBookPageByBook = async(id: string) => {
      try {
        const response = await FindAllBookPageByBookUseCase(id);
        setPageData(response);
      } catch (e) {
        showSimpleAlert({message: 'Error al encontrar paginas de libro', icon: 'error'})
      }
    };

    React.useEffect(() => {
      setTypeFileInput(itemSelected?.fileContent?.type ?? TypeContentCourse.IMAGE);
      setApplyCoverInput(itemSelected?.fileContent?.applyCover ?? itemSelected?.fileContent?.cover ? true : false);
      setContentFile(itemSelected?.fileContent?.content);
      setCoverFile(itemSelected?.fileContent?.cover);
    }, [itemSelected]);

    React.useEffect(() => {
      if (chapterData && chapterData.book) {
        findBookPageByBook(chapterData.book.id ??'');
      }
    }, [chapterData]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>
          <div className='d-flex justify-content-between'>
            <div className=''>
              {mode !== 'ADD' && 
                <Link to={`/evaluation/${itemSelected?.id}`} className='btn'>
                  <span className='py-1 px-2'>
                    Ejercicios
                  </span>
                </Link>
              }
            </div>
            <div className=''>
              <button type='button' className='btn' onClick={() => setTypeForm('GLOSSARY')}>
                <span className='py-1 px-2'>
                  Glosario
                </span>
              </button>
            </div>
            <div className=''>
              <button type='button' className='btn' onClick={() => setTypeForm('TIPS')}>
                <span className='py-1 px-2'>
                  Tips
                </span>
              </button>
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>¿Clase con tutor?</label>
            <div className="form-check form-switch">
              <input
                name={'withTeacher'}
                checked={values.withTeacher}
                value={values.withTeacher?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Nombre de la lección</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-4'>
            <label className='form-label mb-3'>Tipo Quiz</label>
            <div className="form-check form-switch">
              <input
                name={'quiz'}
                checked={values.quiz}
                value={values.quiz?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-3'>
            <label className='form-label mb-3'>Single Quiz</label>
            <div className="form-check form-switch">
              <input
                name={'singleQuiz'}
                checked={values.singleQuiz}
                value={values.singleQuiz?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div> */}
          <div className='mb-10 col-4'>
            <label className='form-label mb-3'>Tipo Final Presentation</label>
            <div className="form-check form-switch">
              <input
                name={'finalPresentation'}
                checked={values.finalPresentation}
                value={values.finalPresentation?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-4'>
            <label className='form-label mb-3'>Tipo Contenido</label>
            <div className="form-check form-switch">
              <input
                name={'review'}
                checked={values.review}
                value={values.review?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Approval</label>
            <select
              disabled={mode === 'VIEW'}
              name='approval'
              value={values.approval}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ApprovalType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.approval && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.approval}</span>
                </div>
              </div>
            )}
          </div> */}
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Orden</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Secuencia</label>
            <select
              disabled={mode === 'VIEW'}
              name='sequence'
              value={values.sequence}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(SequenceType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.sequence && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.sequence}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Book Page</label>
            {/* <GenericSelector<BookPage>
              param={'id'} 
              displayField={'name'}
              values={pageData}
              render={(item) => <label>{item.name}</label>}
              // multiple
            /> */}
            <select
              multiple
              disabled={mode === 'VIEW'}
              name='book_pages'
              value={values.book_pages}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {pageData && pageData.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex">
            <hr className="my-auto flex-grow-1"/>
            <div className="px-4">Datos Informativos</div>
            <hr className="my-auto flex-grow-1"/>
          </div>
         
           {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>resourceType</label>
            <select
              disabled={mode === 'VIEW'}
              name='resourceType'
              value={values.resourceType}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(ResourceType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.resourceType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resourceType}</span>
                </div>
              </div>
            )}
          </div> */}

          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>level</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={values.level}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.level && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.level}</span>
                </div>
              </div>
            )}
          </div> */}

          {mode !== 'ADD' && <>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-0'>resourceType</label>
            <select
              disabled={mode === 'VIEW'}
              value={typeFileInput}
              onChange={(e)=> setTypeFileInput(e.target.value as TypeContentCourse)}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TypeContentCourse).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div> */}

            {/* <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Apply cover?</label>
              <div className="form-check form-switch">
                <input
                  checked={applyCoverInput}
                  onChange={(e)=>setApplyCoverInput(e.target.checked)}
                  className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              </div>
            </div> */}
{/* 
            {applyCoverInput && <div className='mb-10 col-6 d-flex flex-column'>
                <label className='form-label mb-3'>Cover</label>
                {(coverFile && (coverFile.url || coverFile.location)) ? 
                <img className='img-fluid' style={{width:'50%'}} 
                  src={(coverFile.url || coverFile.location)??toAbsoluteUrl('/media/logos/favicon.jpg')} 
                  alt='metronic'/>
              : <img className='img-fluid' style={{width:'50%'}} 
                  src={toAbsoluteUrl('/media/logos/favicon.jpg')} 
                  alt='metronic'/>}
                  <FileUploadComponent
                    onChangeFile={(e) => changeFileCover(e)} 
                    onChangeLoading={(e) => setLoading(e)}
                    pushCover
                    idInput='coverFile' />
            </div>}

            <div className='mb-10 col-6 d-flex flex-column'>
              <label className=''>Content</label>
              {(contentFile && (contentFile.url || contentFile.location)) ? 
                typeFileInput !== TypeContentCourse.VIDEO &&
                <img className='img-fluid' style={{width:'50%'}} 
                  src={(contentFile.url || contentFile.location)??toAbsoluteUrl('/media/logos/favicon.jpg')} 
                  alt='metronic'/>
              : typeFileInput !== TypeContentCourse.VIDEO &&
                <img className='img-fluid' style={{width:'50%'}} 
                  src={toAbsoluteUrl('/media/logos/favicon.jpg')} 
                  alt='metronic'/>}
              <FileContentComponent
                onChangeFile={(e) => changeFileContent(e)} 
                onChangeLoading={(e) => setLoading(e)}
                onChangeContent={(e) => changeContent(e)}
                idInput='contentChapter'
                to={ToTypeContentCourse.LESSON}
                root={itemSelected?.id??'x'}
                typeContent={typeFileInput}
                applyCover={applyCoverInput}
                cover={coverFile?coverFile.id:undefined}
              />
            </div> */}
          </>}

          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Number Hours</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'numberHour'}
              value={values.numberHour}
              onChange={handleChange}
            />
            {touched.numberHour && errors.numberHour && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.numberHour}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Number Topics</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'numberTopics'}
              value={values.numberTopics}
              onChange={handleChange}
            />
            {touched.numberTopics && errors.numberTopics && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.numberTopics}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Resource</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'resource'}
              value={values.resource}
              onChange={handleChange}
            />
            {touched.resource && errors.resource && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resource}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Descripción de la leccion</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {LessonsForm}
