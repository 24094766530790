import { SettingStudentStatus, StudentSettings } from '../../interfaces'
import { api } from '../../../../auth/services/auth.services'
import useAuth from '../../../../../api/main'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseUserModule } from '../../store/main'
import { Pagination } from '../../../../auth/interfaces'
import { SuspenseStudent } from '../../../student-actions/studentStatusManager/interfaces'

const { token, logout } = useAuth.getState()

interface StudentSettingAddWithEvaluationId extends StudentSettings {
  evaluationId: string;
};

export const StudentSettingsAdd = async (data: StudentSettingAddWithEvaluationId): Promise<any> => {
  try {
    const response: any = await api.post(
      '/student-settings',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }

    throw 'Error'
  }
}

export const StudentSettingsUpdate = async (data: StudentSettings): Promise<any> => {
  try {
    const { itemSelectedStudent } = UseUserModule.getState()
    const response: any = await api.patch(
      `/student-settings/${itemSelectedStudent?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const StudentSettingsOneByUser = async (id: string): Promise<any> => {
  try {
    const response: any = await api.get(`/student-settings/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    catchServerError(e.response)
  }
}

export const StudentSettingsUpdateStatus = async (
  status: SettingStudentStatus,
  id: string
): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/student-settings/status-value/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseSuspenseStudentPaginated = async (student: string, start?: string, end?: string): Promise<Pagination<SuspenseStudent>> => {
  try {
    let url = `/suspense-student/paginated?isActive=${1}&limit=${10}&page=${1}&student=${student}`;
    if (start) {
      url += `&start=${start}`;
    }
    if (end) {
      url += `&end=${end}`;
    }
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (e: any) {
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      if (e.response.data.message) {
        e.response.data.message.map((x: string) => {
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
      showSimpleAlert({ message: e.response.data.message, icon: 'error' });
      throw 'Error'
    }
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

// export const UseSuspenseStudentPaginate = async (id: string): Promise<Pagination<SuspenseStudent>> => {
//   try {
//     // await authChecked()
//     // const { setPaginator, limit, currentPage, where, isActive } = await UseStudentStatusManagerModule.getState()

//     const response = await api.get(
//       `/student-status-manager/paginated?root=${id}&isActive=${1}&limit=${10}&page=${currentPage}&where=${where}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     )
//     setPaginator(response.data)
//     return response.data
//   } catch (e:any) {
//     //@ts-ignore
//     if (e.response.status === 401) {
//       logout()
//     }
//     if (e.response.status === 400) {
//        e.response.data.message.map((x)=>{
//          //@ts-ignore
//          showSimpleAlert({ message: x, icon: 'error' })
//        })
//        throw 'Error'
//      }
//     //@ts-ignore
//     showSimpleAlert({ message: 'Error!', icon: 'error' })
//     throw 'Error'
//   }
// }