import {Route, Routes} from 'react-router-dom'
import { CertificateDetails } from '../components/details'
import { CertificateTableList } from '../components/list'


const CertificatePage = () => (
  <Routes>
      <Route path='/details' element={<CertificateDetails />} />
      <Route path='/' element={<CertificateTableList />} />
  </Routes>
)

export {CertificatePage}
