import { create  } from "zustand";

interface ColumnItem {
    title: string;
    value: string;
}
interface rowItem {
    idWord: string;
    title: string;
    value:string;

}
interface ArrayQuestion {
    map: any;
    column: ColumnItem[];
    row: rowItem[];
}
interface InterfaceModuleExercise {
    checked: boolean;
    Arrayquestion: ArrayQuestion;
    instruction: string;
    inputValues: any[];
    setChecked: (value: boolean) => void;
    setInputValuesMo: (value: any[]) => void;
}

export const UseModuleExerciseEleven = create<InterfaceModuleExercise>((set)=> ({
    checked:true,
    instruction: 'look at the sentences from the text and clasify them into',
    
    Arrayquestion : {
        column: [
            { title: "To set up", value: "a"},
            { title: "To build up", value: "b"},
            { title: "To buy and roast", value: "c"},
            { title: "To to make", value: "d"},
            { title: "To go", value: "e"},   
        ],
        row: [
            { idWord: '1', title: "row-A", value: "b"},
            { idWord: '2', title: "row-B", value: "c"},
            { idWord: '3', title: "row-C", value: "e"},
            { idWord: '4', title: "row-D", value: "d"},
            { idWord: '5', title: "row-E", value: "a"},
        ],
        map: undefined
    },

        inputValues: [],
        setInputValuesMo(value) {
            set({inputValues: value})
          },

        setChecked:(value) => {
            set({checked: value})
        },

    }))
    