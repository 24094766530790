import React, { useEffect, useRef } from "react";
import { UseBookingWindows } from "../store";
import moment from "moment";
import { UseBookingPaginate } from "../../admin/appointment/services";
import BookingLoadingLottie from "../../../components/common/lottie/booking-loading";
import { DayPickerRangeBookingSelector } from "../../../components/common/DayRangePickerBooking/indext";
import { BookingWindowsList } from "./booking-windows-list";
import { Booking } from "../../admin/appointment/interfaces";
import { UseAddTrialClass, UseFindPaginatedTrailClass } from "../../me-booking/services/trail-class";
import { showSimpleAlert } from "../../../commonHooks/alert";
import { FindOneSettingByType } from "../../abroad/settings/services";
import { SettingEnumType } from "../../abroad/settings/interfaces";
import { useThemeMode } from "../../../../_metronic/partials";
import { UseBookingModule } from "../../me-booking/store";
import { useQueryClient } from "@tanstack/react-query";
import { UseMyRoadmapModule } from "../../profile/components/my-road-step/store";
import Swal from "sweetalert2";
interface Props {

}

const CalendarSelector: React.FC<Props> = ({ }) => {
    const {mode} = useThemeMode();
    const {settings} = UseMyRoadmapModule();
    const queryClient = useQueryClient()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [bookingAvailable, setBookingAvailable] = React.useState<any[]>([])
    const [day, setDay] = React.useState<Date>()
    const [dataSettings, setDataSettings] = React.useState<any>();
    const textInputRef = useRef<HTMLInputElement | null>(null);
    let { classType ,setStep,step, classroomType } = UseBookingWindows();
    const {classByWeek,setMyAgenda,setClassByWeek} = UseBookingModule();
    const [availableList, setAvailableList] = React.useState<any[]>([
        moment(),
        moment().add(2,'days')
    ]);
    const handelDate = (e:any)=>{
        setDay(e);
        setStep(4)
    }
    const setBooking = async (x: Booking) => {
        Swal.fire({
          title: 'Are you sure?',
          html: "<p class='poppins-light'>You won't be able to revert this!</p>",
          icon: 'warning',
          iconColor: '#2d30e1',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: "<span class='poppins-light fs-4'>Yes!</span>",
          cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
          customClass: {
            title: 'champ-light',
            confirmButton: 'confirm-button-sweet-alert',
            cancelButton: 'cancel-button-sweet-alert',
          }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true)
                    await UseAddTrialClass(x.id)
                    setLoading(false)
                    //await findStarterList()
                    setStep(0)
                    queryClient.invalidateQueries({queryKey: ['trail-active-dashboard']})
                    showSimpleAlert({ message: 'Clase Reservada!', icon: 'success' });
                   // window.location.reload();
                } catch (e) {
                  setStep(0)
                  setLoading(false)
                }
            }
        })
    };

    const fetchSettings = async () => {
        try {
          const responseSettings = await FindOneSettingByType(SettingEnumType.MAXIMUM_NUMBER_HOURS_ADVANCE_TO_MAKE_RESERVATIONS);
          setDataSettings(responseSettings);
        } catch (e) {
          showSimpleAlert({ message: 'Error al encontrar Settings!', icon: 'error' });
          throw 'Error'
        };
      };

    const findStarterList = async () => {
        let starterList = await UseBookingPaginate({
            before: moment().format('YYYY-MM-DD').toString(),
            after: moment().add(1, 'week').endOf('week').format('YYYY-MM-DD').toString()
        })
        let list: any[] = [];
        starterList.map((x) => {
            list.push(moment(x.start))
        })
        setAvailableList(list)
    }
    const findBookingAvailable = async () => {
        setLoading(true);
        let starterList = await UseBookingPaginate({
            before: moment(day).format('YYYY-MM-DD').toString(),
            after: moment(day).format('YYYY-MM-DD').toString(),
            blockHour: moment(day).isSame(moment(), 'day') ? moment().hour() : undefined,
            classType,
            campus: settings ? settings.campus ? settings.campus.id : undefined : undefined,
            classroomType: classroomType??undefined
        })
        setLoading(false);
        setBookingAvailable(starterList)
    }

    const findMyAgendaByDay = async () => {
      let starterList = await UseFindPaginatedTrailClass({
        before: moment(day).format('YYYY-MM-DD').toString(),
        after: moment(day).format('YYYY-MM-DD').toString()
      });
      setMyAgenda(starterList?.result);
    };
    
    const findMyAgendaByWeek = async () => {
      // if (classByWeek && classByWeek.currentWeek && moment(day).isBetween(classByWeek.currentWeek.start,classByWeek.currentWeek.end, undefined,'[]')) {
      //     return;
      // };
      let starterList = await UseFindPaginatedTrailClass({
        before: moment(day).startOf('week').format('YYYY-MM-DD').toString(),
        after: moment(day).endOf('week').format('YYYY-MM-DD').toString(),
        isSuspense: false
      });
      const numberClassWorkshops = starterList?.result.filter((x) => x?.activityType === 'WORKSHOP')
      const numberClassLessons = starterList?.result.filter((x) => x?.activityType === 'LESSON');
      setClassByWeek({
        currentWeek: {
          start: moment(day).startOf('week').format('YYYY-MM-DD'),
          end: moment(day).endOf('week').format('YYYY-MM-DD')
        },
        numberClassWorkshops,
        numberClassLessons,
        result: starterList?.result
      });
    };

    useEffect(() => {
        findStarterList()
    }, [])
    useEffect(() => {
        findBookingAvailable();
        findMyAgendaByDay();
        findMyAgendaByWeek();
    }, [day]);
    useEffect(() => {
        fetchSettings();
      }, []);

    useEffect(() => {
        if (dataSettings && bookingAvailable) {
          const interval = setInterval(() => {
            const now = moment();
            bookingAvailable.forEach((x) => {
              const futureDate = moment(x.time.start);
              const duration = moment.duration(futureDate.diff(now));
              if (duration.asHours() <= dataSettings.value) {
                setBookingAvailable(prevItem => prevItem.filter((item) => moment(item.time.start).isAfter(now)).map((y) => ({
                  ...y,
                  disableButton: true
                })))
              } else {
                setBookingAvailable(prevItem => prevItem.filter((item) => moment(item.time.start).isAfter(now)).map((y) => ({
                  ...y,
                  disableButton: false
                })))
              }
            })
          }, 1000)
    
          return () => clearInterval(interval);
        }
      }, [day, dataSettings, bookingAvailable]);

    return (<>
        {loading ? 
          <div className="d-flex justify-content-center align-items-center"
          style={
              mode != 'dark' ?{
                  height:350, 
                  borderRadius: '50px',
                  background:'white',
                  padding:3
              }:{ 
                  height:'100%',
                  borderRadius: '50px',
                  background:'#1e1e2d',
                  padding:3,
                  border: '1px solid', 
                  borderColor:'#2d30e1'
              }
          }>
              <div className="spinner-grow text-red" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
            :
            (!day && step === 3 &&<DayPickerRangeBookingSelector list={availableList} day={day} changeDate={(e) =>
                handelDate(e)
        } />)}
        {day && !loading && step===4 &&
            <BookingWindowsList
                className='' day={day} items={bookingAvailable} setBooking={(e)=>setBooking(e)}
            />
        }
    </>);
};

export default CalendarSelector;
{/*<BookingLoadingLottie />*/}