import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {  useParams } from 'react-router-dom';
import { AssistanceStudentTrailClass } from '../../../abroad/trail/services';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { useThemeMode } from '../../../../../_metronic/partials';

interface CountdownTimerProps {
  studentAssistant:boolean;
  enabledButton:boolean
  targetDate: string; // Asumiendo que la fecha se proporciona como una cadena (puedes usar el formato que desees)
  showButtonThreshold: number; // Número de minutos antes de mostrar el botón para ir a la clase
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate, showButtonThreshold ,enabledButton,studentAssistant }) => {
  const {id} = useParams();
  const {mode} = useThemeMode()

  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const futureDate = moment(targetDate);
      
      const duration = moment.duration(futureDate.diff(now));
      
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = duration.seconds();
  
      // Formatea las horas, minutos y segundos para asegurar dos dígitos
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
  
      setTimeRemaining(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
  
      if (duration.asMilliseconds() <= -10 * 60 * 1000) {
        setShowButton(false);
        setTimeRemaining('00:00:00');
        clearInterval(interval);
      } else if (duration.asMinutes() <= showButtonThreshold) {
        setShowButton(true); // Muestra el botón cuando se alcanza el umbral de minutos
      } else {
        setShowButton(false);
      }
    }, 1000);
  
    return () => clearInterval(interval);
  }, [targetDate, showButtonThreshold]);
  
  const handleAssistanceStudent = async() => {
    try {
      if (id) {
        const responseAssistance = await AssistanceStudentTrailClass(id);
        if (responseAssistance.url) {
          window.location.href = responseAssistance.url
        };
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al actualizar estudiante!', icon: 'error' })
      throw 'Error'
    }
  };

  return (
    <div className='   row'  
      style={mode==='dark'?
        {color:'white',fontSize:50, border:`1px solid`, background:'#151521',borderBlockColor:'#1becd5',borderRadius:80,width:'100%'}:
        {color:'#2d30e1',fontSize:50,  background:'#1becd5',borderRadius:+80,width:'100%'}
        }
        >


          <div className="col-7 text-center d-flex  flex-column justify-content-center align-items-center">
              <p className='champ-light mt-5'
              style={{marginBottom:0,paddingBottom:0}}
              >{timeRemaining}</p>
              <p className='poppins-light' style={
                mode==='light' ? 
                {fontSize:12, paddingTop:0,marginTop:-16,color:'#2d30e1'}
                :{fontSize:12, paddingTop:0,marginTop:-16, color:'#1becd5'}
              } >{moment(targetDate).format('MMMM DD/YY')}</p>
          </div>
          <div className="col-5 d-flex justify-content-center align-items-center">
            
        {showButton || studentAssistant === true ?
            <button   onClick={(e) => {handleAssistanceStudent()}} 
              type='button'
              style={
                {fontSize:30,color: mode==='dark'?'#7577dd  ': '#1becd5',width:'85%', paddingTop:0,paddingBottom:1, borderRadius:50, border: mode==='dark' ? '2px solid #7577dd' : '2px solid #2d30e1', background: mode==='dark' ? 'none' : '#2d30e1', cursor:'pointer'}
              } 
              className='poppins-light btn text-center' 
            >
              Ingresar
            </button>
            :
            <div style={{cursor: 'not-allowed', padding: 0, height: '100%', width: '85%'}}>
              <button
                type='button'
                disabled
                style={
                  {fontSize:30,color: mode==='dark'?'#22b0a1': '#2b2dad',width:'100%', paddingTop:0,paddingBottom:1, borderRadius:50, border: mode==='dark' ? '2px solid #22b0a1' : '2px solid #2b2dad', background: mode==='dark' ? 'none' : '#22b0a1'}
                }
                className='poppins-light btn text-center mt-5' 
                >
                Ingresar
              </button>
            </div>
        }

          </div>

    </div>
  );
};

export default CountdownTimer;