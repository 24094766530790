import React from 'react'
import {Booking} from '../../../admin/appointment/interfaces'
import moment from 'moment'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import {UpdateStarsValueStudent} from '../../../abroad/trail/services'
import {useThemeMode} from '../../../../../_metronic/partials'
import './index.css'

type Props = {
  item: Booking
}

const TableItem: React.FC<Props> = ({item}) => {
  const {mode} = useThemeMode()
  const [showRating, setShowRating] = React.useState(false)
  const [selectedRating, setSelectedRating] = React.useState(item.stars ?? 0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentDateTime = moment()

      if (currentDateTime.isSameOrAfter(item.time.end.split(' ')[0])) {
        setShowRating(true)
        clearInterval(interval)
      }
      if (currentDateTime.diff(item.time.end.split(' ')[0], 'days') > 1) {
        setShowRating(false)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [item.time.end.split(' ')[0]])

  const handleRatingChange = (value: number) => {
    setSelectedRating((prevRating) => (prevRating === value ? 0 : value))
    const update = async () => {
      try {
        if (item.id) {
          await UpdateStarsValueStudent(item.id, value)
        }
      } catch (e) {
        showSimpleAlert({message: 'Error al actualizar start rating!', icon: 'error'})
        throw 'Error'
      }
    }
    update()
  }
  return (
    <>
      <tr key={item.id} className='fs-5' style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}>
        <td className='p-0'>
          <span className='d-block poppins-light'>
            {moment(item.start.split(' ')[0]).format('DD/MM/YYYY')}
          </span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-bold'>{item.time.start.split(' ')[1]}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.time.end.split(' ')[1]}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.title}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.title}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.room}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.teacher}</span>
        </td>
        <td className='p-0'>
          <span className='fw-semibold d-block poppins-light'>
            {item.isSuspended
              ? 'Sancionado'
              : item.isSuspense
              ? 'Cancelada'
              : moment(item.time.start).isAfter(moment())
              ? 'Activa'
              : item.assistantStudent
              ? 'Asistida'
              : 'No asistida'}
          </span>
        </td>
        <td className='p-0'>
          <div className=''>
            <div className='row'>
              <div className='d-flex'>
                {[1, 2, 3, 4, 5].map((value) => (
                  <label key={value} className={`me-1 ${showRating ? 'cursor-pointer' : ''}`}>
                    <input
                      type='radio'
                      name='star-rating'
                      value={value}
                      checked={selectedRating === value}
                      onChange={() => handleRatingChange(value)}
                      style={{display: 'none'}} // Ocultar el radio button
                      disabled={!showRating}
                    />
                    <i
                      className={`fa-${
                        selectedRating >= value ? 'solid' : 'regular'
                      } fa-star d-inline-block`}
                      style={{fontSize: 15, color: mode === 'light' ? '#2d30e1' : 'white'}}
                    ></i>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </td>
        <td className='p-0'>
          <span className='fw-semibold d-block poppins-light'>{item.updateUser}</span>
        </td>
      </tr>
    </>
  )
}

export {TableItem}
