import React, { useEffect, useState } from 'react';

import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { TrueWordsInterface, TrueWordsItemQuestionsAnswer } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { AddTrueWordsRespone } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseTrueWordsModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';

type Props = {
    userMode?: ModeTypeExercise
    question: TrueWordsInterface[];
    responses?: TrueWordsItemQuestionsAnswer[];
};

const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '525px',
    minWidth: '525px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 20px 40px 20px'
};
const input = {
    display: 'flex',
    width: '90%',
    borderRadius: '40px',
    border: '0px',
    backgroundColor: '#D0FF00',
    outline: 'none',
    padding: '2px'
}
const arrayspan = [
    "There arein total.",
    "The bathroll.",
    "The parking spe house.",
    "The parking space i."
];
const arrayspantwo = [
    "Right version:",
    "Right version:",
    "Right version:",
    "Right version:"
];


const TrueWordsItem: React.FC<Props> = ({
    userMode,
    question,
    responses,
}: Props) => {
    const [results, setResults] = useState<string[]>([]);
    const [checked, setChecked] = useState<boolean>(false)
    const [accuracyPercentage, setAccuracyPercentage] = useState<number | null>(null);
    const [answers, setAnswers] = useState<TrueWordsItemQuestionsAnswer[]>([]);
    const {
        lessonData, 
        stepBookItem, 
        currentTaskId, 
        currentItemBook, 
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer
    } = UseBookStudentModule();

    const {exerciseId, instructions} = UseTrueWordsModule();
    const {modeViewAnswer} = UseModeCourseModule();

    useEffect(() => {
        if (responses && responses.length > 0) {
            setAnswers(responses);
        } else {
            setAnswers(question.map((x) => ({
                trueSentence: x.sentence,
                text: modeViewAnswer ? x.sentence : '',
                order: x.order,
                isTrue: false
            })));
        }
    }, [question, responses, modeViewAnswer]);

    // useEffect(() => {
    //     if (answers.length > 0) {
    //         const allAnsweredCheck = answers.every(x => x.text !== '');
    //         if (allAnsweredCheck) {
    //             calculateAccuracy();
    //         }
    //     }
    // }, [answers]);

    const handleSubmit = async () => {
        // ResetStepByExercises();
        if (lessonData && lessonData.evaluation && 
        lessonData.evaluation.evaluation_item){
            addCompletedExerciseId(exerciseId??'')
            if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId??'')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        }  
        if(userMode === 'STUDENT' && currentTaskId && exerciseId){
            try {
                const response = await AddTrueWordsRespone({
                    exercises: exerciseId??'',
                    task: currentTaskId??'',
                    percentage: 0,
                    isActive: true,
                    answers: answers
                });
                onChangeTaskByAnswer(response, 'true_words');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
            }
        }
    }  

    const resetExercise = () => {
        setChecked(false)
        setAnswers(question.map((x) => ({
            trueSentence: x.sentence,
            text: '',
            order: x.order,
            isTrue: false
        })))
    }

    const handleInputChange = (index: number, value: string) => {
        const newAnswers = [...answers];
        newAnswers[index] = { ...newAnswers[index], text: value };
        setAnswers(newAnswers);
        // const newAnswers = [...userAnswers];
        // newAnswers[index] = value;
        // setUserAnswers(newAnswers);
    };


    const handleCheck = (e :React.FormEvent<HTMLFormElement>) =>{
        e.preventDefault();
        const newResults = question.map((question, index) =>
            answers[index].text === question.sentence ? "Correcto" : "Incorrecto"
        );
        setResults(newResults);
        
        setChecked(true)
    }

    const calculateAccuracy = () => {
        const newAnswers = question.map((question, index) => {
            const userAnswerNormalized = answers[index]?.text?.toLowerCase().trim().replace(/\s+/g, ' ');
            const trueSentenceNormalized = question.sentence.toLowerCase().trim().replace(/\s+/g, ' ');
            const isTrue = userAnswerNormalized === trueSentenceNormalized;
            return {
                trueSentence: question.sentence,
                order: question.order,
                text: answers[index]?.text,
                isTrue: isTrue
            };
        });
        // setAnswers(newAnswers);
        const correctAnswersCount = newAnswers.filter(answer => answer.isTrue).length;
        const accuracy = (correctAnswersCount / question.length) * 100;
        setAccuracyPercentage(accuracy);
        setChecked(true);
    };

    const { setOffDraggable } = UseBookContainerModule()

    return (
        <>
            <div className="container-fluid " style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <HeaderCard />
                    {/*holaaaaaaaaa*/}
                    <p className="scroll-y overflow-y-auto text-white p-3 fs-7 mb-0 poppins-light mb-3" style={{maxHeight:'95px', marginLeft: '10px' }}>{instructions}</p>
                    <div className="scroll-y overflow-y-auto row d-flex px-9 fs-8 text-white container-fluid mb-4" style={{maxHeight:'300px'}}>
                        <div className="col-10 py-1 poppins-light fs-8">
                            <div className='row justify-content-center'>
                                {question.map((x, y) => (
                                    <div className="col-3 d-flex flex-column align-items-center" key={y}>
                                        {/* Imagen redonda con Lorem Picsum */}
                                        <img
                                            src={`${x.image}`}
                                            alt={`Random from Lorem Picsum for question ${y + 1}`}
                                            style={{ borderRadius: '50%', marginBottom: '10px' ,width:'80px', height: '80px' }}
                                        />
                                        {/* Input para la respuesta */}
                                        <input
                                            type="text"
                                            value={answers[y]?.text || ''}
                                            onChange={(e) => {
                                                handleInputChange(y, e.target.value);
                                            }}
                                            onMouseEnter={()=> setOffDraggable(true)}
                                            onMouseLeave={()=> setOffDraggable(false)}
                                            placeholder={` ${String.fromCharCode(97 + y)}.`}
                                            style={input}
                                        />
                                        
                                        {results[y] && <p>{results[y]}</p>}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-2 d-flex ">
                            {/*validacion*/}
                        </div>
                    </div>
                        <div className='d-flex justify-content-between'>
                            <div>
                                {checked &&
                                    <ButtonTryAgain onAction={resetExercise}/>
                                }
                            </div>
                            <div>
                                {checked && accuracyPercentage !== null && <p className='poppins-light text-white animate__animated  animate__headShake'>Porcentaje de acertividad: {accuracyPercentage.toFixed(2)}%</p>}
                            </div>
                            <ButtonSaveExercise
                                isChecked={checked}
                                onNext={calculateAccuracy}
                                onSubmit={handleSubmit}
                                exerciseId={exerciseId}
                            />
                        </div>
                </div>


                
            </div>
        </>
    );
};

export { TrueWordsItem };