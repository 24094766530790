import React, { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { UseModeCourseModule } from '../../../../app/modules/courses/components/front-page/store/main'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { showSimpleAlert } from '../../../../app/commonHooks/alert'
import {
  AddTaskByStudentSettingsManual,
  UseTrailClassByStudentWithCourse,
} from '../../../../app/modules/abroad/trail/services'
import { UseMyRoadmapModule } from '../../../../app/modules/profile/components/my-road-step/store'
import { UseEvaluationCourseModule } from '../../../../app/modules/courses/screen/store/main'
import { EvaluationType } from '../../../../app/modules/admin/evaluation/interfaces'
import { UseBookTestModule } from '../../../../app/modules/book/modules/test/store/main'
import { UseChapterAllByLearningPath } from '../../../../app/modules/abroad/learning-path/modules/chapter/services'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { UseBookTeacherModule } from '../../../../app/modules/book/modules/class/store/main'
import { UseBookStudentModule } from '../../../../app/modules/book/modules/screen/store/main'
import useAuth from '../../../../app/api/main'
import { TaskType } from '../../../../app/modules/admin/tasks/interfaces'

type Props = {
  courseId?: string
  colorCourse: string
  mode: 'ADMIN' | 'STUDENT' | 'TEACHER'
}

const TopicsEbookDrawer: FC<Props> = ({ courseId, mode, colorCourse }) => {
  const location = useLocation()
  const { typeUser } = useAuth()
  const {
    currentCourseId,
    lessonsMenu,
    setCurrentCourse,
    setLessonsId,
    setLessonsMenu,
  } = UseModeCourseModule()
  const { settings } = UseMyRoadmapModule.getState()
  const { lessonData } = UseBookTestModule.getState()
  const { setCurrentTask: setTaskBook } = UseBookStudentModule()
  const { lessonData: lessonTeacher } = UseBookTeacherModule()
  const { lessonData: lessonStudent } = UseBookStudentModule()
  const { setShowEndEvaluation, setCurrentTask, setStepEvaluation, resetEvaluationResponses } = UseEvaluationCourseModule()

  const [show, setShow] = React.useState<boolean>(false);
  const [appointmentID, setAppointmentID] = React.useState<string>();

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const navigate = useNavigate()

  const findLessonsByChapterAdmin = async (course: string) => {
    try {
      const response = await UseChapterAllByLearningPath(course)
      setLessonsMenu(response, 'EBOOK')
      if (response && response.length > 0) {
        console.log(response[0]);
        
        setLessonsId(
          response.flatMap(
            (chapter: any) =>
              chapter?.lessons &&
              chapter.lessons.flatMap(
                (unit: any) =>
                  unit.lessons &&
                  unit.lessons.map((lesson: any) => ({ 
                    id: lesson.id,
                    order: lesson.order,
                    chapter_order: chapter.chapter_order,
                    task: lesson.taskId, 
                    with_teacher: lesson.withTeacher,
                    typeScreen: lesson.typeScreen,
                    evaluationId: lesson.evaluation_id,
                    statusTask: lesson.statusTask,
                  })
                )
              )
          )
        )
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Tópicos', icon: 'error' })
    }
  }

  const findLessonsByChapter = async (id: string, idCourse: string) => {
    try {
      const response = await UseTrailClassByStudentWithCourse(id, idCourse)
      setLessonsMenu(response.result, 'EBOOK')
      setLessonsId(
        response.flatMap((chapter: any) =>
          chapter.lessons.flatMap((unit: any) =>
            unit.lessons.map((lesson: any) => ({ id: lesson.id, order: lesson.order, chapter_order: chapter.chapter_order,task: lesson.taskId, with_teacher: lesson.withTeacher }))
          )
        )
      )
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Topicos', icon: 'error' })
    }
  }

  const addTaskByStudent = async (id: string, code: string, type: TaskType) => {
    try {
      await AddTaskByStudentSettingsManual({
        lesson: id,
        student_setting: settings?.id ?? 'xx',
        code: code,
        description: '',
        isArtificial: false,
        isActive: true,
        type
      })
    } catch (e) {
      showSimpleAlert({ message: 'Error al cargar tarea', icon: 'error' })
    }
  }

  const isCorrectUUID = (value: string) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
    return uuidRegex.test(value)
  }

  React.useEffect(() => {
    if (courseId && isCorrectUUID(courseId)) {
      setCurrentCourse(courseId)
    }
  }, [courseId])

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(searchParams.entries())
    if ((params || typeof mode !== 'undefined') && currentCourseId) {
      findLessonsByChapterAdmin(currentCourseId)
    }
  }, [settings, currentCourseId, mode]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    if (params && params?.appointment) {
      setAppointmentID(params.appointment);
    }
  }, [location]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(searchParams.entries())
    const bookTest = location.pathname.includes('/book/test')
    const bookClass = location.pathname.includes('/book/class')
    const bookStudent = location.pathname.includes('/book/container')
    if (!bookTest) {
      const evaluationCourse = location.pathname.includes('/evaluation/screen/course/')
      if (evaluationCourse) {
        const pathId: boolean = lessonData?.chapter?.path?.id === currentCourseId
        setCurrentCourse(pathId ? lessonData?.chapter?.path?.id : currentCourseId)
      }
      if (!evaluationCourse) {
        if (lessonData && lessonData?.chapter && lessonData?.chapter?.path) {
          setCurrentCourse(lessonData.chapter.path.id)
        }
      }
    } else if (bookTest) {
      if (lessonData && lessonData?.chapter && lessonData?.chapter?.path) {
        setCurrentCourse(lessonData.chapter.path.id)
      }
    }
    if (bookClass) {
      if (lessonTeacher && lessonTeacher?.chapter && lessonTeacher.chapter?.path) {
        setCurrentCourse(lessonTeacher.chapter.path.id)
      }
    }
    if (bookStudent) {
      if (lessonStudent && lessonStudent?.chapter && lessonStudent.chapter?.path) {
        setCurrentCourse(lessonStudent.chapter.path.id)
      }
    }
  }, [lessonData, location, lessonTeacher, lessonStudent])

  const [selectedChapter, setSelectedChapter] = React.useState<number | null>(0)
  const [selectedUnit, setSelectedUnit] = React.useState<number | null>(null)
  const [selectedLesson, setSelectedLesson] = React.useState<number | null>(null)

  const handleChapterClick = (chapterIndex: number) => {
    setSelectedChapter(chapterIndex === selectedChapter ? null : chapterIndex)
    setSelectedUnit(null) // Deseleccionar la unidad al seleccionar otro capítulo
    setSelectedLesson(null) // Deseleccionar lección al seleccionar unidad
  }

  const handleUnitClick = (unitIndex: number) => {
    setSelectedUnit(unitIndex === selectedUnit ? null : unitIndex)
    setSelectedLesson(null)
  }

  const handleLessonClick = (lessonIndex: number) => {
    setSelectedLesson(lessonIndex === selectedLesson ? null : lessonIndex)
  }

  const nonClickedStyles = { color: 'white', background: colorCourse !== '' ? colorCourse : '#3e94ff' }
  const clickedStyles = { color: '#D0FF00', background: colorCourse !== '' ? colorCourse : '#3e94ff' }

  const nonClickedGrayStyles = { color: 'gray', background: colorCourse !== '' ? colorCourse : '#3e94ff' }
  const clickedGrayStyles = { color: '#D0FF00', background: colorCourse !== '' ? colorCourse : '#3e94ff' }

  const nonIconStyles = {
    color: 'white',
    width: '27px',
    height: '27px',
  }
  const IconStyles = {
    color: 'blue',
    width: '27px',
    height: '27px',
    background: '#D0FF00',
  }

  const IconGrayStyles = {
    color: 'gray',
    width: '27px',
    height: '27px',
    background: '#D0FF00',
  }

  const nonIconGrayStyles = {
    color: 'gray',
    width: '27px',
    height: '27px',
  }
  
  return (
    <>
      <button
        type='button'
        className='btn bg-white cursor-pointer champ-light py-2 p-2'
        style={{ borderRadius: '30px', color: '#2d30e1' }}
        onClick={handleShow}
      >
        <img
          className='me-3 ms-4'
          style={{ width: '10px' }}
          src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)}
          alt=''
        />
        <span className='pt-1 me-4'>Topics</span>
      </button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement='end'
        style={{ background: colorCourse !== '' ? colorCourse : '#3e94ff' }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className='mt-10'>
              <button
                type='button'
                onClick={handleClose}
                className='btn bg-white cursor-pointer p-2 py-1 champ-light d-flex justify-content-center align-items-center'
                style={{ borderRadius: '30px', color: '#2d30e1' }}
              >
                <img
                  className='me-1 ms-4'
                  style={{ width: '10px' }}
                  src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/right_arrow.svg`)}
                  alt=''
                />
                <span className='pt-1 me-4'>Topics</span>
              </button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='card-body'>
            <div className='d-flex flex-column scroll-y' style={{ maxHeight: '550px' }}>
              {/* Array Principal */}
              {lessonsMenu && lessonsMenu.sort((a, b) => a.chapter_order - b.chapter_order).map((chapter, i) => ( // 
                <div key={i} className=''>
                  <button
                    className='accordion-button mb-1 rounded-pill'
                    type='button'
                    style={{
                      backgroundColor: selectedChapter === i ? '#C6F711' : '',
                      color: selectedChapter=== i ? '#3E94FF':'white',
                      border: selectedChapter === i ? 'none':'1px solid',
                      
                    }}
                    data-bs-toggle='collapse'
                    data-bs-target={`#collapse${chapter.chapter_id}`}
                    aria-expanded={selectedChapter === i}
                    aria-controls={`collapse${chapter.chapter_id}`}
                    onClick={() => handleChapterClick(i)}
                  >
                    <div className='d-flex'>
                      <div className='d-flex align-items-center ms-7'>
                        <span  className={`${'champ-bold'} fs-6`} style={{marginTop:'4px' }}>
                          {chapter.chapter_name}
                        </span>
                      </div>
                    </div>
                  </button>
                  
                  <div
                    id={`collapse${chapter.chapter_id}`}
                    className={`accordion-collapse collapse ${selectedChapter === i ? 'show' : ''}`}
                    aria-labelledby={`heading${chapter.chapter_id}`}
                    data-bs-parent='#accordionSecondary'
                  >
                    <div className='accordion-body'>
                      <div className='accordion d-flex flex-column py-3'>
                        {chapter.lessons && chapter.lessons.map((unit, iU) => {
                          const isUnitSelected = selectedUnit === iU
                          const buttonStyle = isUnitSelected ? clickedStyles: nonClickedStyles
                          const buttonGrayStyle = isUnitSelected ? clickedGrayStyles: nonClickedGrayStyles
                          const iconStyle = isUnitSelected ? IconStyles : nonIconStyles
                          const iconGrayStyle = isUnitSelected ? IconGrayStyles : nonIconGrayStyles
                          return (
                            <div key={iU}>
                              <button
                                style={unit.lessons.find( x=> !x.isDisabled)? buttonStyle : buttonGrayStyle}
                                onClick={() => handleUnitClick(iU)}
                                className='rounded-pill border border-0 '
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapseUnit${iU}`}
                                aria-expanded={isUnitSelected}
                                aria-controls={`collapseUnit${iU}`}
                              >
                                <div className='d-flex'>
                                  <div
                                    className={`border border-${unit.lessons.find( x=> !x.isDisabled)? "white" : "dark border-opacity-25 "} rounded-circle d-flex align-items-center justify-content-center`}
                                    style={unit.lessons.find( x=> !x.isDisabled)? iconStyle : iconGrayStyle}
                                  >
                                    <span className='champ-bold m-0'>U{iU + 1}</span>
                                  </div>
                                  <div className='d-flex align-items-center ms-2'>
                                    <span className='champ-bold fs-5' >{unit.name}</span>
                                  </div>
                                </div>
                              </button> 
                            
                              <div
                                id={`collapseUnit${iU}`}
                                className={`accordion-collapse collapse ${isUnitSelected ? 'show' : ''}`}
                                aria-labelledby={`heading${iU}`}
                                data-bs-parent={`#collapse${chapter.chapter_id}`}
                              >
                                <div className='accordion-body p-2 '>
                                  <div className='accordion d-flex flex-column px-1'>
                                    {unit.lessons && unit.lessons.sort((a, b) => a.order - b.order).map((lesson, iL) => {
                                      const isLessonSelected = isUnitSelected && selectedLesson === iL
                                      const buttonStyleTwo = isLessonSelected ? clickedStyles : nonClickedStyles
                                      return (
                                        <div key={lesson.id}>
                                          <button
                                            className='d-flex align-items-center ms-2 cursor-pointer rounded-pill border-0 bg-transparent'
                                            data-bs-toggle='collapse'
                                            data-bs-target={`#collapseLesson${iU}-${iL}`}
                                            aria-expanded={isLessonSelected}
                                            aria-controls={`collapseLesson${iU}-${iL}`}
                                            disabled={lesson.isDisabled}
                                            onClick={() => handleLessonClick(iL)}
                                          >
                                            <div style={{width: '8px',height: '8px',background: `${isLessonSelected? '#D0FF00':'#ffffff'}`,borderRadius: '50px',marginRight: '10px'}}/>
                                            <span className='fs-6' style={lesson.isDisabled ? {color: 'gray'} : buttonStyleTwo}>
                                              {lesson.name}
                                            </span>
                                          </button>
                                          
                                          <div
                                            id={`collapseLesson${iU}-${iL}`}
                                            className={`accordion-collapse collapse ${isLessonSelected ? 'show' : ''}`}>
                                              
                                            <div className='accordion d-flex flex-column px-2 cursor-pointer'>
                                              {lesson.items && lesson.items.sort((a, b) => a.order - b.order).map((child, iI) => (
                                                <div
                                                  key={iI}
                                                  className='d-flex align-items-center px-12'
                                                  onClick={async () => {
                                                    if ( lesson.evaluation_id === null ||  lesson.typeScreen === null ) {
                                                      showSimpleAlert({ message: 'La lección no tiene una evaluación', icon: 'error',})
                                                      return
                                                    }
                                                    if (mode === 'STUDENT' && typeUser === 'STUDENT'  ) {                                                      
                                                    if ( lesson.statusTask === 'NOT_TASK') {
                                                      await addTaskByStudent(  lesson.id,'xx', TaskType.NORMAL)
                                                    }
                                                    if (lesson?.taskId) {
                                                      setCurrentTask(lesson.taskId)
                                                      setTaskBook(lesson.taskId)
                                                    }
                                                    setStepEvaluation(0)
                                                    setShowEndEvaluation(false)
                                                    resetEvaluationResponses()
                                                    if (lesson.typeScreen === EvaluationType.BOOK) {
                                                      navigate(`/book/container?lesson=${lesson.id}&mode=STUDENT`)
                                                    } else if (  lesson.typeScreen === EvaluationType.SINGLE_QUIZ) {
                                                      navigate( `/courses/container/${lesson.id}`)
                                                    } else if (lesson.withTeacher) {
                                                      await addTaskByStudent(
                                                        lesson.id,
                                                        'xx',
                                                        TaskType.NORMAL
                                                      )
                                                    } else {
                                                      navigate(`/evaluation/screen/course/${lesson.evaluation_id}`)
                                                    }
                                                  } else if (  mode === 'ADMIN' ||typeUser === 'ADMIN') {
                                                    if (lesson.typeScreen ===EvaluationType.SINGLE_QUIZ) {
                                                      navigate(`/evaluation/test/${lesson.evaluation_id}`)
                                                    } else if (lesson.typeScreen ===EvaluationType.BOOK  ) {
                                                      navigate(`/book/test?lesson=${lesson.id}&mode=ADMIN`)
                                                    } else {
                                                      navigate(`/evaluation/screen/course/${lesson.evaluation_id}`)
                                                    }
                                                  } else if (mode === 'TEACHER' ||  typeUser === 'TEACHER') {
                                                    if (lesson.typeScreen ===EvaluationType.BOOK) {
                                                      navigate(`/book/class?lesson=${lesson.id}&appointment=${appointmentID}&mode=TEACHER`)
                                                    }
                                                  }
                                                  handleClose()
                                                }}
                                              >
                                                {typeUser !== 'STUDENT' ?
                                                  <div style={{width: '13px',height: '13px',background: '#ffffff',borderRadius: '50px',marginRight: '10px'}}/>
                                                :
                                                  lesson.statusTask === 'PENDING' ?
                                                    <div style={{ width: '13px', height: '13px', border: '2px solid #ffffff', borderRadius: '50%', background: 'transparent', marginRight: '10px'}}/>
                                                  :
                                                  lesson.statusTask === 'REPROVED' ?
                                                  <i className="bi bi-x-circle-fill" style={{color: 'red'}}/>
                                                  :
                                                  <i className="bi bi-check-circle-fill" style={{color : '#D0FF00'}}/>
                                                }
                                                <span className='ms-2 fs-6' style={{ color: 'white' }} >
                                                  {child.name}
                                                </span>
                                              </div>
                                            ))}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* End Array Principal */}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export { TopicsEbookDrawer }
