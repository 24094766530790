/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import './Login.css';

import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import useAuth from '../../../api/main';
import { logoAppSelector, logoAppSelectorUrl } from '../../../hooks/useAppSelectorAssetsMode'
let url = logoAppSelectorUrl();
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginPlatform() {
  const { login, username } = useAuth();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const [buttonStyles, setButtonStyles] = useState({
    backgroundColor: '#1bebd4',
    color: '#2d30e1',
  });

  const [buttonStylesRegister, setButtonStylesRegister] = useState({
    backgroundColor: '#FFFFFF',
    color: '#2d30e1',
  });

  const [buttonStylesInfo, setButtonStylesInfo] = useState({
    backgroundColor: '#1bebd4',
    color: '#2d30e1',
  })

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })

  const initialValues = {
    email: '',
    password: '',
  }


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        await login(values.email, values.password);
        navigate('/dashboard')
        //login(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        //  setCurrentUser(user)
      } catch (error) {
        console.error(error)
        //(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='w-100'
      style={{
        overflowY: 'hidden',
        minHeight: '100vh',
        height: '100%',
      }}>
      <img
        src={toAbsoluteUrl('/media/svg/michigan-icons/image_login_min.png')}
        alt="Background"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      <div className='row w-100 d-none d-md-flex'>

        <div className='col-lg-4 col-md-6'>
          <div className='mt-20 ms-20 col-12'>
            <img
              src={toAbsoluteUrl(logoAppSelector('LIGHT'))}
              alt=""
              className='img-fluid title'
              style={{ width: '22%' }}
            />
          </div>
          <div className='mt-15 ms-17 col-12'>
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/TITULO_blanco.svg')}
              alt=""
              className='img-fluid'
              style={{ width: '83%' }}
            />
          </div>
          <div className='mt-6 col-12'>
            <a href={url.www} className='poppins-light text-decoration-none text-white ms-20'>
              {url.www}
            </a>
          </div>
        </div>

        <div className='col-lg-4 col-md-6'>
          <div className='d-flex align-items-end h-100 justify-content-center' style={{ marginTop: '-16%' }}>
            <a href={url.www} className='btn'
              style={{
                borderRadius: 30,
                transition: 'background-color 0.3s, color 0.3s',
                ...buttonStylesRegister
              }}
              onMouseOver={() => {
                setButtonStylesRegister({
                  backgroundColor: '#2d30e1',
                  color: '#ffffff',
                });
              }}
              onMouseOut={() => {
                setButtonStylesRegister({
                  backgroundColor: '#FFFFFF',
                  color: '#2d30e1',
                });
              }}
            >
              <span className='p-2 py-0 fs-1 poppins-light d-flex justify-content-center align-items-center'>
                Regístrate
                <div className='symbol symbol-30px ms-2'>
                  <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${buttonStylesRegister.color === '#ffffff' ? 'register_white' : 'register_blue'}.svg`)} alt="" />
                </div>
              </span>
            </a>
            <a href={url.info} className='btn ms-4'
              style={{
                borderRadius: 30,
                transition: 'background-color 0.3s, color 0.3s',
                ...buttonStylesInfo
              }}
              onMouseOver={() => {
                setButtonStylesInfo({
                  backgroundColor: '#2d30e1',
                  color: '#ffffff',
                });
              }}
              onMouseOut={() => {
                setButtonStylesInfo({
                  backgroundColor: '#1bebd4',
                  color: '#2d30e1',
                });
              }}
            >
              <span className='p-2 py-0 fs-1 poppins-light d-flex justify-content-center align-items-center'>
                Más info
                <div className='symbol symbol-30px ms-2'>
                  <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${buttonStylesInfo.color === '#ffffff' ? 'info_white' : 'info_blue'}.svg`)} alt="" />
                </div>
              </span>
            </a>
          </div>
        </div>

        <div className='col-lg-3 col-md-12 d-flex flex-column justify-content-center align-items-center'>
          <div className='col-12 d-flex justify-content-center align-items-center'>
            <form
              className='form w-100 row d-flex justify-content-center align-items-center'
              onSubmit={formik.handleSubmit}
              style={{
                backgroundColor: '#1bebd4',
                borderRadius: '50px',
                background: 'linear-gradient(to bottom, #1bebd4 70%, rgba(27, 235, 212, 0.6))',
                margin: '20px 0',
              }}
              noValidate
              id='kt_login_signin_form'
            >
              <div className='col-12 mb-5 pt-10'>
                <div className='d-flex justify-content-center align-items-center'>
                  <img
                    src={toAbsoluteUrl('/media/svg/michigan-icons/get_ready.svg')}
                    alt=""
                    className='img-fluid'
                    style={{ width: '70%' }}
                  />
                </div>
              </div>

              <div className='col-12 mb-5 p-10 pt-0'>
                <div className='col-12 mb-3'>
                  <label className='form-label poppins-bold' style={{ color: '#2d30e1', marginBottom: '-2px' }}>Correo</label>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                    style={{
                      borderBottom: '1px solid #2d30e1',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      color: '#2d30e1'
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                <div className='col-12'>
                  <label className='form-label poppins-bold' style={{ color: '#2d30e1' }}>Password</label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                    style={{
                      borderBottom: '1px solid #2d30e1',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      color: '#2d30e1'
                    }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-12 mb-10 d-flex justify-content-center align-items-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn w-100 mx-4'
                  disabled={formik.isSubmitting || !formik.isValid}
                  style={{
                    borderRadius: 30,
                    border: '1px solid #2d30e1',
                    transition: 'background-color 0.3s, color 0.3s',
                    ...buttonStyles,
                  }}
                  onMouseOver={() => {
                    setButtonStyles({
                      backgroundColor: '#2d30e1',
                      color: '#ffffff',
                    });
                  }}
                  onMouseOut={() => {
                    setButtonStyles({
                      backgroundColor: '#1bebd4',
                      color: '#2d30e1',
                    });
                  }}
                >
                  {!loading && <span className='indicator-label poppins-light fs-1'>Entrar</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <div className='col-12 d-flex justify-content-center align-items-center pb-10'>
                <a href='/auth/forgot-password' className='text-decoration-none poppins-light' style={{ color: '#2d30e1' }}>Olvidé mi contraseña</a>
              </div>
            </form>
          </div>

          <div className='col-12 mt-3 d-flex justify-content-center align-items-center'>
            <a href={url.facebook} className='text-decoration-none'>
              <i className="fa-brands fa-facebook-f text-white" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.instagram} className='text-decoration-none'>
              <i className="fa-brands fa-instagram text-white ms-9 cursor-pointer" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.youtube} className='text-decoration-none'>
              <i className="fa-brands fa-youtube text-white ms-9" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.in} className='text-decoration-none'>
              <i className="fa-brands fa-linkedin-in text-white ms-9" style={{ fontSize: 30 }}></i>
            </a>
            <a href={url.tiktok} className='text-decoration-none'>
              <i className="fa-brands fa-tiktok text-white ms-9" style={{ fontSize: 30 }}></i>
            </a>
          </div>
        </div>
      </div>

      {/* Movil */}
      <div className='d-md-none d-flex flex-column'>
        <div className='mt-20 d-flex justify-content-center align-items-center'>
          <img
            src={toAbsoluteUrl(logoAppSelector('LIGHT'))}
            alt=""
            className='img-fluid title'
            style={{ width: '22%' }}
          />
        </div>

        <div className='mt-10 d-flex justify-content-center align-items-center'>
          <form
            className='form w-75 row d-flex justify-content-center align-items-center'
            onSubmit={formik.handleSubmit}
            style={{
              backgroundColor: '#1bebd4',
              borderRadius: '50px',
              background: 'linear-gradient(to bottom, #1bebd4 70%, rgba(27, 235, 212, 0.6))',
              margin: '20px 0',
            }}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='col-12 mb-5 pt-10'>
              <div className='d-flex justify-content-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/svg/michigan-icons/get_ready.svg')}
                  alt=""
                  className='img-fluid'
                  style={{ width: '70%' }}
                />
              </div>
            </div>

            <div className='col-12 mb-5 p-10 pt-0'>
              <div className='col-12 mb-3'>
                <label className='form-label poppins-bold' style={{ color: '#2d30e1', marginBottom: '-2px' }}>Correo</label>
                <input
                  placeholder=''
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                  style={{
                    borderBottom: '1px solid #2d30e1',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    color: '#2d30e1'
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>
              <div className='col-12'>
                <label className='form-label poppins-bold' style={{ color: '#2d30e1' }}>Password</label>
                <input
                  type='password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                  style={{
                    borderBottom: '1px solid #2d30e1',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    color: '#2d30e1'
                  }}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 mb-10 d-flex justify-content-center align-items-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn w-100 mx-4'
                disabled={formik.isSubmitting || !formik.isValid}
                style={{
                  borderRadius: 30,
                  border: '1px solid #2d30e1',
                  transition: 'background-color 0.3s, color 0.3s',
                  ...buttonStyles,
                }}
                onMouseOver={() => {
                  setButtonStyles({
                    backgroundColor: '#2d30e1',
                    color: '#ffffff',
                  });
                }}
                onMouseOut={() => {
                  setButtonStyles({
                    backgroundColor: '#1bebd4',
                    color: '#2d30e1',
                  });
                }}
              >
                {!loading && <span className='indicator-label poppins-light fs-1'>Entrar</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            <div className='col-12 d-flex justify-content-center align-items-center pb-10'>
              <a href='/auth/forgot-password' className='text-decoration-none poppins-light' style={{ color: '#2d30e1' }}>Olvidé mi contraseña</a>
            </div>
          </form>
        </div>

        <div className='d-flex align-items-center h-100 justify-content-center mt-5'>
          <a href='#' className='btn'
            style={{
              borderRadius: 30,
              transition: 'background-color 0.3s, color 0.3s',
              ...buttonStylesRegister
            }}
            onMouseOver={() => {
              setButtonStylesRegister({
                backgroundColor: '#2d30e1',
                color: '#ffffff',
              });
            }}
            onMouseOut={() => {
              setButtonStylesRegister({
                backgroundColor: '#FFFFFF',
                color: '#2d30e1',
              });
            }}
          >
            <span className='p-2 fs-1 poppins-light'>
              Regístrate
              <i className='fa-regular fa-circle-right ms-5 fs-1' style={{ color: buttonStylesRegister.color }}></i>
            </span>
          </a>
          <a href='#' className='btn ms-4'
            style={{
              borderRadius: 30,
              transition: 'background-color 0.3s, color 0.3s',
              ...buttonStylesInfo
            }}
            onMouseOver={() => {
              setButtonStylesInfo({
                backgroundColor: '#2d30e1',
                color: '#ffffff',
              });
            }}
            onMouseOut={() => {
              setButtonStylesInfo({
                backgroundColor: '#1bebd4',
                color: '#2d30e1',
              });
            }}
          >
            <span className='p-2 fs-1 poppins-light'>
              Más info
              <i className='fa-solid fa-play ms-5 fs-1' style={{ color: buttonStylesInfo.color }}></i>
            </span>
          </a>
        </div>

        <div className='mt-10 d-flex justify-content-center align-items-center'>
          <a href="https://www.facebook.com/michiganmaster" className='text-decoration-none'>
            <i className="fa-brands fa-facebook-f text-white" style={{ fontSize: 20 }}></i>
          </a>
          <a href="https://www.instagram.com/michiganmastersas/" className='text-decoration-none'>
            <i className="fa-brands fa-instagram text-white ms-9 cursor-pointer" style={{ fontSize: 20 }}></i>
          </a>
          <a href="https://www.youtube.com/c/MichiganMasterColombia" className='text-decoration-none'>
            <i className="fa-brands fa-youtube text-white ms-9" style={{ fontSize: 20 }}></i>
          </a>
          <a href="#" className='text-decoration-none'>
            <i className="fa-brands fa-linkedin-in text-white ms-9" style={{ fontSize: 20 }}></i>
          </a>
          <a href="https://tiktok.com/@michiganmasterco" className='text-decoration-none'>
            <i className="fa-brands fa-tiktok text-white ms-9" style={{ fontSize: 20 }}></i>
          </a>
        </div>

        <div className='mt-5 d-flex justify-content-center align-items-center'>
          <a href='https://www.michiganmaster.edu.co/' className='poppins-light text-decoration-none text-white'>
            www.michiganmaster.edu.co
          </a>
        </div>
      </div>
    </div>

  )
}
