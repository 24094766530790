import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { TeacherResponseExercise } from "../../../../admin/exercises/interfaces";
import { api } from "../../../../auth/services/auth.services";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseAudioTest {
    exercises: string;
    tasks: string;
    description: string;
    isActive: boolean;
    // percentage: number;
    answers: any[];
}
interface AudioTestResponseTeacherIE {
  question :number
  observation :string
  percentage :number
}
interface TeacherAudioTestResponse {
  response_teacher: AudioTestResponseTeacherIE[];
  percentage: number;
}

export const AddAudioTestResponse = async (data: ResponseAudioTest): Promise<any> => {
    try {
      const response: any = await api.post(
        '/audio-test-response',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }

  export const AddAudioTestTeacherResponse = async (id:string, data:TeacherAudioTestResponse ): Promise<any>=> {
    try {
      const response: any = await api.patch(
        `audio-test-response/${id}`,
        {
          ...data,
        },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch  (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }