import React from "react";
import { DataRoadMap } from "../../../road-map/interfaces";
import { SingleWidget } from "../../../../../components/common/widgets/single-widget";

type Props = {
    item: any;
};

const RoadMapStepWidget: React.FC<Props> = ({item}) => {
    return (
        <div className='row g-5'>
            <div className="col-xl-12">
                <SingleWidget
                    className="card-xl-stretch"
                    avatar="/media/svg/extra/book.svg"
                    title={`Road Map: ${item.name??''}`}
                    description={`Description: ${item.description??''}`}
                />
            </div>
        </div>
    )
};

export {RoadMapStepWidget};