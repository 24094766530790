/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
 
type Props = {
  className: string
  title: string
  description: string
  avatar: string
}

const TeacherWidgets: React.FC<Props> = ({className, title, description, avatar}) => {
  return (
    <div className={`card ${className}`} style={{ background: '#a500b7', borderRadius: 30 }}>
      {/* begin::Body */}
      <div className='card-body d-flex align-items-center pt-3 pb-0'>
        <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
          <a href='#' className=' champ-bold fs-4 mb-2  ' style={{color:'#d1ff00'}}>
            {title}
          </a>

          <span
            className=' champ-light'
            style={{color:'#d1ff00'}}
            dangerouslySetInnerHTML={{__html: description}}
          ></span>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TeacherWidgets}