import {Route, Routes} from 'react-router-dom'
import { EvaluationDetails } from '../components/details'
import { EvaluationTableList } from '../components/list'
import TestContainer from '../modules/test/components'
import { EvaluationTableListByLesson } from '../components/list/listByLesson'
import { ScreenEvaluationCourse } from '../modules/screen'


const EvaluationPage = () => (
  <Routes>
      <Route path='/screen/course/:id' element={<ScreenEvaluationCourse />} />
      <Route path='/screen/test/:id' element={<ScreenEvaluationCourse />} />
      <Route path='/details' element={<EvaluationDetails />} />
      <Route path='/test/:id' element={<TestContainer />} />
      <Route path='/:id' element={<EvaluationTableListByLesson />} />
      <Route path='/' element={<EvaluationTableList />} />
  </Routes>
)

export {EvaluationPage}
