import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { TaskStatus } from "../../../../admin/tasks/interfaces";
import { api } from "../../../../auth/services/auth.services";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseSimpleSelectionQuestions {
    exercises: string;
    task: string;
    description: string;
    isActive: boolean;
    percentage: number;
    false_answer: any[];
    true_answer: any[];
}

export const AddSimpleSelectionQuestionsResponse = async (data: ResponseSimpleSelectionQuestions): Promise<any> => {
    try {
      const response: any = await api.post(
        '/simple-selection-questions-response',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }

export const OneTaskByFindOneTask = async (id: string): Promise<any> => {
  try {
    const response: any = await api.get(
      `/tasks/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }

    throw 'Error'
  }
}

export const updateTaskStatusById = async(id: string, status: TaskStatus): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/tasks/${id}`,
      {
        status
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }

    throw 'Error'
  }
}