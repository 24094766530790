import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';

type Props = {};

const MyAppointmentNotification: React.FC<Props> = () => {
    const [tab, setTab] = React.useState<string>('')
    return(<>
        <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary me-6 ` + 
                    (tab === '' && 'active')}
                    onClick={() => setTab('')}>
                    {/* <i className={`bi ${route.icon}`} style={{marginRight: '5px'}}/> */}
                    <span style={{paddingLeft: '5px'}}>My appointment</span>
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link text-active-primary me-6 ` + 
                    (tab === 'CLASS' && 'active')}
                    onClick={() => setTab('CLASS')}>
                    {/* <i className={`bi ${route.icon}`} style={{marginRight: '5px'}}/> */}
                    <span style={{paddingLeft: '5px'}}>Step</span>
                  </a>
                </li>
            </ul>
        </div>
        {tab === '' && 
            <div className='timeline-item pt-6'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                    <div className='symbol-label bg-light'>
                        <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
                    </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                    <div className='overflow-auto pe-3'>
                    <div className='fs-5 fw-bold mb-2'>
                        Invitation for crafting engaging designs that speak human workshop
                    </div>

                    <div className='d-flex align-items-center mt-1 fs-6'>
                        <div className='text-muted me-2 fs-7'>Sent at 4:23 PM by</div>

                        <div
                        className='symbol symbol-circle symbol-25px'
                        data-bs-toggle='tooltip'
                        data-bs-boundary='window'
                        data-bs-placement='top'
                        title='Alan Nilson'
                        >
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        }

        {tab === 'CLASS' && 
            <div className='timeline-item pt-6'>
                <div className='timeline-line w-40px'></div>

                <div className='timeline-icon symbol symbol-circle symbol-40px'>
                    <div className='symbol-label bg-light'>
                    <KTIcon iconName='pointers' className='fs-2 text-gray-500' />
                    </div>
                </div>

                <div className='timeline-content mb-10 mt-n2'>
                    <div className='overflow-auto pe-3'>
                        <div className='fs-5 fw-bold mb-2'>
                            holaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
                        </div>

                        <div className='d-flex align-items-center mt-1 fs-6'>
                            <div className='text-muted me-2 fs-7'>Sent at 4:23 PM by</div>

                            <div
                            className='symbol symbol-circle symbol-25px'
                            data-bs-toggle='tooltip'
                            data-bs-boundary='window'
                            data-bs-placement='top'
                            title='Alan Nilson'
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        
    </>)
};

export {MyAppointmentNotification}