import React from 'react';
import { BookPage } from '../../../admin/book/modules/book-page/interfaces';
import MultiplePages from './multiplePages';
import useScrollAndZoom from '../../../../hooks/useScrollAndZoom';

type Props = {
  pagesBook: BookPage[];
  numberRenderPage: 1 | 2;
  numberPage: number;
};

const RenderBook: React.FC<Props> = ({ pagesBook, numberRenderPage, numberPage }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerSmallRef = React.useRef<HTMLDivElement>(null);
  const { handleScroll, handleMouseDown, handleMouseMove, handleMouseUp, zoom, isDragging } = useScrollAndZoom(containerRef);
  const { 
    handleScroll: handleScrollMovil, 
    handleMouseDown: handleMouseDownMovil, 
    handleMouseMove: handleMouseMoveMovil, 
    handleMouseUp: handleMouseUpMovil, 
    zoom: zoomMovil, 
    isDragging: isDraggingMovil } = useScrollAndZoom(containerSmallRef);
  
  if (numberRenderPage === 1 && pagesBook.length > 0 && numberPage) {
    const currentPage = pagesBook[numberPage - 1];
    if (!currentPage) {
      return(
        <>
          <div
          className='d-flex justify-content-center'
          style={{
            position: 'fixed',
            top: 120,
            left: 160,
            right: 296,
            bottom: 20,
            backgroundColor: '#3e94ff',
            borderRadius: '50px'
          }}
          >
            {/* //champ-bold    champ-light  poppins-light poppins-bold  */}
            <p className='champ-light text-white mt-8 fs-2 '>PAGINA NO DISPONIBLE</p>
          </div>
        </>
      )
    }
    return(<>
      <div
          ref={containerRef}
          className='d-none d-lg-flex justify-content-center'
          style={{
            position: 'fixed',
            top: 120,
            left: 160,
            right: 296,
            bottom: 20,
            backgroundColor: '#FFFFFF',
            borderRadius: '50px',
            overflow: 'auto',
          }}
          onWheel={handleScroll}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <img
            key={currentPage.id}
            src={currentPage.file.location}
            alt=''
            style={{
              transform: `scale(${zoom / 100})`,
              transformOrigin: 'top left',
              cursor: isDragging ? 'grabbing' : 'grab', 
              width: 'fit-content',
              maxWidth: '100%',
              maxHeight: '100%',
              borderTopLeftRadius: '50px',
              borderBottomLeftRadius: '50px',
              borderTopRightRadius: '50px',
              borderBottomRightRadius: '50px',
            }}
            draggable
            onDragStart={(event) => event.preventDefault()}
          />
      </div> 

      <div
          ref={containerSmallRef}
          className='d-flex d-lg-none justify-content-center img-thumbnail'
          style={{
            position: 'fixed',
            top: 130,
            left: 0,
            right: 0,
            bottom: 80,
            backgroundColor: '#FFFFFF',
            borderRadius: '50px',
            overflow: 'auto',
          }}
          onWheel={handleScrollMovil}
          onMouseDown={handleMouseDownMovil}
          onMouseMove={handleMouseMoveMovil}
          onMouseUp={handleMouseUpMovil}
        >
          <img
            key={currentPage.id}
            src={currentPage.file.location}
            alt=''
            style={{
              transform: `scale(${zoomMovil / 100})`,
              transformOrigin: 'top left',
              cursor: isDraggingMovil ? 'grabbing' : 'grab', 
              width: 'fit-content',
              borderTopLeftRadius: '50px',
              borderBottomLeftRadius: '50px',
              borderTopRightRadius: '50px',
              borderBottomRightRadius: '50px',
            }}
            draggable
            onDragStart={(event) => event.preventDefault()}
          />
      </div>
    </>)
  }

  return (<>
    <MultiplePages pagesBook={pagesBook}/>
  </>)
};

export { RenderBook };