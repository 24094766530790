import React, { useEffect, useState } from 'react';
import { es } from 'date-fns/locale';
import { addDays, format } from 'date-fns';
import { DateRange, DayPicker } from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/dist/style.css';
import useDateRange from './utils-date';
import { useThemeMode } from '../../../../_metronic/partials';
 
type Props = {
  day: Date|undefined;
  list: moment.Moment[];
  changeDate: (date: Date) => void;
}
const DayPickerRangeBookingSelector: React.FC<Props> = ({day,list,changeDate}: Props) => {

  const {mode} = useThemeMode();
  const {dates}=useDateRange(list);
  const changeDateEvent = (e)=>{
    changeDate(e)
  }
  // useEffect(() => {
  //   console.log(list)
  // }, [list])
  return (
    <DayPicker
      className='champ-bold'
      id="test"
      mode="single"
      disabled={dates}
      locale={es}
      selected={day}
      defaultMonth={moment().toDate()}
      onSelect={changeDateEvent}
      style={{color:mode==='light'?'#2d30e1':'#FFFFFF'}}
    />
  );
}
export { DayPickerRangeBookingSelector }