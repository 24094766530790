import {Route, Routes} from 'react-router-dom'
import { BookDetails } from '../components/details'
import { BookTableList } from '../components/list'


const BookPage = () => (
  <Routes>
      <Route path='/details' element={<BookDetails />} />
      <Route path='/' element={<BookTableList />} />
  </Routes>
)

export {BookPage}
