import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CertificateItem, CertificateItemStatus } from '../../interfaces'

import { CertificateItemAdd, CertificateItemUpdate } from '../../services'
import { UseCertificateItemModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { setTypeToSpanish } from '../../../user/utils/setTypeToSpanish'
import { ResolutionCertificate } from '../../../resolution-certificate/interfaces'
import { UseResolutionCertificateAll } from '../../../resolution-certificate/services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'

export type CertificateItemFormFields = {
  date: string;
  numberResolution: number;
  name: string;
  resolutionText: string;
  user_id?: string;
  dni: string;
  conf_certificate: any
  code: string;
  prefix: string;
  status: string;
  isActive: boolean
  description?: string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const CertificateItemForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const [resolutions, setResolutions] = useState<ResolutionCertificate[]>([]);
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView, student } = UseCertificateItemModule();
  const [data, setData] = useState<CertificateItem>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<CertificateItemFormFields>({
      initialValues: {
        date: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.date ?? '' : '',
        numberResolution: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.numberResolution ?? 0 : 0,
        code: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.code ?? '' : '',
        resolutionText: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.resolutionText ?? '' : '',
        user_id: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.user_id ?? '' : '',
        dni: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.dni ?? `${student.document_number ?? ''}` : `${student.document_number ?? ''}`,
        conf_certificate: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.conf_certificate ?? '' : '',
        prefix: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.prefix ?? '' : '',
        status: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.status ?? 'PENDING' : 'PENDING',
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? `${student.first_name.toUpperCase() ?? ''} ${student.last_name.toUpperCase() ?? ''}` : `${student.first_name.toUpperCase() ?? ''} ${student.last_name.toUpperCase() ?? ''}`,
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await CertificateItemAdd({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['certificate-item'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await CertificateItemUpdate({ ...formData })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['certificate-item'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })

  const fetchCategory = async () => {
    try {
      const response = await UseResolutionCertificateAll();
      setResolutions(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Categorias!', icon: 'error' })
      throw 'Error'
    }
  };
  React.useEffect(() => {
    fetchCategory();
  }, []);
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Configuración de resolución</label>
            <select
              disabled={mode === 'VIEW'}
              name={'conf_certificate'}
              value={values.conf_certificate}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option selected></option>
              {resolutions.map((x) =>
                (<option value={x.id}>{x.id}</option>)
              )}
            </select>
            {/* {touched.conf_certificate && errors.conf_certificate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.date}</span>
                </div>
              </div>
            )} */}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Fecha en letras</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'date'}
              value={values.date}
              onChange={handleChange}
            />
            {touched.date && errors.date && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.date}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Número Consecutivo</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'numberResolution'}
              value={values.numberResolution}
              onChange={handleChange}
            />
            {touched.numberResolution && errors.numberResolution && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.numberResolution}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Estudiante</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Documento de identidad</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'dni'}
              value={values.dni}
              onChange={handleChange}
            />
            {touched.dni && errors.dni && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.dni}</span>
                </div>
              </div>
            )}
          </div>





          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {touched.code && errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Prefijo</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'prefix'}
              value={values.prefix}
              onChange={handleChange}
            />
            {touched.prefix && errors.prefix && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.prefix}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Estado</label>
            <select
              disabled={mode === 'VIEW'}
              name='level'
              value={values.status}
              onChange={handleChange}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(CertificateItemStatus).map((x) => (
                <option key={x} value={x}>
                  {setTypeToSpanish(x)}
                </option>
              ))}
            </select>
            {touched.status && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{touched.status}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode === 'VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { CertificateItemForm }
