export const NotificationsTranslateEn = {
  STANDARD: 'Standard',
  COMMENT_POST : 'Comment Post',
  COMMENT_PROJECT : 'Comment Project',
  FORGOT_PASSWORD : 'Forgot Password',
  INTERNAL : 'Internal',
  TICKET_CREATED : 'Ticket Created',
  TICKET_RESPONSE : 'Ticket Response',
  CALENDAR_EVENT_INVITED : 'Calendar Event Invited',
  CALENDAR_EVENT_UPDATED : 'Calendar Event Updated',
  CALENDAR_EVENT_DELETED : 'Calendar Event Deleted',
  NEW_APPOINTMENT_RESERVED : 'New Appointment Reserved',
  APPOINTMENT_CANCELED : 'Appointment Canceled',
  APPOINTMENT_STARTER : 'Appointment Starter',
  STRIKE_ADDED : 'Strike Added',
  SCHEDULING_REMINDER : 'Scheduling Reminder',
  YOU_ARE_SANCTIONED: 'You Are Sanctioned',
  NOTIFICATIONS: 'Notifications',
  TOPIC_SELECTED: 'Topic selected'
  }