import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Classroom} from '../../interfaces'

import { ClassroomAdd, ClassroomUpdate } from '../../services'
import { UseClassroomModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { Country } from '../../../country/interfaces'
import { UseCountryAll } from '../../../country/services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { UseCampusAll } from '../../../../abroad/campus/services'
import { Campus } from '../../../../abroad/campus/interfaces'

export type FormFields = {
  name: string
  address: string
  description: string
  url: string
  country:string
  isActive: boolean
  type: string,
  campus: string,
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  address: Yup.string().required('Address es requerido'),
  url: Yup.string().required('Url es requerido'),
  description: Yup.string().required('description es requerido'),
  country: Yup.string().required('country es requerido'),
  isActive: Yup.boolean().required('country es requerido'),
  type: Yup.string().required('country es requerido'),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const ClassroomForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseClassroomModule();
  const [campus, setCampus] = useState<Campus[]>([])
  const [countries, setCountries] = React.useState<Country[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        url: (mode==='EDIT' || mode==='VIEW')? itemSelected?.url??'': '',
        address:(mode==='EDIT' || mode==='VIEW')? itemSelected?.address??'': '',
        type:(mode==='EDIT' || mode==='VIEW')? itemSelected?.type??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
        country:(mode==='EDIT' || mode==='VIEW')? itemSelected?.country.id??'':'',
        campus:(mode==='EDIT' || mode==='VIEW')? itemSelected?.campus?.id??'':''
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await ClassroomAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['classroom'] })

            }catch(e){
              setLoading(false);
            }
          }else{
            try{
              setLoading(true);
              await  ClassroomUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['classroom'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
   const fetchCountries = async () => {
      try {
        let countries = await UseCountryAll()
        setCountries(countries)
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontar paises!', icon: 'error' })
        throw 'Error'
      }
    }

    const fetchCampus = React.useCallback(async () => {
      try {
        let campus = await UseCampusAll()
        setCampus(campus)
      } catch (e) {
        showSimpleAlert({ message: 'Error al encontar sedes academicas!', icon: 'error' })
        throw 'Error'
      }
    }, [])

    useEffect(() => {
      fetchCountries();
      fetchCampus();
    }, [])
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-1 col-6'>
             <label className='form-label mb-3'>Estado</label>
             <select
               disabled={mode === 'VIEW'}
               name={'type'}
               value={values.type}
               onChange={handleChange}
               className="form-select" aria-label="Default select example">
               <option selected></option>
                  <option value={'VIRTUAL'}>Virtual</option>
                  <option value={'FACE'}>Presencial</option>
             </select>
             {errors.type && (
               <div className='fv-plugins-message-container'>
                 <div className='fv-help-block'>
                   <span role='alert'>{errors.type}</span>
                 </div>
               </div>
             )}
          </div>

          <div className='mb-10 col-6'>
                <label className='form-label mb-3'>País</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'country'}
                  value={values.country}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {countries.map((x) => (<option value={x.id}>{x.name}</option>))}
                </select>
                {errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.country}</span>
                    </div>
                  </div>
                )}
              </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Campus</label>
            <select
              disabled={mode === 'VIEW'}
              name={'campus'}
              value={values.campus}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option value={undefined}></option>
              {campus.map((x) => (<option value={x.id}>{x.name}</option>))}
            </select>
            {errors.campus && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.campus}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Nombre</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>URL</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'url'}
              value={values.url}
              onChange={handleChange}
            />
            {touched.url && errors.url && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.url}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Dirección</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'address'}
              value={values.address}
              onChange={handleChange}
            />
            {touched.address && errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.address}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {ClassroomForm}
