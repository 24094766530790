interface Quiz {
    title:string
    questions:QuestionBasicVocabulary[]
    instructions:string
}
export interface QuestionBasicVocabulary {
    description: string
    name :string
    order:number
    answers:Answer[]
    mode?:'IMG'|'WORD'
    timer:number
}

export interface QuestionAndAnswer {
    name :string
    order:number
    answers:Answer[],
    timer:number
    answer:Answer
}

export interface Answer {
    title :string
    order:number
    isTrue:boolean
    root:number
    feedback: string
}

export interface Resume {
    step:number
    trueAnswer:Answer[]
    falseAnswer:Answer[]
    list:Answer[], 
    total:number
}


export const questions: QuestionBasicVocabulary[] = [
    {
        name: "¿Cuál es la capital de Francia?",
        order: 1,
        timer: 30,
        description: "",
        mode:'IMG',
        answers: [
            { title: "https://th.bing.com/th/id/OIP.uteB-qZab5QwHTAx3w_pfgHaE7?w=237&h=180&c=7&r=0&o=5&pid=1.7", order: 1, isTrue: true, root: 1, feedback: "" },
            { title: "https://flagsweb.com/images/PNG/Flag_of_Australia.png", order: 2, isTrue: false, root: 1, feedback: "" },
            { title: "https://flagsweb.com/images/PNG/Flag_of_Ukraine.png", order: 3, isTrue: false, root: 1, feedback: "" },
            { title: "https://a57.foxnews.com/a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2018/09/640/320/1862/1048/palestinianflag.jpg?ve=1&tl=1?ve=1&tl=1", order: 4, isTrue: false, root: 1, feedback: "" },
        ],
    },
    {
        name: "¿Cuántos planetas tiene el sistema solar?",
        mode:'WORD',
        order: 2,
        timer: 30,
        description: "",
        answers: [
            { title: "8", order: 1, isTrue: true, root: 2 , feedback: ""},
            { title: "9", order: 2, isTrue: false, root: 2 , feedback: ""},
            { title: "10", order: 3, isTrue: false, root: 2 , feedback: ""},
            { title: "7", order: 4, isTrue: false, root: 2 , feedback: ""},
        ],
    }
];

export const quiz :Quiz={
    title:"Titulo del quiz que va a empezar",
    instructions:"Esta es una instrucion para hacer testing en el quiz que necesito entregar hoy.",
    questions:questions
}