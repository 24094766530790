import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { ResetStepByExercises } from '../exercises/utils';
import { OneTaskByFindOneTask } from '../exercises/basic-vocabulary/services';
import { UseEvaluationCourseModule } from '../screen/store/main';
import { UseModeCourseModule } from '../components/front-page/store/main';
import useIsImportantEvaluation from '../hooks/useIsImportantEvaluation';
import { UseEvaluationTestModule } from '../../admin/evaluation/modules/test/store/main';
import useAuth from '../../../api/main';

type Props = {
  onAction: () => void;
};

const ButtonNextFullScreen: React.FC<Props> = ({ onAction }) => {
  const {typeUser} = useAuth();

  const { stepEvaluation,
    evaluationLesson,
    lessonCourse,
    currentTaskId,
    setStepEvaluation,
    setShowEndEvaluation,
  } = UseEvaluationCourseModule();

  const {
    stepEvaluationTest,
    evaluationTest,
    setStepEvaluationTest
} = UseEvaluationTestModule();

  const {modeCourse, modeTest, isPercentageMin} = UseModeCourseModule();

  const isImportant = useIsImportantEvaluation();

  return (<>
    <div
      className='arrow-right-true-false cursor-pointer'
      style={{ position: 'absolute' }}
      onClick={async (e) => {
        e.preventDefault();
        if (!isPercentageMin && !isImportant) return;
        if (modeTest && evaluationTest && evaluationTest.evaluation_item) {
          ResetStepByExercises();
          if (evaluationTest?.evaluation_item?.length > stepEvaluationTest + 1) {
            setStepEvaluationTest(stepEvaluationTest + 1)
          } else {
            alert('acabo')
          }
        }
        if (modeCourse && evaluationLesson
          && evaluationLesson.evaluation_item) {
          onAction();
          if (evaluationLesson?.evaluation_item?.length > stepEvaluation + 1) {
            setStepEvaluation(stepEvaluation + 1)
          } else if (lessonCourse) {
            if (typeUser === 'STUDENT') {
              await OneTaskByFindOneTask(currentTaskId ?? 'xx');
            }
            setShowEndEvaluation(true);
          } else {
            setShowEndEvaluation(true);
          }
        }
      }}
    >
      <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
    </div>
  </>)
};

export {ButtonNextFullScreen};