import { ThemeAnswer } from "../components/item"

export interface CheckWritingItemIE {
    title:string
    order:number
    isTrue:boolean
}
export interface CheckWritingTheme {
    title:string
    order:number
}
 
 
export interface CheckWritingQuestionIE {
    title :string
    order:number
    options:CheckWritingItemIE[]
 
}
 
 
export interface CheckWritingQuestionAnswerIE {
    title:string
    order:number
    text?:string
    isTrue:boolean
}

export enum CheckWritingType {
    CHECK_WRITING_SINGLE = 'CHECK_WRITING_SINGLE',
    CHECK_WRITING_DESCRIPTION = 'CHECK_WRITING_DESCRIPTION'
}

export interface CheckWritingAnswerAndThemesIE {
    answer?: CheckWritingQuestionAnswerIE[];
    answersThemes?: ThemeAnswer[];
}
 

export const themes :CheckWritingTheme[]=[
    {
        title:"este es el tema uno ",
        order:1
    },
    {
        title:"este es el tema dos",
        order:2
    },
    {
        title:"este ese el tema 3",
        order:3
    }
]
 


 

export const quiz :CheckWritingQuestionIE[]=
[
    {
        title:"Esta es la prgutna uno ",
        order:1,
        options:[
            {
                title:"uno",
                order:1,
                isTrue:false
            },
            {
                title:"dosxx",
                order:2,
                isTrue:true
            }
        ]
    },
    {
        title:"Esta es la pregunta dos",
        order:2,
        options:[
            {
                title:"uno xxxx",
                order:1,
                isTrue:false
            },
            {
                title:"3sssss",
                order:3,
                isTrue:true
            }
        ]
    },
    {
        title:"Esta es la pregunta tres",
        order:3,
        options:[
            {
                title:"doshhhh",
                order:2,
                isTrue:false
            },
            {
                title:"3jjjjj",
                order:3,
                isTrue:true
            }
        ]
    },
    {
        title:"Esta es la pregunta cuatro",
        order:4,
        options:[
            {
                title:"tema uno ",
                order:1,
                isTrue:false
            },
            {
                title:"tema 3",
                order:3,
                isTrue:true
            }
        ]
    }
]