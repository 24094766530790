import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { api } from "../../../../auth/services/auth.services";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseAudioTest {
    exercises: string;
    task: string;
    description: string;
    isActive: boolean;
    percentage: number;
    answers: any[];
}

export const AddAudioTestResponse = async (data: ResponseAudioTest): Promise<any> => {
    try {
      const response: any = await api.post(
        '/audio-test-response',
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }