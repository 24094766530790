import {Route, Routes} from 'react-router-dom'
import { TrailSuspenseDetails } from '../components/details'
import { TrailSuspenseTableList } from '../components/list'


const TrailSuspensePage = () => (
  <Routes>
      <Route path='/details' element={<TrailSuspenseDetails />} />
      <Route path='/' element={<TrailSuspenseTableList />} />
  </Routes>
)

export {TrailSuspensePage}
