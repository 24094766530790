import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Exercises, ExercisesType, VariantsExercisesType} from '../../interfaces'

import { ExercisesAdd, ExercisesUpdate } from '../../services'
import { UseExercisesModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'
import { TraditionalLevelEnum } from '../../../../abroad/road-map-step/interfaces'
import { useTranslation } from 'react-i18next'
import { ToTypeContentCourse, TypeContentCourse } from '../../../file-folder/interfaces'
import FileContentComponent from '../../../../../components/common/SingleFile/FileContent'
import FileUploadComponent from '../../../../../components/common/SingleFile'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { FileContentCourse } from '../../../file-content-course/interfaces'
import { UseFileContentCourseAllPaginate } from '../../../file-content-course/services'
import { UseFileContentCourseModule } from '../../../file-content-course/store/main'
import { useFileContentCourseState } from '../../../file-content-course/hooks/useFileContentCourseRepo'

export type FormFields = {
  // code?: string
  name: string
  type:string
  isActive: boolean
  feedBackImmediate: boolean
  description?:string;
  variant: VariantsExercisesType;
  fileContent?: string;
  level: TraditionalLevelEnum;
  code: string;
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const ExercisesForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient()

  const { data, isLoading } = useFileContentCourseState();

  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseExercisesModule();
  const {where, setWhere} = UseFileContentCourseModule();
  const [loading, setLoading] = React.useState<boolean>(false);

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        type: (mode==='EDIT' || mode==='VIEW')? itemSelected?.type??ExercisesType.SIMPLE_SELECTION:ExercisesType.SIMPLE_SELECTION,
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        feedBackImmediate:(mode==='EDIT' || mode==='VIEW')? itemSelected?.feedBackImmediate??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
        level:(mode==='EDIT' || mode==='VIEW')? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        fileContent:(mode==='EDIT' || mode==='VIEW')? itemSelected?.fileContent?.id ?? undefined : undefined,
        variant:(mode==='EDIT' || mode==='VIEW')? itemSelected?.variant ?? VariantsExercisesType.FULL_SCREEN : VariantsExercisesType.FULL_SCREEN,
        code: (mode==='EDIT' || mode==='VIEW')? itemSelected?.code ?? '' : '',
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await ExercisesAdd({
              ...formData,
              code: formData.code.trim(),
              fileContent: formData.fileContent === ('' && undefined) ? undefined : formData.fileContent
            })
            setWhere('');
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['exercises'] })

            }catch(e){
              setLoading(false);
              showSimpleAlert({message: 'Error al cargar ejercicio', icon: 'error'});
            }

          }
          else{
            try{
              setLoading(true);
              await  ExercisesUpdate({
                ...formData,
                code: formData.code.trim(),
                fileContent: formData.fileContent === ('' && undefined) ? undefined : formData.fileContent
              })
              resetData();
              setWhere('')
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['exercises'] })
 
              }catch(e){
                setLoading(false);
                showSimpleAlert({message: 'Error al cargar ejercicio', icon: 'error'});
              }
          }
      },
    });

    const filterVariantType = (variant: VariantsExercisesType) => {
      switch (variant) {
        case VariantsExercisesType.BOOK:
          return [
            ExercisesType.CROSSWORD,
            ExercisesType.DRAG_DROP,
            ExercisesType.AUDIO_INTERACTION,
            ExercisesType.TRUE_OPTIONS,
            ExercisesType.MULTIPLE_SELECTION,
            ExercisesType.CORRECT_SENTENCES,
            ExercisesType.TRUE_FALSE,
            // ExercisesType.HISTORY_WRITING,
            ExercisesType.ORDER_SENTENCES,
            ExercisesType.TRUE_WORDS,
            ExercisesType.HIGHLIGHT_WORD,
            ExercisesType.WRITING_CHECK_LIST,
            ExercisesType.BOOSTER,
            ExercisesType.COORDINATES,
            ExercisesType.GROUP_WORDS,
            ExercisesType.SPEAK_OUT,
            ExercisesType.CHECK_WRITING,
            ExercisesType.COMPLETE_SENTENCES,
          ] 
        // case VariantsExercisesType.SINGLE_SCREEN: 
        //   return [
        //     ExercisesType.SIMPLE_SELECTION,
        //     ExercisesType.SIMPLE_SELECTION_COMPLETION,
        //     ExercisesType.DRAG_DROP,
        //     ExercisesType.TRUE_FALSE,
        //   ]
        case VariantsExercisesType.FULL_SCREEN: 
          return [
            ExercisesType.SIMPLE_SELECTION,
            ExercisesType.SIMPLE_SELECTION_COMPLETION,
            ExercisesType.TRUE_FALSE,
            ExercisesType.DRAG_DROP,
            ExercisesType.MOVIE_WRITING,
            ExercisesType.HISTORY_WRITING,
            ExercisesType.AUDIO_TEST,
          ]
      
        default:
          return Object.values(ExercisesType);
      }
    };
  
    // React.useEffect(() => {
    //     if (values.variant === VariantsExercisesType.BOOK) {
    //       setFieldValue('type', ExercisesType.CROSSWORD);
    //     } else {
    //       setFieldValue('type', ExercisesType.SIMPLE_SELECTION);
    //     } /*else if (values.variant === VariantsExercisesType.SINGLE_SCREEN) {
    //       setFieldValue('type', ExercisesType.SIMPLE_SELECTION);
    //     }*/
    // }, [values.variant])

    React.useEffect(() => {
      if (itemSelected?.fileContent) {
        if (itemSelected.fileContent.name) {
          setWhere(itemSelected.fileContent.name);
        }
      }
    }, [itemSelected]);

    React.useEffect(() => {
      queryClient.invalidateQueries({ queryKey: ['file-content-course'] })
    }, [where]);

  return (

    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Feed Back Inmediato ?</label>
            <div className="form-check form-switch">
              <input
                name={'feedBackImmediate'}
                checked={values.feedBackImmediate}
                value={values.feedBackImmediate?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Nomenclatura</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {touched.code && errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Variante</label>
            <select
              name='variant'
              className='form-select'
              aria-label='Default select example'
              value={values.variant}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value === VariantsExercisesType.BOOK) {
                  setFieldValue('type', ExercisesType.CROSSWORD);
                } else {
                  setFieldValue('type', ExercisesType.SIMPLE_SELECTION);
                }
              }}
              disabled={mode==='VIEW'}
            >
              {Object.keys(VariantsExercisesType).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Tipo</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              {filterVariantType(values.variant).map(x => (
                  <option key={x} value={x}>{t(x)}</option>
              ))}
              {/*{Object.keys(ExercisesType).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))} */}
            </select>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Nivel</label>
            <select
              name='level'
              className='form-select'
              aria-label='Default select example'
              value={values.level}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              <option value=''></option>
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>


          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Título ejercicio</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Contenido de la leccion ?</label>
            <div className="form-check form-switch">
              <input
                name={'isContentLesson'}
                checked={values.isContentLesson}
                value={values.isContentLesson?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Nombre del recurso</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              disabled={mode==='VIEW'}
              placeholder='Search'
              name={'search'}
              value={where}
              onChange={(e) =>
                setWhere(e.target.value) }
            />
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>Recurso</label>
            <select
              disabled={mode === 'VIEW'}
              value={values.fileContent}
              onChange={handleChange}
              className='form-select'
              name='fileContent'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {data && data?.result?.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name??'empty'}
                </option>
              ))}
            </select>
          </div>

          {/* {mode !== 'ADD' && 
            values?.variant === VariantsExercisesType.FULL_SCREEN && <>
          <div className='mb-10 col-6'>
            <label className='form-label mb-0'>resourceType</label>
            <select
              disabled={mode === 'VIEW'}
              value={typeFileInput}
              onChange={(e)=> setTypeFileInput(e.target.value as TypeContentCourse)}
              className='form-select'
              aria-label='Default select example'
            >
              {Object.keys(TypeContentCourse).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Apply cover?</label>
            <div className="form-check form-switch">
              <input
                checked={applyCoverInput}
                onChange={(e)=>setApplyCoverInput(e.target.checked)}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          {applyCoverInput && <div className='mb-10 col-6 d-flex flex-column'>
              <label className='form-label mb-3'>Cover</label>
              {(coverFile && (coverFile.url || coverFile.location)) ? 
              <img className='img-fluid' style={{width:'50%'}} 
                src={(coverFile.url || coverFile.location)??toAbsoluteUrl('/media/logos/favicon.jpg')} 
                alt='metronic'/>
            : <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl('/media/logos/favicon.jpg')} 
                alt='metronic'/>}
                {mode==='EDIT'&& <FileUploadComponent
                  onChangeFile={(e) => changeFileCover(e)} 
                  onChangeLoading={(e) => setLoading(e)}
                  pushCover
                  idInput='coverFile' />}
          </div>}

          <div className='mb-10 col-6 d-flex flex-column'>
            <label className=''>Content</label>
            {(contentFile && (contentFile.url || contentFile.location)) ? 
              typeFileInput !== TypeContentCourse.VIDEO && 
                <img className='img-fluid' style={{width:'50%'}} 
                src={(contentFile.url || contentFile.location)??toAbsoluteUrl('/media/logos/favicon.jpg')} 
                alt='metronic'/>
            : typeFileInput !== TypeContentCourse.VIDEO && 
              <img className='img-fluid' style={{width:'50%'}} 
                src={toAbsoluteUrl('/media/logos/favicon.jpg')} 
                alt='metronic'/>}
            {mode==='EDIT'&& <FileContentComponent
              onChangeFile={(e) => changeFileContent(e)} 
              onChangeLoading={(e) => setLoading(e)}
              onChangeContent={(e) => changeContent(e)}
              idInput='contentChapter'
              to={ToTypeContentCourse.EXERCISE}
              root={itemSelected?.id??'x'}
              typeContent={typeFileInput}
              applyCover={applyCoverInput}
              cover={coverFile?coverFile.id:undefined}
              iFrameContent={itemSelected?.fileContent?.iFrame}
            />}
          </div></>} */}
{/* 
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {touched.code && errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Instrucción del ejercicio</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {ExercisesForm}
