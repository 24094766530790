
import React from 'react';
interface Props {
  name:string
}

const IconFa: React.FC<Props> = ({name}:Props) => {
  return (
    <i style={{fontSize: 17 }} className={name}></i>
  );
};

export default IconFa;