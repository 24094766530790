import React, { useRef } from "react";
import { UseBookingWindows } from "../store";
import CustomButton from "../../../components/common/custom-button";
import { useThemeMode } from "../../../../_metronic/partials";
import { UseMyRoadmapModule } from "../../profile/components/my-road-step/store";
import { SequenceType } from "../../abroad/road-map-step/interfaces";
import { useTranslation } from "react-i18next";
import { LearningPathType } from "../../abroad/learning-path/interfaces";
interface Props {

}

const BookingButton: React.FC<Props> = ({ }) => {
  const { mode } = useThemeMode()
  const { t } = useTranslation();
  const textInputRef = useRef<HTMLInputElement | null>(null);
  let { setClassType } = UseBookingWindows()
  const { settings } = UseMyRoadmapModule();

  const [applyWorkshop, setApplyWorkshop] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (settings && settings.typeStep && settings.typeStep === SequenceType.STANDARD) {
      setApplyWorkshop(true);
    }
  }, [settings]);


  return (<>
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '280px', maxHeight: '280px' }}>
      {settings && (settings?.typeCurrentPath && settings.typeCurrentPath !== LearningPathType.PRISMATIC) ?
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CustomButton
            title={settings?.limitTasksPending ? `${settings.numberTasksPending} ${t('PENDING_TASK')}` : 'Lesson'}
            type='xl'
            color={settings?.limitTasksPending ? 'DANGER' : mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
            handleClick={() => !settings?.limitTasksPending && setClassType('CLASS')}
            buttonDisabled={settings?.limitTasksPending ? true : false}
          />
          {applyWorkshop &&
            <CustomButton
              title='Workshop'
              type='xl'
              color={mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
              handleClick={() => setClassType('WORKSHOP')}
            />
          }
        </div> :
        <CustomButton
          title='Workshop'
          type='xl'
          color={mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
          handleClick={() => setClassType('WORKSHOP')}
        />
      }
    </div>

  </>);
};

export default BookingButton;