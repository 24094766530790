import { TraditionalLevelEnum } from "../../../../../abroad/road-map-step/interfaces";

export interface DataPaginateSpeakOutResponse {
    status:  string;
    message: string;
    data:    DataSpeakOut;
}

export interface DataAllSpeakOutResponse {
    status:  string;
    message: string;
    data:    SpeakOut[];
}

export interface DataSpeakOut {
    id?: string;
    description:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface SpeakOut  {
    id?: string;
    description?: string;
    name: string;
    isActive: boolean;
    createdAt?: string;
    updatedAt?: string;
    tips?: string[];
    level?: TraditionalLevelEnum;
    type?: SpeakOutType; 
    maxWords: number;
    minWords: number;
    exercises: any
}

export interface Pagination {
    result: SpeakOut[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum SpeakOutType {
    SPEAK_OUT = 'SPEAK_OUT',
    WRITE_DOWN = 'WRITE_DOWN',
}