import React from 'react';
import { BookPage } from '../../../admin/book/modules/book-page/interfaces';

type Props = {
  pagesBook: BookPage[];
  numberPage: number;
};

const RenderBookPage: React.FC<Props> = ({ pagesBook, numberPage }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const currentPage = pagesBook.find(x => x.page === numberPage);

  return (
    <div
      ref={containerRef}
      className='d-flex justify-content-center py-1'
      >
      <img
        key={currentPage?.id}
        src={currentPage?.file.location}
        alt=""
        style={{
          maxWidth: '50%',
          height: '100%'
        }}
      />
    </div>
  );
};

export { RenderBookPage };