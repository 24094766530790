import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import { StepInfoForm } from './form';
import { UseAutoVerification } from '../../../store/main';

type Props = {
  onSubmit:()=>void;
}

const StepInfo: FC<Props> = ({onSubmit}) => {
  
  let { dataHolder } = UseAutoVerification();
  return (
    <div className='w-100'>
      <div className='pb-1 pb-lg-1'>
        <h2 className='champ-light' style={{color:'#2d30e1'}}>Datos Titular</h2>
      </div>

        {dataHolder ?<StepInfoForm onSubmit={onSubmit}/>:<></>}
    </div>
  )
}

export {StepInfo}