/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Field } from 'formik'
import { MasterClassLevel } from '../../../../admin/master-class-level/interfaces'
import { UseMasterClassLevelAll } from '../../../../admin/master-class-level/services'
import { UseBookingModule } from '../../../store'
import { useParams } from 'react-router-dom'
import { StatisticsWidget1 } from '../../../../../../_metronic/partials/widgets'
import TikTokEmbed from '../../../../../components/common/TiktokEmbed'

type Props = {
    className: string
    setLevelSelected: (level: MasterClassLevel) => void;
}

const SelectorLevelMasterClass: React.FC<Props> = ({ className,setLevelSelected }) => {
    const { level, setLevel } = UseBookingModule();
    const { code } = useParams();
    const [selected, setSelected] = React.useState<MasterClassLevel>()
    const [levels, setLevels] = React.useState<MasterClassLevel[]>([])
    const fetch = async () => {
        try {
            let response = await UseMasterClassLevelAll()
            setLevels(response)
        } catch (e) {
            console.error(e)
        }
    }
    
    useEffect(() => {
        fetch();
    }, [])
    useEffect(() => {

    }, [level])
    return (
        <div className={`card ${className}`} style={{ width: '100%' }}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-0'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Niveles</span>
                    {/* <span className='text-muted mt-1 fw-semibold fs-7'>Master class disponibles</span> */}
                </h3>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                <div className='tab-content row' style={{minHeight:400}}>
                    <div className='col-md-6'>
                        {levels.filter(x => x.country.code.toLowerCase() == code).map((x) => (
                            <div
                            key={x.id}
                            onMouseEnter={()=>setSelected(x)}
                            
                            className={`card bgi-no-repeat m-5`}
                            style={{
                                backgroundPosition: 'right top',
                                backgroundSize: '30% auto',
                                backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
                                background: (selected && x.id===selected.id)?'#1d0e6c':'',
                                color:(selected && x.id===selected.id)?'white':'#f19a97',
                            }}
                        >
                            {/* begin::Body */}
                            <div className='card-body justify-content-between'>
                                <a href='#' className='card-title fw-bold   text-hover-primary fs-4'>
                                    {x.name??''}
                                </a>
                                <p
                                    className='text-dark-75 fw-semibold fs-5 m-0'
                                    dangerouslySetInnerHTML={{ __html: x.description??'' }}
                                ></p>
                                <button type="button" className="btn btn-secondary"
                                                                style={{
                                                                    borderRadius:5,
                                                                    padding:6,
                                                                    background: (selected && x.id===selected.id)?'#a73dbb':'',
                                                                   
                                                                }}
                                onClick={()=>setLevelSelected(x)}
                                >Selecionar</button>
                            </div>
                            {/* end::Body */}
                        </div>
                        ))}
                    </div>
                    <div className='col-md-6' >
                        {selected && selected.video && <TikTokEmbed video={selected.video} />}
                    </div>
                </div>
            </div>
            {/* end::Body */}
        </div>
    )
}

export { SelectorLevelMasterClass }
