import {Route, Routes} from 'react-router-dom'
import { FileContentCourseDetails } from '../components/details'
import { FileContentCourseTableList } from '../components/list'


const FileContentCoursePage = () => (
  <Routes>
      <Route path='/details' element={<FileContentCourseDetails />} />
      <Route path='/' element={<FileContentCourseTableList />} />
  </Routes>
)

export {FileContentCoursePage}
