import {Route, Routes} from 'react-router-dom'
import { MyTestsTableList } from '../components/list'


const MyTestsPage = () => (
  <Routes>
      <Route path='/' element={<MyTestsTableList />} />
  </Routes>
)

export {MyTestsPage}
