/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import CountdownTimer from '../count-down-timer'
import moment from 'moment'
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import { ButtonStartRating } from '../button-start-rating';
import { useThemeMode } from '../../../../../_metronic/partials';
import './style.css'
import { favIconAppSelector } from '../../../../hooks/useAppSelectorAssetsMode';

type Props = {
  className: string
  teacherName: string;
  targetDate: {
    start: string;
    end: string;
  };
  showButtonThreshold: number;
  starsRating: number;
  teacherEmail: string;
  teacherPhoto: string;
  horizontalBanner: string;
}

const CardLobbyClass: React.FC<Props> = ({teacherEmail,className, teacherName, targetDate, showButtonThreshold, starsRating, teacherPhoto, horizontalBanner}) => {
  
  const {mode} = useThemeMode()
  const [showRating, setShowRating] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentDateTime = moment();

      if (currentDateTime.isSameOrAfter(targetDate.end)) {
        setShowRating(true);
        clearInterval(interval);
      }
    }, 1000); 

    return () => clearInterval(interval);
  }, [targetDate.end]);

  return (
    <div className={`${className}`} style={{padding:0}} >
      <div className='card-body pb-0  '>
        <div className='d-flex align-items-center top-heder-teacher-margin'>
          <div className='d-flex align-items-center flex-grow-1'>
            <div className='symbol symbol-45px me-5'>
              <img className='rounded-circle img-fluid' src={toAbsoluteUrl(teacherPhoto!== "" ? teacherPhoto :favIconAppSelector())} alt=''  style={{color:'#2d30e1'}}/>
            </div>
            <div className='d-flex flex-column'>
              <h4 className=' mb-0 champ-bold' style={mode==='dark'?{color:'white'}:{color:'#2d30e1'}}>
                {teacherName}
              </h4>
              <span className='poppins-light' style={mode!='dark'?{color:'#2d30e1'}:{color:'white'}}>Teacher</span>
              <span className='poppins-light' style={mode!='dark'?{color:'#2d30e1'}:{color:'white'}}>{teacherEmail}</span>
            </div>
          </div>
        </div>
        <div className='mb-5' >
          {/* <img */}
            {/* className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5 image-class-lobby' */}
            <img src={toAbsoluteUrl(horizontalBanner !== "" ? horizontalBanner : '/media/svg/illustrations/class_splash.svg')} alt='Horizontal banner' className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5 image-class-lobby'/>
            {/* style={{
              backgroundImage: `url('${toAbsoluteUrl(horizontalBanner !== "" ? horizontalBanner : '/media/svg/illustrations/class_splash.svg')}')`
            }} */}
          {/* /> */}

        {/* <div className='separator mb-4'></div>
          <div className='d-flex align-items-center mb-5'>
                {!showRating && 
                  <CountdownTimer targetDate={targetDate.start} showButtonThreshold={showButtonThreshold}/>
                }
                {showRating && <>
                  <ButtonStartRating starsRating={starsRating}/>
                </>}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export {CardLobbyClass}
