import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'

import {AudioTest} from '../../interfaces'
import { useParams } from 'react-router-dom'

import { AudioTestAdd, AudioTestUpdate } from '../../services'
import { UseAudioTestModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { QuestionAudioTest } from '../../../../../../courses/exercises/audio-test/interfaces'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'
import { TraditionalLevelEnum } from '../../../../../../abroad/road-map-step/interfaces'

export type AudioTestFormFields = {

  name: string;
  isActive: boolean;
  description?:string;
  level: TraditionalLevelEnum;
  answers: any[];
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().min(1, 'Name must be at least 1 character').required('Name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const AudioTestForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {id} = useParams();
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseAudioTestModule();
  const [data, setData] = useState<AudioTest>()
  

  const [dataQuestion, setDataQuestion] = React.useState<QuestionAudioTest>({
    title:'',
    order:1,
    percentage:100,
    time:5
  });
  const [modeQuestion, setModoQuestion] = React.useState<{question: boolean, option: boolean}>({
    question: true,
    option: false
  });

  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<AudioTestFormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.A1 : TraditionalLevelEnum.A1,
        answers: []
      },
      validationSchema: entitySchema,
      
      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await AudioTestAdd({
              ...formData,
              exercises: id??''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['audio-test'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  AudioTestUpdate({
                ...formData,
                exercises: id??''
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['audio-test'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const addQuestion = () => {
      const findRepeatedOrder = values.answers.find(x => x.order === dataQuestion.order);
      if (findRepeatedOrder) return showSimpleAlert({message: 'Orden repetido', icon: 'error'}); 
      setFieldValue('answers', [...values.answers,{...dataQuestion}]);
      setDataQuestion({
        title :'',
        order:1,
        percentage:100,
        time:0
      })
    };

    const deleteOption = (order: number) => {
      const questionToUpdate = values.answers.find(x => x.order === order);
      if (!questionToUpdate) 
          return showSimpleAlert({ message: 'No se encontró la pregunta correspondiente', icon: 'error' });
  
      const updatedAnswers = values.answers.filter(x => x.order !== order);
  
      setFieldValue('answers', updatedAnswers);
  };
    

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}
          <div className="d-flex">
            <hr className="my-auto flex-grow-1"/>
            <div className="px-4">Preguntas</div>
            <hr className="my-auto flex-grow-1"/>
          </div>

          {modeQuestion.option &&
            <div className='card-footer d-flex justify-content-start py-6 px-9'>
              <button type='button' 
                onClick={() => setModoQuestion({
                  question: true,
                  option: false
                })} 
                className='btn btn-primary' 
                disabled={loading ||mode==='VIEW'}>
                Questions
              </button>
            </div>}

          {modeQuestion.question ? <>
            <div className='mb-10 col-md-6 col-12 row'>
              <label className='form-label mb-3 fw-bold'>Pregunta</label>
              <textarea
                className='form-control form-control-lg form-control-solid'
                placeholder='title'
                disabled={mode === 'VIEW'}
                name={'title'}
                value={dataQuestion.title}
                onChange={(e) => setDataQuestion({ ...dataQuestion, title: e.target.value })}
              />
              
              <div className='mb-10 col-md-6 col-12'>
              <label className='form-label mb-3 fw-bold'>Porcentage de la pregunta</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'percentage'}
                value={dataQuestion.percentage}
                onChange={(e) => setDataQuestion({ ...dataQuestion, percentage:parseInt( e.target.value) })}
              />
              </div>
              
              <div className='mb-10 col-6'>
              <label className='form-label mb-3 fw-bold'>Tiempo maximo del audio (S)</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'time'}
                value={dataQuestion.time}
                onChange={(e) => setDataQuestion({ ...dataQuestion, time: parseInt( e.target.value) })}
                />
              </div>
              
              <div className='col-6'>
                <label className='form-label mb-3 fw-bold'>Orden</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order'}
                  value={dataQuestion.order}
                  onChange={(e) => setDataQuestion({ ...dataQuestion, order: parseInt(e.target.value) })}
                />
              </div>

              <div className='col-6 py-6 px-9'>
                <button type='button' onClick={addQuestion} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                  {!loading && 'Save'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead >
                  <tr className='fw-bold text-muted'>
                  <th className=''>Pregunta</th>
                    <th className=''>Porcentage</th>
                    <th className=''>tiempo maximo</th>
                  </tr>
                </thead>
                <tbody>
                  {values.answers.sort((a,b) => a.order - b.order).map((x) => (
                    <tr>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column me-3'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.title}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>
                          <div className='d-flex justify-content-start flex-column me-3'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.name}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>                          
                         
                        </div>
                      </td>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column me-3'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.percentage}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column me-3'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.time}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {/* {x.color} */}
                            </span>
                          </div>
                        </div>
                      </td>


                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                            disabled={loading ||mode==='VIEW'}
                            type='button'
                            onClick={()=> deleteOption(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                         
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </> : modeQuestion.option && <>
            
          </>}


          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {AudioTestForm}
