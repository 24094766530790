import React, { useEffect } from 'react';
import { TrueOptionItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseTrueOptionsModule } from './store';
import { TrueOptionImageItem } from './components/item-img';
import { RightConnectorItem } from './components/item-right-conector';
import { TrueOptionType } from '../../../admin/exercises/templates-exercise/book/true-option/interfaces';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';



type Props = {
    variant?: TrueOptionType;
    userMode?: ModeTypeExercise
};

const TrueOptions: React.FC<Props> = ({ variant, userMode }) => {
    const { mode } = useThemeMode();
    let { 
        questions,
        falseAnswer,
        instructions,
        responseStudent,
    } = UseTrueOptionsModule();
    return (
        <>
            {variant === TrueOptionType.OPTION_WORD ? 
                <TrueOptionItem
                    instruction={instructions}
                    userMode={userMode}
                    responses={responseStudent}
                    question={questions}
                />
            : variant === TrueOptionType.IMAGE_OPTION ?
                <TrueOptionImageItem
                    instruction={instructions}
                    userMode={userMode}
                    responses={responseStudent}
                    question={questions}
                />
            : variant === TrueOptionType.COMPLETE_SENTENCE ?
                <RightConnectorItem
                    instruction={instructions}
                    userMode={userMode}
                    responses={responseStudent}
                    question={questions}
                /> 
            : <TrueOptionItem
                instruction={instructions}
                userMode={userMode}
                responses={responseStudent}
                question={questions}
            /> 
            }
        </>
    );
};

export { TrueOptions };