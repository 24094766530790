import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ResolutionCertificate} from '../../interfaces'

import { ResolutionCertificateAdd, ResolutionCertificateUpdate } from '../../services'
import { UseResolutionCertificateModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'

export type ResolutionCertificateFormFields = {
  resolutionDate:string;
  signature:string;
  from:number;
  to:number;
  name:string;
  isActive:boolean
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
  from: Yup.number(),
  to: Yup.number(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const ResolutionCertificateForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseResolutionCertificateModule();
  const [data, setData] = useState<ResolutionCertificate>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<ResolutionCertificateFormFields>({
      initialValues: {
        from: (mode==='EDIT' || mode==='VIEW')? itemSelected?.from??1: 1,
        to: (mode==='EDIT' || mode==='VIEW')? itemSelected?.to??1: 1,
        resolutionDate: (mode==='EDIT' || mode==='VIEW')? itemSelected?.resolutionDate??'': '',
        signature:(mode==='EDIT' || mode==='VIEW')? itemSelected?.signature??'': '',
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await ResolutionCertificateAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['resolution-certificate'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  ResolutionCertificateUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['resolution-certificate'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>



          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Desde</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'from'}
              value={values.from}
              onChange={handleChange}
            />
            {touched.from && errors.from && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.from}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Hasta</label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'to'}
              value={values.to}
              onChange={handleChange}
            />
            {touched.to && errors.to && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.to}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Fecha de resolución en letras</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'resolutionDate'}
              value={values.resolutionDate}
              onChange={handleChange}
            />
            {touched.resolutionDate && errors.resolutionDate && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.resolutionDate}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Firma Base 64</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'signature'}
              value={values.signature}
              onChange={handleChange}
            />
            {touched.signature && errors.signature && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.signature}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {ResolutionCertificateForm}
