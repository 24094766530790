import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import IconFa from '../Icon/indext'
type Props = {
    type:'TEACHER'|'EXECUTIVE'|'ADMIN'|'ROOT'|'CLIENT'|'ASSISTANT'|'STUDENT'
    text:string
    isActive:boolean
    setSelector: (value: string) => void;
    icon?:string
}
const ButtonSelector: React.FC<Props> = ({isActive,icon,type,text,setSelector}:Props) => {

return (<>
            <button
            style={{
                color:isActive?'white':'grey',
                background:isActive?'blue':'ghostwhite',
                margin:'10px'
            }}
            onClick={() => setSelector(type)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
            >
                {icon && <IconFa name={icon} />}{text}
            </button>
        </>)
}
export {ButtonSelector}
