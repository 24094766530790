
import { persist } from 'zustand/middleware';
import {create} from 'zustand';
import { LoginResponse } from '../modules/auth/interfaces';
import { authChecked, loginAuth } from '../modules/auth/services/auth.services';
import { ProfileDocumentType } from '../modules/profile/components/details';
import { UserSettings } from '../modules/profile/components/my-road-step/interfaces';

export interface Profile {
  aboutMe?:string
  address?:string
  birthDay?:string
  btcWallet?:string
  city?:string
  country?:string
  documentNumber?:string
  firstName?:string
  gender?:string
  lastName?:string
  phoneNumber?:string
  photo?:any
  avatar?:string
  tronWallet?:string
  zipCode?:string
  culturalInformation?: string;
  documentType?: ProfileDocumentType;
  state?: string;
  municipality?: string;
  eps?: string;
  neighborhood?: string;
  socialStratum?: string;
  civilStatus?: string;
  sisben?: string;
  educationLevel?: string;
  occupation?: string;
  hourlyDay?: string;
  disability?: string;
}
  interface AuthState {
    id?:    string
    email?: string
    roles?: string[]
    fullName?: string;
    code?:string
    token?: string
    studentSettings?:UserSettings;
    profile?:Profile
    notifications:any[]
    typeUser?:'TEACHER'|'EXECUTIVE'|'ADMIN'|'ROOT'|'CLIENT'|'ASSISTANT'|'STUDENT',
    isAuthenticated: boolean
    username?: string
    userAuth?:LoginResponse
    login: (username: string,password:string) => void;
    setProfile: (value:Profile) => void;
    logout: () => void;
    resetNotification: () => void;
    authChecked: () => void;
    setNotification:(value:any)=>void;
    setSettings:(mode:UserSettings)=>void;
}

const useAuth = create(persist<AuthState>(
    (set) =>  ({
        notifications:[],
        isAuthenticated: false,
        login: async (username: string,password:string) =>{ 
          try{
            let data = await loginAuth({
                email: username,
                password: password
              })
              // console.log(data)
              set({
                id:data.id,
                email:data.email??'',
                roles:data.roles,
                fullName: data.fullName??'',
                token: data.token,
                profile:data.profile,
                isAuthenticated:true,
                typeUser:data.type,
                code:data.code??undefined
              })
               window.location.replace('/')

          }catch(e){
            set({
              email:'',
              roles:[],
              token:"",
              profile:{},
              isAuthenticated:false
            })
          }
        },
        logout: () =>{ 
            set({
              isAuthenticated: false,
              username: undefined,
              userAuth:undefined,
              token:""
            })
            localStorage.clear();
            window.location.replace('/auth')
        },
        authChecked:async ()=>{
          try{
            let response = await authChecked()
            set({
                profile:{...response.profile},
                token:response.token,
                typeUser:response.type
              })
          }catch{
            set({
              isAuthenticated: false,
              username: undefined,
              userAuth:undefined,
              token:"",
              profile:{}
            })
            localStorage.clear();
            window.location.replace('/auth')
          }
        },
        setProfile(value:Profile){
          set({profile:value})
        },
        setNotification(value:any){
          let currentArray:any[]=useAuth.getState().notifications;
          currentArray.push(value)
          set({notifications:currentArray})
        },
        resetNotification(){
         set({notifications:[]})
        },
        setSettings(value){
          set({studentSettings:value})
        }
      }),
      {
        name:"auth",

      }
));

export default useAuth;