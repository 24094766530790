import React, { useState } from 'react';
import { UseSupportTicketModule } from '../../store/main';
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish';
import ZoomableImage from '../../../../../components/common/ZoomableImage';
import useAuth from '../../../../../api/main';
import { SupportTicketStatus } from '../../interfaces';
import { UpdateSupportTicketStatus } from '../../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import Swal from 'sweetalert2';
import { useQueryClient } from '@tanstack/react-query';
import { useThemeMode } from '../../../../../../_metronic/partials';
import moment from 'moment';
import './index.css';

type Props = {};

const ResponseSupport: React.FC<Props> = () => {
    const {mode} = useThemeMode();
    const queryClient = useQueryClient();
    const { typeUser } = useAuth.getState();
    const { itemSelected, resetData, setModalInteractive } = UseSupportTicketModule();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const maxCharactersToShow = 100;

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const closedItem = async (id:string)=>{
        //@ts-ignore
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async(result) => {
          if (result.isConfirmed) {
            try{
              await UpdateSupportTicketStatus(id, SupportTicketStatus.CLOSED)
              showSimpleAlert({message:'Ok!',icon:'success'})
              queryClient.invalidateQueries({ queryKey: ['support-ticket'] });
             }catch(e){
                
             }
          }
        })
      }

    const combinedMessage: any[] = itemSelected ? [
    ...(itemSelected.responseSupportTicket || []).map((x) => ({ ...x, type: 'SUPPORT' })),
    ...(itemSelected.responseUserSupportTicket || []).map((x) => ({ ...x, type: 'USER' }))
    ] : [];

    return (
        <>
            <div className='d-flex justify-content-between'>
                <button className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => resetData()}>
                    <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
                </button>
                {typeUser !== 'STUDENT' && (
                        <div className='d-flex'><button
                        type='button'
                        onClick={() => closedItem(itemSelected?.id ?? 'xxx')}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        style={{
                            textDecoration: 'none',
                            transition: 'background-color 0.3s',
                        }}
                    >
                        <i style={{ color: 'red', fontSize: 17 }} className='fa-solid fa-circle-xmark'></i>
                    </button><button
                        type='button'
                        onClick={() => setModalInteractive(true)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        style={{
                            textDecoration: 'none',
                            transition: 'background-color 0.3s',
                        }}
                    >
                            <i style={{ color: 'blue', fontSize: 17 }} className='fa-solid fa-plus'></i>
                        </button></div>
                )}
                {typeUser === 'STUDENT' && itemSelected?.status === SupportTicketStatus.ANSWERED && (
                    <button
                        type='button'
                        onClick={() => setModalInteractive(true)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        style={{
                            textDecoration: 'none',
                            transition: 'background-color 0.3s',
                        }}
                    >
                        <i style={{ color: 'blue', fontSize: 17 }} className='fa-solid fa-rotate-right'></i>
                    </button>
                )}
            </div>
            <div className='d-flex flex-column flex-lg-row mt-3'>
              <div className='card mb-3 mb-lg-0 content-response-support' style={{ flex: '0 0 29.5%', border: '1px solid #a500b7' }}>
                  <div id='content-response-header'
                      className='card-header d-flex justify-content-between rounded-bottom-0'
                      style={{ backgroundColor:mode==='light'?'#a500b7':'' }}
                  >
                      <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-6 poppins-light' style={{ color:mode==='light'?'#FFFFFF':'#a500b7' }}>
                              {itemSelected?.name}
                          </span>
                      </h3>
                      <span className='card-title align-items-end flex-column'>
                          <i className='fa-solid fa-comment' style={{ fontSize: 17, color: 'white' }}>
                              {itemSelected?.responseUserSupportTicket && itemSelected?.responseUserSupportTicket?.length > 0 && (
                              <span className='badge badge-success badge-circle fw-bolder fs-9 top-0 translate-middle'>
                                  {itemSelected?.responseUserSupportTicket?.length}
                              </span>
                          )}
                          </i>
                      </span>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 poppins-light'>
                            Estado: {setTypeToSpanish(itemSelected?.status)}
                        </div>
                        {typeUser !== 'STUDENT' && <div className='col-12 poppins-light'>
                            Prioridad: {setTypeToSpanish(itemSelected?.priority)}
                        </div>}
                    </div>
                    <div className='mt-3'>
                        {itemSelected && <span className='poppins-light'>
                            {showFullDescription
                                ? itemSelected?.description
                                : itemSelected?.description.length > maxCharactersToShow
                                    ? itemSelected?.description.slice(0, maxCharactersToShow) + "..."
                                    : itemSelected?.description
                            }
                            {itemSelected?.description.length > maxCharactersToShow && (
                                <span className='text-primary cursor-pointer' onClick={toggleDescription}>
                                    {" "}
                                    {showFullDescription ? "Ver menos" : "Ver más"}
                                </span>
                            )}
                        </span>}
                    </div>
                    <div className='mt-3 row'>
                        {itemSelected?.files && itemSelected?.files.map((image) => (
                            <>
                            {image && image.location && 
                                <div className='col-6'>
                                    <ZoomableImage imageUrl={image?.location} backGroundColor={'#a500b7'}/>
                                </div>
                            }
                            </>
                        ))}
                    </div>
                  </div>
              </div>
              <div style={{flex:'0 0 1%'}}></div>
              <div className='card content-response-support' style={{ flex: '0 0 69.5%', border: '1px solid #a500b7' }}>
                  <div className='card-body'>
                    {!itemSelected?.responseSupportTicket || itemSelected?.responseSupportTicket?.length < 1 ? 
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <span className='champ-light fs-1'>Sin Respuesta</span>
                        </div> : <></>
                    }
                    {combinedMessage && combinedMessage.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))).map((x,i) => (
                        <div key={i} className={`d-flex justify-content-${x.type === 'SUPPORT' ? 'start':'end'} p-4`}>
                          <div className='flex-column'>
                            {x.type === 'SUPPORT' && 
                              <div className='d-flex align-items-center flex-grow-1 justify-content-start'>
                                  <div className='symbol symbol-45px me-5'>
                                      <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                                  </div>
                                  <div className='d-flex flex-column'>
                                      <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                          {x.user.email}
                                      </span>
                                      <span className='text-gray-400 fw-semibold text-start'>{moment(x.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                                  </div>
                              </div>}
                            {x.type === 'USER' &&
                            <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
                                <div className='d-flex flex-column'>
                                    <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                        {x.user.email}
                                    </span>
                                    <span className='text-gray-400 fw-semibold text-end'>{moment(x.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                                </div>
                                <div className='symbol symbol-45px ms-5'>
                                    <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                                </div>
                            </div>}

                            <span className={`poppins-light d-flex justify-content-${x.type === 'SUPPORT' ? 'start':'end'}`}>
                                {showFullDescription
                                    ? x?.description
                                    : x?.description.length > maxCharactersToShow
                                        ? x?.description.slice(0, maxCharactersToShow) + "..."
                                        : x?.description
                                }
                                {x?.description.length > maxCharactersToShow && (
                                    <span className='text-primary cursor-pointer' onClick={toggleDescription}>
                                        {" "}
                                        {showFullDescription ? "Ver menos" : "Ver más"}
                                    </span>
                                )}
                            </span>
                            <div className={`d-flex justify-content-${x.type === 'SUPPORT' ? 'start':'end'}`}>
                              {x?.files && x?.files.map((image) => (
                                  <ZoomableImage imageUrl={image?.location} backGroundColor={'#a500b7'}/>
                              ))}
                            </div>
                          </div>
                        </div> 
                    ))}
                  </div>
              </div>
            </div>
                {/* // <>
                //     <div className='d-flex justify-content-between'>
                //         <button className='btn btn-icon btn-light btn-sm border-0 me-1' onClick={() => resetData()}>
                //             <KTIcon iconName='arrow-left' className='fs-2 text-primary' />
                //         </button>
                //         {typeUser !== 'STUDENT' && (
                //              <div className='d-flex'><button
                //                 type='button'
                //                 onClick={() => closedItem(itemSelected.id ?? 'xxx')}
                //                 className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                //                 style={{
                //                     textDecoration: 'none',
                //                     transition: 'background-color 0.3s',
                //                 }}
                //             >
                //                 <i style={{ color: 'red', fontSize: 17 }} className='fa-solid fa-circle-xmark'></i>
                //             </button><button
                //                 type='button'
                //                 onClick={() => setModalInteractive(true)}
                //                 className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                //                 style={{
                //                     textDecoration: 'none',
                //                     transition: 'background-color 0.3s',
                //                 }}
                //             >
                //                     <i style={{ color: 'blue', fontSize: 17 }} className='fa-solid fa-plus'></i>
                //                 </button></div>
                //         )}
                //         {typeUser === 'STUDENT' && itemSelected?.status === SupportTicketStatus.ANSWERED && (
                //             <button
                //                 type='button'
                //                 onClick={() => setModalInteractive(true)}
                //                 className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                //                 style={{
                //                     textDecoration: 'none',
                //                     transition: 'background-color 0.3s',
                //                 }}
                //             >
                //                 <i style={{ color: 'blue', fontSize: 17 }} className='fa-solid fa-rotate-right'></i>
                //             </button>
                //         )}
                //     </div>
                    <div className='d-flex flex-column flex-lg-row mt-3'>
                        <div className='card mb-3 mb-lg-0' style={{ flex: '0 0 30%', marginRight: '5px', border: '1px solid #a500b7', borderRadius:'50px' }}>
                            <div
                                className='card-header d-flex justify-content-between rounded-bottom-0'
                                style={{ backgroundColor:mode==='light'?'#a500b7':'',borderRadius:'50px' }}
                            >
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-6 poppins-light' style={{ color:mode==='light'?'#FFFFFF':'#a500b7' }}>
                                        {itemSelected?.name}
                                    </span>
                                </h3>
                                <span className='card-title align-items-end flex-column'>
                                    <i className='fa-solid fa-comment' style={{ fontSize: 17, color: 'white' }}>
                                        {itemSelected?.responseUserSupportTicket && itemSelected?.responseUserSupportTicket?.length > 0 && (
                                        <span className='badge badge-success badge-circle fw-bolder fs-9 top-0 translate-middle'>
                                            {itemSelected?.responseUserSupportTicket?.length}
                                        </span>
                                    )}
                                    </i>
                                </span>
                            </div>
                            <div className='card-body'>
                              <div className='row'>
                                  <div className='col-12 poppins-light'>
                                      Estado: {setTypeToSpanish(itemSelected?.status)}
                                  </div>
                                  {typeUser !== 'STUDENT' && <div className='col-12 poppins-light'>
                                      Prioridad: {setTypeToSpanish(itemSelected?.priority)}
                                  </div>}
                              </div>
                              <div className='mt-3'>
                                  <span className='poppins-light'>
                                      {showFullDescription
                                          ? itemSelected?.description
                                          : itemSelected?.description.length > maxCharactersToShow
                                              ? itemSelected?.description.slice(0, maxCharactersToShow) + "..."
                                              : itemSelected?.description
                                      }
                                      {itemSelected?.description.length > maxCharactersToShow && (
                                          <span className='text-primary cursor-pointer' onClick={toggleDescription}>
                                              {" "}
                                              {showFullDescription ? "Ver menos" : "Ver más"}
                                          </span>
                                      )}
                                  </span>
                              </div>
                              <div className='mt-3 row'>
                                  {itemSelected?.files && itemSelected?.files.map((image) => (
                                      <>
                                      {image && image.location && 
                                          <div className='col-6'>
                                              <ZoomableImage imageUrl={image?.location} backGroundColor={'#a500b7'}/>
                                          </div>
                                      }
                                      </>
                                  ))}
                              </div>
                              <div className='mt-3'>
                                <div className="accordion" id="accordionExample">
                                    {itemSelected?.responseUserSupportTicket && itemSelected?.responseUserSupportTicket.map((x) => (
                                    <div className="accordion-item" key={x?.id}>
                                        <h2 className="accordion-header" id={`heading${x?.id}`}>
                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${x?.id}`} aria-expanded="true" aria-controls={`collapse${x?.id}`}>
                                              {x?.name}
                                          </button>
                                        </h2>
                                        <div id={`collapse${x?.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${x?.id}`} data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            {x?.description}
                                        </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                        </div>

                //         <div className='card' style={{ flex: '0 0 70%', border: '1px solid #a500b7', borderRadius:'50px' }}>
                //             <div className='card-body'>
                //               {!itemSelected?.responseSupportTicket || itemSelected?.responseSupportTicket?.length < 1 ? 
                //                   <div className='d-flex justify-content-center align-items-center h-100'>
                //                       <span className='champ-light fs-1'>Sin Respuesta</span>
                //                   </div> : <></>
                //               }
                //               {itemSelected?.responseSupportTicket && itemSelected?.responseSupportTicket.map((x) => (
                //                   <>
                //                       <div key={x.id} className='p-4'>
                //                           <div className='d-flex align-items-center flex-grow-1'>
                //                               <div className='symbol symbol-45px me-5'>
                //                                   <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
                //                               </div>
                //                               <div className='d-flex flex-column'>
                //                                   <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                //                                       {x.user.email}
                //                                   </a>
                //                                   <span className='text-gray-400 fw-semibold'></span>
                //                               </div>
                //                           </div>
                //                           <span className='poppins-light'>
                //                               {showFullDescription
                //                                   ? x?.description
                //                                   : x?.description.length > maxCharactersToShow
                //                                       ? x?.description.slice(0, maxCharactersToShow) + "..."
                //                                       : x?.description
                //                               }
                //                               {x?.description.length > maxCharactersToShow && (
                //                                   <span className='text-primary cursor-pointer' onClick={toggleDescription}>
                //                                       {" "}
                //                                       {showFullDescription ? "Ver menos" : "Ver más"}
                //                                   </span>
                //                               )}
                //                           </span>
                //                           {x?.files && x?.files.map((image) => (
                //                               <ZoomableImage imageUrl={image?.location} backGroundColor={'#a500b7'}/>
                //                           ))}
                //                       </div>
                //                   </>
                //               ))}  
                //             </div>
                //         </div>
                //     </div>
                // </> */}
        </>
    );
};

export { ResponseSupport };