import {Route, Routes} from 'react-router-dom'
import { TemplateTableTimeDetails } from '../components/details'
import { TemplateTableTimeTableList } from '../components/list'
import { TemplateTableTimeItemTableList } from '../modules/template-table-time-item/components/list'


const TemplateTableTimePage = () => (
  <Routes>
      <Route path='/item/:id' element={<TemplateTableTimeItemTableList />} />
      <Route path='/details' element={<TemplateTableTimeDetails />} />
      <Route path='/' element={<TemplateTableTimeTableList />} />
  </Routes>
)

export {TemplateTableTimePage}
