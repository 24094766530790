import { Evaluation } from "../../evaluation/interfaces";

export interface DataPaginatePlacementTestResponse {
    status:  string;
    message: string;
    data:    DataPlacementTest;
}

export interface DataAllPlacementTestResponse {
    status:  string;
    message: string;
    data:    PlacementTest[];
}

export interface DataPlacementTest {
    id?: string;
    description:string;
    name:string;
    type: PlacementType;
    evaluation: string;
    isActive: boolean;
    pre_a_one: number;
    a_one: number;
    a_two: number;
    b_one: number;
    b_two: number;
    c_one:number;
    createdAt?:string;
    updatedAt?:string;
}

export interface PlacementTest  {
    id?: string;
    description:string;
    name:string;
    type: PlacementType;
    evaluation: Evaluation;
    isActive: boolean;
    pre_a_one: number;
    a_one: number;
    a_two: number;
    b_one: number;
    b_two: number;
    c_one:number;
    createdAt?:string;
    updatedAt?:string;
}


export interface PlacementTestRequest {
    status: PlacementTestRequestStatus;
}
export interface Pagination {
    result: PlacementTest[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum PlacementType {
    WELCOME_DAY = 'WELCOME_DAY',
    PLACEMENT_TEST = 'PLACEMENT_TEST' 
}

export enum PlacementTestRequestStatus {
    PENDING = 'PENDING',
    DECLINE = 'DECLINE',
    IN_PROCESS = 'IN_PROCESS',
    FINISHED = 'FINISHED' 
}