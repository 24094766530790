import {Route, Routes} from 'react-router-dom'
import { BookingDashboard } from '../components/dashsboard'
 


const BookingDashboardPage = () => (
  <Routes>
      <Route path='/:code' element={<BookingDashboard />} />
  </Routes>
)

export {BookingDashboardPage}
