//import { Country, DataAllCountryResponse, DataPaginateCountryResponse } from '../interfaces/index'
 

 
import { useStore } from 'zustand'
import useAuth from '../../../api/main'
import { api } from '../../auth/services/auth.services'
 
 

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}


export const FileMultipleAdd = async (data: any): Promise<any> => {
  try {
    const response: any = await api.post(
      '/file-folder/multiple',data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      }
    )
    return response.data
  } catch (e) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}


