import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { GroupWordAdd, GroupWordUpdate } from '../../services'
import { UseGroupWordModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { QuestionIE } from '../../../../../../../courses/exercises/group-words/main/main'
import { showSimpleAlert } from '../../../../../../../../commonHooks/alert'
import Swal from 'sweetalert2'
import { KTIcon } from '../../../../../../../../../_metronic/helpers'

export type FormFields = {
  name: string
  isActive: boolean
  description: string
  groups: QuestionIE[];
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const GroupWordForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseGroupWordModule();
  const [itemGroup, setItemGroup] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<QuestionIE>();
  const [group, setGroup] = useState<QuestionIE>({
    name: '',
    order: 1,
    values: []
  });
  const [loading, setLoading] = React.useState<boolean>(false)
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        groups: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.groups.map((x) => JSON.parse(x)) ?? [] : [],
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await GroupWordAdd({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['group-word'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await GroupWordUpdate({
              ...formData,
              exercises: id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['group-word'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })

  const addGroup = () => {
    const orderRepeated = values.groups.find(x => x.order === group.order);
    if (orderRepeated) return showSimpleAlert({ message: 'Orden Repetido', icon: 'error' });
    setFieldValue('groups', [...values.groups, group]);
    setGroup({
      name: '',
      order: 1,
      values: []
    })
  };

  const addItemGroup = (order: number) => {
    const currentGroup = values.groups.find(x => x.order === order);
    if (!currentGroup) return showSimpleAlert({ message: 'No se encontro el grupo', icon: 'error' });

    const valuesRepeated = currentGroup.values.find(x => x === itemGroup);
    if (valuesRepeated) return showSimpleAlert({ message: 'Palabra repetida en este grupo', icon: 'error' });

    currentGroup.values = [...currentGroup.values, itemGroup];

    const newValues = values.groups.map((x) =>
      x.order === currentGroup.order ? currentGroup : x
    );

    setFieldValue('groups', newValues);
    setItemGroup('');
  };

  const deleteGroup = (order: number) => {
    const findGroup = values.groups.find(x => x.order === order)
    if (!findGroup) return showSimpleAlert({ message: 'No se encontro el grupo', icon: 'error' });

    const newValues = values.groups.filter(x => x.order !== order);

    if (findGroup.values.length > 0) {
      Swal.fire({
        title: 'Este grupo tiene respeustas',
        text: '¿Desea eliminar el grupo?',
        showCancelButton: true,
        showConfirmButton: true
      }).then((res) => {
        if (res.isConfirmed) {
          setFieldValue('groups', newValues);
        }
      })
      return;
    }

    setFieldValue('groups', newValues);
    return;
  };

  const deleteItemGroup = (orderGroup: number, word: string) => {
    const currentGroup = values.groups.find((x) => x.order === orderGroup);
    if (!currentGroup) return showSimpleAlert({ message: 'No se encontro el grupo', icon: 'error' });

    const newWords = currentGroup.values.filter(x => x !== word);
    currentGroup.values = [...newWords];

    const newValues = values.groups.map((x) =>
      x.order === currentGroup.order ? currentGroup : x
    );

    setFieldValue('groups', newValues);
  };

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          {(!selectedGroup) ? <>
            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Nombre del grupo</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'groupName'}
                value={group.name}
                onChange={(e) => setGroup({...group, name: e.target.value})}
              />
              <div className='d-flex'>
                <div >
                  <label className='form-label mb-0'>Orden</label>
                  <input
                      type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=''
                    disabled={mode === 'VIEW'}
                    name={'groupOrder'}
                    value={group.order}
                    onChange={(e) => setGroup({...group, order: parseInt(e.target.value)})}
                  />
                </div>
                <div className='card-footer d-flex justify-content-between py-6 px-9'>
                  <button type='button' onClick={() => addGroup()} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                    {!loading && 'Save'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className='col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Nombre</th>
                  </tr>
                </thead>
                <tbody className='scroll-y' style={{ maxHeight: '10px' }}>
                  {values.groups.length > 0 && values.groups.map((x, i) => (
                    <tr key={i}>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.name}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading || mode === 'VIEW'}
                            type='button'
                            onClick={() => setSelectedGroup(x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTIcon iconName='plus' className='fs-3 text-primary' />

                          </button>
                          <button
                            disabled={loading || mode === 'VIEW'}
                            type='button'
                            onClick={() => deleteGroup(x.order)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </> : <>
          <div>
            <button type='button'
              className='btn btn-primary'
              onClick={() => setSelectedGroup(undefined)}
            >
              Grupos
            </button>
          </div>
          <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Palabra</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'groupName'}
                value={itemGroup}
                onChange={(e) => setItemGroup(e.target.value)}
              />
                <div className='card-footer d-flex justify-content-between py-6 px-9'>
                  <button type='button' onClick={() => addItemGroup(selectedGroup.order)} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                    {!loading && 'Save'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
            </div>

            <div className='col-6'>
              <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Palabra</th>
                  </tr>
                </thead>
                <tbody className='scroll-y' style={{ maxHeight: '10px' }}>
                  {selectedGroup.values.length > 0 && selectedGroup.values.map((x, i) => (
                    <tr key={i}>
                      <td className=''>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            disabled={loading || mode === 'VIEW'}
                            type='button'
                            onClick={() => deleteItemGroup(selectedGroup.order, x)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>}

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { GroupWordForm }
