import React, { FC } from 'react'
import { KTIcon } from '../../../../../../_metronic/helpers';
import { UseAutoVerification } from '../../../store/main';
import VerificationTestFullScreenExercise from '../../../../courses/exercises/verficaton-test/full-screen'
//import  VerificationTestFullScreenExercise  from '../../courses/exercises/verficaton-test/full-screen'
type Props = {
  onSubmit: () => void;
  prevStep: () => void;
}

const StepPhoto: FC<Props> = ({ onSubmit, prevStep }) => {

  const { verificationRequest, setContentPhoto, answers, setAnswers, resetAnswers} = UseAutoVerification();

  const [photo, setPhoto] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const changePhoto = (obj: any) => {
    setPhoto(obj);
    setContentPhoto(obj.id);
  };

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='champ-light' style={{ color: '#2d30e1' }}>Términos y condiciones</h2>
      </div>
          {answers.length}
          {answers.length > 0 &&<h2 className='champ-bold text-center' style={{ color: '#2d30e1' }}>Todos los terminos aprobados</h2>}
          {answers.length > 0 &&<a href='#' onClick={()=>resetAnswers()}><h5 className='poppins-light text-center'>reiniciar test</h5></a>}
          {answers.length < 1 && <VerificationTestFullScreenExercise
            colorSecondary='blue'
            titleContent='Lionel Pessi'
            descriptionContent='El genio mundial '
            isLastStep={(e) => alert(e)}
            setResponse={(e) => setAnswers(e)}
          />}
      {answers.length > 0 &&<div className='d-flex flex-stack pt-10'>
        <div className='mr-2'>
          <button
            onClick={prevStep}
            type='button'
            className='btn btn-lg btn-light-primary me-3'
            data-kt-stepper-action='previous'
          >
            <KTIcon iconName='arrow-left' className='fs-4 me-1' />
            Volver
          </button>
        </div>
        <div>
          <button type='button' onClick={onSubmit} className='btn btn-lg btn-primary me-3'>
            <span className='indicator-label'>
              {'Finalizar'}
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </span>
          </button>
        </div>
      </div>}
      <pre>
      {/* {JSON.stringify(answers, null, 2)} */}
    </pre>

      {/* <StepInfoForm onSubmit={onSubmit}/> */}
    </div>
  )
}

export { StepPhoto }