import { TraditionalLevelEnum } from "../../../abroad/road-map-step/interfaces";

export interface DataPaginateTasksResponse {
    status:  string;
    message: string;
    data:    DataTasks;
}

export interface DataAllTasksResponse {
    status:  string;
    message: string;
    data:    Tasks[];
}

export interface DataTasks {
    id?: string;
    description:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Tasks  {
  id: string
  isActive: boolean
  isComplete: boolean
  code: string
  percentage: number
  show_video: boolean
  exercises: boolean
  createdAt: string
  updatedAt: string
  status: TaskStatus
  type: TaskType
  lesson?: string
  placement_test?: string
}

export interface Pagination {
    result: Tasks[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}



export enum TaskStatus {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  REPROVED = 'REPROVED',
  QUALIFIED = 'QUALIFIED',
}

export enum TaskType {
  NORMAL = 'NORMAL',
  WELCOME_DAY = 'WELCOME_DAY',
  PLACEMENT_TEST = 'PLACEMENT_TEST',
  FINAL_PRESENTATION = 'FINAL_PRESENTATION',
  QUIZ = 'QUIZ'
}

export interface TaskResumeItem {
  item: string
  order: number
  exercises: string
  percentageUnity: number
  percentageGlobal: number
  isCompleted: boolean
  exercise: Exercise
}

export interface Exercise {
  id: string
  description: string
  name: string
  code: string
  feedBackImmediate: boolean
  isActive: boolean
  createdAt: string
  updatedAt: string
  type: string
  variant: string
  level: string
}


export enum ItemCategoryType {
  GRAMMAR = 'GRAMMAR',
  WRITTEN_COMPREHENSION = 'WRITTEN_COMPREHENSION',
  ORAL_COMPREHENSION = 'ORAL_COMPREHENSION',
  WRITTEN_PRODUCTION = 'WRITTEN_PRODUCTION',
  ORAL_PRODUCTION = 'ORAL_PRODUCTION'
}

export type LevelsByCategory = {
  [key in ItemCategoryType]?: {
    level: TraditionalLevelEnum;
    value: number;
  };
};