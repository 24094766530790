export interface DataPaginateSimpleSelectionQuestionsResponse {
    status:  string;
    message: string;
    data:    DataSimpleSelectionQuestions;
}

export interface DataAllSimpleSelectionQuestionsResponse {
    status:  string;
    message: string;
    data:    SimpleSelectionQuestions[];
}

export interface DataSimpleSelectionQuestions {
    id?: string;
    description?:string;
    name:string;
    value: number;
    order: number;
    answer: any;
    exercises: any;
    mode: TypeSimpleQuestions;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface SimpleSelectionQuestions  {
    id?: string;
    description?:string;
    name:string;
    value: number;
    order: number;
    answer?: any[];
    exercises: any;
    mode: TypeSimpleQuestions;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: SimpleSelectionQuestions[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface AnswerSimpleSelection {
    title: string;
    order: number;
    isTrue: boolean;
}

export enum TypeSimpleQuestions {
    IMG = 'IMG',
    WORD = 'WORD'
}