import {Route, Routes} from 'react-router-dom'
import { TransferStudentDetails } from '../components/details'
import { TransferStudentTableList } from '../components/list'


const TransferStudentPage = () => (
  <Routes>
      <Route path='/details' element={<TransferStudentDetails />} />
      <Route path='/' element={<TransferStudentTableList />} />
  </Routes>
)

export {TransferStudentPage}
