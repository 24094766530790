import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers' 

import { Item2 } from '../../../../_metronic/partials/content/activity/Item2'
import { NotificationItem } from './NotificationItem'

type Props = {
  data: any
};

const NotificationList: FC<Props> = ({data}) => (
  <>
    <NotificationItem data={data} />
  </>
)

export {NotificationList}
