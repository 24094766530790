import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../_metronic/partials';

type Props = {
  children?: React.ReactNode; // Agregando la capacidad de recibir hijos
};

const RightCardContent: React.FC<Props> = ({ children }: Props) => {
    const { mode } = useThemeMode();

    return (
        <div className="card-basic-vocabulary-completion"
            style={
                mode !== 'dark' ? {
                    height: 420,
                    background: `white`,
                    position: 'relative',
                    overflow: 'hidden',
        
                } : {
                    height: 420,
                    background: `white`,
                    position: 'relative',
                    overflow: 'hidden'
                }
            }>
            {children} {/* Renderizar hijos aquí */}
        </div>
    );
};

export { RightCardContent };
