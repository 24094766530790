import {Route, Routes} from 'react-router-dom'
import { ReContractDetails } from '../components/details'
import { ReContractTableList } from '../components/list'


const ReContractPage = () => (
  <Routes>
      <Route path='/details' element={<ReContractDetails />} />
      <Route path='/' element={<ReContractTableList />} />
  </Routes>
)

export {ReContractPage}
