import {Route, Routes} from 'react-router-dom'
import { ResumeTableList } from '../components/list'
// import { AppointmentDetails } from '../components/details'
// import { AppointmentTableList } from '../components/list'


const ResumePage = () => (
  <Routes>
      {/* <Route path='/details' element={<ResumeDetails />} /> */}
      <Route path='/' element={<ResumeTableList />} />
  </Routes>
)

export {ResumePage}
