import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Leads} from '../../interfaces'

import { LeadsAdd, LeadsUpdate } from '../../services'
import { UseLeadsModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'

export type FormFields = {
  name: string,
  email?: string,
  phoneNumber: string,
  description?: string,
  isActive: boolean,
  enrolled?:string,
  contract: string
}
const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const entitySchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
  isActive: Yup.boolean().required(' name is required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Formato de número de teléfono incorrecto'),
  name:  Yup.string().required('First name is required'),
  description:  Yup.string().required('First name is required'),
  enrolled: Yup.string().required('First name is required')
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const LeadsForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseLeadsModule();
  const [data, setData] = useState<Leads>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        enrolled: (mode==='EDIT' || mode==='VIEW')? itemSelected?.enrolled??'': '',
        email: (mode==='EDIT' || mode==='VIEW')? itemSelected?.email??'': '',
        phoneNumber:(mode==='EDIT' || mode==='VIEW')? itemSelected?.phoneNumber??'': '',
        description: (mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'': '',
        contract:(mode==='EDIT' || mode==='VIEW')? itemSelected?.contract??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await LeadsAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['leads'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  LeadsUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['leads'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Estado</label>
            <select
              disabled={mode === 'VIEW'}
              name={'enrolled'}
              value={values.enrolled}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
              <option selected></option>
               <option value='ENROLLED'>Matriculado</option> 
               <option value='NOT_ENROLLED'>Sin Matrícula</option> 
            </select>
            {errors.enrolled && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.enrolled}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Contract</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'contract'}
              value={values.contract}
              onChange={handleChange}
            />
            {touched.contract && errors.contract && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.contract}</span>
                </div>
              </div>
            )}
          </div>


          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Email</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email}</span>
                </div>
              </div>
            )}
          </div>

         <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Teléfono</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.phoneNumber}
              onChange={handleChange}
            />
            {touched.phoneNumber && errors.phoneNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.phoneNumber}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> 
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {LeadsForm}
