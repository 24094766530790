import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ItemsWritingCheckListItems, WritingCheckList, WritingCheckListType} from '../../interfaces'
import { WritingCheckListAdd, WritingCheckListUpdate } from '../../services'
import { UseWritingCheckListModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { TraditionalLevelEnum } from '../../../../../../abroad/road-map-step/interfaces'
import { useTranslation } from 'react-i18next'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'

export type FormFields = {
  name: string
  isActive: boolean
  questions: ItemsWritingCheckListItems[];
  description?:string
  level: TraditionalLevelEnum;
  limit: number;
  minimum: number;
  type: WritingCheckListType;
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const WritingCheckListForm: React.FC<Props>  = (props:  Props) => {
  const {t} = useTranslation();
  const {id} = useParams();
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseWritingCheckListModule();
  
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataAnswer, setDataAnswer] = React.useState<ItemsWritingCheckListItems>({
    order: 1,
    title: ''
  })

  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        questions: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.questions.map(x => JSON.parse(x)) ?? [] : [],
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        limit: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.limit ?? 80 : 80,
        minimum: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.minimum ?? 20 : 20,
        type: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.type ?? WritingCheckListType.TIME_TO_SPEAK : WritingCheckListType.TIME_TO_SPEAK,
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await WritingCheckListAdd({
              ...formData,
              exercises: id??'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['writing-check-list'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  WritingCheckListUpdate({
                ...formData,
                exercises: id??'xx'
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['writing-check-list'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    });

    const handleAddCategory = () => {
      const findOrderRepeat = values.questions.find(x => x.order === dataAnswer.order);
      if (findOrderRepeat) 
        return showSimpleAlert({message: 'Orden repetido', icon: 'error'});
      setFieldValue('questions', [...values.questions, dataAnswer]);
      setDataAnswer({
        title: '',
        order: 1
      })
    }
  
    const deleteCategory = (order: number) => {
      setFieldValue('questions', values.questions.filter(x => x.order !== order));
    };
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Tipo</label>
            <select
              name='type'
              className='form-select'
              aria-label='Default select example'
              value={values.type}
              onChange={handleChange}
              disabled={mode==='VIEW'}
            >
              {Object.keys(WritingCheckListType).map((x) => (
                <option key={x} value={x}>{t(x)}</option>
              ))}
            </select>
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Categorias</label>
            <div className='mb-2 col-12'>
              <label className='form-label mb-2'>Pregunta</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.title}
                onChange={(e) => setDataAnswer(prev => ({...prev, title: e.target.value}))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Orden</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode==='VIEW'}
                value={dataAnswer.order}
                onChange={(e)=> setDataAnswer(prev=>({...prev, order: parseInt(e.target.value)}))}
              />
            </div>
            
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={handleAddCategory} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

          <div className='mb-10 col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Pregunta</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.questions.sort((a,b) => a.order - b.order).map((x) => (
                  <tr>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.title}
                            </a>
                            <span className='text-muted fw-semibold d-block fs-7'>
                              {x.order}
                            </span>
                          </div>
                        </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading ||mode==='VIEW'}
                          type='button'
                          onClick={()=> deleteCategory(x.order)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div> */}
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {WritingCheckListForm}
