import React, { useEffect } from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { UseTrueFalseModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';

type Props = {
    titleContent: string;
    descriptionContent: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
};

const TrueFalseFullExercise: React.FC<Props> = ({ renderType, titleContent, descriptionContent, colorSecondary }) => {
 
    let { 
        questions,
        step, 
        setAnswer, 
        status, 
        answer,
        setStatus,
        startQuiz,
    } = UseTrueFalseModule();


    useEffect(()=>{
        console.log(status);
    },[status])

    return (
        <>
        {renderType && renderType === 'left' ? <></> : 
        <>
            {status !== 'RESUME' && status !== 'TITLE' &&
                <BasicVocabularyExerciseItem
                    questions={questions}
                    handleNext={(e) => {}}
                    handleSendAnswer={(e) => setAnswer(e)}
                    modeQuiz={status}
                    totalQuestions={questions.length}
                    setStatus={(e) => setStatus(e)}
                    setStep={(e) => console.log(step + e)}

                />}
            {status === 'RESUME' &&
                <BasicVocabularyExerciseResume
                    total={questions.length}
                    correct={answer.length}
                    incorrect={answer.length}
                    setStatus={(e) => setStatus(e)}
                    answer={answer}
                />}
            {status === 'TITLE' &&
                <IntroBasicVocabulary
                    title={titleContent}
                    instructions={descriptionContent}
                    startQuiz={() => startQuiz()}
                />
            }
        </>}

        <style>
            {`.card-with-extended-background::before {
            background-color: ${colorSecondary};
            }`}
        </style>

        </>
    );
};

export default React.memo(TrueFalseFullExercise);