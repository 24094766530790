import React, { useEffect } from 'react';
import { UseExerciseTimerModule } from "../store/main";

type Props = {
  isChecked: boolean;
  onNext: () => any;
  onSubmit: () => void;
  exerciseId: string | undefined;
}

const ButtonSaveExercise: React.FC<Props> = ({ isChecked, onNext, onSubmit, exerciseId }) => {

  const { timeLeft } = UseExerciseTimerModule();
  const currentTimer = timeLeft.find(element => element.exerciseId === exerciseId);  
  const exerciseBeforeTime = (currentTimer?.time ?? 0) > 0
  return (
    <div className='mt-0' style={{ cursor: `${exerciseBeforeTime ? 'not-allowed' : 'pointer'}`, marginRight: `${exerciseBeforeTime ? '25px' : '0'}` }}>
      <button
        type='button'
        disabled={exerciseBeforeTime}
        title={exerciseBeforeTime ? "Vea todo el contenido" : "Save"}
        className="btn champ-bold py-1"
        style={{ fontSize: 14, backgroundColor: '#D0FF00', color: `${exerciseBeforeTime ? "#c1b7b7" : "#3f8bff"}`, cursor: `${exerciseBeforeTime ? 'not-allowed' : 'pointer'}`, borderRadius: '20px' }}
        onClick={() => {
          isChecked ? onSubmit() : onNext();
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px' }}>
          {currentTimer ? (
            currentTimer.time !== 0 ? (
              <span style={{ whiteSpace: 'nowrap' }}>
                {`Wait ${currentTimer.time}s`}
              </span>
            ) : (
              <span>
                {isChecked ? <>save</> : <>next</>}
              </span>
            )
          ) : (
            <span>
              {isChecked ? <>save</> : <>next</>}
            </span>
          )}
          <i className="bi bi-chevron-right" style={{ color: `${exerciseBeforeTime ? "#c1b7b7" : "#3f8bff"}`, marginLeft: '5px' }} />
        </div>
      </button>
    </div>
  );
};

export { ButtonSaveExercise };
