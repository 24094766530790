import { create } from "zustand";
import { ContentVerification, DataBeneficiaryType, DataInfoType } from "../interface";
import { ProposalFormField } from "../components/steps/step-info/form";
import moment from "moment";
export interface Answer {
    answer :string
    question:string
    number:number
    type:string
}

interface InterfaceAutoVerification {
    dataHolder?: any;
    verificationRequest: any;
    beneficiaries: any[];
    contentPhoto?: string;
    contentAudio?: string[];
    responseInfo: DataInfoType[];
    questions: any[];
    answers:Answer[] 
    currentQuestion: any;
    responseBeneficiary: DataBeneficiaryType[];
    setResponseInfo: (value: DataInfoType[]) => void;
    setResponseBeneficiary: (value: DataBeneficiaryType[]) => void;
    setVerificationRequest: (value: any) => void;
    setDataHolder: (value: any) => void;
    setContentPhoto: (value: string) => void;
    setContentAudio: (value: string[]) => void;
    setBeneficiaries: (value: any) => void;
    setAnswers: (value: Answer[]) => void;
    resetAnswers: () => void;
}

export const UseAutoVerification = create<InterfaceAutoVerification>((set) => ({
    responseInfo: [],
    answers:[],
    verificationRequest: {
        "id": "85500417-f10d-45a1-8075-0133f321a12c",
        "contract": "V-32576",
        "responseHolder": [],
        "responseBeneficiaries": [],
        "status": "PENDING",
        "isActive": true,
        "createdAt": "2024-05-03T23:17:12.595Z",
        "updatedAt": "2024-05-03T23:17:12.595Z",
        "photo": null,
        "audios": []
    },
    contentPhoto: "ca045863-2695-4b16-8c7c-ec0428e214c6",
    currentQuestion: {
        finished: false,
        order: 1,
        name: '¿Acepta terminos y condiciones?'
    },
    questions: [
        {
            finished: false,
            order: 1,
            name: '¿Acepta terminos y condiciones?'
        },
        {
            finished: false,
            order: 2,
            name: 'Hola'
        },
        {
            finished: false,
            order: 3,
            name: 'como estas'
        }
    ],
    beneficiaries:[],
    dataHolder: undefined,
    responseBeneficiary: [],
    setResponseInfo: (value) => {
        set({ responseInfo: value });
    }, 
    setResponseBeneficiary: (value) => {
        set({ responseBeneficiary: value });
    },
    setVerificationRequest: (value) => {
        set({ verificationRequest: value });
    },
    setContentPhoto: (value) => {
        set({ contentPhoto: value });
    },
    setContentAudio: (value) => {
        set({ contentAudio: value });
    },
    setDataHolder: (value) => {
        set({ dataHolder: {
            ...value,
            birthDate: moment(value.birthDate).format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD'),
        } });
    },
    setBeneficiaries: (value) => {
        const formattedArray = value.map(item => ({
            ...item,
            birthDate: item.birthDate ? moment(item.birthDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        }));
        set({ beneficiaries: formattedArray });
    },
    setAnswers:(value)=>{
        set({answers:value})
    },
    resetAnswers:()=>{
        set({answers:[]})
    },
}));    