/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {SupportTicketForm} from '../forms'
import {UseSupportTicketModule} from '../../store/main'
import { StatisticsWidget2 } from '../../../../../../_metronic/partials/widgets'
import useAuth from '../../../../../api/main'
import MultipleFileInput from '../../../../files/components/multiple-files'
import { useQueryClient } from '@tanstack/react-query'
import { SupportTicketResponseAdd, SupportTicketUserResponseAdd, UpdateSupportTicketStatus } from '../../services'
import { SupportTicketStatus } from '../../interfaces'
type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const InteractiveModal = ({show, handleClose}: Props) => {
  const queryClient = useQueryClient();
  const {typeUser, email} = useAuth.getState();

  const [loading, setLoading] = useState<boolean>(false);
  
  const {modal, mode,setModalInteractive,resetData,itemSelected} = UseSupportTicketModule();
  
  const [files, setFiles] = useState<any[]>([]);
  const [descriptionValue, setDescriptionValue] = useState<string>('');
  const submitResponse = async() => {
    try{
      setLoading(true);
      await SupportTicketResponseAdd({
        name: email ?? 'xxx',
        files: files.map(x => x.id),
        description: descriptionValue,
        isActive: true,
        ticket: itemSelected?.id??'xxx'
      });
      await UpdateSupportTicketStatus(itemSelected?.id??'xxx', SupportTicketStatus.ANSWERED);
      resetData();
      setDescriptionValue('')
      setFiles([])
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ['support-ticket'] });

      }catch(e){
        setLoading(false);
      }
  }

  const submitReload = async() => {
    try{
      setLoading(true);
      await SupportTicketUserResponseAdd({
        name: email ?? 'xxx',
        files: files.map(x => x.id),
        description: descriptionValue,
        ticket: itemSelected?.id??'xxx'
      });
      resetData();
      setDescriptionValue('')
      setFiles([])
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ['support-ticket'] });

      }catch(e){
        setLoading(false);
      }
  }

  // useEffect(() => {
  //   if (itemSelected?.responseSupportTicket) {
  //     setDescriptionValue(itemSelected?.responseSupportTicket[0]?.description??'')
  //   }
  // }, [itemSelected]);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
     // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header champ-light'>
        <h2>Respuesta</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={()=>{
          setModalInteractive(false)
        }}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body poppins-light'>
        {/* <StatisticsWidget2 
          className='card-xl-stretch mb-xl-8'
          avatar='/media/svg/avatars/029-boy-11.svg'
          title={itemSelected?.name??'xx'}
          description={itemSelected?.description??'xx'}
          response={itemSelected?.responseSupportTicket}
        /> */}

        {typeUser === 'ADMIN' && <>
            <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Respuesta</label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  name={'description'}
                  value={descriptionValue}
                  onChange={(e) => setDescriptionValue(e.target.value)}
                />
              </div>

              <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Support Files</label>
                <MultipleFileInput
                  files_={files}
                  onChangeFile={(e) => { setFiles(files.concat(e)) }}
                  onChangeLoading={(e) => console.log(e)}
                  onDeleteFile={(e) => {
                    alert(e)
                    setFiles(prevFiles => {
                      const updatedFiles = prevFiles.filter((_, index) => index !== e);
                      return updatedFiles;
                    });
                  }}
                />
              </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' onClick={submitResponse} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
        </>}

        {typeUser === 'STUDENT' && <>
        <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Mensaje</label>
                <textarea
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode==='VIEW'}
                  name={'description'}
                  value={descriptionValue}
                  onChange={(e) => setDescriptionValue(e.target.value)}
                />
              </div>

              <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Support Files</label>
                <MultipleFileInput
                  files_={files}
                  onChangeFile={(e) => { setFiles(files.concat(e)) }}
                  onChangeLoading={(e) => console.log(e)}
                  onDeleteFile={(e) => {
                    alert(e)
                    setFiles(prevFiles => {
                      const updatedFiles = prevFiles.filter((_, index) => index !== e);
                      return updatedFiles;
                    });
                  }}
                />
              </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' onClick={submitReload} className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
        </>}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {InteractiveModal}