import { create } from 'zustand';
import moment from 'moment';
import { Pagination } from '../interfaces';

interface PromotionHistoryModule {
    before: Date;
    after: Date;
    paginator: Pagination;
    currentPage: number;
    limit: number;
    setBefore: (value: Date) => void;
    setAfter: (value: Date) => void;
    setPaginator: (paginator: Pagination) => void;
    setCurrentPage: (value: number) => void;
    setLimit: (value: number) => void;
}

export const UsePromotionHistoryModule = create<PromotionHistoryModule>((set) => ({
    before: moment().toDate(),
    after: moment().toDate(),
    paginator: {
        result: [],
        total: 0,
        limit: 0,
        current_page: 1,
        total_page: 0,
    },
    currentPage: 1,
    limit: 10,
    setBefore: (value) => {
        set({ before: value });
    },
    setAfter: (value) => {
        set({ after: value });
    },
    setPaginator: (pagination) => {
        set({
            paginator: {
                result: pagination.result,
                total: pagination.total,
                limit: pagination.limit,
                current_page: pagination.current_page,
                total_page: pagination.total_page,
            }
        });
    },
    setCurrentPage: (value) => {
        set({ currentPage: value });
    },
    setLimit: (value) => {
        set({ limit: value });
    },
}));
