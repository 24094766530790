import {Route, Routes} from 'react-router-dom'
import { UserDetails } from '../components/details'
import { UserTableList } from '../components/list'
import { StudentSettingsTableList } from '../components/list/StudentSettingsList'


const UserPage = () => (
  <Routes>
      <Route path='/student-setting' element={<StudentSettingsTableList />} />
      <Route path='/details' element={<UserDetails />} />
      <Route path='/' element={<UserTableList />} />
  </Routes>
)

export {UserPage}
