import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import './header-style.css'
import { useThemeMode } from '../../../../../_metronic/partials';
import { logoAppSelectorUrl } from '../../../../hooks/useAppSelectorAssetsMode';
type Props = {
    title: string;
    leftIcon?: string;
    svgIcon: string;
    background: string;
    colorTitle?: string;
    routeBack?: string;
};

const CardHeaderModule: React.FC<Props> = ({svgIcon,leftIcon, title, background, routeBack, colorTitle}) => {
    const navigate = useNavigate();
    const {mode} = useThemeMode();
    let url = logoAppSelectorUrl();
    const handleClick = () => {
        if (routeBack) {
            navigate(routeBack);
        } else {
            navigate('/dashboard');
        }
    };

    return (
        <div className={`card michigan-title-card my-5`} style={{ background:mode==='light'?background:'',position:'relative', border:mode==='dark'?`1px solid ${background}`:''}}>
            <h3 className='champ-light michigan-title-top ' style={{ color:mode==='light'?colorTitle:background}}>{url.name}</h3>
            <div className="card-header d-flex justify-content-between align-items-center" style={{ border: 'none' }}>
                <div>
                    <div className=' left-icon-card-title' style={{position:'absolute' }}>
                        <img
                            src={toAbsoluteUrl(leftIcon??svgIcon)}
                            alt=''
                            onClick={handleClick}
                            style={{ cursor: 'pointer', transition: 'transform 0.2s ease-in-out' }}
                            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                        />
                    </div>
                </div>
                <div>
                    <div className='right-icon-card-title' style={{  position:'absolute' }}>
                        <img src={toAbsoluteUrl(svgIcon)} alt='' />
                    </div>
                </div>
            </div>
            <div className='card-body pb-0 py-0'>
                <div className={`champ-light fw-semibold pb-0 text-champ-title-header `} 
                style={{minWidth:20, color:mode==='light'?colorTitle:background }}>
                    {title}
                </div>
            </div>
        </div>
    );
};

export { CardHeaderModule };
