import React, { useEffect } from 'react';
import { BasicVocabularyExerciseCompletionItem } from './components/item';
import { UseBasicVocabularyCompletionModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';




type Props = {
    titleContent: string;
    descriptionContent: string;
};
const BasicVocabularyCompletionExercise: React.FC<Props> = ({ titleContent, descriptionContent}) => {

    useEffect(() => { }, []);
    let { 
            questions,
            setStep, 
            step, 
            setAnswer, 
            status, 
            falseAnswer, 
            trueAnswer, 
            setStatus,
            startQuiz,
        } = UseBasicVocabularyCompletionModule();
    return (
        <>

                
                    {status != 'RESUME' && status != 'TITLE' &&
                        <BasicVocabularyExerciseCompletionItem
                            question={questions[step]}
                            handleSendAnswer={(e) => setAnswer(e)}
                            modeQuiz={status}
                            totalQuestions={questions.length}
                            setStep={(e) => setStep(step + e)}
                            description={descriptionContent}

                        />}
                    {status === 'RESUME' &&
                        <BasicVocabularyExerciseResume
                            total={questions.length}
                            correct={trueAnswer.length}
                            setStatus={(e) => setStatus(e)}
                            correctAnswer={trueAnswer}
                            falseAnswer={falseAnswer}
                        />}
                    {status === 'TITLE' && 
                        <IntroBasicVocabulary 
                            title={titleContent}
                            instructions={descriptionContent}
                            startQuiz={()=>startQuiz()}
                        />
                    }


        </>
    );
};

export { BasicVocabularyCompletionExercise };