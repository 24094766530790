import React, { useEffect } from 'react';
import { BasicVocabularyExerciseCompletionItem } from './components/item';
import { useThemeMode } from '../../../../../_metronic/partials';
import { UseVerificationTestModule } from './store';
import { BasicVocabularyExerciseResume } from './components/resume-card';
import { IntroBasicVocabulary } from './components/intro';
import { TitleContentCourse } from '../../../../components/common/TitleContentCourse';
// import { LeftCardContent } from '../../../../components/common/left-card-content/indext';
import { BasicVocabularyExerciseCompletionItemLeft } from './components/item-left';
import { AudioTestItemRight } from './components/item-right';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import { UseModeCourseModule } from '../../components/front-page/store/main';
import { UseEvaluationTestModule } from '../../../admin/evaluation/modules/test/store/main';
import { UseVerificationQuestionAll } from '../../../admin/verification-question/services';
import { QuestionAndAnswer } from './interfaces';




type Props = {
    titleContent: string;
    descriptionContent?: string;
    colorSecondary: string;
    renderType?: 'left' | 'right'
    isLastStep?: (x:boolean) => void
    setResponse: (x:QuestionAndAnswer[]) => void
};
const VerificationTestFullScreenExercise: React.FC<Props> = ({ renderType,isLastStep, setResponse,titleContent, descriptionContent, colorSecondary }) => {
    const { mode } = useThemeMode();
    const [question, setQuestion] = React.useState<number>(0);
    const [selected, setSelected] = React.useState<boolean>(false);
    const { modeTest, modeCourse, currentFile } = UseModeCourseModule();
    const { questionsEvaluationTest } = UseEvaluationTestModule();
    const { questionsEvaluation } = UseEvaluationCourseModule();


    let {
        questions,
        setStep,
        step,
        setAnswer,
        status,
        answers,
        setStatus,
        startQuiz,
        title,
        instructions,
        list,
        setQuestionBasicCompletion,
        resetAnswer
    } = UseVerificationTestModule();
    const findQuestions = async () => {
        let response = await UseVerificationQuestionAll();
        setQuestionBasicCompletion(response)
    }

    useEffect(() => {
        findQuestions()
    }, []);

    useEffect(() => {
        if(isLastStep){
            if(step==questions.length){
                isLastStep(true)
                setResponse(answers)
                resetAnswer()
            }else{
                isLastStep(false)
            }
        }
    }, [step]);
    return (
        <>


     
            {renderType && renderType === 'left' ?
                <>
                    {status !== 'RESUME' && status !== 'TITLE' &&
                        <BasicVocabularyExerciseCompletionItemLeft

                            question={questions[step]}
                            handleNext={(e) => setAnswer(e)}
                            handleSendAnswer={(e) => setAnswer(e)}
                            modeQuiz={status}
                            totalQuestions={questions.length}
                            setStatus={(e) => setStatus(e)}
                            setStep={(e) => setStep(step + e)}
                            handSelected={selected}
                        />
                    }
                </>
                : <>
                    {(modeCourse && questionsEvaluation?.exercise?.fileContent || modeTest && questionsEvaluationTest?.exercise?.fileContent) ?
                        status != 'RESUME' && status != 'TITLE' &&
                        <BasicVocabularyExerciseCompletionItem

                            question={questions[step]}
                            handleNext={(e) => setAnswer(e)}
                            handleSendAnswer={(e) => setAnswer(e)}
                            modeQuiz={status}
                            totalQuestions={questions.length}
                            setStatus={(e) => setStatus(e)}
                            setStep={(e) => setStep(step + e)}
                        />
                        :
                        status != 'RESUME' && status != 'TITLE' &&
                        <AudioTestItemRight
                            handleSelected={(e) => setSelected(e)}
                            question={questions[step]}
                            handleNext={(e) => setAnswer(e)}
                            handleSendAnswer={(e) => setAnswer(e)}
                            modeQuiz={status}
                            totalQuestions={questions.length}
                            setStatus={(e) => setStatus(e)}
                            setStep={(e) => setStep(step + e)}
                        />}

                    {status === 'RESUME' &&
                        <BasicVocabularyExerciseResume
                            total={questions.length}
                            correct={0}
                            incorrect={0}
                            setStatus={(e) => setStatus(e)}
                            correctAnswer={[]}
                            falseAnswer={[]}
                        />}
                    {status === 'TITLE' &&
                        <IntroBasicVocabulary
                            title={title}
                            instructions={instructions}
                            startQuiz={() => startQuiz()}
                        />
                    }
                </>}



            <style>
                {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
            </style>



        </>
    );
};

export default React.memo(VerificationTestFullScreenExercise);