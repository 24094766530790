import { Lessons } from "../../../abroad/learning-path/modules/lessons/interfaces";
import { TraditionalLevelEnum } from "../../../abroad/road-map-step/interfaces";

export interface DataPaginateEvaluationResponse {
    status:  string;
    message: string;
    data:    DataEvaluation;
}

export interface DataAllEvaluationResponse {
    status:  string;
    message: string;
    data:    Evaluation[];
}

export interface DataEvaluation {
    id?: string;
    description?:string;
    name:string;
    type: EvaluationType;
    level: TraditionalLevelEnum;
    obligatory: boolean;
    colorPrimary: string;
    colorSecondary: string;
    cover?: any;
    evaluation_item?: EvaluationItem[];
    isActive: boolean;
    feedbackImmediate: boolean;
    minApprove?: number;
    // lesson?: string;
    createdAt?:string;
    updatedAt?:string;
}

export interface Evaluation  {
    id?: string;
    description?:string;
    name:string;
    type: EvaluationType;
    level: TraditionalLevelEnum;
    obligatory: boolean;
    colorPrimary: string;
    colorSecondary: string;
    cover?: any;
    evaluation_item?: EvaluationItem[];
    feedbackImmediate: boolean;
    minApprove?: number;
    isActive: boolean;
    // lesson?: Lessons;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: Evaluation[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export interface EvaluationItem {
    id?: string;
    percentage: number;
    order: number;
    timer: number;
    evaluation_auto: boolean;
    exercise: any;
    evaluation?: any;
    name: string
    category: ItemCategoryType;
    currentEvaluation?: boolean; 
    minPercentage: number;
    description: string;
    createdAt?: string;
    updatedAt?: string;
}

export enum EvaluationType {
    FINAL_PRESENTATION = 'FINAL_PRESENTATION',
    QUIZ = 'QUIZ',
    SINGLE_QUIZ = 'SINGLE_QUIZ',
    REVIEW = 'REVIEW',
    BOOK = 'BOOK',
    PLACEMENT_TEST = 'PLACEMENT_TEST',
    WELCOME_DAY = 'WELCOME_DAY'
}

export enum ItemCategoryType {
    GRAMMAR = 'GRAMMAR',
    WRITTEN_COMPREHENSION = 'WRITTEN_COMPREHENSION',
    ORAL_COMPREHENSION = 'ORAL_COMPREHENSION',
    WRITTEN_PRODUCTION = 'WRITTEN_PRODUCTION',
    ORAL_PRODUCTION = 'ORAL_PRODUCTION'
}