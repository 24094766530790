import {Route, Routes} from 'react-router-dom'
import { MasterClassLevelDetails } from '../components/details'
import { MasterClassLevelTableList } from '../components/list'


const MasterClassLevelPage = () => (
  <Routes>
      <Route path='/details' element={<MasterClassLevelDetails />} />
      <Route path='/' element={<MasterClassLevelTableList />} />
  </Routes>
)

export {MasterClassLevelPage}
