import { useEffect } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { FileFolderAdd } from '../../../modules/admin/file-folder/services';
import { FileContentChildrenAdd } from '../../../modules/admin/file-folder/services/file-content-course';
// import { MediaContentAudio } from "../../../modules/courses/components/media/audio";
import { ContentVerificationAdd, FileVerificationAdd } from "../../../modules/auto-verification/services";
import { ContentVerification, TypeFileContentRequest } from "../../../modules/auto-verification/interface";
 

type Props = {
    onChangeFile: (file: any) => void;
    onChangeLoading: (isLoading: boolean) => void;
    onChangeContent: (content: ContentVerification) => void;
    idInput?: string;
    root: string;
}
const VerificationVoiceInput: React.FC<Props> = ({ onChangeContent, onChangeFile, onChangeLoading, root }: Props) => {
    // Initialize the recorder controls using the hook
    const recorderControls = useVoiceVisualizer();
    const {
        // ... (Extracted controls and states, if necessary)
        recordedBlob,
        error,
        audioRef,
        saveAudioFile
    } = recorderControls;

    // Get the recorded audio blob
    useEffect(() => {
        if (!recordedBlob) return;

        console.log(recordedBlob);
    }, [recordedBlob, error]);

    // Get the error when it occurs
    useEffect(() => {
        if (!error) return;

        console.error(error);
    }, [error]);

    const changeFile = (file: any) => {
        onChangeFile(file);
      };

    const handleFileUpload = async () => {
        try {
          if (recordedBlob) {
            onChangeLoading(true);
            const formData = new FormData();
            const file = new File([recordedBlob], "audio.mp3", {
              type: 'audio/mpeg',
            });
            formData.append('file', file);
            const response = await FileVerificationAdd(formData);
            await changeFile(response);
            const responseContent =  await ContentVerificationAdd({
              isActive: true,
              type: TypeFileContentRequest.AUDIO,
              verification: root,
              content: response.id,
            });
            onChangeContent(responseContent.VerificationRequests);
            onChangeLoading(false);
          }
        } catch (error) {
          console.error(error);
          onChangeLoading(false);
        }
      };

      function downloadMp3(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || "download.mp3";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }

    return (<>
    
    <VoiceVisualizer ref={audioRef} controls={recorderControls} canvasContainerClassName="d-none"/>
        <button className="btn btn-active-color-primary" type="button" onClick={()=>handleFileUpload()}>guardar</button>

        {/* <MediaContentAudio urlAudio={"https://dkumy02vmzh93.cloudfront.net/1e374f3d-cc3e-4b9a-86b2-a82d83ce0ef8.mp3"} /> */}
    </>
    );
};
export { VerificationVoiceInput }
