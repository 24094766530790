import { create } from "zustand";
interface InterfaceBoosterExercise {
    texts: string[];
    answers: { [key: string]: string };
    instruction: string;
    studentResponse?: { [key: string]: string };
    exerciseId?: string
    setValues: (
      exerciseId: string, 
      instruction: string,
      texts: string[]) => void;
    setStudentResponse: (value: { [key: string]: string }) => void;
}

export const UseBoosterExercise = create<InterfaceBoosterExercise>((set) => ({
    texts: [],
    instruction: "",
    answers: {},
    setValues: (exerciseId, instruction, texts) => {
        const generateUniquePlaceholders = (texts: string[]) => {
            const placeholderCount = {};
            return texts.map(text => {
              return text.replace(/\{([^}]+)\}/g, (match, p1) => {
                if (!placeholderCount[p1]) {
                  placeholderCount[p1] = 1;
                } else {
                  placeholderCount[p1]++;
                }
                return `{${p1}_${placeholderCount[p1]}}`;
              });
            });
          };
        
        const uniqueTexts = generateUniquePlaceholders(texts);
        const answers = {};
        uniqueTexts.forEach(text => {
            const matches = text.match(/{([^{}]*)}/g);
            if (matches) {
            matches.forEach(match => {
                const key = match.slice(1, -1);
                const word = key.split('_')[0]
                answers[key] = word;
            });
            }
        });
        set({ texts: uniqueTexts, answers, exerciseId, instruction });
    },
    setStudentResponse:(value)=>{
        set({studentResponse: value});
    }
}));    