/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { t } from 'i18next'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'

export interface LevelCount {
    level:TraditionalLevelEnum
    count:string
}
type ResumeResponse = {
    total:string
    totalPercentage
}
type LevelChartsProps = {
    list: LevelCount[]
    totalStudent:number
}

const LevelWidgetCustom: React.FC<LevelChartsProps> = ({ list,totalStudent }) => {

    let getPercentageForLevel  =(requestedLevel: string)=>{
        const levelInfo = list.find(level => level.level === requestedLevel);
        if (!levelInfo) {
            return `0%`;
        }
        const percentage = (parseInt(levelInfo.count)/ totalStudent) * 100;
        return `${percentage.toFixed(2)}%`;
    }
    let getTotalForLevel  =(requestedLevel: string)=>{
        const levelInfo = list.find(level => level.level === requestedLevel);
        if (!levelInfo) {
            return `0`;
        }
        return levelInfo?.count
    }
    return (
        <div className='card shadow p-8'>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.PRE_A1_1)} ({getTotalForLevel(TraditionalLevelEnum.PRE_A1_1)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.PRE_A1_1)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.PRE_A1_1)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.PRE_A1_2)} ({getTotalForLevel(TraditionalLevelEnum.PRE_A1_2)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.PRE_A1_2)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.PRE_A1_2)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.A1)} ({getTotalForLevel(TraditionalLevelEnum.A1)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.A1)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.A1)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.PRE_A2)} ({getTotalForLevel(TraditionalLevelEnum.PRE_A2)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.PRE_A2)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.PRE_A2)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.A2)} ({getTotalForLevel(TraditionalLevelEnum.A2)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.A2)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.A2)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.PRE_B1)} ({getTotalForLevel(TraditionalLevelEnum.PRE_B1)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.PRE_B1)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.PRE_B1)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.B1)} ({getTotalForLevel(TraditionalLevelEnum.B1)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.B1)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.B1)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.B2)} ({getTotalForLevel(TraditionalLevelEnum.B2)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.B2)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.B2)}</div>
            </div>
            <h5 className='champ-light'> {t(TraditionalLevelEnum.C1)} ({getTotalForLevel(TraditionalLevelEnum.C1)})</h5>
            <div className="progress my-3">
                <div className="progress-bar bg-success " role="progressbar" style={{width:getPercentageForLevel(TraditionalLevelEnum.C1)}}  aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>{getPercentageForLevel(TraditionalLevelEnum.C1)}</div>
            </div>
        </div>
    )
}

export { LevelWidgetCustom }
