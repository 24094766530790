import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {TypeResponseVerificationQuestion, VerificationQuestion} from '../../interfaces'

import { VerificationQuestionAdd, VerificationQuestionUpdate } from '../../services'
import { UseVerificationQuestionModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { setTypeToSpanish } from '../../../user/utils/setTypeToSpanish'

export type VerificationQuestionFormFields = {
  name: string
  description: string
  isActive: boolean
  type: TypeResponseVerificationQuestion
  time: number
  order: number
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('Question is required'),
  type: Yup.string(),
  isActive: Yup.boolean(),
  time: Yup.number(),
  order: Yup.number(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const VerificationQuestionForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseVerificationQuestionModule();
  const [data, setData] = useState<VerificationQuestion>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<VerificationQuestionFormFields>({
      initialValues: {
        type: (mode==='EDIT' || mode==='VIEW')? itemSelected?.type??TypeResponseVerificationQuestion.AUDIO: TypeResponseVerificationQuestion.AUDIO,
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        order:(mode==='EDIT' || mode==='VIEW')? itemSelected?.order??0: 0,
        time:(mode==='EDIT' || mode==='VIEW')? itemSelected?.time??0: 0,
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await VerificationQuestionAdd({
              ...formData,
              time:parseInt(formData.time.toString()),
              order:parseInt(formData.order.toString())
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['verification-question'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  VerificationQuestionUpdate({
                ...formData,
                time:parseInt(formData.time.toString()),
                order:parseInt(formData.order.toString())
              })
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['verification-question'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Pregunta</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Orden</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'order'}
              value={values.order}
              onChange={handleChange}
            />
            {touched.order && errors.order && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.order}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Tiempo mínimo</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'time'}
              value={values.time}
              onChange={handleChange}
            />
            {touched.time && errors.time && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.time}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
              <label className='form-label mb-0'>level</label>
              <select
                disabled={mode === 'VIEW'}
                name='type'
                value={values.type}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(TypeResponseVerificationQuestion).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
              {errors.type && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.type}</span>
                  </div>
                </div>
              )}
            </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {VerificationQuestionForm}
