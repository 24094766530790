import YouTube, { YouTubeProps } from 'react-youtube';
import React from 'react';
type Props = {
    video:string;
    height?: string;
}

const TikTokEmbed: React.FC<Props> = ({video, height}: Props) => {
    const opts: YouTubeProps['opts'] = {
        height: height??340,
        width: '100%',
        playerVars: {
            autoplay: 0,
            muted: true,
        },
    };
  return (<>
    
            <YouTube videoId={video} 
                opts={
                    opts
                }
            />
    </>);
};

export default TikTokEmbed;