import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Certificate} from '../../interfaces'

import { CertificateAdd, CertificateUpdate } from '../../services'
import { UseCertificateModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'

export type FormFields = {
  code: string
  name: string
  isActive: boolean
  description?:string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const CertificateForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseCertificateModule();
  const [data, setData] = useState<Certificate>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
    useFormik<FormFields>({
      initialValues: {
        code: (mode==='EDIT' || mode==='VIEW')? itemSelected?.code??'': '',
        name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
        isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
        description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
      },
      validationSchema: entitySchema,

      onSubmit:async (formData)=> {
        if(props.mode==='ADD'){
          try{
            setLoading(true);
            await CertificateAdd({...formData})
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['certificate'] })

            }catch(e){
              setLoading(false);
            }

          }
          else{
            try{
              setLoading(true);
              await  CertificateUpdate({...formData})
              resetData();
              setLoading(false)
              queryClient.invalidateQueries({ queryKey: ['certificate'] })
 
              }catch(e){
                setLoading(false);
              }
          }
      },
    })
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Code</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'code'}
              value={values.code}
              onChange={handleChange}
            />
            {touched.code && errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.code}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {CertificateForm}
