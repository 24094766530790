import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { MasterClassLevel } from '../../interfaces'

import { MasterClassLevelAdd, MasterClassLevelUpdate } from '../../services'
import { UseMasterClassLevelModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { Country } from '../../../country/interfaces'
import { UseCountryAll } from '../../../country/services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import TikTokEmbed from '../../../../../components/common/TiktokEmbed'

export type FormFields = {

  id?: string
  name: string
  description?: string
  order: number
  video: string
  isActive: boolean
  country?: string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('Nombre es requerido'),
  description: Yup.string().required('Descripción es requerido'),
  video: Yup.string().required('Video URL is required'),
  isActive: Yup.boolean().required('Activo es requerido'),
  order: Yup.number().required('orden es requerido'),
  country: Yup.string().required('country es requerido')
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}
const MasterClassLevelForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient()
  const { modal, itemSelected, mode, setModal, resetData, setSelectedToEdit, setSelectedToView } = UseMasterClassLevelModule();
  const [data, setData] = useState<MasterClassLevel>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [countries, setCountries] = React.useState<Country[]>([])
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        order: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.order ?? 1 : 1,
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        video: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.video ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        country: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelected?.country && itemSelected?.country.id) ?? '' : '',
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await MasterClassLevelAdd({
               ...formData,
               //@ts-ignore
               order:parseInt(formData.order)
              })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['master-class-level'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await MasterClassLevelUpdate({ 
              ...formData,
              //@ts-ignore
              order:parseInt(formData.order)
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['master-class-level'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })
  const fetchCountries = async () => {
    try {
      let countries = await UseCountryAll()
      setCountries(countries)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontar paises!', icon: 'error' })
      throw 'Error'
    }
  }
  useEffect(() => {
    fetchCountries()
  }, [])
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-12 row'>
         {values.video && <div className='mb-10 col-12'>
            <TikTokEmbed video={values.video} />
          </div>}
          <div className='mb-10 col-12'>
            <div className='card-body border-top p-9 row'>

              <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Activo ?</label>
                <div className="form-check form-switch">
                  <input
                    name={'isActive'}
                    checked={values.isActive}
                    value={values.isActive ? 1 : 0}
                    onChange={handleChange}
                    className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                </div>
              </div>
              <div className='mb-10 col-6'>
                <label className='form-label mb-3'>Orden</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'order'}
                  value={values.order}
                  onChange={handleChange}
                />
                {touched.name && errors.order && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.order}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-10 col-6'>
                <label className='form-label mb-3'>Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'name'}
                  value={values.name}
                  onChange={handleChange}
                />
                {touched.name && errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-10 col-6'>
                <label className='form-label mb-3'>País</label>
                <select
                  disabled={mode === 'VIEW'}
                  name={'country'}
                  value={values.country}
                  onChange={handleChange}
                  className="form-select" aria-label="Default select example">
                  <option selected></option>
                  {countries.map((x) => (<option value={x.id}>{x.name}</option>))}
                </select>
                {errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.country}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='mb-10 col-6'>
                <label className='form-label mb-3'>Video</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'video'}
                  value={values.video}
                  onChange={handleChange}
                />
                {errors.video && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.video}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-10 col-12'>
                <label className='form-label mb-3'>Description</label>
                <textarea

                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'description'}
                  value={values.description}
                  onChange={handleChange}
                />
                {touched.description && errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.description}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>



        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { MasterClassLevelForm }
