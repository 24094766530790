import {create} from 'zustand';
import { Evaluation, EvaluationItem, EvaluationType } from '../../admin/evaluation/interfaces';
import { TraditionalLevelEnum } from '../../abroad/road-map-step/interfaces';
import { ExercisesType, VariantsExercisesType } from '../../admin/exercises/interfaces';

interface BookContainerModules {
    toolsPencil: boolean;
    toolsZoom: boolean;
    toolsViewPage: boolean;
    toolsExercise: boolean;
    toolsTimer: boolean;
    offDraggable: boolean;
    currentsItems: EvaluationItem[];
    evaluationBook?: Evaluation;
    toolsText: boolean;
    deleteItem:(value: number)=>void;
    setToolsTimer:(value: boolean)=>void;
    setToolsPencil:(value: boolean)=>void;
    setToolsZoom:(value: boolean)=>void;
    setToolsViwPage:(value: boolean)=>void;
    setToolsExercise:(value: boolean)=>void;
    setOffDraggable:(value: boolean)=>void;
    setCurrentsItems:(value: EvaluationItem)=>void;
    setEvaluationBook:(value: Evaluation)=>void;
    setToolsText:(value:boolean)=>void;
}

export const UseBookContainerModule = create<BookContainerModules>( (set, get) => ({
    toolsZoom: false,
    toolsPencil: false,
    toolsViewPage: false,
    toolsExercise: false,
    toolsTimer: false,
    toolsText: false,
    offDraggable: false,
    currentsItems: [],
    deleteItem:(value)=>{
        const newItems = get().currentsItems.filter(x => x.order!== value);
        set({currentsItems: newItems});
    },
    setToolsPencil:(value)=>{
        set({toolsPencil: value});
    },
    setToolsZoom:(value)=>{
        set({toolsZoom: value});
    },
    setToolsViwPage:(value)=>{
        set({toolsViewPage: value});
    },
    setToolsExercise:(value)=>{
        set({toolsExercise: value});
    },
    setToolsTimer:(value)=>{
        set({toolsTimer: value});
    },
    setToolsText:(value)=>{
        set({toolsText: value})
    }
    ,
    setOffDraggable:(value)=>{
        set({offDraggable: value});
    },
    setCurrentsItems:(value)=>{
        const findItem = get().currentsItems.find(x => x.id === value.id);
        const currentItems = findItem ? [...get().currentsItems] : [...get().currentsItems, value];
        set({currentsItems: currentItems});
    },
    setEvaluationBook:(value)=>{
        set({evaluationBook: value});
    }
}));