import {Route, Routes} from 'react-router-dom'
import { StudentAttendanceTableList } from '../components/list'


const StudentAttendancePage = () => (
  <Routes>
      <Route path='/:id' element={<StudentAttendanceTableList />} />
  </Routes>
)

export {StudentAttendancePage}
