import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { TeacherResponseExercise } from "../../../../admin/exercises/interfaces";
import { api } from "../../../../auth/services/auth.services";
import { HistoryWritingResponseExercise, HistoryWritingTeacherResponse } from "../interfaces";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseHistoryWriting {
    exercises: string;
    task: string;
    answers: HistoryWritingResponseExercise[];
    isActive: boolean;
    isReviewed: boolean;
    isPending: boolean;
}

export const AddHistoryWritingResponse = async (data: ResponseHistoryWriting): Promise<any> => {
  try {
    const response: any = await api.post(
      '/history-writing-response',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }

    throw 'Error'
  }
}

export const AddTeacherHistoryWritingResponse = async (id: string, data: HistoryWritingTeacherResponse): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/history-writing-response/${id}`,
      {
        ...data,
      },
      {
        headers: { 
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
    
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    if (e.response.status === 400) {
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }

    throw 'Error'
  }
}

export const countWords = (minWords: number, maxWords: number, text: string): {count: number, state: "allow" |"max" | "min"} => {
  const words = text.trim().split(/\s+/).filter(word => word.length > 0);
  const wordCount = words.length;

  if (wordCount >= minWords && wordCount <= maxWords) {
    return {count: maxWords - wordCount, state: "allow"}
  } else if(wordCount < minWords) {
    return {count: minWords - wordCount, state: "min"}
  } else {
    return {count: wordCount - maxWords, state: "max"}
  }
}