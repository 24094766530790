import React, {FC} from 'react'
import { BookingForm } from '../forms'


const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Detalles</h2>

      </div>

      <BookingForm />
    </div>
  )
}

export {Step3}
